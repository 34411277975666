/* External Libraries */
import cls from 'classnames';
import React from 'react';

interface InputProps {
  id?: string;
  type?: string;
  name?: string;
  onChange?: (event: any) => void;
  placeholder?: string;
  className?: string;
  value?: string | number;
  disabled?: boolean;
  autoComplete?: string;
}

export const Input = React.forwardRef((props: InputProps, ref: React.Ref<HTMLInputElement>) => {
  return (
    <input
      id={props.id}
      ref={ref}
      className={cls(
        'mt-1 focus:ring-indigo-500 dark:bg-dark-faded focus:border-indigo-500 block w-full sm:text-sm border-gray-300 dark:focus:border-gray-400 dark:disabled:text-gray-400 disabled:text-gray-500 disabled:bg-gray-50 dark:border-dark-morefaded dark:focus:text-gray-100 rounded-sm',
        props.className
      )}
      type={props.type}
      name={props.name}
      onChange={props.onChange}
      placeholder={props.placeholder ?? ''}
      value={props.value}
      disabled={props.disabled}
      autoComplete={props.autoComplete}
    />
  );
});

Input.displayName = 'Input';

interface InputRadioProps {
  id?: string;
  type?: string;
  name?: string;
  onChange?: (event: any) => void;
  className?: string;
  value?: string | number;
  disabled?: boolean;
  checked?: boolean;
  ariaDescribedby?: string;
}

export const InputRadio = React.forwardRef(
  (props: InputRadioProps, ref: React.Ref<HTMLInputElement>) => {
    return (
      <input
        id={props.id}
        ref={ref}
        className={cls(
          'h-4 w-4 border-gray-300 dark:border-dark-morefaded text-bobo-prop dark:text-dark-prop dark:bg-dark-faded focus:ring-bobo-prop dark:focus:ring-dark-prop cursor-pointer',
          props.className
        )}
        type="radio"
        name={props.name}
        onChange={props.onChange}
        value={props.value}
        disabled={props.disabled}
        checked={props.checked}
        aria-describedby={props.ariaDescribedby}
      />
    );
  }
);

InputRadio.displayName = 'InputRadio';

interface SelectProps {
  id?: string;
  type?: string;
  name?: string;
  value?: string;
  onChange?: (event: any) => void;
  className?: string;
  disabled?: boolean;
  autoComplete?: string;
  options?: OptionProps[];
}

interface OptionProps {
  code?: string;
  text?: string;
}

export const Select = React.forwardRef((props: SelectProps, ref: React.Ref<HTMLSelectElement>) => (
  <select
    ref={ref}
    id={props.id}
    name={props.name}
    value={props.value}
    onChange={props.onChange}
    autoComplete={props.autoComplete}
    className={cls(
      'mt-1 block w-full py-2 px-3 border dark:bg-dark-faded border-gray-300 bg-white rounded-sm focus:outline-none  dark:border-dark-morefaded dark:text-white focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm cursor-pointer',
      props.className
    )}>
    {props?.options?.map((option: OptionProps) => (
      <option key={option.code} value={option.code}>
        {option.text}
      </option>
    ))}
  </select>
));

Select.displayName = 'Select';

interface CheckboxProps {
  id?: string;
  type?: string;
  name?: string;
  onChange?: (event: any) => void;
  className?: string;
  value?: string | number;
  disabled?: boolean;
  checked?: boolean;
}

export const Checkbox = React.forwardRef(
  (props: CheckboxProps, ref: React.Ref<HTMLInputElement>) => (
    <input
      ref={ref}
      id={props.id}
      name={props.name}
      type="checkbox"
      onChange={props.onChange}
      checked={props.checked}
      value={props.value}
      className={cls(
        'h-4 w-4 text-bobo-prop dark:text-dark-prop border-gray-300 rounded cursor-pointer',
        props.className
      )}
    />
  )
);

Checkbox.displayName = 'Checkbox';
