import { Popover, Transition } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import cls from 'classnames';
import { Fragment, useRef, useState } from 'react';
import { ButtonType } from 'shared/dist/types';

import { useOutsideAlerter } from '@/src/hooks/use-outside-alerter';
import Button from '@/src/widgets/buttons';

type CloseMenuFunction = () => void;
type RenderMenuItemsFunction = (closeMenu: CloseMenuFunction) => JSX.Element[];

export default function DropdownMenu({
  title,
  renderMenuItems,
  className,
  buttonClassName = '',
  buttonType = ButtonType.DROPDOWN,
  disabled = false,
  tooltipText,
  dropdownClassName = ''
}: {
  title: JSX.Element | string;
  renderMenuItems: RenderMenuItemsFunction;
  className?: string;
  buttonClassName?: string;
  buttonType?: ButtonType;
  disabled?: boolean;
  tooltipText?: string;
  dropdownClassName?: string;
}) {
  const [showItems, setShowItems] = useState<boolean>(false);
  const showItemsRef = useRef(null);
  useOutsideAlerter(showItemsRef, setShowItems);

  const closeMenu = () => {
    setShowItems(false);
  };

  return (
    <Popover ref={showItemsRef} className={cls('relative w-full z-30', className && className)}>
      <Button
        popover
        type={buttonType}
        tooltipText={tooltipText && !showItems ? tooltipText : undefined}
        className={cls(buttonClassName, {
          'hover:border-bobo-prop dark:hover:border-dark-prop':
            !showItems && !disabled && buttonType === ButtonType.DROPDOWN,
          'border-b rounded-b-sm': !showItems,
          '!border-b-none': showItems,
          'has-tooltip': tooltipText && !showItems,
          'text-gray-200': disabled
        })}
        onClick={() => setShowItems(!showItems)}
        disabled={disabled}>
        <div className="w-full text-left flex gap-2 items-center">{title}</div>

        <ChevronRightIcon
          className={cls('w-4 h-4 transition-transform duration-200', {
            'rotate-90': showItems
          })}
        />
      </Button>

      <Transition
        as={Fragment}
        show={showItems}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 -translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-1">
        <Popover.Panel className="transform translate-x-0 z-50 w-full">
          <div
            className={cls(
              'bg-white dark:text-white dark:bg-dark-primary text-bobo-black border-x border-b border-gray-200 dark:border-dark-morefaded rounded-b-sm overflow-y-auto shadow-sm flex flex-col items-start text-start top-0 absolute min-w-full',
              dropdownClassName && dropdownClassName,
              { '!-top-0.5': buttonType === ButtonType.DROPDOWN }
            )}>
            {renderMenuItems(closeMenu)}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
