import { Popover, Transition } from '@headlessui/react';
import cls from 'classnames';
import { Trans, useTranslation } from 'next-i18next';
import { Fragment } from 'react';
import { toast } from 'react-hot-toast';
import { ButtonType } from 'shared/dist/types';

import Button from '@/src/widgets/buttons';

export function DeleteCalculationBtn({
  savedCalculation,
  setSavedCalculation,
  savedCalculations,
  setSavedCalculations,
  revertToDefault
}: {
  savedCalculation: any | null;
  setSavedCalculation: any;
  savedCalculations: any[];
  setSavedCalculations: any;
  revertToDefault: any;
}) {
  const { t } = useTranslation();
  const disabledBtn: boolean =
    !savedCalculation ||
    savedCalculations?.filter((calc) => calc.uuid === savedCalculation.uuid).length === 0;

  const deleteCalculation = async () => {
    if (savedCalculation && savedCalculation?.uuid) {
      await fetch('/api/prop/value-calculator/delete', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          uuid: savedCalculation.uuid
        })
      }).then(() => {
        const deletedUuid: string = savedCalculation.uuid;
        const deletedName: string = savedCalculation.name;

        const tmpSavedCalculations = savedCalculations?.filter((calc) => {
          return calc.uuid !== deletedUuid;
        });
        setSavedCalculations(tmpSavedCalculations);
        setSavedCalculation(null);
        revertToDefault();
        toast.success(t('value-calculator.delete-validated', { calculationName: deletedName }));
      });
    }
  };

  return (
    <>
      <Popover className="relative flex justify-end">
        {({ open }) => (
          <>
            <Button
              popover
              disabled={disabledBtn}
              type={open ? ButtonType.CANCEL : ButtonType.DELETE}
              className="w-[80px]">
              {!open ? t('general.delete') : t('general.cancel')}
            </Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1">
              <Popover.Panel className="absolute bottom-1 right-3/3 z-10 mb-[38px] w-screen translate-x-0 transform px-4 sm:px-0 max-w-sm">
                {({ close }) => (
                  <div className="rounded-lg shadow-2xl ring-1 ring-black/5 bg-white dark:bg-dark-morefaded overflow-hidden">
                    <div className="p-6 pb-0 text-base text-gray-800 dark:text-white font-demi">
                      <Trans i18nKey="value-calculator.delete-validate" t={t}>
                        Är du säker på att du vill radera kalkylen{' '}
                        <strong>{{ calculationName: savedCalculation?.name }}</strong>?
                      </Trans>
                    </div>
                    <div className="relative flex justify-between gap-2 items-stretch p-6 z-50">
                      <button
                        onClick={() => {
                          close();
                        }}
                        className={cls(
                          `w-1/2 transition-all cursor-pointer rounded-md bg-gray-50 p-3 duration-200 flex justify-between items-center hover:bg-purple-50 dark:bg-dark-lessfaded dark:hover:bg-purple-900/20`
                        )}>
                        <div className="text-sm text-center w-full">
                          <div>{t('general.cancel')}</div>
                        </div>
                      </button>
                      <div className="border-l dark:border-dark-lessfaded mx-1 flex-1" />
                      <button
                        onClick={() => {
                          deleteCalculation();
                          close();
                        }}
                        className={cls(
                          `w-1/2 transition-all cursor-pointer rounded-md bg-gray-50 dark:bg-dark-lessfaded dark:hover:bg-bobo-bricksred p-3 duration-200 flex justify-between items-center hover:bg-purple-50`
                        )}>
                        <div className="text-sm text-center w-full">
                          <div>{t('general.delete')}</div>
                        </div>
                      </button>
                    </div>
                  </div>
                )}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
}
