export const parseCssRgbString = (input) => {
  const parts = input
    .replace(/rgba?\(([^)]+)\)/, '$1')
    .split(/[,\s/]+/)
    .filter(Boolean);
  if (parts.length < 3) {
    return;
  }

  const parseValue = (value, max) => {
    value = value.trim();

    if (value.endsWith('%')) {
      return Math.min((Number.parseFloat(value) * max) / 100, max);
    }

    return Math.min(Number.parseFloat(value), max);
  };

  const red = parseValue(parts[0], 255);
  const green = parseValue(parts[1], 255);
  const blue = parseValue(parts[2], 255);

  return rgbToHex(red, green, blue);
};

function componentToHex(c) {
  const hex = c.toString(16);
  return hex.length == 1 ? '0' + hex : hex;
}

export function rgbToHex(r, g, b) {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export const asc = (arr) => arr.sort((a, b) => a - b);

export const quantile = (arr, q) => {
  const sorted = asc(arr);
  const pos = (sorted.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
  } else {
    return sorted[base];
  }
};
