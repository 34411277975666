import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { getCategoryName } from 'shared/dist/constants';
import {
  ButtonType,
  CalculationResult,
  PropertyTypeGroup,
  RadioButtonOptions,
  ValueCategoryTypes
} from 'shared/dist/types';
import {
  CashflowAdjustableAssumptions,
  CashflowInflation,
  CashflowLease,
  CashflowSelectedIndexAssumption,
  CashflowValuationAsset,
  CashflowValuationModel,
  IndexType,
  InflationType,
  PropertyTax
} from 'shared/dist/types/cashflow/cashflow-model';
import { CashflowLeaseTemplateProps } from 'shared/dist/types/cashflow/general';
import { NewsecCategory } from 'shared/dist/types/rent';
import { v4 as uuidv4 } from 'uuid';
import * as z from 'zod';

import { Error, Scooter } from '@/src/animations';
import Button from '@/src/widgets/buttons';

import ModalWarning from '../../../layout/global-dialog/items/modal-warning';
import { DeleteType } from '../../cashflow-valuation/cashflow-dialog-switcher';
import {
  checkIfUnsavedChanges,
  getUpdatedModel,
  setInitialValuesIfExistingAsset,
  setInitialValuesIfNewAsset,
  setNewArea,
  setNewAssetCategory,
  setNewLease,
  setNewSliderValue,
  updateResultAtValueChange
} from '../../cashflow-valuation/constants/calculator-functions';
import { DEFAULT_INFLATION_UUID } from '../../cashflow-valuation/constants/cashflow-theme';
import AssumptionsCalculations from '../../cashflow-valuation/items/assumptions-calculations';
import { getDefaultValues } from '../../valuation-tool/items/functions';
import TabHeader from '../../valuation-tool/items/tab-header';
import { crmDialogTheme } from '../crm/dialog-theme';
import CashflowInflationTemplate from './cashflow-add-inflation';
import CashflowLeaseTemplate from './cashflow-add-lease';
import CashflowDeleteModel from './cashflow-delete-model';

const missingFieldWarning = 'property.cashflow-valuation.lease.input-errors.string';
const assetSchema = z.object({
  name: z.string().min(1, missingFieldWarning),
  area: z.number().min(1, missingFieldWarning),
  leaseArea: z.number().min(1, missingFieldWarning),
  leaseCount: z.number().min(1, missingFieldWarning)
});

const validateAssetInput = (input: {
  name: string;
  area: number;
  leaseArea: number;
  leaseCount: number;
}): { valid: boolean; errors: Record<string, string> } => {
  const result = assetSchema.safeParse(input);

  if (result.success) {
    return { valid: true, errors: {} };
  }

  const errors: Record<string, string> = {};
  result.error.errors.forEach((error) => {
    const path = error.path.join('.');
    errors[path] = error.message;
  });

  return { valid: false, errors };
};

export const stepNames = {
  initialAdd: 1,
  loading: 3,
  error: 4,
  lease: 5,
  delete: 6,
  inflation: 7
};

export type InflationOption = {
  inflation: CashflowInflation | undefined;
  indexType: IndexType;
};

export default function AddUpdateAsset({ object, close }: { object: any; close: () => void }) {
  const { model, property, selectedAsset, setSelectedModel, refresh } = object;
  const { t } = useTranslation();
  const [reload, setReload] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [showUnsavedText, setShowUnsavedText] = useState<boolean>(false);

  const [assetValues, setAssetValues] = useState<CashflowAdjustableAssumptions | null>(null);
  const [result, setResult] = useState<CalculationResult>({
    [ValueCategoryTypes.YIELD]: 0,
    [ValueCategoryTypes.VACANCY]: 0,
    [ValueCategoryTypes.RENT]: 0,
    [ValueCategoryTypes.COSTS]: 0,
    [ValueCategoryTypes.MAINTENANCE]: 0
  });
  const [assetName, setAssetName] = useState<string>(t(getCategoryName(NewsecCategory.HOUSING)));
  const [selectedLease, setSelectedLease] = useState<CashflowLease | null>(null);
  const [selectedInflation, setSelectedInflation] = useState<InflationOption | null>(null);
  const [rentIndexOptions, setRentIndexOptions] = useState<CashflowInflation[]>(model.rentIndexes);
  const [costIndexOptions, setCostIndexOptions] = useState<CashflowInflation[]>(model.costIndexes);
  const [updatedAssets, setUpdatedAssets] = useState<CashflowValuationAsset[]>(model.assets);
  const [errorState, setErrorState] = useState<any>({});
  const componentRef = useRef<HTMLDivElement>(null);
  const apartmentCount = property?.apartmentCount ?? 0;

  const scrollToTop = () => {
    if (componentRef.current) {
      componentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const assetUuid = selectedAsset?.uuid ?? uuidv4();
  const defaultValues = getDefaultValues(property, t);

  const closeModal = () => {
    if (step === stepNames.lease || step === stepNames.inflation) {
      setStep(stepNames.initialAdd);
    } else if (unsavedChanges && !showUnsavedText) {
      setShowUnsavedText(true);
    } else {
      close();
      if (reload) {
        refresh();
      }
    }
  };

  useEffect(() => {
    if (selectedAsset) {
      setInitialValuesIfExistingAsset(selectedAsset, setAssetValues, setAssetName, defaultValues);
      return;
    }
    setInitialValuesIfNewAsset(property, assetUuid, setAssetValues, t);
  }, [selectedAsset]);

  useEffect(() => {
    if (!assetValues) {
      return;
    }
    updateResultAtValueChange(assetValues, setResult);
    checkIfUnsavedChanges(assetValues, assetName, setUnsavedChanges, selectedAsset);
  }, [assetValues]);

  const changeArea = (_, newArea: number) => {
    if (!assetValues) {
      return;
    }
    setNewArea(assetValues, newArea, setAssetValues);
  };

  const changeTabName = (_, newName) => {
    setAssetName(newName);
  };

  const changeAssetCategory = (newCategory: NewsecCategory) => {
    if (!assetValues) {
      return;
    }
    setNewAssetCategory(
      assetValues,
      property,
      newCategory,
      assetUuid,
      setAssetValues,
      setAssetName,
      t
    );
  };

  const changeValue = (
    tabName: string,
    valueType: ValueCategoryTypes,
    newValue: number,
    radioButton?: RadioButtonOptions,
    newsecCategory?: NewsecCategory,
    flCategory?: PropertyTypeGroup,
    forumCategory?: PropertyTypeGroup
  ) => {
    if (!assetValues) {
      return;
    }
    const newSliderValue = {
      value: newValue,
      category: valueType,
      radioButton: radioButton,
      selectedNewsecCategory: newsecCategory,
      selectedForumCategory: forumCategory,
      selectedFlCategory: flCategory
    };
    setNewSliderValue(assetValues, newSliderValue, setAssetValues);
  };

  const changePropertyTax = (taxType, taxValue, countPropertyTax?) => {
    if (!assetValues) {
      return;
    }
    const newTax: PropertyTax = {
      propertyTaxType: taxType,
      taxValue: taxValue,
      countPropertyTax: countPropertyTax ?? assetValues.propertyTax.countPropertyTax
    };
    setAssetValues({ ...assetValues, propertyTax: newTax });
  };

  const saveLease = (updatedRentRoll: CashflowLease[]) => {
    if (!assetValues) {
      return;
    }
    setNewLease(assetValues, updatedRentRoll, assetName, assetUuid, setAssetValues);
    setStep(stepNames.initialAdd);
  };

  const viewLease = (lease?: CashflowLease | undefined) => {
    setSelectedLease(lease ?? null);
    setStep(stepNames.lease);
  };

  const saveInflation = (
    updatedModel: CashflowValuationModel,
    updatedInflation: CashflowInflation,
    indexType: IndexType,
    selectedAssetUuids: string[]
  ) => {
    setRentIndexOptions(updatedModel.rentIndexes);
    setCostIndexOptions(updatedModel.costIndexes);
    setUpdatedAssets(updatedModel.assets);

    const isChanged: boolean = selectedAssetUuids.some(
      (selectedAsset) => selectedAsset === assetUuid
    );

    if (isChanged) {
      const indexOptions =
        indexType === IndexType.RENT ? updatedModel.rentIndexes : updatedModel.costIndexes;

      const isInIndexOptions: boolean = indexOptions.some((i) => i.uuid === updatedInflation.uuid);

      const updatedAssetInflation: CashflowSelectedIndexAssumption = {
        uuid: isInIndexOptions ? updatedInflation.uuid : DEFAULT_INFLATION_UUID,
        type: indexType === IndexType.RENT ? InflationType.DISCRETE : InflationType.CONTINUOUS
      };

      changeInflation(updatedAssetInflation, indexType);
    }

    setSelectedInflation(null);
    setStep(stepNames.initialAdd);
  };

  const goToInflationModal = (inflation: CashflowInflation | undefined, indexType: IndexType) => {
    setSelectedInflation({ inflation: inflation ?? undefined, indexType: indexType });
    setStep(stepNames.inflation);
  };

  const changeInflation = (inflation: CashflowSelectedIndexAssumption, indexType: IndexType) => {
    if (!assetValues) {
      return;
    }

    const updatedIndex = {
      ...(indexType === IndexType.RENT ? assetValues.rentIndex : assetValues.costIndex),
      uuid: inflation.uuid
    };
    setAssetValues({
      ...assetValues,
      rentIndex: indexType === IndexType.RENT ? updatedIndex : assetValues.rentIndex,
      costIndex: indexType === IndexType.RENT ? assetValues.costIndex : updatedIndex
    });
  };

  const handleSave = async () => {
    if (!model) {
      toast.error(t('property.cashflow-valuation.error-no-model'));
      return;
    }

    if (!assetValues) {
      return;
    }

    const isCommercial: boolean = assetValues.type !== NewsecCategory.HOUSING;
    const totalLeaseArea: number = assetValues.rentRoll.reduce((a, b) => a + b.area, 0);
    const leaseCount: number = assetValues.rentRoll.length;
    const { valid, errors } = validateAssetInput({
      name: assetName,
      area: assetValues.area,
      leaseArea: isCommercial ? totalLeaseArea : 1,
      leaseCount: isCommercial ? leaseCount : 1
    });

    if (!valid) {
      setErrorState(errors);
      scrollToTop();
      return;
    }

    setReload(true);
    setStep(stepNames.loading);

    const newAssetName: string =
      t(getCategoryName(assetName)).length > 0 ? t(getCategoryName(assetName)) : assetName;

    const updatedModel = getUpdatedModel(
      assetValues,
      result,
      { ...model, assets: updatedAssets },
      newAssetName,
      assetUuid,
      selectedAsset,
      rentIndexOptions,
      costIndexOptions
    );

    if (setSelectedModel) {
      await setSelectedModel(updatedModel);
      toast.success(
        t('property.cashflow-valuation.assumptions.saved-property', { propType: newAssetName })
      );
      close();
      refresh();
    } else {
      setStep(stepNames.error);
    }
  };

  let mod: JSX.Element = <div />;

  if (assetValues) {
    switch (step) {
      case stepNames.initialAdd:
        mod = (
          <div
            ref={componentRef}
            className="z-30 max-h-[calc(100vh-100px)] overflow-auto -mx-6 px-6">
            {showUnsavedText ? (
              <ModalWarning
                text={t('property.cashflow-valuation.lease.not-saved-text')}
                setShowWarning={setShowUnsavedText}
                close={close}
                button={{ text: t('general.save'), function: handleSave }}
              />
            ) : null}

            <div className="w-full relative flex justify-center">
              <h3 className="text-base font-bold text-gray-900 dark:text-white sm:text-lg uppercase mb-6">
                {t(
                  `property.cashflow-valuation.assumptions.${
                    selectedAsset ? 'modify-property' : 'add-new-property'
                  }`,
                  {
                    propertyName:
                      t(getCategoryName(assetName)).length > 0
                        ? t(getCategoryName(assetName))
                        : assetName
                  }
                )}
              </h3>

              {assetValues.area === 0 ? (
                <div className="absolute top-6 text-xs text-gray-400">
                  {t('property.cashflow-valuation.add-area-to-asset')}
                </div>
              ) : null}
            </div>

            <TabHeader
              totalResult={[{ tabName: assetName, area: assetValues.area }]}
              selectedTab={assetName}
              deleteTab={() => setStep(stepNames.delete)}
              changeArea={changeArea}
              changeTabName={changeTabName}
              setNewCategory={changeAssetCategory}
              selectedCategory={assetValues.type}
              area={assetValues.area}
              setArea={(area) => setAssetValues({ ...assetValues, area: area })}
              cashflow
              setInflation={changeInflation}
              goToInflationModal={goToInflationModal}
              rentIndex={{ options: rentIndexOptions, selected: assetValues.rentIndex.uuid }}
              costIndex={{ options: costIndexOptions, selected: assetValues.costIndex.uuid }}
              property={property}
              errorState={errorState}
              handleSave={handleSave}
            />

            <AssumptionsCalculations
              values={assetValues.sliderValues}
              defaultValues={defaultValues}
              changeValue={changeValue}
              result={result}
              area={assetValues.area}
              propType={assetValues.type}
              rentRoll={assetValues.rentRoll}
              tabName={assetName}
              countPropertyTax={assetValues.propertyTax.countPropertyTax}
              setCountPropertyTax={(newValue) =>
                changePropertyTax(
                  assetValues.propertyTax.propertyTaxType,
                  assetValues.propertyTax.taxValue,
                  newValue
                )
              }
              tax={assetValues.propertyTax.propertyTaxType}
              taxValue={assetValues.propertyTax.taxValue}
              changePropertyTax={changePropertyTax}
              viewLease={viewLease}
              errorState={errorState}
              apartmentCount={apartmentCount}
            />

            <div className="flex justify-between items-end mt-6">
              <Button type={ButtonType.CANCEL} onClick={closeModal}>
                {t('general.cancel')}
              </Button>

              <div className="flex gap-2">
                {selectedAsset && (
                  <Button type={ButtonType.DELETE} onClick={() => setStep(stepNames.delete)}>
                    {t('general.delete')}
                  </Button>
                )}

                <Button onClick={handleSave}>{t('general.save')}</Button>
              </div>
            </div>
          </div>
        );
        break;

      case stepNames.loading:
        mod = (
          <div className={crmDialogTheme.container}>
            <div className={crmDialogTheme.image}>
              <Scooter />
            </div>
            <div className={crmDialogTheme.textContainer}>
              <Dialog.Title as="h3" className={crmDialogTheme.title}>
                {t('general.saving-info')}
              </Dialog.Title>
            </div>
          </div>
        );
        break;

      case stepNames.error:
        mod = (
          <div className={crmDialogTheme.container}>
            <div className={crmDialogTheme.icon}>
              <Error />
            </div>
            <div className={crmDialogTheme.textContainer}>
              <Dialog.Title as="h3" className={crmDialogTheme.title}>
                <div className={crmDialogTheme.errorTitle}>{t('errors.something-went-wrong')}</div>
              </Dialog.Title>

              <p className={crmDialogTheme.subtext}>{t('errors.try-again')}</p>
            </div>

            <div className="flex justify-between items-end mt-6">
              <Button type={ButtonType.CANCEL} onClick={closeModal}>
                {t('general.cancel')}
              </Button>

              <Button onClick={() => setStep(stepNames.initialAdd)}>{t('general.back')}</Button>
            </div>
          </div>
        );
        break;

      case stepNames.lease:
        mod = (
          <div className="h-full w-full px-44">
            <CashflowLeaseTemplate
              object={
                {
                  lease: selectedLease ?? undefined,
                  model: model,
                  save: saveLease,
                  property: property
                } as CashflowLeaseTemplateProps
              }
              close={() => setStep(1)}
              activeAsset={{
                name: assetName,
                uuid: assetUuid,
                rentRoll: assetValues.rentRoll,
                area: assetValues.area ?? 0
              }}
              setReload={setReload}
            />
          </div>
        );
        break;

      case stepNames.inflation:
        mod = (
          <div className="h-full w-full px-44">
            <CashflowInflationTemplate
              close={() => setStep(stepNames.initialAdd)}
              object={{
                model: {
                  ...model,
                  rentIndexes: rentIndexOptions,
                  costIndexes: costIndexOptions,
                  assets: model.assets.map((asset) => {
                    if (asset.uuid !== assetUuid) {
                      return asset;
                    }
                    return { ...asset, adjustableAssumptions: assetValues, name: assetName };
                  })
                },
                inflation: selectedInflation?.inflation ?? undefined,
                save: saveInflation,
                indexType: selectedInflation?.indexType,
                newAsset: selectedAsset
                  ? undefined
                  : { name: assetName, uuid: assetUuid, adjustableAssumptions: assetValues }
              }}
              inAssetModal={true}
            />
          </div>
        );
        break;

      case stepNames.delete:
        mod = (
          <div className="h-full w-full px-80">
            <CashflowDeleteModel
              close={close}
              model={model}
              setSelectedModel={setSelectedModel}
              deletedObject={{ ...selectedAsset, deleteType: DeleteType.ASSET }}
              setReload={setReload}
              goBack={() => setStep(stepNames.initialAdd)}
            />
          </div>
        );
    }
  }

  return (
    <>
      <button onClick={closeModal} className={crmDialogTheme.closeContainerX}>
        <XMarkIcon className="h-5 w-5" />
      </button>
      <div className="w-full">{property && mod}</div>
    </>
  );
}
