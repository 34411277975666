import {
  ArrowRightCircleIcon,
  BriefcaseIcon,
  BuildingOffice2Icon,
  CubeIcon,
  MapPinIcon
} from '@heroicons/react/24/solid';
import cls from 'classnames';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { PROP_TYPES } from 'shared/dist/constants';
import { GotoFeature } from 'shared/dist/types';

import { useSlideIn } from '@/src/context/slide-in-context';
import Tooltip from '@/src/layout/components/tooltip';
import WrapSection from '@/src/layout/section-wrapper';
import { getColorForPropertyType } from '@/src/lib/colors';
import {
  TYPES_HOUSING,
  TYPES_INDUSTRY,
  TYPES_OFFICE,
  TYPES_SPECIAL,
  TYPES_STREET,
  TYPES_WAREHOUSE
} from '@/src/lib/constants';

import { FinancialTable } from './financialTable';
import { OverviewInfoContact } from './informationTable';

export const getPropType = (taxCode) => {
  if (taxCode && TYPES_HOUSING.includes(taxCode)) {
    return 'housing';
  } else if (TYPES_OFFICE.includes(taxCode)) {
    return 'space';
  } else if (TYPES_WAREHOUSE.includes(taxCode)) {
    return 'warehouse';
  } else if (TYPES_SPECIAL.includes(taxCode)) {
    return 'special';
  } else if (TYPES_INDUSTRY.includes(taxCode)) {
    return 'industry';
  } else if (TYPES_STREET.includes(taxCode)) {
    return 'street';
  } else {
    return 'private';
  }
};

export const getOrgNr = (actor): string | null => {
  if (!actor.actor_id || actor.actor_id.length !== 10) {
    return null;
  }
  return actor.actor_id.slice(0, 6) + '-' + actor.actor_id.slice(6);
};

export function OverviewPortfolioSimple({
  companyData,
  corparateStructure,
  close,
  slideIn,
  companySlideIn = false,
  compact = false
}: {
  companyData: any;
  corparateStructure: any;
  close?: () => void;
  slideIn?: boolean;
  companySlideIn?: boolean;
  compact?: boolean;
}) {
  const [propTypes, setPropTypes] = useState<any[] | null>(null);
  const [hasSpecialProps, setHasSpecialProps] = useState<number>(0);

  const router = useRouter();

  const { setSlideIn } = useSlideIn();

  const { t } = useTranslation('common');

  const activeNode: any = corparateStructure?.find(
    (a) => a.actor_id === companyData.actor.actor_id
  );

  const activeNodeProperties: any = corparateStructure?.filter(
    (a) => a.parent_actor_id === activeNode?.actor_id && a.obj_type === 'property'
  );

  useEffect(() => {
    const pTypes: any[] = [];

    let totalCount = 0;
    let hasSpecial = 0;

    activeNodeProperties?.map((a) => {
      a.tax_codes?.map(() => {
        totalCount++;
      });
    });

    if (!totalCount) {
      totalCount = 1;
    }

    activeNodeProperties?.map((a) => {
      a.tax_codes?.map((tc) => {
        const propType: string = getPropType(tc);

        const idx: number = pTypes?.findIndex((a) => a?.type === propType);

        if (propType === 'special') {
          hasSpecial++;
        }

        if (idx < 0) {
          pTypes.push({
            type: propType,
            count: 1,
            percentage: Math.round((1 / totalCount) * 100),
            desc: t(PROP_TYPES[propType])
          });
        } else {
          let tmp = pTypes[idx];

          const count = tmp?.count + 1;

          tmp = {
            ...tmp,
            count: tmp.count + 1,
            percentage: Math.round((count / totalCount) * 100)
          };
          pTypes[idx] = tmp;
        }
      });
    });

    setHasSpecialProps(hasSpecial);
    setPropTypes(pTypes);
  }, [corparateStructure]);

  const companyName: string = companyData.actor.company_name ?? companyData.actor.name ?? '';
  const isPrivateOwner: boolean =
    companyData.actor.actor_id?.length > 10 || companyData.actor.first_name;

  const isRootActor = companyData.actor.level === 1;
  const hasRootActor = companyData.actor.level > 1 && activeNode;

  const orgNr = getOrgNr(companyData.actor);

  return (
    <>
      <WrapSection
        title={
          <button
            disabled={companySlideIn}
            onClick={() => {
              setSlideIn({
                type: GotoFeature.COMPANY,
                object: companyData.actor
              });
            }}
            className={cls('px-2 py-1  text-left leading-3', {
              'hover:text-bobo-prophover text-bobo-prop dark:hover:text-gray-300 dark:text-white':
                !companySlideIn,
              'dark:text-white ': activeNode?.direct_prop_count == 0
            })}>
            <div className="text-xs font-demi flex gap-2 items-center justify-start has-tooltip relative">
              {companyName?.length > 35 ? <Tooltip className="top-4">{companyName}</Tooltip> : null}
              {companyName?.length > 35 ? `${companyName?.substring(0, 35)}...` : `${companyName}`}
              {!companySlideIn ? <ArrowRightCircleIcon className="w-4 h-4" /> : null}
            </div>

            {orgNr && (
              <div className="text-mini text-gray-400">
                {t('company.org-nr', { org_nr: getOrgNr(companyData.actor) })}
              </div>
            )}
          </button>
        }
        theme={'compact'}>
        <div className="-mt-2 w-full">
          {(!companyData.actor.is_root ||
            (companyData.actor.is_root && activeNode?.direct_prop_count > 0)) &&
          !isPrivateOwner &&
          activeNode?.root_prop_count > 0 ? (
            <button
              onClick={() => {
                router
                  .push(
                    `/avancerad/sok?owner_actor=${companyData.actor.actor_id}&mode=directOwned&to=list`,
                    '/avancerad/sok',
                    { shallow: true }
                  )
                  .then(() => {
                    if (slideIn) {
                      close && close();
                    }
                  });
              }}
              disabled={!activeNode?.direct_prop_count || activeNode?.direct_prop_count < 1}
              className={cls(
                'text-gray-500 w-full flex transition-colors duration-300 p-2 border-b border-gray-100 dark:border-dark-morefaded group',
                {
                  'hover:bg-bobo-proplight dark:hover:bg-dark-prophover !text-bobo-prop dark:text-white dark:!text-dark-prop dark:hover:!text-white':
                    activeNode?.direct_prop_count > 0
                }
              )}>
              <div className="w-8">
                <BuildingOffice2Icon
                  className={cls(
                    'h-4 w-4 transition-colors duration-300 text-bobo-prop dark:text-dark-prop dark:group-hover:text-white',
                    {
                      '!text-bobo-prop dark:!text-dark-prop dark:group-hover:!text-white':
                        companyData.actor.direct_prop_count > 0
                    }
                  )}
                />
              </div>

              <div className={cls('flex-1 text-left')}>
                {activeNode?.direct_prop_count && activeNode?.direct_prop_count > 0
                  ? t('company.direct-owned-properties')
                  : t('company.no-direct-owned-properties')}
              </div>

              {activeNode?.direct_prop_count && activeNode?.direct_prop_count > 0 ? (
                <div className="font-normal">
                  {t('general.x-count', { amount: activeNode?.direct_prop_count ?? 0 })}
                </div>
              ) : null}
            </button>
          ) : null}
          {activeNode?.child_actors?.length > 0 &&
          !isPrivateOwner &&
          activeNode?.root_prop_count > 0 &&
          (hasRootActor || isRootActor) ? (
            <button
              onClick={() => {
                router
                  .push(
                    `/avancerad/sok?owner_actor=${
                      companyData.actor.root_actor_id ?? companyData.actor.actor_id
                    }&mode=structureOwned&to=list`,
                    '/avancerad/sok',
                    { shallow: true }
                  )
                  .then(() => {
                    if (slideIn) {
                      close && close();
                    }
                  });
              }}
              disabled={!activeNode?.direct_prop_count || activeNode?.direct_prop_count < 1}
              className={cls(
                'dark:text-white w-full flex transition-colors duration-300 p-2 even:bg-gray-50 dark:even:bg-dark-faded border-b border-gray-100 dark:border-dark-morefaded group last:border-none',
                {
                  'hover:bg-bobo-proplight dark:hover:bg-dark-prophover !text-bobo-prop dark:text-white dark:!text-dark-prop dark:hover:!text-white':
                    activeNode?.direct_prop_count > 0
                }
              )}>
              <div className="w-8">
                <BuildingOffice2Icon
                  className={cls(
                    'h-4 w-4 transition-colors duration-300',
                    {
                      'text-bobo-prop dark:text-dark-prop dark:group-hover:text-white':
                        activeNode?.direct_prop_count > 0
                    },
                    {
                      'text-gray-500':
                        !activeNode?.direct_prop_count || activeNode?.direct_prop_count < 1
                    }
                  )}
                />
              </div>

              <div className="flex-1 text-left">{t('property.owner.properties-in-structure')}</div>

              <div className="font-normal">
                {t('general.x-count', { amount: activeNode?.root_prop_count ?? 0 })}
              </div>
            </button>
          ) : null}
          {!compact &&
          !companyData.isPrivatePerson &&
          activeNode?.root_prop_count > 0 &&
          (hasRootActor || isRootActor) ? (
            <div className="w-full flex p-2 even:bg-gray-50 dark:even:bg-dark-faded border-b border-gray-100 dark:border-dark-morefaded last:border-none">
              {activeNode?.child_actors?.length > 0 ? (
                <>
                  <div className="w-8">
                    <BuildingOffice2Icon className="h-4 w-4 text-gray-500" />
                  </div>

                  <div className="flex-1">
                    {t('property.owner.properties-including-child-companies')}
                  </div>

                  <div>{t('general.x-count', { amount: activeNode?.level_prop_count })}</div>
                </>
              ) : (
                <>
                  <div className="w-8">
                    <BriefcaseIcon className="h-4 w-4 text-gray-500 dark:text-white" />
                  </div>

                  <div className="flex-1">
                    {t('property.owner.no-children-in', { company: activeNode?.name })}
                  </div>

                  <div></div>
                </>
              )}
            </div>
          ) : null}
          {activeNode?.level_boa > 0 ? (
            <div className="w-full flex justify-between gap-2 p-2 even:bg-gray-50 dark:even:bg-dark-faded border-b border-gray-100 dark:border-dark-morefaded last:border-none">
              <div className="flex">
                <div className="w-8">
                  <CubeIcon className="h-4 w-4 text-gray-500 dark:text-gray-500" />
                </div>

                <div className="flex-1">
                  {hasRootActor || isRootActor ? (
                    <Trans i18nKey={'property.owner.total-area'} t={t}>
                      Total {{ areaType: t('general.boa') }}{' '}
                      <span className="italic">(inklusive dotterbolag)</span>
                    </Trans>
                  ) : (
                    t('general.total-recidential-area')
                  )}
                </div>
              </div>

              <div className="font-normal">
                <NumberFormat
                  value={activeNode?.direct_boa ?? 0}
                  displayType={'text'}
                  suffix={t('general.square-meters')}
                  thousandSeparator={' '}
                />
                {hasRootActor || isRootActor ? (
                  <>
                    {' '}
                    <span className="italic">
                      (
                      <NumberFormat
                        value={activeNode?.level_boa ?? 0}
                        displayType={'text'}
                        suffix={t('general.square-meters')}
                        thousandSeparator={' '}
                      />
                      )
                    </span>
                  </>
                ) : null}
              </div>
            </div>
          ) : null}
          {activeNode?.level_loa > 0 ? (
            <div className="w-full flex justify-between gap-2 p-2 even:bg-gray-50 dark:even:bg-dark-faded border-b border-gray-100 dark:border-dark-morefaded last:border-none">
              <div className="flex">
                <div className="w-8">
                  <CubeIcon className="h-4 w-4 text-gray-500" />
                </div>

                <div className="flex-1">
                  {hasRootActor || isRootActor ? (
                    <Trans i18nKey={'property.owner.total-area'} t={t}>
                      Total {{ areaType: t('general.loa') }}{' '}
                      <span className="italic">(inklusive dotterbolag)</span>
                    </Trans>
                  ) : (
                    t('taxation.total-leasable-area')
                  )}

                  {hasSpecialProps > 0 ? (
                    <div className="text-mini text-bobo-council">
                      {t('property.owner.not-including', { amount: hasSpecialProps })}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="font-normal">
                <NumberFormat
                  value={activeNode?.direct_loa ?? 0}
                  displayType={'text'}
                  suffix={t('general.square-meters')}
                  thousandSeparator={' '}
                />
                {hasRootActor || isRootActor ? (
                  <>
                    {' '}
                    <span className="italic">
                      (
                      <NumberFormat
                        value={activeNode?.level_loa ?? 0}
                        displayType={'text'}
                        suffix={t('general.square-meters')}
                        thousandSeparator={' '}
                      />
                      )
                    </span>
                  </>
                ) : null}
              </div>
            </div>
          ) : null}
          {!isPrivateOwner &&
          (activeNode?.level_land_area > 0 || activeNode?.direct_land_area > 0) ? (
            <div className="w-full flex justify-between gap-2 p-2 even:bg-gray-50 dark:even:bg-dark-faded border-b last:border-none border-gray-100 dark:border-dark-morefaded">
              <div className="flex">
                <div className="w-8">
                  <MapPinIcon className="h-4 w-4 text-gray-500" />
                </div>

                <div className="flex-1 whitespace-nowrap">
                  {hasRootActor || isRootActor ? (
                    <Trans i18nKey={'property.owner.total-area'} t={t}>
                      Total {{ areaType: t('property.plot-area-lower') }}{' '}
                      <span className="italic">(inklusive dotterbolag)</span>
                    </Trans>
                  ) : (
                    t('general.total-land-area')
                  )}
                </div>
              </div>

              <div className="font-normal whitespace-nowrap">
                <NumberFormat
                  value={activeNode?.direct_land_area ?? 0}
                  displayType={'text'}
                  suffix={t('general.square-meters')}
                  thousandSeparator={' '}
                />
                {hasRootActor || isRootActor ? (
                  <>
                    {' '}
                    <span className="italic">
                      (
                      <NumberFormat
                        value={activeNode?.level_land_area ?? 0}
                        displayType={'text'}
                        suffix={t('general.square-meters')}
                        thousandSeparator={' '}
                      />
                      )
                    </span>
                  </>
                ) : null}
              </div>
            </div>
          ) : null}
          {propTypes && propTypes?.length ? (
            <div
              className={cls(
                'border-gray-100 even:bg-gray-50 dark:even:bg-dark-faded border-b last:border-none dark:border-dark-morefaded w-full p-2 rounded-md my-2'
                // { 'pb-0': slideIn },
                // { 'pb-0': !slideIn }
              )}>
              <div className="flex w-full h-5 rounded-md">
                {propTypes &&
                  propTypes.map((type) => {
                    const { color, bgColor } = getColorForPropertyType(type.type);
                    const percentage: number = type.percentage;
                    const width = `${percentage}%`;

                    return (
                      <div
                        key={type.type}
                        style={{ backgroundColor: bgColor, width: width }}
                        className="flex items-center cursor-pointer first:rounded-l-md last:rounded-r-md relative justify-center h-[100%] bg-[rgba(83,139,231,0.2)] has-tooltip">
                        <div
                          key={type.type}
                          style={{ backgroundColor: bgColor }}
                          className="rounded-md tooltip -mt-12 items-center flex mr-1 mb-1 transition-color duration-200 text-[9px] leading-[9px] whitespace-nowrap py-[3px] px-2 h-5">
                          <div
                            style={{ backgroundColor: color }}
                            className="h-2 w-2 rounded-full mr-1"></div>

                          <div>{type.desc}</div>
                        </div>

                        {percentage > 5 ? (
                          <>
                            <div
                              style={{ backgroundColor: color }}
                              className="h-2 w-2 rounded-full mr-1"></div>

                            <div className={cls(slideIn ? 'text-[10px]' : 'text-[10px]')}>
                              {percentage}%
                            </div>
                          </>
                        ) : null}
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : null}
          {!compact ? (
            <FinancialTable companyData={companyData} corparateStructure={corparateStructure} />
          ) : null}
          <OverviewInfoContact companyData={companyData} slideIn={true} />
        </div>
      </WrapSection>
    </>
  );
}
