import { useTranslation } from 'next-i18next';
import NumberFormat from 'react-number-format';
import { PropertyTypeGroup } from 'shared/dist/types';
import { checkRange } from 'shared/dist/util/rent';

export function FLSqm({ value }: { value: number }) {
  const { t } = useTranslation('common');
  return (
    <NumberFormat
      displayType="text"
      thousandSeparator={' '}
      suffix={t('general.square-meters')}
      value={value}
    />
  );
}

export function FLAmount({ value }: { value: number }) {
  const { t } = useTranslation('common');
  return (
    <NumberFormat
      displayType="text"
      thousandSeparator={' '}
      suffix={t('general.kr-suffix')}
      prefix={t('general.sek-prefix')}
      value={value}
    />
  );
}

export function FLCustom({
  value,
  suffix = '',
  prefix = ''
}: {
  value: number;
  suffix?: string;
  prefix?: string;
}) {
  return (
    <NumberFormat
      displayType="text"
      prefix={prefix}
      thousandSeparator={' '}
      suffix={suffix}
      value={value}
    />
  );
}

export default function ShowRent({
  value,
  suffix,
  propType
}: {
  value: number;
  suffix: string | null;
  propType: PropertyTypeGroup;
}) {
  const { t } = useTranslation('common');

  if (value > 0) {
    const outOfRange = checkRange(propType, value);

    if (outOfRange) {
      return <span>{t('property.missing-rent-data')}</span>;
    } else {
      return <FLCustom value={value} suffix={suffix ? suffix : ''} />;
    }
  } else {
    return <span>{t('property.no-rent-data-for-property')}</span>;
  }
}
