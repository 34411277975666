import Slider from '@mui/material/Slider';
import cls from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';

import { getLayerColor } from '@/src/lib/colors';

export const WaterLevelSlider = ({ showSlider, risk }: { showSlider: boolean; risk: any }) => {
  let initialLevel = 0;

  if (risk && risk.layers?.length > 0 && risk.layers[0]) {
    try {
      initialLevel = parseInt(risk.layers[0]?.replace(`kust_`, ''));
      initialLevel = initialLevel / 10;
    } catch (e) {
      console.error(`Failed to parse initial level of coastal flooding`, e);
    }
  }

  const [level, setLevel] = useState<string>(`${initialLevel}`);

  const { t } = useTranslation('common');
  const { riskMap } = useMap();

  const [waterLevel, setWaterLevel] = useState<number>(initialLevel);

  function valuetext(value: number) {
    return `${value}°C`;
  }

  const showFloodingLayers = (flooding_layer) => {
    if (riskMap && level !== flooding_layer) {
      riskMap.getMap().getLayer(`${level}`) && riskMap.getMap().removeLayer(`${level}`);
      riskMap.getMap().getLayer(`${level}-line`) && riskMap.getMap().removeLayer(`${level}-line`);

      if (flooding_layer === `kust_0`) return;

      const sourceName = `bobolaget.flooding-coastal`;

      !riskMap.getMap().getSource(sourceName) &&
        riskMap.getMap().addSource(sourceName, {
          type: 'vector',
          maxzoom: 22,
          url: `mapbox://${sourceName}`
        });

      !riskMap.getMap().getLayer(`${flooding_layer}`) &&
        riskMap.getMap().addLayer(
          {
            id: `${flooding_layer}`,
            type: 'fill',
            source: `${sourceName}`,
            'source-layer': `${flooding_layer}`,
            paint: {
              'fill-color': getLayerColor(flooding_layer),
              'fill-opacity': 0.3
            },
            layout: {
              // visibility: 'none'
            }
          },
          'border'
        );

      !riskMap.getMap().getLayer(`${flooding_layer}-line`) &&
        riskMap.getMap().addLayer(
          {
            id: `${flooding_layer}-line`,
            type: 'line',
            source: `${sourceName}`,
            'source-layer': `${flooding_layer}`,
            paint: {
              'line-color': getLayerColor(flooding_layer),
              'line-opacity': 0.8,
              'line-width': 1
            },
            layout: {
              // visibility: 'none'
            }
          },
          'border'
        );

      setLevel(flooding_layer);
    }
  };

  useEffect(() => {
    if (initialLevel > 0) {
      showFloodingLayers(`kust_${initialLevel * 10}`);
    }
  }, [showSlider]);

  return (
    <div className={cls(`px-4`)}>
      <div>
        {initialLevel > 0
          ? t('risk.water-level-change-text-with-level', { initialLevel })
          : t('risk.water-level-change-text')}
      </div>
      <Slider
        aria-label={t('risk.coastal-level')}
        defaultValue={initialLevel}
        disabled={!showSlider}
        getAriaValueText={valuetext}
        valueLabelDisplay="auto"
        color={`info`}
        shiftStep={0.1}
        value={waterLevel}
        onChangeCommitted={(_event: React.SyntheticEvent | Event, value: number | number[]) => {
          if (!Array.isArray(value)) {
            const newValue: number = value > 0 ? Math.round(value * 10) : 0;
            showFloodingLayers(`kust_${newValue}`);
            setWaterLevel(newValue / 10);
          }
        }}
        valueLabelFormat={(txt) => {
          return `${txt}${t('general.meters-short-suffix')}`;
        }}
        step={0.1}
        marks
        min={0}
        max={5}
      />
    </div>
  );
};
