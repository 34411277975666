import cls from 'classnames';
import dynamic from 'next/dynamic';
import { Trans, useTranslation } from 'next-i18next';
import { PROP_TYPES, TAX_TYPE_SHORT } from 'shared/dist/constants';
import { PropertyTypeGroup } from 'shared/dist/types';
import { getGroupForObjectType } from 'shared/dist/util/calculations';
import { capFirstLetter } from 'shared/dist/util/string';

import ColWrapper from '@/src/layout/col-wrapper';
import { getColorForObjectType } from '@/src/lib/colors';
import { parseCssRgbString } from '@/src/lib/utils/calculations';

import RentStatsTable from '../../../items/rent-stats-table';

const RentChart = dynamic(() => import('@/src/widgets/charts/rent-chart'), {
  ssr: false
});

export default function RentTaxTableWrapper({
  pt,
  forceCategory,
  slideIn = false,
  object,
  primaryType,
  children,
  chartData,
  mean
}: {
  pt: any | null;
  forceCategory?: PropertyTypeGroup | null;
  slideIn?: boolean;
  object: any;
  primaryType: PropertyTypeGroup;
  children: any;
  chartData: any[];
  mean: number;
}) {
  const { t } = useTranslation('common');

  let taxCode = pt?.tax_code ?? 0;

  if (forceCategory) {
    taxCode = 325;
  }

  const propType: PropertyTypeGroup = forceCategory
    ? forceCategory
    : getGroupForObjectType(pt?.tax_code);

  const { color, bgColor } = getColorForObjectType(taxCode);
  const customColor: any = parseCssRgbString(color);

  return (
    <div
      className={cls('border rounded-b-md mb-4 w-full text-left relative text-xs', {
        'rounded-t-md': slideIn
      })}
      style={{ borderColor: bgColor }}>
      <div
        style={{
          backgroundColor: bgColor
        }}
        className={cls(
          `left text-bobo-black dark:text-white dark:bg-opacity-0 font-medium flex items-center space-x-1 text-[11px] leading-3.2 py-2.5 px-6 lg:px-3 2xl:px-6`
        )}>
        <div className="w-2 h-2 rounded-full" style={{ backgroundColor: color }} />

        <div>
          {t(PROP_TYPES[propType])} ({t('general.property-type-code')} {pt?.tax_code}{' '}
          {capFirstLetter(t(TAX_TYPE_SHORT[pt?.tax_code]))})
        </div>
      </div>

      <div className="flex flex-col">
        <div className="w-full hidden lg:block bg-opacity-90 border-gray-200 dark:border-dark-morefaded">
          <RentStatsTable
            property={object}
            iconOnly={false}
            primaryType={primaryType}
            slideIn={slideIn}
          />
        </div>
        <div className="w-full px-0 lg:px-3 2xl:px-6 dark:bg-dark-lessfaded pt-3">{children}</div>

        {chartData ? (
          <div
            className="w-full py-2 px-6 lg:px-3 xl:px-6 dark:bg-dark-lessfaded"
            id="rentDistributionGraph">
            <ColWrapper
              title={
                <div>
                  {primaryType === PropertyTypeGroup.HOUSING ? (
                    <>
                      <Trans i18nKey={'property.rent.rent-distribution'} t={t}>
                        Distribution av normhyror för{' '}
                        <strong>{{ amount: chartData?.length }}</strong> närliggande fastigheter av
                        typen <strong>{{ propType: t(PROP_TYPES[propType]) }}</strong>
                      </Trans>
                    </>
                  ) : (
                    <>
                      <Trans i18nKey={'property.rent.rent-avg-distribution'} t={t}>
                        Distribution av snitthyror för{' '}
                        <strong>{{ amount: chartData?.length }}</strong> närliggande fastigheter av
                        typen <strong>{{ propType: t(PROP_TYPES[propType]) }}</strong>
                      </Trans>
                    </>
                  )}
                </div>
              }>
              <div className="w-full h-44 cursor-pointer">
                <RentChart customColor={customColor} data={chartData} mean={mean} />
              </div>
            </ColWrapper>
          </div>
        ) : null}
      </div>
    </div>
  );
}
