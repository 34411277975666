import { saveAs } from 'file-saver';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';

import Button from '@/src/widgets/buttons';

interface DataObject {
  local_council_code: string;
  local_council: string;
  name: string;
  category: string;
  rent_max?: number;
  yield_min?: number;
  area_class?: string;
  yield_mean?: number;
  vacancy_trend?: number;
  rent_trend?: number;
  yield_max?: number;
  vacancy_long?: number;
  yield_trend?: number;
  vacancy_short?: number;
  cost_estimate?: number;
  rent_min?: number;
  added_by?: string;
  rent_mean?: number;
  tag?: string;
  age?: string;
  uuid?: string;
  date_created?: number;
  timestamp?: number;
  institute?: string;
  geo_polygon?: string;
  created?: number;
  valid_from?: number;
  valid_to?: number;
}

interface ExportToExcelProps {
  data: DataObject[];
  councilCode: string | null;
}

export default function ExportToExcel({ data, councilCode }: ExportToExcelProps) {
  const [isDownloading, setIsDownloading] = useState(false);

  const { t } = useTranslation('common');

  const removeFields = (item: DataObject) => {
    const {
      uuid,
      date_created,
      geo_polygon,
      institute,
      timestamp,
      created,
      valid_from,
      valid_to,
      ...filteredItem
    } = item;
    return filteredItem;
  };

  // Function to generate and download Excel file
  const generateAndDownloadExcel = (
    flattenedData: any[],
    headers: { group: string; columns: string[] }[],
    labels: Record<string, string>,
    fileName: string
  ) => {
    // Prepare the worksheet data
    const worksheetData: any[][] = [];

    // Add the first row (group headers)
    const firstRow: string[] = headers.flatMap((header) => header.columns.map(() => header.group));
    worksheetData.push(firstRow);

    // Add the second row (detailed headers with labels)
    const secondRow: string[] = headers.flatMap((header) =>
      header.columns.map((column) => labels[column] || column)
    );
    worksheetData.push(secondRow);

    // Add the data rows
    flattenedData.forEach((item) => {
      const row: any[] = headers.flatMap((header) =>
        header.columns.map((column) => {
          if (['vacancy_short', 'vacancy_long'].includes(column)) {
            return item[column] || 0.0;
          } else {
            return item[column] || null;
          }
        })
      );
      worksheetData.push(row);
    });

    // Create a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // Define merges for the first row
    worksheet['!merges'] = [];
    let colStart = 0;
    headers.forEach((header) => {
      worksheet['!merges']!.push({
        s: { r: 0, c: colStart }, // Start cell
        e: { r: 0, c: colStart + header.columns.length - 1 } // End cell
      });
      colStart += header.columns.length;
    });

    // Apply styles for top-level headers
    Object.keys(worksheet).forEach((cell) => {
      if (cell.startsWith('A1')) {
        worksheet[cell].s = {
          font: { bold: true },
          alignment: { horizontal: 'center', vertical: 'center' }
        };
      }
    });

    // Auto-adjust column widths based on content
    worksheet['!cols'] = secondRow.map((headerLabel) => {
      const key = Object.keys(labels).find((key) => labels[key] === headerLabel); // Find key
      const maxLength = Math.max(
        headerLabel.length, // Header label length
        ...flattenedData.map((item) => (key && item[key] ? String(item[key]).length : 0)) // Data lengths
      );
      return { wch: maxLength + 2 }; // Add padding
    });

    // Create a workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

    // Generate Excel file and trigger download
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
  };

  // Flatten function for an array of objects
  const flattenDataArray = (dataArray: any[]) => {
    return dataArray.flatMap((data: any) => {
      if (!Array.isArray(data.datasets) || data.datasets.length === 0) {
        // Return a single row with the main object if no datasets exist
        const { datasets, ...mainData } = data; // Exclude `datasets` key
        return [mainData];
      }

      // Flatten the datasets if they exist
      return data.datasets.map((dataset: any) => {
        return {
          ...data, // Spread all main object properties
          ...dataset, // Overwrite with dataset-specific properties
          datasets: undefined // Remove the datasets array
        };
      });
    });
  };

  const filterMissingData = () => {
    const intervals = [
      '1900-1975',
      '1976-1985',
      '1986-1990',
      '1991-1995',
      '1996-2000',
      '2001-2005',
      '2006-2010',
      '2011-2015',
      '2016-'
    ];

    const flattened = flattenDataArray(data);
    const filtered = flattened
      .filter((x: any) => !x.valid_to)
      .map((x) => {
        if (x.dataset_update) {
          return { ...x, dataset_update: moment().format(`YYYY-MM-DD HH:mm`) };
        } else {
          return { ...x };
        }
      });

    // Separate housing and non-housing data
    const housingData = filtered.filter((x) => x.category === 'housing');
    const nonHousingData = filtered.filter((x) => x.category !== 'housing');

    // Get unique combinations for housing data
    const uniqueHousingGroups = Array.from(
      new Map(
        housingData.map((x) => [
          `${x.local_council}-${x.local_council_code}-${x.name}-${x.class}`,
          x
        ])
      ).values()
    );

    // Generate complete housing data with all intervals
    const completeHousingData = uniqueHousingGroups.flatMap((group) =>
      intervals.map((interval) => {
        // Check if the interval already exists for this group
        const existingRow = housingData.find(
          (x) =>
            x.local_council === group.local_council &&
            x.local_council_code === group.local_council_code &&
            x.name === group.name &&
            x.class === group.class &&
            x.interval === interval
        );

        if (existingRow) {
          // Use the existing row if it exists
          return existingRow;
        }

        // Create a new row for the missing interval without `dataset_update` or `added_by`
        return {
          ...group,
          interval, // Set the specific interval
          rent_min: 0,
          rent_mean: 0,
          rent_max: 0,
          yield_min: 0,
          yield_mean: 0,
          yield_max: 0,
          vacancy_short: 0,
          vacancy_long: 0,
          dataset_update: null, // Do not copy dataset_update
          added_by: null // Do not copy added_by
        };
      })
    );

    // Combine complete housing data with non-housing data
    const completeData = [...nonHousingData, ...completeHousingData];

    completeData.sort((a, b) => {
      if (a.local_council_code < b.local_council_code) return -1;
      if (a.local_council_code > b.local_council_code) return 1;
      return 0;
    });

    completeData.forEach((item) => {
      if (item.category === 'housing') {
        item.category = t('general.housing');
      } else if (item.category === 'industry') {
        item.category = t('general.industry');
      } else if (item.category === 'space') {
        item.category = t('general.offices');
      } else if (item.category === 'shop') {
        item.category = t('general.shop');
      }
    });

    const fileName = `data-export-data-saknas${councilCode ? `-${councilCode}` : `-sverige`}.xlsx`;

    const headers = [
      {
        group: '',
        columns: ['local_council', 'local_council_code']
      },
      { group: t('general.area-general'), columns: ['name', 'category', 'interval', 'class'] },
      { group: t('itismad.table.rent'), columns: ['rent_min', 'rent_mean', 'rent_max'] },
      { group: t('itismad.table.yield'), columns: ['yield_min', 'yield_mean', 'yield_max'] },
      { group: t('itismad.table.vacancy'), columns: ['vacancy_short', 'vacancy_long'] },
      { group: t('itismad.table.last-updated'), columns: ['added_by', 'dataset_update'] }
    ];

    const labels = {
      local_council_code: t('itismad.table.council-code'),
      local_council: t('general.local-council'),
      name: t('itismad.table.name'),
      interval: t('itismad.table.value-year-interval'),
      class: t('itismad.table.area-class'),
      category: t('itismad.table.category'),
      rent_min: t('itismad.table.min'),
      rent_mean: t('itismad.table.mean'),
      rent_max: t('itismad.table.max'),
      yield_min: t('itismad.table.min'),
      yield_mean: t('itismad.table.mean'),
      yield_max: t('itismad.table.max'),
      vacancy_short: t('itismad.table.vacancy-short'),
      vacancy_long: t('itismad.table.vacancy-long'),
      added_by: t('itismad.table.added-by'),
      dataset_update: t('itismad.table.last-updated')
    };

    const completeFilteredData = completeData.filter(
      (x) =>
        !x.rent_min ||
        x.rent_min === 0 ||
        !x.rent_mean ||
        x.rent_mean === 0 ||
        !x.rent_max ||
        x.rent_max === 0 ||
        !x.yield_min ||
        x.yield_min === 0 ||
        !x.yield_mean ||
        x.yield_mean === 0 ||
        !x.yield_max ||
        x.yield_max === 0
    );

    // Generate and download the Excel file
    generateAndDownloadExcel(completeFilteredData, headers, labels, fileName);
  };

  const exportAllData = () => {
    const intervals = [
      '0-1975',
      '1976-1985',
      '1986-1990',
      '1991-1995',
      '1996-2000',
      '2001-2005',
      '2006-2010',
      '2011-2015',
      '2016-'
    ];

    const flattened = flattenDataArray(data);
    const filtered = flattened
      .filter((x: any) => !x.valid_to)
      .map((x) => {
        if (x.dataset_update) {
          return { ...x, dataset_update: moment().format(`YYYY-MM-DD HH:mm`) };
        } else {
          return { ...x };
        }
      });

    // Separate housing and non-housing data
    const housingData = filtered.filter((x) => x.category === 'housing');
    const nonHousingData = filtered.filter((x) => x.category !== 'housing');

    // Get unique combinations for housing data
    const uniqueHousingGroups = Array.from(
      new Map(
        housingData.map((x) => [
          `${x.local_council}-${x.local_council_code}-${x.name}-${x.class}`,
          x
        ])
      ).values()
    );

    // Generate complete housing data with all intervals
    const completeHousingData = uniqueHousingGroups.flatMap((group) =>
      intervals.map((interval) => {
        // Check if the interval already exists for this group
        const existingRow = housingData.find(
          (x) =>
            x.local_council_code === group.local_council_code &&
            x.name === group.name &&
            x.class === group.class &&
            x.interval === interval
        );

        if (existingRow) {
          // Use the existing row if it exists
          return existingRow;
        }

        // Create a new row for the missing interval without `dataset_update` or `added_by`
        return {
          ...group,
          interval, // Set the specific interval
          rent_min: 0,
          rent_mean: 0,
          rent_max: 0,
          yield_min: 0,
          yield_mean: 0,
          yield_max: 0,
          vacancy_short: 0,
          vacancy_long: 0,
          dataset_update: null, // Do not copy dataset_update
          added_by: null // Do not copy added_by
        };
      })
    );

    // Combine complete housing data with non-housing data
    const completeData = [...nonHousingData, ...completeHousingData];
    completeData.sort((a, b) => {
      if (a.local_council_code < b.local_council_code) return -1;
      if (a.local_council_code > b.local_council_code) return 1;
      return 0;
    });

    completeData.forEach((item) => {
      if (item.category === 'housing') {
        item.category = t('general.housing');
      } else if (item.category === 'industry') {
        item.category = t('general.industry');
      } else if (item.category === 'space') {
        item.category = t('general.offices');
      } else if (item.category === 'shop') {
        item.category = t('general.shop');
      }
    });

    const fileName = `data-export-full${councilCode ? `-${councilCode}` : `-sverige`}.xlsx`;

    const headers = [
      {
        group: '',
        columns: ['local_council', 'local_council_code']
      },
      { group: t('general.area-general'), columns: ['name', 'category', 'interval', 'class'] },
      { group: t('itismad.table.rent'), columns: ['rent_min', 'rent_mean', 'rent_max'] },
      { group: t('itismad.table.yield'), columns: ['yield_min', 'yield_mean', 'yield_max'] },
      { group: t('itismad.table.vacancy'), columns: ['vacancy_short', 'vacancy_long'] },
      { group: t('itismad.table.last-updated'), columns: ['added_by', 'dataset_update'] }
    ];

    const labels = {
      local_council_code: t('itismad.table.council-code'),
      local_council: t('general.local-council'),
      name: t('itismad.table.name'),
      interval: t('itismad.table.value-year-interval'),
      class: t('itismad.table.area-class'),
      category: t('itismad.table.category'),
      rent_min: t('itismad.table.min'),
      rent_mean: t('itismad.table.mean'),
      rent_max: t('itismad.table.max'),
      yield_min: t('itismad.table.min'),
      yield_mean: t('itismad.table.mean'),
      yield_max: t('itismad.table.max'),
      vacancy_short: t('itismad.table.vacancy-short'),
      vacancy_long: t('itismad.table.vacancy-long'),
      added_by: t('itismad.table.added-by'),
      dataset_update: t('itismad.table.last-updated')
    };

    // Generate and download the Excel file
    generateAndDownloadExcel(completeData, headers, labels, fileName);
  };

  return (
    <div className="flex gap-2 text-xs">
      <Button onClick={exportAllData} disabled={isDownloading}>
        {isDownloading ? 'Downloading...' : t('itismad.download-all')}
      </Button>
      <Button onClick={filterMissingData} disabled={isDownloading}>
        {isDownloading ? 'Downloading...' : t('itismad.download-missing')}
      </Button>
    </div>
  );
}
