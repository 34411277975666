import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import { ArrowsPointingOutIcon } from '@heroicons/react/24/solid';
import cls from 'classnames';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useTheme } from 'next-themes';
import { useState } from 'react';
import { useMap } from 'react-map-gl';
import { PROP_TYPES } from 'shared/dist/constants';

import { usePropertyTypesMode } from '@/src/context/propertytype-context';
import { getColorForPropertyType } from '@/src/lib/colors';
import { SWE_BBOX } from '@/src/lib/constants';
import InfoButton from '@/src/widgets/info-button';

export default function MapTools({
  showSearchBox,
  setIsSearching,
  map,
  zoom,
  hideNorthReset
}: {
  showSearchBox: boolean;
  setIsSearching: any;
  map: any;
  zoom: number;
  hideNorthReset?: boolean;
}) {
  const { propertyTypes, setPropertyTypesMode, availablePropertyTypesExt } = usePropertyTypesMode();
  const { mapContainer } = useMap();
  const { resolvedTheme } = useTheme();
  const { t } = useTranslation('common');

  const [isTilted, setIsTilted] = useState<number>(0);

  if (mapContainer?.isStyleLoaded) {
    mapContainer.on('rotateend', () => {
      if (mapContainer?.getBearing() !== 0) {
        setIsTilted(mapContainer?.getBearing());
      }
    });
    mapContainer.on('pitchend', () => {
      if (mapContainer?.getPitch() !== 20) {
        setIsTilted(mapContainer?.getBearing() + 1);
      }
    });
  }

  return (
    <div
      className={cls(
        `hidden md:flex z-[25] text-xs transition-all duration-200 bg-white justify-center items-start dark:bg-dark-lessfaded h-[60px]`
      )}>
      {!hideNorthReset && (
        <button
          onClick={() => {
            setIsTilted(0);
            map?.resetNorth({ duration: 300, pitch: 0 });
          }}
          disabled={!isTilted || zoom < 5}
          className={cls(
            `p-3 relative flex justify-center items-center border-r border-gray-100 dark:border-dark-morefaded text-center h-full`,
            {
              'text-gray-400': zoom < 5 || !isTilted
            },
            {
              'has-tooltip': zoom >= 5 && isTilted
            }
          )}>
          <div
            className={cls(``, {
              'grayscale opacity-50': !isTilted || zoom < 5
            })}>
            <Image
              src={`/icons/compass.svg`}
              height={25}
              width={25}
              alt={`Kompass`}
              style={{ transform: `rotate(${isTilted + 120}deg)` }}
              className="m-auto"
            />
          </div>
          <div className="text-mini tooltip top-16 left-0 2xl:left-auto z-40 leading-3 py-1 bg-white dark:text-white px-2 rounded-md shadow-xl dark:bg-dark-faded border dark:border-dark-morefaded 2xl:dark:border-dark-faded whitespace-nowrap text-bobo-black border-gray-100">
            {t('map.reset-north')}
          </div>
        </button>
      )}

      {showSearchBox && (
        <button
          disabled={zoom < 5 ? true : false}
          onClick={() => {
            setIsSearching(true);
            // setPopupInfo(null);
            map?.fitBounds(SWE_BBOX, {
              duration: 0,
              pitch: 20
            });
          }}
          className={cls(
            `p-3 relative flex justify-center border-r border-gray-100 dark:border-dark-morefaded text-center h-full`,
            {
              'text-gray-400': zoom < 5
            },
            {
              'has-tooltip': zoom >= 5
            }
          )}>
          <ArrowsPointingOutIcon className="w-5 h-5 m-auto" />
          <div className="text-mini tooltip top-16 leading-3 py-1 bg-white dark:text-white px-2 rounded-md shadow-xl dark:bg-dark-faded dark:border-dark-faded whitespace-nowrap text-bobo-black border-gray-100">
            {t('map.show-all-sweden')}
          </div>
        </button>
      )}

      <div className="p-3 font-medium text-left flex flex-wrap max-w-[421px] gap-1 h-full  overflow-y-scroll">
        {availablePropertyTypesExt?.map((pt, idx) => {
          const { color, bgColor } = getColorForPropertyType(pt);

          const fallBackColor: any = resolvedTheme === 'dark' ? '#2A2A2A' : '#eee';

          return (
            <button
              key={idx}
              onClick={() => {
                const tmp: any = propertyTypes;
                if (tmp.includes(pt)) {
                  setPropertyTypesMode(tmp.filter((a) => a !== pt));
                } else {
                  setPropertyTypesMode([...tmp, pt]);
                }
              }}
              style={{ backgroundColor: propertyTypes?.includes(pt) ? bgColor : fallBackColor }}
              className="rounded-md items-center whitespace-nowrap flex gap-1 transition-color duration-200 px-2 text-[9px] leading-3 py-[2px]">
              <div
                style={{
                  backgroundColor: color,
                  opacity: !propertyTypes?.includes(pt) ? 0.5 : 1
                }}
                className="h-2 w-2 rounded-full transition-color duration-200"
              />
              {t(PROP_TYPES[pt])}
            </button>
          );
        })}
      </div>

      <div className="flex p-3 border-l border-gray-100 dark:border-dark-morefaded items-center justify-center h-full">
        <InfoButton helpTextId="propertyPropTypes" />
      </div>
    </div>
  );
}
