import { Popover, Transition } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import cls from 'classnames';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCategoryName } from 'shared/dist/constants';
import { RadioButtonOptions } from 'shared/dist/types';
import { CashflowLease } from 'shared/dist/types/cashflow/cashflow-model';

import { useOutsideAlerter } from '@/src/hooks/use-outside-alerter';
import { FLCustom } from '@/src/widgets/numbers';

export function FLRadioButton({
  selectedTab,
  valueCategory,
  disabled,
  defaultRowValues,
  radioButtonStyle,
  changeValue,
  tabValues,
  rentRoll
}: {
  valueCategory: any;
  changeValue: any;
  selectedTab: string;
  tabValues: any;
  disabled: boolean;
  radioButtonStyle: string;
  defaultRowValues: any;
  rentRoll?: CashflowLease[];
}) {
  const [selectedFLType, setSelectedFLType] = useState<any>(
    defaultRowValues.fl.find((type) => type.propType === tabValues.selectedFLCategory)
  );
  const [showFLCategories, setShowFLCategories] = useState<boolean>(false);
  const showFLCategoriesRef = useRef(null);
  const { t } = useTranslation('common');

  useOutsideAlerter(showFLCategoriesRef, setShowFLCategories);

  useEffect(() => {
    if (tabValues.selectedFLCategory !== selectedFLType.propType) {
      setSelectedFLType(
        defaultRowValues.fl.find((type) => type.propType === tabValues.selectedFLCategory)
      );
    }
  }, [tabValues.selectedFLCategory]);

  return (
    <div
      className={cls('flex items-center justify-center w-full gap-2 border-gray-100', {
        'grayscale opacity-30 py-0.5':
          defaultRowValues.fl.filter((rentCategory) => rentCategory.value > 0).length === 0
      })}>
      <div className="w-1/6 h-full flex items-center justify-center">
        <input
          type={'radio'}
          name={`${valueCategory.title}-select`}
          disabled={
            defaultRowValues.fl.filter((rentCategory) => rentCategory.value > 0).length === 0 ||
            disabled ||
            rentRoll !== undefined
          }
          value={RadioButtonOptions.FL}
          checked={tabValues.radioButton === RadioButtonOptions.FL && !rentRoll}
          onChange={() => {
            changeValue(
              selectedTab,
              valueCategory.title,
              selectedFLType.value,
              RadioButtonOptions.FL
            );
          }}
          className={radioButtonStyle}
        />
      </div>
      <div className="flex flex-col w-5/6 justify-start text-start leading-3">
        <Popover className="relative">
          <Popover.Button
            onClick={() => {
              setShowFLCategories(!showFLCategories);
            }}
            disabled={
              disabled ||
              defaultRowValues.fl.filter((rentCategory) => rentCategory.value > 0).length === 0
            }
            className={cls(
              'flex gap-1 items-center transition-colors duration-200',
              {
                'text-bobo-prop hover:text-bobo-prophover dark:text-dark-prop hover:dark:text-dark-prophover':
                  !disabled
              },
              { 'text-gray-400': disabled }
            )}>
            <div className="whitespace-nowrap  w-[110px] text-start">{RadioButtonOptions.FL}</div>

            <ChevronRightIcon
              className={cls('w-4 h-4 transition-transform duration-200', {
                'rotate-90': showFLCategories
              })}
            />
          </Popover.Button>

          <Transition
            as={Fragment}
            show={showFLCategories}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 -translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-1">
            <Popover.Panel
              ref={showFLCategoriesRef}
              className="absolute transform translate-x-0 z-50">
              <div className="bg-white dark:text-white dark:bg-dark-morefaded text-bobo-black rounded-md shadow-md flex flex-col w-[110px] overflow-hidden">
                {defaultRowValues.fl.map((propType, idx) => {
                  return (
                    <div
                      key={idx}
                      className={cls('py-3 px-5 transition-colors duration-200 group', {
                        'bg-bobo-prop dark:bg-dark-prop text-white':
                          propType.propType === selectedFLType.propType && propType.value > 0,
                        'hover:bg-bobo-proplight dark:hover:bg-dark-prophover text-bobo-prop dark:text-white group-hover:text-bobo-prophover dark:group-hover:text-white':
                          propType.propType !== selectedFLType.propType && propType.value > 0
                      })}>
                      <button
                        onClick={() => {
                          if (propType) {
                            setSelectedFLType(propType);

                            if (!rentRoll) {
                              changeValue(
                                selectedTab,
                                valueCategory.title,
                                propType.value,
                                RadioButtonOptions.FL,
                                null,
                                propType.propType
                              );
                            }
                            setShowFLCategories(false);
                          }
                        }}
                        disabled={propType.value === 0}
                        className="disabled:text-gray-300 w-full h-full">
                        {t(getCategoryName(propType.propType))}
                        {propType.value > 0 ? (
                          <div
                            className={cls(
                              'whitespace-nowrap',
                              propType.propType === selectedFLType.propType
                                ? 'text-gray-200'
                                : 'text-gray-400'
                            )}>
                            <FLCustom value={propType.value} suffix={t(valueCategory.suffix)} />
                          </div>
                        ) : null}
                      </button>
                    </div>
                  );
                })}
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>

        {defaultRowValues.fl.filter((rentCategory) => rentCategory.value > 0).length > 0 ? (
          <div className="text-gray-400">
            <FLCustom value={selectedFLType.value} suffix={t(valueCategory.suffix)} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
