import { XMarkIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import {
  CashflowCredit,
  CashflowInvestment,
  CashflowLease,
  CashflowValuationAsset,
  CashflowValuationModel
} from 'shared/dist/types/cashflow/cashflow-model';

import CashflowCreditTemplate from '../dialogs/cashflow/cashflow-add-credit';
import CashflowInflationTemplate from '../dialogs/cashflow/cashflow-add-inflation';
import CashflowInvestmentTemplate from '../dialogs/cashflow/cashflow-add-investment';
import CashflowLeaseTemplate from '../dialogs/cashflow/cashflow-add-lease';
import CashflowAddModel from '../dialogs/cashflow/cashflow-add-model';
import CashflowDeleteModel from '../dialogs/cashflow/cashflow-delete-model';
import CashflowImportRentRoll from '../dialogs/cashflow/cashflow-import-rent-roll';
import { crmDialogTheme } from '../dialogs/crm/dialog-theme';

export const stepNames = {
  addModel: 1,
  delete: 2,
  importRentRoll: 3,
  addInflation: 4,
  addLease: 5,
  addCredit: 6,
  addInvestment: 7
};

export enum DeleteType {
  MODEL = 'model',
  ASSET = 'asset',
  LEASE = 'lease',
  CREDIT = 'credit',
  INVESTMENT = 'investment'
}

export interface DeletedModel extends CashflowValuationModel {
  deleteType: DeleteType;
}
export interface DeletedAsset extends CashflowValuationAsset {
  deleteType: DeleteType;
}
export interface DeletedLease extends CashflowLease {
  deleteType: DeleteType;
}
export interface DeletedCredit extends CashflowCredit {
  deleteType: DeleteType;
}
export interface DeletedInvestment extends CashflowInvestment {
  deleteType: DeleteType;
}

export default function CashflowDialogSwitcher({
  onClose,
  object: {
    initStep = stepNames.addModel,
    refresh,
    object,
    model,
    setSelectedModel,
    deletedObject,
    onSuccess,
    models
  }
}: {
  onClose: () => any;
  object: {
    initStep: number;
    refresh?: () => void;
    object: any;
    model: CashflowValuationModel;
    setSelectedModel: (arg0?: CashflowValuationModel) => void;
    deletedObject?: DeletedModel | DeletedAsset | DeletedLease;
    onSuccess?: () => void;
    property?: any;
    models?: CashflowValuationModel[];
  };
}) {
  const [step, setStep] = useState(initStep);
  const [reload, setReload] = useState(false);

  function closeModal() {
    onClose();
    setStep(0);
    if (reload && refresh) {
      refresh();
    }
  }

  let mod: JSX.Element = <div />;

  switch (step) {
    case stepNames.addModel:
      mod = (
        <CashflowAddModel
          close={closeModal}
          setReload={setReload}
          object={object}
          setSelectedModel={setSelectedModel}
          models={models}
        />
      );
      break;
    case stepNames.delete:
      mod = (
        <CashflowDeleteModel
          close={closeModal}
          setReload={setReload}
          model={model}
          setSelectedModel={setSelectedModel}
          deletedObject={deletedObject}
          onSuccess={onSuccess}
        />
      );
      break;
    case stepNames.addInflation:
      mod = <CashflowInflationTemplate object={object} close={closeModal} />;
      break;
    case stepNames.importRentRoll:
      mod = (
        <CashflowImportRentRoll
          close={closeModal}
          setReload={setReload}
          model={model}
          setSelectedModel={setSelectedModel}
        />
      );
      break;
    case stepNames.addLease:
      mod = <CashflowLeaseTemplate object={object} close={closeModal} setReload={setReload} />;
      break;
    case stepNames.addCredit:
      mod = (
        <CashflowCreditTemplate
          object={object}
          setSelectedModel={setSelectedModel}
          close={closeModal}
          setReload={setReload}
        />
      );
      break;
    case stepNames.addInvestment:
      mod = (
        <CashflowInvestmentTemplate
          object={object}
          setSelectedModel={setSelectedModel}
          close={closeModal}
          setReload={setReload}
        />
      );
      break;
  }

  return (
    <>
      <button onClick={closeModal} className={crmDialogTheme.closeContainerX}>
        <XMarkIcon className="h-5 w-5" />
      </button>
      {mod}
    </>
  );
}
