import { PlusIcon } from '@heroicons/react/24/solid';
import cls from 'classnames';
import { Trans, useTranslation } from 'next-i18next';
import React from 'react';
import { CashflowLease } from 'shared/dist/types/cashflow/cashflow-model';

import DropdownMenu from '@/src/layout/components/dropdown-menu';
import InfoButton from '@/src/widgets/info-button';
import { FLCustom, FLSqm } from '@/src/widgets/numbers';

import { isOngoingLease } from '../../constants/calculations';

export default function SelectLeaseDropdown({
  rentRoll,
  viewLease,
  errorState = {}
}: {
  rentRoll: CashflowLease[];
  viewLease: (lease: CashflowLease | undefined) => void;
  errorState?: any;
}) {
  const { t } = useTranslation('common');

  const renderLeases = (closeMenu: () => void) => {
    const handleClick = (e, i?: CashflowLease | undefined) => {
      e.stopPropagation();
      viewLease && viewLease(i ?? undefined);
      closeMenu();
    };

    const leases: React.JSX.Element[] =
      rentRoll
        ?.filter((lease) => isOngoingLease(lease))
        .map((i, idx) => {
          return (
            <div
              key={idx}
              className="py-2 px-2 text-bobo-prop dark:text-white text-start transition-colors duration-200 hover:bg-bobo-proplight dark:hover:bg-dark-prophover hover:text-bobo-prophover w-full">
              <button
                onClick={(e) => handleClick(e, i)}
                className="disabled:text-gray-300 w-full h-full text-start text-xs">
                <div>{i.name}</div>
                <div className="flex justify-between text-gray-400 text-mini">
                  <div>
                    {t('general.rent')}:{' '}
                    <FLCustom value={i.rent} suffix={t('general.kr-per-square-m-suffix')} />
                  </div>
                  <div>
                    {t('general.area')}:{' '}
                    <span
                      className={cls({
                        'text-bobo-bricksred': i.area === 0 && errorState.leaseArea
                      })}>
                      <FLSqm value={Math.round(i.area)} />
                    </span>
                  </div>
                  <div>
                    {t('property.cashflow-valuation.assumptions.indexation')}:{' '}
                    <FLCustom value={i.indexation * 100} suffix={'%'} />
                  </div>
                </div>
              </button>
            </div>
          );
        }) ?? [];

    const addLeaseRow: React.JSX.Element = (
      <div
        key={(rentRoll?.length ?? 0) + 1}
        className="py-2 px-2 text-bobo-prop dark:text-white w-full border-t border-gray-200 dark:border-dark-faded transition-colors duration-200 hover:bg-bobo-proplight dark:hover:bg-dark-prophover hover:text-bobo-prophover text-xs">
        <button
          onClick={(e) => handleClick(e)}
          className="flex gap-2 items-center justify-center w-full h-full">
          <PlusIcon className="w-5 h-5" />
          <div>{t('property.cashflow-valuation.assumptions.add-lease')}</div>
        </button>
      </div>
    );

    return [...leases, addLeaseRow];
  };

  const leaseCount: number = rentRoll.filter((lease) => isOngoingLease(lease)).length;

  const getDropdownTitle = (): React.JSX.Element => {
    if (errorState.leaseCount) {
      return (
        <span className="text-bobo-bricksred italic">
          {t(errorState.leaseCount, { field: t('property.cashflow-valuation.lease.title') })}
        </span>
      );
    }
    if (errorState.leaseArea) {
      return (
        <span className="text-bobo-bricksred italic">
          {t(errorState.leaseArea, { field: t('general.area') })}
        </span>
      );
    }
    return (
      <>
        <Trans
          i18nKey="property.cashflow-valuation.lease.existing-rental-agreements"
          count={leaseCount}>
          Du har <strong>{{ rentRoll: leaseCount }}</strong> befintliga hyresavtal
        </Trans>
      </>
    );
  };

  return (
    <div className="relative">
      <DropdownMenu
        title={<div className="text-xs text-gray-400 dark:text-white">{getDropdownTitle()}</div>}
        renderMenuItems={renderLeases}
        className="h-[24px] !z-20"
        buttonClassName="!py-0.5 !w-[270px]"
      />

      <div className="absolute -right-7 top-0">
        <InfoButton helpTextId="cashflowCalculatorLeases" />
      </div>
    </div>
  );
}
