import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import cls from 'classnames';
import Image from 'next/image';
import { Trans, useTranslation } from 'next-i18next';
import { useEffect, useRef, useState } from 'react';
import { useGlobal } from 'reactn';
import { Error, Scooter, Success } from 'src/animations';

import { crmDialogTheme } from '@/src/components/dialogs/crm/dialog-theme';
import { deletePropertyFromBoard } from '@/src/lib/user-client-wrapper';
import { CRMSlideInObject } from '@/src/types';

const stepNames = {
  initial: 1,
  loading: 2,
  error: 3,
  done: 4,
  not_authorized: 5
};

function PropertyDeletionStep1({
  setStep,
  setReload,
  close,
  propertyUUID,
  authorityNickname,
  boardId
}: {
  setStep: any;
  setReload: any;
  close: any;
  propertyUUID: string | undefined;
  authorityNickname: string | undefined;
  boardId: string;
}) {
  const [inputValue, setInputValue] = useState<string>('');
  const [inputTouched, setInputTouched] = useState<boolean>(false);
  const [inputValid, setInputValid] = useState<boolean>(false);
  const { t } = useTranslation('common');

  const inputRef: any = useRef(null);

  useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current?.focus();
    }
  }, []);

  function handleSubmit() {
    setInputTouched(true);

    if (inputValid) {
      setReload(true);
      setStep(stepNames.loading); // show progress
      deletePropertyFromBoard(propertyUUID + '', boardId + '')
        .then((res: any) => {
          if (res?.ok === 400) {
            setStep(stepNames.not_authorized);
          } else if (res?.ok === 200) {
            setStep(stepNames.done);
          } else {
            setStep(stepNames.error);
          }
        })
        .catch(() => {
          setStep(stepNames.error);
        });
    }
  }

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Image
          alt="main illustration"
          src={`/images/apartment-rate-increment.svg`}
          height={200}
          width={200}
        />
      </div>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          <Trans i18nKey="crm.modals.delete-property-title" authorityNickname={authorityNickname}>
            Är du säker att du vill radera fastighetskortet för
            <span className="text-bobo-prop dark:text-dark-prop"> {{ authorityNickname }}</span>?
          </Trans>
        </Dialog.Title>

        <div className={crmDialogTheme.inputContainerDelete}>
          <span className={crmDialogTheme.inputDeleteLabel}>
            <Trans i18nKey="crm.modals.delete-prompt-input" name={authorityNickname}>
              Skriv in <span className="font-demi">{{ name: authorityNickname }}</span> (exakt) i
              rutan till höger för att bekräfta raderingen.
            </Trans>
          </span>

          <input
            ref={inputRef}
            className={cls(crmDialogTheme.inputDelete, {
              'border-bobo-burgundy': !inputValid,
              'bg-red-200 focus:bg-card-graypanel': !inputValid && inputTouched
            })}
            value={inputValue}
            onPaste={() => {
              setInputTouched(true);
            }}
            onChange={(event) => {
              setInputValue(event.target.value);
              setInputValid(event.target.value.trim() == authorityNickname);
            }}
            onBlur={() => {
              setInputTouched(true);
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleSubmit();
              }
            }}
          />
        </div>
      </div>

      <div className={crmDialogTheme.buttonContainer}>
        <button className={crmDialogTheme.buttonClose} onClick={close}>
          {t('general.cancel')}
        </button>
        <button
          className={crmDialogTheme.buttonDelete}
          disabled={!inputTouched || !inputValid}
          onClick={() => {
            handleSubmit();
          }}>
          {t('general.delete')}
        </button>
      </div>
    </div>
  );
}

function PropertyDeletionStepLoading() {
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Scooter />
      </div>

      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          {t('crm.modals.delete-property-loading-title')}
        </Dialog.Title>
        <p className={crmDialogTheme.subtext}>{t('fun-facts.swe-smallest-property')}</p>
      </div>
    </div>
  );
}

function PropertyDeletionNotAuhtorized({
  close,
  authorityNickname
}: {
  close: any;
  authorityNickname: string | undefined;
}) {
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.icon}>
        <Error />
      </div>

      <Dialog.Title as="h3" className={crmDialogTheme.title}>
        <Trans
          i18nKey="crm.modals.delete-property-error-title"
          authorityNickname={authorityNickname}>
          Du har inte rättighet att radera
          <span className="text-bobo-prop dark:text-dark-prop"> {{ authorityNickname }} </span>
          från tavlan.
        </Trans>
      </Dialog.Title>

      <p className={crmDialogTheme.subtext}>{t('crm.modals.delete-property-error-subtext')}</p>

      <div className={crmDialogTheme.buttonContainer}>
        <button className={crmDialogTheme.buttonClose} onClick={close}>
          {t('general.cancel')}
        </button>
      </div>
    </div>
  );
}

function PropertyDeletionStepError({ close }: { close: any }) {
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.textContainer}>
        <div className={crmDialogTheme.icon}>
          <Error />
        </div>

        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          <span className="text-red-700 font-light">{t('errors.something-went-wrong')}</span>
        </Dialog.Title>
      </div>
      <div className={crmDialogTheme.buttonContainer}>
        <button className={crmDialogTheme.buttonClose} onClick={close}>
          {t('general.close')}
        </button>
      </div>
    </div>
  );
}

function PropertyDeletionStepDone({
  close,
  authorityNickname
}: {
  close: any;
  authorityNickname: string | undefined;
}) {
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.icon}>
        <Success />
      </div>

      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          <Trans i18nKey="crm.modals.delete-property-success" authorityNickname={authorityNickname}>
            <span className="text-bobo-prop dark:text-dark-prop">{{ authorityNickname }}</span> är
            nu borttagen från tavlan.
          </Trans>
        </Dialog.Title>
      </div>

      <div className={crmDialogTheme.buttonContainer}>
        <button className={crmDialogTheme.buttonClose} onClick={close}>
          {t('crm.modals.done')}
        </button>
      </div>
    </div>
  );
}

/**
 * dialog to confirm deletion of property from board & list
 */
export default function CrmPropertyDialogDelete({
  initStep = stepNames.initial,
  object,
  onClose
}: {
  initStep?: number;
  onClose: () => any;
  object?: CRMSlideInObject & { onDone: any };
}) {
  const [crmCurrentBoardIncrement, setCrmCurrentBoardIncrement] = useGlobal<any>(
    'currentBoardIncrement' as never
  );
  const [step, setStep] = useState(initStep);
  const [reload, setReload] = useState(false);

  // need to refresh if closed modal during or after the API request
  function closeAndNotify() {
    if (object?.onDone) {
      object.onDone();
    } else {
      onClose();
      if (reload) {
        setCrmCurrentBoardIncrement((crmCurrentBoardIncrement || 0) + 1);
      }
    }
  }

  let mod: JSX.Element = <div />;

  switch (step) {
    case stepNames.initial:
      mod = (
        <PropertyDeletionStep1
          setStep={setStep}
          setReload={setReload}
          close={onClose}
          propertyUUID={object?.crmObject.uuid}
          authorityNickname={object?.crmObject.authorityNickname}
          boardId={object?.crmObject.boardId}
        />
      );
      break;

    case stepNames.loading:
      mod = <PropertyDeletionStepLoading />;
      break;

    case stepNames.error:
      mod = <PropertyDeletionStepError close={closeAndNotify} />;
      break;
    case stepNames.done:
      mod = (
        <PropertyDeletionStepDone
          close={closeAndNotify}
          authorityNickname={object?.crmObject.authorityNickname}
        />
      );
      break;

    case stepNames.not_authorized:
      mod = (
        <PropertyDeletionNotAuhtorized
          close={closeAndNotify}
          authorityNickname={object?.crmObject.authorityNickname}
        />
      );
      break;
  }

  return (
    <>
      <button onClick={closeAndNotify} className={crmDialogTheme.closeContainerX}>
        <XMarkIcon className="h-5 w-5" />
      </button>
      {mod}
    </>
  );
}
