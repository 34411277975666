import { Dialog } from '@headlessui/react';
import { TrashIcon, XMarkIcon } from '@heroicons/react/24/solid';
import moment from 'moment';
import Image from 'next/image';
import { Trans, useTranslation } from 'next-i18next';
import { useState } from 'react';
import React from 'react';
import { DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend, NativeTypes } from 'react-dnd-html5-backend';
import toast from 'react-hot-toast';
import NumberFormat from 'react-number-format';
import { getCategoryName } from 'shared/dist/constants';
import { ButtonType } from 'shared/dist/types';
import {
  CashflowLease,
  CashflowValuationAsset,
  CashflowValuationModel,
  InflationType
} from 'shared/dist/types/cashflow/cashflow-model';
import { NewsecCategory } from 'shared/dist/types/rent';
import { Error, Scooter, Success } from 'src/animations';
import { v4 as uuidv4 } from 'uuid';
import * as XLSX from 'xlsx';

import DropdownMenu from '@/src/layout/components/dropdown-menu';
import { updateAsset } from '@/src/lib/utils/cashflow-valuation/general';
import Button from '@/src/widgets/buttons';

import { DEFAULT_INFLATION_UUID } from '../../cashflow-valuation/constants/cashflow-theme';
import { crmDialogTheme } from '../crm/dialog-theme';

const stepNames = {
  import: 1,
  submit: 2,
  loading: 3,
  error: 4,
  done: 5
};

// Helper function to convert Excel date to JavaScript Date
function excelDateToJSDate(serial) {
  const excelEpoch = new Date(1899, 11, 30); // Excel's epoch (December 30, 1899)
  const days = Math.floor(serial); // Extract the whole number part (days)
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  return new Date(excelEpoch.getTime() + days * millisecondsPerDay);
}

export function CashflowImportRentRollImportStep({
  setStep,
  setReload,
  close,
  model,
  setNewRentRows,
  asset,
  setAsset
}: {
  setStep: any;
  setReload: any;
  close: any;
  setNewRentRows: (arg0: CashflowLease[]) => void;
  model: CashflowValuationModel;
  asset: CashflowValuationAsset | undefined;
  setAsset: (arg0: CashflowValuationAsset) => void;
}) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { t } = useTranslation('common');

  function onDrop(event: { files: File[] }) {
    if (event.files.length > 1) {
      toast.error(t('crm.modals.import-properties-error-message-one-file'));
    } else {
      const file = event.files[0];
      if (
        file &&
        (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          file.name.endsWith('.xlsx'))
      ) {
        setSelectedFile(file);
      } else {
        toast.error(t('crm.modals.import-properties-error-message-wrong-file'));
      }
    }
  }

  const [, drop] = useDrop(() => ({
    accept: [NativeTypes.FILE],
    drop(item: { files: any[] }) {
      if (onDrop) {
        onDrop(item);
      }
    }
  }));

  const onFileSelect = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    onDrop(e.target);
  };

  async function handleSubmit() {
    if (!selectedFile) {
      toast.error(t('crm.modals.import-properties-error-message-no-file'));
      return;
    }
    if (!asset) {
      toast.error(t('property.cashflow-valuation.lease.no-property'));
      return;
    }

    setReload(true);
    setStep(stepNames.loading);

    try {
      const data = await readFileAsPromise(selectedFile);
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      const rowsData: string[][] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      if (rowsData.length > 50) {
        toast.error(t('crm.modals.import-properties-error-message-rows-limit'));
        setStep(stepNames.import);
        return;
      }

      rowsData.shift(); // Remove header row
      const newRentRows: CashflowLease[] = rowsData.map((row) => {
        return {
          uuid: uuidv4(),
          name: row[0],
          startDate: moment(excelDateToJSDate(row[3])).unix(),
          endDate: moment(excelDateToJSDate(row[4])).unix(),
          rent: parseFloat(row[1]),
          supplements: parseFloat(row[5]),
          indexation: parseFloat(row[6]) / 100,
          rentIndex: {
            uuid: DEFAULT_INFLATION_UUID,
            type: InflationType.CONTINUOUS
          },
          costIndex: {
            uuid: DEFAULT_INFLATION_UUID,
            type: InflationType.CONTINUOUS
          },
          assetName: asset.name,
          assetUuid: asset.uuid,
          area: parseFloat(row[2])
        };
      });
      setNewRentRows(newRentRows);
      setStep(stepNames.submit);
    } catch (error) {
      toast.error(t('crm.modals.import-properties-error-message-unknown'));
      setStep(stepNames.error);
    }
  }

  function readFileAsPromise(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target?.result as string);
      reader.onerror = (e) => reject(e);
      reader.readAsBinaryString(file);
    });
  }

  const handleClick = () => {
    const link = document.createElement('a');
    link.download = 'Fastighetsloggen_Import_Rentroll_Template.xlsx';
    link.href = `/templates/Fastighetsloggen_Import_Rentroll_Template.xlsx`;
    link.click();
    link.remove();
  };

  const assetName = asset?.name || '';

  const renderPropertyTypes = (closeMenu: () => void) =>
    model.assets
      ?.filter((asset) => asset.adjustableAssumptions.type !== NewsecCategory.HOUSING)
      ?.map((i, idx) => {
        return (
          <div
            key={idx}
            className="py-2 px-2 transition-colors duration-200 hover:bg-bobo-proplight dark:hover:bg-dark-prophover text-bobo-prop dark:text-white hover:text-bobo-prophover w-full">
            <button
              onClick={() => {
                setAsset(i);
                closeMenu();
              }}
              className="disabled:text-gray-300 w-full h-full text-start text-xs">
              {t(getCategoryName(i.name)) || i.name}
            </button>
          </div>
        );
      }) ?? [<div key={1} />];

  const hasCommercialAssets: boolean = model.assets.some(
    (asset) => asset.adjustableAssumptions.type !== NewsecCategory.HOUSING
  );

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Image alt="main illustration" src={`/images/excel-image.png`} height={200} width={200} />
      </div>

      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          <Trans
            key="property.cashflow-valuation.import-rentroll-title"
            t={t}
            propertyName={assetName}>
            Importera fastigheter från Excel till{' '}
            <span className="text-bobo-prop dark:text-dark-prop">{{ assetName }}</span>
          </Trans>
        </Dialog.Title>
        <p className={crmDialogTheme.subtext}>
          {t('property.cashflow-valuation.import-rentroll-subtext')}
        </p>

        <div className="py-6 flex flex-col justify-center w-2/3 gap-2">
          <div className="w-full flex flex-col items-center gap-2">
            <p className={crmDialogTheme.subtext}>
              {t('property.cashflow-valuation.lease.choose-prop-type')}:
            </p>

            <DropdownMenu
              title={
                hasCommercialAssets
                  ? asset
                    ? t(getCategoryName(asset.name)) || asset.name
                    : t('property.cashflow-valuation.lease.choose-property')
                  : t('property.cashflow-valuation.lease.input-errors.string', {
                      field: t('property.cashflow-valuation.routes.asset-type')
                    })
              }
              renderMenuItems={renderPropertyTypes}
              disabled={!hasCommercialAssets}
              tooltipText={
                hasCommercialAssets ? '' : t('property.cashflow-valuation.lease.no-assets')
              }
            />
          </div>
          <Button type={ButtonType.ADD} onClick={handleClick}>
            {t('crm.modals.import-properties-download-template')}
          </Button>
        </div>

        {selectedFile ? (
          <div className="mx-auto p-1 rounded-lg shadow-md mt-4 w-full">
            <h2 className="text-sm pb-2 font-medium text-gray-900 dark:text-gray-300 leading-6">
              {t('general.zp-files')}
            </h2>

            <div className="flex justify-between space-x-2 items-center mb-3 rounded-lg mx-4">
              <span className="text-gray-500">{selectedFile.name}</span>

              <button
                onClick={() => setSelectedFile(null)}
                className="hover:text-bobo-bricksred text-gray-400 duration-200 transition-colors">
                <TrashIcon className="w-5 h-5" />
              </button>
            </div>
          </div>
        ) : (
          <div
            ref={drop}
            className="relative cursor-pointer w-full min-h-[150px] mt-8 flex justify-center items-center rounded-md border-2 border-dashed border-bobo-prop dark:border-dark-prop bg-bobo-proplight dark:bg-dark-lessfaded dark:hover:bg-gray-800 hover:bg-bobo-orangelight transition-colors duration-200">
            <span className="text-md font-sans font-normal p-3 text-center">
              {t('crm.modals.import-properties-dnd')}
            </span>

            <input
              type="file"
              className="absolute w-full h-full opacity-0 cursor-pointer"
              onChange={onFileSelect}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </div>
        )}
      </div>
      <div className={crmDialogTheme.buttonContainer}>
        <Button onClick={close} type={ButtonType.CANCEL}>
          {t('general.cancel')}
        </Button>

        <Button
          disabled={!selectedFile || !asset}
          type={ButtonType.ADD}
          onClick={() => {
            handleSubmit();
          }}>
          {t('general.analyze')}
        </Button>
      </div>
    </div>
  );
}

function CashflowImportRentRollLoading() {
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Scooter />
      </div>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          {t('crm.modals.import-properties-loading')}
        </Dialog.Title>
      </div>
    </div>
  );
}

function CashflowImportRentRollSubmit({
  newRentRows,
  setNewRentRows,
  close,
  setStep,
  setReload,
  setSelectedModel,
  model,
  asset
}: {
  newRentRows: CashflowLease[];
  setNewRentRows: (arg0: CashflowLease[]) => void;
  setStep: any;
  setReload: any;
  close: any;
  model: CashflowValuationModel;
  setSelectedModel: (arg0?: CashflowValuationModel) => void;
  asset: CashflowValuationAsset | undefined;
}) {
  const { t } = useTranslation('common');

  async function handleSubmit() {
    if (!asset) {
      toast.error(t('errors.missing-input-fields'));
      return;
    }

    const newProp = {
      ...asset,
      adjustableAssumptions: {
        ...asset.adjustableAssumptions,
        rentRoll: [...asset.adjustableAssumptions.rentRoll, ...newRentRows]
      }
    };
    setReload(true);
    setStep(stepNames.loading);
    updateAsset({
      assetToUpdate: newProp,
      model,
      setSelectedModel
    });
    setStep(stepNames.done);
  }

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.icon}>
        {newRentRows.length === 0 ? <Error /> : <Success />}
      </div>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          {newRentRows.length === 0
            ? t('crm.modals.import-properties-error')
            : t('property.cashflow-valuation.import-rentroll-analyzed')}
        </Dialog.Title>
        {newRentRows.length > 0 ? (
          <p className={crmDialogTheme.subtext}>
            {t('property.cashflow-valuation.import-rentroll-submit-subtext')}
          </p>
        ) : (
          <p className={crmDialogTheme.subtext}>
            {t('property.cashflow-valuation.import-rentroll-submit-try-again')}
          </p>
        )}

        <div className="overflow-x-auto overflow-y-auto mt-4">
          <table className="min-w-[400px] divide-y divide-gray-300">
            <thead>
              <tr>
                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-800">
                  {t('general.name')}
                </th>
                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-800">
                  {t('general.rent')}
                </th>
                <th className="px-4 py-2 text-right">{t('general.change')}</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {newRentRows.map((row, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="px-4 py-2 text-left text-sm text-gray-800">{row.name}</td>
                  <td className="px-4 py-2 text-left text-sm text-gray-800">
                    <NumberFormat
                      value={row.rent}
                      displayType={'text'}
                      thousandSeparator={` `}
                      suffix={t('general.kr-suffix')}
                      prefix={t('general.sek-prefix')}
                    />
                  </td>
                  <td className="px-4 py-2 text-right">
                    <button
                      onClick={() => setNewRentRows(newRentRows.filter((_, i) => i !== index))}
                      className="text-red-500 hover:text-red-700">
                      <XMarkIcon className="w-5 h-5 inline-block" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className={crmDialogTheme.buttonContainer}>
        <Button onClick={close} type={ButtonType.CANCEL}>
          {t('general.cancel')}
        </Button>
        <div className="flex gap-2">
          <Button onClick={() => setStep(1)}>{t('general.back')}</Button>
          <Button type={ButtonType.ADD} onClick={handleSubmit} disabled={newRentRows.length === 0}>
            {t('crm.user-actions.import')}
          </Button>
        </div>
      </div>
    </div>
  );
}

function CrmImportPropertiesError({ close, setStep }: { close: any; setStep: any }) {
  const { t } = useTranslation('common');
  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.icon}>
        <Error />
      </div>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          {t('errors.something-went-wrong')}
        </Dialog.Title>
        <p className={crmDialogTheme.subtext}>{t('crm.modals.import-properties-error-subtext')}</p>
      </div>

      <div className="w-full flex justify-between">
        <Button onClick={close}>{t('general.close')}</Button>
        <Button onClick={() => setStep(1)}>{t('general.back')}</Button>
      </div>
    </div>
  );
}

function CashflowImportRentRollDone({ close }: { close: any }) {
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.icon}>
        <Success />
      </div>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          {t('property.cashflow-valuation.import-rentroll-success')}
        </Dialog.Title>
        <p className={crmDialogTheme.subtext}>{t('crm.modals.import-properties-error-subtext')}</p>
      </div>
      <div className="w-full flex justify-end">
        <Button onClick={close}>{t('crm.modals.done')}</Button>
      </div>
    </div>
  );
}

export default function CashflowImportRentRoll({
  setReload,
  close,
  model,
  setSelectedModel
}: {
  close: () => void;
  setReload: (reload: boolean) => void;
  model: CashflowValuationModel;
  setSelectedModel: (arg0?: CashflowValuationModel) => void;
}) {
  const [step, setStep] = useState(stepNames.import);
  const [newRentRows, setNewRentRows] = useState<CashflowLease[]>([]);
  const [asset, setAsset] = useState<CashflowValuationAsset | undefined>(undefined);

  function closeAndNotify() {
    close();
  }

  let mod: JSX.Element = <div />;

  switch (step) {
    case stepNames.import:
      mod = (
        <DndProvider backend={HTML5Backend}>
          <CashflowImportRentRollImportStep
            model={model}
            setStep={setStep}
            close={close}
            setReload={setReload}
            setNewRentRows={setNewRentRows}
            asset={asset}
            setAsset={setAsset}
          />
        </DndProvider>
      );
      break;

    // Step to ensure user wants to proceed with the import
    case stepNames.submit:
      mod = (
        <CashflowImportRentRollSubmit
          setStep={setStep}
          setReload={setReload}
          close={closeAndNotify}
          model={model}
          newRentRows={newRentRows}
          setNewRentRows={setNewRentRows}
          setSelectedModel={setSelectedModel}
          asset={asset}
        />
      );
      break;

    case stepNames.error:
      mod = <CrmImportPropertiesError close={closeAndNotify} setStep={setStep} />;
      break;

    case stepNames.loading:
      mod = <CashflowImportRentRollLoading />;
      break;

    case stepNames.done:
      mod = <CashflowImportRentRollDone close={closeAndNotify} />;
      break;
  }

  return <>{mod}</>;
}
