import { Dialog, Transition } from '@headlessui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import cls from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GotoFeature } from 'shared/dist/types';

import CompanySlideIn from '@/src/layout/slide-in/templates/company';
import { CRMSlideInObject, SlideInType } from '@/src/types';

import CRM from './templates/crm';
import DP from './templates/detail-plan';
import HistoricPoint from './templates/historic-point';
import Info from './templates/info';
import LocalCouncil from './templates/local-council';
import PropBasic from './templates/property-basic';
import PropPermits from './templates/property-permits';
import RiskMapSlideOut from './templates/risk-map';
import Shelter from './templates/shelter';
import ZoningPlan from './templates/zoning-plan';

type Tab = {
  title: string;
  uuid: string;
  template: JSX.Element;
};

export default function SlideIn({
  isOpen,
  slideIn,
  type,
  close
}: {
  isOpen: boolean;
  slideIn: SlideInType | null;
  type: GotoFeature | false;
  close: any;
}) {
  const [tabs, setTabs] = useState<Tab[]>([]);
  const [activeTab, setActiveTab] = useState<string>();
  const [tmp, setTmp] = useState<GotoFeature | null>(slideIn?.type ?? null);

  const { t } = useTranslation('common');

  useEffect(() => {
    const object = slideIn?.object;

    if (slideIn) {
      const addTab: boolean = object.addTab ?? false;

      switch (slideIn.type) {
        case GotoFeature.LOCAL_COUNCIL: {
          const tmpTabs: Tab[] = addTab ? tabs : [];

          const tabExists = tmpTabs.find((tab) => tab.uuid === object?.uuid);

          if (!tabExists) {
            tmpTabs.unshift({
              title: `${object?.name} ${t('general.local-council').toLowerCase()}`,
              uuid: object?.uuid,
              template: <LocalCouncil object={object} close={close} />
            });
            setTabs([...tmpTabs]);
            setActiveTab(object?.uuid);
          } else {
            setActiveTab(tabExists.uuid);
          }
          break;
        }

        case GotoFeature.PROPERTY_PERMITS:
          setTabs([
            {
              template: <PropPermits key={object?.uuid} object={object} close={close} />,
              title: `${object?.property ?? t('submenu.title.building-permits')}`,
              uuid: object?.uuid
            }
          ]);
          setActiveTab(object?.uuid);
          break;

        case GotoFeature.SHELTER:
          setTabs([
            {
              template: <Shelter key={object?.uuid} object={object} close={close} />,
              title: object?.additional
                ? t('property.historic-point.safe-rooms-in-x', { additional: object?.additional })
                : t('property.historic-point.safe-rooms'),
              uuid: object?.uuid
            }
          ]);
          setActiveTab(object?.uuid);
          break;

        case GotoFeature.RISK_MAP:
          setTabs([
            {
              template: <RiskMapSlideOut key={object?.uuid} object={object} close={close} />,
              title: '',
              uuid: object?.uuid
            }
          ]);
          setActiveTab(object?.uuid);
          break;

        case GotoFeature.DETAIL_PLAN:
          setTabs([
            {
              template: <DP key={object?.uuid} object={object} close={close} />,
              title: `${t('general.plans')} ${object?.obj?.name}`,
              uuid: object?.uuid
            }
          ]);
          setActiveTab(object?.uuid);
          break;

        case GotoFeature.HISTORIC_POINT:
          setTabs([
            {
              template: <HistoricPoint key={object?.uuid} object={object} close={close} />,
              title: `${object?.details[0]?.properties?.antikv_bed}`,
              uuid: object?.uuid
            }
          ]);
          setActiveTab(object?.uuid);
          break;

        case GotoFeature.ZONINGPLAN:
          setTabs([
            {
              template: <ZoningPlan key={object?.uuid} object={object} close={close} />,
              title: `${object.title}`,
              uuid: object?.uuid
            }
          ]);
          setActiveTab(object?.uuid);
          break;

        case GotoFeature.INFO:
          setTabs([
            {
              template: <Info key={object?.uuid} object={object} close={close} />,
              title: `${object?.title}`,
              uuid: object?.uuid
            }
          ]);
          setActiveTab(object?.uuid);
          break;

        case GotoFeature.CRM: {
          const tmpTabs: Tab[] = addTab ? tabs : [];

          const { crmObject } = object as CRMSlideInObject;

          const tabExists = tmpTabs.find((tab) => tab.uuid === crmObject.uuid);

          if (!tabExists) {
            tmpTabs.unshift({
              title: `${crmObject.authority_nickname} [${t('crm.lists_one')}: ${
                crmObject.listName
              }]`,
              uuid: crmObject.uuid,
              template: <CRM key={object?.uuid} object={object} close={close} />
            });
            setTabs([...tmpTabs]);
            setActiveTab(crmObject.uuid);
          } else {
            setActiveTab(tabExists.uuid);
          }
          break;
        }

        // case GotoFeature.DOC:
        //   setTemplates(<DocView object={object} close={close} />)
        //   setTitles(`${object?.document_name}`)
        //   break;

        case GotoFeature.PROPERTY: {
          const tmpTabs: Tab[] = addTab ? tabs : [];

          const tabExists = tmpTabs.find((tab) => tab.uuid === object.uuid);

          if (!tabExists) {
            tmpTabs.unshift({
              title: `${object?.authority_nickname ?? object?.authorityNickname}`,
              uuid: object?.uuid,
              template: <PropBasic object={object} close={close} />
            });
            setTabs([...tmpTabs]);
            setActiveTab(object?.uuid);
          } else {
            setActiveTab(tabExists.uuid);
          }
          break;
        }
        case GotoFeature.COMPANY: {
          setTabs([
            {
              template: <CompanySlideIn key={object?.actor_uuid} object={object} close={close} />,
              title: `${object?.company_name}`,
              uuid: object?.actor_uuid
            }
          ]);
          setActiveTab(object?.actor_uuid);
          break;
        }
      }
    }
    window.scrollTo(0, 0);
  }, [slideIn?.object]);

  useEffect(() => {
    if (slideIn?.type) {
      setTmp(slideIn.type);
    }
  }, [slideIn?.type]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[52]" onClose={close}>
        {slideIn && slideIn.type !== GotoFeature.ZONINGPLAN && (
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-400 bg-opacity-75 transition-opacity" />
          </Transition.Child>
        )}
        <div className="fixed inset-0">
          <div className="absolute inset-0">
            <div
              className={`pointer-events-none fixed inset-y-0  ${
                tmp === GotoFeature.ZONINGPLAN ? 'left-0' : 'right-0'
              } flex max-w-full md:px-0`}>
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-600 sm:duration-400"
                enterFrom={
                  tmp === GotoFeature.ZONINGPLAN ? `-translate-x-full` : `translate-x-full`
                }
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-600 sm:duration-400"
                leaveFrom={tmp === GotoFeature.ZONINGPLAN ? 'translate-x-0' : 'translate-x-0'}
                leaveTo={tmp === GotoFeature.ZONINGPLAN ? '-translate-x-full' : 'translate-x-full'}>
                <Dialog.Panel
                  className={cls(
                    `pointer-events-auto bg-white dark:bg-dark-primary relative w-full md:w-[700px] mt-[0px]`,
                    {
                      'lg:!w-[1000px] xl:!w-[900px]':
                        type === GotoFeature.PROPERTY || type === GotoFeature.CRM
                    },
                    {
                      'lg:!w-[calc(100vw-50px)] xl:!w-[calc(100vw-50px)]':
                        type === GotoFeature.RISK_MAP
                    }
                  )}>
                  <div className="flex relative z-20">
                    {tabs?.length > 1 && (
                      <div className="text-xs absolute -left-60 text-white flex flex-col items-end justify-center space-y-1 mt-2">
                        {tabs?.map(({ title, uuid }) => {
                          return (
                            <button
                              onClick={() => setActiveTab(uuid)}
                              className={cls(
                                `p-2 relative z-10 rounded-l-md text-right transition-[color] duration-300 shadow-md`,
                                {
                                  'bg-bobo-prop dark:bg-dark-prop text-white w-[15rem]':
                                    uuid === activeTab
                                },
                                {
                                  'text-light-graytext bg-gray-50 dark:bg-dark-lessfaded w-[15rem]':
                                    uuid !== activeTab
                                }
                              )}
                              key={uuid}>
                              {title}
                            </button>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <div
                    className={`h-full z-20 abslute top-0 ${
                      tmp === GotoFeature.ZONINGPLAN ? 'right-0' : 'left-0'
                    } bg-white dark:bg-dark-primary shadow-xl border-l border-gray-100 dark:border-dark-morefaded mt-[-1px]`}>
                    <button
                      onClick={close}
                      className={`flex absolute top-2 ${
                        tmp === GotoFeature.ZONINGPLAN ? 'right-2' : 'left-2'
                      } transition-color duration-300 rounded-full shadow-xl p-2 z-40 bg-white hover:bg-bobo-prop hover:text-white dark:bg-dark-morefaded dark:hover:bg-dark-prophover`}>
                      {tmp === GotoFeature.ZONINGPLAN ? (
                        <ChevronLeftIcon className="h-6 w-6 cursor-pointer" />
                      ) : (
                        <ChevronRightIcon className="h-6 w-6 cursor-pointer" />
                      )}
                    </button>
                    {tabs &&
                      tabs?.length > 0 &&
                      tabs.find((tab) => tab.uuid === activeTab)?.template}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
