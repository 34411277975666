import {
  BanknotesIcon,
  BuildingOfficeIcon,
  ChartBarIcon,
  ChartPieIcon,
  ExclamationTriangleIcon,
  KeyIcon
} from '@heroicons/react/24/outline';

import { Location06Icon, MapsEditingIcon, PercentIcon } from '../assets/icons';

export const primaryTabs = {
  getPrimaryRoutes: function (
    uuid: string | null,
    second: string | null,
    third: string | null,
    t: any
  ) {
    return [
      {
        paths: [`/hitta`],
        tabs: null
      },
      {
        paths: [`/crm2/overview`],
        tabs: [
          {
            name: `${t('menu.boards')}`,
            href: '/crm2/overview',
            shallow: true,
            items: [
              {
                name: `${t('menu.all')}`,
                href: '/crm2/overview',
                shallow: true
              },
              {
                name: `${t('menu.private-boards')}`,
                href: '/crm2/overview/private',
                shallow: true
              },
              {
                name: `${t('menu.team')}`,
                href: '/crm2/overview/team',
                shallow: true
              },
              {
                name: `${t('menu.archive')}`,
                href: '/crm2/overview/archive',
                shallow: true
              }
            ]
          }
        ]
      },
      {
        paths: [
          `/crm/overview`,
          `/crm/overview/team`,
          `/crm/overview/private`,
          '/crm/teams/overview',
          `/crm/teams/${second}`
        ],

        tabs: [
          {
            name: `${t('menu.boards')}`,
            href: '/crm/overview',
            shallow: true,
            items: [
              {
                name: `${t('menu.all')}`,
                href: '/crm/overview',
                shallow: true
              },
              {
                name: `${t('menu.private-boards')}`,
                href: '/crm/overview/private',
                shallow: true
              },
              {
                name: `${t('menu.team')}`,
                href: '/crm/overview/team',
                shallow: true
              },
              {
                name: `${t('menu.archive')}`,
                href: '/crm/overview/archive',
                shallow: true
              }
            ]
          }
        ]
      },
      {
        paths: [`/crm/${uuid}`],
        tabs: [
          {
            name: `${t('general.back')}`,
            href: '/crm/overview',
            shallow: true
          }
        ]
      },
      {
        paths: [`/dashboard/overview`, `/dashboard/status`, `/dashboard/latest`],
        tabs: [
          {
            name: `${t('general.overview')}`,
            href: '/dashboard/overview',
            shallow: true
          }
        ]
      },
      {
        paths: [
          `/urban-development/overview`,
          `/urban-development/zoning`,
          `/urban-development/demographics`
        ],
        tabs: [
          {
            name: `${t('menu.urban-development')}`,
            href: '/urban-development/overview',
            shallow: true,
            items: [
              {
                name: `${t('menu.overview')}`,
                href: `/urban-development/overview`,
                shallow: true
              },
              {
                name: `${t('menu.zoning')}`,
                href: `/urban-development/zoning`,
                shallow: true
              },
              {
                name: `${t('menu.zp-status')}`,
                href: `/urban-development/status`,
                shallow: true
              }
            ]
          }
        ]
      },
      {
        paths: [`/crm/${uuid}/${second}/${third}`],
        tabs: [
          {
            name: `${t('menu.back-to-board')}`,
            href: `/crm/${uuid}`,
            shallow: true
          },
          {
            name: `${t('menu.dashboard')}`,
            href: `/crm/${uuid}/${second}/${third}/dashboard`,
            shallow: true
          },
          {
            name: `${t('menu.compare')}`,
            href: `/crm/${uuid}/${second}/${third}/compare`,
            shallow: true
          },
          {
            name: `${t('menu.risks')}`,
            href: `/crm/${uuid}/${second}/${third}/esg`,
            shallow: true
          },
          process.env.NEXT_PUBLIC_FEATURE_CASHFLOW_VALUATION_CRM_ENABLED == 'true'
            ? {
                name: `${t('property.cashflow-valuation.routes.header')}`,
                href: `/crm/${uuid}/${second}/${third}/cashflow`,
                shallow: true
              }
            : null
        ]
      },
      {
        paths: ['/kommuner/overview'],
        tabs: []
      },
      {
        paths: [
          `/kommuner/kommun/${uuid}/${second}`,
          `/kommuner/kommun/${uuid}/${second}/overview`,
          `/kommuner/kommun/${uuid}/${second}/detaljplaner`
        ],
        tabs: [
          {
            name: `${t('general.local-council')}`,
            href: `/kommuner/kommun/${uuid}/${second}/overview`,
            shallow: true,
            index: true,
            items: [
              {
                name: `${t('general.overview')}`,
                href: `/kommuner/kommun/${uuid}/${second}/overview`,
                shallow: true
              },
              process.env.NEXT_PUBLIC_STAGE !== 'production'
                ? {
                    name: t('general.demographics'),
                    href: `/kommuner/kommun/${uuid}/${second}/demographics`,
                    shallow: true
                  }
                : undefined,
              {
                name: t('property.plans.heading'),
                href: `/kommuner/kommun/${uuid}/${second}/detaljplaner`,
                shallow: true
              }
            ]
          }
        ]
      },
      {
        paths: [`/crm/fastighet/${uuid}`],
        tabs: [
          {
            name: t('general.property-def'),
            href: `/crm/fastighet/${uuid}/overview`,
            icon: BuildingOfficeIcon,
            shallow: true,
            items: [
              {
                name: `${t('general.overview')}`,
                href: `/crm/fastighet/${uuid}/overview`,
                shallow: true
              },
              {
                name: t('submenu.title.prop-card-apartments'),
                href: `/crm/fastighet/${uuid}/apartments`,
                shallow: true,
                only: ['housing']
              },
              {
                name: t('submenu.title.contractors'),
                href: `/crm/fastighet/${uuid}/suppliers`,
                shallow: true
              },
              {
                name: t('submenu.title.prop-card-easement'),
                href: `/crm/fastighet/${uuid}/easements`,
                level: 2,
                shallow: true
              },
              {
                name: t('submenu.title.prop-card-coop'),
                level: 2,
                href: `/crm/fastighet/${uuid}/coops`,
                default: true
              }
            ]
          },
          {
            name: t('submenu.title.economy'),
            href: `/crm/fastighet/${uuid}/taxation`,
            icon: BanknotesIcon,
            shallow: true,
            items: [
              {
                name: t('submenu.title.tax'),
                href: `/crm/fastighet/${uuid}/taxation`,
                shallow: true
              },
              {
                name: t('submenu.title.prop-card-marketdata'),
                href: `/crm/fastighet/${uuid}/mdat`,
                shallow: true
              },

              {
                name: t('submenu.title.legal-owners'),
                href: `/crm/fastighet/${uuid}/owner`,
                shallow: true
              },
              {
                name: t('submenu.title.prop-card-deeds'),
                href: `/crm/fastighet/${uuid}/deeds`,
                level: 2,
                shallow: true
              }
            ]
          },
          {
            name: t('general.rents'),
            href: `/crm/fastighet/${uuid}/rent-overview`,
            icon: KeyIcon,
            shallow: true,
            items: [
              {
                name: t('property.rent-summary.header'),
                href: `/crm/fastighet/${uuid}/rent-overview`,
                shallow: true
              },
              {
                name: t('general.rent-calculations'),
                href: `/crm/fastighet/${uuid}/rentcalculation`,
                default: false,
                only: ['housing', 'space', 'warehouse']
              },
              {
                name: t('submenu.title.prop-card-rentstats'),
                href: `/crm/fastighet/${uuid}/rent`,
                shallow: true,
                only: ['housing', 'space', 'warehouse']
              },
              {
                name: t('submenu.title.prop-card-nearby'),
                href: `/crm/fastighet/${uuid}/similar`,
                shallow: true,
                only: ['housing', 'space', 'warehouse']
              },
              {
                name: t('submenu.title.brokered-apartments'),
                href: `/crm/fastighet/${uuid}/rent-brokeredAp`,
                default: true,
                only: ['housing']
              },
              {
                name: t('submenu.title.tenants'),
                href: `/crm/fastighet/${uuid}/rent-tenants`,
                default: false
              }
            ]
          },
          {
            name: t('submenu.title.risk'),
            href: `/crm/fastighet/${uuid}/risk_v2`,
            icon: ExclamationTriangleIcon,
            shallow: true,
            items: [
              {
                name: t('risk.risk-table'),
                href: `/crm/fastighet/${uuid}/risk_v2`,
                shallow: true
              },
              process.env.NEXT_PUBLIC_STAGE !== 'production'
                ? {
                    name: t('submenu.title.climate-taxonomy'),
                    href: `/crm/fastighet/${uuid}/climate_v3`,
                    shallow: true
                  }
                : undefined,
              {
                name: t('submenu.title.energy'),
                href: `/crm/fastighet/${uuid}/energi`,
                default: true
              },
              process.env.NEXT_PUBLIC_STAGE !== 'production'
                ? {
                    name: t('submenu.title.geodata'),
                    href: `/crm/fastighet/${uuid}/geo_v2`,
                    shallow: true
                  }
                : undefined,
              {
                name: t('geo.heading'),
                href: `/crm/fastighet/${uuid}/geo`,
                shallow: true
              },
              {
                name: t('submenu.title.custom-geo'),
                href: `/crm/fastighet/${uuid}/customgeo`,
                shallow: true
              },
              {
                name: t('submenu.title.natural-disasters'),
                href: `/crm/fastighet/${uuid}/natural-disasters`,
                shallow: true
              }
            ]
          },
          {
            name: t('submenu.title.urban-development'),
            href: `/crm/fastighet/${uuid}/bygglov-planer`,
            icon: MapsEditingIcon,
            shallow: true,
            items: [
              {
                name: t('property.plans.heading'),
                href: `/crm/fastighet/${uuid}/bygglov-planer`,
                shallow: true
              },
              {
                name: t('submenu.title.building-permits'),
                href: `/crm/fastighet/${uuid}/bygglov`,
                default: true
              },
              {
                name: t('property.power-grid-short'),
                href: `/crm/fastighet/${uuid}/powergrid`,
                default: true
              }
            ]
          },
          {
            name: t('general.demographics'),
            href: `/crm/fastighet/${uuid}/demographics`,
            icon: ChartPieIcon,
            shallow: true
          },
          {
            name: t('submenu.title.generic-map'),
            href: `/crm/fastighet/${uuid}/karta`,
            icon: Location06Icon,
            shallow: true
          },
          process.env.NEXT_PUBLIC_FEATURE_CASHFLOW_VALUATION_ENABLED == 'true'
            ? {
                name: t('property.cashflow-valuation.routes.header'),
                href: `/crm/fastighet/${uuid}/cashflow-overview`,
                icon: ChartBarIcon,
                shallow: true
              }
            : undefined,
          process.env.NEXT_PUBLIC_FEATURE_VALUE_INDICATION_ENABLED == 'true'
            ? {
                name: t('property.value-indication.header'),
                icon: PercentIcon,
                href: `/crm/fastighet/${uuid}/vardeindikation`,
                shallow: true
              }
            : undefined
        ]
      },
      process.env.NEXT_PUBLIC_STAGE !== 'production'
        ? {
            paths: ['/bolag/filter']
          }
        : undefined,
      {
        paths: [`/company`],
        tabs: [
          {
            name: t('submenu.title.companies'),
            href: `/company/owners`,
            shallow: true
          }
        ]
      },
      {
        paths: [`/profile`, `/companyprofile`, `/teams/${uuid}`],
        tabs: [
          {
            name: `${t('menu.my-profile')}`,
            href: '/profile/me',
            shallow: true,
            items: [
              {
                name: `${t('menu.account')}`,
                href: `/profile/me`
              },
              {
                name: `${t('menu.security')}`,
                href: `/profile/security`
              },
              {
                name: `${t('menu.my-companies')}`,
                href: `/profile/active-actors`
              },
              {
                name: `${t('menu.sessions')}`,
                href: `/profile/sessions`
              },
              {
                name: `${t('menu.forget-me')}`,
                href: `/profile/forgetme`
              }
            ]
          },
          {
            name: `${t('menu.company-settings')}`,
            href: '/companyprofile/settings',
            shallow: true,
            items: [
              {
                name: `${t('menu.company-info')}`,
                href: `/companyprofile/settings`,
                default: true
              },
              {
                name: `${t('menu.handle-users')}`,
                href: `/companyprofile/team`
              },
              process.env.NEXT_PUBLIC_FEATURE_VALUE_INDICATION_ENABLED == 'true'
                ? {
                    name: t('property.value-indication.header'),
                    href: `/companyprofile/value-indication-orders`
                  }
                : undefined
            ]
          }
        ]
      },
      {
        paths: [`/crm/${uuid}`],
        tabs: [
          {
            name: `${t('menu.boards')}`,
            href: `/crm/${uuid}`,
            shallow: true,
            items: [
              {
                name: `${t('menu.all')}`,
                href: '/crm/overview',
                shallow: true
              },
              {
                name: `${t('menu.my')}`,
                href: '/crm/overview/my',
                shallow: true
              },
              {
                name: `${t('menu.team')}`,
                href: '/crm/overview/team',
                shallow: true
              }
            ]
          }
        ]
      },
      {
        paths: [`/mdat`],
        tabs: [
          {
            name: t('pages.mdat.market-data-lc'),
            href: '/mdat/data',
            shallow: true,
            items: [
              {
                name: t('general.local-councils'),
                href: `/mdat/data`,
                default: true
              }
            ]
          }
        ]
      },
      {
        paths: [`/itismad`],
        tabs: [
          {
            name: t('menu.dashboard'),
            href: '/itismad/overview',
            shallow: true
          },
          {
            name: t('submenu.title.itismad-map'),
            href: '/itismad/map',
            shallow: true
          },
          {
            name: t('submenu.title.activities'),
            href: '/itismad/activities',
            shallow: true
          },
          {
            name: t('general.deliverables'),
            href: '/itismad/deliverables',
            shallow: true
          }
        ]
      },
      process.env.NEXT_PUBLIC_STAGE !== 'production'
        ? {
            paths: ['/data-status']
          }
        : null
    ]
      .filter((item) => !!item) // filter out undefined / empty tabs
      .map((item) => {
        if (item?.tabs && item.tabs.length > 0) {
          return {
            ...item,
            tabs: (item.tabs as any[])
              .filter((item) => !!item)
              .map((tab) => {
                if (tab.items && tab.items.length > 0) {
                  return {
                    ...tab,
                    items: (tab.items as any[]).filter((item) => !!item)
                  };
                } else {
                  return tab;
                }
              })
          };
        } else {
          return item;
        }
      });
  }
};
