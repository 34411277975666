import { useState } from 'react';

export function useScrollWithShadow() {
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);
  const [fixedTableHeader, setFixedTableHeader] = useState<number>(0);

  const onScrollHandler = (event) => {
    setScrollTop(event.target.scrollTop);
    setScrollHeight(event.target.scrollHeight);
    setClientHeight(event.target.clientHeight);

    const tableHeader = document.getElementById(`primaryTableHeader`);

    if (tableHeader) {
      if (
        tableHeader?.getBoundingClientRect().top < 90 &&
        fixedTableHeader < 1 &&
        tableHeader?.parentElement
      ) {
        tableHeader.className = `fixed dark:bg-dark-lessfaded border-gray-100 dark:border-gray-600 bg-white z-10`;
        tableHeader.style.width = `${tableHeader?.parentElement?.clientWidth + 0}px`;
        tableHeader.style.top = `65px`;
        tableHeader.style.paddingLeft = `0px`;
        tableHeader.style.marginLeft = `0px`;
        setFixedTableHeader(event?.target?.scrollTop);

        if (
          tableHeader?.parentElement?.children &&
          tableHeader?.parentElement?.children?.length > 0 &&
          tableHeader?.parentElement?.children[1]?.firstChild?.childNodes
        ) {
          for (
            let a = 0;
            a < tableHeader?.parentElement?.children[1]?.firstChild?.childNodes?.length;
            a++
          ) {
            const bb: HTMLElement = tableHeader?.parentElement?.children[1]?.firstChild?.childNodes[
              a
            ] as HTMLElement;
            const cc: HTMLElement = tableHeader?.firstChild?.childNodes[a] as HTMLElement;
            if (cc && cc?.style) {
              cc.style.width = `${bb.clientWidth}px`;
            }
          }
        }
      } else {
        if (event?.target?.scrollTop < fixedTableHeader && fixedTableHeader !== 0) {
          tableHeader.className = `relative bg-white dark:bg-dark-lessfaded `;
          tableHeader.style.top = `auto`;
          setFixedTableHeader(0);
        }
      }
      //
    }
  };

  function getBoxShadow() {
    return !(scrollTop === 0);
  }

  return { boxShadow: getBoxShadow(), onScrollHandler };
}
