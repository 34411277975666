import _ from 'lodash';
import { getRegionName } from 'shared/dist/helpers/demographics';
import { getEnergyClasses } from 'shared/dist/util/energy';

export const getExportDataOwner = (t, owner) => {
  const orgNr: string =
    owner?.actor_id?.toString().substring(0, 6) + '-' + owner?.actor_id?.toString().substring(6);
  return {
    name: owner?.name,
    address: owner?.address,
    actor_id: owner?.actor_id?.length === 10 ? orgNr : '-',
    town: owner?.town,
    postcode: owner?.postcode?.toString(),
    phone: owner?.phone && owner?.phone !== 'NULL' ? owner?.phone?.toString() : '-',
    root_prop_count: owner?.root_prop_count?.toString(),
    match_count: owner?.match_count?.toString(),
    root_total_boa: owner?.root_total_boa?.toString(),
    root_total_loa: owner?.root_total_loa?.toString(),
    root_unit_count: owner?.root_unit_count?.toString(),
    sni_code: owner?.sni_code,
    root_total_companies: owner?.child_actors?.length?.toString()
  };
};

export const getExportDataTenant = (t, tenant) => {
  const orgNr: string =
    tenant?.actor_id?.toString().substring(0, 6) + '-' + tenant?.actor_id?.toString().substring(6);
  const officeName: string =
    tenant?.office_name &&
    tenant?.office_name?.length > 0 &&
    tenant?.office_name !== tenant?.company_name
      ? tenant?.office_name
      : '-';

  const isMainOffice: string =
    tenant?.office_type && tenant?.office_type !== 'undefined' ? t('general.yes') : '-';

  const ownerGeneric: string = (
    _.uniqBy<any>(
      tenant?.lease_holders?.length > 0 ? tenant?.lease_holders : tenant?.land_owners,
      'actor_id'
    ) || []
  )
    ?.map((owner) => {
      if (!owner.name && owner.actor_id.length > 10) {
        return t('property.land-owners-private-person');
      } else {
        return owner.name;
      }
    })
    .join(', ');

  const employeeInterval: string =
    tenant?.employee_interval?.length > 0
      ? `${tenant?.employee_interval[0]} - ${tenant?.employee_interval[1]}`
      : `-`;

  return {
    ...tenant,
    company_name: tenant?.company_name,
    actor_id: orgNr,
    office_name: officeName,
    office_type: isMainOffice,
    sni_code: tenant?.sni_code,
    sni_desc: tenant?.sni_desc,
    employee_interval: employeeInterval,
    phone: tenant?.phone && tenant?.phone !== 'NULL' ? tenant?.phone : '-',
    visit_address: tenant?.visit_address,
    visit_town: tenant?.visit_town,
    tenant_branch_count: tenant?.tenant_branch_count?.toString(),
    owners_generic: ownerGeneric,
    land_lease:
      tenant?.land_owners?.lease_holders?.length > 0
        ? t('property.land-lease')
        : t('property.legal-owner'),
    authority_nickname: tenant?.authority_nickname,
    county: tenant?.county,
    tax_codes: tenant?.taxCodes?.join(', '),
    plot_area: tenant?.plot_area?.toString(),
    gross_residential_area: tenant?.gross_residential_area?.toString(),
    gross_leasable_area: tenant?.gross_leasable_area?.toString(),
    build_year: tenant?.build_year?.toString(),
    value_year: tenant?.value_year?.toString()
  };
};

export const getExportDataProperty = (t, p, location: 'crm' | 'advanced') => {
  const addresses = (p.addresses || [])
    .map((addr) => {
      return `${addr?.street} ${addr?.street_no > 0 ? addr?.street_no : ''}${
        addr?.street_letter && addr?.street_letter?.length > 0 ? addr?.street_letter : ''
      } ${addr?.postal_code} ${addr?.postal_area}`;
    })
    .join(`, `);

  const landOwners = (_.uniqBy<any>(p.land_owners, 'actor_id') || [])
    .filter((owner) => !!owner.actor_id)
    .map((owner) => {
      if (!owner.name && owner.actor_id.length > 10) {
        return t('property.land-owners-private-person');
      } else {
        return owner.name;
      }
    })
    .join(', ');

  const landOwnersOrgNrs = (_.uniqBy<any>(p.land_owners, 'actor_id') || [])
    .map((x) => {
      if (x.actor_id?.length <= 10) {
        return x.actor_id;
      } else {
        return t('property.land-owners-private-person');
      }
    })
    .join(', ');

  const leaseHolders = (_.uniqBy<any>(p.lease_holders, 'actor_id') || [])
    ?.map((owner) => {
      if (!owner.name && owner.actor_id.length > 10) {
        return t('property.land-owners-private-person');
      } else {
        return owner.name;
      }
    })
    .join(', ');

  const leaseHoldersOrgNrs = (_.uniqBy<any>(p.lease_holders, 'actor_id') || [])
    .map((x) => {
      if (x.actor_id?.length <= 10) {
        return x.actor_id;
      } else {
        return t('property.land-owners-private-person');
      }
    })
    .join(', ');

  const coordinates = `${t('units.lat', {
    lat: Number(p.lat).toFixed(5)
  })} / ${t('units.long', {
    lng: Number(p.lng).toFixed(5)
  })}`;

  const propertyData = {
    ...p,
    authority_nickname: (p.authorityNickname ?? p.authority_nickname ?? '')
      .replace(p.county, '')
      .trim(),
    tax_codes: p.tax_codes?.join(', '),
    owner_generic: p.lease_holders?.length > 0 ? leaseHolders : landOwners,
    county: p.county,
    region: getRegionName(p.region_code),
    plot_area: p.plot_area?.toString(),
    gross_residential_area: p.gross_residential_area?.toString(),
    gross_leasable_area: p.gross_leasable_area?.toString(),
    build_year: p.build_year?.join(', '),
    value_year: p.value_year?.join(', '),
    addresses_str: addresses,
    number_apartments: p.number_apartments?.toString(),
    energy_class: p.energy_class ?? '-',
    atemp: p.energyStatement?.primary_energy_number ?? '-',
    energy_level: p.energyStatement?.energy_level ?? '-',
    land_owners_str: landOwners,
    land_owners_org_nr: landOwnersOrgNrs,
    land_owners_root_actors_str: (_.uniqBy<any>(p.land_owners, 'root_actor_id') || [])
      .filter((owner) => !!owner.actor_id)
      .map((owner) => {
        if (!owner.root_actor_name) {
          return '-';
        }
        if (!owner.root_actor_name && owner.root_actor_id.length > 10) {
          return t('property.land-owners-private-person');
        } else {
          return owner.root_actor_name;
        }
      })
      .join(', '),
    land_lease: p.lease_holders?.length > 0 ? t('property.land-lease') : t('property.legal-owner'),
    lease_fee: (p.landLeaseAgreement?.lease_fee ?? '-').toString(),
    lease_annum: (p.landLeaseAgreement?.lease_annum ?? '-').toString(),
    lease_holder: leaseHolders,
    lease_holder_org_nr: leaseHoldersOrgNrs,
    tax_owners: (_.uniqBy<any>(location === 'crm' ? p.tax_owners : p.owner, 'actor_id') || [])
      ?.map((o) => {
        if (o?.actor_id?.startsWith(`0000`) || o?.actor_id?.length > 10) {
          return `${o?.name ?? o?.company_name}`;
        } else {
          return `${o?.name ?? o?.company_name}(${o?.actor_id})`;
        }
      })
      .join(', '),
    tax_owners_root_actor: (
      _.uniqBy<any>(location === 'crm' ? p.tax_owners : p.owner, 'actor_id') || []
    )
      ?.map((o) => {
        if (!o?.root_actor_id) {
          return '-';
        }
        if (o?.actor_id?.startsWith(`0000`) || o?.actor_id?.length > 10) {
          return `${o?.root_actor_name}`;
        } else {
          return `${o?.root_actor_name}(${o?.root_actor_id})`;
        }
      })
      .join(', '),
    tax_area: p.tax_area?.toString(),
    total_tax_value: p.total_tax_value?.toString(),
    residential_rent: (p.residential_rent ?? 0)?.toString(),
    office_rent: (p.office_rent ?? 0)?.toString(),
    warehouse_rent: Math.round(p.warehouse_rent ?? 0)?.toString(),
    tenant_count: (p.tenant_count ?? 0).toString(),
    employee_total: (p.employee_total ?? 0).toString(),
    coordinates: coordinates
  };

  return propertyData;
};

export const getExportDataCrm = (t, data, onlyList = false) => {
  const exportData: any[] = [];

  const getPropertyData = (p, listName, boardName) => {
    const propertyData = getExportDataProperty(t, p, 'crm');

    const energyClasses: string[] = getEnergyClasses(p.energyStatement);

    const energyClass =
      energyClasses && energyClasses.length > 0
        ? energyClasses.length === 1 || energyClasses[0] === energyClasses[energyClasses.length - 1]
          ? `${energyClasses[0]}`
          : `${energyClasses[0]}-${energyClasses[energyClasses.length - 1]}`
        : '-';

    const energyNumbers: number[] = p.energyStatement
      ?.map((es) => {
        if (es?.energy_level && es?.energy_level.length > 0) {
          return +es?.energy_level?.replace(' kWh/m² och år', '');
        }
      })
      .filter((a) => a);

    const energyLevel =
      energyNumbers && energyNumbers.length > 0
        ? energyNumbers.length === 1 ||
          Math.min.apply(0, energyNumbers) === Math.max.apply(0, energyNumbers)
          ? `${energyNumbers[0]} ${t('property.energy-level-value')}`
          : `${Math.min.apply(0, energyNumbers)}-${Math.max.apply(0, energyNumbers)} ${t(
              'property.energy-level-value'
            )}`
        : '-';

    const primaryEnergyNumbers: number[] = p.energyStatement
      ?.map((es) => {
        if (es?.primary_energy_number && es?.primary_energy_number?.length > 0) {
          return +es?.primary_energy_number?.replace(' kWh/m² och år', '');
        }
      })
      .filter((a) => a);

    const atemp =
      primaryEnergyNumbers && primaryEnergyNumbers.length > 0
        ? primaryEnergyNumbers.length === 1 ||
          Math.min.apply(0, primaryEnergyNumbers) === Math.max.apply(0, primaryEnergyNumbers)
          ? `${primaryEnergyNumbers[0]} ${t('property.energy-level-value')}`
          : `${Math.min.apply(0, primaryEnergyNumbers)}-${Math.max.apply(
              0,
              primaryEnergyNumbers
            )} ${t('property.energy-level-value')}`
        : '-';

    return {
      ...propertyData,
      board: boardName,
      list: listName,
      energy_class: energyClass,
      energy_level: energyLevel,
      atemp: atemp
    };
  };
  if (onlyList) {
    data?.properties?.map((p) => {
      const propertyData = getPropertyData(p, data.name, data.boardName);
      exportData.push(propertyData);
    });
  } else {
    data?.lists?.map((l) => {
      l?.properties?.map((p) => {
        const propertyData = getPropertyData(p, l.name, data.title);
        exportData.push(propertyData);
      });
    });
  }

  return exportData;
};
