import RiskMapMain from '@/src/components/risk-v2/maps/risk-map-main';

export default function RiskMapSlideOut({ object }: { object: any; close: any }) {
  return (
    <div className="w-full h-[100dvh]">
      <RiskMapMain
        esgData={object}
        geoData={object}
        object={object}
        slideIn={true}
        overlays={['risk-overview']}
        riskView={'summary'}
        reportingLayers={[]}
      />
    </div>
  );
}
