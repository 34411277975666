/* This example requires Tailwind CSS v2.0+ */
import cls from 'classnames';

import InfoButton from '../widgets/info-button';

export default function ColWrapper({
  children,
  helpTextId,
  title,
  head,
  list,
  mapOdd
}: {
  children?: JSX.Element | JSX.Element[] | string;
  helpTextId?: string;
  title: JSX.Element | string;
  head?: boolean;
  theme?: string;
  list?: boolean;
  mapOdd?: boolean;
}) {
  return (
    <div
      className={cls(
        'flex w-full flex-col p-2 text-[12px] even:bg-gray-50 dark:even:bg-dark-faded dark:text-white rounded-b-md',
        { 'bg-gray-50 dark:bg-dark-faded': mapOdd }
      )}>
      <div className={cls('flex items-center justify-start w-full')}>
        <div className="w-full flex justify-between">
          <div>
            {list ? (
              <div className="w-2 h-2 bg-bobo-prop dark:bg-dark-prop rounded-full mr-2" />
            ) : null}
            {title}
          </div>

          {helpTextId ? (
            <div>
              <InfoButton small helpTextId={helpTextId} />
            </div>
          ) : null}
        </div>
      </div>

      {!list ? (
        <div
          className={cls(
            'w-full text-right justify-end flex items-start mt-3',
            { 'font-[500]': head },
            { 'font-normal': !head }
          )}>
          {children}
        </div>
      ) : null}
    </div>
  );
}
