import cls from 'classnames';
import { useTranslation } from 'next-i18next';
import { useGlobal } from 'reactn';

import { SidebarLeftIcon, SidebarRight01Icon } from '@/src/assets/icons';
import TeamsMenuOptions from '@/src/components/teams/items/teams-menu-options';
import { useBreakpoint } from '@/src/hooks/use-breakpoint';
import { ExportESGGrid } from '@/src/widgets/export-esg-grid';
import CashflowMenu from '@/src/widgets/menu-options/cashflow';
import CrmButton from '@/src/widgets/menu-options/crm-button';
import CRMListExportBtn from '@/src/widgets/menu-options/crm-list-export';
import CRMListOptions from '@/src/widgets/menu-options/crm-list-options';
import CRMOverviewMenuOptions from '@/src/widgets/menu-options/crm-overview';
import CRM2OverviewMenuOptions from '@/src/widgets/menu-options/crm2-overview';
import ForumDeliverableBtn from '@/src/widgets/menu-options/forum-create-deliverable';
import ForumExportBtn from '@/src/widgets/menu-options/forum-export';
import ForumImportBtn from '@/src/widgets/menu-options/forum-import';
import ItIsMadDownloadButtons from '@/src/widgets/menu-options/itismad-download-buttons';
import ItIsMadMapButton from '@/src/widgets/menu-options/itismad-map-btn';
import ItIsMadAddButton from '@/src/widgets/menu-options/itismad-upload-button';
import MapLayerDropdown from '@/src/widgets/menu-options/map-layer-dropdown';
import MemberInviteButton from '@/src/widgets/menu-options/member-invite';
import ReportsBtn from '@/src/widgets/menu-options/reports-button';
import RiskExport from '@/src/widgets/menu-options/risk-export';
import RiskLevels from '@/src/widgets/menu-options/risk-levels';
import RiskWeights from '@/src/widgets/menu-options/risk-weights';
import ValuationToolBtn from '@/src/widgets/menu-options/valuation-tool-button';

export default function MainPageWrapper({
  title,
  sources = [],
  object,
  grid,
  children,
  menuOptions
}: {
  title: string | JSX.Element;
  sources?: string[];
  object?: any;
  grid?: any;
  children: JSX.Element | JSX.Element[];
  menuOptions?: string[];
}) {
  const [hideSubMenu, setHideSubMenu] = useGlobal<any[]>('hideSubMenu' as never);

  const { isLg } = useBreakpoint({ breakpoint: 'lg' });

  const { t } = useTranslation('common');

  const isMobileOrTabletPortrait = !isLg;

  const options: JSX.Element[] = [];
  menuOptions &&
    menuOptions.map((option) => {
      switch (option) {
        case 'reports':
          options.push(<ReportsBtn property={object} />);
          break;
        case 'crm':
          options.push(<CrmButton property={object} />);
          break;
        case 'crm_menu_options':
          options.push(<CRMOverviewMenuOptions key={`crm_overview`} />);
          break;
        case 'crm2_menu_options':
          options.push(<CRM2OverviewMenuOptions key={`crm2_overview`} />);
          break;
        case 'valuation_tool':
          options.push(<ValuationToolBtn property={object} />);
          break;
        case 'forum_import':
          options.push(<ForumImportBtn property={object} />);
          break;
        case 'itismad-add-btn':
          options.push(<ItIsMadAddButton />);
          break;
        case 'at_invite':
          options.push(<MemberInviteButton />);
          break;
        case 'itismad-map-btn':
          options.push(<ItIsMadMapButton localCouncilCode={object.localCouncilCode} />);
          break;
        case 'itismad-download-btns':
          options.push(
            <ItIsMadDownloadButtons
              data={object.data}
              councilCode={object.localCouncilCode ?? null}
            />
          );
          break;
        case 'crm_options':
          options.push(<CRMListOptions currentBoard={object} />);
          break;
        case 'crm_list_export':
          options.push(<CRMListExportBtn list={object} />);
          break;
        // case 'forum_export':
        //   options.push(<ForumExportBtn property={object} />);
        //   break;
        case 'forum_export':
          options.push(<ForumExportBtn property={object} />);
          break;
        case 'forum_deliverable':
          options.push(<ForumDeliverableBtn property={object} />);
          break;
        case 'risk_export':
          options.push(<RiskExport property={object} />);
          break;
        case 'risk_levels':
          options.push(<RiskLevels property={object} />);
          break;
        case 'risk_weights':
          options.push(<RiskWeights property={object} />);
          break;
        case 'map-layer-toggle':
          options.push(<MapLayerDropdown />);
          break;
        case 'cashflow':
          options.push(<CashflowMenu />);
          break;
        case 'teams':
          options.push(<TeamsMenuOptions />);
          break;
        case 'export-esg-list':
          options.push(<ExportESGGrid grid={grid} />);
          break;
      }
    });

  return (
    <div
      className={cls(
        'dark:bg-dark-primary relative w-full max-w-[calc(100vw-52px-230px)] bg-white lg:pt-[10px]',
        { '!max-w-[calc(100vw-52px)]': hideSubMenu, '!max-w-full': isMobileOrTabletPortrait }
      )}>
      {isLg ? (
        <div className="w-full border-b border-gray-200 dark:border-dark-morefaded px-[17px] lg:px-[20px] hidden lg:flex justify-between">
          <div className="flex justify-between pt-[10px] pb-[19px] pr-[15px] lg:pt-[15px]">
            <div className="flex items-start justify-start font-medium text-sm break-all">
              <button
                className="hover:text-bobo-prop dark:hover:text-dark-prophover transition-colors duration-200 pt-[4px]"
                onClick={() => {
                  setHideSubMenu(!hideSubMenu);
                }}>
                {hideSubMenu ? (
                  <SidebarRight01Icon className="dark:text-white" />
                ) : (
                  <SidebarLeftIcon className="dark:text-white" />
                )}
              </button>

              <div
                className={cls(`ml-3 text-sm lg:text-xl font-demi leading-[20px] hidden lg:block`)}>
                <div>
                  {hideSubMenu &&
                    object?.authority_nickname &&
                    `${object?.authority_nickname?.replace(object?.county, '')}: `}
                  {title}
                </div>

                {sources && sources?.length > 0 ? (
                  <div className="text-[11px] leading-[11px] italic text-gray-500 dark:text-gray-400 font-normal">
                    {t('general.sources', { sources: sources.join(', ') })}
                  </div>
                ) : (
                  <div className="h-[11px] w-12"></div>
                )}
              </div>
            </div>
          </div>

          <div className="flex items-center justify-end space-x-3">
            {options?.map((elem, idx) => {
              return <div key={idx}>{elem}</div>;
            })}
          </div>
        </div>
      ) : (
        <></>
      )}
      {children}
    </div>
  );
}
