export const lcAreaLayer = {
  'fill-color': '#fff',
  'fill-opacity': ['case', ['boolean', ['feature-state', 'selected'], false], 0.3, 0.0]
};

export const lcLineLayer = {
  'line-width': ['case', ['boolean', ['feature-state', 'selected'], false], 3.0, 1.0],
  'line-dasharray': [2, 2],
  'line-color': '#FB923C',
  'line-opacity': 0.8
};
