import { Menu } from '@headlessui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { MapsEditingIcon } from '@/src/assets/icons';

import MapLayerToggle from './map-layer-toggle';

const MapLayerDropdown: React.FC = () => {
  const { t } = useTranslation('common');

  return (
    <Menu as="div" className="relative inline-block text-left z-50">
      <div>
        <Menu.Button className="flex items-center gap-1 border bg-gray-700 dark:bg-gray-500 hover:bg-bobo-prop text-white text-xs font-normal px-4 py-2 transition-all duration-200 rounded-sm">
          <MapsEditingIcon className="text-white" />
          <span className="ml-1">{t('general.configure-layers')}</span>
        </Menu.Button>
      </div>
      <Menu.Items className="absolute right-0 mt-1 p-3 w-[400px] bg-white shadow-lg ring-1 ring-black ring-opacity-5 rounded-md flex origin-top-right">
        <div className="py-1">
          <MapLayerToggle />
        </div>
      </Menu.Items>
    </Menu>
  );
};

export default MapLayerDropdown;
