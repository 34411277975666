import moment from 'moment';
import { getCategoryName } from 'shared/dist/constants';
import { CalculationResult, RadioButtonOptions, ValueCategoryTypes } from 'shared/dist/types';
import {
  CashflowAdjustableAssumptions,
  CashflowCalculatedAssumptions,
  CashflowInflation,
  CashflowLease,
  CashflowValuationAsset,
  CashflowValuationModel,
  PropertyValuationDefaultValues,
  SliderValue
} from 'shared/dist/types/cashflow/cashflow-model';
import { PropertyVM } from 'shared/dist/types/property2';
import { NewsecCategory } from 'shared/dist/types/rent';

import {
  createAdjustableAssumptions,
  createCalculatedAssumptions,
  getInitialSliderValuesOnCategory
} from '@/src/lib/utils/cashflow-valuation/general';

import { valueCategories } from '../../valuation-tool/items/constants';
import { getDefaultValues, getNewResult } from '../../valuation-tool/items/functions';
import {
  calculateCommercialVacancy,
  calculateNewSliderValues,
  isOngoingLease
} from './calculations';

export const setInitialValuesIfNewAsset = (
  propertyObject: PropertyVM,
  assetUuid: string,
  setAssetValues: (values: CashflowAdjustableAssumptions) => void,
  t
) => {
  const defaultValues = getDefaultValues(propertyObject, t);
  const newSliderValues = getInitialSliderValuesOnCategory(NewsecCategory.HOUSING, defaultValues);

  const newAdjustableAssumptions = createAdjustableAssumptions(
    newSliderValues,
    0,
    NewsecCategory.HOUSING,
    propertyObject,
    t,
    assetUuid
  );

  setAssetValues(newAdjustableAssumptions);
};

const adjustExistingSliderValues = (
  sliderValues: SliderValue[],
  category: NewsecCategory,
  defaultValues: PropertyValuationDefaultValues
) => {
  const newSliderValues = getInitialSliderValuesOnCategory(category, defaultValues);

  const adjustedSliderValues = sliderValues.map((value) => {
    const newValue = newSliderValues.find((v) => v.category === value.category);
    if (value.selectedForumCategory || newValue === undefined) {
      return value;
    }
    return { ...value, selectedForumCategory: newValue.selectedForumCategory };
  });

  return adjustedSliderValues;
};

export const setInitialValuesIfExistingAsset = (
  asset: CashflowValuationAsset,
  setAssetValues: (assumptions: CashflowAdjustableAssumptions) => void,
  setAssetName: (name: string) => void,
  defaultValues: PropertyValuationDefaultValues
): void => {
  const adjustedSliderValues = adjustExistingSliderValues(
    asset.adjustableAssumptions.sliderValues,
    asset.adjustableAssumptions.type,
    defaultValues
  );
  setAssetValues({ ...asset.adjustableAssumptions, sliderValues: adjustedSliderValues });
  setAssetName(asset.name);
};

export const getRentRollArea = (rentRoll: CashflowLease[]) => {
  const presentRentRoll = rentRoll.filter((lease) => isOngoingLease(lease));
  return presentRentRoll.reduce((a, c) => a + c.area, 0);
};

export const updateResultAtValueChange = (
  values: CashflowAdjustableAssumptions,
  setResult: (result: CalculationResult) => void
) => {
  const assetArea = values.area;
  const newResult = getNewResult(values.sliderValues, assetArea);
  setResult(newResult);
};

export const checkIfUnsavedChanges = (
  newAssetValues: CashflowAdjustableAssumptions,
  assetName: string,
  setUnsavedChanges: (arg: boolean) => void,
  existingAsset?: CashflowValuationAsset
) => {
  if (
    (existingAsset &&
      JSON.stringify(existingAsset.adjustableAssumptions) === JSON.stringify(newAssetValues) &&
      assetName === existingAsset.name) ||
    (!existingAsset && newAssetValues.area !== 0)
  ) {
    setUnsavedChanges(false);
  } else {
    setUnsavedChanges(true);
  }
};

export const setNewArea = (
  assetValues: CashflowAdjustableAssumptions,
  newArea: number,
  setAssetValues: (values: CashflowAdjustableAssumptions) => void
) => {
  const newSliderValues: SliderValue[] = calculateNewSliderValues(
    assetValues.sliderValues,
    assetValues.type,
    assetValues.rentRoll,
    newArea
  );

  setAssetValues({
    ...assetValues,
    area: newArea,
    rentRoll: assetValues.rentRoll,
    sliderValues: newSliderValues
  });
};

export const setNewAssetCategory = (
  assetValues: CashflowAdjustableAssumptions,
  propertyObject: any,
  newCategory: NewsecCategory,
  assetUuid: string,
  setAssetValues: (values: CashflowAdjustableAssumptions) => void,
  setAssetName: (name: string) => void,
  t
) => {
  const defaultValues = getDefaultValues(propertyObject, t);
  const newName: string = t(getCategoryName(newCategory));
  const newSliderValues: SliderValue[] = getInitialSliderValuesOnCategory(
    newCategory,
    defaultValues
  );

  const newAdjustableData: CashflowAdjustableAssumptions = createAdjustableAssumptions(
    newSliderValues,
    assetValues.area,
    newCategory,
    propertyObject,
    t,
    assetUuid
  );

  const newRentRollWithZeroArea = newAdjustableData.rentRoll.map((lease) => {
    return { ...lease, area: 0 };
  });

  const newRentRoll =
    assetValues.rentRoll.length > 0 ? assetValues.rentRoll : newRentRollWithZeroArea;

  const newSliderValuesCommercialVacancy = newSliderValues.map((value) => {
    if (value.category !== ValueCategoryTypes.VACANCY) {
      return value;
    }
    const newSliderValuesCommercialVacancy = calculateCommercialVacancy(
      newRentRoll,
      assetValues.area
    );
    return {
      ...value,
      radioButton: RadioButtonOptions.OTHER,
      value: newSliderValuesCommercialVacancy
    };
  });
  setAssetValues({
    ...newAdjustableData,
    sliderValues:
      newAdjustableData.type === NewsecCategory.HOUSING
        ? newSliderValues
        : newSliderValuesCommercialVacancy,
    rentRoll: newRentRoll,
    area: assetValues.area,
    rentIndex: assetValues.rentIndex,
    costIndex: assetValues.costIndex
  });
  setAssetName(newName);
};

export const setNewSliderValue = (
  assetValues: CashflowAdjustableAssumptions,
  newValue,
  setAssetValues: (values: CashflowAdjustableAssumptions) => void
) => {
  const oldValues = assetValues.sliderValues;
  const isSubcost = valueCategories[newValue.category]?.subcost;

  const avgCost = oldValues
    ?.filter((cat) => cat.subcost)
    ?.map((cat) => (cat.category === newValue.category ? newValue.value : cat.value))
    ?.reduce((a, c) => a + c, 0);

  let newValues: SliderValue[] = oldValues.map((cat) => {
    return cat.category === newValue.category
      ? {
          ...cat,
          value: newValue.value,
          radioButton: newValue.radioButton ?? cat.radioButton,
          selectedNewsecCategory: newValue.selectedNewsecCategory ?? cat.selectedNewsecCategory,
          selectedFLCategory: newValue.selectedFLCategory ?? cat.selectedFLCategory,
          selectedForumCategory: newValue.selectedForumCategory ?? cat.selectedForumCategory
        }
      : cat.category === ValueCategoryTypes.COSTS && isSubcost
        ? { ...cat, value: avgCost, radioButton: RadioButtonOptions.OTHER }
        : cat;
  });

  newValues = calculateNewSliderValues(
    newValues,
    assetValues.type,
    assetValues.rentRoll,
    assetValues.area
  );
  setAssetValues({ ...assetValues, sliderValues: newValues });
};

export const setNewLease = (
  assetValues: CashflowAdjustableAssumptions,
  updatedRentRoll,
  assetName: string,
  assetUuid: string,
  setAssetValues: (values: CashflowAdjustableAssumptions) => void
) => {
  const newValues: SliderValue[] = calculateNewSliderValues(
    assetValues.sliderValues,
    assetValues.type,
    updatedRentRoll,
    assetValues.area
  );
  const newRentRoll = updatedRentRoll.map((lease) => {
    return {
      ...lease,
      assetUuid: assetUuid,
      assetName: assetName
    };
  });

  setAssetValues({ ...assetValues, rentRoll: newRentRoll, sliderValues: newValues });
};

export const getUpdatedModel = (
  assetValues: CashflowAdjustableAssumptions,
  result: CalculationResult,
  model: CashflowValuationModel,
  assetName: string,
  assetUuid: string,
  selectedAsset?: CashflowValuationAsset,
  rentIndexes?: CashflowInflation[],
  costIndexes?: CashflowInflation[]
) => {
  const newCalculatedAssumptions: CashflowCalculatedAssumptions = createCalculatedAssumptions(
    result,
    selectedAsset ? selectedAsset.calculatedAssumptions : undefined
  );

  const newAsset: CashflowValuationAsset = selectedAsset
    ? {
        ...selectedAsset,
        name: assetName,
        adjustableAssumptions: assetValues,
        calculatedAssumptions: newCalculatedAssumptions,
        updatedAt: moment().unix()
      }
    : {
        uuid: assetUuid,
        name: assetName,
        modelUuid: model.uuid,
        adjustableAssumptions: assetValues,
        calculatedAssumptions: newCalculatedAssumptions,
        createdAt: moment().unix(),
        updatedAt: moment().unix()
      };

  const newAssets: CashflowValuationAsset[] = model.assets.map((asset) => {
    if (!selectedAsset || asset.uuid !== selectedAsset.uuid) {
      return asset;
    } else {
      return newAsset;
    }
  });

  if (!selectedAsset && newAssets.filter((asset) => asset.uuid === newAsset.uuid).length === 0) {
    newAssets?.push(newAsset);
  }
  const updatedModel: CashflowValuationModel = {
    ...model,
    rentIndexes: rentIndexes ?? model.rentIndexes,
    costIndexes: costIndexes ?? model.costIndexes,
    updatedAt: moment().unix(),
    assets: newAssets
  };

  return updatedModel;
};

export const handleMaxInputLimit = (
  e: React.FormEvent<HTMLInputElement>,
  maxInput: number,
  maxDecimals?: number
) => {
  const target = e.target as HTMLInputElement;
  const value = +target.value;

  if (value >= maxInput) {
    target.value = `${maxInput}`;
    return;
  }

  if (maxDecimals !== undefined) {
    const regex = new RegExp(`^\\d*\\.?\\d{0,${maxDecimals}}$`);
    if (!regex.test(target.value.toString())) {
      target.value = target.value.toString().slice(0, -1);
    }
  }
};
