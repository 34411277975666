import { Combobox, Dialog, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ChevronDownIcon,
  XMarkIcon as XIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import cls from 'classnames';
import { useTranslation } from 'next-i18next';
import { Fragment, useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { ButtonType, UserListVM } from 'shared/dist/types';

import { Error, Success } from '@/src/animations';
import { crmDialogTheme } from '@/src/components/dialogs/crm/dialog-theme';
import { editCRM2Team, getCRM2AvailableTeamMembers } from '@/src/lib/crm2-wrapper';
import Button from '@/src/widgets/buttons';

enum stepNames {
  addTeamMember = 1,
  error = 2,
  done = 3
}

function AddTeamMemberStep({
  close,
  setStep,
  object
}: {
  close: () => void;
  setStep: any;
  object: any;
}) {
  const { t } = useTranslation('common');

  const [crmRefresh, setCrmRefresh] = useGlobal<{ crmRefresh: number }>('crmRefresh' as never);

  const [showError, setShowError] = useState(false);

  const teamRoles = [
    { id: 'regular', name: 'crm.roles.regular' },
    { id: 'admin', name: 'settings.team.admin' }
    //{ id: 'read-only', name: 'crm.roles.read-only' }
  ];

  const [query, setQuery] = useState('');

  const filteredRoles =
    query === ''
      ? teamRoles
      : teamRoles.filter((role) =>
          t(role.name)
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        );

  const [role, setRole] = useState<any>();

  const handleRoleChange = (role) => {
    setRole(role);
  };

  const [teamMembers, setTeamMembers] = useState<UserListVM[]>([]);
  const [teamMember, setTeamMember] = useState<null | UserListVM>(null);

  useEffect(() => {
    getCRM2AvailableTeamMembers().then((json) => {
      setTeamMembers(json);
    });
  }, []);

  const filteredMember =
    query === ''
      ? teamMembers
      : teamMembers.filter((member) => {
          return (member.name || member.email)
            ?.toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''));
        });

  const handleTeamMemberChange = (member) => {
    setTeamMember(member);
  };

  return (
    <div className={crmDialogTheme.container}>
      <div className="text-lg font-medium text-gray-900 dark:text-gray-300">
        {t('crm.modals.teams.member-addition-name')}
      </div>

      <div className="flex w-[90%] gap-2 h-full items-center mt-10">
        <span className="leading-4 text-sm text-right text-gray-500 dark:text-gray-300">
          {t('crm.modals.teams.select-team-member')}
        </span>

        <div className="flex justify-between flex-grow">
          <div className="w-full">
            <Combobox
              value={teamMember ?? { uuid: '', name: '' }}
              by="uuid"
              onChange={handleTeamMemberChange}>
              {({ open }) => (
                <div className="relative">
                  <Combobox.Input
                    className="w-full py-2 pl-7 pr-10 text-sm leading-5 text-gray-900 dark:text-dark-prop bg-gray-100 dark:bg-dark-lessfaded border-none rounded"
                    displayValue={(member: any) => member?.name}
                    onChange={(event) => setQuery(event.target.value)}
                    autoComplete="off"
                  />

                  <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronDownIcon
                      className={cls('h-5 w-5', {
                        'rotate-180 text-bobo-prop dark:text-dark-prop': open,
                        'rotate-360 text-gray-400': !open
                      })}
                      aria-hidden="true"
                    />
                  </Combobox.Button>

                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setQuery('')}>
                    <Combobox.Options className="absolute mt-1 max-h-40 w-full overflow-auto rounded bg-white shadow-lg ring-1 ring-black/5 text-left z-40 dark:bg-dark-lessfaded">
                      {filteredMember.map((member) => (
                        <Combobox.Option
                          key={member.uuid}
                          className={({ active }) =>
                            cls('relative cursor-default select-none py-2 pl-12 pr-4', {
                              'hover:bg-bobo-prophover dark:hover:bg-gray-800 text-gray-900 hover:text-white dark:text-gray-300 dark:hover:text-white text-sm':
                                active,
                              'text-gray-900 dark:text-gray-300 text-sm': !active
                            })
                          }
                          value={member}>
                          {({ selected, active }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? 'font-medium' : 'font-normal'
                                }`}>
                                {member.name}
                              </span>

                              {selected ? (
                                <span
                                  className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                    active ? 'text-white' : 'text-gray-900 dark:text-dark-prop'
                                  }`}>
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Combobox.Option>
                      ))}
                    </Combobox.Options>
                  </Transition>
                </div>
              )}
            </Combobox>
          </div>

          <button
            className="text-gray-400 hover:text-bobo-prop dark:hover:text-dark-prop transition-all duration-300"
            onClick={() => {
              setTeamMember(null);
            }}>
            {teamMember != null && <XIcon className="w-5 h-5" />}
          </button>
        </div>
      </div>

      <div className="flex w-[90%] gap-2 h-full items-center mb-20">
        <span className="leading-4 text-sm text-right text-gray-500 dark:text-gray-300">
          {t('crm.modals.teams.select-a-role')}
        </span>

        <div className="flex justify-between flex-grow">
          <div className="w-full">
            <Combobox value={role ?? { id: '', name: '' }} by="id" onChange={handleRoleChange}>
              {({ open }) => (
                <div className="relative">
                  <Combobox.Input
                    className="w-full py-2 pl-7 pr-10 text-sm leading-5 text-gray-900 dark:text-dark-prop bg-gray-100 dark:bg-dark-lessfaded border-none rounded"
                    displayValue={(role: any) => t(role?.name)}
                    onChange={(event) => setQuery(event.target.value)}
                    autoComplete="off"
                  />

                  <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronDownIcon
                      className={cls('h-5 w-5', {
                        'rotate-180 text-bobo-prop dark:text-dark-prop': open,
                        'rotate-360 text-gray-400': !open
                      })}
                      aria-hidden="true"
                    />
                  </Combobox.Button>

                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setQuery('')}>
                    <Combobox.Options className="absolute mt-1 max-h-40 w-full overflow-auto rounded bg-white shadow-lg ring-1 ring-black/5 text-left z-40 dark:bg-dark-lessfaded">
                      {filteredRoles.map((role) => (
                        <Combobox.Option
                          key={role.id}
                          className={({ active }) =>
                            cls('relative cursor-default select-none py-2 pl-12 pr-4', {
                              'hover:bg-bobo-prophover dark:hover:bg-gray-800 text-gray-900 hover:text-white dark:text-gray-300 dark:hover:text-white text-sm':
                                active,
                              'text-gray-900 dark:text-gray-300 text-sm': !active
                            })
                          }
                          value={role}>
                          {({ selected, active }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? 'font-medium' : 'font-normal'
                                }`}>
                                {t(role.name)}
                              </span>

                              {selected ? (
                                <span
                                  className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                    active ? 'text-white' : 'text-gray-900 dark:text-dark-prop'
                                  }`}>
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Combobox.Option>
                      ))}
                    </Combobox.Options>
                  </Transition>
                </div>
              )}
            </Combobox>
          </div>

          <button
            className="text-gray-400 hover:text-bobo-prop dark:hover:text-dark-prop transition-all duration-300"
            onClick={() => {
              setRole(null);
            }}>
            {role != null && <XIcon className="w-5 h-5" />}
          </button>
        </div>
      </div>

      {showError && (
        <div className="text-red-600/80 text-md font-medium">
          {t('crm.modals.teams.duplicate-member-warning')}
        </div>
      )}

      <div className="flex flex-grow justify-between w-full items-end mt-4">
        <Button type={ButtonType.CANCEL} onClick={close}>
          {t('general.cancel')}
        </Button>

        <Button
          type={ButtonType.ADD}
          onClick={() => {
            if (!teamMember) {
              return;
            }

            if (
              object.team.member_uuids.concat(object.team.team_admin_uuids).find((element) => {
                return element === teamMember.uuid;
              })
            ) {
              setShowError(true);
              return;
            }

            let member_uuids = object.team.member_uuids;
            let team_admin_uuids = object.team.team_admin_uuids;

            if (role?.id === 'admin') {
              team_admin_uuids = team_admin_uuids.concat([teamMember.uuid]);
            } else {
              member_uuids = member_uuids.concat([teamMember.uuid]);
            }

            editCRM2Team(object.team.uuid, {
              ...object.team,
              member_uuids: member_uuids,
              team_admin_uuids: team_admin_uuids
            })
              .then(() => {
                setStep(stepNames.done);
              })
              .then(() => {
                setTimeout(() => {
                  setCrmRefresh((crmRefresh || 0) + 1);
                  close();
                }, 3000);
              });
          }}>
          {t('general.save')}
        </Button>
      </div>
    </div>
  );
}

function AddTeamMemberStepDone() {
  const { t } = useTranslation('common');

  return (
    <div>
      <div className={crmDialogTheme.icon}>
        <Success />
      </div>

      <div className="text-lg font-medium text-gray-900 dark:text-gray-300">
        {t('crm.modals.teams.adding-a-new-member')}
      </div>
    </div>
  );
}

function ErrorStep({ close }: { close: () => void }) {
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.icon}>
        <Error />
      </div>

      <div className="flex flex-col w-full text-center items-center">
        <Dialog.Title as="h3" className="text-red-700 w-3/4 leading-6">
          <p className={crmDialogTheme.subtext}>{t('errors.try-again')}</p>
        </Dialog.Title>
      </div>

      <div className={crmDialogTheme.buttonContainer}>
        <Button type={ButtonType.CANCEL} onClick={close}>
          {t('general.close')}
        </Button>
      </div>
    </div>
  );
}

export default function CRM2CreateTeamsDialog({
  close,
  object
}: {
  close: () => void;
  object: any;
}) {
  const [step, setStep] = useState(stepNames.addTeamMember);

  let mod: JSX.Element = <div />;

  switch (step) {
    case stepNames.addTeamMember:
      mod = <AddTeamMemberStep close={close} setStep={setStep} object={object} />;
      break;

    case stepNames.done:
      mod = <AddTeamMemberStepDone />;
      break;

    case stepNames.error:
      mod = <ErrorStep close={close} />;
      break;
  }

  return (
    <>
      <button onClick={close} className={crmDialogTheme.closeContainerX}>
        <XMarkIcon className="h-5 w-5" />
      </button>
      {mod}
    </>
  );
}
