import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import cls from 'classnames';
import { motion } from 'framer-motion';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import SignedGoogleMapsImage from 'src/components/map/signed-google-maps-image';

import { Building } from '@/src/animations';
import { FinancialTable } from '@/src/components/bolag/items/financialTable';
import { OverviewPortfolioSimple } from '@/src/components/bolag/items/overviewTable';
import { OverviewParent } from '@/src/components/bolag/items/parentTable';
import Portfolio from '@/src/components/bolag/portfolio';
import Tenant from '@/src/components/bolag/tenant';
import { OrgNumber } from '@/src/widgets/owner';

export default function CompanySlideIn({ object, close }: { object: any; close: any }) {
  const [companyData, setCompanyData] = useState<any>();
  const [corparateStructure, setCorparateStructure] = useState<any[]>([]);
  const [slideInContent, setslideInContent] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [noDataFound, setNoDataFound] = useState<boolean>(false);

  const { t } = useTranslation('common');

  const fetchData = async () => {
    setLoading(true);

    const localData: any = await fetch(
      `/api/company/getCompanyData?actor_uuid=${object?.uuid ?? ''}&actor_id=${
        object.actor_id ?? ''
      }`,
      {
        method: 'GET',
        headers: {
          'content-Type': 'application/json'
        }
      }
    )
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        let isPrivatePerson = false;
        let isInternationalCompany = false;

        if (
          data?.actor?.country_code !== 'SE' &&
          data?.actor?.country_code !== 'se' &&
          data?.actor?.actor_id?.length > 10
        ) {
          isInternationalCompany = true;
        }

        if (
          (data?.actor?.first_name && data?.actor?.first_name?.length > 0) ||
          (data?.actor?.last_name && data?.actor?.last_name?.length > 0) ||
          (!isInternationalCompany && data?.actor?.actor_id?.length > 10)
        ) {
          isPrivatePerson = true;
          isInternationalCompany = false;
        }

        setCompanyData({
          ...data,
          isPrivatePerson: isPrivatePerson,
          isInternationalCompany: isInternationalCompany
        });

        if (data?.actor?.level === 1 && data?.actor?.actor_id?.length !== 10 && !isPrivatePerson) {
          setslideInContent(2);
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
        setNoDataFound(true);
      })
      .finally(() => {
        // setLoading(false);
      });

    await fetch(`/api/company/getCorporateStructure?actor_id=${localData?.actor?.actor_id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setCorparateStructure(res.data || []);
        return res;
      })
      .catch((error) => {
        console.error('[getCompanyTree fetch error]', error);
        return [];
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [object?.actor_id]);

  const activeNode: any = corparateStructure?.find(
    (a) => a?.actor_id === companyData?.actor?.actor_id
  );

  let latLong = '';
  if (
    companyData?.coordinates?.lat &&
    !isNaN(companyData?.coordinates?.lat) &&
    companyData?.coordinates?.lng &&
    !isNaN(companyData?.coordinates?.lng)
  ) {
    latLong = `${companyData?.coordinates?.lat},${companyData?.coordinates?.lng}`;
  } else if (companyData?.workplaces && companyData?.workplaces.length > 0) {
    const hq = companyData?.workplaces.find((wp) => wp && wp.office_type === 'Huvudkontor');

    latLong = hq
      ? `${hq?.property?.lat},${hq?.property?.lng}`
      : `${companyData?.workplaces[0]?.property?.lat},${companyData?.workplaces[0]?.property?.lng}`;
  }

  const signedUrl = `https://maps.googleapis.com/maps/api/streetview?size=1200x188&location=${encodeURIComponent(
    latLong
  )}&fov=120&pitch=5&key=AIzaSyBYCMGQ9cdHlRBN414KW_VE0WgTzR6Sqow&source=outdoor`;

  const showImage =
    !companyData?.isInternationalCompany && !companyData?.isPrivatePerson && latLong;
  const inactiveCondition = companyData && companyData?.actor?.status_flag === 'INACTIVE';

  return (
    <div className="bg-gray-50 dark:bg-dark-lessfaded">
      <div className="h-screen overflow-y-scroll">
        {!noDataFound && (
          <motion.div
            className="relative z-10 overflow-hidden"
            animate={{
              height: showImage && !loading ? 200 : 0,
              transition: { duration: 0.3 }
            }}>
            <>
              {showImage && (
                <>
                  <SignedGoogleMapsImage
                    height={286}
                    width={970}
                    className="object-contain"
                    src={signedUrl}
                  />

                  <div
                    className="py-1 px-4 sm:px-2 text-xs absolute bottom-0 flex right-0"
                    style={{ backgroundColor: 'rgba(255, 255, 255, 0.75)' }}>
                    {t('company.workplace-image', {
                      company: companyData?.actor?.company_name ?? companyData?.actor?.name
                    })}
                  </div>
                </>
              )}
            </>
          </motion.div>
        )}

        {loading ? (
          <>
            <div className="relative h-36 sm:h-48 z-10 overflow-hidden" />
            <Building />
          </>
        ) : !noDataFound ? (
          <div className="flex z-30 flex-col px-0 sm:px-6 py-4">
            <div className="h-max">
              <div>
                <div
                  className={cls('flex mt-2', {
                    'pt-12': !showImage
                  })}>
                  <div className="text-2xl font-medium text-gray-900 dark:text-white px-4 sm:px-0">
                    {companyData?.actor?.company_name ?? companyData?.actor?.name}
                  </div>

                  {inactiveCondition && (
                    <div className="flex text-[#CB6F6C] items-center gap-1 ml-3 border-gray-200 text-xs">
                      <ExclamationTriangleIcon className="w-6 h-6" />

                      <div>{t('company.inactive')}</div>
                    </div>
                  )}
                </div>

                {!companyData?.isInternationalCompany && !companyData?.isPrivatePerson && (
                  <div className="text-gray-500 text-xs mb-4 -mt-[2px] dark:text-gray-300 font-medium px-4 sm:px-0">
                    {t('company.org-nr-long')}
                    {companyData?.actor?.actor_id && companyData?.actor?.actor_id?.length === 10 ? (
                      <OrgNumber actorId={companyData?.actor?.actor_id} />
                    ) : (
                      <em>{t('general.no-data-singular')}</em>
                    )}
                  </div>
                )}

                {companyData?.isInternationalCompany && (
                  <div className="text-gray-500 text-xs mb-4 -mt-[2px] dark:text-gray-300 font-medium">
                    {t('company.international')}
                  </div>
                )}

                {companyData?.isPrivatePerson && (
                  <div className="text-gray-500 text-xs mb-4 -mt-[2px] dark:text-gray-300 font-medium">
                    {t('company.private-person')}
                  </div>
                )}

                {!inactiveCondition && (
                  <div className="mt-6">
                    {(companyData?.actor?.actor_id?.length === 10 ||
                      companyData?.isPrivatePerson) && (
                      <button
                        className={cls(
                          `text-left py-3 px-4 sm:px-6 text-[9px] sm:text-sm shadow-md cursor-pointer items-center hover:bg-bobo-light rounded-t-md transition-colors duration-300 font-medium`,
                          {
                            'bg-white dark:bg-dark-primary dark:text-white hover:border-bobo-prop dark:hover:border-dark-prophover text-bobo-black':
                              slideInContent === 0
                          },
                          {
                            'bg-gray-100 hover:border-bobo-prop dark:hover:border-dark-prop dark:bg-dark-faded dark:hover:bg-dark-lessfaded text-gray-400 ':
                              slideInContent !== 0
                          }
                        )}
                        onClick={() => setslideInContent(0)}>
                        {t('company.company-info')}
                      </button>
                    )}

                    {activeNode?.level !== 0 &&
                      !companyData?.isPrivatePerson &&
                      activeNode?.root_prop_count > 0 && (
                        <button
                          className={cls(
                            `text-left py-3 px-4 sm:px-6 text-[9px] sm:text-sm shadow-md cursor-pointer items-center hover:bg-bobo-light rounded-t-md transition-colors duration-300 font-medium`,
                            {
                              'bg-white dark:bg-dark-primary dark:text-white hover:border-bobo-prop dark:hover:border-dark-prophover text-bobo-black':
                                slideInContent === 2
                            },
                            {
                              'bg-gray-100 hover:border-bobo-prop dark:hover:border-dark-prophover dark:bg-dark-faded dark:hover:bg-dark-lessfaded text-gray-400 ':
                                slideInContent !== 2
                            }
                          )}
                          onClick={() => setslideInContent(2)}>
                          {t('company.parent-company')}
                        </button>
                      )}

                    {activeNode?.level !== 0 &&
                      !companyData?.isPrivatePerson &&
                      activeNode?.root_prop_count > 0 && (
                        <button
                          className={cls(
                            `text-left py-3 px-4 sm:px-6 text-[9px] sm:text-sm shadow-md cursor-pointer items-center hover:bg-bobo-light rounded-t-md transition-colors duration-300 font-medium`,
                            {
                              'bg-white dark:bg-dark-primary dark:text-white hover:border-bobo-prop dark:hover:border-dark-prophover text-bobo-black':
                                slideInContent === 3
                            },
                            {
                              'bg-gray-100 hover:border-bobo-prop dark:hover:border-dark-prophover dark:bg-dark-faded dark:hover:bg-dark-lessfaded text-gray-400 ':
                                slideInContent !== 3
                            }
                          )}
                          onClick={() => setslideInContent(3)}>
                          {t('company.company-structure')}
                        </button>
                      )}

                    <button
                      className={cls(
                        `text-left py-3 px-4 sm:px-6 text-[9px] sm:text-sm shadow-md cursor-pointer items-center hover:bg-bobo-light rounded-t-md transition-colors duration-300 font-medium`,
                        {
                          'bg-white dark:bg-dark-primary dark:text-white hover:border-bobo-prop dark:hover:border-dark-prophover text-bobo-black':
                            slideInContent === 4
                        },
                        {
                          'bg-gray-100 hover:border-bobo-prop dark:hover:border-dark-prophover dark:bg-dark-faded dark:hover:bg-dark-lessfaded text-gray-400 ':
                            slideInContent !== 4
                        }
                      )}
                      onClick={() => setslideInContent(4)}>
                      {t('general.offices')}
                    </button>
                  </div>
                )}
              </div>

              {!inactiveCondition && (
                <>
                  {slideInContent === 0 && (
                    <div className="border-b border-l border-r dark:border-dark-primary py-4 border-gray-100 rounded-b-md relative z-20 bg-white shadow-md dark:bg-dark-primary">
                      <div className="py-2 md:px-4 lg:px-6 text-xs">
                        <OverviewPortfolioSimple
                          companyData={companyData}
                          close={close}
                          slideIn={true}
                          companySlideIn={true}
                          corparateStructure={corparateStructure}
                        />
                      </div>
                    </div>
                  )}

                  {slideInContent === 1 && (
                    <div className="border-b border-l border-r dark:border-dark-primary py-4 border-gray-100 rounded-b-md relative z-20 bg-white shadow-md dark:bg-dark-primary">
                      <div className="py-2 md:px-4 lg:px-6 text-xs">
                        <FinancialTable
                          companyData={companyData}
                          corparateStructure={corparateStructure}
                        />
                      </div>
                    </div>
                  )}

                  {slideInContent === 2 && (
                    // || (slideInContent === 0 &&
                    //   activeNode?.actor_id === rootNode?.actor_id &&
                    //   activeNode?.level !== 0)
                    <div className="border-b border-l border-r dark:border-dark-primary py-4 border-gray-100 rounded-b-md relative z-20 bg-white shadow-md dark:bg-dark-primary">
                      <div className="py-2 md:px-4 lg:px-6 text-xs">
                        <OverviewParent
                          companyData={companyData}
                          corparateStructure={corparateStructure}
                          slideIn={true}
                          close={close}
                        />
                      </div>
                    </div>
                  )}

                  {slideInContent === 3 && (
                    <div className="border-b border-l border-r dark:border-dark-primary py-4 border-gray-100 rounded-b-md relative z-20 bg-white dark:bg-dark-primary">
                      <Portfolio
                        companyData={companyData}
                        corporateStructure={corparateStructure}
                        setSlideinContent={setslideInContent}
                      />
                    </div>
                  )}

                  {slideInContent === 4 && (
                    <div className="border-b border-l border-r dark:border-dark-primary py-4 border-gray-100 rounded-b-md relative z-20 bg-white dark:bg-dark-primary">
                      <Tenant companyData={companyData} slideIn={true} />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="h-full flex flex-col justify-center items-center">
            <div>
              <Image
                className="object-contain"
                src={'/images/illustrations/not-found.svg'}
                alt={'Illustration'}
                height={300}
                width={400}
              />
            </div>
            <div className="text-sm px-32">{t('errors.no-company-data')}</div>
          </div>
        )}
      </div>
    </div>
  );
}
