import { PlusIcon } from '@heroicons/react/24/solid';
import ExcelJS, { BorderStyle, Cell, Column, Fill, Font, Row, Worksheet } from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { RiskHandle, RiskLevel } from 'shared/dist/types/risk';

import { DialogValueType, useDialog } from '../context/dialog-context';
import { createObjectESG } from '../lib/utils/esg-tools';
import { DialogType } from '../types';
import Button from './buttons';

export function ExportESGGrid({ grid }) {
  const { setDialog } = useDialog();
  const { t } = useTranslation('common');

  const subHeadingStyle = { bold: true, size: 13, name: 'Arial' };
  const stdRowStyle: any = {
    bold: false,
    size: 12,
    name: 'Arial',
    color: { argb: '000000' },
    italic: false,
    strike: false,
    outline: false,
    underline: false,
    family: 2
  };
  const boldRowStyle: any = {
    bold: true,
    size: 12,
    name: 'Arial',
    color: { argb: '000000' },
    italic: false,
    strike: false,
    outline: false,
    underline: false,
    family: 2
  };

  const addBorderToRow = (ws, rowIndex, borderColor = '000000') => {
    const row = ws.getRow(rowIndex);
    row.eachCell((cell) => {
      cell.border = {
        bottom: { style: 'thin', color: { argb: borderColor } },
        left: { style: 'thin', color: { argb: 'FFFFFF' } },
        right: { style: 'thin', color: { argb: 'FFFFFF' } }
      };
    });
    row.commit(); // Commit the changes if using a stream
  };

  const addBgColorToRow = (ws, rowIndex, color) => {
    const row = ws.getRow(rowIndex);
    row.eachCell((cell: Cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: `${color ?? 'FFFFFF'}` },
        bgColor: { argb: `${color ?? 'FFFFFF'}` }
      };
    });
    row.commit(); // Commit the changes if using a stream
  };

  const addDefinitionColumn = (ws: Worksheet) => {
    const definitions = [
      { cell: 'A5', value: 'general.property-heading', style: subHeadingStyle },
      { cell: 'A6', value: 'risk.risk-index-score', style: subHeadingStyle },
      { cell: 'A7', value: 'general.social-factors', style: subHeadingStyle },
      { cell: 'A8', value: 'risk.vulnerable-area', style: stdRowStyle },
      { cell: 'A9', value: 'risk.population-trend', style: stdRowStyle },
      { cell: 'A10', value: 'general.climate-risks', style: subHeadingStyle },
      { cell: 'A11', value: 'risk.flooding-ocean-lake-rivers', style: subHeadingStyle },
      { cell: 'A12', value: 'risk.flooding-ocean', style: stdRowStyle },
      { cell: 'A13', value: 'risk.inland_flooding_threat_50', style: stdRowStyle },
      { cell: 'A14', value: 'risk.inland_flooding_threat_100', style: stdRowStyle },
      { cell: 'A15', value: 'risk.inland_flooding_threat_bhf', style: stdRowStyle },
      { cell: 'A16', value: 'risk.inland_flooding_100', style: stdRowStyle },
      { cell: 'A17', value: 'risk.inland_flooding_200', style: stdRowStyle },
      { cell: 'A18', value: 'risk.inland_flooding_bhf', style: stdRowStyle },
      { cell: 'A19', value: 'risk.flooding-malaren', style: stdRowStyle },
      { cell: 'A20', value: 'risk.fire', style: subHeadingStyle },
      { cell: 'A21', value: 'risk.fire-open', style: stdRowStyle },
      { cell: 'A22', value: 'risk.fire-woods', style: stdRowStyle },
      { cell: 'A23', value: 'risk.landslide', style: subHeadingStyle },
      { cell: 'A24', value: 'risk.landslide_fine_grained', style: stdRowStyle },
      { cell: 'A25', value: 'risk.landslide_zones', style: stdRowStyle },
      { cell: 'A26', value: 'risk.erosion', style: stdRowStyle },
      { cell: 'A27', value: 'risk.ravine', style: stdRowStyle },
      { cell: 'A28', value: 'risk.solid_ground', style: stdRowStyle },
      { cell: 'A29', value: 'risk.heat', style: stdRowStyle },
      { cell: 'A30', value: 'risk.heat_spots', style: stdRowStyle },
      { cell: 'A31', value: 'risk.storm', style: stdRowStyle },
      { cell: 'A32', value: 'risk.draught', style: stdRowStyle },
      { cell: 'A33', value: 'risk.energy-class', style: stdRowStyle },
      { cell: 'A34', value: 'general.environment-risks', style: subHeadingStyle },
      { cell: 'A35', value: 'risk.toxic', style: stdRowStyle },
      { cell: 'A36', value: 'risk.historic-sites', style: stdRowStyle },
      { cell: 'A37', value: 'risk.protected-areas', style: subHeadingStyle },
      { cell: 'A38', value: 'risk.natura2000', style: stdRowStyle },
      { cell: 'A39', value: 'risk.nature-reserve', style: stdRowStyle },
      { cell: 'A40', value: 'risk.nature-care-area', style: stdRowStyle },
      { cell: 'A41', value: 'risk.water-protection-area', style: stdRowStyle },
      { cell: 'A42', value: 'risk.water-presence', style: stdRowStyle },
      { cell: 'A43', value: 'risk.wetlands', style: stdRowStyle },
      { cell: 'A44', value: 'risk.bio-area', style: stdRowStyle },
      { cell: 'A45', value: 'risk.national-park', style: stdRowStyle },
      { cell: 'A46', value: 'risk.interim-ban', style: stdRowStyle },
      { cell: 'A47', value: 'risk.dewatering', style: stdRowStyle }
    ];

    definitions.forEach(({ cell, value, style }) => {
      ws.getCell(cell).value = t(value);
      ws.getCell(cell).font = style;
    });

    ws.getRow(47);
    ws.addRow(['']);
    ws.addRow(['']);
    ws.addRow(['']);
    ws.getCell('A49').value = t(`general.risk-disclaimer-title`);
    ws.getCell('A49').font = boldRowStyle;
    ws.getCell('A49').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FAFAFA' },
      bgColor: { argb: 'FAFAFA' }
    };

    ws.getCell('A50').value = t(`general.risk-disclaimer-text`);
    ws.getCell('A50').font = { name: 'Arial', size: 14 };
    ws.getCell('A50').alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
    ws.getRow(50).height = 350;
    ws.getCell('A49').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FAFAFA' },
      bgColor: { argb: 'FAFAFA' }
    };
  };

  const setRiskCellData = (
    ws,
    colNumber,
    rowIndex,
    value,
    font: Font,
    level: RiskLevel | null | undefined,
    motivation?: string | null
  ) => {
    let cellColor = 'FFFFFF';

    switch (level) {
      case RiskLevel.HIGH:
        cellColor = 'FAC5B1';
        break;
      case RiskLevel.MEDIUM:
        cellColor = 'F9E6C3';
        break;
      case RiskLevel.NA:
        cellColor = 'D5E0EA';
        break;
      case RiskLevel.NONE:
        cellColor = 'ecf7ef';
        break;
      case RiskLevel.LOW:
        cellColor = 'B3E0C3';
        break;
    }

    const isNumber = typeof value === 'number' ? true : false;
    const row: Row = ws.getRow(rowIndex);
    row.getCell(colNumber).value = value;
    row.getCell(colNumber).font = font;
    row.getCell(colNumber).alignment = {
      vertical: 'middle',
      horizontal: isNumber ? 'right' : 'center'
    };
    if (level !== null) {
      row.getCell(colNumber).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: cellColor },
        bgColor: { argb: cellColor }
      };
      row.getCell(colNumber).border = {
        bottom: { style: 'thin', color: { argb: '888888' } },
        left: { style: 'thin', color: { argb: '888888' } },
        right: { style: 'thin', color: { argb: '888888' } },
        top: { style: 'thin', color: { argb: '888888' } }
      };
      // if (motivation !== null) {
      //   row.getCell(colNumber).note = `${t(motivation)}`;
      // }
    }
  };

  const stdWidth = 40;

  const downloadGrid = async () => {
    const dialogType: DialogType = 'loading';

    const workbook = new ExcelJS.Workbook();
    // Set main properties
    workbook.creator = 'Me';
    workbook.lastModifiedBy = 'Her';
    workbook.created = new Date(1985, 8, 30);
    workbook.modified = new Date();
    workbook.lastPrinted = new Date(2016, 9, 27);
    workbook.calcProperties.fullCalcOnLoad = true;

    workbook.properties.date1904 = true;

    const worksheet = workbook.addWorksheet(`${t('general.climate-environment-risks')}`, {
      properties: { tabColor: { argb: 'EBF1DE' } }
    });
    worksheet.views = [{ state: 'frozen', xSplit: 1, ySplit: 0 }];

    const columns = [
      {
        header: ``,
        key: 'definitions',
        width: 75
      }
    ];

    if (grid.length > 0) {
      // Create the
      grid.forEach((item: any, idx: number) => {
        columns.push({
          header: ``,
          key: `prop-${idx}`,
          width: stdWidth
        });
      });
    }

    // //Create the structure of the excel file
    const columnCount: number = grid.length + 1;
    const blankColumns = Array.from({ length: columnCount }, () => '');

    worksheet.addRow(blankColumns);
    worksheet.addRow([
      t(`general.extracted-date`, { date: moment().format('DD MMMM YYYY') }),
      ...blankColumns.slice(1)
    ]);
    worksheet.addRow([t(`general.risk-model-used`, { version: '1.3' }), ...blankColumns.slice(1)]);
    worksheet.addRow(blankColumns);

    worksheet.getCell('A2').font = { bold: true, size: 13, name: 'Arial' };
    worksheet.getCell('A3').font = { bold: true, size: 13, name: 'Arial' };
    worksheet.getCell('A5').border = { bottom: { style: 'thin' } };

    // // Create all rows for the rows 6 - 50
    worksheet.getRow(5);
    for (let i = 6; i <= 50; i++) {
      const initRow = worksheet.addRow(blankColumns);
      initRow.height = 25;
      initRow.alignment = { vertical: 'middle', horizontal: 'left' };
      initRow.eachCell((cell: Cell) => {
        cell.font = { bold: true, name: 'Arial', size: 12 };
      });
    }

    worksheet.columns = columns;
    const rows: Row[] = worksheet.getRows(1, 5) as Row[];
    rows.forEach((row, idx) => {
      row.eachCell((cell: Cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFF' },
          bgColor: { argb: 'FFFFFF' }
        };
        cell.border = {
          top: { style: 'thin', color: { argb: 'FFFFFF' } },
          bottom: { style: 'thin', color: { argb: 'FFFFFF' } },
          left: { style: 'thin', color: { argb: 'FFFFFF' } },
          right: { style: 'thin', color: { argb: 'FFFFFF' } }
        };
      });
    });

    // // // Assuming grid is an array of objects
    if (grid.length > 0) {
      // Create the
      grid.forEach((item: any, idx: number) => {
        const colNumber: number = idx + 2;
        const col: Column = worksheet.getColumn(colNumber);
        col.width = stdWidth;
        col.eachCell((cell: Cell) => {
          cell.font = { name: 'Ariel', bold: false, size: 12 };
        });
      });
    }

    addDefinitionColumn(worksheet);

    // // // Assuming grid is an array of objects
    if (grid.length > 0) {
      //   // Create the
      grid.forEach((item: any, idx) => {
        let propData: any = null;
        let risk: RiskHandle | undefined | null = null;
        if (item.result && item.result?.length > 0) {
          propData = item.result[0];
          risk = createObjectESG(propData);
        }
        const colNumber: number = idx + 2;
        const row: Row = worksheet.getRow(5);
        row.getCell(colNumber).value = `${propData?.auth_nickname} `;
        row.getCell(colNumber).font = { bold: true, size: 13 };

        // Risk index
        const riskIndex: number | string =
          propData?.risk_score && propData?.risk_score > 0
            ? Math.round(propData?.risk_score)
            : 'n/a';
        setRiskCellData(worksheet, colNumber, 6, riskIndex, boldRowStyle, null);

        // if ([`risk.ravine`, `risk.solid_ground`].includes(risk.index)) {
        //   riskLabel = t(`risk.level.not-assessed`);
        // }
        const riskData: { rowNumber: number; riskLevel: RiskLevel | undefined; key: string }[] = [
          {
            rowNumber: 8,
            riskLevel: risk?.vulnerable_area?.riskLevel,
            key: 'vulnerable_area'
          },
          { rowNumber: 9, riskLevel: risk?.population_trend?.riskLevel, key: 'population_trend' },
          { rowNumber: 12, riskLevel: risk?.flooding_ocean?.riskLevel, key: 'flooding_ocean' },
          {
            rowNumber: 13,
            riskLevel: risk?.flooding_inland_threat_50?.riskLevel,
            key: 'flooding_inland_threat_50'
          },
          {
            rowNumber: 14,
            riskLevel: risk?.flooding_inland_threat_100?.riskLevel,
            key: 'flooding_inland_threat_100'
          },
          {
            rowNumber: 15,
            riskLevel: risk?.flooding_inland_threat_bhf?.riskLevel,
            key: 'flooding_inland_threat_bhf'
          },
          {
            rowNumber: 16,
            riskLevel: risk?.flooding_inland_100?.riskLevel,
            key: 'flooding_inland_100'
          },
          {
            rowNumber: 17,
            riskLevel: risk?.flooding_inland_200?.riskLevel,
            key: 'flooding_inland_200'
          },
          {
            rowNumber: 18,
            riskLevel: risk?.flooding_inland_bhf?.riskLevel,
            key: 'flooding_inland_bhf'
          },
          {
            rowNumber: 19,
            riskLevel: risk?.flooding_malaren?.riskLevel ?? 0,
            key: 'flooding_malaren'
          },
          { rowNumber: 21, riskLevel: risk?.fire_open?.riskLevel ?? 0, key: 'fire_open' },
          { rowNumber: 22, riskLevel: risk?.fire_woods?.riskLevel ?? 0, key: 'fire_woods' },
          {
            rowNumber: 24,
            riskLevel: risk?.landslide_fine_grained?.riskLevel ?? 0,
            key: 'landslide_fine_grained'
          },
          {
            rowNumber: 25,
            riskLevel: risk?.landslide_zones?.riskLevel ?? 0,
            key: 'landslide_zones'
          },
          { rowNumber: 26, riskLevel: risk?.erosion?.riskLevel ?? 0, key: 'erosion' },
          { rowNumber: 27, riskLevel: -10, key: 'not-assessed' },
          { rowNumber: 28, riskLevel: -10, key: 'not-assessed' },
          { rowNumber: 29, riskLevel: risk?.heat?.riskLevel ?? 0, key: 'heat' },
          { rowNumber: 30, riskLevel: risk?.heat_spots?.riskLevel ?? 0, key: 'heat_spots' },
          { rowNumber: 31, riskLevel: risk?.storm?.riskLevel ?? 0, key: 'storm' },
          { rowNumber: 32, riskLevel: risk?.draught?.riskLevel ?? 0, key: 'draught' },
          { rowNumber: 33, riskLevel: risk?.energy?.riskLevel ?? 0, key: 'energy' },
          { rowNumber: 35, riskLevel: risk?.toxic?.riskLevel ?? 0, key: 'toxic' },
          { rowNumber: 36, riskLevel: risk?.historic_sites?.riskLevel ?? 0, key: 'historic_sites' },
          { rowNumber: 38, riskLevel: risk?.natura_2000?.riskLevel ?? 0, key: 'natura_2000' },
          { rowNumber: 39, riskLevel: risk?.nature_reserve?.riskLevel ?? 0, key: 'nature_reserve' },
          {
            rowNumber: 40,
            riskLevel: risk?.nature_care_area?.riskLevel ?? 0,
            key: 'nature_care_area'
          },
          {
            rowNumber: 41,
            riskLevel: risk?.water_protection_area?.riskLevel ?? 0,
            key: 'water_protection_area'
          },
          { rowNumber: 42, riskLevel: risk?.water_presence?.riskLevel ?? 0, key: 'water_presence' },
          { rowNumber: 43, riskLevel: risk?.wetlands?.riskLevel ?? 0, key: 'wetlands' },
          { rowNumber: 44, riskLevel: risk?.bio?.riskLevel ?? 0, key: 'bio' },
          { rowNumber: 45, riskLevel: risk?.national_park?.riskLevel ?? 0, key: 'national_park' },
          { rowNumber: 46, riskLevel: risk?.interim_ban?.riskLevel ?? 0, key: 'interim_ban' },
          { rowNumber: 47, riskLevel: risk?.dewatering?.riskLevel ?? 0, key: 'dewatering' }
        ];

        riskData.forEach(({ rowNumber, riskLevel, key }) => {
          setRiskCellData(
            worksheet,
            colNumber,
            rowNumber,
            riskLevel === -10
              ? `${t(`risk.level.not-assessed`)}`
              : `${t(`risk.level.${riskLevel}`)}`,
            stdRowStyle,
            riskLevel
          );
        });
      });
    }

    addBorderToRow(worksheet, 5);
    // Add background for Riskindex
    addBorderToRow(worksheet, 6);
    addBgColorToRow(worksheet, 6, 'FFFFFF');

    // Add background for Social factors
    addBorderToRow(worksheet, 7);
    addBgColorToRow(worksheet, 7, 'faf5ff');

    // Add background for Climate Risks
    addBorderToRow(worksheet, 10);
    addBgColorToRow(worksheet, 10, 'DCE6F1');

    // Add background for Environmental Factors
    addBorderToRow(worksheet, 34);
    addBgColorToRow(worksheet, 34, 'FFF1F4');

    //GRAY ROWS
    const grayRows = [11, 20, 23, 37];
    grayRows.forEach((rowIndex) => {
      addBorderToRow(worksheet, rowIndex, 'BBBBBB');
      addBgColorToRow(worksheet, rowIndex, 'F7F7F7');
    });

    // Generate Excel file buffer
    const buffer = await workbook.xlsx.writeBuffer();

    // Create Blob and trigger download
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    saveAs(
      blob,
      `${t('general.risk-export-title')} ${t('general.brand-name')} ${moment().format(
        'DD MMMM YYYY'
      )}.xlsx`
    );

    // Close dialog
    setDialog(null);
  };

  return (
    <div>
      <Button onClick={downloadGrid} className="!py-1.5">
        {t('general.export')}
        <PlusIcon className="w-5 h-5" />
      </Button>
    </div>
  );
}
