import { useTranslation } from 'react-i18next';
import { RadioButtonOptions, ValueCategoryTypes } from 'shared/dist/types';

import { handleMaxInputLimit } from '@/src/components/cashflow-valuation/constants/calculator-functions';

export function OtherRadioButton({
  valueCategory,
  tabValues,
  disabled,
  radioButtonStyle,
  selectedTab,
  changeValue,
  ownValue,
  setOwnValue
}) {
  const { t } = useTranslation('common');

  return (
    <div className="flex items-center justify-center w-full gap-2">
      <div className="w-1/6 h-full flex items-center justify-center">
        <input
          type={'radio'}
          name={`${valueCategory.title}-select`}
          value={RadioButtonOptions.OTHER}
          checked={tabValues.radioButton === RadioButtonOptions.OTHER}
          disabled={disabled}
          onChange={() => {
            changeValue(selectedTab, valueCategory.title, ownValue, RadioButtonOptions.OTHER);
          }}
          className={radioButtonStyle}
        />
      </div>

      <div className="flex flex-col w-5/6 justify-start text-start leading-3">
        <div className="text-gray-400 flex items-center justify-between gap-1 leading-3 rounded-md border border-gray-200 dark:border-dark-morefaded px-2 py-0.5 w-[130px] hover:border-bobo-prop dark:hover:border-dark-prop dark:bg-dark-primary transition-colors duration-200">
          <input
            type={'number'}
            step={valueCategory.title === ValueCategoryTypes.YIELD ? 0.01 : 1}
            value={Number(+ownValue).toString()}
            disabled={disabled}
            onChange={({ target }) => {
              changeValue(
                selectedTab,
                valueCategory.title,
                +target.value,
                RadioButtonOptions.OTHER
              );
              setOwnValue(+target.value);
            }}
            onInput={(e) => handleMaxInputLimit(e, valueCategory.sliderMax, 2)}
            className="border-none p-0 m-0 text-xs text-gray-400 dark:bg-dark-primary w-full"
          />
          <div className="whitespace-nowrap">{t(valueCategory.suffix)}</div>
        </div>
      </div>
    </div>
  );
}
