import { Dialog } from '@headlessui/react';
import Image from 'next/image';
import Link from 'next/link';
import { Trans, useTranslation } from 'next-i18next';
import { useEffect, useRef, useState } from 'react';
import { Error, Scooter, Success } from 'src/animations';

import { saveUpdateList } from '@/src/lib/actor-client-wrapper';

import { crmDialogTheme } from './dialog-theme';

const stepNames = {
  initialAdd: 1,
  initialEdit: 2,
  loading: 3,
  error: 4,
  done: 5
};

function ListAddStep1({
  close,
  setListName,
  saveList,
  object,
  backToAddProp,
  setMainStep
}: {
  close: any;
  setListName: any;
  saveList: any;
  object: any;
  backToAddProp: { addProperty: boolean; addProperties: boolean };
  setMainStep: any;
}) {
  const inputRef: any = useRef(null);
  const [name, setName] = useState<string>(``);
  const [clicked, setClicked] = useState<boolean>(false);

  const { t } = useTranslation('common');

  useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current?.focus();
    }
  }, []);

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Image
          alt="main illustration"
          src={`/images/property-management.svg`}
          height={200}
          width={200}
        />
      </div>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          <Trans i18nKey="crm.list-actions.add-new-list-in-board" boardName={object?.boardName}>
            Skapa ny lista i tavlan
            <span className="text-bobo-prop dark:text-dark-prop">
              {{ boardName: object?.boardName }}
            </span>
          </Trans>
        </Dialog.Title>
        <p className={crmDialogTheme.subtext}>{t('crm.modals.create-list-title-desc')}</p>
        <div className={crmDialogTheme.label}>{t('crm.modals.create-list-input-label')}</div>
        <input
          placeholder={t('crm.modals.list-name-placeholder')}
          className={crmDialogTheme.input}
          value={name}
          onChange={(txt) => {
            setName(txt.target.value);
            setListName(txt.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key == 'Enter' && clicked === false) {
              saveList();
              setClicked(true);
            }
          }}
          ref={inputRef}
        />
      </div>

      <div className={crmDialogTheme.buttonContainer}>
        <button className={crmDialogTheme.buttonClose} onClick={close}>
          {t('general.cancel')}
        </button>
        <div className={crmDialogTheme.subButtonContainer}>
          {backToAddProp.addProperty && (
            <button
              className={crmDialogTheme.buttonBack}
              onClick={() => (backToAddProp.addProperties ? setMainStep(40) : setMainStep(20))}>
              {t('general.back')}
            </button>
          )}
          <button
            disabled={!(name?.trim().length > 0)}
            className={crmDialogTheme.buttonConfirm}
            onClick={() => {
              if (clicked === false) {
                saveList();
                setClicked(true);
              }
            }}>
            {t('crm.list-actions.create-list')}
          </button>
        </div>
      </div>
    </div>
  );
}

function ListEditStep1({
  close,
  setListName,
  listName,
  saveList
}: {
  close: any;
  setListName: any;
  listName?: string;
  saveList: any;
}) {
  const inputRef: any = useRef(null);
  const [name, setName] = useState<string>(listName as string);
  const [inputValid, setInputValid] = useState<boolean>(false);
  const { t } = useTranslation('common');

  useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current?.focus();
    }
  }, []);

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Image
          alt="main illustration"
          src={`/images/property-management.svg`}
          height={200}
          width={200}
        />
      </div>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          <Trans i18nKey="crm.modals.update-list" listName t={t}>
            Uppdatera {<span className="text-bobo-prop dark:text-dark-prop">{{ listName }}</span>}
          </Trans>
        </Dialog.Title>
        <label className={crmDialogTheme.labelWithoutSubtext} htmlFor={inputRef}>
          {t('crm.modals.update-list-label')}
        </label>
        <input
          placeholder={t('crm.modals.list-name-placeholder')}
          className={crmDialogTheme.input}
          value={name}
          onChange={(txt) => {
            setName(txt.target.value);
            setListName(txt.target.value?.trim());
            setInputValid(
              txt.target.value?.trim() !== listName && txt.target.value?.trim().length > 0
            );
          }}
          onKeyDown={(e) => {
            if (e.key == 'Enter' && inputValid) {
              saveList();
            }
          }}
          ref={inputRef}
        />
      </div>
      <div className={crmDialogTheme.buttonContainer}>
        <button className={crmDialogTheme.buttonClose} onClick={close}>
          {t('general.cancel')}
        </button>
        <button
          className={crmDialogTheme.buttonConfirm}
          disabled={!inputValid}
          onClick={() => {
            saveList();
          }}>
          {t('general.update')}
        </button>
      </div>
    </div>
  );
}

function ListAddEditStepLoading() {
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Scooter />
      </div>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          {t('general.saving-info')}
        </Dialog.Title>
      </div>
    </div>
  );
}

function ListAddEditStepError({ close }: { close: any }) {
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.icon}>
        <Error />
      </div>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          <div className={crmDialogTheme.errorTitle}>{t('errors.something-went-wrong')}</div>
        </Dialog.Title>

        <p className={crmDialogTheme.subtext}>{t('errors.try-again')}</p>
      </div>

      <div className={crmDialogTheme.buttonContainer}>
        <button
          className={crmDialogTheme.buttonClose}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              close();
            }
          }}
          onClick={() => {
            close();
          }}>
          {t('general.close')}
        </button>
      </div>
    </div>
  );
}

function ListAddEditStepDone({
  setStep,
  close,
  object
}: {
  setStep: any;
  close: any;
  object: any;
}) {
  const { t } = useTranslation('common');

  const title = (
    <>
      {object?.boardId && object?.listId ? (
        <Trans i18nKey="crm.list-actions.list-updated-title" listname={object?.listName}>
          Klart! Vi har uppdaterat listans namn till
          <span className="text-bobo-prop dark:text-dark-prop">
            {' '}
            {{ listName: object?.listName }}
          </span>
          !
        </Trans>
      ) : (
        <Trans i18nKey="crm.list-actions.list-created-title" listName={object?.listName}>
          Klart! Vi har nu skapat listan
          <span className="text-bobo-prop dark:text-dark-prop">
            {' '}
            {{ listName: object?.listName }}
          </span>{' '}
          och lagt till den i tavlan.
        </Trans>
      )}
    </>
  );
  const subText = (
    <Trans i18nKey="crm.list-actions.list-created-added-subtext" t={t}>
      Kom igång genom att gå till{' '}
      <Link
        href="/hitta"
        className="font-demi text-bobo-black dark:text-white hover:text-bobo-prop dark:hover:text-dark-prophover">
        {t('navigation.search.heading')}
      </Link>{' '}
      för att hitta fastigheter som du kan lägga till i din lista.
    </Trans>
  );

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.icon}>
        <Success />
      </div>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          {title}
        </Dialog.Title>

        <div className={crmDialogTheme.subtext}>{subText}</div>
      </div>

      <div className={crmDialogTheme.buttonContainer}>
        <button
          className={crmDialogTheme.buttonClose}
          onClick={() => {
            close();
          }}>
          {t('general.cancel')}
        </button>

        <div className={crmDialogTheme.subButtonContainer}>
          <button
            className={crmDialogTheme.buttonClose}
            onClick={() => {
              close();
            }}>
            {t('crm.modals.done')}
          </button>

          <button className={crmDialogTheme.buttonConfirm} onClick={() => setStep(1)}>
            {t('crm.list-actions.create-new-list')}
          </button>
        </div>
      </div>
    </div>
  );
}

/**
 * dialog for adding new and editing list
 */
export default function CrmListDialogAddEdit({
  initStep = 1,
  setMainStep,
  setReload,
  close,
  setListName,
  listName,
  object,
  setObject,
  addEditMode,
  authoritiesNicknames
}: {
  initStep: number;
  setMainStep: any;
  setReload: any;
  close: any;
  setListName: any;
  listName?: string;
  object?: any;
  setObject: any;
  addEditMode: any;
  authoritiesNicknames: any;
}) {
  const [step, setStep] = useState(initStep);
  const backToAddProp: { addProperty: boolean; addProperties: boolean } = {
    addProperty: addEditMode,
    addProperties: authoritiesNicknames && authoritiesNicknames?.length > 0
  };

  const saveList = async () => {
    setReload(true);
    setStep(stepNames.loading);
    await saveUpdateList(object).then((res) => {
      if (res.error) {
        setStep(stepNames.error);
      } else {
        setStep(stepNames.done);
        setObject({
          ...object,
          listName: res?.name,
          listId: res?.uuid
        });
      }
    });
    if (addEditMode && authoritiesNicknames && authoritiesNicknames?.length > 0) {
      // Add list of properties to crm
      setMainStep(40);
    } else if (addEditMode) {
      // Add single property to crm
      setMainStep(20);
    }
  };

  let mod: JSX.Element = <div />;

  switch (step) {
    case stepNames.initialAdd:
      mod = (
        <ListAddStep1
          close={close}
          setListName={setListName}
          saveList={saveList}
          object={object}
          setMainStep={setMainStep}
          backToAddProp={backToAddProp}
        />
      );
      break;
    case stepNames.initialEdit:
      mod = (
        <ListEditStep1
          listName={listName}
          close={close}
          setListName={setListName}
          saveList={saveList}
        />
      );
      break;

    case stepNames.loading:
      mod = <ListAddEditStepLoading />;
      break;

    case stepNames.error:
      mod = <ListAddEditStepError close={close} />;
      break;

    case stepNames.done:
      mod = <ListAddEditStepDone setStep={setStep} close={close} object={object} />;
      break;
  }

  return <>{mod}</>;
}
