import { TableCellsIcon } from '@heroicons/react/24/solid';
import cls from 'classnames';
import ExcelJS from 'exceljs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonType, ValueCategoryTypes } from 'shared/dist/types';
import { CashflowInflation } from 'shared/dist/types/cashflow/cashflow-model';
import { NewsecCategory } from 'shared/dist/types/rent';

import { useBreakpoint } from '@/src/hooks/use-breakpoint';
import { useCashflowModelContext } from '@/src/hooks/use-cashflow-context';
import DropdownMenu from '@/src/layout/components/dropdown-menu';
import { getAssetInflation } from '@/src/lib/utils/cashflow-valuation/general';
import { getLeaseFeePerSqm, getPropertyTax } from '@/src/lib/utils/cashflow-valuation/valuation';

import { calculateYearDifference } from '../../constants/calculations';
import { getOverviewData } from '../tables/overview';

function getExcelColumnName(colIndex) {
  let columnName = '';
  while (colIndex >= 0) {
    columnName = String.fromCharCode((colIndex % 26) + 65) + columnName;
    colIndex = Math.floor(colIndex / 26) - 1;
  }
  return columnName;
}

function setExcelDateCell(cell: ExcelJS.Cell, unixTime: number) {
  const date = new Date(unixTime * 1000);

  // Extract year, month (0-11), and day from the Unix timestamp
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth();
  const day = date.getUTCDate();

  // ---- Below is some logic to ensure dates start in the beginning of the month ----

  // Get the last day of the current month
  const lastDay = new Date(Date.UTC(year, month + 1, 0)).getUTCDate();

  // If day is in the second half of the month, move to first day of next month
  if (day > lastDay / 2) {
    // If it's December (month 11), increment year and set month to January (0)
    if (month === 11) {
      cell.value = new Date(Date.UTC(year + 1, 0, 1));
    } else {
      cell.value = new Date(Date.UTC(year, month + 1, 1));
    }
  } else {
    cell.value = new Date(Date.UTC(year, month, 1));
  }

  cell.numFmt = 'yy-mm-dd';
}

export default function ExportModel({ iconOnly = false }: { iconOnly?: boolean }) {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const fullModelTemplateUrl = '/templates/Fastighetsloggen_Cashflow_Valuation_Model_Template.xlsx';
  const summarySheetUrl = '/templates/Fastighetsloggen_Cashflow_Valuation_Summary.xlsx';
  const { selectedModel, object } = useCashflowModelContext();
  const isXl = useBreakpoint({ breakpoint: 'xl' });

  const exportFullModel = async () => {
    if (!selectedModel) return;
    setIsLoading(true);
    try {
      // Fetch the template file
      const response = await fetch(fullModelTemplateUrl);
      const arrayBuffer = await response.arrayBuffer();

      // Load the workbook
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(arrayBuffer);

      // Assuming we're working with the first worksheet
      const worksheet = workbook.getWorksheet('Cockpit');
      const inflationSheet = workbook.getWorksheet('Inflation scenarios');
      const rentrollSheet = workbook.getWorksheet('RR');
      const universalSheet = workbook.getWorksheet('Universal');
      const investmentsSheet = workbook.getWorksheet('Investments');

      if (!worksheet || !inflationSheet || !rentrollSheet || !universalSheet || !investmentsSheet) {
        return;
      }

      universalSheet.getCell('D2').value = selectedModel.name;
      universalSheet.getCell('D4').value = calculateYearDifference(
        selectedModel.modelLength.startDate,
        selectedModel.modelLength.endDate
      );

      // Add inflation scenarios
      selectedModel.rentIndexes.forEach((inflation, index) => {
        const row = index + 3; // Start writing from row 3
        inflationSheet.getCell(`B${row}`).value = inflation.name; // Write the inflation name in column B

        // Assuming inflation.values is an array of values corresponding to columns C, D, E, etc.
        inflation.values.forEach((value, colIndex) => {
          const column = getExcelColumnName(2 + colIndex); // Start from column C (C is the 3rd column, index 2)
          inflationSheet.getCell(`${column}${row}`).value = value.value; // Write the value in the corresponding cell
        });
      });

      // Add potential leases to the rent roll
      let leaseIndex = 0;
      selectedModel.assets.forEach((asset, assetIndex) => {
        const assetInflation: CashflowInflation = getAssetInflation(
          asset.adjustableAssumptions.rentIndex.uuid,
          selectedModel.rentIndexes
        );
        if (asset.adjustableAssumptions.type !== NewsecCategory.HOUSING) {
          asset.adjustableAssumptions.rentRoll.forEach((lease) => {
            const row = rentrollSheet.getRow(leaseIndex + 3); // Start writing from row 2 (adjust as needed)
            row.getCell('B').value = assetIndex + 1;
            row.getCell('C').value = lease.name;
            row.getCell('D').value = lease.area;
            row.getCell('E').value = lease.rent;
            row.getCell('F').value = lease.supplements;
            row.getCell('G').value = lease.indexation;
            row.getCell('H').value = assetInflation.name;
            setExcelDateCell(row.getCell('I'), lease.startDate);
            setExcelDateCell(row.getCell('J'), lease.endDate);

            row.commit(); // Commit the row changes to the worksheet
            leaseIndex++;
          });
        }
      });

      const totalArea: number = selectedModel.assets.reduce(
        (acc, asset) => acc + asset.adjustableAssumptions.area,
        0
      );

      const leaseFeePerSqm: number = getLeaseFeePerSqm(totalArea, object);

      // Add investments
      let investmentIndex = 0;
      selectedModel.investments.map((investment) => {
        selectedModel.assets.forEach((asset, assetIndex) => {
          const row = investmentsSheet.getRow(investmentIndex + 3); // Start writing from row 2 (adjust as needed)
          row.getCell('B').value = assetIndex + 1;
          row.getCell('C').value = investment.name;
          row.getCell('D').value =
            (investment.amount / totalArea) * asset.adjustableAssumptions.area;
          setExcelDateCell(row.getCell('E'), investment.startDate);
          row.getCell('F').value = investment.duration;
          row.getCell('G').value =
            (investment.saving / totalArea) * asset.adjustableAssumptions.area;

          row.commit(); // Commit the row changes to the worksheet
          investmentIndex++;
        });
      });

      // Fill the Cockpit sheet
      const startChar = 'F';
      let charCode = startChar.charCodeAt(0);

      selectedModel.assets.map((asset) => {
        const assetInflation: CashflowInflation = getAssetInflation(
          asset.adjustableAssumptions.rentIndex.uuid,
          selectedModel.rentIndexes
        );
        const modelType: string =
          asset.adjustableAssumptions.type === NewsecCategory.HOUSING
            ? 'Residential'
            : 'Commercial';

        const currentChar = String.fromCharCode(charCode);
        worksheet.getCell(`${currentChar}9`).value = asset.name;
        worksheet.getCell(`${currentChar}12`).value = 1;
        worksheet.getCell(`${currentChar}13`).value = modelType;
        worksheet.getCell(`${currentChar}14`).value = asset.adjustableAssumptions.area;

        // OPEX
        worksheet.getCell(`${currentChar}17`).value = assetInflation.name;
        worksheet.getCell(`${currentChar}18`).value =
          (asset.calculatedAssumptions.calculatorResult[ValueCategoryTypes.COSTS] +
            (asset.calculatedAssumptions.calculatorResult[ValueCategoryTypes.MAINTENANCE] ?? 0)) /
          asset.adjustableAssumptions.area;
        worksheet.getCell(`${currentChar}19`).value = leaseFeePerSqm;

        worksheet.getCell(`${currentChar}20`).value =
          (getPropertyTax(asset.adjustableAssumptions.propertyTax) * 1000) /
          asset.adjustableAssumptions.area;

        // DCF
        worksheet.getCell(`${currentChar}26`).value = asset.calculatedAssumptions.growthRate;
        worksheet.getCell(`${currentChar}27`).value = asset.calculatedAssumptions.discountRate;

        // Residential assumptions (if applicable)
        if (asset.adjustableAssumptions.type === NewsecCategory.HOUSING) {
          const results = asset.calculatedAssumptions.calculatorResult;
          worksheet.getCell(`${currentChar}30`).value =
            results[ValueCategoryTypes.RENT] / asset.adjustableAssumptions.area;
          worksheet.getCell(`${currentChar}31`).value = results[ValueCategoryTypes.VACANCY] / 100;
          worksheet.getCell(`${currentChar}32`).value = object.apartmentCount;
          worksheet.getCell(`${currentChar}33`).value = assetInflation.name;
        }
        charCode++;
      });

      // Set the initial view of the first sheet (the cover)
      workbook.views = [
        {
          x: 0,
          y: 0,
          width: 10000,
          height: 20000,
          firstSheet: 0,
          activeTab: 0,
          visibility: 'visible' // Set activeTab to 0
        }
      ];

      // Make sure the workbook calculates on load
      workbook.calcProperties.fullCalcOnLoad = true;

      // Generate the modified Excel file
      const modifiedBuffer = await workbook.xlsx.writeBuffer();

      // Create a Blob from the buffer
      const blob = new Blob([modifiedBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });

      // Create a download link and trigger the download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${t('property.cashflow-valuation.export-full-model-name')}_${
        selectedModel.valuatedObject.name
      }.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error modifying Excel file:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const exportSummary = async () => {
    if (!selectedModel?.valuation) return;
    setIsLoading(true);
    try {
      // Fetch the template file
      const response = await fetch(summarySheetUrl);
      const arrayBuffer = await response.arrayBuffer();

      // Load the workbook
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(arrayBuffer);

      // Assuming we're working with the first worksheet
      const worksheet = workbook.getWorksheet('Report');

      if (!worksheet) {
        return;
      }

      const overviewData = getOverviewData(selectedModel);
      const totalArea: number = overviewData.area;

      worksheet.getCell(1, 1).value = selectedModel.valuatedObject.name + ' Kassaflödessummering';

      worksheet.getCell(5, 2).value = selectedModel.valuation.amount;
      worksheet.getCell(6, 2).value = selectedModel.valuation.amount / totalArea;
      worksheet.getCell(7, 2).value = overviewData.discountRate;
      worksheet.getCell(8, 2).value = selectedModel.valuation.noi / selectedModel.valuation.amount;
      worksheet.getCell(9, 2).value = overviewData.discountRate - overviewData.growthRate;

      const { NOI, cashflow, dcf, credit } = selectedModel.valuation.tables;

      const years = Object.keys(NOI[0])?.filter((key) => key !== 'index');

      const yearRow = 11; // The row at which the years are exported

      years.forEach((year, index) => {
        worksheet.getCell(yearRow, 2 + index).value = new Date(
          Date.UTC(parseInt(year.substring(0, 4)), 0, 1)
        );

        // ---- Summarize commercial vs. resi numbers ----
        let rentCommercial = 0;
        let rentResi = 0;
        let opexCommercial = 0;
        let opexResi = 0;

        selectedModel.assets.map((asset) => {
          if (!asset.valuation) {
            return;
          }
          const rent = asset.valuation?.tables.NOI.find((item) => item.index === 'Rental income')?.[
            year
          ] as number;
          const opex = asset.valuation?.tables.NOI.find((item) => item.index === 'Maintenance')?.[
            year
          ] as number;

          if (asset.adjustableAssumptions.type === NewsecCategory.HOUSING) {
            rentResi += rent;
            opexResi += opex;
          } else {
            rentCommercial += rent;
            opexCommercial += opex;
          }
        });
        // ------------------------------------------------

        const vacancy = NOI.find((item) => item.index === 'Vacancy')?.[year] as number;
        const supplements = NOI.find((item) => item.index === 'Supplements')?.[year] as number;

        worksheet.getCell(yearRow + 1, 2 + index).value = rentCommercial;
        worksheet.getCell(yearRow + 2, 2 + index).value = rentResi;
        worksheet.getCell(yearRow + 3, 2 + index).value = vacancy;
        worksheet.getCell(yearRow + 4, 2 + index).value = supplements;

        const grossRent = rentCommercial + rentResi + vacancy + supplements;
        worksheet.getCell(yearRow + 5, 2 + index).value = grossRent;

        const savings = NOI.find((item) => item.index === 'Savings')?.[year] as number;
        const propTax = NOI.find((item) => item.index === 'Property tax')?.[year] as number;
        const leaseHold = NOI.find((item) => item.index === 'Leasehold')?.[year] as number;

        worksheet.getCell(yearRow + 7, 2 + index).value = opexCommercial;
        worksheet.getCell(yearRow + 8, 2 + index).value = opexResi;
        worksheet.getCell(yearRow + 9, 2 + index).value = savings;
        worksheet.getCell(yearRow + 10, 2 + index).value = propTax;
        worksheet.getCell(yearRow + 11, 2 + index).value = leaseHold;

        const netCosts = opexCommercial + opexResi + savings + propTax + leaseHold;
        worksheet.getCell(yearRow + 12, 2 + index).value = netCosts;

        const noi = grossRent + netCosts;
        worksheet.getCell(yearRow + 14, 2 + index).value = noi;

        const investments = cashflow.find((item) => item.index === 'CAPEX')?.[year] as number;
        const residualValue = dcf.find((item) => item.index === 'Terminal value')?.[year] as number;
        worksheet.getCell(yearRow + 16, 2 + index).value = investments;
        worksheet.getCell(yearRow + 17, 2 + index).value = residualValue;
        const cashflowYear = noi + investments + residualValue;
        worksheet.getCell(yearRow + 18, 2 + index).value = cashflowYear;

        const npv = dcf.find((item) => item.index === 'NPV')?.[year] as number;

        worksheet.getCell(yearRow + 20, 2 + index).value = npv != 0 ? cashflowYear / npv : 1;
        worksheet.getCell(yearRow + 21, 2 + index).value = npv;

        if (credit) {
          const interest = credit.cf.find((item) => item.index === 'Interest')?.[year] as number;
          const amortization = credit.cf.find((item) => item.index === 'Amortization')?.[
            year
          ] as number;

          worksheet.getCell(yearRow + 23, 2 + index).value = interest;
          worksheet.getCell(yearRow + 24, 2 + index).value = amortization;
          worksheet.getCell(yearRow + 25, 2 + index).value = cashflowYear + interest + amortization;

          const loan = credit.kpi.find((item) => item.index === 'Loan')?.[year] as number;
          const icr = credit.kpi.find((item) => item.index === 'ICR')?.[year] as number;
          const dscr = credit.kpi.find((item) => item.index === 'DSCR')?.[year] as number;
          const dy = credit.kpi.find((item) => item.index === 'Debt yield')?.[year] as number;
          const ltv = credit.kpi.find((item) => item.index === 'LTV')?.[year] as number;

          worksheet.getCell(yearRow + 28, 2 + index).value = loan;
          worksheet.getCell(yearRow + 29, 2 + index).value = icr;
          worksheet.getCell(yearRow + 30, 2 + index).value = dscr;
          worksheet.getCell(yearRow + 31, 2 + index).value = dy;
          worksheet.getCell(yearRow + 32, 2 + index).value = ltv;
        }
      });

      // Set the initial view of the first sheet (the cover)
      workbook.views = [
        {
          x: 0,
          y: 0,
          width: 10000,
          height: 20000,
          firstSheet: 0,
          activeTab: 0,
          visibility: 'visible' // Set activeTab to 0
        }
      ];

      // Generate the modified Excel file
      const modifiedBuffer = await workbook.xlsx.writeBuffer();

      // Create a Blob from the buffer
      const blob = new Blob([modifiedBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });

      // Create a download link and trigger the download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${t('property.cashflow-valuation.export-summary-model-name')}_${
        selectedModel.valuatedObject.name
      }.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error modifying Excel file:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderMenuItems = (closeMenu: () => void) => {
    const buttonArray = [
      {
        label: t('general.summary'),
        onClick: (e) => {
          e.preventDefault();
          exportSummary();
          closeMenu();
        }
      },
      {
        label: t('property.cashflow-valuation.export-full-model'),
        onClick: (e) => {
          e.preventDefault();
          exportFullModel();
          closeMenu();
        }
      }
    ];

    return buttonArray.map((button, index) => (
      <button
        key={index}
        disabled={isLoading}
        onClick={button.onClick}
        className={cls(
          'leading-3 p-3 flex justify-between items-center space-x-6 w-full transition-colors duration-300 dark:hover:bg-gray-800 hover:bg-bobo-proplight'
        )}>
        <div>
          <div className="w-full text-left whitespace-nowrap text-xs text-bobo-black dark:text-white">
            {button.label}
          </div>
        </div>
      </button>
    ));
  };

  return (
    <DropdownMenu
      title={
        <div className="flex gap-2">
          <TableCellsIcon className="w-4 h-4" />
          {!iconOnly && <div>{isLoading ? t('general.loading') : t('general.export')}</div>}
        </div>
      }
      renderMenuItems={renderMenuItems}
      buttonClassName={cls('flex justify-between items-center !h-[32px] gap-2', {
        'has-tooltip relative': iconOnly,
        '!w-[150px]': !iconOnly
      })}
      tooltipText={isXl ? undefined : t('general.export')}
      buttonType={ButtonType.ACTION}
    />
  );
}
