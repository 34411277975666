import cls from 'classnames';
import moment from 'moment';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import { getCeo } from 'shared/dist/util/actor';

import { getOrgNr } from './overviewTable';

export function OverviewInfoContact({
  companyData,
  slideIn
}: {
  companyData: any;
  slideIn?: boolean;
}) {
  const { t } = useTranslation('common');

  let visitingAddress: string | null = null;
  let city: string | null = null;
  let sni: string | null = null;

  const isPrivateOwner: boolean =
    companyData.actor.actor_id?.length > 10 ||
    !!companyData.actor.first_name ||
    companyData.isPrivatePerson;

  if (companyData.workplaces && companyData.workplaces.length > 0) {
    const hq = companyData.workplaces.find((wp) => wp && wp.office_type === 'Huvudkontor');

    if (!hq) {
      const altOffice = companyData.workplaces[0];
      visitingAddress = altOffice.visit_address
        ? `${altOffice.visit_address}, ${altOffice.visit_town}`
        : null;
      city = altOffice.visit_town;
    }

    if (hq) {
      visitingAddress = `${hq.visit_address}, ${hq.visit_town}`;
      city = hq.visit_town;
    }
  }

  if (!visitingAddress && companyData.actor.postal_area) {
    city = companyData.actor.postal_area;
  }

  if (
    !sni &&
    companyData.actor.nace_codes &&
    companyData.actor.nace_codes.length > 0 &&
    companyData.actor.nace_descriptions &&
    companyData.actor.nace_descriptions.length > 0
  ) {
    if (companyData.actor.nace_descriptions[0] !== 'Huvudnäring okänd') {
      sni = `${companyData.actor.nace_codes[0]}: ${companyData.actor.nace_descriptions[0]}`;
    }
  }

  if (!sni && companyData.actor.SNI_code && companyData.actor.SNI_code !== 'NULL') {
    sni = `${companyData.actor.SNI_code}${
      companyData.actor.SNI_code_description && companyData.actor.SNI_code_description !== 'NULL'
        ? `: ${companyData.actor.SNI_code_description}`
        : ''
    }`;
  }

  if (!sni && companyData.actor.nace_categories && companyData.actor.nace_categories.length > 0) {
    sni = companyData.actor.nace_categories[0];
  }

  const ceo: string | null = getCeo(companyData.actorRoles);

  const goToAllaBolag = (e) => {
    const allaBolag = `https://www.allabolag.se/${companyData.actor.actor_id}/`;

    e.preventDefault();
    window.open(allaBolag, '_blank');
  };

  const orgNr = getOrgNr(companyData.actor);

  return (
    <div className="mt-0 w-full">
      <div className="mt-2 p-2 font-demi text-xs uppercase -ml-2">
        {t('company.information-and-contact-details').toUpperCase()}
      </div>

      {orgNr ? (
        <div className="flex justify-between p-2 text-[12px] even:bg-gray-50 dark:even:bg-dark-faded border-b border-gray-100 dark:border-dark-morefaded">
          <div>{t('company.org-nr-long')}</div>

          <div className="font-normal">{orgNr}</div>
        </div>
      ) : null}

      {ceo ? (
        <div className="flex justify-between p-2 text-[12px] even:bg-gray-50 dark:even:bg-dark-faded border-b border-gray-100 dark:border-dark-morefaded">
          <div>{t('company.highest-position')}</div>

          <div className="font-normal">{ceo}</div>
        </div>
      ) : null}

      {companyData.actor.company_type || companyData.actor.actor_type_text || isPrivateOwner ? (
        <div className="flex justify-between p-2 text-[12px] even:bg-gray-50 dark:even:bg-dark-faded border-b border-gray-100 dark:border-dark-morefaded">
          <div>{t('company.company-form')}</div>

          <div className="font-normal">
            {isPrivateOwner
              ? t('company.private-person')
              : (companyData.actor.company_type ?? companyData.actor.actor_type_text)}
          </div>
        </div>
      ) : null}

      {companyData.actor.company_purpose && companyData.actor.company_purpose.length > 0 ? (
        <div className="flex justify-between p-2 text-[12px] even:bg-gray-50 dark:even:bg-dark-faded border-b border-gray-100 dark:border-dark-morefaded">
          <div className="w-1/3">{t('company.activity-and-purpose')}</div>

          <div
            className={cls('font-normal w-2/3', {
              'text-justify': companyData.actor.company_purpose.length > 200,
              'text-right': companyData.actor.company_purpose.length <= 200
            })}>
            {companyData.actor.company_purpose}
          </div>
        </div>
      ) : null}

      {sni ? (
        <div className="flex justify-between p-2 text-[12px] even:bg-gray-50 dark:even:bg-dark-faded border-b border-gray-100 dark:border-dark-morefaded">
          <div className="w-1/3">{t('company.sni-code')}</div>

          <div className="font-normal w-2/3 text-right">{sni}</div>
        </div>
      ) : null}

      {companyData.actor.registration_date ? (
        <div className="flex justify-between p-2 text-[12px] even:bg-gray-50 dark:even:bg-dark-faded border-b border-gray-100 dark:border-dark-morefaded">
          <div>{t('company.registration-year')}</div>

          <div className="flex justify-end font-normal">
            {moment(companyData.actor.registration_date).format('YYYY')}
          </div>
        </div>
      ) : null}

      {companyData.actor.contact_phone || companyData.actor.phone_number ? (
        <div className="flex justify-between p-2 text-[12px] even:bg-gray-50 dark:even:bg-dark-faded border-b border-gray-100 dark:border-dark-morefaded">
          <div>{t('company.phone')}</div>

          <div className="font-normal">
            {companyData.actor.contact_phone ?? companyData.actor.phone_number}
          </div>
        </div>
      ) : null}

      {companyData.actor.email ? (
        <div className="flex justify-between p-2 text-[12px] even:bg-gray-50 dark:even:bg-dark-faded border-b border-gray-100 dark:border-dark-morefaded">
          <div>{t('company.email')}</div>

          <div className="font-normal">{companyData.actor.email}</div>
        </div>
      ) : null}

      {visitingAddress ? (
        <div className="flex justify-between p-2 text-[12px] even:bg-gray-50 dark:even:bg-dark-faded border-b border-gray-100 dark:border-dark-morefaded">
          <div>{t('company.visiting-address')}</div>

          <div className="font-normal">{visitingAddress}</div>
        </div>
      ) : null}

      {companyData.actor.municipality || city ? (
        <div className="flex justify-between p-2 text-[12px] even:bg-gray-50 dark:even:bg-dark-faded border-b border-gray-100 dark:border-dark-morefaded">
          <div>{t('company.place')}</div>

          <div className="font-normal">{companyData.actor.municipality ?? city}</div>
        </div>
      ) : null}

      {slideIn && !isPrivateOwner && !companyData.isInternationalCompany ? (
        <div className="flex justify-around px-[15%] pt-3 space-x-4">
          <div className="flex items-center justify-center">
            <button onClick={goToAllaBolag}>
              <div className="uppercase leading-3 justify-center items-center rounded-md transition-colors duration-300 shadow-md p-3 flex space-x-1 dark:hover:bg-dark-faded">
                <div>{t('company.link-to')}</div>

                <Image
                  src={`/images/allabolag-green.svg`}
                  width={100}
                  height={30}
                  alt={`allabolag.se logotyp`}
                />
              </div>
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
}
