import ExportToExcel from '@/src/components/itismad/export-excel';

export default function ItIsMadDownloadButtons({
  data,
  councilCode
}: {
  data: any;
  councilCode: string | null;
}) {
  return (
    <>
      <ExportToExcel councilCode={councilCode} data={data} />
    </>
  );
}
