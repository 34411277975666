import { Dialog } from '@headlessui/react';
import { LockClosedIcon } from '@heroicons/react/24/solid';
import cls from 'classnames';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import { useEffect, useRef, useState } from 'react';
import { CRMBoard } from 'shared/dist/types';
import { Error, Scooter, Success } from 'src/animations';
import { addPropertiesArrayToCRM, addPropToCRM, getCRMBoards } from 'src/lib/user-client-wrapper';

import { crmDialogTheme } from './dialog-theme';
import LoadingBoards from './loading-boards';

const stepNames = {
  initialAdd: 1,
  loading: 2,
  error: 3,
  done: 4
};

function PropertiesAddStepPickBoardAndList({
  setMainStep,
  close,
  addingOnlyOneProp,
  authorityNicknames,
  listOfBoards,
  listOfLists,
  selectedList,
  selectedBoard,
  setSelectedBoard,
  setSelectedList,
  setObject,
  addPropertiesToList,
  loading
}: {
  setMainStep: any;
  close: any;
  addingOnlyOneProp: boolean;
  authorityNicknames: string[];
  listOfBoards: any[];
  selectedBoard: CRMBoard | null;
  setSelectedBoard: any;
  listOfLists: any[];
  selectedList: string | null;
  setSelectedList: any;
  addPropertiesToList: any;
  setObject: any;
  loading: boolean;
}) {
  const { t } = useTranslation('common');
  const confirmButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (selectedBoard && selectedList) {
      setTimeout(() => {
        confirmButtonRef.current?.focus();
      }, 150);
    }
  }, [selectedBoard, selectedList]);

  const title = addingOnlyOneProp ? (
    <Trans i18nKey="crm.modals.add-property-title" authorityNickname={authorityNicknames[0]}>
      Vilken Tavla/Lista vill du lägga till fastigheten
      <span className="text-bobo-prop dark:text-dark-prop">
        {' '}
        {{ authorityNickname: authorityNicknames[0] }}
      </span>{' '}
      i?
    </Trans>
  ) : (
    <Trans i18nKey="crm.modals.add-properties-title" propertyCount={authorityNicknames.length}>
      Vilken Tavla/Lista vill du lägga till
      <span className="text-bobo-prop dark:text-dark-prop font-bold">
        {' '}
        {{ propertyCount: authorityNicknames.length }}{' '}
      </span>
      valda fastigheter i?
    </Trans>
  );
  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Image
          alt="main illustration"
          src={`/images/apartment-rate-increment.svg`}
          height={200}
          width={200}
        />
      </div>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          {title}
        </Dialog.Title>
      </div>

      <div className={crmDialogTheme.selectionContainer}>
        <div className={crmDialogTheme.selectionTitle}>{t('crm.modals.pick-board')}</div>
        {loading ? (
          <LoadingBoards />
        ) : (
          <div className={crmDialogTheme.selectionGrid}>
            {listOfBoards?.map((board, idx) => {
              return (
                <div
                  key={idx}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    if (board?.uuid === selectedBoard?.uuid) {
                      setSelectedBoard(null);
                      setSelectedList(null);
                    } else {
                      setSelectedBoard(board);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key == 'Enter') {
                      if (board?.uuid === selectedBoard?.uuid) {
                        setSelectedBoard(null);
                        setSelectedList(null);
                      } else {
                        setSelectedBoard(board);
                      }
                    }
                  }}
                  className={cls(crmDialogTheme.selectionItem, {
                    'border-2 border-bobo-prop dark:hover:!bg-dark-faded dark:bg-dark-prophover dark:border-dark-prop dark:hover:border-dark-morefaded bg-bobo-proplight hover:bg-white hover:border-gray-200 focus-visible:bg-white dark:focus-visible:bg-dark-faded':
                      board?.uuid === selectedBoard?.uuid
                  })}>
                  <>{board?.title}</>
                  {board?.private && (
                    <LockClosedIcon className="w-3 h-3 right-1 top-1 absolute text-gray-600 dark:text-gray-400" />
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
      {selectedBoard && (
        <div className={crmDialogTheme.selectionContainer}>
          <div className={crmDialogTheme.selectionTitle}>{t('crm.modals.pick-list')}</div>

          <div className={crmDialogTheme.selectionGrid}>
            {listOfLists?.map((list, idx) => {
              return (
                <div
                  key={idx}
                  onClick={() => {
                    if (list?.uuid === selectedList) {
                      setSelectedList(null);
                    } else {
                      setSelectedList(list?.uuid);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key == 'Enter') {
                      if (list?.uuid === selectedList) {
                        setSelectedList(null);
                      } else {
                        setSelectedList(list?.uuid);
                      }
                    }
                  }}
                  role="button"
                  tabIndex={0}
                  className={cls(crmDialogTheme.selectionItem, {
                    'border-2 border-bobo-prop bg-bobo-proplight dark:hover:!bg-dark-faded dark:bg-dark-prophover dark:border-dark-prop dark:hover:border-dark-morefaded hover:bg-white hover:border-gray-200 focus-visible:bg-white dark:focus-visible:bg-dark-faded':
                      list?.uuid === selectedList
                  })}>
                  <>{list?.name}</>
                </div>
              );
            })}
          </div>
        </div>
      )}

      <div className={crmDialogTheme.buttonContainer}>
        <button className={crmDialogTheme.buttonClose} onClick={close}>
          {t('general.cancel')}
        </button>

        <div className={crmDialogTheme.subButtonContainer}>
          {!selectedBoard || !selectedList ? (
            <button
              className={crmDialogTheme.buttonConfirm}
              onClick={() => {
                if (!selectedBoard) {
                  setMainStep(10);
                } else {
                  setObject({
                    boardId: selectedBoard.uuid,
                    boardName: selectedBoard.title
                  });
                  setMainStep(2);
                }
              }}>
              {!selectedBoard ? t('crm.activity-type.board-added') : t('crm.list-actions.add-list')}
            </button>
          ) : null}
          <button
            className={crmDialogTheme.buttonConfirm}
            disabled={!selectedBoard || !selectedList}
            onClick={() => {
              addPropertiesToList();
            }}
            ref={confirmButtonRef}>
            {t('general.confirm')}
          </button>
        </div>
      </div>
    </div>
  );
}

function PropertiesAddStepLoading({
  addingOnlyOneProp,
  authorityNicknames
}: {
  addingOnlyOneProp: boolean;
  authorityNicknames: string[];
}) {
  const { t } = useTranslation('common');

  const title: JSX.Element = addingOnlyOneProp ? (
    <>
      {t('crm.modals.add-properties-loading', {
        count: 1,
        authorityNickname: authorityNicknames[0]
      })}
    </>
  ) : (
    <>{t('crm.modals.add-properties-loading', { count: authorityNicknames.length })}</>
  );
  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Scooter />
      </div>

      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          {title}
        </Dialog.Title>

        <p className={crmDialogTheme.subtext}>{t('fun-facts.swe-smallest-property')}</p>
      </div>
    </div>
  );
}

function PropertiesAddStepError({
  close,
  addingOnlyOneProp,
  authorityNicknames,
  selectedBoard
}: {
  close: any;
  addingOnlyOneProp: boolean;
  authorityNicknames: string[];
  selectedBoard: CRMBoard | null;
}) {
  const { t } = useTranslation('common');
  const router = useRouter();

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.icon}>
        <Error />
      </div>

      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          {t('crm.modals.add-properties-error-title', {
            count: authorityNicknames.length,
            authorityNickname: authorityNicknames[0]
          })}
        </Dialog.Title>

        <p className={crmDialogTheme.subtext}>
          {t('crm.modals.add-properties-error-subtext-1', { count: authorityNicknames.length })}
        </p>
        <p className={crmDialogTheme.subtext}>{t('crm.modals.add-properties-error-subtext-2')}</p>
      </div>

      <div className={crmDialogTheme.buttonContainer}>
        <button className={crmDialogTheme.buttonClose} onClick={close}>
          {t('general.cancel')}
        </button>

        <div className={crmDialogTheme.subButtonContainer}>
          <button className={crmDialogTheme.buttonClose} onClick={close}>
            {t('general.close')}
          </button>

          <button
            className={crmDialogTheme.buttonConfirm}
            onClick={() => {
              router.push(`/crm/${selectedBoard?.uuid}`);
              close();
            }}>
            {t('crm.modals.link-to-board')}
          </button>
        </div>
      </div>
    </div>
  );
}

function PropertiesAddStepDone({
  close,
  addingOnlyOneProp,
  authorityNicknames,
  propertiesAdded,
  selectedBoard
}: {
  selectedBoard: CRMBoard | null;
  close: any;
  addingOnlyOneProp: boolean;
  authorityNicknames: string[];
  propertiesAdded: number;
}) {
  const { t } = useTranslation('common');
  const router = useRouter();

  const onlyOnePropertyAdded = addingOnlyOneProp || propertiesAdded === 1;

  const title: JSX.Element = onlyOnePropertyAdded ? (
    <Trans i18nKey="crm.modals.property-added-title" authorityNickname={authorityNicknames[0]}>
      <span className="text-bobo-prop dark:text-dark-prop">
        {{ authorityNickname: authorityNicknames[0] }}
      </span>{' '}
      är tillagd!
    </Trans>
  ) : (
    <Trans i18nKey="crm.modals.properties-added-title" propertiesAdded={propertiesAdded}>
      <span className="text-bobo-prop dark:text-dark-prop">{{ propertiesAdded }}</span> fastigheter
      är tillagda!
    </Trans>
  );

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.icon}>
        <Success />
      </div>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          {title}
        </Dialog.Title>

        <p className={crmDialogTheme.subtext}>
          {propertiesAdded < authorityNicknames.length &&
            t('crm.modals.some-props-already-exist', {
              count: authorityNicknames.length - propertiesAdded
            })}
        </p>
      </div>

      <div className={crmDialogTheme.buttonContainer}>
        <button className={crmDialogTheme.buttonClose} onClick={close}>
          {t('general.cancel')}
        </button>

        <div className={crmDialogTheme.subButtonContainer}>
          <button className={crmDialogTheme.buttonClose} onClick={close}>
            {t('general.close')}
          </button>

          <button
            className={crmDialogTheme.buttonConfirm}
            onClick={() => {
              close();
              router.push(`/crm/${selectedBoard?.uuid}`);
            }}>
            {t('crm.modals.link-to-board')}
          </button>
        </div>
      </div>
    </div>
  );
}

/**
 * dialog for adding properties to crm
 */
export function CrmPropertiesDialogAdd({
  setMainStep,
  setReload,
  close,
  propertyUUIDs,
  authorityNicknames,
  setObject
}: {
  setMainStep: any;
  setReload: any;
  close: any;
  propertyUUIDs: string[];
  authorityNicknames: string[];
  setObject?: any;
}) {
  const [step, setStep] = useState<number>(1);
  const [boards, setBoards] = useState<any[]>([]);
  const [selectedBoard, setSelectedBoard] = useState<CRMBoard | null>(null);
  const [selectedList, setSelectedList] = useState<string | null>(null);
  const [propertiesAdded, setPropertiesAdded] = useState<number>(authorityNicknames.length);
  const [loading, setLoading] = useState<boolean>(true);

  const addingOnlyOneProp: boolean = !!propertyUUIDs[0] && propertyUUIDs.length === 1;

  useEffect(() => {
    setLoading(true);
    getCRMBoards(0).then((res) => {
      // dont show archive boards
      const filteredBoards = res.filter((board) => board.for_archived !== true);
      setBoards(filteredBoards);
      setLoading(false);
    });
  }, []);

  const addPropertiesToList = async () => {
    setReload(true);
    setStep(stepNames.loading);

    if (selectedBoard && selectedList && propertyUUIDs) {
      // only one property to add
      if (addingOnlyOneProp) {
        await addPropToCRM(selectedBoard.uuid, selectedList, propertyUUIDs[0]).then((res) => {
          if (res?.ok === 200) {
            setStep(stepNames.done);
          } else if (res?.ok === 400) {
            setStep(stepNames.error);
          }
        });
      } else {
        // multiple properties to add
        await addPropertiesArrayToCRM(selectedBoard?.uuid, selectedList, propertyUUIDs).then(
          (res) => {
            const statusesWith400 = res.filter((propertyStatus) => propertyStatus.ok == 400);
            if (statusesWith400.length == res.length) {
              setStep(stepNames.error);
            } else {
              // only the properties that were successfully added are counted
              setPropertiesAdded((total) => total - statusesWith400.length);
              setStep(stepNames.done);
            }
          }
        );
      }
    }
  };

  let listOfBoards: any[] = boards;

  if (selectedBoard) {
    listOfBoards = boards?.filter((a) => a?.uuid === selectedBoard?.uuid);
  }

  let listOfLists: any[] = [];

  if (selectedBoard) {
    listOfLists = boards
      ?.find((a) => a?.uuid === selectedBoard.uuid)
      ?.lists.filter((list) => list.name !== 'Från arkivet'); // don't show the default 'from archive' list
    if (selectedList) {
      listOfLists = listOfLists?.filter((a) => a?.uuid === selectedList);
    }
  }

  let mod = <div />;

  switch (step) {
    case stepNames.initialAdd:
      mod = (
        <PropertiesAddStepPickBoardAndList
          close={close}
          setMainStep={setMainStep}
          addingOnlyOneProp={addingOnlyOneProp}
          authorityNicknames={authorityNicknames}
          listOfBoards={listOfBoards}
          listOfLists={listOfLists}
          selectedList={selectedList}
          selectedBoard={selectedBoard}
          setSelectedBoard={setSelectedBoard}
          setSelectedList={setSelectedList}
          setObject={setObject}
          addPropertiesToList={addPropertiesToList}
          loading={loading}
        />
      );
      break;

    case stepNames.loading:
      mod = (
        <PropertiesAddStepLoading
          addingOnlyOneProp={addingOnlyOneProp}
          authorityNicknames={authorityNicknames}
        />
      );
      break;

    case stepNames.error:
      mod = (
        <PropertiesAddStepError
          close={close}
          addingOnlyOneProp={addingOnlyOneProp}
          authorityNicknames={authorityNicknames}
          selectedBoard={selectedBoard}
        />
      );
      break;

    case stepNames.done:
      mod = (
        <PropertiesAddStepDone
          close={close}
          addingOnlyOneProp={addingOnlyOneProp}
          authorityNicknames={authorityNicknames}
          selectedBoard={selectedBoard}
          propertiesAdded={propertiesAdded}
        />
      );
      break;
  }

  return <>{mod}</>;
}

// Error when adding 50+ properties to CRM
export function ErrorTooManyProps({ close }: { close: any }) {
  const closeRef: any = useRef(null);
  const { t } = useTranslation('common');

  useEffect(() => {
    if (closeRef?.current) {
      closeRef?.current.focus();
    }
  }, []);

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Image
          alt="main illustration"
          src={`/images/illustrations/push.svg`}
          height={400}
          width={400}
        />
      </div>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          {t('general.oops')}
        </Dialog.Title>

        <p className={crmDialogTheme.subtext}>{t('errors.adding-too-many-properties')}</p>
      </div>

      <div className={crmDialogTheme.buttonContainer}>
        <button className={crmDialogTheme.buttonClose} onClick={close} ref={closeRef}>
          {t('general.close')}
        </button>
      </div>
    </div>
  );
}
