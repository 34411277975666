import { Dialog } from '@headlessui/react';
import { CheckCircleIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/solid';
import cls from 'classnames';
import Image from 'next/image';
import { Trans, useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import React from 'react';
import { DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend, NativeTypes } from 'react-dnd-html5-backend';
import toast from 'react-hot-toast';
import { ButtonType, PropertyNode } from 'shared/dist/types';
import { Error, Scooter, Success } from 'src/animations';
import * as XLSX from 'xlsx';

import DropdownMenu from '@/src/layout/components/dropdown-menu';
import Tooltip from '@/src/layout/components/tooltip';
import { addPropToCRM } from '@/src/lib/user-client-wrapper';
import Button from '@/src/widgets/buttons';
import LoggenSwitch from '@/src/widgets/switch';

import { crmDialogTheme } from './dialog-theme';

export enum ExcelColumnSelectorType {
  PROPERTY_NICKNAME = 'property-nickname',
  LOCAL_COUNCIL = 'local-council'
}

const stepNames = {
  import: 1,
  submit: 2,
  loading: 3,
  error: 4,
  done: 5
};

interface ProcessingResult {
  propertyNickname: string;
  status: 'success' | 'error';
  message: string;
}

interface PropertyProcessingStats {
  total: number;
  successful: number;
  failed: number;
  results: ProcessingResult[];
}

export function CrmImportPropertiesImportStep({
  setStep,
  setReload,
  close,
  listName,
  setProcessingResults,
  setProcessedProperties
}: {
  setStep: any;
  setReload: any;
  close: any;
  listName: string;
  setProcessingResults: (arg0: PropertyProcessingStats | null) => void;
  setProcessedProperties: (arg0: PropertyNode[]) => void;
}) {
  const [inputValid, setInputValid] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { t } = useTranslation('common');

  const [hasSeperateColumn, setHasSeperateColumn] = useState<boolean>(false);
  const [authorityNicknameColumn, setAuthorityNicknameColumn] = useState<number>(0);
  const [councilColumn, setCouncilColumn] = useState<number>(4);

  function onDrop(event: { files: File[] }) {
    if (event.files.length > 1) {
      toast.error(t('crm.modals.import-properties-error-message-one-file'));
    } else {
      const file = event.files[0];
      if (
        file &&
        (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          file.name.endsWith('.xlsx'))
      ) {
        setSelectedFile(file);
      } else {
        toast.error(t('crm.modals.import-properties-error-message-wrong-file'));
      }
    }
  }

  useEffect(() => {
    if (!selectedFile) {
      setInputValid(false);
      return;
    }
    setInputValid(true);
  }, [authorityNicknameColumn, councilColumn, selectedFile, hasSeperateColumn]);

  const [, drop] = useDrop(() => ({
    accept: [NativeTypes.FILE],
    drop(item: { files: any[] }) {
      if (onDrop) {
        onDrop(item);
      }
    }
  }));

  const onFileSelect = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    onDrop(e.target);
  };

  async function handleSubmit() {
    if (!selectedFile) {
      toast.error(t('crm.modals.import-properties-error-message-no-file'));
      return;
    }

    // Need to do authorityNicknameColumn !== 0 because column choice of 0 is falsy
    if (!authorityNicknameColumn && authorityNicknameColumn !== 0) {
      toast.error(t('crm.modals.import-properties-error-message-no-column'));
      return;
    }

    setReload(true);
    setStep(stepNames.loading);

    try {
      const data = await readFileAsPromise(selectedFile);
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      const jsonData: string[][] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const authorityNicknames = jsonData.map((row) => row?.[authorityNicknameColumn]);
      let councilNames: string[] | null = null;
      if (hasSeperateColumn && (councilColumn || councilColumn === 0)) {
        councilNames = jsonData.map((row) => row?.[councilColumn]);
        if (authorityNicknames.length !== councilNames?.length) {
          toast.error(t('crm.modals.import-properties-error-message-rows-match'));
          setStep(stepNames.import);
          return;
        }
      }
      if (authorityNicknames.length > 50) {
        toast.error(t('crm.modals.import-properties-error-message-rows-limit'));
        setStep(stepNames.import);
        return;
      }

      const processingResults = await processProperties(authorityNicknames, councilNames);
      const processingStats = processingResults.stats;
      setProcessedProperties(processingResults.properties);
      setProcessingResults(processingStats);

      if (processingStats.successful > 0) {
        toast.success(
          `${t('crm.modals.import-properties-success-share')} ${processingStats.successful} / ${
            processingStats.total
          } ${t('general.properties_other')}`
        );
        setStep(stepNames.submit);
      } else {
        toast.error(t('crm.modals.import-properties-error-message-full-fail'));
        setStep(stepNames.error);
      }
    } catch (error) {
      toast.error(t('crm.modals.import-properties-error-message-unknown'));
      setStep(stepNames.error);
    }
  }

  function readFileAsPromise(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target?.result as string);
      reader.onerror = (e) => reject(e);
      reader.readAsBinaryString(file);
    });
  }

  async function processProperties(
    authorityNicknames: string[],
    councilNames: string[] | null
  ): Promise<{ stats: PropertyProcessingStats; properties: PropertyNode[] }> {
    const stats: PropertyProcessingStats = {
      total: authorityNicknames.length,
      successful: 0,
      failed: 0,
      results: []
    };

    const properties: PropertyNode[] = [];

    // Process properties sequentially to maintain order and prevent race conditions
    for (let index = 0; index < authorityNicknames.length; index++) {
      const councilName = councilNames?.[index];
      const authorityNickname = authorityNicknames[index];
      let propertyAuthorityNickname = authorityNickname;

      try {
        // If seperate column for council name
        if (councilName) {
          propertyAuthorityNickname = councilName + ' ' + authorityNickname;
        }

        // Find property uuid
        const propertyResponse = await fetch(
          `/api/crm/get-property-from-authority-nickname?authorityNickname=${propertyAuthorityNickname.toUpperCase()}`,
          { method: 'GET' }
        );

        // Fail if property not found
        if (!propertyResponse.ok) {
          stats.failed++;
          let message = t('crm.modals.import-properties-error-message-unknown');
          if (propertyResponse.status === 413) {
            message = `${t(
              'crm.modals.import-properties-error-message-could-not-find'
            )} ${propertyAuthorityNickname}`;
          }
          stats.results.push({
            propertyNickname: propertyAuthorityNickname,
            status: 'error',
            message: message
          });
          continue;
        }

        const { property }: { property: PropertyNode } = await propertyResponse.json();

        // Unlikely situation
        if (!property?.uuid) {
          stats.failed++;
          stats.results.push({
            propertyNickname: propertyAuthorityNickname,
            status: 'error',
            message: t('crm.modals.import-properties-error-message-property-id')
          });
          continue;
        }

        properties.push(property);

        stats.successful++;
        stats.results.push({
          propertyNickname: propertyAuthorityNickname,
          status: 'success',
          message: t('crm.modals.import-properties-success-message-found')
        });
      } catch (error) {
        stats.failed++;
        stats.results.push({
          propertyNickname: authorityNickname,
          status: 'error',
          message: t('crm.modals.import-properties-error-message-unknown')
        });
      }
    }

    return { stats, properties };
  }

  const downloadTemplate = () => {
    const link = document.createElement('a');
    link.download = t('crm.modals.import-properties-example', {
      context: hasSeperateColumn ? 'two-columns' : 'one-column'
    });
    link.href = `/templates/Fastighetsloggen-CRM-Import-Example-(${
      hasSeperateColumn ? 'separate columns' : 'same columns'
    }).xlsx`;
    link.click();
    link.remove();
  };

  const handleColumnSelect = (column: number, columnType: ExcelColumnSelectorType) => {
    if (columnType === ExcelColumnSelectorType.PROPERTY_NICKNAME) {
      setAuthorityNicknameColumn(column);
    } else {
      setCouncilColumn(column);
    }
  };

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Image alt="main illustration" src={`/images/excel-image.png`} height={200} width={200} />
      </div>

      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          <Trans key="crm.modals.import-properties-title" t={t} listName={listName}>
            Importera fastigheter från Excel till{' '}
            <span className="text-bobo-prop dark:text-dark-prop">{{ listName }}</span>
          </Trans>
        </Dialog.Title>

        <ExcelColumnSelectorRow
          handleColumnSelect={handleColumnSelect}
          authorityNicknameColumn={authorityNicknameColumn}
          localCouncilColumn={councilColumn}
          hasSeperateColumn={hasSeperateColumn}
          setHasSeperateColumn={setHasSeperateColumn}
        />

        {selectedFile ? (
          <div className="mx-auto p-1 rounded-lg shadow-md mt-4 w-full">
            <h2 className="text-sm pb-2 font-medium text-gray-900 dark:text-gray-300 leading-6">
              {t('general.zp-files')}
            </h2>

            <div className="flex justify-between space-x-2 items-center mb-3 rounded-lg mx-4">
              <span className="text-gray-500">{selectedFile.name}</span>

              <button
                onClick={() => setSelectedFile(null)}
                className="hover:text-bobo-bricksred text-gray-400 duration-200 transition-colors">
                <TrashIcon className="w-5 h-5" />
              </button>
            </div>
          </div>
        ) : (
          <div
            ref={drop}
            className="relative cursor-pointer w-full min-h-[150px] mt-8 flex justify-center items-center rounded-md border-2 border-dashed border-bobo-prop dark:border-dark-prop bg-bobo-proplight dark:bg-dark-lessfaded dark:hover:bg-gray-800 hover:bg-bobo-orangelight transition-colors duration-200">
            <span className="text-md font-sans font-normal p-3 text-center">
              {t('crm.modals.import-properties-dnd')}
            </span>

            <input
              type="file"
              className="absolute w-full h-full opacity-0 cursor-pointer"
              onChange={onFileSelect}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </div>
        )}
      </div>

      <div className={crmDialogTheme.buttonContainer}>
        <Button onClick={close} type={ButtonType.CANCEL}>
          {t('general.cancel')}
        </Button>

        <div className="flex gap-2">
          <Button type={ButtonType.ADD} onClick={downloadTemplate}>
            {t('crm.modals.import-properties-download-template')}
          </Button>

          <Button
            disabled={!inputValid || !selectedFile}
            type={ButtonType.ADD}
            onClick={() => {
              handleSubmit();
            }}>
            {t('crm.user-actions.import')}
          </Button>
        </div>
      </div>
    </div>
  );
}

const ExcelColumnSelect = ({
  handleColumnSelect,
  columnType,
  selectedColumn
}: {
  handleColumnSelect: (column: number, columnType: ExcelColumnSelectorType) => void;
  columnType: ExcelColumnSelectorType;
  selectedColumn: number;
}) => {
  const { t } = useTranslation('common');
  const columns = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));

  const renderColumnItems = (close: () => void) =>
    columns.map((column, idx) => (
      <button
        key={column}
        onClick={() => {
          handleColumnSelect(idx, columnType);
          close();
        }}
        className={cls('w-full p-2 text-xs text-left transition-colors duration-200', {
          'bg-bobo-prop text-white': selectedColumn === idx,
          'hover:bg-bobo-proplight focus:outline-none focus:bg-bobo-proplight dark:hover:bg-gray-800 dark:focus:bg-gray-800':
            selectedColumn !== idx
        })}>
        {t('crm.modals.import-properties-column')} {column}
      </button>
    ));

  return (
    <div className="relative w-full text-sm">
      <DropdownMenu
        title={`${t('crm.modals.import-properties-column')} ${columns[selectedColumn]}`}
        renderMenuItems={renderColumnItems}
      />
    </div>
  );
};

const ExcelColumnSelectorRow = ({
  handleColumnSelect,
  authorityNicknameColumn,
  localCouncilColumn,
  hasSeperateColumn,
  setHasSeperateColumn
}: {
  handleColumnSelect: (column: number, columnType: ExcelColumnSelectorType) => void;
  authorityNicknameColumn: number;
  localCouncilColumn: number;
  hasSeperateColumn: boolean;
  setHasSeperateColumn: (hasSeperateColumn: boolean) => void;
}) => {
  const { t } = useTranslation('common');

  return (
    <div className="w-2/3 flex flex-col items-center gap-2 mt-6">
      <div
        className={`w-full grid grid-cols-1 gap-6 md:grid-cols-${hasSeperateColumn ? '2' : '1'}`}>
        {hasSeperateColumn && (
          <div>
            <span className={crmDialogTheme.label}>{t('general.local-council')}</span>
            <ExcelColumnSelect
              handleColumnSelect={handleColumnSelect}
              columnType={ExcelColumnSelectorType.LOCAL_COUNCIL}
              selectedColumn={localCouncilColumn}
            />
          </div>
        )}

        <div>
          <span className={crmDialogTheme.label}>{t('property.property-nickname')}</span>
          <ExcelColumnSelect
            handleColumnSelect={handleColumnSelect}
            columnType={ExcelColumnSelectorType.PROPERTY_NICKNAME}
            selectedColumn={authorityNicknameColumn}
          />
        </div>
      </div>

      <div className="w-fit justify-center has-tooltip relative">
        <LoggenSwitch
          value={hasSeperateColumn}
          setValue={(newVal) => setHasSeperateColumn(newVal)}
          label={
            <span className="text-gray-400 font-normal text-mini">
              {t('crm.modals.import-properties-switch-text')}
            </span>
          }
        />

        <Tooltip>{t('crm.modals.import-properties-subtext')}</Tooltip>
      </div>
    </div>
  );
};

function CrmImportPropertiesLoading() {
  const { t } = useTranslation('common');
  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Scooter />
      </div>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          {t('crm.modals.import-properties-loading')}
        </Dialog.Title>
      </div>
    </div>
  );
}

function CrmImportPropertiesSubmit({
  processingResults,
  properties,
  boardId,
  listId,
  close,
  setStep,
  setReload,
  setUploadResults
}: {
  processingResults: PropertyProcessingStats | null;
  setUploadResults: (arg0: PropertyProcessingStats | null) => void;
  properties: PropertyNode[];
  setStep: any;
  setReload: any;
  boardId: string;
  listId: string;
  close: any;
}) {
  const { t } = useTranslation('common');
  const successful = processingResults?.successful;
  const total = processingResults?.total;

  async function handleSubmit() {
    setReload(true);
    setStep(stepNames.loading);
    const uploadResults = await uploadProperties();
    setUploadResults(uploadResults);
    setStep(stepNames.done);
  }

  async function uploadProperties(): Promise<PropertyProcessingStats> {
    const stats: PropertyProcessingStats = {
      total: properties.length,
      successful: 0,
      failed: 0,
      results: []
    };

    for (let index = 0; index < properties.length; index++) {
      const property = properties[index];
      try {
        const response = await addPropToCRM(boardId, listId, property.uuid);
        if (response.ok === 200) {
          stats.successful++;
          stats.results.push({
            propertyNickname: property.authority_nickname,
            status: 'success',
            message: t('crm.modals.import-properties-success-message')
          });
        } else if (response.ok === 400) {
          stats.failed++;
          stats.results.push({
            propertyNickname: property.authority_nickname,
            status: 'error',
            message: t('crm.modals.import-properties-error-message-property-exists')
          });
        } else {
          stats.failed++;
          stats.results.push({
            propertyNickname: property.authority_nickname,
            status: 'error',
            message: t('crm.modals.import-properties-error-message-unknown')
          });
        }
      } catch (error) {
        stats.failed++;
        stats.results.push({
          propertyNickname: property.authority_nickname,
          status: 'error',
          message: t('crm.modals.import-properties-error-message-unknown')
        });
      }
    }

    return stats;
  }

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.icon}>
        {processingResults?.successful === 0 ? <Error /> : <Success />}
      </div>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          <Trans
            key="crm.modals.import-properties-error-title"
            t={t}
            successful={successful}
            total={total}>
            Kan importera{' '}
            <span className="text-bobo-prop dark:text-dark-prop">{{ successful }}</span> av{' '}
            <span className="text-bobo-prop dark:text-dark-prop">{{ total }}</span> fastigheter.
          </Trans>
        </Dialog.Title>

        {successful !== total ? (
          <p className={crmDialogTheme.subtext}>
            {t('crm.modals.import-properties-error-disclaimer')}
          </p>
        ) : null}

        <PropertiesResultsTable results={processingResults?.results} />

        <p className={crmDialogTheme.subtext}>{t('crm.modals.import-properties-submit-subtext')}</p>
      </div>

      <div className="w-full flex justify-between">
        <Button onClick={close} type={ButtonType.CANCEL}>
          {t('general.cancel')}
        </Button>

        <div className="flex gap-2">
          <Button onClick={() => setStep(1)}>{t('general.back')}</Button>

          {processingResults && processingResults.successful > 0 && (
            <Button type={ButtonType.ADD} onClick={handleSubmit}>
              {t('general.import-properties')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

// Could be a partial error, i.e. some properties were imported
function CrmImportPropertiesError({
  processingResults,
  close,
  setStep
}: {
  processingResults: PropertyProcessingStats | null;
  close: any;
  setStep: any;
}) {
  const { t } = useTranslation('common');
  const successful = processingResults?.successful;
  const total = processingResults?.total;
  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.icon}>
        {processingResults?.successful === 0 ? <Error /> : <Success />}
      </div>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          <Trans
            key="crm.modals.import-properties-error-title"
            t={t}
            successful={successful}
            total={total}>
            Kan importera{' '}
            <span className="text-bobo-prop dark:text-dark-prop">{{ successful }}</span> av{' '}
            <span className="text-bobo-prop dark:text-dark-prop">{{ total }}</span> fastigheter.
          </Trans>
        </Dialog.Title>
        <PropertiesResultsTable results={processingResults?.results} />
        <p className={crmDialogTheme.subtext}>{t('crm.modals.import-properties-error-subtext')}</p>
      </div>

      <div className="w-full flex justify-between">
        <Button onClick={close}>{t('general.close')}</Button>

        <Button onClick={() => setStep(1)}>{t('general.back')}</Button>
      </div>
    </div>
  );
}

function CrmImportPropertiesDone({
  close,
  listName,
  uploadResults
}: {
  close: any;
  listName: string;
  uploadResults: PropertyProcessingStats | null;
}) {
  const { t } = useTranslation('common');
  const successful = uploadResults?.successful;
  const total = uploadResults?.total;
  if (uploadResults?.failed === 0)
    return (
      <div className={crmDialogTheme.container}>
        <div className={crmDialogTheme.icon}>
          <Success />
        </div>
        <div className={crmDialogTheme.textContainer}>
          <Dialog.Title as="h3" className={crmDialogTheme.title}>
            <Trans key="crm.modals.import-properties-successful" listName={listName}>
              Alla funna fastigheter har importerats till listan{' '}
              <span className="text-bobo-prop dark:text-dark-prop">{{ listName }}</span>.
            </Trans>
          </Dialog.Title>
        </div>

        <div className="w-full flex justify-end">
          <Button onClick={close}>{t('crm.modals.done')}</Button>
        </div>
      </div>
    );
  else {
    return (
      <div className={crmDialogTheme.container}>
        <div className={crmDialogTheme.icon}>
          {uploadResults?.successful && uploadResults.successful > 0 ? <Success /> : <Error />}
        </div>
        <div className={crmDialogTheme.textContainer}>
          <Dialog.Title as="h3" className={crmDialogTheme.title}>
            <Trans
              key="crm.modals.import-properties-error"
              t={t}
              successful={successful}
              total={total}>
              Lyckades importera{' '}
              <span className="text-bobo-prop dark:text-dark-prop">{{ successful }}</span> av{' '}
              <span className="text-bobo-prop dark:text-dark-prop">{{ total }}</span> fastigheter.
            </Trans>
          </Dialog.Title>
        </div>
        <PropertiesResultsTable results={uploadResults?.results} />
        <div className="w-full flex justify-end">
          <Button onClick={close}>{t('crm.modals.done')}</Button>
        </div>
      </div>
    );
  }
}

function PropertiesResultsTable({ results }: { results?: ProcessingResult[] }) {
  const { t } = useTranslation('common');
  return (
    <div className="flow-root mt-4">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          {/* Desktop table */}
          <table className="min-w-full divide-y divide-gray-300 hidden lg:table">
            <thead>
              <tr className="text-gray-600 dark:text-gray-200 border-b border-gray-100">
                <th scope="col" className="py-2 pl-4 pr-3 text-left text-sm font-semibold">
                  {t('general.status')}
                </th>
                <th scope="col" className="px-3 py-2 text-left text-sm font-semibold">
                  {t('property.property-nickname')}
                </th>
                <th scope="col" className="px-3 py-2 text-left text-sm font-semibold">
                  {t('crm.modals.import-properties-message')}
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white dark:bg-dark-primary">
              {results?.map((result, index) => (
                <tr
                  key={index}
                  className="text-gray-800 dark:text-white hover:bg-gray-50 dark:hover:bg-dark-morefaded">
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm">
                    <div className="flex items-center">
                      {result.status === 'success' ? (
                        <CheckCircleIcon className="text-green-500" />
                      ) : (
                        <XMarkIcon className="text-red-500 h-8 w-8 ml-1" />
                      )}
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-left">
                    {result.propertyNickname}
                  </td>
                  <td className="px-3 py-4 text-sm text-left">{result.message}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default function CrmImportProperties({
  initStep = 1,
  setReload,
  close,
  object
}: {
  initStep?: number;
  setReload: any;
  close: any;
  object: any;
}) {
  const [step, setStep] = useState(initStep);
  const [processingResults, setProcessingResults] = useState<PropertyProcessingStats | null>(null);
  const [uploadResults, setUploadResults] = useState<PropertyProcessingStats | null>(null);
  const [processedProperties, setProcessedProperties] = useState<PropertyNode[]>([]);

  function closeAndNotify() {
    close();
  }

  let mod: JSX.Element = <div />;

  switch (step) {
    case stepNames.import:
      mod = (
        <DndProvider backend={HTML5Backend}>
          <CrmImportPropertiesImportStep
            setStep={setStep}
            close={close}
            listName={object?.listName}
            setReload={setReload}
            setProcessingResults={setProcessingResults}
            setProcessedProperties={setProcessedProperties}
          />
        </DndProvider>
      );
      break;

    // Step to ensure user wants to proceed with the import
    case stepNames.submit:
      mod = (
        <CrmImportPropertiesSubmit
          setStep={setStep}
          setReload={setReload}
          processingResults={processingResults}
          setUploadResults={setUploadResults}
          properties={processedProperties}
          close={closeAndNotify}
          boardId={object?.boardId}
          listId={object.listId}
        />
      );
      break;

    case stepNames.loading:
      mod = <CrmImportPropertiesLoading />;
      break;

    case stepNames.error:
      mod = (
        <CrmImportPropertiesError
          processingResults={processingResults}
          close={closeAndNotify}
          setStep={setStep}
        />
      );
      break;

    case stepNames.done:
      mod = (
        <CrmImportPropertiesDone
          close={closeAndNotify}
          listName={object?.listName}
          uploadResults={uploadResults}
        />
      );
      break;
  }

  return <>{mod}</>;
}
