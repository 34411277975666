import { getCategoryName } from 'shared/dist/constants';
import {
  CalculationResult,
  PropertyTypeGroup,
  RadioButtonOptions,
  TaxCategory,
  TaxTypes,
  ValueCategoryTypes
} from 'shared/dist/types';
import {
  FlValue,
  NewsecValue,
  PropertyValuationDefaultValues
} from 'shared/dist/types/cashflow/cashflow-model';
import { PropertyVM } from 'shared/dist/types/property2';
import { NewsecCategory } from 'shared/dist/types/rent';
import { getGroupForObjectType } from 'shared/dist/util/calculations';
import { getLatestYearOfRentalDataForPropType } from 'shared/dist/util/rent';

import { PROPERTY_TAX_PER_APARTMENT_LIMIT } from '../../cashflow-valuation/constants/general';
import { taxCategories, valueCategories } from './constants';

export function calculatePropertyValue(
  rentalYieldPercent: number,
  rentalIncomePerYear: number,
  costsPerYear: number
): number {
  // Step 1: Calculate Net Rental Income
  const netRentalIncome = rentalIncomePerYear - costsPerYear;

  // Step 2: Convert Rental Yield to Decimal
  const rentalYieldDecimal = rentalYieldPercent / 100;

  // Step 3: Calculate Property Value
  const propertyValue = rentalYieldDecimal > 0 ? netRentalIncome / rentalYieldDecimal : 0;

  return Math.round(propertyValue);
}

export function calculateAvgYieldOnValue(sumOfPropTypeValues: number, tabData: any[]): number {
  const weightedYieldArray = tabData.map((tab) => {
    return (tab.valueTotal / sumOfPropTypeValues) * tab.yield;
  });
  const avgYield = weightedYieldArray?.reduce((a, b) => a + b, 0);
  return +(Math.round(avgYield * 100) / 100).toFixed(2);
}

export const getNewResult = (values: any[], area: number) => {
  const newResult: any = {
    [ValueCategoryTypes.YIELD]:
      values?.find((cat) => cat.category === ValueCategoryTypes.YIELD)?.value ?? 0,
    [ValueCategoryTypes.RENT]:
      values.find((cat) => cat.category === ValueCategoryTypes.RENT)?.value * area,
    [ValueCategoryTypes.VACANCY]:
      values?.find((cat) => cat.category === ValueCategoryTypes.VACANCY)?.value ?? 0,
    [ValueCategoryTypes.COSTS]:
      values.find((cat) => cat.category === ValueCategoryTypes.COSTS)?.value * area,
    [ValueCategoryTypes.MAINTENANCE]:
      values.find((cat) => cat.category === ValueCategoryTypes.MAINTENANCE)?.value * area
  };

  return newResult;
};

export function calculatePropertyTax(
  taxType: TaxCategory,
  taxValue: number,
  apartmentCount: number
): number {
  const costPerApartment: number = apartmentCount
    ? apartmentCount * PROPERTY_TAX_PER_APARTMENT_LIMIT
    : 0;

  const taxValueInKr: number = taxValue * 1000;
  const taxOnTaxValue: number = taxValueInKr * taxType?.value;

  const result: number =
    taxType.title === TaxTypes.HOUSINGOLD && costPerApartment > 0
      ? Math.min(taxOnTaxValue, costPerApartment)
      : taxOnTaxValue;

  return Math.round(result);
}

export const getNewPropertyTax = (
  tabName: string,
  taxType: TaxCategory,
  taxValue: number,
  totalResult
) => {
  return totalResult?.map((tab) => {
    if (tab.tabName === tabName) {
      return {
        ...tab,
        taxValue: taxValue,
        propertyTaxType: taxType
      };
    } else {
      return tab;
    }
  });
};

export const getNewArea = (tabName, newArea: number, totalResult) => {
  const values = totalResult?.find((tab) => tab.tabName === tabName).values;

  const newResult = getNewResult(values, newArea);

  return totalResult?.map((tab) => {
    if (tab.tabName === tabName) {
      return {
        ...tab,
        area: newArea,
        result: newResult
      };
    } else {
      return tab;
    }
  });
};

export const getNewValue = (
  tabName: string,
  valueType: ValueCategoryTypes,
  newValue: number,
  totalResult: any[],
  radioButton?: RadioButtonOptions,
  newsecCategory?: string,
  flCategory?: string,
  forumCategory?: string
) => {
  const oldValues = totalResult?.find((tab) => tab.tabName === tabName).values;
  const area = totalResult?.find((tab) => tab.tabName === tabName).area;
  const isSubcost = valueCategories[valueType]?.subcost;

  const avgCost = oldValues
    ?.filter((cat) => cat.subcost)
    ?.map((cat) => (cat.category === valueType ? newValue : cat.value))
    ?.reduce((a, c) => a + c, 0);

  const newValues = oldValues.map((cat) => {
    return cat.category === valueType
      ? {
          ...cat,
          value: newValue,
          radioButton: radioButton ?? cat.radioButton,
          selectedNewsecCategory: newsecCategory ?? cat.selectedNewsecCategory,
          selectedFLCategory: flCategory ?? cat.selectedFLCategory,
          selectedForumCategory: forumCategory ?? cat.selectedForumCategory
        }
      : cat.category === ValueCategoryTypes.COSTS && isSubcost
        ? { ...cat, value: avgCost, radioButton: RadioButtonOptions.OTHER }
        : cat;
  });

  const newResult: any = getNewResult(newValues, area);

  return totalResult?.map((tab) => {
    if (tab.tabName === tabName) {
      return {
        ...tab,
        values: newValues,
        result: newResult
      };
    } else {
      return tab;
    }
  });
};

export const setTabCategory = (
  category: string,
  defaultValues: any | null,
  object: any,
  tabName?: string
) => {
  const valueYear: number = Math.max.apply(null, object?.valueYears) ?? 0;
  const taxValue: number = getTaxValueOnPropType(category, object?.propTax);
  const countLeaseFee = !!object?.landLeaseAgreement?.lease_fee;

  let selectedNewsecCategory: string = NewsecCategory.HOUSING;
  let selectedFLCategory: PropertyTypeGroup = PropertyTypeGroup.HOUSING;

  if (category !== 'other') {
    selectedNewsecCategory = category;
  }

  let area = 0;
  const propTypes = getPropTypesFromProptax(object?.propTax);

  let propertyTaxType = taxCategories?.find((cat) => cat?.title === TaxTypes.HOUSINGOLD);

  if (category === NewsecCategory.HOUSING) {
    area = object?.v2?.residential_details?.sqm ?? object?.boa ?? 0;

    if (valueYear > 2009) {
      propertyTaxType = taxCategories?.find((cat) => cat?.title === TaxTypes.HOUSINGNEW);
    } else if (valueYear < 2009 && valueYear > 2004) {
      propertyTaxType = taxCategories?.find((cat) => cat?.title === TaxTypes.HOUSING16TO20);
    }
  } else if (category === NewsecCategory.OFFICE) {
    area = object?.v2?.office_details?.sqm ?? object?.loa ?? 0;
    propertyTaxType = taxCategories?.find((cat) => cat?.title === TaxTypes.COMMERCIAL);
    selectedFLCategory = PropertyTypeGroup.SPACE;
  } else if (category === NewsecCategory.INDUSTRY) {
    if (propTypes.filter((type) => type.propType === PropertyTypeGroup.INDUSTRY).length > 0) {
      area = propTypes.find((type) => type.propType === PropertyTypeGroup.INDUSTRY).area;
    } else if (
      propTypes.filter((type) => type.propType === PropertyTypeGroup.WAREHOUSE).length > 0
    ) {
      area = propTypes.find((type) => type.propType === PropertyTypeGroup.WAREHOUSE).area;
    } else {
      area = object?.v2?.warehouse_details?.sqm ?? object?.loa ?? 0;
    }
    propertyTaxType = taxCategories?.find((cat) => cat?.title === TaxTypes.INDUSTRY);
    selectedFLCategory = PropertyTypeGroup.WAREHOUSE;
  } else if (category === NewsecCategory.SHOP) {
    area = propTypes.find((type) => type.propType === PropertyTypeGroup.SHOP)?.area ?? 0;
    propertyTaxType = taxCategories?.find((cat) => cat?.title === TaxTypes.COMMERCIAL);
  } else if (category === NewsecCategory.CARE) {
    propertyTaxType = taxCategories?.find((cat) => cat?.title === TaxTypes.SPECIAL);
  }

  const values = Object.keys(valueCategories).map((cat) => {
    let selectedRadioButton: RadioButtonOptions = RadioButtonOptions.OTHER;

    if (
      defaultValues[cat] &&
      defaultValues[cat]?.fl.find((rentCategory) => rentCategory.propType === selectedFLCategory)
        ?.value > 0 &&
      category !== 'other'
    ) {
      selectedRadioButton = RadioButtonOptions.FL;
    } else if (
      defaultValues[cat] &&
      defaultValues[cat]?.newsec.find(
        (rentCategory) => rentCategory.propType === selectedNewsecCategory
      )?.value > 0 &&
      category !== 'other'
    ) {
      selectedRadioButton = RadioButtonOptions.NEWSEC;
    }

    let value = 0;
    if (selectedRadioButton === RadioButtonOptions.FL) {
      value = defaultValues[cat]?.fl?.find((type) => type.propType === selectedFLCategory)?.value;
    } else if (selectedRadioButton === RadioButtonOptions.NEWSEC) {
      value = defaultValues[cat]?.newsec.find(
        (type) => type.propType === selectedNewsecCategory
      )?.value;
    }

    return {
      category: cat,
      value: value,
      radioButton: selectedRadioButton,
      selectedNewsecCategory: selectedNewsecCategory,
      selectedFLCategory: selectedFLCategory,
      selectedForumCategory: selectedNewsecCategory,
      subcost: valueCategories[cat].subcost
    };
  });

  const result: CalculationResult = {
    [ValueCategoryTypes.YIELD]:
      values?.find((cat) => cat.category === ValueCategoryTypes.YIELD)?.value ?? 0,
    [ValueCategoryTypes.VACANCY]:
      values?.find((cat) => cat.category === ValueCategoryTypes.VACANCY)?.value ?? 0,
    [ValueCategoryTypes.RENT]:
      (values.find((cat) => cat.category === ValueCategoryTypes.RENT)?.value as number) * area,
    [ValueCategoryTypes.COSTS]:
      (values.find((cat) => cat.category === ValueCategoryTypes.COSTS)?.value as number) * area,
    [ValueCategoryTypes.MAINTENANCE]:
      (values.find((cat) => cat.category === ValueCategoryTypes.MAINTENANCE)?.value as number) *
      area
  };

  return {
    tabName: tabName ?? category,
    propType: category,
    area: area,
    apartmentCount: object?.apartmentCount ?? 0,
    valueYear: valueYear,
    countLeaseFee: countLeaseFee,
    propertyTaxType: propertyTaxType,
    countPropertyTax: false,
    taxValue: taxValue,
    values: values,
    result: result
  };
};

export const getPropTypesFromProptax = (propTax) => {
  const propTypes = propTax?.map((prop) => {
    const totalArea: number = prop?.taxUnits
      ?.filter((a) => a?.unit_type !== 'hyreshusmark lokal' && a?.unit_type !== 'industrimark')
      ?.reduce((acc, b) => {
        return acc + (b?.total_area > 0 ? b?.total_area : 0);
      }, 0);
    return {
      taxCode: prop.tax_code,
      area: totalArea,
      propType: getGroupForObjectType(prop.tax_code ?? 0)
    };
  });
  return propTypes?.filter((type) => type.area > 0);
};

export const getTaxValueOnPropType = (propType: string, propTax): number => {
  let taxValue = 0;

  propTax?.map((a) => {
    a?.taxUnits?.map((taxUnit) => {
      if (
        (taxUnit?.lm_unit_type === 'Q' || taxUnit?.lm_unit_type === 'S') &&
        propType === NewsecCategory.HOUSING
      ) {
        taxValue += taxUnit?.value;
      }
      if (
        (propType === NewsecCategory.OFFICE || propType === NewsecCategory.SHOP) &&
        (taxUnit?.lm_unit_type === 'T' ||
          taxUnit?.lm_unit_type === 'Z' ||
          taxUnit?.unit_type === 'hyreshusmark lokal')
      ) {
        taxValue += taxUnit?.value;
      }
      if (
        propType === NewsecCategory.INDUSTRY &&
        (taxUnit?.lm_unit_type === 'V' ||
          taxUnit?.lm_unit_type === 'Y' ||
          taxUnit?.lm_unit_type === 'X' ||
          taxUnit?.unit_type === 'lager avkastning')
      ) {
        taxValue += taxUnit?.value;
      }
    });
  });

  return taxValue;
};

export const createTabArrayOnPropTypes = (object) => {
  const propTypes = getPropTypesFromProptax(object?.propTax);
  const tabArray: string[] = [];

  if (propTypes.length > 0) {
    propTypes?.map((type) => {
      if (
        type.propType === PropertyTypeGroup.WAREHOUSE ||
        type.propType === PropertyTypeGroup.INDUSTRY
      ) {
        tabArray.push(NewsecCategory.INDUSTRY);
      } else if (type.taxCode === 321) {
        if (object?.boa && object?.boa > 0) {
          tabArray.push(NewsecCategory.HOUSING);
        }
        if (object?.loa && object?.loa > 0) {
          tabArray.push(NewsecCategory.OFFICE);
        }
      } else if (type.propType === PropertyTypeGroup.HOUSING) {
        tabArray.push(NewsecCategory.HOUSING);
      } else if (type.propType === PropertyTypeGroup.SPACE) {
        tabArray.push(NewsecCategory.OFFICE);
      } else if (type.propType === PropertyTypeGroup.SHOP) {
        tabArray.push(NewsecCategory.SHOP);
      }
    });
  }

  return [...new Set(tabArray)];
};

const getRentValue = (
  cat: ValueCategoryTypes,
  propType: PropertyTypeGroup,
  object: any
): number => {
  if (cat !== ValueCategoryTypes.RENT) return 0;

  let value = 0;
  if (propType === PropertyTypeGroup.HOUSING) {
    const lastYearOfPrimaryType = getLatestYearOfRentalDataForPropType(
      PropertyTypeGroup.HOUSING,
      object?.v2
    );
    const rentIncome = object?.v2?.residential_details?.total_rent_income[lastYearOfPrimaryType];
    value = rentIncome
      ? Math.round((rentIncome * 1000) / object?.v2?.residential_details?.sqm)
      : (object?.v2?.residential_rent_91 ?? 0);
  } else if (propType === PropertyTypeGroup.SPACE) {
    value = object?.v2?.office_rent ?? 0;
  } else if (propType === PropertyTypeGroup.WAREHOUSE) {
    value = Math.round(object?.v2?.warehouse_rent ?? 0);
  }

  return value;
};

const getNewsecValue = (cat: ValueCategoryTypes, newsecType: any, t: any): NewsecValue => {
  let value = 0;
  const institute = newsecType.institute;

  const categoryCost = newsecType.costs ?? null;
  const avgCost =
    categoryCost &&
    Object.keys(valueCategories).some((category) => valueCategories[category].subcost)
      ? Object.keys(valueCategories)
          .filter((category) => valueCategories[category].subcost)
          .map((category) => categoryCost[t(category)] ?? 0)
          .reduce((a, c) => a + c, 0)
      : 0;

  if (cat === ValueCategoryTypes.YIELD) {
    value = +newsecType.yield_avg;
  } else if (cat === ValueCategoryTypes.RENT) {
    value = Math.round(+newsecType.rent_avg);
  } else if (cat === ValueCategoryTypes.VACANCY) {
    value = institute === 'newsec' ? +newsecType.vacancy_current : +newsecType.vacancy_short;
  } else if (cat === ValueCategoryTypes.COSTS) {
    value = avgCost;
  } else if (categoryCost) {
    value = categoryCost[t(cat)] ?? 0;
  }

  return {
    propType: newsecType?.property_type,
    value,
    isPrimary: newsecType.is_primary
  };
};

export const getDefaultValues = (object: PropertyVM, t: any): PropertyValuationDefaultValues => {
  const getFlValues = (cat: ValueCategoryTypes, object: PropertyVM): FlValue[] => {
    const flRentCategories: PropertyTypeGroup[] = [
      PropertyTypeGroup.HOUSING,
      PropertyTypeGroup.SPACE,
      PropertyTypeGroup.WAREHOUSE
    ];

    return flRentCategories.map((rentCategory) => {
      const value = getRentValue(cat, rentCategory, object);
      return { propType: rentCategory, value };
    });
  };

  return Object.keys(valueCategories).reduce((acc, catKey) => {
    const cat = catKey as ValueCategoryTypes;

    const flValues = getFlValues(cat, object);

    const newsecValues =
      object.market_data.newsec?.map((newsecType) => getNewsecValue(cat, newsecType, t)) ?? [];

    const forumValues =
      object.market_data.forum?.map((forumType) => getNewsecValue(cat, forumType, t)) ?? [];

    acc[cat] = {
      fl: flValues,
      newsec: newsecValues,
      forum: forumValues
    };

    return acc;
  }, {} as PropertyValuationDefaultValues);
};

export const createExportableData = (summaryData, t) => {
  const tabData = summaryData?.tabData?.map((tab) => {
    return {
      ...tab,
      row:
        t(getCategoryName(tab?.tabName)).length > 0
          ? t(getCategoryName(tab?.tabName))
          : tab?.tabName,
      area: `${tab?.area}`,
      rentGross: `${Math.round(tab?.rentGross / 1000)}`,
      vacancy: `${tab?.vacancy}`,
      rentNet: `${Math.round(tab?.rentNet / 1000)}`,
      costs: `${Math.round(-tab?.costs / 1000)}`,
      maintenance: `${Math.round(-tab?.maintenance / 1000)}`,
      operatingNet: `${Math.round(tab?.operatingNet / 1000)}`,
      operatingNetSqm: Math.round(tab.operatingNet / tab.area),
      yield: `${tab?.yield}`,
      tax: tab?.countPropertyTax ? `${-tab.tax}` : '-',
      valueTotalSqm: `${Math.round(tab.valueTotal / tab.area)}`,
      valueTotal: `${Math.round(tab?.valueTotal / 1000)}`
    };
  });

  const landLeaseRow = {
    row: t('value-calculator.lease-fee'),
    area: '',
    rentGross: '',
    rentNet: '',
    vacancy: '',
    costs:
      summaryData?.landLeaseCost && summaryData?.countLeaseFee
        ? `${Math.round(-summaryData?.landLeaseCost / 1000)}`
        : '-',
    maintenance: '',
    tax: '',
    operatingNet: '',
    yield:
      summaryData?.landLeaseCost && summaryData?.countLeaseFee
        ? `${summaryData?.avgYieldForLandLeaseCost}`
        : '-',
    valueTotal:
      summaryData?.landLeaseCost && summaryData?.countLeaseFee
        ? `${Math.round(summaryData?.landLeaseCostWithYield / 1000)}`
        : '-'
  };

  const sumRow = {
    row: t('general.total-2'),
    area: `${summaryData?.totalArea}`,
    rentGross: `${Math.round(summaryData?.totalRentGross / 1000)}`,
    rentNet: `${Math.round(summaryData?.totalRentNet / 1000)}`,
    vacancy: '',
    costs: `${Math.round(-summaryData?.totalCosts / 1000)}`,
    maintenance: `${Math.round(-summaryData?.totalMaintenance / 1000)}`,
    tax: `${-summaryData?.totalTax}`,
    operatingNetSqm: `${summaryData?.totalArea > 0 ? summaryData?.totalOperatingNetSqm : 0}`,
    operatingNet: `${Math.round(summaryData?.totalOperatingNet / 1000)}`,
    yield: `${summaryData.totalYield}`,
    valueTotalSqm: `${summaryData?.totalArea > 0 ? summaryData.totalValueSqm : 0}`,
    valueTotal: `${Math.round(summaryData?.totalValue / 1000)}`
  };

  return tabData.concat([landLeaseRow, sumRow]);
};

export const getSummaryData = (totalResult, object) => {
  let totalSum = 0;
  let totalArea = 0;
  let totalRentGross = 0;
  let totalRentNet = 0;
  let totalCosts = 0;
  let totalMaintenance = 0;
  let totalTax = 0;
  let totalOperatingNet = 0;
  let countLeaseFee = true;

  const apartmentCount = object?.apartmentCount ?? 0;

  const tabData = totalResult?.map((tab) => {
    if (typeof tab.countLeaseFee !== 'undefined' && tab.countLeaseFee !== countLeaseFee) {
      countLeaseFee = tab.countLeaseFee;
    }
    const tabTax =
      tab.area > 0 ? calculatePropertyTax(tab.propertyTaxType, tab.taxValue, apartmentCount) : 0;
    totalTax += tab.countPropertyTax ? tabTax : 0;

    const tabSum: number = calculatePropertyValue(
      tab?.result[ValueCategoryTypes.YIELD],
      tab?.result[ValueCategoryTypes.RENT] *
        ((100 - tab?.result[ValueCategoryTypes.VACANCY]) / 100),
      tab.result[ValueCategoryTypes.COSTS] +
        tab.result[ValueCategoryTypes.MAINTENANCE] +
        (tab?.countPropertyTax ? tabTax : 0)
    );
    totalSum += tabSum ?? 0;
    totalArea += tab.area ?? 0;

    const rentGross = Math.round(tab.result[ValueCategoryTypes.RENT]);
    totalRentGross += rentGross;

    const rentNet = Math.round(
      tab.result[ValueCategoryTypes.RENT] * ((100 - tab.result[ValueCategoryTypes.VACANCY]) / 100)
    );
    totalRentNet += rentNet;

    const tabVacancy = tab.result[ValueCategoryTypes.VACANCY];
    const tabCosts = Math.round(tab.result[ValueCategoryTypes.COSTS]);
    totalCosts += tabCosts;

    const tabMaintenance = Math.round(tab.result[ValueCategoryTypes.MAINTENANCE]);
    totalMaintenance += tabMaintenance;

    const operatingNet = Math.round(
      tab.result[ValueCategoryTypes.RENT] * ((100 - tab.result[ValueCategoryTypes.VACANCY]) / 100) -
        (tab.result[ValueCategoryTypes.COSTS] + tab.result[ValueCategoryTypes.MAINTENANCE]) -
        (tab.countPropertyTax ? tabTax : 0)
    );
    totalOperatingNet += operatingNet;

    const valueSqm = tab.area > 0 ? Math.round(tabSum / tab.area) : 0;
    const valueTotal = tab.area > 0 ? Math.round(tabSum) : 0;

    return {
      tabName: tab.tabName,
      rentGross: rentGross,
      rentNet: rentNet,
      tax: tabTax,
      vacancy: tabVacancy,
      costs: tabCosts,
      maintenance: tabMaintenance,
      area: tab.area,
      operatingNet: operatingNet,
      yield: tab.result[ValueCategoryTypes.YIELD],
      valueSqm: valueSqm,
      valueTotal: valueTotal ?? 0,
      countPropertyTax: tab.countPropertyTax,
      uuid: tab.uuid ?? null
    };
  });

  const avgYieldForLandLeaseCost = calculateAvgYieldOnValue(totalSum, tabData);

  const landLeaseCost =
    object?.landLeaseAgreement?.lease_fee && countLeaseFee
      ? +object?.landLeaseAgreement?.lease_fee
      : 0;
  const landLeaseCostWithYield =
    object?.landLeaseAgreement?.lease_fee && countLeaseFee && totalSum > 0
      ? Math.round(
          +object?.landLeaseAgreement?.lease_fee /
            ((avgYieldForLandLeaseCost > 0 ? avgYieldForLandLeaseCost : 1) / 100)
        )
      : 0;

  const totalValue = totalSum - landLeaseCostWithYield;
  const operatingNetTotal = totalOperatingNet - landLeaseCost;
  const totalYield =
    operatingNetTotal > 0 ? +((operatingNetTotal / totalValue) * 100).toFixed(2) : 0;

  return {
    totalValueSqm: totalArea > 0 ? Math.round(totalValue / totalArea) : 0,
    totalValue: totalValue,
    totalArea: totalArea,
    totalRentGross: totalRentGross,
    totalRentNet: totalRentNet,
    totalCosts: totalCosts + landLeaseCost,
    totalMaintenance: totalMaintenance,
    totalTax: totalTax,
    totalOperatingNetSqm: totalArea > 0 ? Math.round(operatingNetTotal / totalArea) : 0,
    totalOperatingNet: operatingNetTotal,
    avgYieldForLandLeaseCost: avgYieldForLandLeaseCost,
    totalYield: totalYield,
    countLeaseFee: countLeaseFee,
    landLeaseCost: landLeaseCost,
    landLeaseCostWithYield: landLeaseCostWithYield,
    tabData: tabData
  };
};
