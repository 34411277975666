import cls from 'classnames';

export default function Tooltip({
  children,
  className = ''
}: {
  children: JSX.Element | string;
  className?: string;
}) {
  return (
    <div
      className={cls(
        'tooltip text-xs font-normal p-3 bg-white dark:text-white dark:bg-dark-morefaded text-bobo-black rounded-md shadow-md absolute',
        className && className
      )}>
      {children}
    </div>
  );
}
