export default async function highlightSelected(map: any, selected: any[]) {
  selected &&
    selected?.map((item) => {
      try {
        map?.getMap()?.removeFeatureState({
          source: item?.source,
          sourceLayer: item?.sourceLayer
        });
        map.getMap()?.setFeatureState(
          {
            source: item?.source,
            id: item?.id,
            sourceLayer: item?.sourceLayer
          },
          { selected: true }
        );
      } catch (e) {
        console.log(`Failed to highlight selected for layer`, e);
      }
    });
}
