import { useContext } from 'react';

import { CashflowModelContext } from '../context/cashflow-context';

export const useCashflowModelContext = () => {
  const data = useContext(CashflowModelContext);
  if (!data) {
    throw new Error('This hook must be used inside a CashflowModel context provider');
  }
  return {
    models: data.models,
    selectedModel: data.selectedModel,
    setSelectedModel: data.setSelectedModel,
    loading: data.loading,
    refresh: data.refresh,
    object: data.object,
    sensitivities: data.sensitivities,
    sensitivitiesLoading: data.sensitivitiesLoading,
    inCrm: data.inCrm
  };
};
