/* External Libraries */
import { useUser } from '@auth0/nextjs-auth0';
/* Components & Widgets */
import Authenticated from 'src/layout/header/authenticated';

const Header = ({ boxShadow, subHeader }: { boxShadow: boolean; subHeader: boolean }) => {
  const { user } = useUser();

  if (user) {
    return <Authenticated subHeader={subHeader} boxShadow={boxShadow} user={user} />;
  }
  return <></>;
};

export default Header;
