/**
 * custom hook to use tailwind breakpoints in react components
 * inspired by https://stackoverflow.com/questions/59982018/how-do-i-get-tailwinds-active-breakpoint-in-javascript
 */
import { useEffect, useState } from 'react';
import resolveConfig from 'tailwindcss/resolveConfig';
import { Config } from 'tailwindcss/types/config';

import tailwindConfig from '@/tailwind.config.js'; // Your tailwind config

const fullConfig = resolveConfig(tailwindConfig as unknown as Config);

export function useBreakpoint<K extends string>({ breakpoint }: { breakpoint: K }) {
  const numericBreakpointsInOrder = Object.entries(fullConfig.theme.screens)
    // some breakpoints (like 'print') are not numbers so filter out
    .filter((a) => typeof a[1] === 'string')
    .map((a) => [a[0], parseInt(a[1] as string)])
    .sort((a, b) => {
      return (a[1] as number) - (b[1] as number);
    });

  let breakpointIndex = numericBreakpointsInOrder.findIndex((a) => a[0] === breakpoint);

  if (breakpointIndex == -1) {
    console.warn('[isDeviceSize fn] Breakpoint not found:', breakpoint);
    breakpointIndex = 0;
  }

  const currentBreakpoint = numericBreakpointsInOrder[breakpointIndex];
  const nextBreakpoint = numericBreakpointsInOrder[breakpointIndex + 1];

  const [matchesThisBreakpointOrAbove, setMatchesThisBreakpointOrAbove] = useState(false);
  const [matchesThisBreakpointOnly, setMatchesThisBreakpointOnly] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const handleResize = () => {
      const matches = window.matchMedia(`(min-width: ${currentBreakpoint[1]}px)`).matches;
      setMatchesThisBreakpointOrAbove(matches);

      setMatchesThisBreakpointOnly(
        nextBreakpoint
          ? matches && !window.matchMedia(`(min-width: ${nextBreakpoint[1]}px)`).matches
          : matches
      );
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    // Clean up
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [currentBreakpoint, nextBreakpoint]);

  const capitalizedKey = breakpoint[0].toUpperCase() + breakpoint.substring(1);

  type KeyAbove = `is${Capitalize<K>}`;
  type KeyOnly = `is${Capitalize<K>}Only`;
  type Key = KeyAbove | KeyOnly;

  return {
    [`is${capitalizedKey}`]: matchesThisBreakpointOrAbove,
    [`is${capitalizedKey}Only`]: matchesThisBreakpointOnly,
    [capitalizedKey]: currentBreakpoint[1]
  } as Record<K, number> & Record<Key, boolean>;
}
