import centroid from '@turf/centroid';
import { PropertyTypeGroup } from 'shared/dist/types';
import { getGroupForObjectType } from 'shared/dist/util/calculations';

import { getPrimaryColorForMap } from '../../../colors';
import {
  TYPES_HOUSING,
  TYPES_INDUSTRY,
  TYPES_OFFICE,
  TYPES_PRIVATE,
  TYPES_SPECIAL,
  TYPES_STREET,
  TYPES_WAREHOUSE
} from '../../../constants';
import loadSources from '../../sources';

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default async function propertiesBasic(
  map: any,
  theme: any,
  propertyTypes?: PropertyTypeGroup[],
  disableInteraction?: boolean
) {
  const lowestLabelLayerId = 'road-primary';
  const borderColor: string = theme === 'dark' ? '#ff6666' : '#FF0000';

  let overlayOpacity = 0.1;
  let overlayHoverEffect = 0.1;
  let overlayColor = '#ffffff';

  switch (theme) {
    case 'satellite':
      overlayOpacity = 0.05;
      overlayHoverEffect = 0.1;
      overlayColor = '#ffffff';
      break;
    case 'dark':
      overlayOpacity = 0.05;
      overlayHoverEffect = 0.1;
      overlayColor = '#444444';
      break;
    case 'lantmateriet':
      overlayOpacity = 0.05;
      overlayHoverEffect = 0.1;
      overlayColor = '#ffffff';
      break;
  }

  map?.getMap()?.getLayer('prop-area') && map?.getMap()?.removeLayer('prop-area');
  map?.getMap()?.getLayer('prop-line') && map?.getMap()?.removeLayer('prop-line');

  await sleep(200);

  try {
    if (
      map &&
      map?.getMap() &&
      map?.getMap() !== undefined &&
      !map?.getMap()?.getSource('properties')
    ) {
      await loadSources(map, ['properties']);
      await sleep(1000);
    }
  } catch (e) {
    console.log(`Map is not yet rendered`, e, map);
  }

  try {
    !map?.getMap()?.getLayer('prop-area') &&
      map?.getMap()?.getSource('properties') &&
      map?.getMap()?.addLayer(
        {
          id: 'prop-area',
          type: 'fill',
          source: 'properties', // reference the data source
          'source-layer': 'properties', // reference the data source
          layout: {},
          paint: {
            'fill-color': [
              'match',
              ['get', 'primary_tax_code'],
              TYPES_PRIVATE,
              propertyTypes?.includes(PropertyTypeGroup.PRIVATE)
                ? getPrimaryColorForMap(PropertyTypeGroup.PRIVATE, 1)
                : overlayColor,
              TYPES_HOUSING,
              propertyTypes?.includes(PropertyTypeGroup.HOUSING)
                ? getPrimaryColorForMap(PropertyTypeGroup.HOUSING, 1)
                : overlayColor,
              TYPES_OFFICE,
              propertyTypes?.includes(PropertyTypeGroup.SPACE)
                ? getPrimaryColorForMap(PropertyTypeGroup.SPACE, 1)
                : overlayColor,
              TYPES_WAREHOUSE,
              propertyTypes?.includes(PropertyTypeGroup.WAREHOUSE)
                ? getPrimaryColorForMap(PropertyTypeGroup.WAREHOUSE, 1)
                : overlayColor,
              TYPES_INDUSTRY,
              propertyTypes?.includes(PropertyTypeGroup.INDUSTRY)
                ? getPrimaryColorForMap(PropertyTypeGroup.INDUSTRY, 1)
                : overlayColor,
              TYPES_SPECIAL,
              propertyTypes?.includes(PropertyTypeGroup.SPECIAL)
                ? getPrimaryColorForMap(PropertyTypeGroup.SPECIAL, 1)
                : overlayColor,
              TYPES_STREET,
              propertyTypes?.includes(PropertyTypeGroup.STREET)
                ? getPrimaryColorForMap(PropertyTypeGroup.STREET, 1)
                : overlayColor,
              overlayColor
            ],
            'fill-opacity': [
              'case',
              ['boolean', ['feature-state', 'selected'], false],
              0.2,
              ['boolean', ['feature-state', 'hover'], false],
              overlayOpacity - overlayHoverEffect,
              overlayOpacity
            ]
          }
        },
        lowestLabelLayerId
      );

    !map?.getMap()?.getLayer('prop-line') &&
      map?.getMap()?.getSource('properties') &&
      map?.getMap()?.addLayer(
        {
          id: 'prop-line',
          type: 'line',
          source: 'properties', // reference the data source
          'source-layer': 'properties', // reference the data source
          layout: {},
          paint: {
            'line-color': borderColor,
            // 'line-color': [
            //   'case',
            //   ['boolean', ['feature-state', 'selected'], false],
            //   [
            //     'match',
            //     ['get', 'primaryTaxCode'],
            //     TYPES_PRIVATE,
            //     getPrimaryColorForMap(PropertyTypeGroup.PRIVATE, 1),
            //     TYPES_HOUSING,
            //     getPrimaryColorForMap(PropertyTypeGroup.HOUSING, 1),
            //     TYPES_OFFICE,
            //     getPrimaryColorForMap(PropertyTypeGroup.SPACE, 1),
            //     TYPES_WAREHOUSE,
            //     getPrimaryColorForMap(PropertyTypeGroup.WAREHOUSE, 1),
            //     TYPES_INDUSTRY,
            //     getPrimaryColorForMap(PropertyTypeGroup.INDUSTRY, 1),
            //     TYPES_SPECIAL,
            //     getPrimaryColorForMap(PropertyTypeGroup.SPECIAL, 1),
            //     TYPES_STREET,
            //     getPrimaryColorForMap(PropertyTypeGroup.STREET, 1),
            //     overlayColor
            //   ],
            //   ['boolean', ['feature-state', 'hover'], false],
            //   [
            //     'match',
            //     ['get', 'primaryTaxCode'],
            //     TYPES_PRIVATE,
            //     getPrimaryColorForMap(PropertyTypeGroup.PRIVATE, 1),
            //     TYPES_HOUSING,
            //     getPrimaryColorForMap(PropertyTypeGroup.HOUSING, 1),
            //     TYPES_OFFICE,
            //     getPrimaryColorForMap(PropertyTypeGroup.SPACE, 1),
            //     TYPES_WAREHOUSE,
            //     getPrimaryColorForMap(PropertyTypeGroup.WAREHOUSE, 1),
            //     TYPES_INDUSTRY,
            //     getPrimaryColorForMap(PropertyTypeGroup.INDUSTRY, 1),
            //     TYPES_SPECIAL,
            //     getPrimaryColorForMap(PropertyTypeGroup.SPECIAL, 1),
            //     TYPES_STREET,
            //     getPrimaryColorForMap(PropertyTypeGroup.STREET, 1),
            //     overlayColor
            //   ],
            //   borderColor
            // ],
            'line-width': [
              'case',
              ['boolean', ['feature-state', 'selected'], false],
              4,
              ['boolean', ['feature-state', 'hover'], false],
              2,
              1
            ],
            'line-dasharray': [2, 1],
            'line-opacity': ['interpolate', ['exponential', 1], ['zoom'], 10, 0, 15, 1]
          }
        },
        lowestLabelLayerId
      );

    let hoveredStateId: string | null = null;

    map?.getMap()?.on('mousemove', 'prop-area', (e) => {
      let idx = 0;
      if (e?.features?.length > 1) {
        e?.features?.map((a, i) => {
          idx = i;
        });
      }

      if (
        e.features.length > 0 &&
        e.features[idx].properties?.uuid !== hoveredStateId &&
        !disableInteraction
      ) {
        map.getMap().getCanvas().style.cursor = 'pointer';
        if (hoveredStateId !== null) {
          const tmp: any = e.features[idx]?.properties;
          tmp.title = `${e.features[idx]?.properties?.authority_nickname}`?.toLowerCase();
          const c: any = centroid(e.features[idx]?.geometry);
          tmp.lng = c?.geometry?.coordinates[0];
          tmp.lat = c?.geometry?.coordinates[1];

          map?.getMap()?.setFeatureState(
            {
              source: 'properties',
              id: hoveredStateId,
              sourceLayer: 'properties'
            },
            { hover: false }
          );

          map?.getMap()?.getSource('buildings') &&
            map?.getMap()?.setFeatureState(
              {
                source: 'buildings',
                id: hoveredStateId,
                sourceLayer: 'buildings'
              },
              { hover: false }
            );
        }
        hoveredStateId = e.features[idx]?.properties?.uuid;

        if (!TYPES_STREET.includes(e.features[idx]?.properties?.primary_tax_code)) {
          map?.getMap()?.setFeatureState(
            {
              source: 'properties',
              id: hoveredStateId,
              sourceLayer: 'properties'
            },
            { hover: true }
          );

          map?.getMap()?.getSource('buildings') &&
            map?.getMap()?.setFeatureState(
              {
                source: 'buildings',
                id: hoveredStateId,
                sourceLayer: 'buildings'
              },
              { hover: true }
            );
        }
      } else if (
        !propertyTypes?.includes(
          getGroupForObjectType(e?.features[idx]?.properties?.primary_tax_code)
        )
      ) {
        map.getMap().getCanvas().style.cursor = 'default';
      }
    });

    map?.getMap()?.on('mouseleave', 'prop-area', () => {
      map.getMap().getCanvas().style.cursor = 'default';
      map?.getMap()?.setFeatureState(
        {
          source: 'properties',
          id: hoveredStateId,
          sourceLayer: 'properties'
        },
        { hover: false }
      );

      map?.getMap()?.getSource('buildings') &&
        map?.getMap()?.setFeatureState(
          {
            source: 'buildings',
            id: hoveredStateId,
            sourceLayer: 'buildings'
          },
          { hover: false }
        );
      hoveredStateId = null;
    });
  } catch (e) {
    console.log(e, 'pending map rendering');
  }
}
