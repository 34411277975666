/* External Libraries */
import 'mapbox-gl/dist/mapbox-gl.css';

import { useTheme } from 'next-themes';
import { useCallback, useRef, useState } from 'react';
import { Map } from 'react-map-gl';

/* Components & Widgets */
import { lcAreaLayer, lcLineLayer } from './items/layers';

export default function MainMap({ bbox, id }: { bbox: any; id: string }) {
  const mapRef: any = useRef();
  const { resolvedTheme } = useTheme();

  const customMap =
    resolvedTheme === 'light'
      ? 'mapbox://styles/bobolaget/ckwlahgui1ctx14o2ybuyx5vy'
      : 'mapbox://styles/bobolaget/cl4kx9o21007j15nyucf4lw80';

  const [zoom, setZoom] = useState<number | null>(null);
  const [mapStyle, setMapStyle] = useState(true);
  const [viewState] = useState({
    maxZoom: 22,
    minZoom: 4,
    pitch: 20,
    bearing: 0,
    bounds: bbox?.coordinates
  });

  const onMapLoad = useCallback(() => {
    if (!mapRef?.current) {
      throw 'map failed';
    }

    const map: any = mapRef?.current;

    if (!map?.getSource('local-council')) {
      map?.getMap()?.addSource('local-council', {
        type: 'vector',
        url: 'mapbox://bobolaget.60jr9vre'
      });
      // Layer settings
      map?.getMap()?.addLayer({
        id: 'polygons',
        type: 'fill',
        'source-layer': 'kommunytaid',
        source: 'local-council',
        paint: lcAreaLayer
      });
      map?.getMap()?.addLayer({
        id: 'lc-lines',
        type: 'line',
        'source-layer': 'kommunytaid',
        source: 'local-council',
        paint: lcLineLayer
      });
    }

    map.setFeatureState(
      { source: 'local-council', id: parseInt(id), sourceLayer: 'kommunytaid' },
      { selected: true }
    );
    map.setZoom(map.getZoom() - 0.3);

    map?.on('zoom', (e) => {
      // do something
      setZoom(Math.round(e?.target?.getZoom() * 100) / 100);
    });
  }, []);

  return (
    <Map
      initialViewState={viewState}
      id="myMapA"
      ref={mapRef}
      onLoad={onMapLoad}
      style={{ width: '100%', height: '100%' }}
      mapboxAccessToken={`pk.eyJ1IjoiYm9ib2xhZ2V0IiwiYSI6ImNrZmNmZmtlajE4NG0zNm81NjB5ZTgwOGwifQ.AqhIm23pQPp2jjs_Q2Ca6Q`}
      mapStyle={`${mapStyle ? 'mapbox://styles/bobolaget/clbad5k62007e14ntq3q6rrly' : customMap}`}
    />
  );
}
