import React from 'react';
import { getCategoryName } from 'shared/dist/constants';
import { ValueCategoryTypes } from 'shared/dist/types';

import { FLCustom, FLSqm } from '@/src/widgets/numbers';

export const CASHFLOW_ROW_UNITS = {
  // These are keys for rows coming from the backend
  // Would be good to add them as a type to 'Shared'
  'Rental income': {
    label: 'general.rent',
    unit: 'general.thousand-suffix',
    decimals: 0,
    scale: 1 / 1000,
    helpTextId: 'cashflowRent'
  },
  Supplements: {
    label: 'property.cashflow-valuation.assumptions.supplements',
    unit: 'general.thousand-suffix',
    decimals: 0,
    scale: 1 / 1000
  },
  Vacancy: {
    label: 'value-calculator.vacancy',
    unit: 'general.thousand-suffix',
    decimals: 0,
    scale: 1 / 1000
  },
  Maintenance: {
    label: 'property.cashflow-valuation.maintenance-long',
    unit: 'general.thousand-suffix',
    decimals: 0,
    scale: 1 / 1000,
    helpTextId: 'cashflowMaintenance'
  },
  Savings: {
    label: 'property.cashflow-valuation.savings',
    unit: 'general.thousand-suffix',
    decimals: 0,
    scale: 1 / 1000
  },
  'Property tax': {
    label: 'property.cashflow-valuation.prop-tax',
    unit: 'general.thousand-suffix',
    decimals: 0,
    scale: 1 / 1000
  },
  Leasehold: {
    label: 'property.cashflow-valuation.leasehold',
    unit: 'general.thousand-suffix',
    decimals: 0,
    scale: 1 / 1000
  },
  NOI: {
    label: 'property.cashflow-valuation.noi',
    unit: 'general.thousand-suffix',
    decimals: 0,
    scale: 1 / 1000
  },
  CAPEX: {
    label: 'property.cashflow-valuation.capex',
    unit: 'general.thousand-suffix',
    decimals: 0,
    scale: 1 / 1000
  },
  Cashflow: {
    label: 'property.cashflow-valuation.cashflow-unlevered',
    unit: 'general.thousand-suffix',
    decimals: 0,
    scale: 1 / 1000
  },
  'Terminal value': {
    label: 'property.cashflow-valuation.terminal-value',
    unit: 'general.thousand-suffix',
    decimals: 0,
    scale: 1 / 1000
  },

  'Hold fraction': {
    label: 'property.cashflow-valuation.hold-fraction',
    unit: '',
    decimals: 2,
    scale: 1
  },
  Period: {
    label: 'property.cashflow-valuation.period',
    scale: 1,
    unit: 'units.year-suffix',
    decimals: 0
  },
  'Discount factor': {
    label: 'property.cashflow-valuation.discount-factor',
    unit: '',
    decimals: 2,
    scale: 1,
    helpTextId: 'cashflowDiscountFactor'
  },
  NPV: {
    label: 'property.cashflow-valuation.npv',
    unit: 'general.thousand-suffix',
    decimals: 0,
    scale: 1 / 1000
  },

  Interest: {
    label: 'property.cashflow-valuation.interest',
    unit: 'general.thousand-suffix',
    decimals: 0,
    scale: 1 / 1000
  },
  Amortization: {
    label: 'property.cashflow-valuation.amortization',
    unit: 'general.thousand-suffix',
    decimals: 0,
    scale: 1 / 1000
  },
  'Unlevered CF': {
    label: 'property.cashflow-valuation.cashflow-unlevered',
    unit: 'general.thousand-suffix',
    decimals: 0,
    scale: 1 / 1000
  },
  'Levered CF': {
    label: 'property.cashflow-valuation.cashflow-levered',
    unit: 'general.thousand-suffix',
    decimals: 0,
    scale: 1 / 1000,
    helpTextId: 'cashflowUnlevered'
  },
  Loan: {
    label: 'property.cashflow-valuation.loan',
    unit: 'general.thousand-suffix',
    decimals: 0,
    scale: 1 / 1000,
    helpTextId: 'cashflowLoan'
  },
  ICR: {
    label: 'property.cashflow-valuation.icr',
    unit: 'x',
    decimals: 2,
    scale: 1
  },
  DSCR: {
    label: 'property.cashflow-valuation.dscr',
    unit: 'x',
    decimals: 2,
    scale: 1
  },
  LTV: {
    label: 'property.cashflow-valuation.ltv',
    unit: '%',
    decimals: 2,
    scale: 100
  },
  'Debt yield': {
    label: 'property.cashflow-valuation.debt-yield',
    unit: '%',
    decimals: 2,
    scale: 100
  }
} as {
  [key: string]: {
    label: string;
    unit: string;
    decimals: number;
    scale: number;
    helpTextId?: string;
  };
};

const accentuatingCell =
  'font-medium !bg-gray-50 dark:!bg-dark-lessfaded group-hover:!bg-bobo-proplight dark:group-hover:!bg-gray-800 transition-colors duration-200';

interface TableRow {
  title: string;
  className?: string;
  render: (prop: any, t: any, open?: (e) => void) => JSX.Element;
  renderAssumption: (ass: any, t: any) => JSX.Element;
  renderResult: (res: any, t: any) => JSX.Element;
}

export const tableCols: TableRow[] = [
  {
    title: '',
    className: '!font-medium',
    render: (row, _, openSlideIn) => (
      <>
        <button
          onClick={(e) => {
            e.stopPropagation();
            openSlideIn && openSlideIn(row);
          }}
          className="text-bobo-prop dark:text-dark-prop hover:text-bobo-prophover dark:hover:text-dark-prohover transition-colors duration-200">
          {row.propertyName}
        </button>
        <div className="text-gray-400">
          <FLSqm value={row.totalArea} />
        </div>
      </>
    ),
    renderAssumption: (row, t) => (
      <>
        <div>{t(getCategoryName(row.tabName)) || row.tabName}</div>
        <div className="text-gray-400">
          <FLSqm value={row.area} />
        </div>
      </>
    ),
    renderResult: (row, t) => (
      <>
        <div>{t('general.total')}</div>
        <div className="text-gray-400">
          <FLSqm value={row?.totalArea} />
        </div>
      </>
    )
  },
  {
    title: 'value-calculator.rent-gross',
    className: '!font-light',
    render: (row, t) => (
      <FLCustom
        value={row.totalArea ? Math.round(row.totalRentGross / 1000) : 0}
        suffix={t('general.thousand-suffix')}
        prefix={t('general.sek-prefix')}
      />
    ),
    renderAssumption: (row, t) => (
      <FLCustom
        value={row.area ? Math.round(row.rentGross / 1000) : 0}
        suffix={t('general.thousand-suffix')}
        prefix={t('general.sek-prefix')}
      />
    ),
    renderResult: (row, t) => (
      <FLCustom
        value={Math.round(row?.totalRentGross / 1000)}
        suffix={t('general.thousand-suffix')}
        prefix={t('general.sek-prefix')}
      />
    )
  },
  {
    title: ValueCategoryTypes.VACANCY,
    render: (row) => <FLCustom value={row.vacancy ?? 0} suffix={'%'} />,
    renderAssumption: (row) => <FLCustom value={row.vacancy ?? 0} suffix={'%'} />,
    renderResult: (row) => <></>
  },
  {
    title: 'value-calculator.rent-net',
    className: accentuatingCell,
    render: (row, t) => (
      <FLCustom
        value={row.totalArea ? Math.round(row.totalRentNet / 1000) : 0}
        suffix={t('general.thousand-suffix')}
        prefix={t('general.sek-prefix')}
      />
    ),
    renderAssumption: (row, t) => (
      <FLCustom
        value={row.area ? Math.round(row.rentNet / 1000) : 0}
        suffix={t('general.thousand-suffix')}
        prefix={t('general.sek-prefix')}
      />
    ),
    renderResult: (row, t) => (
      <FLCustom
        value={Math.round(row?.totalRentNet / 1000)}
        suffix={t('general.thousand-suffix')}
        prefix={t('general.sek-prefix')}
      />
    )
  },
  {
    title: 'property.cashflow-valuation.maintenance-short',
    render: (row, t) => (
      <FLCustom
        value={Math.round(-row.totalCosts / 1000)}
        suffix={t('general.thousand-suffix')}
        prefix={t('general.sek-prefix')}
      />
    ),
    renderAssumption: (row, t) => (
      <FLCustom
        value={Math.round(-row.costs / 1000)}
        suffix={t('general.thousand-suffix')}
        prefix={t('general.sek-prefix')}
      />
    ),
    renderResult: (row, t) => (
      <FLCustom
        value={Math.round(-row?.totalCosts / 1000)}
        suffix={t('general.thousand-suffix')}
        prefix={t('general.sek-prefix')}
      />
    )
  },
  {
    title: ValueCategoryTypes.MAINTENANCE,
    render: (row, t) => (
      <FLCustom
        value={Math.round(-row.totalMaintenance / 1000)}
        suffix={t('general.thousand-suffix')}
        prefix={t('general.sek-prefix')}
      />
    ),
    renderAssumption: (row, t) => (
      <FLCustom
        value={Math.round(-row.maintenance / 1000)}
        suffix={t('general.thousand-suffix')}
        prefix={t('general.sek-prefix')}
      />
    ),
    renderResult: (row, t) => (
      <FLCustom
        value={Math.round(-row?.totalMaintenance / 1000)}
        suffix={t('general.thousand-suffix')}
        prefix={t('general.sek-prefix')}
      />
    )
  },
  {
    title: 'value-calculator.prop-tax',
    render: (row, t) => (
      <FLCustom
        value={-Math.round(row.totalTax / 1000)}
        suffix={t('general.thousand-suffix')}
        prefix={t('general.sek-prefix')}
      />
    ),
    renderAssumption: (row, t) =>
      row.countPropertyTax ? (
        <FLCustom
          value={-Math.round(row.tax / 1000)}
          suffix={t('general.thousand-suffix')}
          prefix={t('general.sek-prefix')}
        />
      ) : (
        <div>–</div>
      ),
    renderResult: (row, t) => (
      <FLCustom
        value={-Math.round(row?.totalTax / 1000)}
        suffix={t('general.thousand-suffix')}
        prefix={t('general.sek-prefix')}
      />
    )
  },
  {
    title: 'value-calculator.operation-net-sqm',
    className: accentuatingCell,
    render: (row, t) => (
      <FLCustom
        value={row.totalArea > 0 ? Math.round(row?.totalOperatingNetSqm) : 0}
        suffix={t('general.kr-per-square-m-suffix')}
      />
    ),
    renderAssumption: (row, t) => (
      <FLCustom
        value={row.area > 0 ? Math.round(row?.operatingNet / row.area) : 0}
        suffix={t('general.kr-per-square-m-suffix')}
      />
    ),
    renderResult: (row, t) => (
      <FLCustom
        value={row?.totalArea > 0 ? Math.round(row?.totalOperatingNetSqm) : 0}
        suffix={t('general.kr-per-square-m-suffix')}
      />
    )
  },
  {
    title: 'value-calculator.operation-net',
    className: accentuatingCell,
    render: (row, t) => (
      <FLCustom
        value={Math.round(row.totalOperatingNet / 1000)}
        suffix={t('general.thousand-suffix')}
        prefix={t('general.sek-prefix')}
      />
    ),
    renderAssumption: (row, t) => (
      <FLCustom
        value={Math.round(row.operatingNet / 1000)}
        suffix={t('general.thousand-suffix')}
        prefix={t('general.sek-prefix')}
      />
    ),
    renderResult: (row, t) => (
      <FLCustom
        value={Math.round(row?.totalOperatingNet / 1000)}
        suffix={t('general.thousand-suffix')}
        prefix={t('general.sek-prefix')}
      />
    )
  },
  {
    title: ValueCategoryTypes.YIELD,
    render: (row) => <FLCustom value={row.totalYield} suffix={'%'} />,
    renderAssumption: (row) => <FLCustom value={row.yield} suffix={'%'} />,
    renderResult: (row) => <FLCustom value={row?.totalYield} suffix={'%'} />
  },
  {
    title: 'value-calculator.value-sqm',
    className: accentuatingCell,
    render: (row, t) => (
      <FLCustom
        value={row.totalArea > 0 ? row.totalValueSqm : 0}
        suffix={t('general.kr-per-square-m-suffix')}
      />
    ),
    renderAssumption: (row, t) => (
      <FLCustom
        value={row.area > 0 ? Math.round(row.valueTotal / row.area) : 0}
        suffix={t('general.kr-per-square-m-suffix')}
      />
    ),
    renderResult: (row, t) => (
      <FLCustom
        value={Math.round(row?.totalArea > 0 ? row.totalValueSqm : 0)}
        suffix={t('general.kr-per-square-m-suffix')}
      />
    )
  },
  {
    title: 'value-calculator.value',
    className: accentuatingCell,
    render: (row, t) => (
      <FLCustom
        value={Math.round(row.totalValue / 1000)}
        suffix={t('general.thousand-suffix')}
        prefix={t('general.sek-prefix')}
      />
    ),
    renderAssumption: (row, t) => (
      <FLCustom
        value={Math.round(row.valueTotal / 1000)}
        suffix={t('general.thousand-suffix')}
        prefix={t('general.sek-prefix')}
      />
    ),
    renderResult: (row, t) => (
      <FLCustom
        value={Math.round(row.totalValue / 1000)}
        suffix={t('general.thousand-suffix')}
        prefix={t('general.sek-prefix')}
      />
    )
  }
];

// Input field limits
// leases
export const MAX_LIMIT_LEASE_AREA_INPUT = 1e5;

// credits
export const MAX_LIMIT_CREDIT_VALUE_INPUT = 1e10;

//investments
export const MAX_LIMIT_INVESTMENT_AMOUNT_INPUT = 1e10;
export const MAX_LIMIT_INVESTMENT_SAVINGS_INPUT = 1e10;
export const MAX_LIMIT_INVESTMENT_MONTHS_INPUT = 1e2;

// assets
export const MAX_LIMIT_TAX_VALUE_INPUT = 1e6;
export const MAX_LIMIT_ASSET_AREA_INPUT = 1e5;

// general
export const MAX_LIMIT_RENT_PER_SQM_INPUT = 1e4;

export const PROPERTY_TAX_PER_APARTMENT_LIMIT = 1724;
