/* External Libraries */
import { Popover, Transition } from '@headlessui/react';
import {
  Bars3Icon as MenuIcon,
  UserPlusIcon as UserAddIcon,
  XMarkIcon as XIcon
} from '@heroicons/react/24/solid';
import cls from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { Fragment } from 'react';
import { useGlobal } from 'reactn';
/* Components & Widgets */
import { Actor, MenuMode } from 'shared/dist/types';
import { useMenuMode } from 'src/context/menu-mode-context';
import UserMenu from 'src/layout/menu/user-menu';

const AuthenticatedHeader = ({
  user,
  boxShadow,
  subHeader
}: {
  user: any;
  boxShadow: boolean;
  subHeader: boolean;
}) => {
  const [activeActor]: Actor[] | any[] = useGlobal<{ activeActor: Actor }>('activeActor' as never);
  const { menuMode, setMenuMode } = useMenuMode();
  const { t } = useTranslation('common');

  return (
    <Popover
      className={`relative flex flex-row h-[65px] max-w-full justify-between z-50 pl-2 md:pl-4 md:ml-[55px] py-8 bg-white dark:text-white dark:bg-dark-primary border-b border-gray-200 ${
        boxShadow && !subHeader ? 'bg-white' : ''
      } transition-all dark:border-dark-morefaded items-center ${subHeader && 'border-b'}`}
      id="main-header">
      <div className="flex flex-row items-center gap-6">
        {menuMode === MenuMode.MOBILE_OPEN ? (
          <XIcon
            onClick={() => setMenuMode(MenuMode.MOBILE_CLOSED)}
            className="flex md:hidden h-[20px] w-[20px] mx-2 my-5.5 fill-light-graytext cursor-pointer"
          />
        ) : (
          <MenuIcon
            onClick={() => setMenuMode(MenuMode.MOBILE_OPEN)}
            className="flex md:hidden h-[20px] w-[20px] mx-2 my-5.5 fill-light-graytext cursor-pointer"
          />
        )}

        <Link href="/" className="md:flex items-end ml-6 hidden">
          <span className="sr-only">{t('general.brand-name')}</span>

          <Image
            height={45}
            width={45}
            className="h-[20px] lg:h-[33px] transition-all duration-300 hover:opacity-80 w-auto"
            src="/images/logo-icon-xmas.svg"
            alt="logo Fastighetsloggen"
          />
        </Link>
      </div>
      <div className="ml-4 hidden lg:flex"></div>
      <div className="mr-2 my-2 md:hidden">
        <Popover.Button className="group text-gray-800 dark:text-gray-200 hover:border-opacity-60 transition-all duration-300 leading-[13px] bg-white dark:bg-dark-primary overflow-hidden items-end text-xs font-normal hover:text-gray-600 lowercase flex md:hidden flex-col justify-center border-r-4 border-bobo-orange pr-1">
          <span className="sr-only">{t('general.open')}</span>

          <div className="capitalize font-medium text-sm">{user?.name ?? user?.nickname}</div>

          <div className="capitalize">{activeActor?.company_name ?? activeActor?.name}</div>
        </Popover.Button>
      </div>
      <div className="md:flex-1 md:flex hidden md:items-center md:justify-between">
        <div className="flex items-center md:ml-12 mr-6 space-x-2">
          <Link
            href={`/companyprofile/team`}
            className="hidden md:flex rounded-full bg-bobo-orangelight p-2 bg-opacity-10 dark:bg-opacity-70 hover:opacity-80 hover:shadow hover:bg-opacity-50 transition-all cursor-pointer">
            <UserAddIcon className="text-bobo-orange dark:text-gray-200 h-5 w-5" />
          </Link>

          <Popover className="relative">
            {({ open, close }) => (
              <>
                <Popover.Button
                  className={cls(
                    open ? 'text-gray-900 dark:text-white' : 'text-gray-800 dark:text-gray-200',
                    'group hover:border-opacity-60 transition-all ml-6 leading-[13px] bg-white dark:bg-dark-primary overflow-hidden items-end text-xs font-normal hover:text-gray-600 lowercase md:flex hidden flex-col justify-center border-r-4 border-bobo-orange pr-1'
                  )}>
                  <div className="capitalize font-medium text-sm">
                    {user?.name ?? user?.nickname}
                  </div>

                  <div className="capitalize">{activeActor?.company_name ?? activeActor?.name}</div>
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1">
                  <Popover.Panel className="absolute z-50 -right-6 transform mt-3 px-2 w-screen max-w-xs sm:px-0">
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                      <UserMenu user={user} close={close} />
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        </div>
      </div>
      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95">
        <Popover.Panel
          focus
          className="absolute z-30 top-0 inset-x-0 sm:p-2 transition transform origin-top-right md:hidden">
          <div className="sm:rounded-lg sm:shadow-lg sm:ring-1 ring-black ring-opacity-5 bg-white dark:bg-dark-primary divide-y-2 divide-gray-50 dark:divide-dark-morefaded sm:overflow-y-auto h-[100vh] sm:max-h-[calc(100vh_-_15px)]">
            <div className="pt-5 pb-6 px-5 sm:pb-8">
              <div className="flex items-center justify-between">
                <div>
                  <span className="sr-only">{t('general.brand-name')}</span>

                  <Image
                    height={20}
                    width={20}
                    className="h-6 sm:h-8 w-auto"
                    src="/images/logo-icon-xmas.svg"
                    alt=""
                  />
                </div>

                <div className="-mr-2">
                  <Popover.Button className="bg-white dark:bg-dark-primary rounded-md p-2 inline-flex items-center justify-center text-gray-400 dark:text-gray-200 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">{t('general.close')}</span>

                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>

            <div className="py-6 px-5">
              <UserMenu user={user} close={close} />
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default AuthenticatedHeader;
