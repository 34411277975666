import { PlusIcon } from '@heroicons/react/20/solid';
import cls from 'classnames';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { getCategoryName } from 'shared/dist/constants';
import { CashflowInflation, IndexType } from 'shared/dist/types/cashflow/cashflow-model';
import { PropertyVM } from 'shared/dist/types/property2';
import { NewsecCategory } from 'shared/dist/types/rent';

import DropdownMenu from '@/src/layout/components/dropdown-menu';
import Button from '@/src/widgets/buttons';

import AreaInputDropdown from './buttons/area-input-dropdown';
import DeleteTabBtn from './buttons/delete-tab-button';

export default function TabHeader({
  totalResult,
  selectedTab,
  deleteTab,
  changeArea,
  changeTabName,
  setNewCategory,
  selectedCategory,
  area,
  setArea,
  property,
  cashflow = false,
  setInflation,
  goToInflationModal,
  rentIndex,
  costIndex,
  errorState = {},
  handleSave
}: {
  totalResult: any[];
  selectedTab: string;
  deleteTab: any;
  changeArea: any;
  changeTabName: any;
  setNewCategory: any;
  selectedCategory: NewsecCategory | 'other';
  area: number;
  setArea: any;
  property: PropertyVM;
  cashflow?: boolean;
  setInflation?: any;
  goToInflationModal?: (i: CashflowInflation | undefined, type: IndexType) => void;
  rentIndex?: { options: CashflowInflation[]; selected: string };
  costIndex?: { options: CashflowInflation[]; selected: string };
  errorState?: any;
  handleSave?: () => void;
}) {
  const [tabName, setTabName] = useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    const tmpArea: number = totalResult?.find((tab) => tab.tabName === selectedTab)?.area;
    if (area !== tmpArea && (tmpArea || cashflow)) {
      setArea(tmpArea);
    }
    if (cashflow) {
      setTabName(selectedTab);
    } else {
      setTabName('');
    }
  }, [selectedTab, totalResult]);

  const renderPropTypes = (closeMenu: () => void) =>
    Object.values(NewsecCategory)?.map((propType, idx) => {
      return (
        <div
          key={idx}
          className={cls('py-3 px-5 transition-colors duration-200 text-xs w-full', {
            'hover:bg-bobo-proplight dark:hover:bg-dark-prophover text-bobo-prop dark:text-white hover:text-bobo-prophover':
              propType !== selectedCategory,
            'bg-bobo-prop text-white dark:bg-dark-prop': propType === selectedCategory
          })}>
          <button
            disabled={propType === selectedCategory}
            onClick={(e) => {
              e.stopPropagation();
              setNewCategory(propType);
              closeMenu();
            }}
            className="disabled:text-gray-300 w-full h-full">
            {t(getCategoryName(propType))}
          </button>
        </div>
      );
    });

  const renderIndexOptions = (closeMenu, options, indexType: IndexType) => {
    const inflations: JSX.Element[] =
      options && options.length > 0
        ? options.map((i, idx) => {
            return (
              <div
                key={idx}
                className="py-2 px-2 transition-colors duration-200 hover:bg-bobo-proplight dark:hover:bg-dark-prophover hover:text-bobo-prophover text-bobo-prop dark:text-white w-full flex justify-between items-center">
                <button
                  onClick={() => {
                    setInflation && setInflation(i, indexType);
                    closeMenu();
                  }}
                  className="disabled:text-gray-300 w-full h-full text-start">
                  {t(getCategoryName(i.name)) || i.name}
                </button>
                <button
                  onClick={() => goToInflationModal && goToInflationModal(i, indexType)}
                  className="transition-colors duration-200 py-1 px-1.5 hover:bg-white dark:hover:bg-dark-primary rounded-lg">
                  {t('general.change')}
                </button>
              </div>
            );
          })
        : [<div key={options?.length ?? 0} />];

    const addInflationRow: JSX.Element = (
      <div
        key={(options.length ?? 0) + 1}
        className="py-2 px-2 text-bobo-prop dark:text-white w-full border-t border-gray-200 dark:border-dark-faded transition-colors duration-200 hover:bg-bobo-proplight dark:hover:bg-dark-prophover hover:text-bobo-prophover text-xs">
        <button
          onClick={() => goToInflationModal && goToInflationModal(undefined, indexType)}
          className="flex gap-2 items-center justify-center w-full h-full">
          <PlusIcon className="w-5 h-5" />
          <div>{t('property.cashflow-valuation.inflation.add-new', { context: indexType })}</div>
        </button>
      </div>
    );

    return [...inflations, addInflationRow];
  };

  const renderInflationDropdowns = (
    indexes: { options: CashflowInflation[]; selected: string; indexType: IndexType }[]
  ) =>
    indexes.map((index, idx) => (
      <div key={idx} className="flex gap-2 text-xs items-center">
        <div>
          {t('property.cashflow-valuation.assumptions.inflation', {
            context: index.indexType
          })}
          :
        </div>
        <DropdownMenu
          title={
            index.selected
              ? index.options.find((i) => i.uuid === index.selected)?.name
              : t('property.cashflow-valuation.lease.choose-inflation', {
                  context: index.indexType
                })
          }
          renderMenuItems={(item) => renderIndexOptions(item, index.options, index.indexType)}
          className="h-[24px] !w-[270px] !text-xs !z-30"
          buttonClassName="!py-0.5 !text-gray-400"
        />
      </div>
    ));

  return (
    <div className="w-full flex justify-between items-center p-4 bg-white dark:!bg-dark-lessfaded z-50 border-x border-t border-gray-100 dark:border-dark-morefaded">
      <div className="flex flex-col items-start gap-2">
        <div className="w-full flex gap-4 justify-start">
          <div className="flex gap-2 text-xs items-center relative">
            <div>{t('value-calculator.prop-type')}</div>

            <div className="w-[150px]">
              <DropdownMenu
                title={
                  selectedCategory === 'other'
                    ? t('value-calculator.prop-type-choose')
                    : t(getCategoryName(selectedCategory))
                }
                renderMenuItems={renderPropTypes}
                className="h-[24px] !w-[150px] !z-40"
                buttonClassName="!py-0.5 !text-gray-400"
              />
            </div>
          </div>

          <div className="flex gap-2 text-xs items-center">
            <div>{t('value-calculator.area')}</div>

            <AreaInputDropdown
              changeArea={changeArea}
              area={area}
              selectedTab={selectedTab}
              property={property}
              errorState={errorState}
            />
          </div>

          <div className="flex gap-2 text-xs items-center">
            <div>{t('general.name')}:</div>

            <div className="text-gray-400 flex items-center overflow-hidden dark:border-dark-morefaded dark:hover:border-dark-prop dark:bg-dark-primary justify-between gap-1 leading-3 rounded-sm border border-gray-200 hover:border-bobo-prop transition-colors duration-200 px-2 py-0.5 w-[150px]">
              <input
                type={'text'}
                value={tabName}
                autoComplete="off"
                placeholder={
                  errorState.name
                    ? t(errorState.name, { field: t('general.name') })
                    : t(getCategoryName(selectedTab)).length > 0
                      ? t(getCategoryName(selectedTab))
                      : selectedTab
                }
                onChange={({ target }) => {
                  if (cashflow) {
                    changeTabName(selectedTab, target.value);
                  } else {
                    setTabName(target.value);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && tabName.length >= 3) {
                    changeTabName(selectedTab, tabName);
                  }
                }}
                className={cls(
                  'p-0 m-0 text-xs text-gray-400 placeholder:text-gray-300 dark:placeholder:text-gray-500 placeholder:italic dark:bg-dark-primary border-none',
                  { 'placeholder:text-bobo-bricksred': errorState.name }
                )}
              />
            </div>

            {!cashflow && (
              <button
                disabled={tabName.length < 3}
                onClick={() => {
                  changeTabName(selectedTab, tabName);
                }}
                className={cls(
                  'border rounded-full px-3 py-1 dark:border-dark-morefaded dark:bg-dark-lessfaded disabled:text-gray-300 transition-colors duration-200 bg-white',
                  {
                    'cursor-pointer hover:bg-bobo-orangelight dark:hover:bg-dark-prophover':
                      tabName.length >= 3
                  }
                )}>
                {t(`general.save`)}
              </button>
            )}
          </div>
        </div>

        {rentIndex && costIndex ? (
          <div className="flex flex-row gap-4 items-start">
            {renderInflationDropdowns([
              { ...rentIndex, indexType: IndexType.RENT },
              { ...costIndex, indexType: IndexType.COST }
            ])}
          </div>
        ) : null}
      </div>

      {cashflow && (
        <div>
          <Button onClick={handleSave}>{t('general.save')}</Button>
        </div>
      )}

      {!cashflow ? (
        <DeleteTabBtn tabName={selectedTab} deleteTab={deleteTab} inSummary={false} />
      ) : null}
    </div>
  );
}
