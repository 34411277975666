/* Components & Widgets */
import { useUser } from '@auth0/nextjs-auth0';
import cls from 'classnames';
import TabMenu from 'src/layout/menu/tab-menu';

import { useBreakpoint } from '../hooks/use-breakpoint';

export default function ContentContainer({
  children,
  title,
  titleRight,
  switchUrl,
  tabsRight,
  image,
  contentContainerClasses,
  mapOnly = false,
  section,
  mobileTabDropdown = true,
  backUrl = null,
  hideTabMenu = false,
  reduceSecondaryRow,
  subMenu,
  object
}: {
  children: JSX.Element | JSX.Element[];
  title: string;
  image: string;
  bgClass?: string;
  contentContainerClasses?: string;
  switchUrl?: string;
  section?: string | JSX.Element;
  header: boolean;
  mapOnly?: boolean;
  titleRight?: JSX.Element | JSX.Element[] | string;
  tabsRight?: JSX.Element | JSX.Element[] | null;
  mobileTabDropdown?: boolean;
  backUrl?: string | null;
  hideTabMenu?: boolean;
  reduceSecondaryRow: boolean;
  subMenu?: JSX.Element | null;
  object?: any;
}): JSX.Element {
  const { user } = useUser();

  const { isLg } = useBreakpoint({ breakpoint: 'lg' });
  const isMobileOrTabletPortrait = !isLg;

  return (
    <>
      {(title || section) && !isMobileOrTabletPortrait && (
        <TabMenu
          reduceSecondaryRow={reduceSecondaryRow}
          title={title}
          image={image}
          section={section}
          titleRight={titleRight}
          switchUrl={switchUrl}
          tabsRight={tabsRight}
          mobileDropdown={mobileTabDropdown}
          backUrl={backUrl}
          hideTabMenu={hideTabMenu}
          subMenu={subMenu}
          object={object}
        />
      )}
      <div
        className={cls(
          'w-full flex-grow overflow-y-auto bg-white dark:bg-dark-primary',
          contentContainerClasses
        )}>
        {(title || section) && isMobileOrTabletPortrait && (
          <TabMenu
            reduceSecondaryRow={reduceSecondaryRow}
            title={title}
            image={image}
            section={section}
            titleRight={titleRight}
            switchUrl={switchUrl}
            tabsRight={tabsRight}
            mobileDropdown={mobileTabDropdown}
            backUrl={backUrl}
            hideTabMenu={hideTabMenu}
            subMenu={subMenu}
            object={object}
          />
        )}
        <div
          className={cls(
            `${
              isMobileOrTabletPortrait
                ? 'h-[calc(100dvh-220px)] md:h-[calc(100dvh-163px)]'
                : 'h-[100dvh]'
            }`,
            {
              'ml-[0px] md:-ml-[1px] lg:ml-[0px]': user && mapOnly
            },
            { 'w-full h-full': !user },
            {
              'm-auto w-full bg-white dark:bg-dark-primary': user && !mapOnly
            },
            {
              '': !mapOnly
            }
          )}>
          {children}
        </div>
      </div>
    </>
  );
}
