import { Popover, Transition } from '@headlessui/react';
import { Switch } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import cls from 'classnames';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TaxTypes, ValueCategoryTypes } from 'shared/dist/types';
import { PropertyVM } from 'shared/dist/types/property2';
import { NewsecCategory } from 'shared/dist/types/rent';

import { useOutsideAlerter } from '@/src/hooks/use-outside-alerter';
import InfoButton from '@/src/widgets/info-button';
import { FLCustom } from '@/src/widgets/numbers';

import { handleMaxInputLimit } from '../cashflow-valuation/constants/calculator-functions';
import { MAX_LIMIT_TAX_VALUE_INPUT } from '../cashflow-valuation/constants/general';
import { TaxDescription } from '../cashflow-valuation/items/assumptions-calculations';
import { CalculatorRow } from './items/calculator-row';
import { headerCellStyle, rowCellStyle, taxCategories, valueCategories } from './items/constants';
import { calculatePropertyTax, calculatePropertyValue } from './items/functions';
import TabHeader from './items/tab-header';

export function CalculationTab({
  defaultValues,
  totalResult,
  setTotalResult,
  changeValue,
  changeArea,
  changePropertyTax,
  selectedTab,
  setNewCategory,
  deleteTab,
  changeTabName,
  property
}: {
  defaultValues: any;
  totalResult: any;
  setTotalResult: any;
  changeValue: any;
  changeArea: any;
  changePropertyTax: any;
  selectedTab: string;
  setNewCategory: any;
  deleteTab: any;
  changeTabName: any;
  property: PropertyVM;
}) {
  const [result, setResult] = useState<any>({
    [ValueCategoryTypes.YIELD]: 0,
    [ValueCategoryTypes.RENT]: 0,
    [ValueCategoryTypes.COSTS]: 0,
    [ValueCategoryTypes.MAINTENANCE]: 0
  });
  const [values, setValues] = useState<any | null>(null);
  const [area, setArea] = useState<number>(0);
  const [tax, setTax] = useState<any>(
    taxCategories?.find((cat) => cat?.title === TaxTypes.HOUSINGOLD)
  );
  const [valueYear, setValueYear] = useState<number>(0);
  const [taxValue, setTaxValue] = useState<number>(0);

  const [showTaxTypes, setShowTaxTypes] = useState<boolean>(false);
  const [countPropertyTax, setCountPropertyTax] = useState<boolean>(
    totalResult?.find((tab) => tab.tabName === selectedTab)?.countPropertyTax ?? false
  );
  const [propType, setPropType] = useState<NewsecCategory | 'other'>(
    totalResult?.find((tab) => tab.tabName === selectedTab)?.propType ?? 'other'
  );
  const { t } = useTranslation();
  const showTaxTypesRef = useRef(null);
  const apartmentCount = property?.apartmentCount ?? 0;

  useOutsideAlerter(showTaxTypesRef, setShowTaxTypes);

  useEffect(() => {
    const tmpValues: any = totalResult?.find((tab) => tab.tabName === selectedTab)?.values;
    const tmpValueYear: number = totalResult?.find((tab) => tab.tabName === selectedTab)?.valueYear;
    const tmpTax: any = totalResult?.find((tab) => tab.tabName === selectedTab)?.propertyTaxType;
    const tmpTaxValue: number =
      totalResult?.find((tab) => tab.tabName === selectedTab)?.taxValue ?? 0;
    const tmpPropType =
      totalResult?.find((tab) => tab.tabName === selectedTab)?.propType ?? 'other';

    if (propType !== tmpPropType) {
      setPropType(tmpPropType);
    }

    if (valueYear !== tmpValueYear) {
      setValueYear(tmpValueYear);
    }
    if (tax !== tmpTax) {
      setTax(tmpTax);
    }
    if (taxValue !== tmpTaxValue) {
      setTaxValue(tmpTaxValue);
    }
    setValues(tmpValues);

    if (totalResult?.find((tab) => tab.tabName === selectedTab)?.result) {
      setResult(totalResult?.find((tab) => tab.tabName === selectedTab)?.result);
    }

    if (
      totalResult?.find((tab) => tab.tabName === selectedTab)?.countPropertyTax !== countPropertyTax
    ) {
      setCountPropertyTax(
        totalResult?.find((tab) => tab.tabName === selectedTab)?.countPropertyTax
      );
    }
  }, [selectedTab, totalResult]);

  useEffect(() => {
    if (
      totalResult?.find((tab) => tab.tabName === selectedTab)?.countPropertyTax !== countPropertyTax
    ) {
      const newTotalResult = totalResult?.map((tab) => {
        return tab.tabName === selectedTab ? { ...tab, countPropertyTax: countPropertyTax } : tab;
      });
      setTotalResult(newTotalResult);
    }
  }, [countPropertyTax]);

  return (
    <>
      <TabHeader
        totalResult={totalResult}
        selectedTab={selectedTab}
        deleteTab={deleteTab}
        changeArea={changeArea}
        changeTabName={changeTabName}
        setNewCategory={setNewCategory}
        selectedCategory={propType}
        area={area}
        setArea={setArea}
        property={property}
      />

      <div className="flex items-stretch justify-between border-x border-gray-100 w-full overflow-hidden text-xs bg-gray-100 dark:bg-dark-lessfaded dark:border-dark-morefaded">
        <div className={`${headerCellStyle} w-1/6`}></div>
        <div className={`${headerCellStyle} w-3/6`}>{t('value-calculator.levels')}</div>
        <div className={`${headerCellStyle} w-1/6 relative`}>
          {t('value-calculator.matrixes')}
          <div className="absolute right-2">
            <InfoButton helpTextId="marketDataNewsec" />
          </div>
        </div>
        <div className={`${headerCellStyle} w-1/6`}>{t('general.sum')}</div>
      </div>

      {values &&
        values
          ?.filter(
            (cat) =>
              cat.category === ValueCategoryTypes.VACANCY ||
              cat.category === ValueCategoryTypes.RENT
          )
          ?.map((cat, idx) => {
            return (
              <CalculatorRow
                key={idx}
                valueCategory={valueCategories[cat.category]}
                changeValue={changeValue}
                selectedTab={selectedTab}
                tabValues={cat}
                defaultValues={cat.category === ValueCategoryTypes.COSTS ? defaultValues : null}
                result={cat.subcost ? null : result}
                costValues={
                  cat.category === ValueCategoryTypes.COSTS
                    ? values.filter((category) => category.subcost)
                    : []
                }
                defaultRowValues={defaultValues[cat.category]}
                area={area}
              />
            );
          })}

      <div className="flex items-stretch justify-between border-l border-gray-100 dark:border-dark-morefaded">
        <div className={`${rowCellStyle} !font-medium w-1/6 !bg-gray-50 dark:!bg-dark-faded`}>
          {t(ValueCategoryTypes.RENT)}
          {t('value-calculator.net')}
        </div>
        <div className={`${rowCellStyle} !font-medium w-4/6 !bg-gray-50 dark:!bg-dark-faded`} />
        <div className={`${rowCellStyle} !font-medium w-1/6 !bg-gray-50 dark:!bg-dark-faded`}>
          <FLCustom
            value={
              Math.round(
                (result[ValueCategoryTypes.RENT] *
                  ((100 - result[ValueCategoryTypes.VACANCY]) / 100)) /
                  1000
              ) ?? 0
            }
            suffix={t('general.thousand-suffix')}
            prefix={t('general.sek-prefix')}
          />
        </div>
      </div>

      {values &&
        values
          ?.filter(
            (cat) =>
              !valueCategories[cat.category].subcost &&
              cat.category !== ValueCategoryTypes.YIELD &&
              cat.category !== ValueCategoryTypes.VACANCY &&
              cat.category !== ValueCategoryTypes.RENT
          )
          ?.map((cat, idx) => {
            return (
              <CalculatorRow
                key={idx}
                valueCategory={valueCategories[cat.category]}
                changeValue={changeValue}
                selectedTab={selectedTab}
                tabValues={cat}
                defaultValues={cat.category === ValueCategoryTypes.COSTS ? defaultValues : null}
                result={valueCategories[cat.category].subcost ? null : result}
                costValues={
                  cat.category === ValueCategoryTypes.COSTS
                    ? values.filter((category) => category.subcost)
                    : []
                }
                defaultRowValues={defaultValues[cat.category]}
                area={area}
              />
            );
          })}

      <div className="flex items-stretch justify-between border-l border-gray-100 dark:border-dark-morefaded">
        <div className={`${rowCellStyle} !font-medium w-1/6 relative`}>
          <div>{t('value-calculator.prop-tax')}</div>
          <Switch
            checked={countPropertyTax}
            onChange={setCountPropertyTax}
            className={`${
              countPropertyTax
                ? 'bg-bobo-prop dark:bg-dark-prop'
                : 'bg-gray-300 dark:bg-dark-morefaded'
            }
                relative mt-1 inline-flex h-[20px] w-[40px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}>
            <span className="sr-only">{countPropertyTax}</span>

            <span
              aria-hidden="true"
              className={`${countPropertyTax ? 'translate-x-5' : 'translate-x-0'}
                  pointer-events-none inline-block h-[17px] w-[17px] transform rounded-full bg-white dark:bg-dark-primary shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>

          <div className="absolute right-2 top-2">
            <InfoButton helpTextId="valuationToolTax" />
          </div>
        </div>
        <div className={`${rowCellStyle} !font-medium w-3/6`}>
          <TaxDescription
            tax={tax}
            apartmentCount={apartmentCount}
            countPropertyTax={countPropertyTax}
          />
        </div>
        <div className={`${rowCellStyle} !font-medium w-1/6 gap-3 p-2 h-[120px]`}>
          <div className="flex flex-col text-xs font-normal items-start justify-between w-full relative">
            <div
              className={cls('duration-200 transition-colors', {
                'text-gray-400': !countPropertyTax
              })}>
              {t('value-calculator.prop-type')}
            </div>

            <Popover className="relative">
              <Popover.Button
                onClick={() => setShowTaxTypes(!showTaxTypes)}
                className={cls(
                  'text-gray-400 h-[24px] dark:bg-dark-primary font-normal overflow-hidden flex items-center justify-between gap-1 leading-3 rounded-t-md border-x border-t border-gray-100 dark:border-dark-morefaded transition-colors duration-200 px-2 py-0.5 w-[150px]',
                  {
                    'hover:border-bobo-prop dark:hover:border-dark-prop border-b rounded-b-md':
                      !showTaxTypes
                  }
                )}>
                <div>{t(tax?.title)}</div>
                <ChevronRightIcon className="text-bobo-prop dark:text-dark-prop w-3 h-3" />
              </Popover.Button>

              <Transition
                as={Fragment}
                show={showTaxTypes}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 -translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 -translate-y-1">
                <Popover.Panel
                  ref={showTaxTypesRef}
                  className="absolute transform translate-x-0 z-50">
                  <div className="bg-white dark:text-white dark:bg-dark-primary text-bobo-black border-x border-b border-gray-100 dark:border-dark-morefaded rounded-b-md overflow-hidden shadow-sm flex flex-col pt-1 w-[150px]">
                    {taxCategories?.map((taxType, idx) => {
                      return (
                        <div
                          key={idx}
                          className={cls(
                            'py-3 px-5 transition-colors duration-200 hover:bg-bobo-proplight dark:hover:bg-dark-prophover text-bobo-prop dark:text-white hover:text-bobo-prophover text-xs',
                            {
                              '!bg-bobo-prop dark:!bg-dark-prop !text-white': taxType === tax
                            }
                          )}>
                          <button
                            onClick={() => {
                              changePropertyTax(selectedTab, taxType, taxValue);
                              setShowTaxTypes(!showTaxTypes);
                            }}
                            className="disabled:text-gray-300 w-full h-full">
                            {t(taxType.title)}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
          </div>

          <div className="flex flex-col text-xs font-normal items-start justify-between w-full">
            <div
              className={cls('duration-200 transition-colors', {
                'text-gray-400': !countPropertyTax
              })}>
              {t('value-calculator.tax-value')}
            </div>

            <div className="text-gray-400 flex items-center justify-between gap-1 leading-3 rounded-md border border-gray-100 dark:hover:border-dark-prop dark:bg-dark-primary dark:border-dark-morefaded hover:border-bobo-prop transition-colors duration-200 px-2 py-0.5 w-[150px]">
              <div className="flex items-center">
                {t('general.sek-prefix').length > 0 ? (
                  <div className="mr-1">{t('general.sek-prefix')}</div>
                ) : null}

                <input
                  type={'number'}
                  value={taxValue}
                  onChange={({ target }) => {
                    changePropertyTax(selectedTab, tax, +target.value);
                  }}
                  onInput={(e) => handleMaxInputLimit(e, MAX_LIMIT_TAX_VALUE_INPUT)}
                  className="border-none p-0 m-0 text-xs text-gray-400 dark:bg-dark-primary w-full"
                />
              </div>

              <div>{t('general.thousand-suffix')}</div>
            </div>
          </div>
        </div>
        <div
          className={cls(`${rowCellStyle} !font-medium w-1/6 duration-200 transition-colors`, {
            'text-gray-400': !countPropertyTax
          })}>
          <div className="text-gray-400">{t('value-calculator.prop-tax-sqm')}</div>
          <FLCustom
            value={
              area > 0 ? Math.round(-calculatePropertyTax(tax, taxValue, apartmentCount) / area) : 0
            }
            suffix={t('general.kr-per-square-m-suffix')}
          />
          <div className="mt-2 text-gray-400">{t('value-calculator.prop-tax-total')}</div>
          <FLCustom
            value={-Math.round(calculatePropertyTax(tax, taxValue, apartmentCount) / 1000)}
            suffix={t('general.thousand-suffix')}
            prefix={t('general.sek-prefix')}
          />
        </div>
      </div>

      <div className="flex items-stretch justify-between border-l border-gray-100 dark:border-dark-morefaded">
        <div className={`${rowCellStyle} !font-medium w-1/6 !bg-gray-50 dark:!bg-dark-faded`}>
          {t('value-calculator.operation-net')}
        </div>
        <div className={`${rowCellStyle} !font-medium w-4/6 !bg-gray-50 dark:!bg-dark-faded`}></div>
        <div className={`${rowCellStyle} !font-medium w-1/6 !bg-gray-50 dark:!bg-dark-faded`}>
          <div className="text-gray-400">{t('value-calculator.operation-net-sqm')}</div>
          <FLCustom
            value={
              area === 0
                ? 0
                : Math.round(
                    (result[ValueCategoryTypes.RENT] *
                      ((100 - result[ValueCategoryTypes.VACANCY]) / 100) -
                      (result[ValueCategoryTypes.MAINTENANCE] + result[ValueCategoryTypes.COSTS]) -
                      (countPropertyTax
                        ? calculatePropertyTax(tax, taxValue, apartmentCount)
                        : 0)) /
                      area
                  )
            }
            suffix={t('general.kr-per-square-m-suffix')}
          />
          <div className="mt-2 text-gray-400">{t('value-calculator.operation-net-total')}</div>
          <FLCustom
            value={
              area === 0
                ? 0
                : Math.round(
                    (result[ValueCategoryTypes.RENT] *
                      ((100 - result[ValueCategoryTypes.VACANCY]) / 100) -
                      (result[ValueCategoryTypes.MAINTENANCE] + result[ValueCategoryTypes.COSTS]) -
                      (countPropertyTax
                        ? calculatePropertyTax(tax, taxValue, apartmentCount)
                        : 0)) /
                      1000
                  )
            }
            suffix={t('general.thousand-suffix')}
            prefix={t('general.sek-prefix')}
          />
        </div>
      </div>

      {values ? (
        <CalculatorRow
          valueCategory={valueCategories[ValueCategoryTypes.YIELD]}
          changeValue={changeValue}
          selectedTab={selectedTab}
          tabValues={values.find((cat) => cat.category === ValueCategoryTypes.YIELD)}
          result={result}
          costValues={[]}
          defaultRowValues={defaultValues[ValueCategoryTypes.YIELD]}
          area={area}
        />
      ) : null}

      <div className="flex items-stretch justify-between border-l border-gray-100 dark:border-dark-morefaded">
        <div className={`${rowCellStyle} !font-medium w-1/6 !bg-gray-50 dark:!bg-dark-faded`}>
          {t('general.result')}
        </div>
        <div className={`${rowCellStyle} !font-medium w-4/6 !bg-gray-50 dark:!bg-dark-faded`}></div>
        <div className={`${rowCellStyle} !font-medium w-1/6 !bg-gray-50 dark:!bg-dark-faded`}>
          <div className="text-gray-400">{t('value-calculator.value-sqm')}</div>
          <FLCustom
            value={
              area === 0
                ? 0
                : Math.round(
                    calculatePropertyValue(
                      result[ValueCategoryTypes.YIELD],
                      result[ValueCategoryTypes.RENT] *
                        ((100 - result[ValueCategoryTypes.VACANCY]) / 100),
                      result[ValueCategoryTypes.MAINTENANCE] +
                        result[ValueCategoryTypes.COSTS] +
                        (countPropertyTax ? calculatePropertyTax(tax, taxValue, apartmentCount) : 0)
                    ) / area
                  )
            }
            suffix={t('general.kr-per-square-m-suffix')}
          />
          <div className="mt-2 text-gray-400">{t('value-calculator.value-total')}</div>
          <FLCustom
            value={
              area === 0
                ? 0
                : Math.round(
                    calculatePropertyValue(
                      result[ValueCategoryTypes.YIELD],
                      result[ValueCategoryTypes.RENT] *
                        ((100 - result[ValueCategoryTypes.VACANCY]) / 100),
                      result[ValueCategoryTypes.MAINTENANCE] +
                        result[ValueCategoryTypes.COSTS] +
                        (countPropertyTax ? calculatePropertyTax(tax, taxValue, apartmentCount) : 0)
                    ) / 1000
                  )
            }
            suffix={t('general.thousand-suffix')}
            prefix={t('general.sek-prefix')}
          />
        </div>
      </div>
    </>
  );
}
