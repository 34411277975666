import { Switch } from '@headlessui/react';

export default function LoggenSwitch({
  value,
  setValue,
  label
}: {
  value: any;
  setValue: any;
  label: string | JSX.Element;
}) {
  return (
    <div className="py-1 flex justify-between space-x-2 items-center">
      <div>
        <div className="text-xs text-gray-500 dark:text-gray-300">{label}</div>
      </div>

      <Switch
        checked={value}
        onChange={setValue}
        className={`${value ? 'bg-bobo-prop dark:bg-dark-prop' : 'bg-gray-300 dark:bg-dark-primary'}
          relative inline-flex h-[21px] w-[40px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}>
        <span className="sr-only">{label}</span>

        <span
          aria-hidden="true"
          className={`${value ? 'translate-x-5' : 'translate-x-0'}
            pointer-events-none inline-block h-[17px] w-[17px] transform rounded-full bg-white dark:bg-dark-morefaded shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </Switch>
    </div>
  );
}
