import Image from 'next/image';

export default function Info({ object }: { object: any; close: any }) {
  return (
    <div className="flex z-30 h-full flex-col overflow-y-auto">
      {/* Main */}
      <div>
        <div className="pb-1 sm:pb-6">
          <div>
            <div className="relative flex justify-center border dark:border-none overflow-hidden bg-gray-50 dark:bg-dark-lessfaded pt-4 text-center">
              <Image
                src={`/images/illustrations/not-found.svg`}
                alt="Help Text"
                height={85}
                width={200}
                className="text-center"
              />
            </div>

            <div className="mt-4">
              <div className="px-6 py-4 text-sm text-gray-800">
                <div className="text-base font-bold text-gray-900 dark:text-white sm:text-lg uppercase !leading-tight">
                  {object?.title}
                </div>

                <div className="text-sm text-gray-700 mt-1 whitespace-pre-line dark:text-gray-200">
                  {object?.desc}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
