import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import toast from 'react-hot-toast';
import { getCategoryName } from 'shared/dist/constants';
import { ButtonType } from 'shared/dist/types';
import {
  CashflowInvestment,
  CashflowValuationAsset,
  CashflowValuationModel,
  InvestmentParent,
  InvestmentParentType
} from 'shared/dist/types/cashflow/cashflow-model';
import { v4 as uuidv4 } from 'uuid';
import * as z from 'zod';

import DropdownMenu from '@/src/layout/components/dropdown-menu';
import { getValidLocale } from '@/src/lib/constants';
import Button from '@/src/widgets/buttons';

import { DeleteType } from '../../cashflow-valuation/cashflow-dialog-switcher';
import { handleMaxInputLimit } from '../../cashflow-valuation/constants/calculator-functions';
import { cashflowTheme } from '../../cashflow-valuation/constants/cashflow-theme';
import {
  MAX_LIMIT_INVESTMENT_AMOUNT_INPUT,
  MAX_LIMIT_INVESTMENT_MONTHS_INPUT,
  MAX_LIMIT_INVESTMENT_SAVINGS_INPUT
} from '../../cashflow-valuation/constants/general';
import CashflowDeleteModel from './cashflow-delete-model';

// Zod schema for lease validation
const investmentSchema = z.object({
  name: z.string().min(1, 'property.cashflow-valuation.lease.input-errors.string'),
  startDate: z.number(),
  amount: z.number().min(0, 'property.cashflow-valuation.lease.input-errors.number'),
  duration: z.number().min(0, 'property.cashflow-valuation.lease.input-errors.number'),
  saving: z.number().min(0, 'property.cashflow-valuation.lease.input-errors.number')
});

// Type for form inputs
interface InvestmentInput {
  name: string;
  uuid: string;
  amount: number;
  startDate: number;
  duration: number;
  saving: number;
  parent: InvestmentParent;
}

const stepNames = {
  initialAdd: 1,
  deleteInvestment: 2
};

const validateInvestmentInput = (
  InvestmentInput: InvestmentInput
): { valid: boolean; errors: Record<string, string> } => {
  const result = investmentSchema.safeParse(InvestmentInput);

  if (result.success) {
    return { valid: true, errors: {} };
  }

  const errors: Record<string, string> = {};
  result.error.errors.forEach((error) => {
    const path = error.path.join('.');
    errors[path] = error.message;
  });

  return { valid: false, errors };
};

export default function CashflowInvestmentTemplate({
  object,
  close,
  setReload,
  setSelectedModel
}: {
  object: {
    object: any;
    model: CashflowValuationModel;
    assets: CashflowValuationAsset[];
    investment: CashflowInvestment | undefined;
  };
  setSelectedModel: (arg0?: CashflowValuationModel) => void;
  close: any;
  setReload: (arg: boolean) => void;
}) {
  const { t } = useTranslation();
  const { investment, model, assets } = object;
  const [step, setStep] = useState<number>(stepNames.initialAdd);
  const currentYear = +moment().format('YYYY');
  const router = useRouter();
  const locale: string = getValidLocale(router);

  const [selectedInvestment, setSelectedInvestment] = useState<InvestmentInput | null>(null);

  const [loading, setLoading] = useState(false);

  const [errorState, setErrorState] = useState<any>({});

  const initialInvestmentParent: InvestmentParent = {
    type: InvestmentParentType.MODEL,
    uuid: model.uuid,
    name: model.name
  };

  useEffect(() => {
    if (investment) {
      const investmentInput: InvestmentInput = {
        name: investment.name,
        uuid: investment.uuid,
        amount: investment.amount,
        startDate: investment.startDate,
        duration: investment.duration,
        saving: investment.saving,
        parent: investment.parent
      };
      setSelectedInvestment(investmentInput);
      return;
    }

    const newInvestment: InvestmentInput = {
      name: t('property.cashflow-valuation.investments.new'),
      uuid: uuidv4(),
      amount: 0,
      startDate: moment(currentYear + '-01-01')
        .startOf('day')
        .unix(),
      duration: 6,
      saving: 0,
      parent: initialInvestmentParent
    };
    setSelectedInvestment(newInvestment);
  }, []);

  const getUpdatedModelForAssetInvestment = (validatedInvestment: CashflowInvestment) => {
    const selectedAsset = model?.assets.find(
      (asset) => asset.uuid === validatedInvestment.parent.uuid
    );
    const otherAssets = assets.filter((a) => a.uuid !== validatedInvestment.parent.uuid);

    if (!selectedAsset) {
      return;
    }

    const updatedInvestments: CashflowInvestment[] = investment
      ? selectedAsset.adjustableAssumptions.investments.map((i) =>
          i.uuid === investment.uuid ? validatedInvestment : i
        )
      : [...selectedAsset.adjustableAssumptions.investments, validatedInvestment];

    const updatedAssets = [
      ...otherAssets,
      {
        ...selectedAsset,
        adjustableAssumptions: {
          ...selectedAsset.adjustableAssumptions,
          investments: updatedInvestments
        }
      }
    ];
    return {
      ...model,
      assets: updatedAssets
    };
  };

  const getUpdatedModelForModelInvestment = (validatedInvestment: CashflowInvestment) => {
    const updatedInvestments: CashflowInvestment[] = investment
      ? model.investments.map((i) => (i.uuid === investment.uuid ? validatedInvestment : i))
      : [...model.investments, validatedInvestment];

    return {
      ...model,
      investments: updatedInvestments
    };
  };

  const validateAndSave = async () => {
    if (!selectedInvestment) {
      return;
    }

    const { valid, errors } = validateInvestmentInput(selectedInvestment);

    if (!valid) {
      setErrorState(errors);
      return;
    }

    setErrorState({});

    const validatedInvestment: CashflowInvestment = {
      ...selectedInvestment
    };

    const parentType: InvestmentParentType = validatedInvestment.parent.type;

    const updatedModel =
      parentType === InvestmentParentType.ASSET
        ? getUpdatedModelForAssetInvestment(validatedInvestment)
        : getUpdatedModelForModelInvestment(validatedInvestment);

    setSelectedModel(updatedModel);
    toast.success(
      t('property.cashflow-valuation.investments.saved', { name: validatedInvestment.name })
    );
    setLoading(false);
    close();
  };

  const renderAssetOptions = (closeMenu) =>
    [...assets, initialInvestmentParent].map((i, idx) => {
      const hasModelParent: boolean = JSON.stringify(i) === JSON.stringify(initialInvestmentParent);
      const newInvestmentParent: InvestmentParent = hasModelParent
        ? (i as InvestmentParent)
        : {
            type: InvestmentParentType.ASSET,
            name: i.name,
            uuid: i.uuid
          };

      return (
        <div
          key={idx}
          className="py-2 px-2 transition-colors duration-200 hover:bg-bobo-proplight dark:hover:bg-dark-prophover text-bobo-prop dark:text-white hover:text-bobo-prophover w-full">
          <button
            onClick={() => {
              if (!selectedInvestment) {
                return;
              }
              setSelectedInvestment({
                ...selectedInvestment,
                parent: newInvestmentParent
              });
              closeMenu();
            }}
            className="disabled:text-gray-300 w-full h-full text-start">
            {hasModelParent
              ? t('property.cashflow-valuation.investments.whole-property-parent')
              : t(getCategoryName(i.name)) || i.name}
          </button>
        </div>
      );
    });

  let mod: JSX.Element = <div />;

  if (selectedInvestment) {
    switch (step) {
      case stepNames.initialAdd:
        mod = (
          <div className="z-30 max-h-[calc(100vh-100px)] overflow-auto flex flex-col gap-8">
            <div>
              <h3 className="text-base font-bold text-gray-900 dark:text-white sm:text-lg uppercase">
                {t(`property.cashflow-valuation.investments.${investment ? 'modify' : 'add-new'}`)}
              </h3>
            </div>

            <div className="flex flex-col justify-center gap-4 text-xs">
              <div className="flex gap-2 items-center">
                <label htmlFor="namn" className="font-demi w-[100px] text-right">
                  {t('property.cashflow-valuation.credit.issuer')}
                </label>
                <div className={cashflowTheme.inputContainer}>
                  <input
                    id="namn"
                    type="text"
                    autoComplete="off"
                    value={
                      t(getCategoryName(selectedInvestment.name ?? '')) ||
                      selectedInvestment.name ||
                      ''
                    }
                    placeholder={
                      errorState.name
                        ? t(errorState.string, { field: t('general.name') })
                        : t('property.cashflow-valuation.credit.issuer-placeholder')
                    }
                    onChange={({ target }) => {
                      setSelectedInvestment({ ...selectedInvestment, name: target.value });
                    }}
                    className={cashflowTheme.inputField}
                  />
                </div>
              </div>

              <div className="flex gap-2 items-center">
                <label htmlFor={'property'} className="font-demi w-[100px] text-right">
                  {t('property.cashflow-valuation.assumptions.header')}:
                </label>

                <DropdownMenu
                  title={
                    errorState.asset ? (
                      <span className="text-bobo-bricksred whitespace-nowrap">
                        {t(errorState.asset, {
                          field: t('property.cashflow-valuation.assumptions.header')
                        })}
                      </span>
                    ) : selectedInvestment.parent.type === InvestmentParentType.ASSET ? (
                      t(
                        getCategoryName(selectedInvestment.parent.name) ||
                          selectedInvestment.parent.name
                      )
                    ) : (
                      t('property.cashflow-valuation.investments.whole-property-parent')
                    )
                  }
                  renderMenuItems={renderAssetOptions}
                />
              </div>

              <div className="flex gap-2 items-center">
                <label htmlFor="investment-input" className="font-demi w-[100px] text-right">
                  {t('property.cashflow-valuation.investments.investment')}
                </label>
                <div className={cashflowTheme.inputContainer}>
                  <input
                    type="number"
                    id="investment-input"
                    value={selectedInvestment.amount || ''}
                    placeholder={
                      errorState.rent ? t(errorState.rent, { field: t('general.rent') }) : '0'
                    }
                    onChange={({ target }) => {
                      setSelectedInvestment({
                        ...selectedInvestment,
                        amount: parseFloat(target.value)
                      });
                    }}
                    onInput={(e) => handleMaxInputLimit(e, MAX_LIMIT_INVESTMENT_AMOUNT_INPUT)}
                    className={cashflowTheme.inputField}
                  />
                  <div className="whitespace-nowrap">{t('general.kr-suffix')}</div>
                </div>
              </div>

              <div className="flex gap-2 items-center">
                <label htmlFor="start-date" className="font-demi w-[100px] text-right">
                  {t('property.start-date')}:
                </label>
                <div className={cashflowTheme.inputContainer}>
                  <DatePicker
                    id="start-date"
                    selected={
                      selectedInvestment?.startDate
                        ? moment.unix(selectedInvestment.startDate).toDate()
                        : new Date()
                    }
                    onChange={(date) => {
                      setSelectedInvestment({
                        ...selectedInvestment,
                        startDate: moment(date, 'YYYY-MM').startOf('month').unix()
                      });
                    }}
                    dateFormat="yyyy-MM-dd"
                    locale={locale}
                    showMonthYearPicker
                    className={cashflowTheme.inputField}
                    wrapperClassName="!w-full"
                  />
                </div>
              </div>

              <div className="flex gap-2 items-center">
                <label htmlFor={'floating-interest'} className="font-demi w-[100px] text-right">
                  {t('property.cashflow-valuation.investments.duration')}:
                </label>
                <div className={cashflowTheme.inputContainer}>
                  <input
                    type={'number'}
                    id="duration"
                    min={0}
                    step={1}
                    placeholder={
                      errorState.indexation
                        ? t('property.cashflow-valuation.assumptions.indexation')
                        : '0'
                    }
                    value={Number(+(selectedInvestment.duration ?? 0)).toString()}
                    onChange={({ target }) => {
                      setSelectedInvestment({
                        ...selectedInvestment,
                        duration: parseInt(target.value)
                      });
                    }}
                    onInput={(e) => handleMaxInputLimit(e, MAX_LIMIT_INVESTMENT_MONTHS_INPUT)}
                    className={cashflowTheme.inputField}
                  />
                  <div>{t('general.months')}</div>
                </div>
              </div>
              <div className="flex gap-2 items-center">
                <label htmlFor="saving-input" className="font-demi w-[100px] text-right">
                  {t('property.cashflow-valuation.investments.saving')}
                </label>
                <div className={cashflowTheme.inputContainer}>
                  <input
                    type="number"
                    id="saving-input"
                    value={selectedInvestment.saving || ''}
                    placeholder={
                      errorState.rent ? t(errorState.rent, { field: t('general.rent') }) : '0'
                    }
                    onChange={({ target }) => {
                      setSelectedInvestment({
                        ...selectedInvestment,
                        saving: parseFloat(target.value)
                      });
                    }}
                    onInput={(e) => handleMaxInputLimit(e, MAX_LIMIT_INVESTMENT_SAVINGS_INPUT)}
                    className={cashflowTheme.inputField}
                  />
                  <div className="whitespace-nowrap">{t('general.kr-suffix')}</div>
                </div>
              </div>
            </div>

            <div className="flex justify-between items-end">
              <Button type={ButtonType.CANCEL} onClick={close}>
                {t('general.cancel')}
              </Button>

              <div className="flex gap-2">
                {investment && (
                  <Button
                    type={ButtonType.DELETE}
                    disabled={loading}
                    onClick={async () => {
                      setStep(2);
                    }}>
                    {t('general.delete')}
                  </Button>
                )}

                <Button disabled={loading} onClick={validateAndSave}>
                  {t('general.save')}
                </Button>
              </div>
            </div>
          </div>
        );
        break;
      case stepNames.deleteInvestment:
        mod = (
          <>
            <CashflowDeleteModel
              close={close}
              model={model}
              setSelectedModel={setSelectedModel}
              deletedObject={{
                ...(investment as CashflowInvestment),
                deleteType: DeleteType.INVESTMENT
              }}
              setReload={setReload}
              goBack={() => setStep(1)}
            />
          </>
        );
    }
  }
  return <>{mod}</>;
}
