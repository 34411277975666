import { Dialog } from '@headlessui/react';
import cls from 'classnames';
import Image from 'next/image';
import { Trans, useTranslation } from 'next-i18next';
import { useEffect, useRef, useState } from 'react';
import { Error, Scooter, Success } from 'src/animations';

import { deleteList } from '@/src/lib/actor-client-wrapper';

import { crmDialogTheme } from './dialog-theme';

const stepNames = {
  initial_empty: 1,
  initial_not_empty: 2,
  loading: 3,
  error: 4,
  done: 5
};

function ListDeleteEmptyStep1({
  close,
  object,
  deleteLocalList
}: {
  close: any;
  object: any;
  deleteLocalList?: any;
}) {
  const confirmRef: any = useRef(null);
  const { t } = useTranslation('common');

  useEffect(() => {
    if (confirmRef?.current) {
      confirmRef?.current?.focus();
    }
  }, []);

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Image
          alt="main illustration"
          src={`/images/property-management.svg`}
          height={200}
          width={200}
        />
      </div>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          <Trans i18nKey="crm.list-actions.delete-list-title" listName={object?.listName} t={t}>
            Är du säker på att du vill radera listan
            <span className="text-bobo-prop dark:text-dark-prop">
              {{ listName: object?.listName }}
            </span>
            ?
          </Trans>
        </Dialog.Title>
        <p className={crmDialogTheme.subtext}>{t('crm.list-actions.delete-list-desc')}</p>
      </div>
      <div className={crmDialogTheme.buttonContainer}>
        <button className={crmDialogTheme.buttonClose} onClick={close}>
          {t('general.cancel')}
        </button>
        <button
          className={crmDialogTheme.buttonDelete}
          onClick={() => deleteLocalList()}
          ref={confirmRef}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              deleteLocalList();
            }
          }}>
          {t('general.delete')}
        </button>
      </div>
    </div>
  );
}

function ListDeleteWithPropertiesStep1({
  close,
  object,
  deleteLocalList
}: {
  close: any;
  object: any;
  deleteLocalList: any;
}) {
  const [inputValue, setInputValue] = useState<string>('');
  const [inputTouched, setInputTouched] = useState<boolean>(false);
  const [inputValid, setInputValid] = useState<boolean>(false);
  const [inputFocusedInitially, setInputFocusedInitially] = useState<boolean>(false);
  const { t } = useTranslation('common');

  const inputRef: any = useRef(null);

  const { listName } = object;

  useEffect(() => {
    if (inputRef?.current && !inputFocusedInitially) {
      inputRef?.current?.focus();
      setInputFocusedInitially(true);
    }
  }, [inputFocusedInitially]);

  function handleSubmit() {
    setInputTouched(true);

    if (inputValid) {
      deleteLocalList();
    }
  }

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Image
          alt="main illustration"
          src={`/images/apartment-rate-increment.svg`}
          height={200}
          width={200}
        />
      </div>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          <Trans i18nKey="crm.list-actions.delete-list-title" listName={listName} t={t}>
            Är du säker på att du vill radera listan
            <span className="text-bobo-prop dark:text-dark-prop">{{ listName }}</span>?
          </Trans>
        </Dialog.Title>
        <p className={crmDialogTheme.subtextDelete}>{t('crm.list-actions.delete-list-desc')}</p>
        <div className={crmDialogTheme.inputContainerDelete}>
          <label className={crmDialogTheme.inputDeleteLabel} htmlFor={inputRef}>
            <Trans key="crm.modals.delete-prompt-input" name={listName}>
              Skriv in{' '}
              <span className="font-demi text-bobo-black dark:text-white">
                {{ name: listName }}
              </span>{' '}
              (exakt) i rutan till höger för att bekräfta raderingen.
            </Trans>
          </label>

          <input
            ref={inputRef}
            className={cls(crmDialogTheme.inputDelete, {
              'border-bobo-burgundy': !inputValid,
              'bg-red-200 focus:bg-card-graypanel': !inputValid && inputTouched
            })}
            value={inputValue}
            onPaste={() => {
              setInputTouched(true);
            }}
            onChange={(event) => {
              setInputValue(event.target.value);
              setInputValid(event.target.value?.trim() == object?.listName);
            }}
            onBlur={() => {
              setInputTouched(true);
            }}
            onKeyDown={(event) => {
              setInputTouched(true);
              if (event.key === 'Enter') {
                // pressed enter
                handleSubmit();
              }
            }}
          />
        </div>
      </div>
      <div className={crmDialogTheme.buttonContainer}>
        <button className={crmDialogTheme.buttonClose} onClick={close}>
          {t('general.cancel')}
        </button>

        <div>
          <button
            className={crmDialogTheme.buttonDelete}
            disabled={!inputTouched || !inputValid}
            onClick={() => {
              handleSubmit();
            }}>
            {t('general.delete')}
          </button>
        </div>
      </div>
    </div>
  );
}

function ListDeleteStepLoading() {
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Scooter />
      </div>

      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          {t('crm.list-actions.deleting-list')}
        </Dialog.Title>
      </div>
    </div>
  );
}

function ListDeleteStepError({ close, object }: { close: any; object: any }) {
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.icon}>
        <Error />
      </div>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.errorTitle}>
          <Trans i18nKey="errors.could-not-delete-list" listName={object?.listName}>
            Något gick fel,{' '}
            <span className={crmDialogTheme.title}>{{ listName: object?.listName }} </span>
            kunde inte raderas.
          </Trans>
        </Dialog.Title>

        <p className={crmDialogTheme.subtext}>{t('errors.try-again')}</p>
      </div>
      <div className={crmDialogTheme.buttonContainer}>
        <button
          className={crmDialogTheme.buttonClose}
          onClick={() => {
            close();
          }}>
          {t('general.cancel')}
        </button>
      </div>
    </div>
  );
}

function ListDeleteStepDone({ close, object }: { close: any; object: any }) {
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.icon}>
        <Success />
      </div>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          <Trans i18nKey="crm.list-actions.list-deleted-title" name={object?.listName}>
            Listan{' '}
            <span className="text-bobo-prop dark:text-dark-prop">{{ name: object?.listName }}</span>{' '}
            är nu raderad!
          </Trans>
        </Dialog.Title>

        <p className={crmDialogTheme.subtext}>{t('crm.list-actions.list-deleted-subtext')}</p>
      </div>
      <div className={crmDialogTheme.buttonContainer}>
        <button
          className={crmDialogTheme.buttonClose}
          onClick={() => {
            close();
          }}>
          {t('general.close')}
        </button>
      </div>
    </div>
  );
}

export default function CrmListDialogDelete({
  initStep = stepNames.initial_not_empty,
  setReload,
  object,
  close
}: {
  close: any;
  setReload: any;
  initStep?: number;
  object: any;
}) {
  const [step, setStep] = useState(initStep);

  const deleteLocalList = async () => {
    setReload(true);
    setStep(stepNames.loading);

    await deleteList(object).then((res: any) => {
      if (res.error) {
        setStep(stepNames.error);
      } else {
        setStep(stepNames.done);
      }
    });
  };

  let mod: JSX.Element = <div />;

  switch (step) {
    case stepNames.initial_empty:
      mod = (
        <ListDeleteEmptyStep1 deleteLocalList={deleteLocalList} close={close} object={object} />
      );
      break;

    case stepNames.initial_not_empty:
      mod = (
        <ListDeleteWithPropertiesStep1
          deleteLocalList={deleteLocalList}
          close={close}
          object={object}
        />
      );
      break;

    case stepNames.loading:
      mod = <ListDeleteStepLoading />;
      break;

    case stepNames.error:
      mod = <ListDeleteStepError close={close} object={object} />;
      break;

    case stepNames.done:
      mod = <ListDeleteStepDone close={close} object={object} />;
      break;
  }

  return <>{mod}</>;
}
