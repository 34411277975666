export default function selectProperty(uuid, map) {
  if (map?.getMap()?.getSource('properties')) {
    map?.getMap()?.removeFeatureState({
      source: 'properties',
      sourceLayer: 'properties'
    });
  }
  if (map?.getMap()?.getSource('buildings')) {
    map?.getMap()?.getSource('buildings') &&
      map?.getMap()?.removeFeatureState({
        source: 'buildings',
        sourceLayer: 'buildings'
      });
  }

  map?.getMap()?.getSource('properties') &&
    map?.getMap()?.setFeatureState(
      {
        source: 'properties',
        id: uuid,
        sourceLayer: 'properties'
      },
      { selected: true }
    );

  map?.getMap()?.getSource('buildings') &&
    map?.getMap()?.setFeatureState(
      {
        source: 'buildings',
        id: uuid,
        sourceLayer: 'buildings'
      },
      { selected: true }
    );
}
