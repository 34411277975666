import { AdjustmentsHorizontalIcon, ArrowUturnLeftIcon } from '@heroicons/react/20/solid';
import { PlusIcon } from '@heroicons/react/24/solid';
import cls from 'classnames';
import moment from 'moment';
import { useTranslation } from 'next-i18next';
import { useRef, useState } from 'react';
import { ButtonType } from 'shared/dist/types';

import { useDialog } from '@/src/context/dialog-context';
import { useCashflowModelContext } from '@/src/hooks/use-cashflow-context';
import { useOutsideAlerter } from '@/src/hooks/use-outside-alerter';
import DropdownMenu from '@/src/layout/components/dropdown-menu';

import { stepNames } from '../../cashflow-dialog-switcher';

export default function ChooseModel({ object, iconOnly }: { object: any; iconOnly?: boolean }) {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const showMenuRef = useRef(null);
  useOutsideAlerter(showMenuRef, setShowMenu);

  const { selectedModel, models, setSelectedModel, refresh } = useCashflowModelContext();
  const { setDialog } = useDialog();
  const { t } = useTranslation('common');

  const buttonTitle: string = t('property.cashflow-valuation.overview.change-model');

  const renderMenuItems = (closeMenu: () => void) => {
    const modelButtons = models.map((model, idx) => {
      const isSelectedModel: boolean = selectedModel?.uuid === model.uuid;

      return (
        <button
          disabled={isSelectedModel}
          key={idx}
          onClick={(e) => {
            e.stopPropagation();
            setSelectedModel(model);
            closeMenu();
          }}
          className={cls('leading-3 p-3 w-full transition-colors duration-30 text-left', {
            'bg-bobo-proplight dark:bg-dark-primary': isSelectedModel,
            'dark:hover:bg-gray-800 hover:bg-bobo-proplight': !isSelectedModel
          })}>
          <div className="w-full whitespace-nowrap text-xs text-bobo-black dark:text-white">
            {model.name}
          </div>
          <div className="w-full whitespace-nowrap text-gray-400 text-mini font-medium">
            {t('itismad.table.last-updated')}: {moment.unix(model.updatedAt).format('YYYY-MM-DD')}
          </div>
        </button>
      );
    });

    const extraButtonArray = [
      {
        title: t('property.cashflow-valuation.overview.create-model'),
        onClick: (e) => {
          e.stopPropagation();
          setDialog({
            dialogType: 'cashflow-dialog-switcher',
            object: {
              initStep: stepNames.addModel,
              refresh,
              object: object,
              setSelectedModel: setSelectedModel,
              models: models
            }
          });
          closeMenu();
        },
        icon: <PlusIcon className="w-4 h-4" />
      },
      {
        title: t('property.cashflow-valuation.overview.unselect-model'),
        onClick: (e) => {
          e.stopPropagation();
          setSelectedModel();
          closeMenu();
        },
        icon: <ArrowUturnLeftIcon className="w-3 h-3" />
      }
    ];

    const extraButtons = extraButtonArray.map((button, idx) => {
      return (
        <button
          key={modelButtons.length + idx}
          onClick={button.onClick}
          className="py-2 border-t dark:border-dark-morefaded hover:bg-bobo-proplight dark:hover:bg-gray-800 transition-colors duration-300 font-demi flex items-center justify-center gap-2 text-bobo-prop dark:text-dark-prop text-xs w-full">
          {button.icon}
          <div>{button.title}</div>
        </button>
      );
    });

    return [...modelButtons, ...extraButtons];
  };

  return (
    <DropdownMenu
      title={
        <>
          <AdjustmentsHorizontalIcon className="h-4 w-4" />

          {!iconOnly && <div>{buttonTitle}</div>}
        </>
      }
      renderMenuItems={renderMenuItems}
      tooltipText={iconOnly ? buttonTitle : undefined}
      buttonClassName={cls('flex justify-between items-center !h-[32px] gap-2', {
        'has-tooltip relative': iconOnly,
        '!w-[150px]': !iconOnly
      })}
      buttonType={ButtonType.ACTION}
    />
  );
}
