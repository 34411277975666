import { PropertyTypeGroup } from 'shared/dist/types';
import { NewsecCategory } from 'shared/dist/types/rent';

export function getColorForObjectType(objectType: number) {
  if (objectType > 100 && objectType < 200) {
    return getColorForPropertyType(PropertyTypeGroup.FARM);
  } else if (objectType > 200 && objectType < 300) {
    return getColorForPropertyType(PropertyTypeGroup.PRIVATE);
  } else if ([320, 321].includes(objectType)) {
    return getColorForPropertyType(PropertyTypeGroup.HOUSING);
  } else if (objectType > 300 && objectType < 400) {
    return getColorForPropertyType(PropertyTypeGroup.SPACE);
  } else if (objectType === 423 || objectType === 426 || objectType === 432 || objectType === 433) {
    return getColorForPropertyType(PropertyTypeGroup.WAREHOUSE);
  } else if (objectType === 498 || objectType === 499) {
    return getColorForPropertyType(PropertyTypeGroup.STREET);
  } else if (objectType > 400 && objectType < 500) {
    return getColorForPropertyType(PropertyTypeGroup.INDUSTRY);
  } else if (objectType > 500 && objectType < 600) {
    return getColorForPropertyType(PropertyTypeGroup.INDUSTRY);
  } else if (objectType > 600 && objectType < 700) {
    return getColorForPropertyType(PropertyTypeGroup.INDUSTRY);
  } else if (objectType > 700 && objectType < 800) {
    return getColorForPropertyType(PropertyTypeGroup.INDUSTRY);
  } else if (objectType >= 800) {
    return getColorForPropertyType(PropertyTypeGroup.SPECIAL);
  }
  return getColorForPropertyType(PropertyTypeGroup.STREET);
}

export function getPrimaryColorForMap(propType: PropertyTypeGroup, opacity = 1.0) {
  switch (propType) {
    case PropertyTypeGroup.HOUSING:
      return `rgba(83, 139, 231, ${opacity})`;

    case PropertyTypeGroup.SPACE:
      return `rgba(134, 40, 145, ${opacity})`;

    case PropertyTypeGroup.SPECIAL:
      return `rgba(203, 111, 108, ${opacity})`;

    case PropertyTypeGroup.WAREHOUSE:
      return `rgba(145, 133, 40, ${opacity})`;

    case PropertyTypeGroup.INDUSTRY:
      return `rgba(145, 88, 40, ${opacity})`;

    case PropertyTypeGroup.STREET:
      return `rgba(105, 151, 63, ${opacity})`;

    case PropertyTypeGroup.PRIVATE:
      return `rgba(207, 123, 67, ${opacity})`;

    case PropertyTypeGroup.FARM:
      return `rgba(40, 145, 62, ${opacity})`;
  }

  return 'rgba(40, 83, 145, ${opacity})';
}

export function getLayerColor(layerName: string) {
  //To avoid writing all 50 different layers
  if (layerName.startsWith('kust_')) {
    return '#02f2f5';
  }

  switch (layerName) {
    case 'uso_2023':
      return '#63cee3';

    case 'skred-inspire-zon1':
      return '#b3e0c3';

    case 'skred-inspire-zon2':
      return '#f9e6c3';

    case 'skred-inspire-zon3':
      return '#DC143C';

    case 'skred-finkornig-jord-lutning':
      return '#34ff93';

    case 'skred-finkornig-jord-strandnara':
      return '#ff34cb';

    case 'Sverige_temp_gt35_2024_v3':
      return '#FF9933';

    case 'RegSO_2018_v2-a7xtub':
      return '#90e363';

    case 'jordskred_raviner':
      return '#a743ea';

    case 'EBH_Potentiellt_fororenade_omraden':
      return '#fff';

    case 'NR_polygon':
    case 'NP_polygon':
    case 'NVO_polygon':
      return '#90e363';

    case 'Forbud_mot_markavvattning':
      return '#e75353';

    case 'VSO_polygon':
      return '#e69d06';

    case 'Grundvatten_Vattenforekomster_2016_1':
    case 'Kustvatten_Vattenforekomster_2016_1':
    case 'Sjoar_OvrigtVatten_2016_1':
    case 'Sjoar_Vattenforekomster_2016_1':
    case 'Vattendrag_OvrigtVatten_2016_1':
    case 'Vattendrag_Vattenforekomster_2016_1':
      return '#7da2dd';

    case 'VMI':
      return '#7da2dd';

    case 'sksBiotopskydd':
      return '#90e363';
    case 'InspireMSB_hot_risk_bhf':
    case 'InspireMSB_hot_risk_50':
    case 'InspireMSB_hot_risk_100':
    case '100arsflode':
    case '200arsflode':
    case 'BHF':
      return '#7da2dd';
    default:
      return getPrimaryColorForMap(PropertyTypeGroup.HOUSING);
  }
}

export function getColorForPropertyType(
  propType: PropertyTypeGroup | NewsecCategory,
  opacity = 0.2
) {
  let color = 'rgba(40, 83, 145, 1)';
  let bgColor = 'rgba(40, 83, 145, 0.05)';

  switch (propType) {
    case PropertyTypeGroup.HOUSING:
    case NewsecCategory.HOUSING:
      color = getPrimaryColorForMap(PropertyTypeGroup.HOUSING);
      bgColor = getPrimaryColorForMap(PropertyTypeGroup.HOUSING, opacity);
      break;
    case PropertyTypeGroup.SPACE:
    case NewsecCategory.OFFICE:
      color = getPrimaryColorForMap(PropertyTypeGroup.SPACE);
      bgColor = getPrimaryColorForMap(PropertyTypeGroup.SPACE, opacity);
      break;
    case PropertyTypeGroup.SPECIAL:
      color = getPrimaryColorForMap(PropertyTypeGroup.SPECIAL);
      bgColor = getPrimaryColorForMap(PropertyTypeGroup.SPECIAL, opacity);
      break;
    case PropertyTypeGroup.WAREHOUSE:
      color = getPrimaryColorForMap(PropertyTypeGroup.WAREHOUSE);
      bgColor = getPrimaryColorForMap(PropertyTypeGroup.WAREHOUSE, opacity);
      break;
    case PropertyTypeGroup.INDUSTRY:
      color = getPrimaryColorForMap(PropertyTypeGroup.INDUSTRY);
      bgColor = getPrimaryColorForMap(PropertyTypeGroup.INDUSTRY, opacity);
      break;
    case PropertyTypeGroup.STREET:
      color = getPrimaryColorForMap(PropertyTypeGroup.STREET);
      bgColor = getPrimaryColorForMap(PropertyTypeGroup.STREET, opacity);
      break;
    case PropertyTypeGroup.PRIVATE:
      color = getPrimaryColorForMap(PropertyTypeGroup.PRIVATE);
      bgColor = getPrimaryColorForMap(PropertyTypeGroup.PRIVATE, opacity);
      break;
    case PropertyTypeGroup.FARM:
    case NewsecCategory.SHOP:
      color = getPrimaryColorForMap(PropertyTypeGroup.FARM);
      bgColor = getPrimaryColorForMap(PropertyTypeGroup.FARM, opacity);
      break;
  }

  return { color, bgColor };
}

export function rgbaToHex(rgba: string): string {
  // Match RGBA values with regex, including optional alpha
  const match = rgba.match(/rgba?\((\d+), (\d+), (\d+),? ([\d.]+)?\)/);

  if (!match) return '';

  // Parse and ensure values are of the correct type
  const r: number = parseInt(match[1]);
  const g: number = parseInt(match[2]);
  const b: number = parseInt(match[3]);
  const a: number = match[4] ? parseFloat(match[4]) : 1; // Default alpha is 1 if undefined

  // Convert RGB values to hexadecimal
  const rHex: string = r.toString(16).padStart(2, '0');
  const gHex: string = g.toString(16).padStart(2, '0');
  const bHex: string = b.toString(16).padStart(2, '0');

  // Convert alpha (0-1) to hexadecimal (0-255 scale)
  const aHex: string = Math.round(a * 255)
    .toString(16)
    .padStart(2, '0');

  return `#${rHex}${gHex}${bHex}${aHex}`;
}
