import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Trans, useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { ButtonType } from 'shared/dist/types';

import { Error, Success } from '@/src/animations';
import { crmDialogTheme } from '@/src/components/dialogs/crm/dialog-theme';
import { editCRM2Team } from '@/src/lib/crm2-wrapper';
import Button from '@/src/widgets/buttons';

enum stepNames {
  editTeam = 1,
  error = 2,
  done = 3
}

function EditTeamStep({
  close,
  setStep,
  object
}: {
  close: () => void;
  setStep: any;
  object: any;
}) {
  const { t } = useTranslation('common');

  const [editTeamName, setEditTeamName] = useState<string>('');
  const [crmRefresh, setCrmRefresh] = useGlobal<{ crmRefresh: number }>('crmRefresh' as never);

  return (
    <div className={crmDialogTheme.container}>
      <div className="text-lg font-medium text-gray-900 dark:text-gray-300 px-5">
        <span className="break-all">
          <Trans
            i18nKey="crm.modals.teams.edit-team-name"
            t={t}
            values={{ teamName: object?.team?.title }}
            components={[<span className="text-bobo-prop dark:text-dark-prop" key={0} />]}
          />
        </span>
      </div>

      <div className="flex w-[90%] h-full items-center my-20">
        <span className="leading-4 text-sm text-right text-gray-500 dark:text-gray-300">
          {t('crm.modals.teams.rename-team')}
        </span>

        <input
          value={editTeamName}
          onChange={(event) => {
            setEditTeamName(event.target.value);
          }}
          autoComplete="off"
          className="p-2 py-1 w-9/12 ml-5 bg-card-graypanel border-2 dark:bg-dark-lessfaded dark:placeholder:text-gray-400 rounded dark:border-dark-morefaded border-bobo-burgundy bg-red-200 focus:bg-card-graypanel"
        />
      </div>

      <div className="flex flex-grow justify-between w-full items-end mt-4">
        <Button type={ButtonType.CANCEL} onClick={close}>
          {t('general.cancel')}
        </Button>

        <Button
          type={ButtonType.ADD}
          onClick={() => {
            editCRM2Team(object?.team?.uuid, {
              ...object?.team,
              title: editTeamName
            })
              .then(() => {
                setStep(stepNames.done);
              })
              .then(() => {
                setTimeout(() => {
                  setCrmRefresh((crmRefresh || 0) + 1);
                  close();
                }, 3000);
              });
          }}>
          {t('general.save')}
        </Button>
      </div>
    </div>
  );
}

function EditTeamStepDone({ object }: { object: any }) {
  const { t } = useTranslation('common');

  return (
    <div>
      <div className={crmDialogTheme.icon}>
        <Success />
      </div>

      <div className="text-lg font-medium text-gray-900 dark:text-gray-300">
        <span className="break-all">
          <Trans
            i18nKey="crm.modals.teams.edited-team-name"
            t={t}
            values={{ teamName: object?.team?.title }}
            components={[<span className="text-bobo-prop dark:text-dark-prop" key={0} />]}
          />
        </span>
      </div>
    </div>
  );
}

function ErrorStep({ close }: { close: () => void }) {
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.icon}>
        <Error />
      </div>

      <div className="flex flex-col w-full text-center items-center">
        <Dialog.Title as="h3" className="text-red-700 w-3/4 leading-6">
          <p className={crmDialogTheme.subtext}>{t('errors.try-again')}</p>
        </Dialog.Title>
      </div>

      <div className={crmDialogTheme.buttonContainer}>
        <Button type={ButtonType.CANCEL} onClick={close}>
          {t('general.close')}
        </Button>
      </div>
    </div>
  );
}

export default function CRM2EditTeamDialog({ close, object }: { close: () => void; object: any }) {
  const [step, setStep] = useState(stepNames.editTeam);

  let mod: JSX.Element = <div />;

  switch (step) {
    case stepNames.editTeam:
      mod = <EditTeamStep close={close} setStep={setStep} object={object} />;
      break;

    case stepNames.done:
      mod = <EditTeamStepDone object={object} />;
      break;

    case stepNames.error:
      mod = <ErrorStep close={close} />;
      break;
  }

  return (
    <>
      <button onClick={close} className={crmDialogTheme.closeContainerX}>
        <XMarkIcon className="h-5 w-5" />
      </button>
      {mod}
    </>
  );
}
