import { XMarkIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';

import { CRMBoardVM } from '@/src/types';

import CrmBoardDialogAddEdit from '../dialogs/crm/board-add-edit';
import { crmDialogTheme } from '../dialogs/crm/dialog-theme';
import CrmImportProperties from '../dialogs/crm/import-properties';
import CrmListDialogAddEdit from '../dialogs/crm/list-add-edit';
import CrmListDialogArchive from '../dialogs/crm/list-archive';
import CrmListDialogDelete from '../dialogs/crm/list-delete';
import { CrmPropertiesDialogAdd, ErrorTooManyProps } from '../dialogs/crm/property-add';
import CrmPropertyDialogArchive from '../dialogs/crm/property-archive';

const stepNames = {
  new_board: 10,
  update_board: 30,
  new_list: 2,
  update_list: 5,
  delete_list: 6,
  archive_list: 7,
  import_properties: 8,
  add_property: 20,
  add_properties: 40,
  too_many_props_to_import: 41,
  archive_property: 42,
  unarchive_property: 43
};

export default function CrmDialogSwitcher({
  object: {
    initStep = stepNames.new_list,
    listId = null,
    listName = ``,
    boardName,
    boardId,
    boardPrivacy,
    propertyUUID,
    authorityNickname,
    propertiesUUIDS,
    authoritiesNicknames,
    addEditMode = false,
    isEmpty,
    board
  },
  onClose
}: {
  onClose: () => any;
  object: {
    initStep?: number;
    listId?: null | string;
    listName?: string;
    boardName?: string;
    boardId?: null | string;
    boardPrivacy?: boolean;
    propertyUUID?: string;
    authorityNickname?: string;
    propertiesUUIDS?: string[];
    authoritiesNicknames?: string[];
    addEditMode?: boolean;
    isEmpty?: boolean;
    board?: CRMBoardVM;
  };
}) {
  const [step, setStep] = useState(initStep);
  const [reload, setReload] = useState(false);
  const [currentObject, setCurrentObject] = useState<{
    listName?: string;
    boardName?: string;
    boardId?: string | null;
    listId?: string | null;
    boardPrivacy?: boolean;
  }>({
    listName,
    boardName,
    boardId,
    listId,
    boardPrivacy
  });

  const [crmIncrement, setCRMIncrement] = useGlobal('crmIncrement');
  const [crmCurrentBoardIncrement, setCrmCurrentBoardIncrement] = useGlobal<any>(
    'currentBoardIncrement' as never
  );

  const setListName = (newListName) => {
    setCurrentObject({
      ...currentObject,
      listName: newListName
    });
  };

  useEffect(() => {
    setCurrentObject({
      listName,
      boardName,
      boardId,
      listId,
      boardPrivacy
    });
  }, [boardName, boardId, boardPrivacy]);

  const setBoardName = (boardName) => {
    setCurrentObject({
      ...currentObject,
      boardName
    });
  };

  const setBoardPrivacy = (boardPrivacy: boolean) => {
    setCurrentObject({
      ...currentObject,
      boardPrivacy
    });
  };

  useEffect(() => {
    setCurrentObject({
      ...currentObject,
      listId,
      listName,
      boardId,
      boardName,
      boardPrivacy
    });
  }, [listId]);

  function closeModal() {
    onClose();
    setStep(0);
    if (reload && step === (stepNames.new_board || stepNames.update_board)) {
      setCRMIncrement((crmIncrement || 0) + 1);
    } else if (reload) {
      setCrmCurrentBoardIncrement((crmCurrentBoardIncrement || 0) + 1);
    }
  }

  // const inviteToBoard = () => {
  //   console.log('Invite to Board');
  // };

  let mod: JSX.Element = <div />;

  switch (step) {
    case stepNames.new_board:
      mod = (
        <CrmBoardDialogAddEdit
          initStep={addEditMode ? 2 : 1}
          setMainStep={setStep}
          close={closeModal}
          setBoardPrivacy={setBoardPrivacy}
          setBoardName={setBoardName}
          object={currentObject}
          setObject={setCurrentObject}
          addEditMode={addEditMode}
          authoritiesNicknames={authoritiesNicknames}
          setReload={setReload}
        />
      );

      break;

    case stepNames.update_board:
      if (board) {
        mod = (
          <CrmBoardDialogAddEdit
            initStep={4}
            setMainStep={setStep}
            close={closeModal}
            setBoardPrivacy={setBoardPrivacy}
            setBoardName={setBoardName}
            board={board}
            object={currentObject}
            setObject={setCurrentObject}
            addEditMode={addEditMode}
            authoritiesNicknames={authoritiesNicknames}
            setReload={setReload}
          />
        );
      }
      break;

    case stepNames.new_list:
      currentObject.listId = null;
      mod = (
        <CrmListDialogAddEdit
          initStep={1}
          setMainStep={setStep}
          close={closeModal}
          setListName={setListName}
          object={currentObject}
          setObject={setCurrentObject}
          addEditMode={addEditMode}
          authoritiesNicknames={authoritiesNicknames}
          setReload={setReload}
        />
      );
      break;

    case stepNames.update_list:
      mod = (
        <CrmListDialogAddEdit
          initStep={2}
          setMainStep={setStep}
          close={closeModal}
          setListName={setListName}
          listName={listName}
          object={currentObject}
          setObject={setCurrentObject}
          addEditMode={addEditMode}
          authoritiesNicknames={authoritiesNicknames}
          setReload={setReload}
        />
      );
      break;

    case stepNames.archive_list:
      mod = (
        <CrmListDialogArchive
          initStep={1}
          close={closeModal}
          object={currentObject}
          setReload={setReload}
        />
      );
      break;

    case stepNames.import_properties:
      mod = (
        <CrmImportProperties
          initStep={1}
          close={closeModal}
          object={currentObject}
          setReload={setReload}
        />
      );
      break;

    case stepNames.delete_list:
      mod = (
        <CrmListDialogDelete
          initStep={isEmpty ? 2 : 1}
          close={closeModal}
          object={currentObject}
          setReload={setReload}
        />
      );
      break;

    case stepNames.add_property:
      mod = (
        <CrmPropertiesDialogAdd
          setMainStep={setStep}
          setObject={setCurrentObject}
          close={closeModal}
          authorityNicknames={[authorityNickname] as string[]}
          propertyUUIDs={[propertyUUID] as string[]}
          setReload={setReload}
        />
      );
      break;

    case stepNames.add_properties:
      mod = (
        <CrmPropertiesDialogAdd
          setMainStep={setStep}
          close={closeModal}
          setObject={setCurrentObject}
          authorityNicknames={authoritiesNicknames as string[]}
          propertyUUIDs={propertiesUUIDS as string[]}
          setReload={setReload}
        />
      );
      break;

    case stepNames.too_many_props_to_import:
      mod = <ErrorTooManyProps close={closeModal} />;
      break;

    case stepNames.archive_property:
      mod = (
        <CrmPropertyDialogArchive
          initStep={1}
          close={closeModal}
          listId={listId}
          propertyId={propertyUUID}
          boardId={boardId}
          authorityNickname={authorityNickname}
          setReload={setReload}
        />
      );
      break;

    case stepNames.unarchive_property:
      mod = (
        <CrmPropertyDialogArchive
          initStep={2}
          close={closeModal}
          propertyId={propertyUUID}
          boardId={boardId}
          authorityNickname={authorityNickname}
          setReload={setReload}
        />
      );
      break;
  }

  return (
    <>
      <button onClick={closeModal} className={crmDialogTheme.closeContainerX}>
        <XMarkIcon className="h-5 w-5" />
      </button>
      {mod}
    </>
  );
}
