// TODO: Later to be placed in the context folder

import { createContext, ReactNode, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  CashflowModelObjectType,
  CashflowValuationModel
} from 'shared/dist/types/cashflow/cashflow-model';
import {
  CashflowModelProviderContextVariables,
  Sensitivities
} from 'shared/dist/types/cashflow/general';
import { ValuationApiResponse } from 'shared/dist/types/cashflow/valuation';

import { getOrUpdateValuation } from '../lib/utils/cashflow-valuation/valuation';

export const CashflowModelContext = createContext<CashflowModelProviderContextVariables | null>(
  null
);

export const CashflowModelProvider = ({
  object,
  inCrm = false,
  children
}: {
  object: any;
  inCrm?: boolean;
  children: ReactNode;
}) => {
  const [loading, setLoading] = useState(true);
  const [selectedModel, setSelectedModel] = useState<CashflowValuationModel | undefined>(undefined);
  const [models, setModels] = useState<CashflowValuationModel[]>([]);
  const [sensitivities, setSensitivities] = useState<Sensitivities>({ maintenance: [] });
  const [sensitivitiesLoading, setSensitivitiesLoading] = useState(false);

  async function fetchData() {
    setLoading(true);

    const serverModel = await getModels();

    if (JSON.stringify(serverModel) !== JSON.stringify(selectedModel)) {
      await updateValuation();
      // fetchSensitivities();
    } else if (sensitivities.maintenance.length === 0) {
      // fetchSensitivities();
    }
    // }
    setLoading(false);
  }

  // Saved code for when adding sensitivities
  // async function fetchSensitivities() {
  //   if (!activeModel) {
  //     return;
  //   }
  //   setSensitivitiesLoading(true);
  //   const response = await getSensitivities(activeModel);
  //   if (response.ok) {
  //     const { result } = (await response.json()) as SensitivitiesApiResponse;
  //     setSensitivities(result);
  //   }
  //   setSensitivitiesLoading(false);
  // }

  async function saveModelWithValuation(model: CashflowValuationModel) {
    setLoading(true);

    const response = await fetch('/api/prop/cashflow/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: model
      })
    });

    if (!response.ok) {
      throw new Error('Failed to update property');
    }
    setLoading(false);
  }

  async function getModels(): Promise<CashflowValuationModel | undefined> {
    const response = await fetch(
      `/api/prop/cashflow/get-all?objectUuid=${object?.uuid}&objectType=${CashflowModelObjectType.PROPERTY}`,
      {
        method: 'GET'
      }
    );

    if (response.ok) {
      const { models }: { models: CashflowValuationModel[] } = await response.json();
      setModels(models);

      const serverModel = models.find((m) => m.uuid === selectedModel?.uuid);
      if (serverModel) return serverModel;
      if (!selectedModel && models.length > 0) return models[0];
      if (selectedModel) return selectedModel;
      return models.sort((a, b) => b.updatedAt - a.updatedAt)[-1];
    }
    return;
  }

  const formatUpdatedAssetWithValuation = (
    valuationApiResponse: ValuationApiResponse,
    selectedModel: CashflowValuationModel
  ): CashflowValuationModel => {
    const updatedAssets = selectedModel.assets.map((asset) => {
      const assetValuation = valuationApiResponse.result.properties.find(
        (p) => p.id === asset.uuid
      );
      return assetValuation
        ? {
            ...asset,
            valuation: {
              amount: assetValuation.valuation,
              noi: assetValuation.noi,
              tables: assetValuation.tables
            }
          }
        : asset;
    });
    const updatedModel: CashflowValuationModel = {
      ...selectedModel,
      valuation: {
        amount: valuationApiResponse.result.valuation,
        noi: valuationApiResponse.result.noi,
        tables: valuationApiResponse.result.tables
      },
      assets: updatedAssets
    };

    return updatedModel;
  };

  async function updateValuation() {
    if (!selectedModel) {
      return;
    }

    const response = await getOrUpdateValuation(selectedModel, object);

    if (response.ok) {
      const valuationApiResponse = (await response.json()) as ValuationApiResponse;
      const updatedModel = formatUpdatedAssetWithValuation(valuationApiResponse, selectedModel);

      await saveModelWithValuation(updatedModel);

      if (JSON.stringify(updatedModel) !== JSON.stringify(selectedModel)) {
        setSelectedModel(updatedModel); // Only set if changed
      }
    } else if (response.status === 422) {
      toast.error('Felaktigt format på förfrågan');
    } else if (response.status === 500) {
      toast.error('Internt fel i beräknningen hos servern');
    } else if (response.status === 404) {
      toast.error('Hittade inte servern');
    } else if (response.status === 400) {
      toast.error('Felaktig förfrågan');
    }
  }

  useEffect(() => {
    fetchData();
  }, [selectedModel]);

  return (
    <CashflowModelContext.Provider
      value={{
        models,
        selectedModel,
        setSelectedModel,
        loading,
        refresh: fetchData,
        object: object,
        sensitivities,
        sensitivitiesLoading,
        inCrm
      }}>
      {children}
    </CashflowModelContext.Provider>
  );
};
