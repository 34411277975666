import cls from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { ButtonType } from 'shared/dist/types';

import { FileDownloadIcon } from '@/src/assets/icons';
import { useAccess } from '@/src/context/access-features-context';
import { useDialog } from '@/src/context/dialog-context';
import { useBreakpoint } from '@/src/hooks/use-breakpoint';
import DropdownMenu from '@/src/layout/components/dropdown-menu';
import { getReport } from '@/src/lib/prop-client-wrapper';

import ReportOption from './report-option-button';

export default function ReportsBtn({
  property,
  iconOnly = false
}: {
  property: any;
  iconOnly?: boolean;
}) {
  const { t } = useTranslation('common');
  const { setDialog } = useDialog();
  const router = useRouter();
  const { accessFeatures } = useAccess();
  const { isXl } = useBreakpoint({ breakpoint: 'xl' });

  const fetchFDS = () => {
    if (property?.access_level > 0 || accessFeatures?.fds) {
      getReport('fds', property?.uuid, router, t);
    }
  };

  const renderMenuItems = (closeMenu: () => void) => {
    const menuItems = [
      {
        title: t('reports.property-report.title'),
        onClick: (e) => {
          e.stopPropagation();
          setDialog({
            dialogType: 'prompt-prop-report',
            object: {
              property: property
            }
          });
          closeMenu();
        },
        isShown: process.env.NEXT_PUBLIC_FEATURE_PROPERTY_REPORT_ENABLED === 'true'
      },
      {
        title: t('general.fds'),
        onClick: (e) => {
          e.stopPropagation();
          if (property?.access_level > 0 || accessFeatures?.fds) {
            fetchFDS();
          } else {
            setDialog({
              dialogType: 'prompt-prop-unlock-fds',
              object: property
            });
          }
          closeMenu();
        },
        accessStatus: !!(property?.access_level > 0 || accessFeatures?.fds),
        isShown: true
      },
      {
        title: t('reports.env-and-bank-report.env-report-title'),
        onClick: (e) => {
          e.stopPropagation();
          setDialog({
            dialogType: 'prompt-env-report',
            object: {
              property: property
            }
          });
          closeMenu();
        },
        accessStatus: !!accessFeatures?.edd_report,
        isShown: process.env.NEXT_PUBLIC_FEATURE_ENV_REPORT_ENABLED === 'true'
      },
      {
        title: t('reports.env-and-bank-report.bank-report-title'),
        onClick: (e) => {
          e.stopPropagation();
          setDialog({
            dialogType: 'prompt-bank-report',
            object: {
              property: property
            }
          });
          closeMenu();
        },
        accessStatus: !!accessFeatures?.bank_report,
        isShown: process.env.NEXT_PUBLIC_FEATURE_BANK_VALUATION_REPORT_ENABLED === 'true'
      }
    ];

    return menuItems
      .filter((item) => item.isShown)
      .map((item, index) => (
        <ReportOption
          key={index}
          title={item.title}
          onClick={item.onClick}
          accessStatus={item.accessStatus ?? undefined}
        />
      ));
  };

  return (
    <DropdownMenu
      buttonType={ButtonType.INFO}
      title={
        <>
          <FileDownloadIcon
            className={cls(
              'group-hover:text-bobo-texthover transition-colors duration-200 dark:text-white h-5 w-5 text-bobo-black cursor-pointer',
              {
                iconOnly: 'text-gray-600 dark:text-white'
              }
            )}
          />
          <div
            className={cls('font-demi text-smaller tracking-wide hidden', {
              'xl:block': !iconOnly
            })}>
            {t('risk.reports')}
          </div>
        </>
      }
      renderMenuItems={renderMenuItems}
      buttonClassName={cls('!h-[32px]', { 'xl:!w-[160px]': !iconOnly })}
      dropdownClassName="lg:!bg-gray-200 dark:!bg-dark-faded !right-0 !border-none"
      tooltipText={!isXl ? t('risk.reports') : undefined}
    />
  );
}
