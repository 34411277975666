import { TrashIcon } from '@heroicons/react/24/solid';
import cls from 'classnames';
import { useTranslation } from 'next-i18next';
import { ButtonType } from 'shared/dist/types';

import {
  DeleteType,
  stepNames
} from '@/src/components/cashflow-valuation/cashflow-dialog-switcher';
import ChooseModel from '@/src/components/cashflow-valuation/items/buttons/choose-model';
import ExportModel from '@/src/components/cashflow-valuation/items/buttons/export-model';
import { useDialog } from '@/src/context/dialog-context';
import { useBreakpoint } from '@/src/hooks/use-breakpoint';
import { useCashflowModelContext } from '@/src/hooks/use-cashflow-context';
import Tooltip from '@/src/layout/components/tooltip';

import Button from '../buttons';

export default function CashflowMenu() {
  const { setDialog } = useDialog();
  const { t } = useTranslation('common');
  const { refresh, selectedModel, setSelectedModel, object } = useCashflowModelContext();
  const { isXl } = useBreakpoint({ breakpoint: 'xl' });
  const iconOnly = !isXl;

  const handleDelete = (event) => {
    event.stopPropagation();
    setDialog({
      dialogType: 'cashflow-dialog-switcher',
      object: {
        initStep: stepNames.delete,
        refresh,
        object: object,
        deletedObject: { ...selectedModel, deleteType: DeleteType.MODEL },
        model: selectedModel,
        setSelectedModel
      }
    });
  };

  return (
    <div className="flex flex-row gap-2 items-center">
      <div className="w-fit">
        <ChooseModel object={object} iconOnly={iconOnly} />
      </div>

      <div className="w-fit">
        <ExportModel iconOnly={iconOnly} />
      </div>

      <Button
        type={ButtonType.DELETE}
        onClick={(e) => handleDelete(e)}
        className={cls('!h-[32px]', {
          'has-tooltip relative': iconOnly,
          'w-[150px]': !iconOnly
        })}>
        <TrashIcon className="w-4 h-4" />

        {!iconOnly ? (
          <div>{t('property.cashflow-valuation.delete-model')}</div>
        ) : (
          <Tooltip className={cls('top-10 -right-3')}>
            {t('property.cashflow-valuation.delete-model')}
          </Tooltip>
        )}
      </Button>
    </div>
  );
}
