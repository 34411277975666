import moment from 'moment';
import { TFunction } from 'next-i18next';
import { getCategoryName } from 'shared/dist/constants';
import {
  CalculationResult,
  PropertyTypeGroup,
  RadioButtonOptions,
  TaxCategory,
  TaxTypes,
  ValueCategoryTypes
} from 'shared/dist/types';
import {
  CashflowAdjustableAssumptions,
  CashflowCalculatedAssumptions,
  CashflowInflation,
  CashflowLease,
  CashflowModelObjectType,
  CashflowModelOwnership,
  CashflowSelectedIndexAssumption,
  CashflowValuationAsset,
  CashflowValuationModel,
  InflationType,
  PropertyTax,
  PropertyValuationDefaultValues,
  SliderValue
} from 'shared/dist/types/cashflow/cashflow-model';
import { NewsecCategory } from 'shared/dist/types/rent';
import { getGroupForObjectType } from 'shared/dist/util/calculations';
import { v4 as uuidv4 } from 'uuid';

import {
  DEFAULT_INFLATION_UUID,
  MAX_MODEL_DURATION
} from '@/src/components/cashflow-valuation/constants/cashflow-theme';
import { taxCategories, valueCategories } from '@/src/components/valuation-tool/items/constants';
import { getDefaultValues, getNewResult } from '@/src/components/valuation-tool/items/functions';
import { getColorForObjectType } from '@/src/lib/colors';

interface DecoratedPropType {
  area: number;
  propType: PropertyTypeGroup;
  taxCode: number;
}

// Constants
const currentYear = +moment().format('YYYY');
const lastYear = currentYear + MAX_MODEL_DURATION;

function getNewsecCategoriesFromObject(object: any): NewsecCategory[] {
  const propTypes: DecoratedPropType[] = getPropTypesFromProptax(object?.propTax);
  const newSecCategories: NewsecCategory[] = [];

  propTypes?.map((type) => {
    if (
      type.propType === PropertyTypeGroup.WAREHOUSE ||
      type.propType === PropertyTypeGroup.INDUSTRY
    ) {
      newSecCategories.push(NewsecCategory.INDUSTRY);
    } else if (type.taxCode === 321) {
      if (object?.boa && object?.boa > 0) {
        newSecCategories.push(NewsecCategory.HOUSING);
      }
      if (object?.loa && object?.loa > 0) {
        newSecCategories.push(NewsecCategory.OFFICE);
      }
    } else if (type.propType === PropertyTypeGroup.HOUSING) {
      newSecCategories.push(NewsecCategory.HOUSING);
    } else if (type.propType === PropertyTypeGroup.SPACE) {
      newSecCategories.push(NewsecCategory.OFFICE);
    } else if (type.propType === PropertyTypeGroup.SHOP) {
      newSecCategories.push(NewsecCategory.SHOP);
    }
  });

  return [...new Set(newSecCategories)];
}

export const getPropTypesFromProptax = (propTax) => {
  return propTax?.map((prop) => {
    const totalArea: number = prop?.taxUnits
      ?.filter((a) => a?.unit_type !== 'hyreshusmark lokal' && a?.unit_type !== 'industrimark')
      ?.reduce((acc, b) => {
        return acc + (b?.total_area > 0 ? b?.total_area : 0);
      }, 0);
    return {
      taxCode: prop.tax_code,
      area: totalArea,
      propType:
        prop.tax_code === 800
          ? PropertyTypeGroup.SPECIAL
          : getGroupForObjectType(prop.tax_code ?? 0)
    };
  });
};

export function getInflationScenario(
  startYear: number,
  endYear: number,
  value: number,
  name: string
) {
  return {
    name: name,
    uuid: uuidv4(),
    values: [...Array(endYear - startYear + 1).keys()].map((year) => {
      return {
        year: year + startYear,
        value: value
      };
    })
  } as CashflowInflation;
}

const createDefaultInflationScenarios = (t) => {
  const baseInflation = getInflationScenario(
    currentYear,
    lastYear,
    0.02,
    t('property.cashflow-valuation.lease.inflation-flat')
  );
  const zeroInflation = getInflationScenario(
    currentYear,
    lastYear,
    0.0,
    t('property.cashflow-valuation.lease.inflation-none')
  );

  return [{ ...baseInflation, uuid: DEFAULT_INFLATION_UUID }, zeroInflation];
};

export const getInitialSliderValuesOnCategory = (
  category: NewsecCategory,
  defaultValues: PropertyValuationDefaultValues
): SliderValue[] => {
  const selectedNewsecCategory = category;
  const selectedForumCategory = category;

  const selectedFLCategory: PropertyTypeGroup = (() => {
    switch (category) {
      case NewsecCategory.OFFICE:
      case NewsecCategory.SHOP:
        return PropertyTypeGroup.SPACE;
      case NewsecCategory.INDUSTRY:
        return PropertyTypeGroup.WAREHOUSE;
      default:
        return PropertyTypeGroup.HOUSING;
    }
  })();

  return Object.keys(valueCategories).map((cat) => {
    const selectedRadioButton: RadioButtonOptions = getRadioButtonOption(
      cat,
      selectedFLCategory,
      selectedNewsecCategory,
      defaultValues
    );
    const value = getValueForCategory(
      cat,
      selectedRadioButton,
      selectedFLCategory,
      selectedNewsecCategory,
      selectedForumCategory,
      defaultValues
    );

    return {
      category: cat as ValueCategoryTypes,
      value,
      radioButton: selectedRadioButton,
      selectedNewsecCategory,
      selectedFLCategory,
      selectedForumCategory,
      subcost: valueCategories[cat]?.subcost ?? false
    } as SliderValue;
  });
};

const getRadioButtonOption = (
  category: string,
  selectedFLCategory: PropertyTypeGroup,
  selectedNewsecCategory: NewsecCategory,
  defaultValues
): RadioButtonOptions => {
  const flValue = defaultValues[category].fl.find(
    (rentCategory) => rentCategory.propType === selectedFLCategory
  )?.value;
  const newsecValue = defaultValues[category].newsec.find(
    (rentCategory) => rentCategory.propType === selectedNewsecCategory
  )?.value;
  const forumValue = defaultValues[category].forum.find(
    (rentCategory) => rentCategory.propType === selectedNewsecCategory
  )?.value;

  if (flValue > 0) return RadioButtonOptions.FL;
  if (newsecValue > 0) return RadioButtonOptions.NEWSEC;
  if (forumValue > 0) return RadioButtonOptions.FORUM;
  return RadioButtonOptions.OTHER;
};

const getValueForCategory = (
  category: string,
  selectedRadioButton: RadioButtonOptions,
  selectedFLCategory: PropertyTypeGroup,
  selectedNewsecCategory: NewsecCategory,
  selectedForumCategory: NewsecCategory,
  defaultValues
): number => {
  if (selectedRadioButton === RadioButtonOptions.FL) {
    return (
      defaultValues[category].fl?.find((type) => type.propType === selectedFLCategory)?.value ?? 0
    );
  } else if (selectedRadioButton === RadioButtonOptions.NEWSEC) {
    return (
      defaultValues[category].newsec.find((type) => type.propType === selectedNewsecCategory)
        ?.value ?? 0
    );
  } else if (selectedRadioButton === RadioButtonOptions.FORUM) {
    return (
      defaultValues[category].forum.find((type) => type.propType === selectedForumCategory)
        ?.value ?? 0
    );
  }

  return 0;
};

export const getAreaOnCategory = (category, object): number => {
  let area = 0;
  const propTypes = getPropTypesFromProptax(object.propTax);

  if (category === NewsecCategory.HOUSING) {
    area = object.v2?.residential_details?.sqm ?? object.boa ?? 0;
  } else if (category === NewsecCategory.OFFICE) {
    area = object.v2?.office_details?.sqm ?? object.loa ?? 0;
  } else if (category === NewsecCategory.INDUSTRY) {
    if (propTypes.filter((type) => type.propType === PropertyTypeGroup.INDUSTRY).length > 0) {
      area = propTypes.find((type) => type.propType === PropertyTypeGroup.INDUSTRY).area;
    } else if (
      propTypes.filter((type) => type.propType === PropertyTypeGroup.WAREHOUSE).length > 0
    ) {
      area = propTypes.find((type) => type.propType === PropertyTypeGroup.WAREHOUSE).area;
    } else {
      area = object.v2?.warehouse_details?.sqm ?? object.loa ?? 0;
    }
  }

  return area;
};

export const getPropertyTaxInfo = (category: NewsecCategory, object): PropertyTax => {
  const valueYear = Math.max(...(object.valueYears ?? [0]));
  const propTypes = getPropTypesFromProptax(object.propTax);

  let propertyTaxType: TaxCategory | undefined = taxCategories.find(
    (cat) => cat.title === TaxTypes.HOUSINGOLD
  );
  let taxValue = 0;

  const taxUnitTypes = {
    [NewsecCategory.HOUSING]: ['Q', 'S'],
    [NewsecCategory.OFFICE]: ['T', 'Z', 'hyreshusmark lokal'],
    [NewsecCategory.SHOP]: ['T', 'Z', 'hyreshusmark lokal'],
    [NewsecCategory.INDUSTRY]: ['V', 'Y', 'X', 'lager avkastning']
  };

  const categoryTaxMap = {
    [NewsecCategory.HOUSING]: () => {
      if (valueYear >= 2009) return TaxTypes.HOUSINGNEW;
      if (valueYear > 2004) return TaxTypes.HOUSING16TO20;
      return TaxTypes.HOUSINGOLD;
    },
    [NewsecCategory.OFFICE]: () => TaxTypes.COMMERCIAL,
    [NewsecCategory.INDUSTRY]: () => TaxTypes.INDUSTRY,
    [NewsecCategory.SHOP]: () => TaxTypes.COMMERCIAL,
    [NewsecCategory.CARE]: () => TaxTypes.SPECIAL
  };

  if (categoryTaxMap[category]) {
    propertyTaxType = taxCategories.find((cat) => cat.title === categoryTaxMap[category]());
  }

  if (
    category === NewsecCategory.INDUSTRY ||
    propTypes.some((type) => type.propType === PropertyTypeGroup.WAREHOUSE)
  ) {
    propertyTaxType = taxCategories.find((cat) => cat.title === TaxTypes.INDUSTRY);
  }

  if (!propertyTaxType) {
    propertyTaxType = taxCategories.find((cat) => cat.title === TaxTypes.HOUSINGOLD); // Fallback to HousingOld
  }

  if (!propertyTaxType) {
    throw new Error('Unable to determine the property tax type');
  }

  object.propTax?.forEach((tax) => {
    tax.taxUnits?.forEach((taxUnit) => {
      if (
        taxUnitTypes[category]?.includes(taxUnit.lm_unit_type) ||
        taxUnitTypes[category]?.includes(taxUnit.unit_type)
      ) {
        taxValue += taxUnit?.value;
      }
    });
  });

  return {
    propertyTaxType,
    taxValue,
    countPropertyTax: false
  };
};

export const calculateCommercialAreaWithVacancy = (
  area: number,
  sliderValues: SliderValue[]
): number => {
  const vacancy =
    sliderValues.find((cat) => cat.category === ValueCategoryTypes.VACANCY)?.value ?? 0;
  const vacancyDecimal = (100 - vacancy) / 100;

  return +(area * vacancyDecimal).toFixed(2);
};

export const createAdjustableAssumptions = (
  sliderValues: SliderValue[],
  area: number,
  category: NewsecCategory,
  object: any,
  t: any,
  assetUuid: string
) => {
  const propertyTax: PropertyTax = getPropertyTaxInfo(category, object);

  const rentValue =
    sliderValues.find((cat) => cat.category === ValueCategoryTypes.RENT)?.value ?? 0;

  const rentRoll: CashflowLease[] =
    category !== NewsecCategory.HOUSING
      ? [
          {
            uuid: uuidv4(),
            name: t('property.cashflow-valuation.lease.default-lease-name', {
              assetCategory: t(getCategoryName(category))
            }),
            startDate: moment(currentYear + '-01-01')
              .startOf('day')
              .unix(),
            endDate: moment(lastYear + '-01-01')
              .startOf('day')
              .unix(),
            rent: rentValue,
            supplements: 0,
            indexation: 1.0,
            area: calculateCommercialAreaWithVacancy(area, sliderValues),
            assetName: t(getCategoryName(category)),
            assetUuid: assetUuid
          }
        ]
      : [];

  const defaultSelectedRentIndex: CashflowSelectedIndexAssumption = {
    uuid: DEFAULT_INFLATION_UUID,
    type: InflationType.DISCRETE
  };
  const defaultSelectedCostIndex: CashflowSelectedIndexAssumption = {
    uuid: DEFAULT_INFLATION_UUID,
    type: InflationType.CONTINUOUS
  };

  return {
    sliderValues: sliderValues,
    area,
    type: category,
    propertyTax,
    rentIndex: defaultSelectedRentIndex,
    costIndex: defaultSelectedCostIndex,
    investments: [],
    rentRoll
  };
};

export const createCalculatedAssumptions = (
  calculatorResult: CalculationResult,
  existingAssumptions?: CashflowCalculatedAssumptions
): CashflowCalculatedAssumptions => {
  if (existingAssumptions) {
    return {
      ...existingAssumptions,
      discountRate: calculatorResult[ValueCategoryTypes.YIELD] / 100 + 0.02,
      calculatorResult: calculatorResult
    };
  }
  return {
    growthRate: 0.02, // Based on long term normalized growth
    discountRate: calculatorResult[ValueCategoryTypes.YIELD] / 100 + 0.02, // Normalize yield and add base growth
    inflationStartDate: moment().startOf('year').unix(),
    discreteInflationMonth: 1,
    calculatorResult: calculatorResult
  };
};

export const createNewAsset = (
  object,
  defaultValues: PropertyValuationDefaultValues,
  category: NewsecCategory,
  modelUuid: string,
  t
): CashflowValuationAsset => {
  const assetUuid = uuidv4();
  const area: number = getAreaOnCategory(category, object);
  const sliderValues: SliderValue[] = getInitialSliderValuesOnCategory(category, defaultValues);
  const calculatorResult: CalculationResult = getNewResult(sliderValues, area);
  const calculatedAssumptions: CashflowCalculatedAssumptions =
    createCalculatedAssumptions(calculatorResult);

  const adjustableAssumptions: CashflowAdjustableAssumptions = createAdjustableAssumptions(
    sliderValues,
    area,
    category,
    object,
    t,
    assetUuid
  );

  return {
    uuid: assetUuid,
    name: t(getCategoryName(category)),
    modelUuid: modelUuid,
    adjustableAssumptions: adjustableAssumptions,
    calculatedAssumptions: calculatedAssumptions,
    createdAt: moment().unix(),
    updatedAt: moment().unix()
  };
};

export const createNewModel = (
  object,
  name: string,
  t,
  cashflowModelType: CashflowModelObjectType
): CashflowValuationModel => {
  const modelUuid: string = uuidv4();
  const newsecCategories: NewsecCategory[] = getNewsecCategoriesFromObject(object);
  const defaultValues: PropertyValuationDefaultValues = getDefaultValues(object, t);

  const modelAssets: CashflowValuationAsset[] = newsecCategories.map((cat) =>
    createNewAsset(object, defaultValues, cat, modelUuid, t)
  );

  const startOfCurrentYear = moment().startOf('year');

  const modelOwnership: CashflowModelOwnership = {
    type: 'private',
    uuid: ''
  };

  return {
    uuid: modelUuid,
    name: name,
    valuatedObject: {
      uuid: object.uuid,
      name: object.authorityNickname,
      type: cashflowModelType
    },
    rentIndexes: createDefaultInflationScenarios(t),
    costIndexes: createDefaultInflationScenarios(t),
    modelLength: {
      startDate: startOfCurrentYear.unix(),
      endDate: startOfCurrentYear.add(9, 'years').endOf('year').unix()
    },
    assets: modelAssets.filter((asset) => asset.adjustableAssumptions.area > 0),
    credits: [],
    investments: [],
    ownership: modelOwnership,
    createdAt: moment().unix(),
    updatedAt: moment().unix()
  };
};

export async function initializeModel(object: any, name: string, t: TFunction) {
  const cashflowModel: CashflowValuationModel = createNewModel(
    object,
    name,
    t,
    CashflowModelObjectType.PROPERTY
  );

  const response = await fetch('/api/prop/cashflow/create', {
    method: 'POST',
    body: JSON.stringify({
      data: cashflowModel
    })
  });

  if (response.ok) {
    return cashflowModel;
  }
  return;
}

export function updateAsset({
  assetToUpdate,
  model,
  setSelectedModel
}: {
  assetToUpdate: CashflowValuationAsset;
  model: CashflowValuationModel;
  setSelectedModel: (arg0?: CashflowValuationModel) => void;
}) {
  const otherAssets = model.assets.filter((p) => p.uuid !== assetToUpdate.uuid);

  const updatedModel = {
    ...model,
    assets: [...otherAssets, assetToUpdate]
  };

  setSelectedModel(updatedModel);
}

export function getTaxCodeWithLargestArea(data) {
  const largestAreaObject = data.reduce((maxObj, currentObj) => {
    return currentObj.tax_area > (maxObj.tax_area || 0) ? currentObj : maxObj;
  }, {});
  return largestAreaObject.tax_code || null;
}

export function getColorFromProperty(object: any) {
  const taxCode = getTaxCodeWithLargestArea(object.propTax);
  const { color, bgColor } = getColorForObjectType(taxCode);
  return { color, bgColor };
}

export const getAssetInflation = (
  inflationUuid: string,
  modelInflations: CashflowInflation[]
): CashflowInflation =>
  modelInflations.find((infl) => infl.uuid === inflationUuid) ?? modelInflations[0];
