import { ChevronRightIcon } from '@heroicons/react/24/solid';
import cls from 'classnames';
import { useTranslation } from 'next-i18next';
import { useEffect, useMemo, useState } from 'react';
import { RadioButtonOptions, ValueCategoryTypes } from 'shared/dist/types';
import { CashflowLease } from 'shared/dist/types/cashflow/cashflow-model';

import Tooltip from '@/src/layout/components/tooltip';
import InfoButton from '@/src/widgets/info-button';
import { FLCustom } from '@/src/widgets/numbers';

import { calculateCommercialVacancy } from '../../cashflow-valuation/constants/calculations';
import SelectLeaseDropdown from '../../cashflow-valuation/items/buttons/select-lease-dropdown';
import { FLRadioButton } from './buttons/fl-radio-button';
import { ForumRadioButton } from './buttons/forum-radio-button';
import { NewsecRadioButton } from './buttons/newsec-radio-button';
import { OtherRadioButton } from './buttons/other-radio-button';
import { SpinnerSlider, valueCategories } from './constants';

export function CalculatorRow({
  valueCategory,
  changeValue,
  selectedTab,
  tabValues,
  defaultValues,
  costValues,
  defaultRowValues,
  result = {},
  area = 0,
  rentRoll = undefined,
  viewLease,
  errorState,
  inCashflow = false
}: {
  valueCategory: any;
  changeValue: any;
  selectedTab: string;
  tabValues: any;
  defaultValues?: any;
  costValues: any[];
  defaultRowValues: any;
  result?: any;
  area?: number;
  rentRoll?: CashflowLease[];
  viewLease?: (lease: CashflowLease | undefined) => void;
  errorState?: any;
  inCashflow?: boolean;
}) {
  const [showCosts, setShowCosts] = useState<boolean>(false);
  const [ownValue, setOwnValue] = useState<number>(tabValues.value || 0);

  const { t } = useTranslation('common');

  const isCashflowNoSliderRow: boolean = rentRoll !== undefined;
  const isCashflowCommercialVacancy: boolean =
    isCashflowNoSliderRow && valueCategory.title === ValueCategoryTypes.VACANCY;
  const isCashflowCommercialRent: boolean =
    isCashflowNoSliderRow && valueCategory.title === ValueCategoryTypes.RENT;

  useEffect(() => {
    if (
      showCosts &&
      valueCategory.title === ValueCategoryTypes.COSTS &&
      ownValue !== tabValues.value
    ) {
      setOwnValue(tabValues.value);
    }
  }, [area, tabValues.value, tabValues.selectedFLCategory]);

  const disabled: boolean =
    (valueCategory.title === ValueCategoryTypes.COSTS && showCosts) || isCashflowNoSliderRow;

  const rowCellStyle = `text-xs py-2 font-medium bg-white dark:bg-dark-lessfaded flex flex-col items-center justify-center ${
    showCosts || valueCategory.subcost ? '' : 'border-b'
  } border-r border-gray-100 dark:border-dark-morefaded text-center h-[140px]`;

  const radioButtonStyle = `bg-white dark:bg-dark-lessfaded ${
    disabled || isCashflowNoSliderRow
      ? 'text-gray-400 border-gray-400 cursor-default'
      : 'cursor-pointer text-bobo-prop dark:text-dark-prop border-bobo-prop dark:border-dark-prop hover:bg-bobo-proplight dark:hover:bg-dark-proplight'
  } transition-all duration-200`;

  const hasInfoBtn = !valueCategory.subcost;

  const getHelpTextId = (category: ValueCategoryTypes): string => {
    switch (category) {
      case ValueCategoryTypes.RENT:
        return 'valuationToolRent';
      case ValueCategoryTypes.YIELD:
        return inCashflow ? 'cashflowLongTermYield' : 'valuationToolYield';
      case ValueCategoryTypes.COSTS:
        return 'valuationToolCosts';
      case ValueCategoryTypes.VACANCY:
        return 'valuationToolVacancy';
      case ValueCategoryTypes.MAINTENANCE:
        return 'valuationToolCosts';
      default:
        return '';
    }
  };

  const getResultValue = (): number => {
    const value = Math.round(result[valueCategory.title] / 1000) ?? 0;

    if (
      valueCategory.title === ValueCategoryTypes.COSTS ||
      valueCategory.title === ValueCategoryTypes.MAINTENANCE
    ) {
      return -value;
    }

    if (valueCategory.title === ValueCategoryTypes.VACANCY) {
      if (isCashflowCommercialVacancy && rentRoll) {
        return calculateCommercialVacancy(rentRoll, area ?? 0);
      } else {
        return result[valueCategory.title] ?? 0;
      }
    }
    if (valueCategory.title === ValueCategoryTypes.YIELD) {
      return result[valueCategory.title] ?? 0;
    }

    return value;
  };

  const resultValue = useMemo(() => {
    if (valueCategory.subcost) {
      return 0;
    }
    return getResultValue();
  }, [result[valueCategory.title], rentRoll]);

  const renderCostSection = () => {
    if (!showCosts) return null;

    return costValues.map((cat, idx) => (
      <CalculatorRow
        key={idx}
        valueCategory={valueCategories[cat.category]}
        changeValue={changeValue}
        selectedTab={selectedTab}
        tabValues={cat}
        defaultRowValues={defaultValues[cat.category]}
        costValues={[]}
      />
    ));
  };

  const titleString = () => {
    if (valueCategory.title === ValueCategoryTypes.COSTS) {
      return t('property.cashflow-valuation.maintenance-short');
    }
    if (valueCategory.title === ValueCategoryTypes.YIELD) {
      return inCashflow ? t('property.cashflow-valuation.exit-yield') : t('general.yield');
    }
    return t(valueCategory.title);
  };

  return (
    <>
      <div
        className={cls(
          'flex items-stretch justify-between border-l border-gray-100 dark:border-dark-morefaded',
          {
            'border-y-0': showCosts || valueCategory.subcost || isCashflowNoSliderRow
          }
        )}>
        <div className={`${rowCellStyle} w-1/6 relative`}>
          <div className={cls(valueCategory.subcost ? 'text-mini text-gray-400' : '')}>
            {titleString()}
            {valueCategory.title === ValueCategoryTypes.RENT ? t('value-calculator.gross') : ''}
          </div>

          {valueCategory.title === ValueCategoryTypes.COSTS ? (
            <button
              onClick={() => setShowCosts(!showCosts)}
              className="text-mini font-normal hover:bg-bobo-proplight dark:hover:bg-dark-primary rounded-full p-1 text-bobo-prop dark:text-dark-prop hover:text-bobo-prophover hover:dark:text-dark-prophover transition-colors duration-300 flex items-center absolute right-1 bottom-1">
              <ChevronRightIcon
                className={cls('w-4 h-4 transition-transform duration-200', {
                  'rotate-90': showCosts
                })}
              />
            </button>
          ) : null}

          {hasInfoBtn ? (
            <div className="absolute right-2 top-2">
              <InfoButton helpTextId={getHelpTextId(valueCategory.title)} />
            </div>
          ) : null}
        </div>

        <div
          className={`${rowCellStyle} w-3/6 px-4 pb-2 ${
            isCashflowNoSliderRow ? '!justify-between' : '!justify-end'
          }`}>
          {isCashflowNoSliderRow ? (
            <div className="h-full w-full flex flex-col gap-1 items-center justify-start px-4">
              <div className="text-gray-400 text-mini leading-3">
                {t(
                  `property.cashflow-valuation.assumptions.commercial-${
                    isCashflowCommercialRent ? 'rents' : 'vacancies'
                  }`
                )}
              </div>

              {isCashflowCommercialRent && rentRoll !== undefined && viewLease ? (
                <div>
                  <SelectLeaseDropdown
                    rentRoll={rentRoll}
                    viewLease={viewLease}
                    errorState={errorState}
                  />
                </div>
              ) : null}
            </div>
          ) : null}

          <SpinnerSlider
            min={valueCategory.sliderMin}
            max={valueCategory.sliderMax}
            step={valueCategory.sliderStep}
            disabled={disabled}
            value={tabValues.value ?? null}
            valueLabelDisplay="on"
            valueLabelFormat={() => {
              return `${tabValues.value}${t(valueCategory.suffix)}`;
            }}
            onChange={(_, value) => {
              if (value && typeof value === 'number') {
                changeValue(selectedTab, valueCategory.title, value, RadioButtonOptions.OTHER);
                setOwnValue(value);
              }
            }}
          />
        </div>

        <div className={`${rowCellStyle} w-1/6 flex flex-col gap-1`}>
          <NewsecRadioButton
            valueCategory={valueCategory}
            changeValue={changeValue}
            selectedTab={selectedTab}
            tabValues={tabValues}
            disabled={disabled}
            radioButtonStyle={radioButtonStyle}
            defaultRowValues={defaultRowValues}
            rentRoll={rentRoll ?? undefined}
          />
          <ForumRadioButton
            valueCategory={valueCategory}
            changeValue={changeValue}
            selectedTab={selectedTab}
            tabValues={tabValues}
            disabled={disabled}
            radioButtonStyle={radioButtonStyle}
            defaultRowValues={defaultRowValues}
            rentRoll={rentRoll ?? undefined}
          />
          <FLRadioButton
            selectedTab={selectedTab}
            valueCategory={valueCategory}
            disabled={disabled}
            defaultRowValues={defaultRowValues}
            radioButtonStyle={radioButtonStyle}
            changeValue={changeValue}
            tabValues={tabValues}
            rentRoll={rentRoll ?? undefined}
          />
          {!rentRoll ? (
            <OtherRadioButton
              valueCategory={valueCategory}
              tabValues={tabValues}
              disabled={disabled}
              radioButtonStyle={radioButtonStyle}
              selectedTab={selectedTab}
              changeValue={changeValue}
              ownValue={ownValue}
              setOwnValue={setOwnValue}
            />
          ) : null}
        </div>

        <div className={`${rowCellStyle} w-1/6`}>
          {!valueCategory?.subcost && (
            <div
              className={cls('has-tooltip w-full relative', {
                'text-bobo-bricksred': isCashflowCommercialVacancy && resultValue < 0
              })}>
              <FLCustom
                value={resultValue}
                suffix={
                  valueCategory?.suffix === '%'
                    ? valueCategory?.suffix
                    : t('general.thousand-suffix')
                }
                prefix={valueCategory?.suffix === '%' ? '' : t('general.sek-prefix')}
              />

              {isCashflowCommercialVacancy && resultValue < 0 && (
                <Tooltip className="absolute w-full top-5">
                  {t('property.cashflow-valuation.assumptions.negative-vacancy')}
                </Tooltip>
              )}
            </div>
          )}
        </div>
      </div>

      {renderCostSection()}
    </>
  );
}
