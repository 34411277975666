import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import cls from 'classnames';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import { useEffect, useRef, useState } from 'react';
import { CRMBoard } from 'shared/dist/types';
import { Error, Scooter, Success } from 'src/animations';

import { deleteBoard } from '@/src/lib/user-client-wrapper';

import { crmDialogTheme } from './dialog-theme';

const stepNames = {
  initial: 1,
  loading: 2,
  error: 3,
  done: 4,
  not_authorized: 5
};

function BoardDeletionStep1({
  setStep,
  close,
  board
}: {
  setStep: any;
  close: any;
  board: CRMBoard;
}) {
  const [inputValue, setInputValue] = useState<string>('');
  const [inputTouched, setInputTouched] = useState<boolean>(false);
  const [inputValid, setInputValid] = useState<boolean>(false);
  const [inputFocusedInitially, setInputFocusedInitially] = useState<boolean>(false);
  const inputRef: any = useRef(null);

  const { t } = useTranslation('common');

  useEffect(() => {
    if (inputRef?.current && !inputFocusedInitially) {
      inputRef?.current?.focus();
      setInputFocusedInitially(true);
    }
  }, [inputFocusedInitially]);

  function handleSubmit() {
    setInputTouched(true);

    if (inputValid) {
      setStep(stepNames.loading);

      deleteBoard(board.uuid, board.private)
        .then((res: any) => {
          if (res?.status === 200) {
            setStep(stepNames.done);
          } else {
            setStep(stepNames.error);
          }
        })
        .catch(() => {
          setStep(stepNames.error);
        });
    }
  }

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Image
          alt="main illustration"
          src={`/images/apartment-rate-increment.svg`}
          height={200}
          width={200}
        />
      </div>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          <Trans i18nKey="crm.modals.delete-board-title" boardName={board.title}>
            Är du säker att du vill radera tavlan
            <span className="text-bobo-prop dark:text-dark-prop">
              {' '}
              {{ boardName: board.title }}
            </span>
            ?
          </Trans>
        </Dialog.Title>
        <p className={crmDialogTheme.subtextDelete}>{t('crm.modals.delete-board-subtext')}</p>

        <div className={crmDialogTheme.inputContainerDelete}>
          <label className={crmDialogTheme.inputDeleteLabel} htmlFor={inputRef}>
            <Trans key="crm.modals.delete-prompt-input" name={board.title}>
              Skriv in{' '}
              <span className="font-demi text-bobo-black dark:text-white">
                {{ name: board.title }}
              </span>{' '}
              (exakt) i rutan till höger för att bekräfta raderingen.
            </Trans>
          </label>
          <input
            ref={inputRef}
            className={cls(crmDialogTheme.inputDelete, {
              'border-bobo-burgundy': !inputValid,
              'bg-red-200 focus:bg-card-graypanel': !inputValid && inputTouched
            })}
            value={inputValue}
            onPaste={() => {
              setInputTouched(true);
            }}
            onChange={(event) => {
              setInputValue(event.target.value);
              setInputTouched(true);
              setInputValid(event.target.value?.trim() == board.title);
            }}
            onBlur={() => {
              setInputTouched(true);
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && (inputTouched || inputValid)) {
                // pressed enter
                handleSubmit();
              }
            }}
          />
        </div>
      </div>

      <div className={crmDialogTheme.buttonContainer}>
        <button className={crmDialogTheme.buttonClose} onClick={close}>
          {t('general.cancel')}
        </button>

        <div>
          <button
            className={crmDialogTheme.buttonDelete}
            disabled={!inputTouched || !inputValid}
            onClick={() => {
              handleSubmit();
            }}>
            {t('general.delete')}
          </button>
        </div>
      </div>
    </div>
  );
}

function BoardDeletionStepLoading() {
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Scooter />
      </div>

      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          {t('crm.modals.delete-board-loading')}
        </Dialog.Title>
        <p className={crmDialogTheme.subtext}>{t('fun-facts.swe-smallest-property')}</p>
      </div>
    </div>
  );
}

function BoardDeletionStepError({ close, object }: { close: any; object: any }) {
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Error />
      </div>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.errorTitle}>
          {t('errors.something-went-wrong')}
        </Dialog.Title>
      </div>

      <div className={crmDialogTheme.buttonContainer}>
        <button
          className={crmDialogTheme.buttonClose}
          onClick={() => {
            close();
            !object?.boardId && (window.location.href = `/crm/overview`);
          }}>
          {t('general.cancel')}
        </button>
      </div>
    </div>
  );
}

function BoardDeletionStepDone({ close, board }: { close: any; board: any }) {
  const router = useRouter();
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Success />
      </div>

      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          <Trans i18nKey="crm.modals.deleted-board-title" boardName={board.title}>
            Tavlan{' '}
            <span className="text-bobo-prop dark:text-dark-prop">{{ boardName: board.title }}</span>{' '}
            är raderad.
          </Trans>
        </Dialog.Title>
      </div>
      <div className={crmDialogTheme.buttonContainer}>
        <button
          className={crmDialogTheme.buttonClose}
          onClick={async () => {
            // nav with reload
            close();
            router.push('/crm/overview');
          }}>
          {t('general.close')}
        </button>
      </div>
    </div>
  );
}

/**
 * dialog to confirm deletion of property from board & list
 */
export default function CrmBoardDialogDelete({
  initStep = 1,
  object,
  onClose
}: {
  initStep?: number;
  onClose: () => any;
  object?: any;
}) {
  const [step, setStep] = useState(initStep);

  // need to refresh if closed modal during or after the API request
  function closeAndNotify() {
    if (object?.onDone) {
      object.onDone();
    } else {
      onClose();
    }
  }

  let mod: JSX.Element = <div />;

  switch (step) {
    case stepNames.initial:
      mod = <BoardDeletionStep1 setStep={setStep} close={onClose} board={object?.board} />;
      break;

    case stepNames.loading:
      mod = <BoardDeletionStepLoading />;
      break;

    case stepNames.error:
      mod = <BoardDeletionStepError close={closeAndNotify} object={object} />;
      break;

    case stepNames.done:
      mod = <BoardDeletionStepDone close={closeAndNotify} board={object?.board} />;
      break;
  }

  return (
    <>
      <button onClick={closeAndNotify} className={crmDialogTheme.closeContainerX}>
        <XMarkIcon className="h-5 w-5" />
      </button>
      {mod}
    </>
  );
}
