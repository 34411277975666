import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { TaxCategory, TaxTypes, ValueCategoryTypes } from 'shared/dist/types';

import { TableWithExportHeader } from '@/src/widgets/tableWithExport';

export const taxCategories: TaxCategory[] = [
  {
    title: TaxTypes.HOUSINGNEW,
    value: 0
  },
  {
    title: TaxTypes.HOUSING16TO20,
    value: 0.0015
  },
  {
    title: TaxTypes.HOUSINGOLD,
    value: 0.003
  },
  {
    title: TaxTypes.COMMERCIAL,
    value: 0.01
  },
  {
    title: TaxTypes.INDUSTRY,
    value: 0.005
  },
  {
    title: TaxTypes.SPECIAL,
    value: 0
  }
];

export const valueCategories = {
  [ValueCategoryTypes.YIELD]: {
    title: ValueCategoryTypes.YIELD,
    sliderMin: 0,
    sliderMax: 15.0,
    sliderStep: 0.01,
    subcost: false,
    suffix: '%'
  },
  [ValueCategoryTypes.RENT]: {
    title: ValueCategoryTypes.RENT,
    sliderMin: 0,
    sliderMax: 12000,
    sliderStep: 1,
    subcost: false,
    suffix: 'general.kr-per-square-m-suffix'
  },
  [ValueCategoryTypes.VACANCY]: {
    title: ValueCategoryTypes.VACANCY,
    sliderMin: 0,
    sliderMax: 100,
    sliderStep: 0.5,
    subcost: false,
    suffix: '%'
  },
  [ValueCategoryTypes.COSTS]: {
    title: ValueCategoryTypes.COSTS,
    sliderMin: 0,
    sliderMax: 1000,
    sliderStep: 1,
    subcost: false,
    suffix: 'general.kr-per-square-m-suffix'
  },
  [ValueCategoryTypes.ADMIN]: {
    title: ValueCategoryTypes.ADMIN,
    sliderMin: 0,
    sliderMax: 250,
    sliderStep: 1,
    subcost: true,
    suffix: 'general.kr-per-square-m-suffix'
  },
  [ValueCategoryTypes.INSURANCE]: {
    title: ValueCategoryTypes.INSURANCE,
    sliderMin: 0,
    sliderMax: 250,
    sliderStep: 1,
    subcost: true,
    suffix: 'general.kr-per-square-m-suffix'
  },
  [ValueCategoryTypes.HEAT]: {
    title: ValueCategoryTypes.HEAT,
    sliderMin: 0,
    sliderMax: 250,
    sliderStep: 1,
    subcost: true,
    suffix: 'general.kr-per-square-m-suffix'
  },
  [ValueCategoryTypes.POWER]: {
    title: ValueCategoryTypes.POWER,
    sliderMin: 0,
    sliderMax: 250,
    sliderStep: 1,
    subcost: true,
    suffix: 'general.kr-per-square-m-suffix'
  },
  [ValueCategoryTypes.WATER]: {
    title: ValueCategoryTypes.WATER,
    sliderMin: 0,
    sliderMax: 250,
    sliderStep: 1,
    subcost: true,
    suffix: 'general.kr-per-square-m-suffix'
  },
  [ValueCategoryTypes.SERVICING]: {
    title: ValueCategoryTypes.SERVICING,
    sliderMin: 0,
    sliderMax: 250,
    sliderStep: 1,
    subcost: true,
    suffix: 'general.kr-per-square-m-suffix'
  },
  [ValueCategoryTypes.TRASH]: {
    title: ValueCategoryTypes.TRASH,
    sliderMin: 0,
    sliderMax: 250,
    sliderStep: 1,
    subcost: true,
    suffix: 'general.kr-per-square-m-suffix'
  },
  [ValueCategoryTypes.MAINTENANCE]: {
    title: ValueCategoryTypes.MAINTENANCE,
    sliderMin: 0,
    sliderMax: 1000,
    sliderStep: 1,
    subcost: false,
    suffix: 'general.kr-per-square-m-suffix'
  },
  [ValueCategoryTypes.OTHER]: {
    title: ValueCategoryTypes.OTHER,
    sliderMin: 0,
    sliderMax: 250,
    sliderStep: 1,
    subcost: true,
    suffix: 'general.kr-per-square-m-suffix'
  }
};

export const SpinnerSlider = styled(Slider)({
  color: 'rgba(41, 145, 132, 1.0)',
  height: 6,
  '& .MuiSlider-track': {
    border: 'none'
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit'
    },
    '&::before': {
      display: 'none'
    }
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.0,
    fontSize: 10,
    background: 'unset',
    padding: 8,
    width: 'auto',
    height: 'auto',
    borderRadius: '10%',
    backgroundColor: 'rgba(41, 145, 132, 1.0)',
    transformOrigin: 'bottom left'
  }
});

export const headerCellStyle =
  'p-2 font-medium flex items-center justify-center border-b border-gray-200 text-center dark:border-dark-morefaded';

export const rowCellStyle =
  'p-2 font-normal flex flex-col items-center justify-center space-x-1 text-mini 2xl:text-xs text-center min-h-[55px]';

export const excelHeaders: TableWithExportHeader[] = [
  {
    key: 'row',
    label: ''
  },
  {
    key: 'area',
    label: 'Area (㎡)'
  },
  {
    key: 'rentGross',
    label: 'Bruttohyra (tkr)'
  },
  {
    key: 'vacancy',
    label: 'Vakanser (%)'
  },
  {
    key: 'rentNet',
    label: 'Nettohyra (tkr)'
  },
  {
    key: 'costs',
    label: 'Drift (tkr)'
  },
  {
    key: 'maintenance',
    label: 'Underhåll (tkr)'
  },
  {
    key: 'tax',
    label: 'Fastighetsskatt (tkr)'
  },
  {
    key: 'operatingNetSqm',
    label: 'Driftnetto/㎡ (kr/㎡)'
  },
  {
    key: 'operatingNet',
    label: 'Driftnetto (tkr)'
  },
  {
    key: 'yield',
    label: 'Avkastning/yield (%)'
  },
  {
    key: 'valueTotalSqm',
    label: 'Uppskattat värde/㎡ (kr/㎡)'
  },
  {
    key: 'valueTotal',
    label: 'Uppskattat värde (tkr)'
  }
];
