import { FeatureCollection } from '@turf/helpers';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { GotoFeature } from 'shared/dist/types';
import { PlanningData } from 'shared/dist/types/urban-development';

import { geojsonLayerStyles } from '@/src/styles/geojson-layer-styles';

import { getCouncilZoningPlans } from '../lib/user-client-wrapper';
import { useSlideIn } from './slide-in-context';

interface MapContextProps {
  map: google.maps.Map | null;
  mapStyles: google.maps.MapTypeStyle[];
  toggleDefaultLayer: (featureType: string, elementType: string, isVisible: boolean) => void;
  setLayerVisibility: (layerId: string, isVisible: boolean) => void;
  onLoad: (mapInstance: google.maps.Map) => void;
  onUnmount: () => void;
  resetMap: () => void;
}

const MapContext = createContext<MapContextProps | undefined>(undefined);

interface MapProviderProps {
  children: ReactNode;
}

const initialMapStyles: google.maps.MapTypeStyle[] = [];
const SWEDEN_BOUNDS = {
  north: 69.06,
  south: 55.23,
  west: 10.86,
  east: 24.16
};

export const MapProvider = ({ children }: MapProviderProps) => {
  const mapRef = useRef<google.maps.Map | null>(null); // Use ref for the map instance
  const [mapStyles, setMapStyles] = useState<google.maps.MapTypeStyle[]>(initialMapStyles);
  const activeRegionRef = useRef<any>(null);
  const activeZPRef = useRef<any>(null);
  const regionMarkersRef = useRef<google.maps.Marker[]>([]);
  const councilMarkersRef = useRef<google.maps.Marker[]>([]);
  const { t } = useTranslation('common');

  const { setSlideIn } = useSlideIn();

  // Function to merge FeatureCollections and add custom properties
  function mergeFeatureCollections(featureCollections) {
    const mergedFeatures = featureCollections.reduce(
      (acc, tmpObject: PlanningData, index: number) => {
        const featureCollection: FeatureCollection = tmpObject.geometry;

        const updatedFeatures = featureCollection.features.map((feature) => {
          return {
            ...feature,
            id: `${index}`,
            properties: {
              code: `${tmpObject.s3Path}`,
              files: `${tmpObject.documents}`,
              requirements: `${tmpObject.summary?.viktiga_krav}`,
              created: `${tmpObject.summary?.skapandedatum}`,
              legal_date: `${tmpObject.summary?.datum_for_laga_kraft}`,
              allowed_buildings: `${tmpObject.summary?.tillaten_byggnation}`,
              building_height: `${tmpObject.summary?.byggnadshojd_begransningar}`,
              building_types: `${tmpObject.summary?.building_types}`,
              name: `${tmpObject.summary?.titel ?? tmpObject.description}`,
              summary: `${tmpObject.summary?.sammanfattning}`,
              doc_count: `${tmpObject.documents.length}`,
              council: `${tmpObject.council}`,
              layerId: `zp`
            }
          };
        });
        return acc.concat(updatedFeatures);
      },
      []
    );

    return {
      type: 'FeatureCollection',
      features: mergedFeatures
    };
  }

  const onLoad = useCallback((mapInstance: google.maps.Map) => {
    mapRef.current = mapInstance;

    // LEAVE COMMENTED OUT FOR NOW
    // Load "regions" GeoJSON with all region labels visible by default
    // mapInstance.data.loadGeoJson('/geojson/regions-se.json', null, (features) => {
    //   features.forEach((feature) => {
    //     feature.setProperty('layerId', 'regions');
    //     feature.setProperty('visible', true); // Show all regions on load
    //     const name = feature.getProperty('name') as string;
    //     const geometry = feature.getGeometry();

    //     // Add labels for all regions on load
    //     if (geometry && name) {
    //       addRegionLabel(mapInstance, geometry, name);
    //     }
    //   });
    // });

    // // Load "councils" GeoJSON with visibility off by default
    // mapInstance.data.loadGeoJson('/geojson/councils-se.json', null, (features) => {
    //   councilMarkersRef.current = [];
    //   features.forEach((feature) => {
    //     const councilRegionCode = (feature.getProperty('code') as string).substring(0, 2);
    //     const councilName = feature.getProperty('name') as string;

    //     feature.setProperty('layerId', 'councils');
    //     feature.setProperty('visible', false); // Hide councils layer by default

    //     const name = feature.getProperty('name') as string;
    //     const code = feature.getProperty('code') as string;
    //     const geometry = feature.getGeometry();

    //     // Add labels for councils
    //     // if (geometry && name) {
    //     addCouncilLabel(mapInstance, geometry, name, code);
    //     // }
    //   });
    // });

    // Apply styles from geojsonLayerStyles to the GeoJSON layers based on visibility
    mapInstance.data.setStyle((feature) => {
      const layerId = feature.getProperty('layerId') as string;
      const isVisible = feature.getProperty('visible');
      return isVisible !== false ? geojsonLayerStyles[layerId] || {} : { visible: false };
    });

    mapInstance.data.addListener('mouseover', (event) => {
      const feature = event.feature;
      const layerId: string = event.feature.getProperty('layerId');

      //Find the actual feature
      const featureId: string = event.feature.getProperty('code');
      let activeId: string | null = null;
      if (activeZPRef.current && activeZPRef.current.feature) {
        activeId = activeZPRef.current.feature.getProperty('code');
      }

      if (activeId !== featureId) {
        mapInstance.data.overrideStyle(feature, geojsonLayerStyles[`${layerId}_hover`]);
      }
    });

    mapInstance.data.addListener('mouseout', (event) => {
      const feature = event.feature;
      const layerId: string = event.feature.getProperty('layerId');

      //Find the actual feature
      const featureId: string = event.feature.getProperty('code');
      let activeId: string | null = null;
      if (activeZPRef.current && activeZPRef.current.feature) {
        activeId = activeZPRef.current.feature.getProperty('code');
      }

      if (activeId !== featureId) {
        mapInstance.data.overrideStyle(feature, geojsonLayerStyles[layerId]);
      }
    });

    // Attach a click event listener to handle region clicks
    mapInstance.data.addListener('click', (event) => {
      const layerId = event.feature.getProperty('layerId');
      if (layerId === 'regions') {
        const regionName = event.feature.getProperty('name') as string;
        const geometry = event.feature.getGeometry();
        const regionCode = (event.feature.getProperty('code') as string).substring(0, 2);

        activeRegionRef.current = {
          feature: event.feature,
          marker: null
        };

        if (geometry && regionName && regionCode) {
          showSelectedRegionLabel(mapInstance, geometry, regionName, regionCode);
          showCouncilsForRegion(regionCode);
        }
      } else if (layerId === 'councils') {
        const councilName = event.feature.getProperty('name') as string;
        const councilCode = event.feature.getProperty('code') as string;

        if (mapRef.current) {
          mapRef.current.data.forEach((feature) => {
            const layerId = feature.getProperty('layerId');
            const code = feature.getProperty('code');
            const councilRegionCode = ((feature.getProperty('code') as string) || '').substring(
              0,
              2
            );

            if (layerId === 'councils' && councilCode.substring(0, 2) === councilRegionCode) {
              if (councilCode === code) {
                feature.setProperty('visible', false);
              } else {
                feature.setProperty('visible', true);
              }
            }
          });
        }

        getCouncilZoningPlans(councilCode, councilName, t).then((zoningPlans: PlanningData[]) => {
          const mergedFeatureCollection = mergeFeatureCollections(zoningPlans);
          mapInstance.data.addGeoJson(mergedFeatureCollection);

          mapInstance.data.setStyle((feature) => {
            const layerId = feature.getProperty('layerId') as string;
            const isVisible = feature.getProperty('visible');
            return isVisible !== false ? geojsonLayerStyles[layerId] || {} : { visible: false };
          });
        });
      } else if (layerId === 'zp') {
        const name = event.feature.getProperty('name') as string;
        const allowed = event.feature.getProperty('allowed_buildings') as string;
        const height = event.feature.getProperty('building_height') as string;
        const buildingTypes = event.feature.getProperty('building_types') as string;
        const created = event.feature.getProperty('created') as string;
        const docCount = event.feature.getProperty('doc_count') as string;
        const docs = event.feature.getProperty('files') as string;
        const legalDate = event.feature.getProperty('legal_date') as string;
        const requirements = event.feature.getProperty('requirements') as string;
        const summary = event.feature.getProperty('summary') as string;

        const geometry = event.feature.getGeometry();
        const code = event.feature.getProperty('code') as string;
        const council = event.feature.getProperty('council') as string;

        //Reset the blob
        if (activeZPRef.current && activeZPRef.current.marker) {
          activeZPRef.current.marker.setMap(null);
          activeZPRef.current.marker.onRemove();
        }

        const bounds = new google.maps.LatLngBounds();
        geometry.forEachLatLng((latLng) => bounds.extend(latLng));
        const props = {
          name,
          allowed,
          height,
          buildingTypes,
          created,
          docCount,
          docs,
          legalDate,
          requirements,
          summary,
          code,
          council
        };

        activeZPRef.current = {
          feature: event.feature
        };
        mapInstance.data.overrideStyle(event.feature, geojsonLayerStyles[`${layerId}_active`]);

        mapInstance.data.forEach((feature) => {
          if (feature.getProperty('layerId') === 'zp' && feature.getProperty('code') !== code) {
            mapInstance.data.overrideStyle(feature, geojsonLayerStyles[`${layerId}`]);
          }
        });

        setSlideIn({ object: props, type: GotoFeature.ZONINGPLAN });
      }
    });
  }, []);

  const clearRegionLabel = () => {
    if (activeRegionRef.current?.marker) {
      activeRegionRef.current.marker.setMap(null);
      activeRegionRef.current.marker = null;
    }
  };

  const showSelectedRegionLabel = (
    mapInstance: google.maps.Map,
    geometry: google.maps.Data.Geometry,
    name: string,
    code: string
  ) => {
    regionMarkersRef.current.forEach((marker) => marker.setMap(null));
    regionMarkersRef.current = [];

    clearRegionLabel();

    const bounds = new google.maps.LatLngBounds();
    geometry.forEachLatLng((latLng) => bounds.extend(latLng));
    const center = bounds.getCenter();

    const marker = new google.maps.Marker({
      position: center,
      label: {
        text: name,
        color: '#FFFFFF',
        fontSize: '10px',
        fontWeight: 'bold'
      },
      icon: { path: google.maps.SymbolPath.CIRCLE, scale: 0 },
      map: mapInstance
    });
    (marker as any).code = code;
    activeRegionRef.current.marker = marker;

    mapInstance.data.forEach((feature) => {
      if (feature.getProperty('layerId') === 'regions') {
        feature.setProperty('visible', true);
        if (feature.getProperty('code') === code) {
          feature.setProperty('visible', false);
        }
      }
    });
  };

  const showCouncilsForRegion = (regionCode: string) => {
    if (mapRef.current) {
      mapRef.current.data.forEach((feature) => {
        const layerId = feature.getProperty('layerId');
        const councilRegionCode = ((feature.getProperty('code') as string) || '').substring(0, 2);

        if (layerId === 'councils') {
          const isVisible = councilRegionCode === regionCode;
          feature.setProperty('visible', isVisible);
        }
      });

      councilMarkersRef.current.map((marker: any) => {
        const councilRegionCode = ((marker.code as string) || '').substring(0, 2);
        if (regionCode === councilRegionCode && !marker.getVisible()) {
          marker.setVisible(true);
        } else if (regionCode !== councilRegionCode && marker.getVisible()) {
          marker.setVisible(false);
        }
      });
    }
  };

  const resetMap = () => {
    if (mapRef.current) {
      mapRef.current.data.forEach((feature) => {
        const layerId = feature.getProperty('layerId');
        feature.setProperty('visible', layerId === 'regions');
      });

      clearRegionLabel();
      regionMarkersRef.current.forEach((marker) => marker.setMap(mapRef.current));
      mapRef.current.fitBounds(SWEDEN_BOUNDS);
    }
  };

  const onUnmount = useCallback(() => {
    mapRef.current = null;
    regionMarkersRef.current.forEach((marker) => marker.setMap(null));
    clearRegionLabel();
  }, []);

  const toggleDefaultLayer = useCallback(
    (featureType: string, elementType: string, isVisible: boolean) => {
      setMapStyles((prevStyles) => {
        const updatedStyles = prevStyles.filter(
          (style) => style.featureType !== featureType || style.elementType !== elementType
        );

        if (!isVisible) {
          updatedStyles.push({
            featureType,
            elementType,
            stylers: [{ visibility: 'off' }]
          });
        }

        if (mapRef.current) {
          mapRef.current.setOptions({ styles: updatedStyles });
        }

        return updatedStyles;
      });
    },
    []
  );

  const setLayerVisibility = useCallback((layerId: string, isVisible: boolean) => {
    if (mapRef.current) {
      mapRef.current.data.forEach((feature) => {
        if (feature.getProperty('layerId') === layerId) {
          feature.setProperty('visible', isVisible);
        }
      });
    }
  }, []);

  const contextValue = useMemo(
    () => ({
      map: mapRef.current,
      mapStyles,
      toggleDefaultLayer,
      setLayerVisibility,
      onLoad,
      onUnmount,
      resetMap
    }),
    [mapStyles, toggleDefaultLayer, setLayerVisibility, onLoad, onUnmount, resetMap]
  );

  return <MapContext.Provider value={contextValue}>{children}</MapContext.Provider>;
};

export const useMapContext = (): MapContextProps => {
  const context = useContext(MapContext);
  if (!context) {
    throw new Error('useMapContext must be used within a MapProvider');
  }
  return context;
};
