import { LinkIcon, XMarkIcon } from '@heroicons/react/24/solid';
import bbox from '@turf/bbox';
import {
  Feature,
  featureCollection,
  lineString,
  MultiPolygon,
  Point,
  Polygon
} from '@turf/helpers';
import cls from 'classnames';
import Link from 'next/link';
import { isValidElement, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Map, { FullscreenControl, MapboxMap, MapProvider, MapRef } from 'react-map-gl';
import { useGlobal } from 'reactn';
import { FeatureProperties, RiskHandle, RiskObject } from 'shared/dist/types/risk';

import { getLayerColor } from '@/src/lib/colors';
import { createObjectESG } from '@/src/lib/utils/esg-tools';

import SummaryBoard from '../items/climate/climate-score-summary';
import SummaryRCP from '../items/climate/rcp';
import SwitchTablePopup from '../items/widgets/switch-table-popup';

type RiskLayer = {
  tile: string;
  layer: string;
  type: string;
  filter_param: string;
  ids: string[];
  features: Feature<Point>[];
};

export interface LeafObject {
  type?: string;
  key?: string;
  title?: string;
  layers?: string[];
  features?: any[];

  [key: string]: any; // Allow additional properties
}

export default function RiskMapMain({
  geoData,
  object,
  esgData,
  overlays,
  riskView,
  reportingLayers = [],
  slideIn = false,
  isReportScreenshot = false
}: {
  geoData?: any;
  object: any;
  esgData: RiskObject;
  overlays: string[];
  riskView: string;
  reportingLayers: string[];
  slideIn?: boolean;
  isReportScreenshot?: boolean;
}) {
  const map = useRef<MapRef | null>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [showInfoBar, setShowInfoBar] = useState<any | null>(null);
  const [visuals, setVisuals] = useState<any>({});
  const [mapHasBeenMoved, setMapHasBeenMoved] = useState<boolean>(false);

  const [hideSubMenu] = useGlobal<any[]>('hideSubMenu' as never);
  const { t } = useTranslation('common');

  useEffect(() => {
    if (loaded && reportingLayers?.length > 0) {
      const newVisuals = {};
      for (const layer of reportingLayers) {
        newVisuals[layer] = true;
      }

      setVisuals((currentState) => {
        const output = {
          ...currentState
        };
        for (const layer in output) {
          output[layer] = false;
        }

        return {
          ...output,
          ...newVisuals
        };
      });

      // extra steps to enable flooding layers (if required)
      if (map?.current && reportingLayers?.find((x) => x.startsWith('kust_'))) {
        const level = reportingLayers
          ?.find((x) => x.startsWith('kust_'))
          ?.replace('kust_', '') as string;
        const currentMapInst = map.current.getMap();

        const showFloodingLayers = (flooding_layer) => {
          if (map && level !== flooding_layer) {
            currentMapInst.getLayer(`${level}`) && currentMapInst.removeLayer(`${level}`);
            currentMapInst.getLayer(`${level}-line`) && currentMapInst.removeLayer(`${level}-line`);

            if (flooding_layer === `kust_0`) return;

            const sourceName = `bobolaget.flooding-coastal`;

            !currentMapInst.getSource(sourceName) &&
              currentMapInst.addSource(sourceName, {
                type: 'vector',
                maxzoom: 22,
                url: `mapbox://${sourceName}`
              });

            !currentMapInst.getLayer(`${flooding_layer}`) &&
              currentMapInst.addLayer(
                {
                  id: `${flooding_layer}`,
                  type: 'fill',
                  source: `${sourceName}`,
                  'source-layer': `${flooding_layer}`,
                  paint: {
                    'fill-color': getLayerColor(flooding_layer),
                    'fill-opacity': 0.3
                  },
                  layout: {
                    // visibility: 'none'
                  }
                },
                'border'
              );

            !currentMapInst.getLayer(`${flooding_layer}-line`) &&
              currentMapInst.addLayer(
                {
                  id: `${flooding_layer}-line`,
                  type: 'line',
                  source: `${sourceName}`,
                  'source-layer': `${flooding_layer}`,
                  paint: {
                    'line-color': getLayerColor(flooding_layer),
                    'line-opacity': 0.8,
                    'line-width': 1
                  },
                  layout: {
                    // visibility: 'none'
                  }
                },
                'border'
              );
          }
        };

        showFloodingLayers(`kust_${level}`);
      }
    }
  }, [reportingLayers, loaded]);

  useEffect(() => {
    if (loaded && geoData) {
      addBorders(geoData.geometry);
      addRiskLayers();
    }
  }, [loaded, geoData]);

  useEffect(() => {
    if (loaded) {
      setNormalZoomLevel();
    }
  }, [loaded, map?.current, object]);

  useEffect(() => {
    if (visuals && map?.current) {
      const m: MapboxMap = map?.current?.getMap();
      const fullFeatures: any = [];

      Object.keys(visuals).map(function (key) {
        if (key === 'skyfall') {
          m.setLayoutProperty(`skyfall-max-layer`, 'visibility', visuals[key] ? 'visible' : 'none');
          m.setLayoutProperty(
            `skyfall-path-layer`,
            'visibility',
            visuals[key] ? 'visible' : 'none'
          );
          m.setLayoutProperty(
            `skyfall-maxsim-layer`,
            'visibility',
            visuals[key] ? 'visible' : 'none'
          );
        } else if (key === 'heat-raster') {
          m.setLayoutProperty(`${key}`, 'visibility', visuals[key] ? 'visible' : 'none');
        } else {
          if (m.getLayer(key)) {
            m.setLayoutProperty(`${key}`, 'visibility', visuals[key] ? 'visible' : 'none');
            m.setLayoutProperty(`${key}-line`, 'visibility', visuals[key] ? 'visible' : 'none');

            const leafObject: LeafObject | null = findLeafObject([esgData], key);

            if (leafObject) {
              const uuids: any = leafObject?.features
                ?.filter((x) => x.properties.distance || x.properties.distance_from_border)
                ?.filter((x) => x.properties.show_path)
                ?.map((x) => x.properties.uuid as string);

              if (uuids) {
                uuids?.map((x) => {
                  if (x !== 'undefined') {
                    m.setLayoutProperty(
                      `${x}-connector`,
                      // Get the bounding box for this connector layer
                      'visibility',
                      visuals[key] ? 'visible' : 'none'
                    );
                    m.setLayoutProperty(
                      `${x}-connector-label`,
                      'visibility',
                      visuals[key] ? 'visible' : 'none'
                    );
                  }
                });

                // If we have bounds, calculate the overall bounding box
                // Convert linestring features to a FeatureCollection
                if (visuals[key]) {
                  const allFeatures: any = uuids.map((x) => {
                    const source: any = m.getSource(`${x}-connector`);
                    if (source && source._data) {
                      return source._data.features[0]; // Get the lineString feature
                    }
                    return null;
                  });

                  fullFeatures.push(...allFeatures);
                }
              }
            }
          }
        }
      });

      // If we have any features, create a FeatureCollection and fit the map to it
      if (fullFeatures.length > 0 && isReportScreenshot) {
        // Get the property borders if they exist
        const propertySource = m.getSource('property-border');
        if (propertySource) {
          const propertyData = (propertySource as any)._data;
          if (propertyData && propertyData.features) {
            fullFeatures.push(...propertyData.features);
          }
        }
        const featureCollectionBlob = {
          type: 'FeatureCollection',
          features: fullFeatures.filter(Boolean) // Remove any null values
        };

        // Calculate bounds and fit map if there are valid features
        if (featureCollectionBlob.features.length > 0) {
          let boundingBox = bbox(featureCollectionBlob) as number[];

          // Compare bounding boxes and use the larger one
          let objectBBox = object.bbox;

          if (typeof objectBBox === 'string') {
            objectBBox = JSON.parse(objectBBox);
          }

          if (
            objectBBox &&
            (objectBBox[0] < boundingBox[0] ||
              objectBBox[1] < boundingBox[1] ||
              objectBBox[2] > boundingBox[2] ||
              objectBBox[3] > boundingBox[3])
          ) {
            boundingBox = objectBBox;
          }
          try {
            m.fitBounds(
              [
                [boundingBox[0], boundingBox[1]], // southwestern corner
                [boundingBox[2], boundingBox[3]] // northeastern corner
              ],
              {
                padding: { top: 100, bottom: 100, left: 100, right: 100 },
                animate: false
              }
            );
          } catch (e) {
            console.log('Failed to fit bounds to features', e);
          }
        }
      } else {
        setNormalZoomLevel();
      }
    }
  }, [visuals, isReportScreenshot, esgData]);

  const onMapLoad = useCallback(async () => {
    if (!map?.current) {
      throw new Error('map load failed');
    } else {
      const m: MapboxMap = map?.current?.getMap();
      m.setLayoutProperty('road-label', 'visibility', 'none');
      m.setLayoutProperty('poi-label', 'visibility', 'none');

      m.on('click', (e) => {
        const features = m.queryRenderedFeatures(e.point);

        if (features.length > 0) {
          const clickedFeature = features[0];

          if (clickedFeature && clickedFeature.properties && clickedFeature.layer) {
            const source = clickedFeature.layer.source;
            const sourceLayer = clickedFeature.layer['source-layer'];

            let title = ``;
            let subTitle = ``;
            let text: string | JSX.Element = ``;
            let comment = ``;
            let bkl = ``;
            let pollution = ``;
            let other_pollution = ``;
            let sediment = ``;
            let more_sediment = ``;
            let url: string | null = null;
            let showInfoText = false;

            switch (source) {
              case 'bobolaget.ancient': {
                showInfoText = true;
                title = `${clickedFeature.properties.antikv_bed}: ${
                  clickedFeature.properties.namn ? `${clickedFeature.properties.namn} ` : ''
                }(${clickedFeature.properties.lamningtyp})`;
                subTitle = `${
                  clickedFeature.properties.orienter
                    ? `${clickedFeature.properties.orienter}. `
                    : ''
                }${clickedFeature.properties.placering}. (${t('general.status')}: ${
                  clickedFeature.properties.akt_status
                })`;
                text = `${clickedFeature.properties.beskrivn}`;
                url = `${clickedFeature.properties.url}`;
                break;
              }
              case 'bobolaget.flooding-coastal': {
                let level = 1;
                try {
                  level = parseInt(clickedFeature.layer.id?.replace('kust_', ''));
                  showInfoText = true;
                } catch (e) {
                  console.error('Failed to parse flooding layer', e);
                }

                title = `${t('risk.coastal-level')}: ${level / 10}${t(
                  'general.meters-short-suffix'
                )}`;
                subTitle = `${t('general.sources')}: MSB och SMHI`;
                text = `${t('risk.coastal-level-info')}`;
                break;
              }
              case 'bobolaget.toxic': {
                if (clickedFeature && clickedFeature.properties) {
                  const item = esgData?.toxic?.features.find(
                    (x) => x.properties.uuid === `${clickedFeature?.properties?.EBH_ID}`
                  );

                  showInfoText = true;

                  title = `${
                    clickedFeature.properties.RISKKLASS
                      ? t('risk.risk-class', { class: clickedFeature.properties.RISKKLASS })
                      : t('risk.risk-class-missing')
                  } (${t('general.status')}: ${clickedFeature.properties.STATUS})`;
                  subTitle = `${t('general.trade-area')}: ${clickedFeature.properties.P_BRANSCH}.`;
                  text = t('risk.toxic-text');
                  comment = item?.properties?.comment ?? null;
                  bkl = item?.properties?.bkl ?? null;
                  pollution = item?.properties['bransch-specific-pollution'] ?? null;
                  other_pollution = item?.properties['other-related-pollution'] ?? null;
                  sediment = item?.properties['sediment-class'] ?? null;
                  more_sediment = item?.properties['other-related-sediment'] ?? null;
                }
                break;
              }
              case 'bobolaget.skred': {
                showInfoText = true;
                if (sourceLayer === 'jordskred_raviner') {
                  title = `${t('risk.ravine-title', {
                    type: clickedFeature.properties.sl_tx,
                    length: Math.round(clickedFeature.properties.geom_length)
                  })}`;
                  subTitle = ``;
                  text = `${t('risk.atrax.jordskred_raviner')}`;
                } else if (sourceLayer === 'fastmark') {
                  title = `${clickedFeature.properties.fastmark_tx}`;
                  subTitle = `${clickedFeature.properties.jg2_tx} (${t('general.area')} ${t(
                    'units.x-sqm',
                    { sqm: Math.round(clickedFeature.properties.geom_area) }
                  )})`;
                  text = `${t('risk.atrax.fastmark')}`;
                } else {
                  title = `${clickedFeature.properties.aktskre_tx}`;
                  subTitle = `${clickedFeature.properties.metod}`;
                  text = `${t('risk.atrax.skred')}`;
                }
                break;
              }
              case 'bobolaget.erosion': {
                showInfoText = true;
                title = `${clickedFeature.properties.erod_tx}`;
                subTitle = `${clickedFeature.properties.j_enkel_tx} (${clickedFeature.properties.jg2_tx})`;
                text = `${t('risk.atrax.erosion')}`;
                break;
              }
              case 'bobolaget.fire': {
                showInfoText = true;
                title = `${t('risk.fire')} ${t('risk.fire-open')} (${
                  clickedFeature.properties.Klasskod
                })`;
                subTitle = `${clickedFeature.properties.Klassnamn}`;
                text = `${t('risk.atrax.fire')}`;
                break;
              }
              case 'bobolaget.heat-spots': {
                showInfoText = true;
                title = `${t('risk.heat-spots-title', {
                  min: clickedFeature.properties.ContourMin,
                  max: clickedFeature.properties.ContourMax
                })}`;
                subTitle = `${t('risk.atrax.heat-spots-sub')}`;
                text = `${t('risk.atrax.heat-spots-text')}`;
                break;
              }
              case 'bobolaget.axba6w7n': {
                showInfoText = true;
                title = `${t('risk.population-trend')}`;
                subTitle = `${t('general.the-regso')}: ${clickedFeature.properties.regso}`;
                text = t(
                  esgData?.social?.population?.motivation.key,
                  esgData?.social?.population.motivation.options
                );
                break;
              }
              case 'bobolaget.social': {
                if (sourceLayer === `uso_2023`) {
                  showInfoText = true;
                  title = `${clickedFeature.properties.KATEGORI}: ${clickedFeature.properties.NAMN}`;
                  subTitle = `${t('risk.atrax.vulnerable-area-sub-title', {
                    category: clickedFeature.properties.KATEGORI,
                    police: clickedFeature.properties.POLISOMRADE,
                    since: clickedFeature.properties.AKTUALITET_START
                  })}`;
                  text = `${t('risk.atrax.vulnerable-area')}`;
                }
                break;
              }
              case 'bobolaget.nature': {
                showInfoText = true;
                const featureLayer: string = clickedFeature.layer.id;
                title = `${clickedFeature.properties.NAMN} (${clickedFeature.properties.SKYDDSTYP})`;
                subTitle = clickedFeature.properties.FORVALTARE
                  ? `${t('risk.protected-area-subtitle', {
                      area: clickedFeature.properties.AREA_HA,
                      care_of: clickedFeature.properties.FORVALTARE
                    })}`
                  : `${t('risk.protected-area-subtitle-simple', {
                      area: clickedFeature.properties.AREA_HA
                    })}`;
                text = `${t('risk.descriptions.' + featureLayer)}`;

                if (featureLayer === `Forbud_mot_markavvattning`) {
                  title = `${t('risk.dewatering')}`;
                  subTitle = `${clickedFeature.properties.PARAGRAF}`;
                  text = <div>{t('risk.descriptions.dewatering')}</div>;
                }

                if (featureLayer === `sksBiotopskydd`) {
                  title = `${clickedFeature.properties.Biotyp} (${t('risk.bio-area')})`;
                  subTitle = `${t('risk.protected-area-subtitle-simple', {
                    area: clickedFeature.properties.AreaTot
                  })}`;
                  text = (
                    <div>
                      {t('risk.descriptions.bio-area')}
                      <br />
                      <br />
                      <span className="font-demi">
                        <Link target={`_new`} href={`${clickedFeature.properties.Url}`}>
                          {t('general.read-more')}
                        </Link>
                      </span>
                    </div>
                  );
                }
                break;
              }
              case 'bobolaget.water-precense': {
                showInfoText = true;
                title = `${t('risk.water-presence')} ${
                  clickedFeature.properties.NAMN ? `(${clickedFeature.properties.NAMN})` : ``
                }`;
                text = (
                  <div>
                    {t('risk.descriptions.water-presence')}
                    <br />
                    <br />
                    <span className="font-demi">
                      <Link target={`_new`} href={`${clickedFeature.properties.URL_VISS}`}>
                        {t('general.read-more')}
                      </Link>
                    </span>
                  </div>
                );
                break;
              }
              case 'bobolaget.vmi': {
                showInfoText = true;
                title = `${clickedFeature.properties.OBJNAMN} ${
                  clickedFeature.properties.NVKLASS ? `(${clickedFeature.properties.NVKLASS})` : ``
                }`;
                subTitle = `${t('risk.wetlands')}`;
                text = <div>{t('risk.descriptions.wetlands')}</div>;
                break;
              }
              case 'bobolaget.skred-zones': {
                showInfoText = true;
                switch (sourceLayer) {
                  case 'skred-inspire-zon2':
                    title = `${t('risk.landslide-text.zone2')}`;
                    subTitle = `${t('risk.landslide-text.zone2-description')}`;
                    text = `${t('risk.landslide-text.zone2-use')}`;
                    break;
                  case 'skred-inspire-zon3':
                    title = `${t('risk.landslide-text.zone3')}`;
                    subTitle = `${t('risk.landslide-text.zone3-description')}`;
                    text = `${t('risk.landslide-text.zone3-use')}`;
                    break;
                  default:
                    title = `${t('risk.landslide-text.zone1')}`;
                    subTitle = `${t('risk.landslide-text.zone1-description')}`;
                    text = `${t('risk.landslide-text.zone1-use')}`;
                    break;
                }
                break;
              }
              case 'bobolaget.flooding-lake-river': {
                showInfoText = true;
                const localRiskObj: RiskHandle = createObjectESG(esgData);
                switch (sourceLayer) {
                  case 'InspireMSB_hot_risk_bhf':
                    {
                      title = localRiskObj.flooding_inland_threat_bhf
                        ? t(
                            localRiskObj.flooding_inland_threat_bhf.title.key,
                            localRiskObj.flooding_inland_threat_bhf.title.options
                          )
                        : '';
                      subTitle = localRiskObj.flooding_inland_threat_bhf
                        ? t(
                            localRiskObj.flooding_inland_threat_bhf.motivation.key,
                            localRiskObj.flooding_inland_threat_bhf.motivation.options
                          )
                        : '';
                      text = `${t('risk.flooding.inland-threat-bhf-description')}`;
                    }
                    break;
                  case 'InspireMSB_hot_risk_50':
                    {
                      title = localRiskObj.flooding_inland_threat_50
                        ? t(
                            localRiskObj.flooding_inland_threat_50.title.key,
                            localRiskObj.flooding_inland_threat_50.title.options
                          )
                        : '';
                      subTitle = localRiskObj.flooding_inland_threat_50
                        ? t(
                            localRiskObj.flooding_inland_threat_50.motivation.key,
                            localRiskObj.flooding_inland_threat_50.motivation.options
                          )
                        : '';
                      text = `${t('risk.flooding.inland-threat-50-description')}`;
                    }
                    break;
                  case 'InspireMSB_hot_risk_100':
                    {
                      title = localRiskObj.flooding_inland_threat_100
                        ? t(
                            localRiskObj.flooding_inland_threat_100.title.key,
                            localRiskObj.flooding_inland_threat_100.title.options
                          )
                        : '';
                      subTitle = localRiskObj.flooding_inland_threat_100
                        ? t(
                            localRiskObj.flooding_inland_threat_100.motivation.key,
                            localRiskObj.flooding_inland_threat_100.motivation.options
                          )
                        : '';
                      text = `${t('risk.flooding.inland-threat-100-description')}`;
                    }
                    break;
                  case '100arsflode':
                    {
                      title = localRiskObj.flooding_inland_100
                        ? t(
                            localRiskObj.flooding_inland_100.title.key,
                            localRiskObj.flooding_inland_100.title.options
                          )
                        : '';
                      subTitle = localRiskObj.flooding_inland_100
                        ? t(
                            localRiskObj.flooding_inland_100.motivation.key,
                            localRiskObj.flooding_inland_100.motivation.options
                          )
                        : '';
                      text = `${t('risk.flooding.inland-100-description')}`;
                    }
                    break;
                  case '200arsflode':
                    {
                      title = localRiskObj.flooding_inland_200
                        ? t(
                            localRiskObj.flooding_inland_200.title.key,
                            localRiskObj.flooding_inland_200.title.options
                          )
                        : '';
                      subTitle = localRiskObj.flooding_inland_200
                        ? t(
                            localRiskObj.flooding_inland_200.motivation.key,
                            localRiskObj.flooding_inland_200.motivation.options
                          )
                        : '';
                      text = `${t('risk.flooding.inland-200-description')}`;
                    }
                    break;
                  case 'BHF':
                    {
                      title = localRiskObj.flooding_inland_bhf
                        ? t(
                            localRiskObj.flooding_inland_bhf.title.key,
                            localRiskObj.flooding_inland_bhf.title.options
                          )
                        : '';
                      subTitle = localRiskObj.flooding_inland_bhf
                        ? t(
                            localRiskObj.flooding_inland_bhf.motivation.key,
                            localRiskObj.flooding_inland_bhf.motivation.options
                          )
                        : '';
                      text = `${t('risk.flooding.inland-bhf-description')}`;
                    }
                    break;
                  default:
                    title = `${t('general.missing-data')}`;
                    subTitle = ``;
                    text = ``;
                    break;
                }
                break;
              }
            }

            if (!isReportScreenshot) {
              if (showInfoText) {
                setShowInfoBar({
                  title,
                  subTitle,
                  text,
                  url,
                  comment,
                  bkl,
                  pollution,
                  other_pollution,
                  sediment,
                  more_sediment
                });
              } else {
                setShowInfoBar(null);
              }
            }
          }
        }
      });

      setLoaded(true);
    }
  }, [esgData, isReportScreenshot, t]);

  const style = `mapbox://styles/bobolaget/cm083s0bg00l001qt02n1c6nr`;

  const viewState = {
    maxZoom: 22,
    minZoom: 1,
    pitch: 0,
    bearing: 0,
    longitude: reportingLayers.length == 0 && object.lng ? object.lng - 0.008 : object.lng,
    latitude: object?.lat,
    zoom: 15
  };

  const addBorders = (geometry) => {
    if (map?.current) {
      const m: MapboxMap = map?.current?.getMap();
      if (m && !m?.getSource('property-border')) {
        const updatedFeatures = geometry.features.map((x: Feature<Polygon | MultiPolygon>) => {
          return {
            ...x,
            properties: {
              layer: `prop_area`,
              type: `Fastighet`,
              uuid: `${object.uuid}`,
              title: `${object.authority_nickname}`
            }
          };
        });

        m.addSource('property-border', {
          type: 'geojson',
          data: featureCollection(updatedFeatures)
        });
      }

      if (!m?.getLayer('border')) {
        m.addLayer({
          id: 'border',
          type: 'line',
          source: 'property-border',
          paint: {
            'line-color': '#FF0000',
            'line-width': 3
          }
        });
      }
      if (!m?.getLayer('area')) {
        m.addLayer({
          id: 'area',
          type: 'fill',
          source: 'property-border',
          paint: {
            'fill-color': '#FF0000',
            'fill-opacity': defaultMainOpacity
          }
        });
      }
    }
  };

  const addRiskLayers = () => {
    if (map?.current) {
      const m: MapboxMap = map?.current?.getMap();

      const layers: RiskLayer[] = [];

      geoData?.risk_layers
        ?.filter((x) => !x?.hide)
        ?.map((layer) => {
          const props: FeatureProperties = layer.properties;

          if (m && !m?.getSource(`${props.tile}`)) {
            m.addSource(`${props?.tile}`, {
              type: 'vector',
              maxzoom: 22,
              url: `mapbox://${props.tile}`
            });
          }

          const existingLayer: RiskLayer | undefined = layers.find(
            (x: any) => x.layer === props.layer
          );
          if (existingLayer) {
            const tmp: RiskLayer = existingLayer;
            if (props.uuid && !props.uuid.endsWith('default-uuid')) {
              tmp.ids.push(props.uuid);
            }
            tmp.features.push(layer);
          } else {
            layers.push({
              tile: props.tile,
              layer: props.layer,
              type: props.geometry_type,
              filter_param: props.filter_param,
              ids: props.uuid && !props.uuid.endsWith('default-uuid') ? [props.uuid] : [],
              features: [layer]
            });
          }
        });

      layers.map((l) => {
        const filter = l.ids?.length > 0 ? ['in', `${l.filter_param}`, ...l.ids] : ['all'];

        if (!m?.getLayer(`${l?.layer}`)) {
          switch (l?.type) {
            case 'polygon': {
              m.addLayer(
                {
                  id: `${l?.layer}`,
                  type: 'fill',
                  source: `${l?.tile}`,
                  'source-layer': `${l?.layer}`,
                  paint: {
                    'fill-color': `${getLayerColor(l?.layer)}`,
                    'fill-opacity': 0.45
                  },
                  filter,
                  layout: {
                    visibility: 'none'
                  }
                },
                'border'
              );
              m.addLayer(
                {
                  id: `${l?.layer}-line`,
                  type: 'line',
                  source: `${l?.tile}`,
                  'source-layer': `${l?.layer}`,
                  paint: {
                    'line-color': `${getLayerColor(l?.layer)}`,
                    'line-width': 4,
                    'line-dasharray': [2, 2]
                  },
                  filter,
                  layout: {
                    visibility: 'none'
                  }
                },
                'border'
              );

              l.features.map((feature: Feature<Point>) => {
                if (feature && feature.properties) {
                  const uniqueLayerId = `${feature.properties.uuid}-connector`;
                  if (m && !m?.getSource(uniqueLayerId)) {
                    if (feature.properties.distance_from_border > 0) {
                      const connectorLine = lineString(
                        [
                          feature.geometry.coordinates,
                          [feature.properties.intersection_lng, feature.properties.intersection_lat]
                        ],
                        {
                          name: feature.properties.title,
                          distance: feature.properties.distance_from_border
                        }
                      );

                      m.addSource(uniqueLayerId, {
                        type: 'geojson',
                        maxzoom: 22,
                        data: featureCollection([connectorLine])
                      });

                      m.addLayer({
                        id: `${uniqueLayerId}`,
                        type: 'line',
                        source: `${uniqueLayerId}`,
                        paint: {
                          'line-color': '#fff', //`${getLayerColor(layer?.layer)}`,
                          'line-width': 2,
                          'line-dasharray': [2, 2]
                        },
                        layout: {
                          visibility: 'none',
                          'line-cap': 'round'
                        }
                      });

                      m.addLayer({
                        id: `${uniqueLayerId}-label`,
                        type: 'symbol',
                        minzoom: 8,
                        maxzoom: 22,
                        source: `${uniqueLayerId}`,
                        layout: {
                          'text-field': `${feature.properties.type} (${
                            feature.properties.distance_from_border
                          }${t('general.meters-short-suffix')})`,
                          'text-font': ['literal', ['DIN Pro Medium']],
                          'text-offset': [0, 0.7],
                          'text-size': 13,
                          // 'text-ignore-placement': true,
                          'symbol-placement': 'line-center',
                          visibility: 'none'
                        },
                        paint: {
                          'text-halo-width': 2,
                          'text-halo-color': `rgba(0, 0, 0, .75)`,
                          'text-color': '#FFFFFF'
                        }
                      });
                    }
                  }
                }
              });

              break;
            }
            case 'linestring': {
              m.addLayer(
                {
                  id: `${l?.layer}`,
                  type: 'line',
                  source: `${l?.tile}`,
                  'source-layer': `${l?.layer}`,
                  paint: {
                    // 'line-color': `${l?.color}`,
                    'line-color': `#ff0000`,
                    'line-width': 3
                  },
                  layout: {
                    visibility: 'none'
                  }
                },
                'border'
              );
              break;
            }
            case 'point': {
              m.addLayer({
                id: `${l?.layer}`,
                type: 'circle',
                source: `${l?.tile}`,
                'source-layer': `${l?.layer}`,
                paint: {
                  'circle-color': `${getLayerColor(l?.layer)}`,
                  'circle-radius': {
                    base: 1.75,
                    stops: [
                      [12, 2],
                      [22, 180]
                    ]
                  }
                },
                layout: {
                  visibility: 'none'
                }
              });
              m.addLayer(
                {
                  id: `${l?.layer}-label`,
                  type: 'symbol',
                  source: `${l?.tile}`,
                  'source-layer': `${l?.layer}`,
                  layout: {
                    'text-field': '1',
                    'text-font': ['literal', ['DIN Pro Regular']],
                    'text-size': 12,
                    visibility: 'none'
                  },
                  paint: {
                    'text-color': '#FFFFFF'
                  }
                },
                `${l?.layer}`
              );
              break;
            }
          }
        }
      });
    }
  };

  const findLeafObject = (data: any[], searchString: string): LeafObject | null => {
    function searchInDict(d: any): LeafObject | null {
      if (d.layers && d.layers.includes(searchString)) {
        return d as LeafObject;
      }

      for (const key in d) {
        if (typeof d[key] === 'object' && d[key] !== null) {
          const result = searchInDict(d[key]);
          if (result) {
            return result;
          }
        }
      }
      return null;
    }

    for (const entry of data) {
      const result = searchInDict(entry);
      if (result) {
        return result;
      }
    }
    return null;
  };

  const defaultMainOpacity: any = [
    'interpolate',
    ['linear'],
    ['zoom'],
    10,
    0.4, // At zoom level 10, fill-opacity is 0.3
    14,
    0.3, // At zoom level 14, fill-opacity is 0.2
    16,
    0 // At zoom level 18, fill-opacity is 0
  ];

  const setNormalZoomLevel = () => {
    // if user has moved the map, don't move it back
    if (mapHasBeenMoved) {
      return;
    }
    if (map?.current && object) {
      const padding = 150;
      let boundingBox = object.bbox;

      if (typeof boundingBox === 'string') {
        boundingBox = JSON.parse(boundingBox);
      }

      const m: MapboxMap = map?.current?.getMap();
      try {
        m.fitBounds(
          [
            [boundingBox[0], boundingBox[1]], // [lng, lat] - southwestern corner of the bounds
            [boundingBox[2], boundingBox[3]] // [lng, lat] - northeastern corner of the bounds
          ],
          {
            padding: { top: padding, bottom: padding, left: padding, right: padding },
            animate: isReportScreenshot ? false : true
          }
        );
      } catch (e) {
        console.error('Mapbox unhappy with bounding box', e);
      }
    }
  };

  return (
    <MapProvider>
      {showInfoBar && (
        <div
          className={cls(
            `absolute top-[84px] right-0 rounded-b-md min-h-[100px] bg-gray-50 dark:bg-dark-faded dark:border-dark-morefaded p-5 border border-t-0 border-gray-300 z-50 shadow-2xl`,
            {
              'lg:w-[calc(100vw-500px-51px-30px)] xl:w-[calc(100vw-600px-35px-2px)]': hideSubMenu,
              'lg:w-[calc(100vw-490px-51px-271px)] xl:w-[calc(100vw-650px-51px-165px)]':
                !hideSubMenu,
              'top-[0px]': slideIn
            }
          )}>
          <button
            onClick={() => {
              setShowInfoBar(null);
            }}
            className="absolute right-5 top-2 bg-white text-bobo-orange rounded-full p-1">
            <XMarkIcon className="w-5 h-5" />
          </button>
          <div className="text-sm font-demi">{showInfoBar?.title}</div>
          <div className="text-xs font-medium leading-[14px]">{showInfoBar?.subTitle}</div>
          {isValidElement(showInfoBar?.text) ? (
            <div className="text-[11px] mt-2 leading-[12.5px] font-normal">{showInfoBar?.text}</div>
          ) : (
            <div
              className="text-[11px] mt-2 leading-[12.5px] font-normal"
              dangerouslySetInnerHTML={{
                __html: showInfoBar?.text.replaceAll('||', '</strong>').replaceAll('|', '<strong>')
              }}
            />
          )}
          {showInfoBar?.url ? (
            <div className="text-xs font-medium mt-2 leading-[14px] flex space-x-1 text-bobo-prop">
              <LinkIcon className="w-4 h-4 " />
              <Link target={'_new'} href={showInfoBar?.url}>
                <div>{t('general.more-info')}</div>
              </Link>
            </div>
          ) : null}
          {showInfoBar?.comment && (
            <div className="space-y-0 text-xs font-normal mt-2 leading-[14px]">
              <div className="font-demi">{t('general.trade-area-comment')}</div>
              <div>{showInfoBar?.comment}</div>
            </div>
          )}
          {showInfoBar?.pollution && (
            <div className="space-y-0 text-xs font-normal mt-2 leading-[14px]">
              <div className="font-demi">{t('general.trade-area-pollution')}</div>
              <div>{showInfoBar?.pollution}</div>
            </div>
          )}
          {showInfoBar?.other_pollution && (
            <div className="space-y-0 text-xs font-normal mt-2 leading-[14px]">
              <div className="font-demi">{t('general.other-pollution')}</div>
              <div>{showInfoBar?.other_pollution}</div>
            </div>
          )}
          {showInfoBar?.sediment && (
            <div className="space-y-0 text-xs font-normal mt-2 leading-[14px]">
              <div className="font-demi">{t('general.sediment')}</div>
              <div>{showInfoBar?.sediment}</div>
            </div>
          )}
          {showInfoBar?.more_sediment && (
            <div className="space-y-0 text-xs font-normal mt-2 leading-[14px]">
              <div className="font-demi">{t('general.more_sediment')}</div>
              <div>{showInfoBar?.more_sediment}</div>
            </div>
          )}
        </div>
      )}
      <div className="w-full h-full flex flex-col md:flex-row">
        <div
          id="page1"
          className={cls(
            `!w-full border-r dark:border-dark-faded shadow inset-0 bg-white dark:bg-dark-primary overflow-hidden overflow-y-auto transition-opacity duration-500 z-20 opacity-0 flex-grow`,
            {
              hidden: reportingLayers.length > 0,
              'h-[calc(100dvh)]': slideIn,
              'opacity-100 md:px-4 xl:px-9 lg:max-w-[530px] xl:max-w-[580px]':
                riskView === 'summary',
              'opacity-100 px-4 xl:px-9 lg:max-w-[750px] xl:max-w-[850px]': riskView === 'rcp'
            }
          )}>
          {riskView === 'summary' && (
            <SummaryBoard
              esgData={esgData}
              visuals={visuals}
              setVisuals={setVisuals}
              hideTable={reportingLayers.length > 0}
            />
          )}

          {riskView === 'rcp' && <SummaryRCP geoData={geoData} esgData={esgData} />}
        </div>
        {(overlays.includes(`risk-overview`) || overlays.includes(`overview-slideout`)) && <></>}
        <div className={cls(`absolute bottom-[150px] right-0 z-20`)}>
          {reportingLayers.length == 0 && <SwitchTablePopup />}
        </div>
        <Map
          initialViewState={viewState}
          id="riskMap"
          ref={map}
          onLoad={onMapLoad}
          onMoveEnd={(e) => {
            // checks that event is triggerd by user interaction
            if (e.originalEvent) {
              setMapHasBeenMoved(true);
            }
          }}
          onZoomEnd={(e) => {
            if (e.originalEvent) {
              setMapHasBeenMoved(true);
            }
          }}
          style={{ width: '100%', height: '100%' }}
          mapboxAccessToken={`pk.eyJ1IjoiYm9ib2xhZ2V0IiwiYSI6ImNrZmNmZmtlajE4NG0zNm81NjB5ZTgwOGwifQ.AqhIm23pQPp2jjs_Q2Ca6Q`}
          mapStyle={style}>
          <FullscreenControl style={{ zIndex: 1000 }} />
        </Map>
      </div>
    </MapProvider>
  );
}
