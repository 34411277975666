import moment from 'moment';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { ButtonType } from 'shared/dist/types';
import {
  CashflowAdjustableAssumptions,
  CashflowInflation,
  CashflowSelectedIndexAssumption,
  CashflowValuationAsset,
  CashflowValuationModel,
  IndexType
} from 'shared/dist/types/cashflow/cashflow-model';
import { v4 as uuidv4 } from 'uuid';

import {
  cashflowTheme,
  DEFAULT_INFLATION_UUID,
  MAX_MODEL_DURATION
} from '@/src/components/cashflow-valuation/constants/cashflow-theme';
import ModalWarning from '@/src/layout/global-dialog/items/modal-warning';
import Button from '@/src/widgets/buttons';

import { handleMaxInputLimit } from '../../cashflow-valuation/constants/calculator-functions';
import SelectAssetsForInflation from '../../cashflow-valuation/items/buttons/select-assets-for-inflation';

const CashflowInflationChart = dynamic(
  () => import('@/src/widgets/charts/cashflow-inflation-chart'),
  {
    ssr: false
  }
);

export type NewAssetOption = {
  name: string;
  uuid: string;
  adjustableAssumptions: CashflowAdjustableAssumptions;
};

export default function CashflowInflationTemplate({
  object,
  close,
  inAssetModal = false
}: {
  object;
  close: () => void;
  inAssetModal?: boolean;
}) {
  const { t } = useTranslation();
  const { model, inflation, save, setSelectedModel, indexType, newAsset } = object;

  const startYear = +moment.unix(model.modelLength.startDate).format('YYYY');
  const endYear = +moment.unix(model.modelLength.endDate).format('YYYY');
  const isDefaultInflation: boolean = inflation && inflation.uuid === DEFAULT_INFLATION_UUID;

  const formatInflationValuesToPercent = (inflation: CashflowInflation) => {
    const newValues = inflation.values.map((v) => {
      return { ...v, value: v.value * 100 };
    });
    return { ...inflation, values: newValues };
  };

  const formatInflationToDecimal = (inflation: CashflowInflation) => {
    const newValues = inflation.values.map((v) => {
      return { ...v, value: v.value / 100 };
    });
    return { ...inflation, values: newValues };
  };

  const [selectedInflation, setSelectedInflation] = useState<CashflowInflation>(
    inflation
      ? formatInflationValuesToPercent(inflation)
      : {
          uuid: uuidv4(),
          name: t('property.cashflow-valuation.inflation.custom-scenario', { context: indexType }),
          values: Array.from({ length: MAX_MODEL_DURATION }, (_, i) => ({
            year: startYear + i,
            value: 2
          }))
        }
  );
  const [loading, setLoading] = useState(false);
  const [validInput, setValidInput] = useState(false);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [selectedAssetUuids, setSelectedAssetUuids] = useState<string[]>([]);

  const getSelectedAssetUuids = (): string[] => {
    const assetUuids: string[] = model.assets
      .filter((asset) => {
        const indexUuid =
          indexType === IndexType.RENT
            ? asset.adjustableAssumptions.rentIndex.uuid
            : asset.adjustableAssumptions.costIndex.uuid;

        return indexUuid === selectedInflation.uuid;
      })
      .map((asset) => asset.uuid);
    return assetUuids;
  };

  useEffect(() => {
    const assetUuids: string[] = getSelectedAssetUuids();
    setSelectedAssetUuids(assetUuids);
  }, [model]);

  useEffect(() => {
    const isValid =
      selectedInflation.name.trim() !== '' &&
      selectedInflation.values.every((v) => v.year > 0 && v.value >= 0);
    setValidInput(isValid);
  }, [selectedInflation]);

  const updateAssetsInflations = (
    assets: CashflowValuationAsset[],
    selectedAssetUuids: string[]
  ) => {
    if (assets.length === 0) {
      return assets;
    }

    const updatedAssets = assets.map((asset) => {
      const assetIndexUuid: string =
        indexType === IndexType.RENT
          ? asset.adjustableAssumptions.rentIndex.uuid
          : asset.adjustableAssumptions.costIndex.uuid;

      const isAdded: boolean =
        assetIndexUuid !== selectedInflation.uuid &&
        selectedAssetUuids.some((assetUuid) => assetUuid === asset.uuid);
      const isRemoved: boolean =
        assetIndexUuid === selectedInflation.uuid &&
        selectedAssetUuids.every((assetUuid) => assetUuid !== asset.uuid);

      if (!isAdded && !isRemoved) {
        return asset;
      }

      const oldInflation: CashflowSelectedIndexAssumption =
        indexType === IndexType.RENT
          ? asset.adjustableAssumptions.rentIndex
          : asset.adjustableAssumptions.costIndex;

      let fallBackInflationUuid = DEFAULT_INFLATION_UUID;

      if (isDefaultInflation) {
        const indexAssumptions =
          indexType === IndexType.RENT ? model.rentIndexes : model.costIndexes;
        fallBackInflationUuid = indexAssumptions.find(
          (assumption) => assumption.uuid !== DEFAULT_INFLATION_UUID
        )?.uuid;
      }

      const updatedInflation = {
        ...oldInflation,
        uuid: isAdded ? selectedInflation.uuid : fallBackInflationUuid
      };

      return {
        ...asset,
        adjustableAssumptions: {
          ...asset.adjustableAssumptions,
          rentIndex:
            indexType === IndexType.RENT ? updatedInflation : asset.adjustableAssumptions.rentIndex,
          costIndex:
            indexType === IndexType.RENT ? asset.adjustableAssumptions.costIndex : updatedInflation
        }
      };
    });
    return updatedAssets;
  };

  const handleSave = async () => {
    setLoading(true);

    const oldInflations: CashflowInflation[] =
      indexType === IndexType.RENT ? model.rentIndexes : model.costIndexes;

    const updatedInflations: CashflowInflation[] = inflation
      ? oldInflations.map((inf) =>
          inf.uuid === inflation.uuid ? formatInflationToDecimal(selectedInflation) : inf
        )
      : [...oldInflations, formatInflationToDecimal(selectedInflation)];

    const updatedAssets = updateAssetsInflations(model.assets, selectedAssetUuids);

    const updatedModel: CashflowValuationModel = {
      ...model,
      updatedAt: moment().unix(),
      assets: updatedAssets,
      rentIndexes: indexType === IndexType.RENT ? updatedInflations : model.rentIndexes,
      costIndexes: indexType === IndexType.RENT ? model.costIndexes : updatedInflations
    };
    if (setSelectedModel) {
      setSelectedModel(updatedModel);
    }
    if (inAssetModal) {
      save(updatedModel, selectedInflation, indexType, selectedAssetUuids);
    } else {
      await save(updatedModel);
    }
    toast.success(
      t('property.cashflow-valuation.inflation.scenario-saved', { context: indexType })
    );
    setLoading(false);
    close();
  };

  const handleUpdateValue = (index: number, key: 'year' | 'value', value: number) => {
    const updatedValues = [...selectedInflation.values];
    updatedValues[index] = { ...updatedValues[index], [key]: value };
    setSelectedInflation({ ...selectedInflation, values: updatedValues });
  };

  const handleDelete = () => {
    const hasAssetsWithSelectedInflation: boolean = model.assets.some(
      (asset) =>
        (indexType === IndexType.RENT
          ? asset.adjustableAssumptions.rentIndex.uuid
          : asset.adjustableAssumptions.costIndex.uuid) === inflation.uuid
    );

    if (hasAssetsWithSelectedInflation && !showWarning) {
      setShowWarning(true);
      return;
    }

    const oldInflations: CashflowInflation[] =
      indexType === IndexType.RENT ? model.rentIndexes : model.costIndexes;
    const updatedInflations = oldInflations.filter((inf) => inf.uuid !== inflation?.uuid);
    const updatedModel = {
      ...model,
      rentIndexes: indexType === IndexType.RENT ? updatedInflations : model.rentIndexes,
      costIndexes: indexType === IndexType.RENT ? model.costIndexes : updatedInflations
    };

    if (hasAssetsWithSelectedInflation) {
      const newAssets: CashflowValuationAsset[] = model.assets.map((asset) => {
        const oldInflation: CashflowSelectedIndexAssumption =
          indexType === IndexType.RENT
            ? asset.adjustableAssumptions.rentIndex
            : asset.adjustableAssumptions.costIndex;

        if (oldInflation.uuid !== inflation.uuid) {
          return asset;
        }

        const newSelectedIndexAssumption = { ...oldInflation, uuid: DEFAULT_INFLATION_UUID };

        return {
          ...asset,
          adjustableAssumptions: {
            ...asset.adjustableAssumptions,
            rentIndex:
              indexType === IndexType.RENT
                ? newSelectedIndexAssumption
                : asset.adjustableAssumptions.rentIndex,
            costIndex:
              indexType === IndexType.RENT
                ? asset.adjustableAssumptions.costIndex
                : newSelectedIndexAssumption
          }
        };
      });
      updatedModel.assets = newAssets;
    }

    if (setSelectedModel) {
      setSelectedModel(updatedModel);
    }
    if (inAssetModal) {
      save(updatedModel, selectedInflation, indexType, selectedAssetUuids);
    } else {
      save(updatedModel);
    }
    close();
    toast.success(t('property.cashflow-valuation.inflation.deleted', { context: indexType }));
  };

  return (
    <div className="z-30 max-h-[calc(100vh-100px)] overflow-y-scroll -mx-6 px-6">
      {showWarning && (
        <ModalWarning
          text={t('property.cashflow-valuation.inflation.delete-warning', { context: indexType })}
          setShowWarning={setShowWarning}
          button={{ text: t('general.delete'), function: handleDelete }}
        />
      )}

      <h3 className="text-base font-bold text-gray-900 dark:text-white sm:text-lg uppercase px-4">
        {t(`property.cashflow-valuation.inflation.${inflation ? 'modify' : 'new'}`, {
          context: indexType
        })}
      </h3>

      <div className="text-xs text-gray-500 px-4">
        {isDefaultInflation
          ? t('property.cashflow-valuation.inflation.modify-default-desc')
          : t('property.cashflow-valuation.inflation.modify-desc', { context: indexType })}
      </div>

      <div className="mt-5">
        <div className="h-48 dark:border-dark-morefaded ">
          <CashflowInflationChart
            data={{
              ...selectedInflation,
              indexType: indexType,
              values: selectedInflation.values
                .filter((value) => {
                  return value.year >= startYear && value.year <= endYear;
                })
                .map((v) => {
                  return { ...v, value: v.value / 100 };
                })
            }}
          />
        </div>

        <div className="flex flex-col gap-4 text-xs pt-4 px-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col gap-1">
              <span className="font-demi text-sm"> {t('general.name')}</span>

              <div className={cashflowTheme.inputContainer}>
                <input
                  type="text"
                  disabled={isDefaultInflation}
                  value={selectedInflation.name}
                  placeholder={t('property.cashflow-valuation.inflation.name')}
                  autoComplete="off"
                  onChange={(e) =>
                    setSelectedInflation({ ...selectedInflation, name: e.target.value })
                  }
                  className={cashflowTheme.inputField}
                />
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <span className="font-demi text-sm">
                {t('property.cashflow-valuation.assumptions.header')}
              </span>

              <SelectAssetsForInflation
                model={model}
                selectedAssetUuids={selectedAssetUuids}
                setSelectedAssetUuids={setSelectedAssetUuids}
                newAsset={newAsset}
                indexType={indexType}
              />
            </div>
          </div>

          <div className="flex flex-col gap-1">
            <h4 className="font-demi text-sm">
              {t('property.cashflow-valuation.inflation.values')}
            </h4>
            {selectedInflation.values
              .filter((value) => {
                return value.year >= startYear && value.year <= endYear;
              })
              .map((val, index) => (
                <div key={index} className="flex gap-2 items-center w-full">
                  <span className="font-demi w-[30px]">{val.year}</span>
                  <div className={cashflowTheme.inputContainer}>
                    <input
                      type="number"
                      disabled={isDefaultInflation}
                      value={+(val.value ?? 0).toFixed(2).toString()} // Return a string with 2 decimal places
                      onChange={
                        (e) => handleUpdateValue(index, 'value', parseFloat(e.target.value) || 0) // Safely parse the value
                      }
                      onInput={(e) => handleMaxInputLimit(e, 100)}
                      className={cashflowTheme.inputField}
                    />
                    <div className="text-gray-400">%</div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="flex justify-between items-end mt-6 px-4">
        <Button type={ButtonType.CANCEL} onClick={close}>
          {t('general.cancel')}
        </Button>

        <div className="flex gap-2">
          {inflation && (
            <Button
              type={ButtonType.DELETE}
              disabled={loading || isDefaultInflation}
              onClick={handleDelete}>
              {t('general.delete')}
            </Button>
          )}

          <Button disabled={loading || !validInput} onClick={handleSave}>
            {t('general.save')}
          </Button>
        </div>
      </div>
    </div>
  );
}
