import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { useGlobal } from 'reactn';
import { ButtonType } from 'shared/dist/types';

import { Error, Success } from '@/src/animations';
import { crmDialogTheme } from '@/src/components/dialogs/crm/dialog-theme';
import { createCRM2Team } from '@/src/lib/crm2-wrapper';
import Button from '@/src/widgets/buttons';

enum stepNames {
  addTeam = 1,
  error = 2,
  done = 3
}

function AddTeamStep({ close, setStep }: { close: () => void; setStep: any }) {
  const { t } = useTranslation('common');

  const router = useRouter();

  const [addTeamName, setAddTeamName] = useState<string>('');

  const [crmRefresh, setCrmRefresh] = useGlobal<{ crmRefresh: number }>('crmRefresh' as never);

  return (
    <div className={crmDialogTheme.container}>
      <div className="text-lg font-medium text-gray-900 dark:text-gray-300">
        {t('crm.modals.teams.create-team')}
      </div>

      <div className="flex w-[90%] gap-2 h-full items-center my-20">
        <span className="leading-4 text-sm text-right text-gray-500 dark:text-gray-300">
          {t('crm.modals.teams.name-of-team')}
        </span>

        <input
          value={addTeamName}
          onChange={(event) => {
            setAddTeamName(event.target.value);
          }}
          autoComplete="off"
          className="p-2 py-1 w-4/5 bg-card-graypanel border-2 dark:bg-dark-lessfaded dark:placeholder:text-gray-400 rounded dark:border-dark-morefaded border-bobo-burgundy bg-red-200 focus:bg-card-graypanel"
        />
      </div>

      <div className="flex flex-grow justify-between w-full items-end mt-4">
        <Button type={ButtonType.CANCEL} onClick={close}>
          {t('general.cancel')}
        </Button>

        <Button
          type={ButtonType.ADD}
          onClick={() => {
            createCRM2Team(addTeamName)
              .then((team) => {
                setStep(stepNames.done);
                router.push(`/teams/${team.uuid}`);
              })
              .then(() => {
                setTimeout(() => {
                  setCrmRefresh((crmRefresh || 0) + 1);
                  close();
                }, 3000);
              });
          }}>
          {t('general.save')}
        </Button>
      </div>
    </div>
  );
}

function ErrorStep({ close }: { close: () => void }) {
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.icon}>
        <Error />
      </div>

      <div className="flex flex-col w-full text-center items-center">
        <Dialog.Title as="h3" className="text-red-700 w-3/4 leading-6">
          <p className={crmDialogTheme.subtext}>{t('errors.try-again')}</p>
        </Dialog.Title>
      </div>

      <div className={crmDialogTheme.buttonContainer}>
        <Button type={ButtonType.CANCEL} onClick={close}>
          {t('general.close')}
        </Button>
      </div>
    </div>
  );
}

function CreateTeamStepDone() {
  const { t } = useTranslation('common');

  return (
    <div>
      <div className={crmDialogTheme.icon}>
        <Success />
      </div>

      <div className="text-lg font-medium text-gray-900 dark:text-gray-300">
        {t('crm.modals.teams.team-creation-message')}
      </div>
    </div>
  );
}

export default function CRM2CreateTeamsDialog({
  close,
  object
}: {
  close: () => void;
  object: any;
}) {
  const [step, setStep] = useState(stepNames.addTeam);

  let mod: JSX.Element = <div />;

  switch (step) {
    case stepNames.addTeam:
      mod = <AddTeamStep close={close} setStep={setStep} />;
      break;

    case stepNames.done:
      mod = <CreateTeamStepDone />;
      break;

    case stepNames.error:
      mod = <ErrorStep close={close} />;
      break;
  }

  return (
    <>
      <button onClick={close} className={crmDialogTheme.closeContainerX}>
        <XMarkIcon className="h-5 w-5" />
      </button>
      {mod}
    </>
  );
}
