/* This example requires Tailwind CSS v2.0+ */
import cls from 'classnames';

import InfoButton from '../widgets/info-button';

export default function RowWrapper({
  title,
  children,
  helpTextId,
  head,
  theme = 'roomy',
  list,
  mapOdd,
  clickable,
  flip = false
}: {
  title: JSX.Element | string;
  children?: JSX.Element | JSX.Element[] | string;
  helpTextId?: string;
  head?: boolean;
  theme?: string;
  list?: boolean;
  mapOdd?: boolean;
  clickable?: any;
  flip?: boolean;
}) {
  return clickable ? (
    <div
      className={cls(
        `flex w-full p-2 text-[12px] ${
          mapOdd ? 'bg-gray-50 dark:bg-dark-faded' : ''
        } even:bg-white dark:even:bg-dark-faded border-b border-gray-100 text-gray-700 dark:border-dark-morefaded dark:text-white`,
        { 'hover:bg-bobo-proplight dark:hover:bg-dark-primary cursor-pointer': clickable },
        { 'p-3 py-1.5': theme === 'roomy' },
        { 'px-1.5 py-3': theme === '2.0' }
      )}
      role={clickable ? 'button' : ''}
      onKeyDown={clickable}
      onClick={clickable}>
      {' '}
      <div
        className={cls(
          'flex items-start justify-between',
          { 'w-full': list },
          { 'w-1/2 xl:w-2/6': !list }
        )}>
        <div>
          {list ? (
            <div className="w-2 h-2 bg-bobo-prop dark:bg-dark-prop rounded-full mr-2" />
          ) : null}
          {title}
        </div>

        {helpTextId ? (
          <div className="ml-3">
            <InfoButton small helpTextId={helpTextId} />
          </div>
        ) : null}
      </div>
      {!list ? (
        <div
          className={cls(
            'w-3/5 flex-1 text-right justify-end flex items-start',
            { 'font-[500]': head },
            { 'font-normal': !head }
          )}>
          {children}
        </div>
      ) : null}
    </div>
  ) : (
    <div
      className={cls(
        'flex w-full text-[12px] even:bg-gray-50 dark:even:bg-dark-faded border-b last:border-0 border-gray-100 dark:border-dark-morefaded text-gray-700 dark:text-white',
        { 'bg-gray-50 dark:bg-dark-faded': mapOdd },
        { 'hover:bg-bobo-proplight cursor-pointer': clickable },
        { 'p-3 py-1.5': theme === 'roomy' },
        { 'px-3 py-1.5': theme === '2.0' }
      )}>
      {' '}
      <div
        className={cls(
          'flex items-start justify-between',
          { '!w-3/5': flip },
          { 'w-full pl-4 !justify-start !items-center': list },
          { 'sm:w-3/6 xl:2/6': !list && !flip }
        )}>
        {list ? <div className="w-2 h-2 bg-bobo-prop dark:bg-dark-prop rounded-full mr-2" /> : null}

        {title}

        {helpTextId ? (
          <div className="ml-3">
            <InfoButton small helpTextId={helpTextId} />
          </div>
        ) : null}
      </div>
      {!list ? (
        <div
          className={cls(
            'flex-1 text-right justify-end flex items-start',
            { 'font-[500]': head },
            { 'font-normal': !head },
            { '!w-2/5': flip },
            { 'w-3/5': !flip }
          )}>
          {flip}
          {children}
        </div>
      ) : null}
    </div>
  );
}
