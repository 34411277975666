import cls from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Building } from 'src/animations';

import CompanyStructureSlideIn from './items/companyStructureSlideIn';

export default function Portfolio({
  companyData,
  corporateStructure,
  setSlideinContent
}: {
  companyData: any;
  corporateStructure?: any;
  setSlideinContent?: any;
}) {
  const { t } = useTranslation('common');

  const [loading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]);

  const onClickRef = useRef((index: number) => {
    //empty
  });

  useEffect(() => {
    onClickRef.current = (index: number) => {
      const row = { ...tableData[index] };

      row.open = !row.open;

      const data = tableData.concat();

      data[index] = row;

      if (index + 1 < tableData.length) {
        for (let rowIndex = index + 1; rowIndex < tableData.length; rowIndex++) {
          const nextRow = { ...tableData[rowIndex] };

          if (row.level < nextRow.level) {
            nextRow.folded = row.open;
            nextRow.open = row.open;
            data[rowIndex] = nextRow;
          } else {
            break;
          }
        }
      }
      setTableData(data);
    };
  }, [tableData]);

  useEffect(() => {
    let currentCompanyChildren: any[] | null = null;
    corporateStructure.map((x: any) => {
      if (x.actor_id === companyData.actor.actor_id) {
        currentCompanyChildren = x.child_actors;
      }
    });

    const data = corporateStructure.map((structure, index) => {
      let fold_indicator = false;

      if (index > 0) {
        const previousRow = corporateStructure[index - 1];

        if (previousRow.level < structure?.level) {
          fold_indicator = true;
        }
      }

      let fold_button = false;
      let lastOnBranch = false;

      if (index < corporateStructure.length - 1) {
        const nextRow = corporateStructure[index + 1];

        if (nextRow.level > structure.level) {
          fold_button = true;
        }
        if (nextRow && nextRow.level !== structure.level) {
          lastOnBranch = true;
        }
      }

      if (index === corporateStructure.length - 1) {
        lastOnBranch = true;
      }

      let isInCurrentTree = true;
      let isMainPath = false;

      if (
        structure.actor_id === companyData.actor.actor_id ||
        structure?.child_actors?.filter((x) => x === companyData.actor.actor_id).length > 0 ||
        (structure.obj_type !== 'company' &&
          structure?.parent_actor_id === companyData?.actor?.actor_id) ||
        (structure.obj_type !== 'company' &&
          currentCompanyChildren &&
          currentCompanyChildren?.filter((x) => x === structure?.parent_actor_id).length > 0) ||
        (currentCompanyChildren &&
          currentCompanyChildren?.filter((x) => x === structure?.actor_id).length > 0)
      ) {
        isInCurrentTree = true;
        isMainPath = true;
      }

      let folded = false;
      let open = true;

      if (
        (!fold_button || (!isInCurrentTree && structure.level >= companyData.actor.level)) &&
        companyData.actor?.actor_id !== structure.parent_actor_id &&
        structure.actor_id !== companyData.actor.actor_id
      ) {
        folded = true;
      }

      if (isMainPath) {
        open = false;
      }

      if (
        structure.obj_type !== 'company' &&
        currentCompanyChildren &&
        currentCompanyChildren?.filter((x) => x === structure?.parent_actor_id).length > 0
      ) {
        folded = false;
      }

      if (structure.obj_type === 'company') {
        return {
          name: structure.name,
          org_nr: structure.actor_id,
          number_of_properties: structure.direct_prop_count,
          total_boa: structure.total_boa,
          total_loa: structure.total_loa,
          total_rental_income: -9999,
          open: open,
          folded: folded,
          lastOnBranch: lastOnBranch,
          level: structure.level,
          isInCurrentTree: isInCurrentTree,
          fold_indicator: fold_indicator,
          fold_button: fold_button,
          structure: structure,
          isMainPath,
          onClick: () => {
            onClickRef.current(index);
          },
          extraClasses:
            structure.actor_id == companyData.actor.actor_id
              ? '!text-bobo-orange dark:!text-bobo-lightgreen'
              : '',
          isCurrentActor: structure.actor_id == companyData.actor.actor_id
        };
      } else {
        return {
          name: structure.authority_nickname,
          number_of_properties: '',
          total_boa: structure.boa || structure.total_boa,
          total_loa: structure.loa || structure.total_loa,
          total_rental_income: structure.total_boa_rent + structure.total_loa_rent,
          open: false,
          folded,
          level: structure.level,
          lastOnBranch: lastOnBranch,
          fold_indicator: fold_indicator,
          fold_button: fold_button,
          structure: structure,
          isMainPath
        };
      }
    });
    setTableData(data);
  }, [corporateStructure]);

  return (
    <div className={cls('p-0')}>
      {loading ? (
        <div className="h-full min-h-[200px] flex flex-col items-center">
          <div className="text-2xl mb-8">{t('general.fetching-data')}</div>

          <Building />

          <div className="my-8 w-3/4 m-auto text-center">{t('company.long-wait-warning')}</div>
        </div>
      ) : (
        loading && <Building />
      )}

      {!loading && (
        <div className="w-[100%]">
          <div className="w-[1000px] xl:w-[100%]">
            <CompanyStructureSlideIn
              companyStructure={tableData}
              corparateStructure={corporateStructure}
              setSlideinContent={setSlideinContent}
            />
          </div>
        </div>
      )}
    </div>
  );
}
