import { Popover, Transition } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import cls from 'classnames';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCategoryName } from 'shared/dist/constants';
import { RadioButtonOptions, ValueCategoryTypes } from 'shared/dist/types';
import { CashflowLease } from 'shared/dist/types/cashflow/cashflow-model';

import { useOutsideAlerter } from '@/src/hooks/use-outside-alerter';
import { FLCustom } from '@/src/widgets/numbers';

export function ForumRadioButton({
  valueCategory,
  changeValue,
  selectedTab,
  tabValues,
  disabled,
  radioButtonStyle,
  defaultRowValues,
  rentRoll
}: {
  valueCategory: any;
  changeValue: any;
  selectedTab: string;
  tabValues: any;
  disabled: boolean;
  radioButtonStyle: string;
  defaultRowValues: any;
  rentRoll?: CashflowLease[];
}) {
  const [showCategories, setShowCategories] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<any>(
    defaultRowValues.forum.find((type) => type.propType === tabValues.selectedForumCategory)
  );
  const { t } = useTranslation('common');
  const showCategoriesRef = useRef(null);

  useOutsideAlerter(showCategoriesRef, setShowCategories);

  useEffect(() => {
    if (tabValues.selectedForumCategory !== selectedType?.propType) {
      setSelectedType(
        defaultRowValues.forum.find((type) => type.propType === tabValues.selectedForumCategory)
      );
    }
  }, [tabValues.selectedForumCategory]);

  return (
    <div
      className={cls('flex items-center justify-center w-full gap-2 border-gray-100', {
        'grayscale opacity-30 py-0.5':
          !selectedType ||
          (selectedType?.value === 0 && valueCategory.title !== ValueCategoryTypes.VACANCY)
      })}>
      <div className="w-1/6 h-full flex items-center justify-center">
        <input
          type={'radio'}
          name={`${valueCategory.title}-select`}
          disabled={
            !selectedType ||
            (selectedType?.value === 0 && valueCategory.title !== ValueCategoryTypes.VACANCY) ||
            disabled ||
            rentRoll !== undefined
          }
          value={RadioButtonOptions.FORUM}
          checked={tabValues.radioButton === RadioButtonOptions.FORUM && !rentRoll}
          onChange={() => {
            if (selectedType) {
              changeValue(
                selectedTab,
                valueCategory.title,
                selectedType.value,
                RadioButtonOptions.FORUM
              );
            }
          }}
          className={radioButtonStyle}
        />
      </div>

      <div className="flex flex-col w-5/6 justify-start text-start leading-3">
        {selectedType ? (
          <>
            <Popover className="relative">
              <Popover.Button
                onClick={(e) => {
                  e.stopPropagation();
                  setShowCategories(!showCategories);
                }}
                disabled={
                  disabled ||
                  defaultRowValues?.forum?.filter((rentCategory) => rentCategory.value > 0)
                    .length === 0
                }
                className={cls(
                  'flex gap-1 items-center transition-colors duration-200 justify-start',
                  {
                    'text-bobo-prop hover:text-bobo-prophover dark:text-dark-prop hover:dark:text-dark-prophover':
                      !disabled
                  },
                  { 'text-gray-400': disabled }
                )}>
                <div className="whitespace-nowrap text-start w-[110px]">
                  {RadioButtonOptions.FORUM} ({t(getCategoryName(selectedType?.propType))})
                </div>

                <ChevronRightIcon
                  className={cls('w-4 h-4 transition-transform duration-200', {
                    'rotate-90': showCategories
                  })}
                />
              </Popover.Button>

              <Transition
                as={Fragment}
                show={showCategories}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 -translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 -translate-y-1">
                <Popover.Panel
                  ref={showCategoriesRef}
                  className="absolute transform translate-x-0 z-50">
                  <div className="bg-white dark:text-white dark:bg-dark-morefaded text-bobo-black rounded-md shadow-md flex flex-col w-[110px] overflow-hidden">
                    {defaultRowValues.forum.map((propType, idx) => {
                      return (
                        <div
                          key={idx}
                          className={cls(
                            'py-3 px-5 transition-colors duration-200 group',
                            propType.propType === selectedType.propType &&
                              (propType.value > 0 ||
                                valueCategory.title === ValueCategoryTypes.VACANCY)
                              ? 'bg-bobo-prop dark:bg-dark-prop text-white'
                              : 'hover:bg-bobo-proplight dark:hover:bg-dark-prophover'
                          )}>
                          <button
                            onClick={() => {
                              if (propType) {
                                setSelectedType(propType);
                                if (!rentRoll) {
                                  changeValue(
                                    selectedTab,
                                    valueCategory.title,
                                    propType.value,
                                    RadioButtonOptions.FORUM,
                                    undefined,
                                    undefined,
                                    propType.propType
                                  );
                                }
                                setShowCategories(false);
                              }
                            }}
                            disabled={
                              propType.value === 0 &&
                              valueCategory.title !== ValueCategoryTypes.VACANCY
                            }
                            className={cls(
                              'disabled:text-gray-300 w-full h-full',
                              propType.propType === selectedType.propType &&
                                (propType.value > 0 ||
                                  valueCategory.title === ValueCategoryTypes.VACANCY)
                                ? 'text-white'
                                : 'text-bobo-prop dark:text-white group-hover:text-bobo-prophover dark:group-hover:text-white'
                            )}>
                            {t(getCategoryName(propType.propType))}
                            {propType.value > 0 ||
                            valueCategory.title === ValueCategoryTypes.VACANCY ? (
                              <div
                                className={cls(
                                  'whitespace-nowrap',
                                  propType.propType === selectedType?.propType
                                    ? 'text-gray-200'
                                    : 'text-gray-400'
                                )}>
                                <FLCustom value={propType.value} suffix={t(valueCategory.suffix)} />
                              </div>
                            ) : null}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>

            {selectedType?.value === 0 &&
            valueCategory.title !== ValueCategoryTypes.VACANCY ? null : (
              <div className="text-gray-400">
                <FLCustom value={selectedType?.value} suffix={t(valueCategory.suffix)} />
              </div>
            )}
          </>
        ) : (
          <div className="text-gray-400">
            <div className="whitespace-nowrap text-start w-[110px]">{RadioButtonOptions.FORUM}</div>
          </div>
        )}
      </div>
    </div>
  );
}
