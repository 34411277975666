import { useTranslation } from 'next-i18next';
import NumberFormat from 'react-number-format';
import { filterOutExtremeRents } from 'shared/dist/constants';
import { PropertyTypeGroup } from 'shared/dist/types';
import {
  getBuildingUsed,
  getLatestYearOfRentalData,
  getRentIntervals
} from 'shared/dist/util/rent';

import RowWrapper from '@/src/layout/row-wrapper';
import { getWarehouseNeighbours } from '@/src/lib/rent-calculator';
import { compare_index } from '@/src/lib/utils/sort';
import ShowRent, { FLSqm } from '@/src/widgets/numbers';

import RentTaxTableWrapper from '../wrappers/rent-tax-table-wrapper';

export default function RentTaxTableWarehouse({
  object,
  rent,
  pt,
  forceCategory = null,
  slideIn = false
}: {
  object: any | null;
  rent: any | null;
  pt: any | null;
  forceCategory?: PropertyTypeGroup | null;
  slideIn?: boolean;
}) {
  const { t } = useTranslation('common');

  const valueYear: string[] = [];
  pt?.taxUnits?.map((tu: any) => {
    if (!valueYear?.includes(tu?.value_year) && tu?.value_year) {
      valueYear.push(tu?.value_year);
    }
  });

  const loa = rent?.v2?.warehouse_details?.sqm ?? object?.warehouse?.loa ?? null;
  const list: number[] = object?.warehouse?.nearbyWarehouses
    ?.filter((a) => filterOutExtremeRents(a, PropertyTypeGroup.WAREHOUSE))
    ?.map((a) => a?.warehouse_rent);

  const {
    interval,
    mean
  }: { interval: [number, number]; extremeRentValue: boolean; mean: number } = getRentIntervals(
    list ?? [],
    rent?.v2?.warehouse_rent
  );

  const rentNeighbours: any[] = getWarehouseNeighbours(
    rent?.warehouse?.nearbyWarehouses ?? [],
    object,
    interval
  );

  const { buildingUsed } = getBuildingUsed(object?.propTax, PropertyTypeGroup.WAREHOUSE);

  const chartData: any[] = rentNeighbours
    ?.filter((a) => a?.warehouse_rent > 0)
    ?.map((a) => {
      if (object?.uuid === a?.uuid) {
        return {
          authority_nickname: a?.authority_nickname || object?.authority_nickname,
          lat: a?.lat,
          lng: a?.lng,
          county: a?.county,
          uuid: a?.uuid,
          owner: a?.tax_owners?.map((o) => o?.name),
          loa: a?.gross_leasable_area,
          boa: null,
          rent: null,
          selected: Math.round(a?.warehouse_rent),
          index: Math.round(a?.warehouse_rent)
        };
      } else {
        return {
          authority_nickname: a?.authority_nickname || object?.authority_nickname,
          lat: a?.lat,
          lng: a?.lng,
          county: a?.county,
          uuid: a?.uuid,
          owner: a?.tax_owners?.map((o) => o?.name),
          loa: a?.gross_leasable_area,
          boa: null,
          rent: Math.round(a?.warehouse_rent),
          selected: null,
          index: Math.round(a?.warehouse_rent)
        };
      }
    });

  if (chartData && chartData?.length > 0) {
    chartData.sort(compare_index);
  }

  const { lastYearOfWarehouseRent } = getLatestYearOfRentalData(rent?.v2);

  return (
    <RentTaxTableWrapper
      pt={pt}
      forceCategory={forceCategory}
      slideIn={slideIn}
      object={object}
      primaryType={PropertyTypeGroup.WAREHOUSE}
      chartData={chartData}
      mean={mean}>
      <RowWrapper
        title={`${t('property.rent.estimated-rent-short', {
          rentType: t('property.rent-avg')
        })} ${lastYearOfWarehouseRent > 0 ? lastYearOfWarehouseRent : ''}`}
        helpTextId={'rentAvgWarehouse'}>
        <ShowRent
          value={Math.round(rent?.v2?.warehouse_rent)}
          suffix={t('general.kr-per-square-m-suffix')}
          propType={PropertyTypeGroup.WAREHOUSE}
        />
      </RowWrapper>

      {rent?.warehouse?.warehouse_details?.purposeSqm &&
      rent?.warehouse?.warehouse_details?.purposePercentage ? (
        <RowWrapper helpTextId={'rentLoa'} title={t('property.warehouse-area')}>
          <NumberFormat
            value={Math.round(rent?.warehouse?.warehouse_details?.purposePercentage * 100)}
            displayType={'text'}
            thousandSeparator={` `}
            suffix={`%`}
          />
          {rent?.warehouse?.warehouse_details?.purposeSqm !== loa ? (
            <span className="ml-1">
              (
              <NumberFormat
                value={Math.round(rent?.warehouse?.warehouse_details?.purposeSqm)}
                displayType={'text'}
                thousandSeparator={` `}
                suffix={t('general.square-meters')}
                prefix={''}
              />
              )
            </span>
          ) : (
            <div />
          )}
        </RowWrapper>
      ) : null}

      <RowWrapper
        helpTextId={'rentInterval'}
        title={t('general.rent-interval', { rentType: t('property.rent-avg') })}>
        {interval[0] > 0 && interval[1] > 0 ? (
          <>
            <NumberFormat
              value={Math.round(interval[0])}
              displayType={'text'}
              thousandSeparator={` `}
              suffix={t('general.kr-suffix')}
              prefix={t('general.sek-prefix')}
            />
            &nbsp; - &nbsp;
            <NumberFormat
              value={Math.round(interval[1])}
              displayType={'text'}
              thousandSeparator={` `}
              suffix={t('general.kr-suffix')}
              prefix={t('general.sek-prefix')}
            />{' '}
            <span className="ml-1">
              (
              <NumberFormat
                value={Math.round(mean)}
                displayType={'text'}
                thousandSeparator={` `}
                suffix={t('general.kr-suffix')}
                prefix={t('general.sek-prefix')}
              />
              )
            </span>
          </>
        ) : (
          t('property.no-rent-data-for-property')
        )}
      </RowWrapper>

      <RowWrapper helpTextId={'rentLoa'} title={t('general.loa')}>
        <NumberFormat
          value={Math.round(loa)}
          displayType={'text'}
          thousandSeparator={` `}
          suffix={t('general.square-meters')}
        />
      </RowWrapper>

      {buildingUsed && buildingUsed?.length > 1 ? <div /> : null}

      {buildingUsed && buildingUsed?.length > 1 && (
        <div className="divide-dashed divide-y pb-2 px-3 pl-8 pt-2 even:bg-gray-50 dark:even:bg-dark-faded dark:text-white text-xs text-gray-600">
          {buildingUsed?.map((br, idx) => {
            return (
              <div key={idx} className="flex py-1 first:pt-0 justify-between">
                <div className="flex items-start justify-start">
                  <div className="bg-bobo-prop dark:bg-dark-prop mt-[3px] rounded-full w-2 h-2 mr-1" />
                  <div className="capitalize mr-6 leading-[13px]">{br?.unit_type}</div>
                </div>
                <div className="text-right flex-1 min-w-[60px]">
                  <FLSqm value={br?.area} />
                </div>
              </div>
            );
          })}
        </div>
      )}
      <RowWrapper helpTextId={'rentValueYearArea'} title={t('property.value-year')}>
        {valueYear?.join(', ')}
      </RowWrapper>
    </RentTaxTableWrapper>
  );
}
