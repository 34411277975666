import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useGlobal } from 'reactn';
import { Actor, ButtonType } from 'shared/dist/types';
import { Error, Success } from 'src/animations';

import { crmDialogTheme } from '@/src/components/dialogs/crm/dialog-theme';
import { createCRM2Team } from '@/src/lib/crm2-wrapper';
import Button from '@/src/widgets/buttons';

enum stepNames {
  addTeam = 1,
  error = 2,
  done = 3
}

function AddTeamStep({ close, setStep }: { close: () => void; setStep: any }) {
  const { t } = useTranslation('common');

  const router = useRouter();

  const [actorId, setActorId] = useState<string>('');
  const [memberEmail, setMemberEmail] = useState<string>('');
  const [memberName, setMemberName] = useState<string>('');

  const [crmRefresh, setCrmRefresh] = useGlobal<{ crmRefresh: number }>('crmRefresh' as never);

  const inviteUser = async (data: any) => {
    try {
      const toastId: string = toast.loading(t('toasts.save-actor-settings-loading'));

      const actorObject: Actor = await fetch(`/api/actor/getActorFromReference`, {
        method: 'POST',
        body: JSON.stringify({
          reference: actorId
        })
      })
        .then((res) => {
          return res?.json();
        })
        .then((json) => {
          return json;
        });

      const body = JSON.stringify({
        newData: { user: { name: memberName, email: memberEmail, role: 'administrator' } },
        orgData: null,
        customerActor: actorObject,
        permission: { isAdmin: true },
        deactivate: false,
        activate: true
      });

      await fetch(`/api/customer/addUpdateMember`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body
      })
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          if (json.result == 'invite-user-error-duplicate-email') {
            toast.error(t('toasts.invite-member-error'), {
              id: toastId
            });
          } else {
            toast.success(t('toasts.update-member-success'), {
              id: toastId
            });
          }
          return json;
        })
        .catch((err) => {
          toast.error(t('toasts.update-team-error', { errorMsg: err.message }), {
            id: toastId
          });
          return null;
        });

      await fetch(
        `/api/at/members/send-email?name=${memberName}&email=${memberEmail}&actor_id&${actorId}`,
        {
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
    } catch (error) {
      console.error('Failed to invite user:', error);
      return null;
    }
  };

  return (
    <div className={crmDialogTheme.container}>
      <div className="text-lg font-medium text-gray-900 dark:text-gray-300">
        Bjud in en medlem till Allmännyttans Tjänstelager
      </div>

      <Image src={`/images/an-logo.png`} height={122} width={105} alt={`Test`} />

      <div className="flex w-[90%] gap-2 h-full items-center my-1">
        <span className="leading-4 text-sm text-right text-gray-500 dark:text-gray-300 w-[200px]">
          Organisationsnummer:
        </span>
        <input
          value={actorId}
          onChange={(event) => {
            setActorId(event.target.value);
          }}
          autoComplete="off"
          className="p-2 py-1 w-4/5 bg-card-graypanel border-2 dark:bg-dark-lessfaded dark:placeholder:text-gray-400 rounded dark:border-dark-morefaded border-gray-200 bg-gray-100 focus:bg-card-graypanel"
        />
      </div>

      <div className="flex w-[90%] gap-2 h-full items-center my-1">
        <span className="leading-4 text-sm text-right text-gray-500 dark:text-gray-300 w-[200px]">
          Email:
        </span>

        <input
          value={memberEmail}
          onChange={(event) => {
            setMemberEmail(event.target.value);
          }}
          autoComplete="off"
          className="p-2 py-1 w-4/5 bg-card-graypanel border-2 dark:bg-dark-lessfaded dark:placeholder:text-gray-400 rounded dark:border-dark-morefaded border-gray-200 bg-gray-100 focus:bg-card-graypanel"
        />
      </div>

      <div className="flex w-[90%] gap-2 h-full items-center my-1">
        <span className="leading-4 text-sm text-right text-gray-500 dark:text-gray-300 w-[200px]">
          Namn på kontaktperson:
        </span>

        <input
          value={memberName}
          onChange={(event) => {
            setMemberName(event.target.value);
          }}
          autoComplete="off"
          className="p-2 py-1 w-4/5 bg-card-graypanel border-2 dark:bg-dark-lessfaded dark:placeholder:text-gray-400 rounded dark:border-dark-morefaded border-gray-200 bg-gray-100 focus:bg-card-graypanel"
        />
      </div>

      <div className="flex flex-grow justify-between w-full items-end mt-4">
        <Button type={ButtonType.CANCEL} onClick={close}>
          {t('general.cancel')}
        </Button>

        <Button type={ButtonType.ADD} onClick={inviteUser}>
          {t('general.invite')}
        </Button>
      </div>
    </div>
  );
}

function ErrorStep({ close }: { close: () => void }) {
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.icon}>
        <Error />
      </div>

      <div className="flex flex-col w-full text-center items-center">
        <Dialog.Title as="h3" className="text-red-700 w-3/4 leading-6">
          <p className={crmDialogTheme.subtext}>{t('errors.try-again')}</p>
        </Dialog.Title>
      </div>

      <div className={crmDialogTheme.buttonContainer}>
        <Button type={ButtonType.CANCEL} onClick={close}>
          {t('general.close')}
        </Button>
      </div>
    </div>
  );
}

function CreateTeamStepDone() {
  return (
    <div>
      <div className={crmDialogTheme.icon}>
        <Success />
      </div>

      <div className="text-lg font-medium text-gray-900 dark:text-gray-300">
        Kul - Du har bjudit in en medlem till Allmännyttans Tjänstelager!
      </div>
    </div>
  );
}

export default function MemberInviteDialog({ close, object }: { close: () => void; object: any }) {
  const [step, setStep] = useState(stepNames.addTeam);

  let mod: JSX.Element = <div />;

  switch (step) {
    case stepNames.addTeam:
      mod = <AddTeamStep close={close} setStep={setStep} />;
      break;

    case stepNames.done:
      mod = <CreateTeamStepDone />;
      break;

    case stepNames.error:
      mod = <ErrorStep close={close} />;
      break;
  }

  return (
    <>
      <button onClick={close} className={crmDialogTheme.closeContainerX}>
        <XMarkIcon className="h-5 w-5" />
      </button>
      {mod}
    </>
  );
}
