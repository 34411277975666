import { feature, featureCollection } from '@turf/helpers';

export function parseBuildingPermits(list: any[]) {
  const features: any[] = [];
  list?.map((permit: any) => {
    const geometry = {
      type: 'point',
      coordinates: [permit?.point?.x, permit?.point?.y]
    };
    const properties = {
      details: permit?.details ? JSON.parse(permit?.details) : {},
      name: permit?.authorityNickname,
      propertyUUID: permit?.propertyUUID,
      address: permit?.street
        ? `${permit?.street} ${permit?.street_no}${permit?.street_letter}`
        : null,
      county: permit?.county,
      propType: `Småhusenehet (202)`,
      area: permit?.area_total > 0 ? permit?.area_total : 1029,
      property: permit?.authorityNickname,
      extid: permit?.kungorelseid,
      date: permit?.publiceringsdatum,
      source: permit?.uppgiftslamnare
    };
    const geoJsonFeature = feature(geometry, properties);
    features.push(geoJsonFeature);
  });

  const geoJsonFeatureCollection = featureCollection(features);

  return geoJsonFeatureCollection;
}
