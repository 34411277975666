import { useTranslation } from 'next-i18next';

import { compare_year } from '@/src/lib/utils/sort';
import { FinancialRow } from '@/src/widgets/financials';

export function FinancialTable({
  companyData,
  corparateStructure
}: {
  companyData: any;
  corparateStructure: any;
}) {
  const { t } = useTranslation();
  // const rootNode: any = corparateStructure?.find((a) => a?.isRoot);

  const activeNode: any = corparateStructure?.find(
    (a) => a?.actor_id === companyData.actor.actor_id
  );

  const financials: any[] = activeNode?.financials?.sort(compare_year)?.map((a) => {
    return a;
  });

  let currYear: any = null;
  let previousYear: any = null;

  if (financials && financials?.length > 0) {
    currYear = financials[0];

    if (financials && financials?.length > 1) {
      previousYear = financials[1];
    }
  }

  let directLoaTotal = 0;
  let directBoaTotal = 0;
  let groupLoaTotal = 0;
  let groupBoaTotal = 0;
  let directTaxArea = 0;
  let groupTaxArea = 0;
  let sdi = 0;

  corparateStructure
    ?.filter((a) => a?.obj_type == 'company')
    ?.map((c) => {
      const d: any = c?.financials?.find((a) => a?.year === currYear?.year);
      if (d?.SDI) {
        sdi += parseInt(d?.SDI);
      }
    });

  // const rFin: any = rootNode?.financials?.find((a) => a?.year === currYear?.year);

  corparateStructure
    ?.filter((a) => a?.parent_actor_id === companyData.actor.actor_id && a?.obj_type === 'property')
    ?.map((a) => {
      directLoaTotal += a?.loa;
      directBoaTotal += a?.boa;
      directTaxArea += a?.tax_area;
    });

  corparateStructure
    ?.filter((a) => a?.obj_type === 'property')
    ?.map((a) => {
      groupLoaTotal += a?.loa;
      groupBoaTotal += a?.boa;
      groupTaxArea += a?.tax_area;
    });

  if (!financials || financials?.length < 1 || !currYear || !previousYear) {
    return null;
  }

  return (
    <div>
      <div className="border-gray-100">
        <div className="mt-2 p-2 font-demi text-xs uppercase -ml-2">
          {`Nyckeltal ${currYear?.period} (tkr) i ${currYear?.currency}`}
        </div>
        <FinancialRow
          title={t('general.turnover')}
          keyItem={'SDI'}
          currYear={currYear}
          previousYear={previousYear}
        />
        <FinancialRow
          title={t('property.owner.current-result')}
          keyItem={'DR'}
          currYear={currYear}
          previousYear={previousYear}
        />
        <FinancialRow
          title={t('financials.solidity')}
          keyItem={'EKA'}
          currYear={currYear}
          previousYear={previousYear}
        />
        <FinancialRow
          title={t('general.assets')}
          keyItem={'SED'}
          currYear={currYear}
          previousYear={previousYear}
        />
        <FinancialRow
          title={t('financials.own-capital')}
          keyItem={'SEK'}
          currYear={currYear}
          previousYear={previousYear}
        />
        <FinancialRow
          title={t('financials.short-debt')}
          keyItem={'SKG'}
          currYear={currYear}
          previousYear={previousYear}
        />
        <FinancialRow
          title={t('financials.long-debt')}
          keyItem={'summa_langfristiga_skulder'}
          currYear={currYear}
          previousYear={previousYear}
        />
      </div>
    </div>
  );
}
