import { useUser } from '@auth0/nextjs-auth0';
import { Dialog } from '@headlessui/react';
import { LockClosedIcon, UserGroupIcon } from '@heroicons/react/24/solid';
import cls from 'classnames';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef, useState } from 'react';
import { CRM2OwnershipType } from 'shared/dist/types/crm/board';

import { createCRM2Board } from '@/src/lib/crm2-wrapper';

import { BoardAddStepIntro, BoardAddStepTitle } from '../crm/board-add-edit';
import { crmDialogTheme } from '../crm/dialog-theme';

const stepNames = {
  addBoardIntro: 1,
  addBoardTitle: 2,
  addBoardPrivacy: 3,
  editBoard: 4,
  loading: 5,
  error: 6,
  done: 7
};

// view files where any is used
function BoardAddStepPrivacy({
  setStep,
  close,
  setBoardOwnershipType,
  saveBoard
}: {
  setStep: (a: number) => void;
  close: () => void;
  setBoardOwnershipType: (a: CRM2OwnershipType) => void;
  saveBoard: () => Promise<void>;
}) {
  const inputRef: any = useRef(null);
  const confirmRef: any = useRef(null);

  const [ownershipType, setOwnershipType] = useState<CRM2OwnershipType | null>(null);

  const { t } = useTranslation('common');

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && confirmRef) {
      setTimeout(() => {
        confirmRef?.current.focus();
      }, 150);
    }
  };

  useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current?.focus();
    }
  }, []);

  // 'private', 'team', 'department', 'company'
  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Image
          alt="main illustration"
          src={`/images/apartment-rate-increment.svg`}
          height={200}
          width={200}
        />
      </div>

      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          {t('crm.modals.add-board-privacy-title')}
        </Dialog.Title>

        <p className={crmDialogTheme.subtext}>{t('crm.modals.add-board-privacy-subtext')}</p>
      </div>

      <div className="grid grid-cols-2 gap-2">
        <button
          onKeyDown={(e) => handleKeyDown(e)}
          onClick={() => {
            setOwnershipType('private');
            setBoardOwnershipType('private');
          }}
          className={cls(
            crmDialogTheme.privacyItem,
            {
              'bg-bobo-proplight border-bobo-orange dark:border-dark-prop':
                ownershipType === 'private'
            },
            'w-full'
          )}>
          <LockClosedIcon className={crmDialogTheme.privateBoardIcon} />

          <div className={crmDialogTheme.privacyTitle}>{t('crm.modals.privacy-private')}</div>

          <p>{t('crm.modals.privacy-private-desc')}</p>
        </button>

        <button
          onKeyDown={(e) => handleKeyDown(e)}
          onClick={() => {
            setOwnershipType('team');
            setBoardOwnershipType('team');
          }}
          className={cls(
            crmDialogTheme.privacyItem,
            {
              'bg-bobo-proplight border-bobo-prop dark:border-dark-prop': ownershipType === 'team'
            },
            'w-full'
          )}>
          <UserGroupIcon className={crmDialogTheme.publicBoardIcon} />

          {/* TODO: create translations for new ownership */}
          <div className={crmDialogTheme.privacyTitle}>{t('crm.modals.privacy-team')}</div>

          <div>{t('crm.modals.privacy-team-desc')}</div>
        </button>

        <button
          onKeyDown={(e) => handleKeyDown(e)}
          onClick={() => {
            setOwnershipType('department');
            setBoardOwnershipType('department');
          }}
          className={cls(
            crmDialogTheme.privacyItem,
            {
              'bg-bobo-proplight border-bobo-prop dark:border-dark-prop':
                ownershipType === 'department'
            },
            'w-full'
          )}>
          <UserGroupIcon className={crmDialogTheme.publicBoardIcon} />

          {/* TODO: create translations for new ownership */}
          <div className={crmDialogTheme.privacyTitle}>{t('crm.modals.privacy-department')}</div>

          <div>{t('crm.modals.privacy-department-desc')}</div>
        </button>

        <button
          onKeyDown={(e) => handleKeyDown(e)}
          onClick={() => {
            setOwnershipType('company');
            setBoardOwnershipType('company');
          }}
          className={cls(
            crmDialogTheme.privacyItem,
            {
              'bg-bobo-proplight border-bobo-prop dark:border-dark-prop':
                ownershipType === 'company'
            },
            'w-full'
          )}>
          <UserGroupIcon className={crmDialogTheme.publicBoardIcon} />

          <div className={crmDialogTheme.privacyTitle}>{t('crm.modals.privacy-public')}</div>

          <div>{t('crm.modals.privacy-public-desc')}</div>
        </button>
      </div>

      <div className={crmDialogTheme.buttonContainer}>
        <button className={crmDialogTheme.buttonClose} onClick={close}>
          {t('general.cancel')}
        </button>

        <div className={crmDialogTheme.subButtonContainer}>
          <button
            className={crmDialogTheme.buttonBack}
            onClick={() => setStep(stepNames.addBoardTitle)}>
            {t('general.back')}
          </button>

          <button
            className={crmDialogTheme.buttonConfirm}
            ref={confirmRef}
            disabled={!ownershipType}
            onClick={() => saveBoard()}>
            {t('crm.modals.create-board-button')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default function CRM2BoardCreate({
  initStep = 1,
  setMainStep,
  close,
  object,
  setObject,
  setReload
}) {
  const [step, setStep] = useState(initStep);
  // const [status, setStatus] = useState(0);
  const [boardName, setBoardName] = useState('');
  const [boardOwnershipType, setBoardOwnershipType] = useState<CRM2OwnershipType>('company');

  const { user } = useUser();

  let mod: JSX.Element = <div />;

  async function saveBoard() {
    let ownerUUID = '';

    if (boardOwnershipType === 'private') {
      ownerUUID = ''; // TODO
    } else if (boardOwnershipType === 'company') {
      ownerUUID = (user?.activeActor as any)?.actor_uuid;
    } else {
      throw new Error('TODO');
    }

    await createCRM2Board(boardName, { type: boardOwnershipType, owner_uuid: ownerUUID })
      .then((res) => {
        if ('error' in res) {
          setStep(stepNames.error);
        } else {
          // setStatus(2);
          setStep(stepNames.done);
          setObject({
            ...object,
            boardId: res?.uuid
          });
        }
      })
      .catch((err) => {
        setStep(stepNames.error);
      });
  }

  switch (step) {
    case stepNames.addBoardIntro:
      mod = <BoardAddStepIntro setStep={setStep} close={close} />;
      break;

    case stepNames.addBoardTitle:
      mod = (
        <BoardAddStepTitle
          setStep={setStep}
          setMainStep={setMainStep}
          close={close}
          setBoardName={setBoardName}
          boardName={''}
          backToAddProp={{ addProperty: false, addProperties: false }}
        />
      );
      break;

    case stepNames.addBoardPrivacy:
      mod = (
        <BoardAddStepPrivacy
          setStep={setStep}
          close={close}
          setBoardOwnershipType={setBoardOwnershipType}
          saveBoard={saveBoard}
        />
      );
      break;

    // case stepNames.editBoard:
    //   if (board) {
    //     mod = (
    //       <BoardEditStepIntro
    //         close={close}
    //         setBoardName={setBoardName}
    //         saveBoard={saveBoard}
    //         setBoardPrivacy={setBoardPrivacy}
    //         board={board}
    //       />
    //     );
    //   }
    //   break;

    // case stepNames.loading:
    //   mod = <BoardAddEditStepLoading />;
    //   break;

    // case stepNames.error:
    //   mod = <BoardAddEditStepError close={close} object={object} />;
    //   break;

    // case stepNames.done:
    //   mod = (
    //     <BoardAddEditStepDone
    //       setMainStep={setMainStep}
    //       close={close}
    //       object={object}
    //       status={status}
    //     />
    //   );
    //   break;
  }

  return <>{mod}</>;
}
