import { Popover } from '@headlessui/react';
import cls from 'classnames';
import React from 'react';
import { ButtonType } from 'shared/dist/types';

import { useBreakpoint } from '../hooks/use-breakpoint';
import Tooltip from '../layout/components/tooltip';

export default function Button({
  children,
  onClick,
  className = '',
  disabled = false,
  type = ButtonType.ACTION,
  bigger = false,
  popover = false,
  onKeyDown,
  tooltipText
}: {
  children: React.JSX.Element | React.JSX.Element[] | string;
  onClick?: (any) => void;
  className?: string;
  disabled?: boolean;
  type?: ButtonType;
  bigger?: boolean;
  popover?: boolean;
  onKeyDown?: (any) => void;
  tooltipText?: string;
}): React.JSX.Element {
  const { isMd } = useBreakpoint({ breakpoint: 'md' });
  const isMobile = !isMd;

  const getButtonStyle = (buttonType: ButtonType): string => {
    switch (buttonType) {
      case 'action':
        return 'border-transparent bg-gray-700 dark:bg-gray-700 hover:bg-bobo-prop dark:hover:bg-dark-prophover text-white';
      case 'add':
        return 'border-transparent bg-bobo-prop dark:bg-dark-prop hover:bg-bobo-prophover dark:hover:bg-dark-prophover text-white';
      case 'info':
        return 'border-transparent text-bobo-black hover:text-bobo-texthover dark:text-white dark:hover:text-bobo-texthover bg-gray-200 dark:bg-dark-faded dark:hover:bg-dark-lessfaded';
      case 'delete':
        return 'border-transparent shadow bg-red-600/80 hover:bg-bobo-fun/80 text-white disabled:dark:!bg-dark-lessfaded disabled:text-gray-400 disabled:hover-none disabled:shadow-none';
      case 'cancel':
        return 'text-gray-500 dark:hover:text-white hover:bg-gray-100 dark:hover:bg-dark-lessfaded hover:shadow border border-gray-100 dark:border-dark-morefaded disabled:shadow-none';
      case 'dropdown':
        return 'overflow-auto !justify-between leading-3 rounded-t-sm !border-x !border-t !border-b-none border-gray-200 dark:border-dark-morefaded dark:bg-dark-primary transition-colors duration-200 !px-2 !py-0.5 w-full text-xs';
      default:
        return '';
    }
  };

  const style = `transition-all relative duration-200 group rounded-sm disabled:dark:bg-dark-lessfaded disabled:dark:text-gray-400 disabled:text-gray-400 disabled:bg-gray-100 disabled:cursor-not-allowed disabled:border-gray-100 dark:disabled:border-dark-morefaded disabled:hover-none flex items-center justify-center items-center gap-1 whitespace-nowrap border ${className} ${getButtonStyle(
    type
  )}`;

  const extraCssObject = {
    'text-xs font-normal px-4 py-2': !bigger,
    'text-base font-medium px-6 py-2': bigger,
    'has-tooltip': tooltipText
  };

  const tootip: React.JSX.Element = (
    <Tooltip
      className={cls('top-8 right-0', {
        hidden: !tooltipText,
        '!text-wrap !max-w-[250px]': isMobile
      })}>
      {tooltipText ?? ''}
    </Tooltip>
  );

  return popover ? (
    <Popover.Button
      disabled={disabled}
      onClick={(e) => onClick && onClick(e)}
      onKeyDown={(e) => onKeyDown && onKeyDown(e)}
      className={cls(style, extraCssObject)}>
      {children}
      {tootip}
    </Popover.Button>
  ) : (
    <button
      disabled={disabled}
      onClick={(e) => onClick && onClick(e)}
      onKeyDown={(e) => onKeyDown && onKeyDown(e)}
      className={cls(style, extraCssObject)}>
      {children}
      {tootip}
    </button>
  );
}
