import { GlobeAltIcon } from '@heroicons/react/24/outline';
import {
  ArrowLeftOnRectangleIcon as LogoutIcon,
  BuildingOffice2Icon as OfficeBuildingIcon,
  ComputerDesktopIcon,
  MoonIcon,
  ShieldCheckIcon,
  SunIcon
} from '@heroicons/react/24/solid';
import { shutdown as shutdownIntercom, update as updateIntercom } from '@intercom/messenger-js-sdk';
import cls from 'classnames';
import { setCookie } from 'cookies-next';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { i18n, Trans, useTranslation } from 'next-i18next';
import { useTheme } from 'next-themes';
import { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { Actor2, UserProfileVM } from 'shared/dist/types';
import { getUserProfile, saveProfile } from 'src/lib/user-client-wrapper';

export type UserProfile = Pick<
  UserProfileVM['user'],
  'name' | 'email' | 'language' | 'newsletter' | 'phone' | 'alerts'
>;

const UserMenu = ({ user, close }: { user: any; close: any }) => {
  const { theme, setTheme } = useTheme();

  const { t } = useTranslation('common');

  const router = useRouter();

  const [userObj, setUserObj] = useState<UserProfile>();
  const [activeActor, _] = useGlobal<{ activeActor: Actor2 }>('activeActor' as never);

  useEffect(() => {
    getUserProfile(t).then((userData) => {
      setUserObj({
        ...userData?.user
      });
    });
  }, [user]);

  const userMenuOptions = [
    {
      name: t('navigation.settings.my-profile'),
      description: t('navigation.settings.my-profile-desc'),
      href: '/profile/me',
      icon: ShieldCheckIcon
    }
  ];

  const actorMenuOptions = [
    {
      name: t('navigation.settings.heading'),
      description: t('navigation.settings.actor-desc'),
      href: '/companyprofile/settings',
      icon: OfficeBuildingIcon
    }
  ];

  const updateLocale = async (language: string) => {
    if (i18n?.language !== language) {
      const { pathname, asPath, query } = router;
      router.push({ pathname, query }, asPath, { locale: language });
      i18n?.changeLanguage(language);
      setCookie('NEXT_LOCALE', language);
      updateIntercom({
        email: user.email,
        language_override: language
      });
      userObj && (await saveProfile({ ...userObj, language }, t));
    }
  };

  return (
    <>
      <div className="relative grid bg-white dark:bg-dark-primary text-sm font-medium">
        <div className="uppercase text-gray-800 dark:text-white px-0 md:px-6 py-2 mt-3">
          {user?.nickname}
        </div>

        {userMenuOptions.map((item) => (
          <Link key={item.name} href={item.href}>
            <span className="px-0 md:px-6 flex items-start py-4 hover:bg-gray-100 dark:hover:bg-dark-faded transition ease-in-out duration-150">
              <item.icon className="flex-shrink-0 h-5 w-5 text-bobo-orange" aria-hidden="true" />

              <div className="ml-4">
                <p className="text-sm font-medium text-gray-900 dark:text-white">{item.name}</p>

                <p className="text-xs font-normal text-gray-500 dark:text-gray-300">
                  {item.description}
                </p>
              </div>
            </span>
          </Link>
        ))}

        <div className="border-t dark:border-dark-morefaded w-full" />

        <div className="uppercase text-gray-800 px-0 dark:text-white md:px-6 py-2 mt-3">
          {activeActor?.company_name}
        </div>

        {activeActor &&
          actorMenuOptions.map((item) => (
            <Link key={item.name} href={item.href}>
              <span className="px-0 md:px-6 flex items-start py-4 hover:bg-gray-100 dark:hover:bg-dark-faded transition ease-in-out duration-150">
                <item.icon className="flex-shrink-0 h-5 w-5 text-bobo-orange" aria-hidden="true" />

                <div className="ml-4">
                  <p className="text-sm font-medium text-gray-900 dark:text-white">{item.name}</p>

                  <p className="text-xs font-normal text-gray-500 dark:text-gray-300">
                    {item.description}
                  </p>
                </div>
              </span>
            </Link>
          ))}
        <div className="border-t dark:border-dark-morefaded w-full" />

        <div className="pt-6 pb-4 px-0 md:px-6 flex items-start text-left hover:bg-gray-100 dark:hover:bg-dark-faded transition ease-in-out duration-150">
          <ComputerDesktopIcon
            className={`flex-shrink-0 h-5 w-5 text-bobo-orange`}
            aria-hidden="true"
          />

          <div className="ml-4 w-full flex flex-col">
            <div className="text-sm font-medium text-gray-900 dark:text-white">
              <Trans
                i18nKey="settings.personal.theme"
                theme={
                  theme === 'system' ? 'Auto' : theme === 'light' ? 'Light Mode' : 'Dark Mode'
                }>
                Skärmläge
                <div className="font-normal inline-block text-gray-500 dark:text-gray-300">
                  {{
                    theme:
                      theme === 'system' ? 'Auto' : theme === 'light' ? 'Light Mode' : 'Dark Mode'
                  }}
                </div>
              </Trans>
            </div>

            <div className="flex justify-start gap-10 items-end">
              <div className="group">
                <button
                  className={cls(
                    'w-[32px] h-[34px] flex items-center justify-center border-b-2 transition-150 transition-all',
                    {
                      'border-bobo-orange cursor-default': theme === 'light'
                    },
                    {
                      'group-hover:border-bobo-prop group-hover:dark:border-dark-prop':
                        theme !== 'light'
                    }
                  )}
                  onClick={() => {
                    setTheme('light');
                    close();
                  }}>
                  <SunIcon
                    className={cls(
                      'h-6 w-6',
                      {
                        'text-bobo-orange cursor-default': theme === 'light'
                      },
                      {
                        'group-hover:text-bobo-prop dark:group-hover:text-dark-prop text-gray-400':
                          theme !== 'light'
                      }
                    )}
                    aria-hidden="true"
                  />
                  <p className="sr-only">
                    {t('settings.personal.change-theme', {
                      theme: 'light'
                    })}
                  </p>
                </button>
              </div>

              <div className="group">
                <button
                  className={cls(
                    'w-[32px] h-[34px] flex items-center justify-center border-b-2 transition-150 transition-all',
                    {
                      'border-bobo-orange cursor-default': theme === 'dark'
                    },
                    {
                      'group-hover:border-bobo-prop group-hover:dark:border-dark-prop':
                        theme !== 'dark'
                    }
                  )}
                  onClick={() => {
                    setTheme('dark');
                    close();
                  }}>
                  <MoonIcon
                    className={cls(
                      'h-5 w-5',
                      {
                        'text-bobo-orange cursor-default': theme === 'dark'
                      },
                      {
                        'group-hover:text-bobo-prop dark:group-hover:text-dark-prop text-gray-400':
                          theme !== 'dark'
                      }
                    )}
                    aria-hidden="true"
                  />
                  <p className="sr-only">
                    {t('settings.personal.change-theme', {
                      theme: 'dark'
                    })}
                  </p>
                </button>
              </div>

              <button
                className={cls(
                  'w-[32px] h-[34px] flex items-center justify-center text-xs border-b-2 transition-150 transition-all',
                  {
                    'border-bobo-orange cursor-default text-bobo-orange ': theme === 'system'
                  },
                  {
                    'hover:border-bobo-prop dark:hover:border-dark-prop hover:text-bobo-prop dark:hover:text-dark-prop text-gray-400':
                      theme !== 'system'
                  }
                )}
                onClick={() => {
                  setTheme('system');
                  close();
                }}>
                <p>{t('general.auto')}</p>
                <p className="sr-only">
                  {t('settings.personal.change-theme', {
                    theme: 'auto'
                  })}
                </p>
              </button>
            </div>
          </div>
        </div>

        {process.env.NEXT_PUBLIC_FEATURE_LANGUAGE_SWITCHER_ENABLED === 'true' && (
          <div className="px-0 md:px-6 flex items-start py-4 hover:bg-gray-100 dark:hover:bg-dark-faded transition ease-in-out duration-150">
            <GlobeAltIcon className={`flex-shrink-0 h-5 w-5 text-bobo-orange`} aria-hidden="true" />

            <div className="ml-4">
              <div className="text-sm font-medium text-gray-900 dark:text-white">
                <Trans
                  i18nKey="settings.personal.language"
                  language={
                    i18n?.language === 'sv'
                      ? 'Svenska'
                      : i18n?.language === 'en'
                        ? 'English'
                        : 'Suomalainen'
                  }>
                  Språk
                  <div className="font-normal inline-block text-gray-500 dark:text-gray-300">
                    {{
                      language:
                        i18n?.language === 'sv'
                          ? 'Svenska'
                          : i18n?.language === 'en'
                            ? 'English'
                            : 'Suomalainen'
                    }}
                  </div>
                </Trans>
              </div>

              <div className="flex justify-start gap-10 items-end">
                <button
                  className={cls(
                    'text-xs py-2 px-1 border-b-2 transition-150 transition-all',
                    {
                      'border-bobo-orange cursor-default': i18n?.language === 'sv'
                    },
                    {
                      'hover:border-bobo-prop hover:dark:border-dark-prop': i18n?.language !== 'sv'
                    }
                  )}
                  onClick={() => {
                    updateLocale('sv');
                  }}>
                  <Image
                    alt={t('images.swedish-flag-alt')}
                    height={20}
                    width={25}
                    src={`/images/flags/se.svg`}
                  />
                  <p className="sr-only">{t('settings.personal.change-language-swe')}</p>
                </button>

                {process.env.NEXT_PUBLIC_FEATURE_ENGLISH_ENABLED === 'true' && (
                  <button
                    className={cls(
                      'text-xs py-2 px-1 border-b-2 transition-150 transition-all',
                      {
                        'border-bobo-orange cursor-default': i18n?.language === 'en'
                      },
                      {
                        'hover:border-bobo-prop hover:dark:border-dark-prop':
                          i18n?.language !== 'en'
                      }
                    )}
                    onClick={() => {
                      updateLocale('en');
                    }}>
                    <Image
                      alt={t('images.great-brittain-flag-alt')}
                      height={15}
                      width={25}
                      src={`/images/flags/gb.svg`}
                    />
                    <p className="sr-only">{t('settings.personal.change-language-eng')}</p>
                  </button>
                )}

                {process.env.NEXT_PUBLIC_FEATURE_FINNISH_ENABLED === 'true' && (
                  <button
                    className={cls(
                      'text-xs py-2 px-1 border-b-2 transition-150 transition-all',
                      {
                        'border-bobo-orange cursor-default': i18n?.language === 'fi-FI'
                      },
                      {
                        'hover:border-bobo-prop hover:dark:border-dark-prop':
                          i18n?.language !== 'fi-FI'
                      }
                    )}
                    onClick={() => {
                      updateLocale('fi-FI');
                    }}>
                    <Image
                      alt={t('images.finnish-flag-alt')}
                      height={15}
                      width={25}
                      src={`/images/flags/fi.svg`}
                    />
                    <p className="sr-only">{t('settings.personal.change-language-fin')}</p>
                  </button>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="border-t dark:border-dark-morefaded w-full" />

        <Link
          href={'/api/auth/logout'}
          onClick={() => {
            shutdownIntercom();
          }}>
          <span className="p-3 px-0 md:px-6 flex items-start hover:bg-gray-100 dark:hover:bg-dark-faded transition ease-in-out duration-150 py-6">
            <LogoutIcon className="flex-shrink-0 h-5 w-5 text-bobo-orange" aria-hidden="true" />

            <div className="ml-4">
              <p className="text-sm font-medium text-gray-900 dark:text-white">
                {t('general.log-out')}
              </p>
            </div>
          </span>
        </Link>
      </div>
    </>
  );
};

export default UserMenu;
