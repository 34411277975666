import { useTranslation } from 'next-i18next';

export default function LoadingBoards() {
  const { t } = useTranslation('common');
  const emptyBoards = new Array(7);
  emptyBoards.fill(33);
  return (
    <div
      role="status"
      className="w-full p-4 space-y-4  divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700">
      <div className="grid md:grid-cols-4 grid-cols-1 sm:grid-cols-2 gap-2 w-[90%]">
        {emptyBoards?.map((b, i) => (
          <div
            key={i}
            className="border rounded-md dark:border-dark-morefaded dark:bg-dark-faded p-3 relative h-full flex flex-col items-center justify-center">
            <div className="mb-2 mx-8 font-medium bg-gray-300 rounded-full dark:bg-gray-600 w-[90%] h-2" />
          </div>
        ))}
      </div>
      <span className="sr-only">{t('general.loading')}</span>
    </div>
  );
}
