import ChevronRightIcon from '@heroicons/react/24/outline/ChevronRightIcon';
import cls from 'classnames';
import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobal } from 'reactn';
import { RiskElement, RiskLevel } from 'shared/dist/types/risk';

import { EyeIcon } from '@/src/assets/icons';
import InfoButton from '@/src/widgets/info-button';

import { MalarenLevelSlider } from './malaren-level-slider';
import { WaterLevelSlider } from './water-level-slider';

export const ScoreCardRiskLevel = ({
  level,
  risk,
  crm = false
}: {
  level: RiskLevel;
  risk: RiskElement;
  crm?: boolean;
}) => {
  const { t } = useTranslation('common');
  const [hideSubMenu, setHideSubMenu] = useGlobal<any[]>('hideSubMenu' as never);

  let riskLabel = t(`risk.level.${level}`);

  if (risk.index === `risk.vulnerable-area`) {
    riskLabel = t(`risk.social.${level}`, { type: risk.riskType });
  }

  if (risk.index === `risk.population-trend`) {
    riskLabel = t(`risk.population.${level}`);
  }

  if ([`risk.ravine`, `risk.solid_ground`].includes(risk.index)) {
    riskLabel = t(`risk.level.not-assessed`);
  }

  return (
    <div
      className={cls(
        `flex px-1 ${
          crm
            ? 'w-full h-[36px] min-w-[200px] has-tooltip cursor-help inline-block group'
            : 'min-w-[85px] w-[85px]'
        } items-center justify-center border-r dark:text-gray-900 border-gray-100 text-center font-medium text-[11px] dark:border-dark-morefaded`,
        {
          'bg-bobo-low': level === RiskLevel.LOW
        },
        {
          'bg-gradient-to-r from-bobo-low to-bobo-medium': level === RiskLevel.MEDIUM_LOW
        },
        {
          'bg-bobo-medium': level === RiskLevel.MEDIUM
        },
        {
          'bg-gradient-to-r from-bobo-medium to-bobo-high': level === RiskLevel.MEDIUM_HIGH
        },
        {
          'bg-bobo-high': level === RiskLevel.HIGH
        },
        {
          'bg-bobo-low': level === RiskLevel.NONE
        },
        {
          'bg-[#d5e0ea]': level === RiskLevel.NA
        }
      )}>
      {crm && (
        <div
          className={cls(
            'tooltip fixed top-0 right-0 shadow bg-white dark:text-white dark:bg-dark-primary text-left p-6 text-xs rounded  opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-50',
            { 'w-[calc(100dvw-51px)]': hideSubMenu, 'w-[calc(100dvw-282px)]': !hideSubMenu }
          )}>
          <div className="font-demi">{t(`${risk.index}`)}</div>
          <div
            className="text-xs font-normal mt-2"
            dangerouslySetInnerHTML={{
              __html: t(risk?.motivation?.key, risk?.motivation?.options)
                ?.replaceAll('||', '</strong>')
                .replaceAll('|', '<strong>')
            }}
          />
          <div className="text-gray-400 dark:text-gray-200 italic text-xs mt-2">
            {risk.source?.join(', ')}
          </div>
        </div>
      )}
      {riskLabel}
    </div>
  );
};

export const ScoreCardRiskEye = ({
  visuals,
  setVisuals,
  layers
}: {
  visuals: any;
  setVisuals: any;
  layers: any;
}) => {
  return (
    <div className="min-w-[40px] flex justify-center items-center">
      <div className="header p-2 py-3 font-medium flex items-center justify-center text-center">
        {visuals && layers && layers.length > 0 ? (
          <button
            onClick={() => {
              setVisuals((prevVisuals) => {
                const updatedVisuals = layers.reduce(
                  (acc, layer) => {
                    acc[layer] = !prevVisuals[layer];
                    return acc;
                  },
                  { ...prevVisuals }
                );

                return updatedVisuals;
              });
            }}
            className={cls(
              'p-0.5 rounded-full',
              {
                'text-gray-300': !visuals[`${layers[0]}`]
              },
              {
                'text-bobo-prop': visuals[`${layers[0]}`]
              }
            )}>
            {visuals[`${layers[0]}`] ? (
              <EyeIcon className={`text-bobo-prop`} />
            ) : (
              <EyeIcon className={`text-gray-300`} />
            )}
          </button>
        ) : null}
      </div>
    </div>
  );
};

export const RiskTableRow = ({
  risk,
  setVisuals,
  visuals,
  offset = false,
  crm = false
}: {
  risk: RiskElement;
  setVisuals: any;
  visuals: any;
  offset?: boolean;
  crm?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef<any>(null);
  const [contentHeight, setContentHeight] = useState(0);

  const { t } = useTranslation('common');

  useEffect(() => {
    if (isOpen && contentRef && contentRef.current) {
      setContentHeight(1000);
    } else {
      setContentHeight(0);
    }
  }, [isOpen]);

  if (!risk && crm) {
    return (
      <div
        className={cls({
          'h-[36px] overflow-hidden text-gray-700 font-demi text-center text-[11.5px] dark:text-white bg-bobo-low bg-opacity-25':
            crm
        })}>
        <div className="w-full flex text-xs text-center justify-center items-center py-2">
          {t('risk.level.0')}
        </div>
      </div>
    );
  }

  if (!risk) {
    return <div />;
  }

  return (
    <div
      className={cls({
        'h-[36px] overflow-hidden text-gray-700 font-demi text-left text-[11.5px] dark:text-white':
          crm
      })}>
      <div className="w-full flex items-stretch text-xs">
        {!crm && (
          <div
            className={cls(
              'flex-1 flex flex-col justify-center md:w-4/5 border-r dark:border-dark-morefaded border-gray-100 py-2 pr-1 md:p-2 overflow-hidden',
              { '!pl-[20px]': offset }
            )}>
            <div
              role="button"
              tabIndex={0}
              onClick={() => setIsOpen(!isOpen)}
              onKeyDown={() => setIsOpen(!isOpen)}
              className="font-medium relative text-left text-xs justify-start items-center w-full hover:text-bobo-orange transition-colors cursor-pointer">
              <div className="flex">
                <div className="w-[20px]">
                  <div className="rounded-full p-0.5 w-fit">
                    <ChevronRightIcon
                      className={cls(
                        'w-3 h-3 text-gray-800 dark:text-white transition-transform duration-300',
                        {
                          'rotate-90': isOpen
                        }
                      )}
                    />
                  </div>
                </div>
                <div className="flex justify-start w-full items-center space-x-1">
                  <div className="text-mini md:text-xs">{t(`${risk.index}`)}</div>
                  {risk.helpTextId ? (
                    <InfoButton small={true} helpTextId={risk.helpTextId} />
                  ) : null}
                </div>
              </div>

              <div className="text-gray-400 italic text-[10px] pl-[20px]">
                {t('general.sources', { sources: risk.source?.join(', ') })}
              </div>
            </div>

            <div
              ref={contentRef}
              style={{ maxHeight: `${contentHeight}px` }}
              className="duration-500 transition-max-height overflow-hidden">
              <div
                className="text-gray-800 font-normal dark:text-white leading-[15px] text-xs p-3 pl-[20px] mb-1"
                dangerouslySetInnerHTML={{
                  __html: t(risk.motivation.key, risk.motivation.options)
                    .replaceAll('||', '</strong>')
                    .replaceAll('|', '<strong>')
                }}
              />

              {risk.layers?.includes('EBH_Potentiellt_fororenade_omraden') &&
                risk.features?.slice(0, 10)?.map((riskItem, idx) => {
                  return (
                    <div
                      key={idx}
                      className="odd:bg-gray-50 dark:odd:bg-dark-faded flex justify-between items-center text-[11px] space-x-2 border-b border-gray-200 dark:border-dark-morefaded">
                      <div
                        className={cls(
                          `w-[20px] p-1 h-[20px] rounded-full bg-white dark:text-gray-900 flex items-center justify-center`,
                          {
                            'bg-[#ff0300]': riskItem.properties?.classification === 1
                          },
                          {
                            'bg-[#ffa900]': riskItem.properties?.classification === 2
                          },
                          {
                            'bg-[#fffe01]': riskItem.properties?.classification === 3
                          },
                          {
                            'bg-[#aaff00]': riskItem.properties?.classification === 4
                          }
                        )}>
                        {riskItem.properties?.classification ?? 'E'}
                      </div>
                      <div className="w-3/4 p-1">
                        <div>{riskItem.properties?.title}</div>
                        <div className="text-gray-500 dark:text-gray-300 space-x-1 flex">
                          <div>{t('general.status')}:</div>
                          <div>{riskItem.properties?.status}</div>
                        </div>
                      </div>
                      <div className="flex-1 p-1 text-right">
                        {riskItem.properties?.distance_from_border}
                        {t('general.meters-short-suffix')}
                      </div>
                    </div>
                  );
                })}
              {!crm && risk.index === `risk.flooding-ocean` && (
                <WaterLevelSlider showSlider={visuals[`${risk.layers[0]}`]} risk={risk} />
              )}
              {!crm &&
                risk.index === `risk.flooding-malaren` &&
                risk.riskLevel > RiskLevel.NONE && (
                  <MalarenLevelSlider showSlider={visuals[`${risk.layers[0]}`]} />
                )}
              {!crm && risk.index === `risk.draught` && (
                <div className="px-5 text-bobo-prop hover:text-bobo-prophover">
                  <Link
                    href={`https://www.skogsstyrelsen.se/globalassets/sjalvservice/karttjanster/geodatatjanster/produktbeskrivningar/markfuktighetskarta-slu---produktbeskrivning.pdf`}
                    target={`_new`}>
                    {t('general.more-info')}
                  </Link>
                </div>
              )}
              {risk.layers?.includes('lamningar_sverige_point') &&
                risk.features?.slice(0, 10)?.map((riskItem, idx) => {
                  return (
                    <div
                      key={idx}
                      className="odd:bg-gray-50 dark:odd:bg-dark-faded flex justify-between items-center text-[11px] space-x-2 border-b border-gray-200 dark:border-dark-morefaded">
                      <div className={cls(`w-1/3 p-1 font-demi`)}>
                        {riskItem.properties?.type ?? 'E'}
                      </div>
                      <div className="w-2/4 p-1">
                        <div>{riskItem.properties?.title}</div>
                        <div className="text-gray-500 dark:text-gray-300 space-x-1 flex leading-3">
                          <div>{t('general.status')}:</div>
                          <div>{riskItem.properties?.geo_status}</div>
                        </div>
                      </div>
                      <div className="flex-1 p-1 text-right">
                        {riskItem.properties?.distance_from_border}
                        {t('general.meters-short-suffix')}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
        <ScoreCardRiskLevel level={risk.riskLevel} crm={crm} risk={risk} />
        {!crm && (
          <ScoreCardRiskEye setVisuals={setVisuals} visuals={visuals} layers={risk.layers} />
        )}
      </div>
    </div>
  );
};

export const RiskTableRowExpandable = ({
  risk,
  items,
  visuals,
  setVisuals,
  crm = false
}: {
  risk: RiskElement;
  items: any[];
  visuals: any;
  setVisuals: any;
  crm?: boolean;
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef<any>(null);
  const [contentHeight, setContentHeight] = useState(crm ? 2000 : 0);
  const [globalShowEnv, setGlobalShowEnv] = useGlobal<any>('globalShowEnv');

  const { t } = useTranslation('common');

  useEffect(() => {
    if (isOpen && contentRef && contentRef.current) {
      setContentHeight(2000);
    } else {
      setContentHeight(crm ? 2000 : 0);
    }
  }, [isOpen, items]);

  if (!risk) {
    return <div />;
  }

  return (
    <div className={cls({ 'h-full': !crm })}>
      <div className="w-full flex items-stretch">
        {!crm && (
          <div className="flex-1 md:w-4/5 border-r border-gray-100 py-2 md:p-2 pr-1 dark:border-dark-morefaded overflow-hidden">
            <div
              role="button"
              className="w-full justify-start flex flex-col"
              tabIndex={0}
              onClick={() => {
                if (risk.index === 'risk.protected_area') {
                  setGlobalShowEnv(!isOpen);
                } else {
                  setIsOpen(!isOpen);
                }
              }}
              onKeyDown={() => {
                if (risk.index === 'risk.protected_area') {
                  setGlobalShowEnv(!isOpen);
                } else {
                  setIsOpen(!isOpen);
                }
              }}>
              <div className="font-medium text-left flex justify-start items-center w-full relative text-xs">
                <div className="w-[20px]">
                  <ChevronRightIcon
                    className={cls(
                      'w-3 h-3 text-gray-800 dark:text-white transition-transform duration-300',
                      {
                        'rotate-90': isOpen
                      }
                    )}
                  />
                </div>

                <div className="flex justify-start w-full items-start space-x-1">
                  <div className="text-mini md:text-xs">{t(`${risk.index}`)}</div>
                  {risk.helpTextId && (
                    <InfoButton small={true} helpTextId={risk.helpTextId ?? ''} />
                  )}
                </div>
              </div>
              <div className="text-gray-400 italic text-[10px] pl-[20px]">
                {t('general.sources', { sources: risk.source?.join(', ') })}
              </div>
            </div>
          </div>
        )}
        {risk.index !== `risk.protected-areas` ? (
          <ScoreCardRiskLevel level={risk.riskLevel} crm={crm} risk={risk} />
        ) : (
          <div className="w-[85px] h-[36px] flex justify-center items-center">
            {t('risk.level.s')}
          </div>
        )}
        {!crm && (
          <ScoreCardRiskEye setVisuals={setVisuals} visuals={visuals} layers={risk.layers} />
        )}
      </div>

      <div
        ref={contentRef}
        style={{ maxHeight: `${contentHeight}px` }}
        className="transition-max-height duration-500 overflow-hidden">
        <div className={cls({ 'bg-blue-200/15': !crm })}>
          {items?.map((item: RiskElement, idx) => {
            return (
              <RiskTableRow
                crm={crm}
                setVisuals={setVisuals}
                offset={true}
                visuals={visuals}
                risk={item}
                key={idx}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
