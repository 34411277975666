import Slider from '@mui/material/Slider';
import cls from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';

import { getLayerColor } from '@/src/lib/colors';

export const MalarenLevelSlider = ({ showSlider }: { showSlider: boolean }) => {
  const [level, setLevel] = useState<string>('');
  const [waterLevel, setWaterLevel] = useState<number>(0);

  const { t } = useTranslation('common');
  const { riskMap } = useMap();

  function valuetext(value: number) {
    return `${value}°C`;
  }

  const showFloodingLayers = (flooding_layer, water_level) => {
    if (riskMap) {
      riskMap.getMap().getLayer(`${level}`) && riskMap.getMap().removeLayer(`${level}`);
      riskMap.getMap().getLayer(`${level}-line`) && riskMap.getMap().removeLayer(`${level}-line`);

      const sourceName = `bobolaget.flooding-malaren`;

      !riskMap.getMap().getSource(sourceName) &&
        riskMap.getMap().addSource(sourceName, {
          type: 'vector',
          maxzoom: 22,
          url: `mapbox://${sourceName}`
        });

      !riskMap.getMap().getLayer(`${flooding_layer}`) &&
        riskMap.getMap().addLayer(
          {
            id: `${flooding_layer}`,
            type: 'fill',
            source: `${sourceName}`,
            'source-layer': `${flooding_layer}`,
            paint: {
              'fill-color': getLayerColor(flooding_layer),
              'fill-opacity': 0.5
            },
            layout: {
              // visibility: 'none'
            }
          },
          'border'
        );

      !riskMap.getMap().getLayer(`${flooding_layer}-line`) &&
        riskMap.getMap().addLayer(
          {
            id: `${flooding_layer}-line`,
            type: 'line',
            source: `${sourceName}`,
            'source-layer': `${flooding_layer}`,
            paint: {
              'line-color': getLayerColor(flooding_layer),
              'line-width': 3,
              'line-opacity': 0.8
            },
            layout: {
              // visibility: 'none'
            }
          },
          'border'
        );

      riskMap
        .getMap()
        .setFilter('LstabMSB_Konsekvenser_oversvamning_Malaren_nivaer_klippt', [
          '<',
          ['get', 'mark_min'],
          water_level
        ]);
    }
  };

  useEffect(() => {
    if (level && !showSlider && riskMap) {
      riskMap.getMap().getLayer(`${level}`) && riskMap.getMap().removeLayer(`${level}`);
      riskMap.getMap().getLayer(`${level}-line`) && riskMap.getMap().removeLayer(`${level}-line`);
    }

    showFloodingLayers(`LstabMSB_Konsekvenser_oversvamning_Malaren_nivaer_klippt`, 0.9);
  }, [showSlider]);

  return (
    <div className={cls(`px-4`)}>
      <div>{t('risk.malaren-level-change-text')}</div>
      <Slider
        aria-label={t('risk.coastal-level')}
        defaultValue={0}
        disabled={!showSlider}
        getAriaValueText={valuetext}
        valueLabelDisplay="auto"
        color={`info`}
        shiftStep={0.1}
        value={waterLevel}
        onChange={(event: React.SyntheticEvent | Event, value: number | number[]) => {
          if (!Array.isArray(value)) {
            setWaterLevel(value);
          }
        }}
        onChangeCommitted={(event: React.SyntheticEvent | Event, value: number | number[]) => {
          if (!Array.isArray(value)) {
            showFloodingLayers(`LstabMSB_Konsekvenser_oversvamning_Malaren_nivaer_klippt`, value);
          }
        }}
        valueLabelFormat={(txt) => {
          return `${txt}${t('general.meters-short-suffix')}`;
        }}
        step={0.1}
        marks
        min={0.9}
        max={3.1}
      />
    </div>
  );
};
