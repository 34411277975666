import { TableWithExportHeader } from '../tableWithExport';

export const getExportHeaders = (t, location: 'crm' | 'advanced'): TableWithExportHeader[] => {
  const headers: TableWithExportHeader[] = [
    /*FASTIGHET*/
    { label: t('general.property-heading'), key: 'authority_nickname' },
    { label: t('column-headers.board'), key: 'board' },
    { label: t('column-headers.list'), key: 'list' },
    { label: t('column-headers.property-type'), key: 'tax_codes' },
    { label: t('general.local-council'), key: 'county' },
    { label: t('general.region-short'), key: 'region' },
    { label: t('column-headers.owners'), key: 'owner_generic' },
    { label: t('column-headers.plot-area'), key: 'plot_area' },
    { label: t('column-headers.gross-residential-area'), key: 'gross_residential_area' },
    { label: t('column-headers.gross-leasable-area'), key: 'gross_leasable_area' },
    { label: t('property.build-year'), key: 'build_year' },
    { label: t('column-headers.addresses-str'), key: 'addresses_str' },
    { label: t('column-headers.number-apartments'), key: 'number_apartments' },
    { label: t('column-headers.energy-dek'), key: 'energy_class' },
    { label: t('general.primary-energy-number'), key: 'atemp' },
    { label: t('general.energy-consumption'), key: 'energy_level' },
    {
      label: t('column-headers.environment-certification-str'),
      key: 'environment_certification_str'
    },
    { label: t('column-headers.land-owners-str'), key: 'land_owners_str' },
    { label: t('column-headers.land-owners-org-nr'), key: 'land_owners_org_nr' },
    {
      label: t('column-headers.land-owners-root-actors-str'),
      key: 'land_owners_root_actors_str'
    },

    {
      label: t('column-headers.owner-type'),
      key: 'land_lease'
    },
    { label: t('column-headers.lease-fee'), key: 'lease_fee' },
    { label: t('column-headers.lease-annum'), key: 'lease_annum' },
    { label: t('general.lease-holder'), key: 'lease_holder' },
    { label: t('column-headers.lease-holder-org-nr'), key: 'lease_holder_org_nr' },
    { label: t('property.geoposition'), key: 'coordinates' },

    /*TAXERAD INFORMATION*/
    { label: t('taxation.tax-owners'), key: 'tax_owners' },
    { label: t('column-headers.tax-owners-root-actor'), key: 'tax_owners_root_actor' },
    { label: t('column-headers.tax-area'), key: 'tax_area' },
    { label: t('column-headers.total-tax-value'), key: 'total_tax_value' },
    { label: t('column-headers.value-year'), key: 'value_year' },

    /*UPPSKATTAD HYRA*/
    { label: t('column-headers.office-rent'), key: 'office_rent' },
    { label: t('column-headers.warehouse-rent'), key: 'warehouse_rent' },
    { label: t('column-headers.avg-rent-residential'), key: 'residential_rent' },
    { label: t('column-headers.indexed-rent'), key: 'indexed_rent' },
    { label: t('column-headers.tenant-count-st'), key: 'tenant_count' },
    { label: t('column-headers.employees'), key: 'employee_total' },
    { label: t('column-headers.phone'), key: 'owners_phone' }
  ];

  if (location === 'advanced') {
    return headers.filter((col) => col.key !== 'board' && col.key !== 'list');
  }
  return headers.filter((col) => col.key !== 'owners_phone');
};
