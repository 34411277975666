/* External Libraries */
import { useTranslation } from 'next-i18next';
import { TAX_TYPE_SHORT } from 'shared/dist/constants';
import { parseRightsAndUse } from 'shared/dist/helpers/propertyTaxes';
import { ButtonType, GotoFeature } from 'shared/dist/types';

import { useDialog } from '@/src/context/dialog-context';
import { useSlideIn } from '@/src/context/slide-in-context';
import RowWrapper from '@/src/layout/row-wrapper';
import Button from '@/src/widgets/buttons';
import { FLAmount, FLSqm } from '@/src/widgets/numbers';
import { RowHeader, RowTableWrapper } from '@/src/widgets/row-table-body';

export default function AreaTaxTable({ property }: { property: any; compact?: boolean }) {
  const { setSlideIn } = useSlideIn();
  const { setDialog } = useDialog();
  const { t } = useTranslation('common');

  let total: number = property?.v2?.residential_details?.sqm ?? 0;

  if (property?.v2?.office_details?.sqm > 0 || property?.space?.loa > 0) {
    total += property?.v2?.office_details?.sqm ?? property?.space?.loa;
  }

  if (property?.v2?.warehouse_details?.sqm > 0 || property?.warehouse?.loa > 0) {
    total += property?.v2?.warehouse_details?.sqm ?? property?.warehouse?.loa;
  }

  const { buildingRights, totalBuildingRightArea, buildingUsed } = parseRightsAndUse(
    property?.propertyTax
  );

  return (
    <RowTableWrapper>
      <RowHeader title={t('taxation.heading')} />
      <div>
        <RowWrapper title={t('property.plot-area')} theme={'roomy'}>
          <FLSqm value={property?.area} />
        </RowWrapper>

        {property?.loa > 0 ? (
          <RowWrapper title={t('taxation.total-leasable-area')} theme={'roomy'}>
            <FLSqm value={property?.loa} />
          </RowWrapper>
        ) : null}

        {buildingUsed && buildingUsed?.length > 0 ? <div /> : null}

        {buildingUsed && buildingUsed?.length > 0 && (
          <div className="divide-dashed divide-y pb-2 px-3 pl-4 pt-2 even:bg-gray-50 dark:even:bg-dark-faded dark:text-white text-xs">
            {buildingUsed?.map((br, idx) => {
              return (
                <div key={idx} className="flex py-1 first:pt-0 justify-between">
                  <div className="flex items-start justify-start">
                    <div className="bg-bobo-prop dark:bg-dark-prop mt-[3px] rounded-full w-2 h-2 mr-1" />
                    <div className="capitalize mr-6 leading-[13px]">{br?.unit_type}</div>
                  </div>
                  <div className="text-right flex-1 min-w-[60px]">
                    <FLSqm value={br?.area} />
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {property?.boa > 0 ? (
          <RowWrapper title={t('property.gross-residential-area')} theme={'roomy'}>
            <FLSqm value={property?.boa} />
          </RowWrapper>
        ) : null}

        {buildingRights && buildingRights?.length > 0 ? (
          <RowWrapper
            title={
              <div>
                <div>{t('general.build-right')}</div>
                {/*<div className="text-mini italic">
                  Lantmäteriet: {BUILD_RIGHT_CAT[buildingRights[0]?.classification]}
                </div>*/}
              </div>
            }
            theme={'roomy'}>
            <FLSqm value={totalBuildingRightArea} />
          </RowWrapper>
        ) : null}

        {buildingRights && buildingRights?.length > 0 ? <div /> : null}

        {buildingRights && buildingRights?.length > 0 ? (
          <div className="divide-dashed divide-y pb-2 px-3 pl-4 pt-2 even:bg-gray-50 dark:even:bg-dark-faded text-xs text-gray-700 dark:text-white">
            {buildingRights?.map((br, idx) => {
              return (
                <div key={idx} className="flex py-1 first:pt-0 justify-between">
                  <div className="flex items-start justify-start">
                    <div className="bg-bobo-prop dark:bg-dark-prop mt-[3px] rounded-full w-2 h-2 mr-1" />
                    <div className="capitalize mr-6 leading-[13px]">{br?.unit_type}</div>
                  </div>
                  <div className="text-right flex-1 min-w-[60px]">
                    <FLSqm value={br?.area} />
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}

        <RowWrapper title={t('taxation.tax-year', { taxYear: '' })} theme={'roomy'}>
          {property?.taxYears?.join(', ')}
        </RowWrapper>

        <RowWrapper title={t('general.assessed-value')} theme={'roomy'}>
          <FLAmount value={property?.taxValue * 1000} />
        </RowWrapper>

        <RowWrapper title={t('general.taxed-rental-income')} theme={'roomy'} flip={true}>
          {property?.totalRent > 0 ? (
            <FLAmount value={property?.totalRent * 1000} />
          ) : (
            <span className="italic">{t('general.no-data')}</span>
          )}
        </RowWrapper>

        <RowWrapper title={t('taxation.collective-tax')} theme={'roomy'}>
          {t('property.is-collectively-taxed-plus', { count: property?.samTaxCount })}
        </RowWrapper>

        <RowWrapper title={t('taxation.tax-codes')} theme={'roomy'}>
          <div>
            {property?.taxCodes?.map((tc, idx) => {
              return (
                <div key={idx}>
                  {TAX_TYPE_SHORT[tc]
                    ? t(TAX_TYPE_SHORT[tc]).charAt(0).toUpperCase() + t(TAX_TYPE_SHORT[tc]).slice(1)
                    : null}{' '}
                  ({tc})
                </div>
              );
            })}
          </div>
        </RowWrapper>

        {property?.samTaxProps && property?.samTaxProps?.length > 0 && (
          <div className="w-full text-[12px] even:bg-gray-50 dark:even:bg-dark-faded mt-3">
            <div className="w-full font-medium dark:text-white pl-2.5 pt-3" id="samtax">
              {t('taxation.collectively-taxed-properties')}
            </div>

            {property?.samTaxProps?.map((stp, idx: number) => {
              return (
                <RowWrapper
                  key={idx}
                  title={`${stp?.authorityNickname?.replace(
                    `${property?.county?.toUpperCase()}`,
                    ``
                  )}`}
                  theme={'roomy'}>
                  <div className="flex gap-1">
                    <Button
                      type={ButtonType.INFO}
                      className="!py-1.5 !px-2 w-[70px]"
                      onClick={() => {
                        setSlideIn({
                          object: {
                            uuid: stp?.uuid,
                            point: { x: stp?.lng, y: stp?.lat },
                            lat: stp?.lat,
                            lng: stp?.lng,
                            areaTotal: stp?.propertyArea,
                            authorityNickname: stp?.authorityNickname,
                            addTab: true
                          },
                          type: GotoFeature.PROPERTY
                        });
                      }}>
                      {t('general.preview')}
                    </Button>

                    <Button
                      type={ButtonType.ADD}
                      className="!py-1.5 !px-2 w-[70px]"
                      onClick={(event) => {
                        event.stopPropagation();
                        setDialog({
                          dialogType: 'prompt-crm-dialog-switcher',
                          object: {
                            addEditMode: true,
                            initStep: 20,
                            propertyUUID: stp?.uuid,
                            authorityNickname: stp?.authorityNickname
                          }
                        });
                      }}>
                      {t('general.add')}
                    </Button>
                  </div>
                </RowWrapper>
              );
            })}
          </div>
        )}
      </div>
    </RowTableWrapper>
  );
}
