import moment from 'moment';
import { LocalCouncil } from 'shared/dist/types';

export function compare_timestamp(b: any, a: any): number {
  if (a.timestamp < b.timestamp) {
    return -1;
  }

  if (a.timestamp > b.timestamp) {
    return 1;
  }

  return 0;
}

export function compare_year_month(data) {
  return data.sort((a, b) => {
    // Handle null values by pushing them to the end
    if (a.year_month === null) return 1;
    if (b.year_month === null) return -1;

    // Compare year_month strings
    return a.year_month.localeCompare(b.year_month);
  });
}

export function compare_interval(b: any, a: any): number {
  if (a.interval < b.interval) {
    return 1;
  }

  if (a.interval > b.interval) {
    return -1;
  }

  return 0;
}

export function compare_main(a: any, b: any, ascDir: boolean): number {
  if (ascDir) {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  } else {
    // order === 'desc'
    if (a < b) {
      return 1;
    }
    if (a > b) {
      return -1;
    }
    return 0;
  }
}

export function compare_prop_count(b: any, a: any): number {
  if (a?.value?.root_count < b?.value?.root_count) {
    return -1;
  }

  if (a?.value?.root_count > b?.value?.root_count) {
    return 1;
  }

  return 0;
}

export function compare_tax_value(b: any, a: any): number {
  if (a.total_tax_value < b.total_tax_value) {
    return -1;
  }

  if (a.total_tax_value > b.total_tax_value) {
    return 1;
  }

  return 0;
}

export function compare_index(b: any, a: any): number {
  if (a.index < b.index) {
    return 1;
  }

  if (a.index > b.index) {
    return -1;
  }

  return 0;
}

export function compare_year(b: any, a: any): number {
  if (a.year < b.year) {
    return -1;
  }

  if (a.year > b.year) {
    return 1;
  }

  return 0;
}

export function compare_date_actuals(b: any, a: any): number {
  if (a.date < b.date) {
    return -1;
  }

  if (a.date > b.date) {
    return 1;
  }

  return 0;
}

export function compare_rooms(b: any, a: any): number {
  if (a.rooms > b.rooms) {
    return -1;
  }

  if (a.rooms < b.rooms) {
    return 1;
  }

  return 0;
}

export function compare_indexed_norm(b: any, a: any): number {
  if (a.indexed_rent > b.indexed_rent) {
    return -1;
  }

  if (a.indexed_rent < b.indexed_rent) {
    return 1;
  }

  return 0;
}

export function compare_space_rent(b: any, a: any): number {
  if (
    (a?.properties?.rent && a?.properties?.rent > b?.properties?.rent) ||
    (a?.office_rent && a?.office_rent > b?.office_rent)
  ) {
    return -1;
  }

  if (
    (a?.properties?.rent && a?.properties?.rent < b?.properties?.rent) ||
    (a?.office_rent && a?.office_rent < b?.office_rent)
  ) {
    return 1;
  }

  return 0;
}

export function compare_warehouse_rent(b: any, a: any): number {
  if (a?.warehouse_rent && a?.warehouse_rent > b?.warehouse_rent) {
    return -1;
  }

  if (a?.warehouse_rent && a?.warehouse_rent < b?.warehouse_rent) {
    return 1;
  }

  return 0;
}

export function compare_space_tenants(b: any, a: any): number {
  if (a?.properties?.tenant_count > b?.properties?.tenant_count) {
    return -1;
  }

  if (a?.properties?.tenant_count < b?.properties?.tenant_count) {
    return 1;
  }

  return 0;
}

export function compare_disaster_date(b: any, a: any): number {
  if (moment(a?.properties?.startTime).unix() > moment(b?.properties?.startTime).unix()) {
    return 1;
  }

  if (moment(a?.properties?.startTime).unix() < moment(b?.properties?.startTime).unix()) {
    return -1;
  }

  return 0;
}

export function compare_addressString(b: any, a: any): number {
  console.log(a.addressMatchString);
  if (a.addressMatchString > b.addressMatchString) {
    return -1;
  }

  if (a.addressMatchString < b.addressMatchString) {
    return 1;
  }

  return 0;
}

export function compare_auth_name(b: any, a: any): number {
  if (a.authorityNickname < b.authorityNickname) {
    return 1;
  }

  if (a.authorityNickname > b.authorityNickname) {
    return -1;
  }

  return 0;
}

export function compare_distance(b: any, a: any): number {
  if (a.distance > b.distance) {
    return -1;
  }

  if (a.distance < b.distance) {
    return 1;
  }

  return 0;
}

export function compare_transaction_date(b: any, a: any): number {
  if (a.date ?? a.sortDate < b.date ?? b.sortDate) {
    return -1;
  }

  if (a.date ?? a.sortDate > b.date ?? b.sortDate) {
    return 1;
  }

  return 0;
}

export function compare_deed(b: any, a: any): number {
  if (a.ranking < b.ranking) {
    return 1;
  }

  if (a.ranking > b.ranking) {
    return -1;
  }

  return 0;
}

export function compare_easement(b: any, a: any): number {
  if (a.type < b.type) {
    return 1;
  }

  if (a.type > b.type) {
    return -1;
  }

  return 0;
}

export function compare_deed_asc(b: any, a: any): number {
  if (a.date_from > b.date_from) {
    return -1;
  }

  if (a.date_from < b.date_from) {
    return 1;
  }

  return 0;
}

export function compare_name(a: LocalCouncil, b: LocalCouncil): number {
  if (a.name && b.name) {
    if (a.name < b.name) {
      return -1;
    }

    if (a.name > b.name) {
      return 1;
    }
  }

  return 0;
}

export function compare_council(a: any, b: any): number {
  if (a.name && b.name) {
    if (a.name < b.name) {
      return -1;
    }

    if (a.name > b.name) {
      return 1;
    }
  }

  return 0;
}
