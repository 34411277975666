import { DocumentIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { downloadFile } from '@/src/lib/user-client-wrapper';

export default function ZoningPlan({ object }: { object: any; close: any }) {
  const { t } = useTranslation('common');

  const [planFiles] = useState<string[]>(object.docs.split(','));

  return (
    <div className="flex z-30 h-[100vh] overflow-y-auto flex-col">
      {/* Main */}
      <div>
        <div className="pb-1 sm:pb-6">
          <div>
            <div className="mt-10 overflow-hidden pb-16 px-4">
              <div className="px-6 py-4 mt-6 border-b dark:border-dark-morefaded text-sm text-gray-800">
                <h3 className="text-base font-bold text-gray-900 dark:text-white sm:text-lg uppercase">
                  {object.name}
                </h3>

                <div className="flex justify-start space-x-16 border-t dark:border-dark-morefaded pt-2 mt-2">
                  <div className="text-smaller text-gray-600 dark:text-white flex space-x-2">
                    <div className="font-demi">{t('general.date-created')}:</div>
                    <div>{object.created ?? t('general.unknown')}</div>
                  </div>
                  <div className="text-smaller text-gray-600 dark:text-white flex space-x-2">
                    <div className="font-demi">{t('general.date-legal')}:</div>
                    <div>{object.legalDate ?? t('general.unknown')}</div>
                  </div>
                </div>
                <div className="text-smaller text-gray-600 dark:text-white flex space-x-2">
                  <div className="font-demi">{t('general.building-types')}:</div>
                  <div>{object.buildingTypes ?? t('general.unknown')}</div>
                </div>

                <div className="mt-3 text-smaller border-b dark:border-dark-morefaded pb-2">
                  <div className="w-[150px] font-demi dark:text-white">{t('general.zp-files')}</div>
                  {planFiles.map((filePath: string, index: number) => {
                    const urlArray: string[] = filePath.split(`/`);
                    const fileName: string = urlArray[urlArray.length - 1];

                    return (
                      <button
                        onClick={() => {
                          downloadFile(object.code, fileName, t);
                        }}
                        key={index}
                        className="text-left pl-6 text-bobo-prop font-medium flex space-x-2">
                        <DocumentIcon className="w-4 h-4" />
                        <div>{filePath}</div>
                      </button>
                    );
                  })}
                </div>

                <div className="italic mt-3 p-3 bg-bobo-orangelight border-bobo-orange rounded-md border text-xs dark:text-white">
                  {object.summary ? t('general.plan-ai-showing') : t('general.plan-ai-failed')}
                </div>

                {object.summary ? (
                  <div className="text-smaller text-gray-600 dark:text-white mt-3">
                    <div className="font-demi">{t('general.zp-summary')}</div>
                    <div>{object.summary}</div>
                  </div>
                ) : null}

                {object.allowed ? (
                  <div className="text-smaller text-gray-600 dark:text-white mt-3">
                    <div className="font-demi">{t('general.zp-allowed')}</div>
                    <div>{object.allowed}</div>
                  </div>
                ) : null}

                {object.requirements ? (
                  <div className="text-smaller text-gray-600 dark:text-white mt-3">
                    <div className="font-demi">{t('general.zp-requirements')}</div>
                    <div>{object.requirements}</div>
                  </div>
                ) : null}

                <div className="bg-bobo-verylightgreen py-1 px-4 text-mini text-gray-900 rounded-full inline-flex z-30 mt-4">
                  {t('general.sources', { sources: 'Lantmäteriet, Kommunen' })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
