import moment from 'moment';
import { useTranslation } from 'next-i18next';
import { useGlobal } from 'reactn';
import { Actor } from 'shared/dist/types';

import { commonDataFetchFunction } from '@/src/lib/utils/dataExport';

import DataExportDropdown from '../data-export/dataExportDropdown';
import { getExportHeaders } from '../data-export/export-headers';
import { getExportDataCrm } from '../data-export/get-export-data';

export default function CRMListExportBtn({ list }: { list: any }) {
  const { t } = useTranslation('common');
  const [actor] = useGlobal<{ activeActor: Actor }>('activeActor' as never);

  return (
    <DataExportDropdown
      title={t('crm.list-actions.export-list')}
      fileName={`${actor?.name ?? actor?.company_name} - ${list?.boardName} - ${
        list?.name
      } ${moment().format(`YYYYMMDD`)}`}
      tableHeaders={getExportHeaders(t, 'crm')}
      dataFetchFunction={commonDataFetchFunction}
      down={true}
      data={getExportDataCrm(t, list, true)}
    />
  );
}
