import { Controls, Player } from '@lottiefiles/react-lottie-player';
import { useRef } from 'react';
import BuildingJson from 'src/animations/building.json';
import CREJson from 'src/animations/commercial-property.json';
import ErrorJson from 'src/animations/error-mark.json';
import FloodingJson from 'src/animations/flooding.json';
import GreenJson from 'src/animations/green-energy.json';
import ScooterJson from 'src/animations/scooter.json';
import SuccessJson from 'src/animations/success-mark.json';

export function Scooter() {
  return (
    <Player autoplay loop src={ScooterJson} style={{ height: '130px', width: '231px' }}>
      <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
    </Player>
  );
}

export function Flooding() {
  return (
    <Player autoplay loop src={FloodingJson} style={{ height: '220px', width: '300px' }}>
      <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
    </Player>
  );
}

export function CRE({ width = '300px', height = '220px' }: { height?: string; width?: string }) {
  return (
    <Player autoplay loop src={CREJson} style={{ height, width }}>
      <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
    </Player>
  );
}

export function GreenEnergy({ size = '100px' }: { size?: string }) {
  return (
    <Player autoplay loop src={GreenJson} style={{ height: size, width: size }}>
      <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
    </Player>
  );
}

export function Building() {
  return (
    <Player autoplay loop src={BuildingJson} style={{ height: '250px', width: '365px' }}>
      <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
    </Player>
  );
}

export function Success() {
  return (
    <Player autoplay keepLastFrame src={SuccessJson} style={{ height: '130px', width: '265px' }}>
      <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
    </Player>
  );
}

export function Error() {
  const ref: any = useRef(null);

  return (
    <Player
      autoplay
      keepLastFrame
      ref={ref}
      src={ErrorJson}
      style={{ height: '130px', width: '265px' }}>
      <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
    </Player>
  );
}
