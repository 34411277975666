import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useGlobal } from 'reactn';
import { Actor, Actor2, ButtonType } from 'shared/dist/types';
import { Error as ErrorAnimation, Scooter } from 'src/animations';

import { crmDialogTheme } from '@/src/components/dialogs/crm/dialog-theme';
import { useAccess } from '@/src/context/access-features-context';
import { getReport } from '@/src/lib/prop-client-wrapper';
import Button from '@/src/widgets/buttons';

const stepNames = {
  intro: 1,
  loading: 5,
  error: 6
};

function IntroStep({
  close,
  property,
  customerPlan,
  generateReport,
  setStep,
  setErrorMsg
}: {
  close: any;
  property: any;
  customerPlan?: string;
  generateReport: () => void;
  setStep: (step: number) => void;
  setErrorMsg: (msg: string | JSX.Element) => void;
}) {
  const { accessFeatures, unlockAccessFeatures } = useAccess();
  const { t } = useTranslation('common');

  const isUnlocked = accessFeatures?.bank_report;
  const reportPrice = 1000;

  let mod = (
    <>
      <div className={crmDialogTheme.icon}></div>
      <div className="flex flex-col w-full text-center items-center">
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          {t('reports.env-and-bank-report.annual-title')}
        </Dialog.Title>
        <p className={crmDialogTheme.subtext}>
          {t('reports.env-and-bank-report.annual-text-1-bank')}
        </p>
        <p className={crmDialogTheme.subtext}>
          {t('reports.env-and-bank-report.annual-text-2-bank', { price: reportPrice })}
        </p>
        <p className={crmDialogTheme.subtext}>{t('reports.env-and-bank-report.annual-text-3')}</p>
      </div>

      <div className={crmDialogTheme.buttonContainer}>
        <Button type={ButtonType.CANCEL} onClick={() => close()}>
          {t('general.cancel')}
        </Button>
      </div>
    </>
  );

  if (customerPlan && customerPlan === 'annual' && !isUnlocked) {
    mod = (
      <>
        <div className={crmDialogTheme.icon}></div>
        <div className="flex flex-col w-full text-center items-center">
          <Dialog.Title as="h3" className={crmDialogTheme.title}>
            <Trans
              i18nKey="reports.env-and-bank-report.unlock-report-for-prop"
              values={{
                reportType: t('reports.env-and-bank-report.bank-report-title'),
                property: property.authority_nickname
              }}
              components={[<div key={0} className="text-bobo-prop dark:text-dark-prop" />]}
            />
          </Dialog.Title>
          <p className={crmDialogTheme.subtext}>
            {t('reports.env-and-bank-report.unlock-report-text-1-bank')}
          </p>
          <p className={crmDialogTheme.subtext}>
            {t('reports.env-and-bank-report.unlock-report-text-2-bank')}
          </p>
          <p className={crmDialogTheme.subtext}>
            {t('reports.env-and-bank-report.unlock-report-text-3-bank')}
          </p>
        </div>

        <div className={crmDialogTheme.buttonContainer}>
          <Button type={ButtonType.CANCEL} onClick={() => close()}>
            {t('general.cancel')}
          </Button>

          <Button
            type={ButtonType.ADD}
            onClick={() => {
              setStep(stepNames.loading);
              unlockAccessFeatures('bank_report', property.uuid)
                .then(() => {
                  setStep(stepNames.intro);
                })
                .catch(() => {
                  setErrorMsg(t('errors.report-unlock-error'));
                  setStep(stepNames.error);
                });
            }}>
            {t('reports.env-and-bank-report.unlock-report-button', { price: reportPrice })}
          </Button>
        </div>
      </>
    );
  }

  if (isUnlocked) {
    mod = (
      <>
        <div className={crmDialogTheme.icon}></div>
        <div className={crmDialogTheme.textContainer}>
          <Dialog.Title as="h3" className={crmDialogTheme.title}>
            <Trans
              i18nKey="reports.env-and-bank-report.get-report-for-prop"
              values={{
                reportType: t('reports.env-and-bank-report.bank-report-title'),
                property: property.authority_nickname
              }}
              components={[<div key={0} className="text-bobo-prop dark:text-dark-prop" />]}
            />
          </Dialog.Title>
          <p className={crmDialogTheme.subtext}>
            {t('reports.env-and-bank-report.get-report-text-1')}
          </p>
          <p className={crmDialogTheme.subtext}>
            <Trans
              i18nKey="reports.env-and-bank-report.get-report-text-2"
              t={t}
              components={[
                <Link
                  className="text-bobo-prop dark:text-dark-prop hover:text-bobo-prophover dark:hover:text-dark-prophover transition-colors duration-200"
                  href="/companyprofile/settings"
                  onClick={close}
                  key={0}
                />
              ]}
            />
          </p>
        </div>

        <div className={crmDialogTheme.buttonContainer}>
          <Button type={ButtonType.CANCEL} onClick={() => close()}>
            {t('general.cancel')}
          </Button>

          <Button
            type={ButtonType.ADD}
            onClick={() => {
              generateReport();
            }}>
            {t('reports.env-and-bank-report.get-report', {
              reportType: t('reports.env-and-bank-report.bank-report-title')
            })}
          </Button>
        </div>
      </>
    );
  }

  return <div className={crmDialogTheme.container}>{mod}</div>;
}

function LoadingStep({ intro }: { intro?: boolean }) {
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Scooter />
      </div>
      <div className={crmDialogTheme.textContainer}>
        {intro ? (
          <>
            <Dialog.Title as="h3" className={crmDialogTheme.title}>
              {t('general.loading')}...
            </Dialog.Title>
          </>
        ) : (
          <>
            <Dialog.Title as="h3" className={crmDialogTheme.title}>
              {t('reports.loading-report')}
            </Dialog.Title>
            <p className={crmDialogTheme.subtext}>{t('reports.loading-report-desc')}</p>
          </>
        )}
      </div>
    </div>
  );
}

function ErrorStep({ close, errorMsg }: { close: any; errorMsg: string | JSX.Element }) {
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.icon}>
        <ErrorAnimation />
      </div>
      <div className="flex flex-col w-full text-center items-center">
        <Dialog.Title as="h3" className="text-red-700 w-3/4 leading-6">
          {errorMsg}
        </Dialog.Title>

        <p className={crmDialogTheme.subtext}>{t('errors.try-again')}</p>
      </div>

      <div className={crmDialogTheme.buttonContainer}>
        <Button
          type={ButtonType.CANCEL}
          onClick={() => close()}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              close();
            }
          }}>
          {t('general.close')}
        </Button>
      </div>
    </div>
  );
}

/**
 * dialog for adding new and editing list
 */
export default function CreateBankValuationReport({ close, object }: { close: any; object: any }) {
  const [step, setStep] = useState(stepNames.loading);
  const [activePlan, setActivePlan] = useState<string>();
  const [errorMsg, setErrorMsg] = useState<string | JSX.Element>(
    <Trans
      i18nKey="errors.report-generation-error-for-prop"
      values={{ property: object.property.authority_nickname }}
      components={[<div key={0} className="text-bobo-prop dark:text-dark-prop" />]}
    />
  );
  const [loading, setLoading] = useState<boolean>(true);

  const [actor] = useGlobal<{ activeActor: Actor2 }>('activeActor' as never);
  const { t } = useTranslation('common');
  const router = useRouter();

  const { property } = object;

  useEffect(() => {
    fetch(`/api/profile/getProfileData`)
      .then((response) => response.json())
      .then((data) => {
        const actorData: { actor: Actor } = data.userActors.find(
          (a: { actor: Actor }) => a.actor.uuid === actor.uuid
        );
        if (!actorData.actor.plan_name) {
          setActivePlan('test');
        }

        setActivePlan(actorData.actor.plan_name);
        setStep(stepNames.intro);
      })
      .catch((error) => {
        setErrorMsg(t('errors.actor-subscription-error'));
        setStep(stepNames.error);
      })
      .finally(() => setLoading(false));
  }, [actor]);

  const generateReport = () => {
    getReport('bank', property?.uuid, router, t);
    toast.success(
      t('reports.env-and-bank-report.report-created', {
        reportType: t('reports.env-and-bank-report.bank-report-title'),
        property: property?.authority_nickname
      })
    );
    close();
  };

  let mod: JSX.Element = <div />;

  switch (step) {
    case stepNames.intro:
      mod = (
        <IntroStep
          property={property}
          close={close}
          customerPlan={activePlan}
          generateReport={generateReport}
          setStep={setStep}
          setErrorMsg={setErrorMsg}
        />
      );
      break;

    case stepNames.loading:
      mod = <LoadingStep intro={loading} />;
      break;

    case stepNames.error:
      mod = <ErrorStep close={close} errorMsg={errorMsg} />;
      break;
  }

  return (
    <>
      <button onClick={close} className={crmDialogTheme.closeContainerX}>
        <XMarkIcon className="h-5 w-5" />
      </button>
      {mod}
    </>
  );
}
