import { PropertyTypeGroup } from 'shared/dist/types';
import { getGroupForObjectType } from 'shared/dist/util/calculations';

import { getPrimaryColorForMap } from '../../../colors';
import {
  TYPES_HOUSING,
  TYPES_INDUSTRY,
  TYPES_OFFICE,
  TYPES_PRIVATE,
  TYPES_SPECIAL,
  TYPES_STREET,
  TYPES_WAREHOUSE
} from '../../../constants';

export default function buildingBasic(
  map: any,
  object: any,
  propertyTypes?: PropertyTypeGroup[],
  selectedObject?: any
) {
  const groupType: PropertyTypeGroup = getGroupForObjectType(
    object?.taxCodes && object?.taxCodes?.length > 0 ? object?.taxCodes[0] : 320
  );
  const opacity = 0.4;
  const main: string = getPrimaryColorForMap(groupType, 1);
  const fallBackColor = `rgba(100, 100, 100, ${opacity})`;

  if (selectedObject?.current) {
    if (!map?.getMap()?.getLayer('buildings') && map?.getMap()?.getSource('composite')) {
      map?.getMap()?.addLayer(
        {
          id: 'buildings',
          type: 'fill',
          source: 'composite',
          'source-layer': 'buildings', // reference the data source
          layout: {},
          paint: {
            'fill-color': [
              'match',
              ['get', 'property_uuid'],
              [selectedObject?.current],
              main,
              [
                'match',
                ['get', 'tax_code'],
                TYPES_PRIVATE,
                propertyTypes?.includes(PropertyTypeGroup.PRIVATE)
                  ? getPrimaryColorForMap(PropertyTypeGroup.PRIVATE, opacity)
                  : fallBackColor,
                TYPES_HOUSING,
                propertyTypes?.includes(PropertyTypeGroup.HOUSING)
                  ? getPrimaryColorForMap(PropertyTypeGroup.HOUSING, opacity)
                  : fallBackColor,
                TYPES_OFFICE,
                propertyTypes?.includes(PropertyTypeGroup.SPACE)
                  ? getPrimaryColorForMap(PropertyTypeGroup.SPACE, opacity)
                  : fallBackColor,
                TYPES_WAREHOUSE,
                propertyTypes?.includes(PropertyTypeGroup.WAREHOUSE)
                  ? getPrimaryColorForMap(PropertyTypeGroup.WAREHOUSE, opacity)
                  : fallBackColor,
                TYPES_INDUSTRY,
                propertyTypes?.includes(PropertyTypeGroup.INDUSTRY)
                  ? getPrimaryColorForMap(PropertyTypeGroup.INDUSTRY, opacity)
                  : fallBackColor,
                TYPES_SPECIAL,
                propertyTypes?.includes(PropertyTypeGroup.SPECIAL)
                  ? getPrimaryColorForMap(PropertyTypeGroup.SPECIAL, opacity)
                  : fallBackColor,
                TYPES_STREET,
                propertyTypes?.includes(PropertyTypeGroup.STREET)
                  ? getPrimaryColorForMap(PropertyTypeGroup.STREET, opacity)
                  : fallBackColor,
                fallBackColor
              ]
            ],
            'fill-opacity': 1
          }
        },
        'tunnel-minor-case'
      );
    }
  } else {
    try {
      if (
        map?.getMap() !== undefined &&
        !map?.getMap()?.getLayer('buildings') &&
        map?.getMap()?.getSource('buildings')
      ) {
        map?.getMap()?.addLayer(
          {
            id: 'buildings',
            type: 'fill',
            source: 'buildings',
            'source-layer': 'buildings', // reference the data source
            layout: {},
            paint: {
              'fill-color': [
                'case',
                ['boolean', ['feature-state', 'hidden'], false],
                'rgba(255, 255, 255, 1)',
                [
                  'match',
                  ['get', 'tax_code'],
                  TYPES_PRIVATE,
                  propertyTypes?.includes(PropertyTypeGroup.PRIVATE)
                    ? getPrimaryColorForMap(PropertyTypeGroup.PRIVATE, opacity)
                    : fallBackColor,
                  TYPES_HOUSING,
                  propertyTypes?.includes(PropertyTypeGroup.HOUSING)
                    ? getPrimaryColorForMap(PropertyTypeGroup.HOUSING, opacity)
                    : fallBackColor,
                  TYPES_OFFICE,
                  propertyTypes?.includes(PropertyTypeGroup.SPACE)
                    ? getPrimaryColorForMap(PropertyTypeGroup.SPACE, opacity)
                    : fallBackColor,
                  TYPES_WAREHOUSE,
                  propertyTypes?.includes(PropertyTypeGroup.WAREHOUSE)
                    ? getPrimaryColorForMap(PropertyTypeGroup.WAREHOUSE, opacity)
                    : fallBackColor,
                  TYPES_INDUSTRY,
                  propertyTypes?.includes(PropertyTypeGroup.INDUSTRY)
                    ? getPrimaryColorForMap(PropertyTypeGroup.INDUSTRY, opacity)
                    : fallBackColor,
                  TYPES_SPECIAL,
                  propertyTypes?.includes(PropertyTypeGroup.SPECIAL)
                    ? getPrimaryColorForMap(PropertyTypeGroup.SPECIAL, opacity)
                    : fallBackColor,
                  TYPES_STREET,
                  propertyTypes?.includes(PropertyTypeGroup.STREET)
                    ? getPrimaryColorForMap(PropertyTypeGroup.STREET, opacity)
                    : fallBackColor,
                  fallBackColor
                ]
              ],
              'fill-opacity': 1 //passiveHeight === 0 ? 0.5 : 0.5
            }
          },
          'tunnel-minor-case'
        );
      }
    } catch (e) {
      console.log(`Error`, e);
    }
  }
}
