import { useUser } from '@auth0/nextjs-auth0';
import Intercom from '@intercom/messenger-js-sdk';
import { Regions } from '@intercom/messenger-js-sdk/dist/types';
import cls from 'classnames';
import { getCookie } from 'cookies-next';
import { LayoutGroup, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { i18n, useTranslation } from 'next-i18next';
import { useEffect, useMemo, useState } from 'react';
import { useGlobal } from 'reactn';
/* Components & Widgets */
import { Actor, ActorUsers, UserProfileActor } from 'shared/dist/types';
import { useDialog } from 'src/context/dialog-context';
import { useSlideIn } from 'src/context/slide-in-context';
import ContentContainer from 'src/layout/content-container';
import Footer from 'src/layout/footer';
import GlobalDialog from 'src/layout/global-dialog';
import Header from 'src/layout/header';
import MainMenu from 'src/layout/menu/main-menu';
import GlobalSlideIn from 'src/layout/slide-in';
import {
  getOrPromptActiveActor,
  getUserHash,
  getUserProfile,
  saveActiveActor
} from 'src/lib/user-client-wrapper';
import { useScrollWithShadow } from 'src/lib/useScrollWithShadow';
import ToastMod from 'src/widgets/toaster';

import { getActorUsers } from '../lib/customer-client-wrapper';

export default function Container({
  children,
  header = true,
  fade = true,
  bgClass,
  contentContainerClasses,
  subHeader = true,
  switchUrl,
  title = ``,
  section = ``,
  image = ``,
  subTitle = ``,
  mapOnly = false,
  backUrl = null,
  tabsRight = null,
  hideTabMenu = false,
  object = null,
  reduceSecondaryRow = false,
  subMenu,
  intercomEnabled = true
}: {
  children?: JSX.Element | JSX.Element[];
  header?: boolean;
  fade?: boolean;
  bgClass?: string;
  contentContainerClasses?: string;
  subHeader?: boolean;
  title?: string;
  image?: string;
  switchUrl?: string;
  section?: string | JSX.Element;
  subTitle?: string | JSX.Element;
  mapOnly?: boolean;
  backUrl?: string | null;
  tabsRight?: null | JSX.Element;
  hideTabMenu?: boolean;
  object?: any;
  reduceSecondaryRow?: boolean;
  subMenu?: JSX.Element | null;
  intercomEnabled?: boolean;
}): JSX.Element {
  const router = useRouter();
  const { user } = useUser();
  const [activeActor, setActiveActor] = useGlobal<{ activeActor: Actor }>('activeActor' as never);
  const [activeActorUsers, setActiveActorUsers] = useGlobal<{
    activeActorUsers: ActorUsers;
  }>('activeActorUsers' as never);
  const [teamRefreshTrigger, setTeamRefreshTrigger] = useGlobal<any>('teamRefreshTrigger' as never);
  const [_, setUserActors] = useGlobal<{ userActors: UserProfileActor[] }>('userActors' as never);
  const [hash, setHash] = useState<string>();

  const { slideIn, setSlideIn } = useSlideIn();
  const { boxShadow, onScrollHandler } = useScrollWithShadow();
  const { dialog, setDialog } = useDialog();
  const slideInCondition = slideIn && slideIn.object && slideIn.type;

  const { t } = useTranslation('common');

  const dialogCondition = dialog && dialog.object && dialog.dialogType;

  const intercomSettings = useMemo(() => {
    const languageCookie = getCookie('NEXT_LOCALE');

    if (user?.email && user?.name && activeActor && hash) {
      return {
        app_id: process.env.INTERCOM_APP_ID ?? 'pmusdk3t', // defaults to intercom production workspace
        region: 'eu' as Regions,
        api_base: 'https://api-iam.eu.intercom.io/',
        email: user.email,
        name: user.name,
        language_override: typeof languageCookie === 'string' ? languageCookie : 'sv',
        company: {
          name: activeActor?.name ?? activeActor?.company_name,
          id: activeActor?.actor_id
        },
        user_hash: hash
      };
    }
  }, [user, activeActor, hash]);

  useEffect(() => {
    if (hash) return;

    getUserHash(t).then(setHash);
  }, [t, hash]);

  useEffect(() => {
    if (!user) return;
    if (activeActor) return;

    getUserProfile(t).then((userProfile) => {
      setUserActors(userProfile.userActors);
      const activeActorInDb = userProfile.userActors.find(
        (ua) => ua.actor.actor_uuid === userProfile.user.active_actor
      );

      if (activeActorInDb) {
        setActiveActor(activeActorInDb.actor);
      } else {
        getOrPromptActiveActor(setDialog, t).then(updateActiveActor);
      }
    });
  }, [user, activeActor]);

  useEffect(() => {
    let userLanguage = getCookie('NEXT_LOCALE');

    if (!userLanguage) {
      const userDefault = router.locales?.find((locale) => locale === router.defaultLocale);

      if (userDefault) {
        userLanguage = userDefault;
      } else {
        userLanguage = 'sv';
      }
    }

    if (router.locale !== userLanguage) {
      const { pathname, asPath, query } = router;

      router.push({ pathname, query }, asPath, { locale: userLanguage as string });
      i18n?.changeLanguage(userLanguage);
    }
  }, [router]);

  useEffect(() => {
    if (intercomSettings && (intercomEnabled ?? true)) {
      Intercom(intercomSettings);
    }
  }, [intercomSettings]);

  async function setActorUsers(actor: Actor) {
    if (actor && actor.actor_uuid) {
      const teamData: ActorUsers = await getActorUsers(actor.actor_uuid, t);
      setActiveActorUsers(teamData);
    }
  }

  // get active actor users
  useEffect(() => {
    if (activeActor && !activeActorUsers.length) {
      setActorUsers(activeActor);
    }
  }, [activeActor]);

  useEffect(() => {
    if (teamRefreshTrigger) {
      setActorUsers(activeActor).finally(() => setTeamRefreshTrigger(false));
    }
  }, [teamRefreshTrigger]);

  const updateActiveActor = async (actor: Actor | null) => {
    if (!actor) return;

    if (actor.actor_uuid !== activeActor?.actor_uuid) {
      await saveActiveActor(actor, t);
      setActiveActor(actor);
      setActorUsers(actor);
    }
  };

  return (
    <div className={`absolute flex w-full max-w-full h-full top-0`}>
      {user && <MainMenu user={user} />}

      <div
        className={`flex-1 grid grid-rows-main grid-cols-[100%] h-[100dvh] w-full md:ml-[52px]`}
        id="content-wrapper">
        <ToastMod />

        <div className="block w-full md:hidden">
          {header && <Header subHeader={subHeader} boxShadow={boxShadow} />}
        </div>

        <div className={cls('flex-1 flex relative w-full h-full', bgClass)} id="main-content">
          <main className="relative flex flex-1 min-w-[100%]" data-test="main-area">
            <GlobalSlideIn
              type={slideInCondition ? slideIn.type : false}
              isOpen={slideInCondition ? !!slideIn.object : false}
              slideIn={slideInCondition ? slideIn : null}
              close={() => setSlideIn(null)}
            />

            <div
              onScroll={onScrollHandler}
              className={`relative flex-1 flex flex-col md:flex-row w-full md:h-[100dvh]`}
              data-neat="main-area">
              <GlobalDialog
                type={dialog?.dialogType}
                isOpen={dialogCondition ? !!dialog.dialogType : false}
                object={dialogCondition ? dialog.object : {}}
                closeable={dialogCondition ? (dialog?.closeable ?? true) : true}
                closeableByOutsideClick={
                  dialogCondition ? (dialog?.closeableByOutsideClick ?? true) : true
                }
                dialogStyle={dialog?.dialogStyle ?? {}}
                close={() => {
                  if (dialog?.onClose) {
                    dialog?.onClose();
                  }
                  setDialog(null);
                }}
              />
              {(!!user && !!activeActor) || !user ? (
                <ContentContainer
                  reduceSecondaryRow={reduceSecondaryRow}
                  image={image}
                  section={section}
                  titleRight={subTitle}
                  switchUrl={switchUrl}
                  bgClass={bgClass}
                  header={header}
                  tabsRight={tabsRight}
                  backUrl={backUrl}
                  contentContainerClasses={contentContainerClasses}
                  mapOnly={mapOnly}
                  hideTabMenu={hideTabMenu}
                  title={title}
                  subMenu={subMenu}
                  object={object}>
                  <LayoutGroup>
                    <motion.div
                      initial={{ opacity: fade ? 0.75 : 1 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: fade ? 0.75 : 1, transition: { duration: 0.35 } }}
                      transition={{ duration: 0.3, delay: 0.1 }}
                      aria-labelledby="primary-heading"
                      className={cls(
                        'min-w-0 flex-1 relative bg-light-faded dark:bg-dark-lessfaded dark:rounded-tl-lg transition flex flex-col lg:order-last h-full w-full flex-grow',
                        bgClass
                      )}>
                      <div className="h-full dark:rounded-b-lg">
                        {children}
                        {!user && <Footer />}
                      </div>
                    </motion.div>
                  </LayoutGroup>
                </ContentContainer>
              ) : (
                ''
              )}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
