import { Switch } from '@headlessui/react';
import cls from 'classnames';
import { useTranslation } from 'next-i18next';
import { ValueCategoryTypes } from 'shared/dist/types';
import { CashflowLease } from 'shared/dist/types/cashflow/cashflow-model';
import { NewsecCategory } from 'shared/dist/types/rent';

import DropdownMenu from '@/src/layout/components/dropdown-menu';
import InfoButton from '@/src/widgets/info-button';
import { FLCustom } from '@/src/widgets/numbers';

import { CalculatorRow } from '../../valuation-tool/items/calculator-row';
import {
  headerCellStyle,
  rowCellStyle,
  taxCategories,
  valueCategories
} from '../../valuation-tool/items/constants';
import { calculatePropertyTax, calculatePropertyValue } from '../../valuation-tool/items/functions';
import { handleMaxInputLimit } from '../constants/calculator-functions';
import { MAX_LIMIT_TAX_VALUE_INPUT, PROPERTY_TAX_PER_APARTMENT_LIMIT } from '../constants/general';

export const TaxDescription = ({
  tax,
  apartmentCount,
  countPropertyTax
}: {
  tax: any;
  apartmentCount: number;
  countPropertyTax: boolean;
}) => {
  const { t } = useTranslation('common');
  if (countPropertyTax) {
    const descString = tax.title.split('.').pop();

    return (
      <div className="flex flex-col gap-1 text-mini px-6 font-normal text-gray-400 leading-3">
        <div className="font-semibold">{t(tax.title)}</div>
        <div>
          {t(`value-calculator.tax-description.${descString}`, {
            maxTax: PROPERTY_TAX_PER_APARTMENT_LIMIT
          })}
        </div>
        {(descString === 'housing-old' || descString === 'housing-medium') && (
          <div>
            {t('value-calculator.tax-description.housing-old-desc-apartment-count', {
              apartmentCount
            })}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="text-gray-400 text-mini px-6 font-normal">
      {t('value-calculator.tax-deactivated')}
    </div>
  );
};

export default function AssumptionsCalculations({
  values,
  defaultValues,
  changeValue,
  result,
  area,
  propType,
  rentRoll,
  tabName,
  countPropertyTax,
  setCountPropertyTax,
  tax,
  taxValue,
  changePropertyTax,
  viewLease,
  errorState,
  apartmentCount
}: {
  values: any[];
  defaultValues: any;
  changeValue: (...args) => void;
  result: any;
  area: number;
  propType: NewsecCategory | 'other';
  rentRoll: CashflowLease[];
  tabName: string;
  countPropertyTax: boolean;
  setCountPropertyTax: (arg: boolean) => void;
  tax: any;
  taxValue: number;
  changePropertyTax: (type: any, value: number) => void;
  viewLease: (lease: CashflowLease | undefined) => void;
  errorState: any;
  apartmentCount: number;
}) {
  const { t } = useTranslation('common');

  const calculateResult = () => {
    if (area === 0 || (propType !== NewsecCategory.HOUSING && rentRoll?.length === 0)) {
      return 0;
    }

    const rentalYieldPercent = result[ValueCategoryTypes.YIELD];
    const costs =
      result[ValueCategoryTypes.COSTS] +
      result[ValueCategoryTypes.MAINTENANCE] +
      (countPropertyTax ? calculatePropertyTax(tax, taxValue, apartmentCount) : 0);
    let rentalIncome = result[ValueCategoryTypes.RENT];

    const nonVacantPercent = 100 - result[ValueCategoryTypes.VACANCY];
    rentalIncome = (rentalIncome * nonVacantPercent) / 100;

    return calculatePropertyValue(rentalYieldPercent, rentalIncome, costs);
  };

  const calculateNetResult = () => {
    if (area === 0 || (propType !== NewsecCategory.HOUSING && rentRoll?.length === 0)) {
      return 0;
    }

    const propTax: number = countPropertyTax
      ? calculatePropertyTax(tax, taxValue, apartmentCount)
      : 0;
    let rentalIncome: number = result[ValueCategoryTypes.RENT];

    const nonVacantPercent = 100 - result[ValueCategoryTypes.VACANCY];
    rentalIncome = (rentalIncome * nonVacantPercent) / 100;

    return (
      rentalIncome -
      result[ValueCategoryTypes.COSTS] -
      result[ValueCategoryTypes.MAINTENANCE] -
      propTax
    );
  };

  const renderTaxTypes = (closeMenu) =>
    taxCategories?.map((taxType, idx) => {
      return (
        <div
          key={idx}
          className={cls(
            'py-3 px-5 transition-colors duration-200 hover:bg-bobo-proplight dark:hover:bg-dark-prophover text-bobo-prop dark:text-white hover:text-bobo-prophover text-xs w-full',
            {
              '!bg-bobo-prop dark:!bg-dark-prop !text-white': taxType === tax
            }
          )}>
          <button
            onClick={() => {
              changePropertyTax(taxType, taxValue);
              closeMenu();
            }}
            className="disabled:text-gray-300 w-full h-full">
            {t(taxType.title)}
          </button>
        </div>
      );
    }) ?? [<div key={1} />];

  return (
    <div>
      <div className="flex items-stretch justify-between border-x border-t border-gray-100 w-full overflow-hidden text-xs bg-gray-100 dark:bg-dark-faded dark:border-dark-morefaded">
        <div className={`${headerCellStyle} w-1/6`}></div>
        <div className={`${headerCellStyle} w-3/6`}>{t('value-calculator.levels')}</div>
        <div className={`${headerCellStyle} w-1/6 relative`}>
          {t('value-calculator.matrixes')}
          <div className="absolute right-2">
            <InfoButton helpTextId="marketDataNewsec" />
          </div>
        </div>
        <div className={`${headerCellStyle} w-1/6`}>{t('general.sum')}</div>
      </div>

      {values &&
        values
          ?.filter(
            (cat) =>
              cat.category === ValueCategoryTypes.VACANCY ||
              cat.category === ValueCategoryTypes.RENT
          )
          ?.map((cat, idx) => {
            return (
              <CalculatorRow
                key={idx}
                valueCategory={valueCategories[cat.category]}
                changeValue={changeValue}
                selectedTab={tabName}
                tabValues={cat}
                defaultValues={null}
                result={result}
                costValues={
                  cat.category === ValueCategoryTypes.COSTS
                    ? values.filter((category) => category.subcost)
                    : []
                }
                defaultRowValues={defaultValues[cat.category]}
                area={area}
                rentRoll={propType !== NewsecCategory.HOUSING ? rentRoll : undefined}
                viewLease={viewLease}
                errorState={errorState}
              />
            );
          })}

      <div className="flex items-stretch justify-between border-l border-gray-100 dark:border-dark-morefaded">
        <div className={`${rowCellStyle} !font-medium w-1/6 !bg-gray-50 dark:!bg-dark-faded`}>
          {t(ValueCategoryTypes.RENT)}
          {t('value-calculator.net')}
        </div>
        <div className={`${rowCellStyle} !font-medium w-4/6 !bg-gray-50 dark:!bg-dark-faded`} />
        <div className={`${rowCellStyle} !font-medium w-1/6 !bg-gray-50 dark:!bg-dark-faded`}>
          <FLCustom
            value={
              Math.round(
                (result[ValueCategoryTypes.RENT] *
                  ((100 - result[ValueCategoryTypes.VACANCY]) / 100)) /
                  1000
              ) ?? 0
            }
            suffix={t('general.thousand-suffix')}
            prefix={t('general.sek-prefix')}
          />
        </div>
      </div>

      {values &&
        values
          ?.filter(
            (cat) =>
              !valueCategories[cat.category].subcost &&
              cat.category !== ValueCategoryTypes.YIELD &&
              cat.category !== ValueCategoryTypes.VACANCY &&
              cat.category !== ValueCategoryTypes.RENT
          )
          ?.map((cat, idx) => {
            return (
              <CalculatorRow
                key={idx}
                valueCategory={valueCategories[cat.category]}
                changeValue={changeValue}
                selectedTab={tabName}
                tabValues={cat}
                defaultValues={cat.category === ValueCategoryTypes.COSTS ? defaultValues : null}
                result={result}
                costValues={
                  cat.category === ValueCategoryTypes.COSTS
                    ? values.filter((category) => category.subcost)
                    : []
                }
                defaultRowValues={defaultValues[cat.category]}
                area={area}
              />
            );
          })}

      <div className="flex items-stretch justify-between border-x border-gray-100 dark:border-dark-morefaded dark:bg-dark-lessfaded">
        <div
          className={`${rowCellStyle} !font-medium w-1/6 relative border-r border-gray-100 dark:border-dark-morefaded`}>
          <div>{t('value-calculator.prop-tax')}</div>
          <Switch
            checked={countPropertyTax}
            onChange={() => setCountPropertyTax(!countPropertyTax)}
            className={`${
              countPropertyTax
                ? 'bg-bobo-prop dark:bg-dark-prop'
                : 'bg-gray-300 dark:bg-dark-morefaded'
            }
                      relative mt-1 inline-flex h-[20px] w-[40px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}>
            <span className="sr-only">{countPropertyTax}</span>

            <span
              aria-hidden="true"
              className={`${countPropertyTax ? 'translate-x-5' : 'translate-x-0'}
                        pointer-events-none inline-block h-[17px] w-[17px] transform rounded-full bg-white dark:bg-dark-primary shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>

          <div className="absolute right-2 top-2">
            <InfoButton helpTextId="valuationToolTax" />
          </div>
        </div>

        <div className={`${rowCellStyle} !font-medium w-3/6`}>
          <TaxDescription
            tax={tax}
            apartmentCount={apartmentCount}
            countPropertyTax={countPropertyTax}
          />
        </div>

        <div
          className={`${rowCellStyle} !font-medium w-1/6 gap-3 p-2 h-[120px] border-x border-gray-100 dark:border-dark-morefaded`}>
          <div className="flex flex-col text-xs font-normal items-start justify-between w-full relative">
            <div
              className={cls('duration-200 transition-colors', {
                'text-gray-400': !countPropertyTax
              })}>
              {t('value-calculator.prop-type')}
            </div>

            <DropdownMenu
              title={t(tax?.title)}
              disabled={!countPropertyTax}
              renderMenuItems={renderTaxTypes}
              className="!w-[180px]"
              buttonClassName="!h-[24px] overflow-hidden text-gray-400"
            />
          </div>

          <div className="flex flex-col text-xs font-normal items-start justify-between w-full">
            <div
              className={cls('duration-200 transition-colors', {
                'text-gray-400': !countPropertyTax
              })}>
              {t('value-calculator.tax-value')}
            </div>

            <div className="text-gray-400 flex items-center justify-between gap-1 leading-3 rounded-md border border-gray-100 dark:hover:border-dark-prop dark:bg-dark-primary dark:border-dark-morefaded hover:border-bobo-prop transition-colors duration-200 px-2 py-0.5 w-[180px]">
              <div className="flex items-center">
                {t('general.sek-prefix').length > 0 ? (
                  <div className="mr-1">{t('general.sek-prefix')}</div>
                ) : null}

                <input
                  type={'number'}
                  value={taxValue}
                  onChange={({ target }) => {
                    changePropertyTax(tax, +target.value);
                  }}
                  onInput={(e) => handleMaxInputLimit(e, MAX_LIMIT_TAX_VALUE_INPUT)}
                  className="border-none p-0 m-0 text-xs text-gray-400 dark:bg-dark-primary w-full"
                />
              </div>

              <div>{t('general.thousand-suffix')}</div>
            </div>
          </div>
        </div>

        <div
          className={cls(`${rowCellStyle} !font-medium w-1/6  duration-200 transition-colors`, {
            'text-gray-400': !countPropertyTax
          })}>
          <div className="text-gray-400">{t('value-calculator.prop-tax-sqm')}</div>
          <FLCustom
            value={
              area > 0 ? Math.round(-calculatePropertyTax(tax, taxValue, apartmentCount) / area) : 0
            }
            suffix={t('general.kr-per-square-m-suffix')}
          />
          <div className="mt-2 text-gray-400">{t('value-calculator.prop-tax-total')}</div>
          <FLCustom
            value={-Math.round(calculatePropertyTax(tax, taxValue, apartmentCount) / 1000)}
            suffix={t('general.thousand-suffix')}
            prefix={t('general.sek-prefix')}
          />
        </div>
      </div>

      <div className="flex items-stretch justify-between border-l border-gray-100 dark:border-dark-morefaded">
        <div className={`${rowCellStyle} !font-medium w-1/6 !bg-gray-50 dark:!bg-dark-faded`}>
          {t('value-calculator.operation-net')}
        </div>
        <div className={`${rowCellStyle} !font-medium w-4/6 !bg-gray-50 dark:!bg-dark-faded`}></div>
        <div className={`${rowCellStyle} !font-medium w-1/6 !bg-gray-50 dark:!bg-dark-faded`}>
          <div className="text-gray-400">{t('value-calculator.operation-net-sqm')}</div>
          <FLCustom
            value={Math.round(calculateNetResult() / area)}
            suffix={t('general.kr-per-square-m-suffix')}
          />
          <div className="mt-2 text-gray-400">{t('value-calculator.operation-net-total')}</div>
          <FLCustom
            value={Math.round(calculateNetResult() / 1000)}
            suffix={t('general.thousand-suffix')}
            prefix={t('general.sek-prefix')}
          />
        </div>
      </div>

      {values ? (
        <CalculatorRow
          valueCategory={valueCategories[ValueCategoryTypes.YIELD]}
          changeValue={changeValue}
          selectedTab={tabName}
          tabValues={values.find((cat) => cat.category === ValueCategoryTypes.YIELD)}
          result={result}
          costValues={[]}
          defaultRowValues={defaultValues[ValueCategoryTypes.YIELD]}
          area={area}
          inCashflow={true}
        />
      ) : null}

      <div className="flex items-stretch justify-between border-x border-b rounded-b-md border-gray-100 dark:border-dark-morefaded">
        <div className={`${rowCellStyle} !font-medium w-1/6 !bg-gray-50 dark:!bg-dark-faded`}>
          {t('general.result')}
        </div>
        <div className={`${rowCellStyle} !font-medium w-4/6 !bg-gray-50 dark:!bg-dark-faded`}></div>
        <div className={`${rowCellStyle} !font-medium w-1/6 !bg-gray-50 dark:!bg-dark-faded`}>
          <div className="text-gray-400">{t('value-calculator.value-sqm')}</div>
          <FLCustom
            value={Math.round(calculateResult() / area)}
            suffix={t('general.kr-per-square-m-suffix')}
          />
          <div className="mt-2 text-gray-400">{t('value-calculator.value-total')}</div>
          <FLCustom
            value={Math.round(calculateResult() / 1000)}
            suffix={t('general.thousand-suffix')}
            prefix={t('general.sek-prefix')}
          />
        </div>
      </div>
    </div>
  );
}
