import { CubeIcon } from '@heroicons/react/24/solid';
import { LinearProgress } from '@material-ui/core';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { PropertyVM } from 'shared/dist/types/property2';
import SignedGoogleMapsImage from 'src/components/map/signed-google-maps-image';

import { getProperty } from '@/src/lib/prop-client-wrapper';

import WrapSection from '../../section-wrapper';

export default function PropPermitsTemplate({ object, close }: { object: any; close: any }) {
  const [address, setAddress] = useState<string | null>(null);
  const [property, setProperty] = useState<PropertyVM | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const { t } = useTranslation('common');

  try {
    const prop: any = JSON.parse(object?.property);
    object = {
      ...object,
      ...prop
    };
  } catch (e) {
    console.log('Failed', e);
  }

  useEffect(() => {
    getProperty({ uuid: object?.uuid }, ['core', 'address', 'tax', 'permits'], false).then(
      (json) => {
        if (json.error) {
          console.error('getProperty error', json.errMessage);
          return;
        }
        setProperty(json?.property);
        const addresses: any[] = json?.property?.addresses || [];
        if (addresses && addresses?.length > 0) {
          const addr: any = addresses.shift();
          setAddress(
            `${addr?.street}${addr?.street_no > 0 ? ' ' + addr?.street_no : ''}${
              addr?.street_letter
            }, ${addr?.postal_code} ${json?.property?.county}`
          );
        }
        setLoading(false);
      }
    );
  }, [object?.uuid]);

  const router = useRouter();

  const latLong = `${property?.lat},${property?.lng}`;

  return (
    <div className="flex z-30 h-full flex-col overflow-y-scroll">
      {/* Main */}
      <div className="pb-1 sm:pb-6">
        <div className="relative h-36 sm:h-48 z-10 overflow-hidden">
          <SignedGoogleMapsImage
            height={290}
            width={670}
            className="object-contain"
            src={`https://maps.googleapis.com/maps/api/streetview?size=1200x275&location=${encodeURIComponent(
              latLong
            )}&fov=300&pitch=5&key=AIzaSyBYCMGQ9cdHlRBN414KW_VE0WgTzR6Sqow&source=outdoor`}
          />
        </div>
        {loading ? <LinearProgress /> : <div className="h-[4px] w-8" />}
        <div className="sm:flex sm:items-end relative">
          <div className="sm:flex-1 mt-2 sm:mt-2">
            <div className="px-4">
              <WrapSection title={`Bygglov för ${object?.authority_nickname}`}>
                {/*                    <div className="text-xl text-left font-medium text-gray-900 dark:text-white sm:text-2xl flex items-center hover:text-bobo-link transition-colors duration-300 w-4/5">
                      {object?.authorityNickname}
                    </div>*/}
                <div className="my-2 mb-4">
                  <button
                    onClick={() => {
                      router.push(`/crm/fastighet/${object?.uuid}/overview`);
                      close();
                    }}
                    type="button"
                    className="items-center justify-center rounded-full border border-bobo-prop dark:border-dark-prop px-4 py-1 text-sm text-bobo-prop dark:text-dark-prop font-regular hover:bg-bobo-verylightgreen sm:flex-1 transition-colors duration-300">
                    {t('property.explore-property')}
                  </button>
                </div>

                <div className="w-full">
                  {property?.erodingSpace && (
                    <div className="text-mini py-1 px-2 rounded-sm text-gray-500 text-center uppercase flex items-center justify-center bg-gray-200">
                      <CubeIcon className="w-5 h-5 m-auto" />
                      <div className="ml-1">{t('property.3d-prop')}</div>
                    </div>
                  )}
                  <div className="text-xs flex justify-between items-center font-normaltext-gray-500 dark:text-bobo-prop dark:dark:text-dark-prop">
                    <div className="border-gray-100 dark:border-gray-600 flex justify-between">
                      <div>{address}</div>
                      {object?.addresses && object?.addresses?.length > 1 && (
                        <div className="italic text-gray-500 dark:text-gray-300 ml-2 text-mini lowercase">
                          +{object?.addresses?.length - 1} {t('general.addresses')}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="w-4/5 mt-2">
                  <div className="text-xs text-gray-700 font-demi dark:text-gray-300">
                    <div className="flex w-full">
                      <div className="w-[155px]">{t('general.address')}</div>

                      <div className="w-4/5 ml-4">{address}</div>
                    </div>

                    <div className="flex w-full">
                      <div className="w-[155px]">{t('general.area')}</div>

                      <div className="w-4/5 ml-4 flex">
                        <NumberFormat
                          value={property?.propertyArea}
                          displayType={'text'}
                          thousandSeparator={` `}
                          suffix={t('general.square-meters')}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="px-0 mt-4 pt-4 border-t border-gray-100 dark:border-dark-morefaded">
                  {object?.details &&
                    object?.details?.map((item: any, idx: number) => (
                      <div
                        className="text-sm min-h-[50px] rounded-sm space-x-3 border-b border-gray-100 dark:border-dark-morefaded p-4 odd:bg-gray-50 dark:odd:bg-dark-faded flex items-center"
                        key={idx}>
                        <div className="w-full">
                          <div className="text-sm font-demi">
                            {item?.rubrik} {item?.registreringsDatum}
                          </div>

                          <div className="font-medium">{item?.title}</div>

                          <div className="text-xs">{item?.title}</div>

                          <div className="text-gray-700 dark:text-gray-300 text-xs">
                            <div dangerouslySetInnerHTML={{ __html: item?.kungorelseText }} />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>

                <div className="bg-bobo-verylightgreen py-1 mt-2 px-4 text-mini text-gray-900 rounded-full inline-flex z-30">
                  {t('general.sources', { sources: 'Lantmäteriet & POIT' })}
                </div>
              </WrapSection>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
