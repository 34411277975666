/* eslint-disable i18next/no-literal-string */
import { useRouter } from 'next/router';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useGlobal } from 'reactn';

import UploadExcelModal from '@/src/components/itismad/upload-excel';
import { useDialog } from '@/src/context/dialog-context';

import Button from '../buttons';

export default function MemberInviteButton() {
  const router = useRouter();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [crmIncrement, setCRMIncrement] = useGlobal('crmIncrement');
  const { t } = useTranslation('common');
  const { setDialog } = useDialog();

  const handleDataSubmit = (data: any) => {
    setDialog({
      dialogType: 'member-invite',
      object: {
        setSelectedModel: {}
      }
    });
  };

  return (
    <>
      <Button onClick={handleDataSubmit}>{t('settings.team.invite-member')}</Button>
    </>
  );
}
