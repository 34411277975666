import { useUser } from '@auth0/nextjs-auth0';
import { Dialog } from '@headlessui/react';
import { LockClosedIcon, UserGroupIcon, XMarkIcon } from '@heroicons/react/24/solid';
import cls from 'classnames';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import { MutableRefObject, SetStateAction, useEffect, useRef, useState } from 'react';
import { useGlobal } from 'reactn';
import { Actor } from 'shared/dist/types';

import { Error, Scooter, Success } from '@/src/animations';
import { AllmannyttanLogo } from '@/src/assets/icons';
import { saveUpdateBoard } from '@/src/lib/actor-client-wrapper';
import { CreateUpdateBoardData, CRMBoardVM } from '@/src/types';
import { Checkbox } from '@/src/widgets/inputs';

import { crmDialogTheme } from './dialog-theme';

const stepNames = {
  addBoardIntro: 1,
  addBoardTitle: 2,
  addBoardPrivacy: 3,
  editBoard: 4,
  loading: 5,
  error: 6,
  done: 7
};

const ATCheckBox = ({
  disabled,
  isShared,
  setIsShared,
  setShowSharedConfirm,
  sharedRef
}: {
  disabled?: boolean;
  isShared?: boolean;
  setIsShared: (value: SetStateAction<boolean>) => void;
  setShowSharedConfirm: (value: SetStateAction<boolean>) => void;
  sharedRef: MutableRefObject<null>;
}) => {
  const { t } = useTranslation('common');

  if (disabled && isShared) {
    setIsShared(false);
  }

  return (
    <div className="flex gap-2 justify-center items-center">
      <Checkbox
        onChange={() => {
          if (disabled) {
            return;
          }
          isShared ? setIsShared(false) : setShowSharedConfirm(true);
        }}
        checked={isShared}
        ref={sharedRef}
        id={'shared'}
        name={'shared'}
        className={cls('h-5 w-5 mb-0.5', { '!cursor-not-allowed': disabled })}
      />
      <label
        htmlFor="shared"
        className={cls('text-sm flex items-center', {
          'text-gray-400 cursor-not-allowed': disabled
        })}>
        {t('crm.modals.board-shared-with-AT')} <AllmannyttanLogo className="h-3.5 w-3.5 ml-1" />
      </label>
    </div>
  );
};

const ATConfirmDialog = ({
  setIsShared,
  setShowSharedConfirm
}: {
  setIsShared: (value: SetStateAction<boolean>) => void;
  setShowSharedConfirm: (value: SetStateAction<boolean>) => void;
}) => {
  const { t } = useTranslation('common');
  return (
    <div className="fixed inset-0 bg-black dark:bg-white bg-opacity-25 dark:bg-opacity-25 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-dark-primary p-6 rounded-lg shadow-xl max-w-xl relative text-justify">
        <button
          onClick={() => setShowSharedConfirm(false)}
          className={cls(crmDialogTheme.closeContainerX, '!top-3 !right-3')}>
          <XMarkIcon className="h-4 w-4" />
        </button>
        <div className="flex justify-center">
          <Image
            alt={`Sveriges Allmännytta logga`}
            src={`/images/an-logo.png`}
            height={150}
            width={150}
          />
        </div>
        <p className="text-gray-700 dark:text-gray-300 mb-6 mx-6 ">
          {t('crm.modals.confirm-share-with-at-1')}
        </p>
        <p className="text-gray-700 dark:text-gray-300 mb-6 mx-6">
          {t('crm.modals.confirm-share-with-at-2')}
        </p>
        <p className="text-gray-700 dark:text-gray-300 mb-6 mx-6 text-pretty">
          {t('crm.modals.confirm-share-with-at-3')}
        </p>
        <div className="flex justify-between gap-2">
          <button
            className={crmDialogTheme.buttonClose}
            onClick={() => setShowSharedConfirm(false)}>
            {t('general.cancel')}
          </button>
          <button
            className={crmDialogTheme.buttonConfirm}
            onClick={() => {
              setIsShared(true);
              setShowSharedConfirm(false);
            }}>
            {t('general.confirm')}
          </button>
        </div>
      </div>
    </div>
  );
};

export function BoardAddStepIntro({ setStep, close }: { setStep: any; close: any }) {
  const nextStepRef = useRef<HTMLButtonElement>(null);

  const { t } = useTranslation('common');

  useEffect(() => {
    if (nextStepRef?.current) {
      nextStepRef.current?.focus();
    }
  }, []);

  return (
    <div className={crmDialogTheme.container}>
      <Image
        alt="main illustration"
        src={`/images/apartment-rate-increment.svg`}
        height={200}
        width={200}
      />
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          {t('crm.modals.add-board-title')}
        </Dialog.Title>

        <p className={crmDialogTheme.subtext}>{t('crm.modals.add-board-subtext')}</p>
      </div>

      <div className={crmDialogTheme.buttonContainer}>
        <button className={crmDialogTheme.buttonClose} onClick={close}>
          {t('general.cancel')}
        </button>

        <button
          ref={nextStepRef}
          className={crmDialogTheme.buttonConfirm}
          onClick={() => setStep(stepNames.addBoardTitle)}>
          {t('crm.modals.get-started')}
        </button>
      </div>
    </div>
  );
}

export function BoardAddStepTitle({
  setStep,
  setMainStep,
  close,
  setBoardName,
  boardName,
  backToAddProp
}: {
  setStep: any;
  setMainStep: any;
  close: any;
  setBoardName: any;
  boardName?: string;
  backToAddProp: { addProperty: boolean; addProperties: boolean };
}) {
  const inputRef: any = useRef(null);

  const [name, setName] = useState<string>(boardName ? boardName : '');
  const [inputValid, setInputValid] = useState<boolean>(boardName ? true : false);

  const { t } = useTranslation('common');

  useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current?.focus();
    }
  }, []);

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Image
          alt="main illustration"
          src={`/images/apartment-rate-increment.svg`}
          height={200}
          width={200}
        />
      </div>

      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          {t('crm.modals.add-board-name-title')}
        </Dialog.Title>

        <p className={crmDialogTheme.subtext}>
          <Trans i18nKey="crm.modals.add-board-name-subtext">
            Det namn som kommer synas i listan och rapporter. <br /> Du kan ändra namnet senare.
          </Trans>
        </p>

        <label htmlFor={inputRef} className={crmDialogTheme.label}>
          {t('crm.modals.add-board-input-label')}
        </label>

        <input
          placeholder={t('crm.modals.add-update-board-name-placeholder')}
          className={crmDialogTheme.input}
          value={name}
          onChange={(txt) => {
            setName(txt.target.value);
            setBoardName(txt.target.value);
            setInputValid(txt.target.value?.trim().length > 0);
          }}
          onKeyDown={(e) => {
            if (e.key == 'Enter' && inputValid) {
              setStep(stepNames.addBoardPrivacy);
            }
          }}
          ref={inputRef}
        />
      </div>

      <div className={crmDialogTheme.buttonContainer}>
        <button className={crmDialogTheme.buttonClose} onClick={close}>
          {t('general.cancel')}
        </button>

        <div className={crmDialogTheme.subButtonContainer}>
          {backToAddProp.addProperty && (
            <button
              className={crmDialogTheme.buttonBack}
              onClick={() => (backToAddProp.addProperties ? setMainStep(40) : setMainStep(20))}>
              {t('general.back')}
            </button>
          )}
          <button
            className={crmDialogTheme.buttonConfirm}
            disabled={!inputValid}
            onClick={() => setStep(stepNames.addBoardPrivacy)}>
            {t('crm.modals.add-board-next-step')}
          </button>
        </div>
      </div>
    </div>
  );
}

function BoardAddStepPrivacy({
  setStep,
  close,
  setBoardPrivacy,
  saveBoard
}: {
  setStep: any;
  close: any;
  setBoardPrivacy: any;
  saveBoard: (boardData: CreateUpdateBoardData) => Promise<void>;
}) {
  const inputRef: any = useRef(null);
  const confirmRef: any = useRef(null);
  const sharedRef = useRef(null);

  const [privacy, setPrivacy] = useState<boolean | null>(null);
  const [showSharedConfirm, setShowSharedConfirm] = useState<boolean>(false);
  const [isShared, setIsShared] = useState<boolean>(false);

  const { user } = useUser();
  const [actor] = useGlobal<{ activeActor: Actor }>('activeActor' as never);

  const isAllmannyttanMember = actor?.is_allmannyttan_member;
  const isAdminNotProd =
    user?.email?.endsWith('@fastighetsloggen.se') && process.env.NEXT_PUBLIC_ENV !== 'production';

  const { t } = useTranslation('common');

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && confirmRef) {
      setTimeout(() => {
        confirmRef?.current.focus();
      }, 150);
    }
  };

  useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current?.focus();
    }
  }, []);

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Image
          alt="main illustration"
          src={`/images/apartment-rate-increment.svg`}
          height={200}
          width={200}
        />
      </div>

      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          {t('crm.modals.add-board-privacy-title')}
        </Dialog.Title>

        <p className={crmDialogTheme.subtext}>{t('crm.modals.add-board-privacy-subtext')}</p>
      </div>

      <div className={crmDialogTheme.privacyContainer}>
        <button
          onKeyDown={(e) => handleKeyDown(e)}
          onClick={() => {
            setPrivacy(true);
            setBoardPrivacy(true);
          }}
          className={cls(crmDialogTheme.privacyItem, {
            'bg-bobo-proplight border-bobo-prop dark:border-dark-prop': privacy
          })}>
          <LockClosedIcon className={crmDialogTheme.privateBoardIcon} />

          <div className={crmDialogTheme.privacyTitle}>{t('crm.modals.privacy-private')}</div>

          <p>{t('crm.modals.privacy-private-desc')}</p>
        </button>

        <button
          onKeyDown={(e) => handleKeyDown(e)}
          onClick={() => {
            setPrivacy(false);
            setBoardPrivacy(false);
          }}
          className={cls(crmDialogTheme.privacyItem, {
            'bg-bobo-proplight border-bobo-prop dark:border-dark-prop': !privacy && privacy !== null
          })}>
          <UserGroupIcon className={crmDialogTheme.publicBoardIcon} />

          <div className={crmDialogTheme.privacyTitle}>{t('crm.modals.privacy-public')}</div>

          <div>{t('crm.modals.privacy-public-desc')}</div>
        </button>
      </div>

      {(isAllmannyttanMember || isAdminNotProd) && (
        <ATCheckBox
          disabled={privacy !== null ? privacy : false}
          isShared={isShared}
          setIsShared={setIsShared}
          setShowSharedConfirm={setShowSharedConfirm}
          sharedRef={sharedRef}
        />
      )}

      {showSharedConfirm && (
        <ATConfirmDialog setIsShared={setIsShared} setShowSharedConfirm={setShowSharedConfirm} />
      )}

      <div className={crmDialogTheme.buttonContainer}>
        <button className={crmDialogTheme.buttonClose} onClick={close}>
          {t('general.cancel')}
        </button>

        <div className={crmDialogTheme.subButtonContainer}>
          <button
            className={crmDialogTheme.buttonBack}
            onClick={() => setStep(stepNames.addBoardTitle)}>
            {t('general.back')}
          </button>

          <button
            className={crmDialogTheme.buttonConfirm}
            ref={confirmRef}
            disabled={privacy !== null ? false : true}
            onClick={() => saveBoard({ isAllmannyttanShared: isShared })}>
            {t('crm.modals.create-board-button')}
          </button>
        </div>
      </div>
    </div>
  );
}

function BoardEditStepIntro({
  close,
  setBoardName,
  setBoardPrivacy,
  saveBoard,
  board: { title, isPrivate, is_allmannyttan_shared }
}: {
  close: any;
  setBoardName: any;
  saveBoard: (boardData: CreateUpdateBoardData) => Promise<void>;
  setBoardPrivacy: any;
  board: CRMBoardVM;
}) {
  const { user } = useUser();

  const inputRef: any = useRef(null);
  const confirmRef: any = useRef(null);
  const sharedRef = useRef(null);

  const [name, setName] = useState<string>(title ?? '');
  const [privacy, setPrivacy] = useState<boolean>(isPrivate || false);
  const [changed, setChanged] = useState<boolean>(false);
  const [showSharedConfirm, setShowSharedConfirm] = useState<boolean>(false);
  const [isShared, setIsShared] = useState<boolean>(is_allmannyttan_shared || false);

  const [actor] = useGlobal<{ activeActor: Actor }>('activeActor' as never);

  const isAllmannyttanMember = actor?.is_allmannyttan_member;
  const isAdminNotProd =
    user?.email?.endsWith('@fastighetsloggen.se') && process.env.NEXT_PUBLIC_ENV !== 'production';

  const { t } = useTranslation('common');

  useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current?.focus();
    }
  }, []);

  useEffect(() => {
    setChanged(
      name?.trim() !== title ||
        privacy !== isPrivate ||
        isShared !== (is_allmannyttan_shared || false)
    );
  }, [name, privacy, title, isPrivate, isShared, is_allmannyttan_shared]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && confirmRef) {
      setTimeout(() => {
        confirmRef?.current.focus();
      }, 150);
    }
  };

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Image
          alt="main illustration"
          src={`/images/apartment-rate-increment.svg`}
          height={200}
          width={200}
        />
      </div>

      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          <Trans
            i18nKey="crm.modals.update-board-title"
            values={{ boardName: title }}
            components={[<span key={0} className="text-bobo-prop dark:text-dark-prop" />]}
          />
        </Dialog.Title>

        <label htmlFor={inputRef} className={crmDialogTheme.labelWithoutSubtext}>
          {t('crm.modals.update-board-input-label')}
        </label>

        <input
          placeholder={t('crm.modals.add-update-board-name-placeholder')}
          className={crmDialogTheme.input}
          value={name}
          onChange={(txt) => {
            setName(txt.target.value);
          }}
          onKeyDown={(e) => handleKeyDown(e)}
          ref={inputRef}
        />
      </div>

      <div className={crmDialogTheme.privacyContainer}>
        <button
          disabled={!isPrivate}
          onKeyDown={(e) => handleKeyDown(e)}
          className={cls(crmDialogTheme.privacyItem, {
            'bg-bobo-proplight border-bobo-prop dark:border-dark-prop': privacy
          })}
          onClick={() => {
            if (isPrivate) {
              setPrivacy(true);
            }
          }}>
          <LockClosedIcon className={crmDialogTheme.privateBoardIcon} />

          <div className={crmDialogTheme.privacyTitle}>{t('crm.modals.privacy-private')}</div>

          <p>
            {isPrivate
              ? t('crm.modals.privacy-private-desc')
              : t('crm.modals.privacy-private-disabled')}
          </p>
        </button>

        <button
          onClick={() => {
            setPrivacy(false);
          }}
          onKeyDown={(e) => handleKeyDown(e)}
          className={cls(crmDialogTheme.privacyItem, {
            'bg-bobo-proplight border-bobo-prop dark:border-dark-prop': !privacy
          })}>
          <UserGroupIcon className={crmDialogTheme.publicBoardIcon} />

          <div className={crmDialogTheme.privacyTitle}>{t('crm.modals.privacy-public')}</div>

          <p>{t('crm.modals.privacy-public-desc')}</p>
        </button>
      </div>

      {isPrivate && !privacy ? (
        <p className="absolute bottom-[4.5rem] text-sm w-[80%] text-bobo-burgundy dark:text-gray-300">
          {t('crm.board-actions.change-privacy-warning')}
        </p>
      ) : null}

      {(isAllmannyttanMember || isAdminNotProd) && !isPrivate && (
        <ATCheckBox
          isShared={isShared}
          setIsShared={setIsShared}
          setShowSharedConfirm={setShowSharedConfirm}
          sharedRef={sharedRef}
        />
      )}

      {showSharedConfirm && (
        <ATConfirmDialog setIsShared={setIsShared} setShowSharedConfirm={setShowSharedConfirm} />
      )}

      <div className={crmDialogTheme.buttonContainer}>
        <button className={crmDialogTheme.buttonClose} onClick={close}>
          {t('general.cancel')}
        </button>

        <button
          className={crmDialogTheme.buttonConfirm}
          ref={confirmRef}
          disabled={!changed || !(name?.trim().length > 0)}
          onClick={() => {
            setBoardName(name.trim());
            setBoardPrivacy(privacy);
            saveBoard({
              boardPrivacy: privacy,
              boardName: name.trim(),
              isAllmannyttanShared: isShared
            });
          }}>
          {t('general.save')}
        </button>
      </div>
    </div>
  );
}

function BoardAddEditStepLoading() {
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Scooter />
      </div>

      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          {t('general.loading')}
        </Dialog.Title>

        <p className={crmDialogTheme.subtext}>
          <Trans i18nKey="fun-facts.BRF">
            <strong>Visste du att:</strong> Av Sveriges drygt 4,8 miljoner hushåll bor ungefär 2,4
            miljoner i en lägenhet i flerbostadshus, varav 1,4 miljoner är hyresrätter och 1,0
            miljoner är bostadsrätter. <em>(Källa: SCB)</em>
          </Trans>
        </p>
      </div>
    </div>
  );
}

function BoardAddEditStepError({ close }: { close: any; object: any }) {
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.icon}>
        <Error />
      </div>

      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          {t('errors.something-went-wrong')}
        </Dialog.Title>

        <p className={crmDialogTheme.subtext}>{t('errors.try-again')}</p>
      </div>

      <div className={crmDialogTheme.buttonContainer}>
        <button
          className={crmDialogTheme.buttonClose}
          onClick={() => {
            close();
          }}>
          {t('general.close')}
        </button>
      </div>
    </div>
  );
}

function BoardAddEditStepDone({
  close,
  object,
  status,
  setMainStep
}: {
  setMainStep: any;
  close: any;
  object: any;
  status: number;
}) {
  const { t } = useTranslation('common');

  const router = useRouter();

  const handleClose = () => {
    close();
    if (status === 2) {
      router.push(`/crm/${object?.boardId}`);
    }
  };

  let title: JSX.Element = <></>;

  let subText: JSX.Element = <></>;

  if (status === 1) {
    title = (
      <Trans i18nKey="crm.board-actions.updated-board" boardName={object?.boardName}>
        Du har uppdaterat tavlan
        <span className="text-bobo-prop dark:text-dark-prop">
          {' '}
          {{ boardName: object?.boardName }}
        </span>
        .
      </Trans>
    );

    subText = <>{t('crm.list-actions.get-started')}</>;
  } else if (status === 2) {
    title = (
      <Trans i18nKey="crm.board-actions.created-board" boardName={object?.boardName}>
        Du har skapat tavlan
        <span className="text-bobo-prop dark:text-dark-prop">
          {' '}
          {{ boardName: object?.boardName }}
        </span>
        .
      </Trans>
    );

    subText = <>{t('crm.list-actions.get-started')}</>;
  }

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.icon}>
        <Success />
      </div>

      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          {title}
        </Dialog.Title>

        <p className={crmDialogTheme.subtext}>{subText}</p>
      </div>

      <div className={crmDialogTheme.buttonContainer}>
        <button
          className={crmDialogTheme.buttonClose}
          onClick={() => {
            close();
          }}>
          {t('general.cancel')}
        </button>

        <div className={crmDialogTheme.subButtonContainer}>
          <button className={crmDialogTheme.buttonClose} onClick={handleClose}>
            {status === 2 ? t('crm.modals.link-to-board') : t('crm.modals.done')}
          </button>

          <button className={crmDialogTheme.buttonConfirm} onClick={() => setMainStep(2)}>
            {t('crm.list-actions.create-new-list')}
          </button>
        </div>
      </div>
    </div>
  );
}

/**
 * dialog for adding new and editing board
 */

export default function CrmBoardDialogAddEdit({
  initStep = 1,
  setMainStep,
  setReload,
  close,
  setBoardName,
  setBoardPrivacy,
  board,
  boardId,
  object,
  setObject,
  addEditMode,
  authoritiesNicknames
}: {
  initStep: number;
  setMainStep: any;
  setReload: any;
  close: any;
  board?: CRMBoardVM;
  boardId?: null | string;
  object: {
    listName?: string;
    boardName?: string;
    boardId?: string | null;
    listId?: string | null;
    boardPrivacy?: boolean;
  };
  setBoardName: any;
  setBoardPrivacy: any;
  setObject: any;
  addEditMode: any;
  authoritiesNicknames: any;
}) {
  const [step, setStep] = useState(initStep);
  const [status, setStatus] = useState(0);

  const backToAddProp: { addProperty: boolean; addProperties: boolean } = {
    addProperty: addEditMode,
    addProperties: authoritiesNicknames && authoritiesNicknames?.length > 0
  };

  const saveBoard = async (boardData?: CreateUpdateBoardData) => {
    setReload(true);

    setStep(stepNames.loading);

    let newBoard: CreateUpdateBoardData = object ?? {};

    if (boardData) {
      newBoard = {
        ...newBoard,
        ...boardData
      };
    }

    await saveUpdateBoard(newBoard).then((res) => {
      if (res.error) {
        setStep(stepNames.error);
      } else {
        board ? setStatus(1) : setStatus(2);
        setStep(stepNames.done);
        setObject({
          ...object,
          boardId: res?.uuid
        });
      }
    });

    if (addEditMode && authoritiesNicknames && authoritiesNicknames?.length > 0) {
      // Add list of properties to crm
      setMainStep(40);
    } else if (addEditMode) {
      // Add single property to crm
      setMainStep(20);
    }
  };

  let mod: JSX.Element = <div />;

  switch (step) {
    case stepNames.addBoardIntro:
      mod = <BoardAddStepIntro setStep={setStep} close={close} />;
      break;

    case stepNames.addBoardTitle:
      mod = (
        <BoardAddStepTitle
          setStep={setStep}
          setMainStep={setMainStep}
          close={close}
          setBoardName={setBoardName}
          boardName={object?.boardName}
          backToAddProp={backToAddProp}
        />
      );
      break;

    case stepNames.addBoardPrivacy:
      mod = (
        <BoardAddStepPrivacy
          setStep={setStep}
          close={close}
          setBoardPrivacy={setBoardPrivacy}
          saveBoard={saveBoard}
        />
      );
      break;

    case stepNames.editBoard:
      if (board) {
        mod = (
          <BoardEditStepIntro
            close={close}
            setBoardName={setBoardName}
            saveBoard={saveBoard}
            setBoardPrivacy={setBoardPrivacy}
            board={board}
          />
        );
      }
      break;

    case stepNames.loading:
      mod = <BoardAddEditStepLoading />;
      break;

    case stepNames.error:
      mod = <BoardAddEditStepError close={close} object={object} />;
      break;

    case stepNames.done:
      mod = (
        <BoardAddEditStepDone
          setMainStep={setMainStep}
          close={close}
          object={object}
          status={status}
        />
      );
      break;
  }

  return <>{mod}</>;
}
