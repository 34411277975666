import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { ArrowLeftOnRectangleIcon as LogoutIcon } from '@heroicons/react/24/solid';
import { shutdown as shutdownIntercom } from '@intercom/messenger-js-sdk';
import moment from 'moment';
import Image from 'next/image';
import Link from 'next/link';
import { Trans, useTranslation } from 'next-i18next';
import { Fragment } from 'react';
import { PropertyTypeGroup } from 'shared/dist/types';
import { MDATRegion } from 'shared/dist/types/rent';
import { ActorSelectionDialog } from 'src/components/dialogs/selected-actor-dialogs';
import LoadingDialog from 'src/widgets/loading-dialog';

import CashflowDialogSwitcher from '@/src/components/cashflow-valuation/cashflow-dialog-switcher';
import CashflowAddOrUpdateAsset from '@/src/components/dialogs/cashflow/add-or-update-asset';
import CrmBoardDialogDelete from '@/src/components/dialogs/crm/board-delete';
import CrmPropertyDialogDelete from '@/src/components/dialogs/crm/property-delete';
import InviteMemberDialog from '@/src/components/dialogs/member-invite';
import CrmDialogSwitcher from '@/src/components/overview/crm-dialog-switcher';
import Crm2DialogSwitcher from '@/src/components/overview/crm2-dialog-switcher';
import { UnlockFDS } from '@/src/components/property/items/steps';
import { ValuationTool } from '@/src/components/valuation-tool/valuation-tool';
import { useBreakpoint } from '@/src/hooks/use-breakpoint';
import { DialogType } from '@/src/types';
import { AddDeliverable } from '@/src/widgets/dialog/add-deliverable';

import BuyEnvReport from './templates/buy-env-report';
import CreateBankValuationReport from './templates/create-bank-report';
import CreatePropReport from './templates/create-prop-report';
import CRM2AddTeamMemberDialog from './templates/crm2-add-team-member';
import CRM2CreateTeamsDialog from './templates/crm2-create-team';
import CRM2DeleteTeamsDialog from './templates/crm2-delete-teams';
import CRM2EditTeamDialog from './templates/crm2-edit-team';
import HandleMember from './templates/handle-member';

export default function GlobalDialog({
  isOpen,
  object,
  type,
  closeableByOutsideClick,
  closeable,
  close,
  dialogStyle
}: {
  isOpen: boolean;
  object: any;
  type?: DialogType;
  closeableByOutsideClick: boolean;
  closeable: boolean;
  close: any;
  dialogStyle?: React.CSSProperties;
}) {
  let template: JSX.Element = <div />;
  let modalWidth = '672px';

  const { isMd } = useBreakpoint({ breakpoint: 'md' });

  function closeModal() {
    if (closeable) {
      close();
    }
  }

  let DialogWrapper = function DialogWrapper({ children }) {
    if (!type) {
      return null;
    }

    return (
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[55]"
          onClose={() => {
            if (closeable && closeableByOutsideClick) {
              close();
            }
          }}>
          <div className="fixed inset-0 bg-gray-600/40" aria-hidden="true" />

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black dark:bg-white bg-opacity-25 dark:bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel
                  style={{ ...dialogStyle, maxWidth: modalWidth }}
                  className={`w-full transform overflow-hidden rounded-lg bg-white dark:bg-dark-primary dark:text-white p-6 text-center align-middle shadow-xl transition-all min-h-[90vh] md:min-h-[350px] items-between flex flex-col justify-start`}>
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const { t } = useTranslation('common');

  switch (type) {
    case 'prompt-delete-property-from-board':
      template = <CrmPropertyDialogDelete onClose={closeModal} object={object} />;
      break;

    case 'select-actor':
      template = (
        <div className="min-h-[300px]">
          <ActorSelectionDialog
            onSelectedActor={(actor) => {
              if (object?.onDone) {
                object.onDone(actor);
              }
            }}
          />
        </div>
      );
      break;

    case 'forum-create-deliverable':
      template = (
        <div className="min-h-[300px]">
          <AddDeliverable />
        </div>
      );
      break;

    case 'loading':
      return <LoadingDialog isLoading />;

    case 'prompt-delete-board':
      template = <CrmBoardDialogDelete onClose={closeModal} object={object} />;
      break;

    case 'prompt-crm-dialog-switcher':
      template = <CrmDialogSwitcher object={object} onClose={closeModal} />;
      break;

    case 'prompt-crm2-dialog-switcher':
      template = <Crm2DialogSwitcher object={object} onClose={closeModal} />;
      break;

    case 'value-spinner':
      modalWidth = '1280px';
      template = <ValuationTool object={object} close={closeModal} />;
      break;

    case 'cashflow-dialog-switcher':
      template = <CashflowDialogSwitcher object={object} onClose={closeModal} />;
      break;

    case 'cashflow-add-assumption':
      modalWidth = '1280px';
      template = <CashflowAddOrUpdateAsset object={object} close={closeModal} />;
      break;

    case 'prompt-prop-report':
      if (isMd) {
        modalWidth = '800px';
      }
      template = <CreatePropReport object={object} close={closeModal} />;
      break;

    case 'prompt-env-report':
      if (isMd) {
        modalWidth = '800px';
      }
      template = <BuyEnvReport object={object} close={closeModal} />;
      break;

    case 'member-invite':
      if (isMd) {
        modalWidth = '800px';
      }
      template = <InviteMemberDialog object={object} close={closeModal} />;
      break;

    case 'prompt-bank-report':
      if (isMd) {
        modalWidth = '800px';
      }
      template = <CreateBankValuationReport object={object} close={closeModal} />;
      break;

    case 'mdat-export-all':
      template = (
        <>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-visible">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="w-[600px] transform overflow-hidden rounded-md bg-white dark:bg-dark-lessfaded pb-12 text-center align-middle shadow-xl transition-all items-between flex flex-col justify-start">
                  <div className="bg-bobo-prop dark:bg-dark-prop p-2 px-4 text-white rounded-t-md">
                    {t('pages.mdat-tool.export-all-nation')}
                  </div>

                  <button onClick={closeModal} className="absolute right-3 top-3">
                    <XMarkIcon className="h-5 w-5 text-white hover:text-bobo-orange transition-colors duration-300 dark:text-white z-50 dark:hover:text-dark-prophover hidden lg:block" />
                  </button>

                  <div className="flex flex-col justify-center pt-6">
                    <div className="text-center flex justify-center">
                      <Image
                        src={`/images/illustrations/egenskap_dndw_ritning.svg`}
                        height={40}
                        width={200}
                        alt={`Exportera områden`}
                      />
                    </div>

                    <div className="text-base text-bobo-black dark:text-gray-300 w-full my-6 px-4 flex-col justify-center">
                      {t('pages.mdat-tool.choose-category')}
                    </div>

                    <div className="flex items-center justify-between gap-4 px-10">
                      <button
                        onClick={() => {
                          const data: MDATRegion[] = object.regions
                            .filter(
                              (region: MDATRegion) => region.category === PropertyTypeGroup.SHOP
                            )
                            .sort((a, b) => a.local_council.localeCompare(b.local_council));
                          const fileName = `Områden butik ${moment().format('YYYY-MM-DD')}`;
                          object.handleExcelExport(data, fileName);
                        }}
                        className="cursor-pointer hover:bg-bobo-orangelight border rounded-full px-3 text-xs py-1 dark:border-dark-morefaded dark:bg-dark-lessfaded flex items-center space-x-3 transition-colors bg-white">
                        {t('prop-types.shop')}
                      </button>

                      <button
                        onClick={() => {
                          const data: MDATRegion[] = object.regions
                            .filter(
                              (region: MDATRegion) => region.category === PropertyTypeGroup.INDUSTRY
                            )
                            .sort((a, b) => a.local_council.localeCompare(b.local_council));
                          const fileName = `Områden industri ${moment().format('YYYY-MM-DD')}`;
                          object.handleExcelExport(data, fileName);
                        }}
                        className="cursor-pointer hover:bg-bobo-orangelight border rounded-full px-3 text-xs py-1 dark:border-dark-morefaded dark:bg-dark-lessfaded flex items-center space-x-3 transition-colors bg-white">
                        {t('prop-types.industry-short')}
                      </button>

                      <button
                        onClick={() => {
                          const data: MDATRegion[] = object.regions
                            .filter(
                              (region: MDATRegion) => region.category === PropertyTypeGroup.HOUSING
                            )
                            .sort((a, b) => a.local_council.localeCompare(b.local_council));
                          const fileName = `Områden bostäder ${moment().format('YYYY-MM-DD')}`;
                          object.handleExcelExport(data, fileName);
                        }}
                        className="cursor-pointer hover:bg-bobo-orangelight border rounded-full px-3 text-xs py-1 dark:border-dark-morefaded dark:bg-dark-lessfaded flex items-center space-x-3 transition-colors bg-white">
                        {t('general.housing')}
                      </button>

                      <button
                        onClick={() => {
                          const data: MDATRegion[] = object.regions
                            .filter(
                              (region: MDATRegion) => region.category === PropertyTypeGroup.SPACE
                            )
                            .sort((a, b) => a.local_council.localeCompare(b.local_council));
                          const fileName = `Områden kontor ${moment().format('YYYY-MM-DD')}`;
                          object.handleExcelExport(data, fileName);
                        }}
                        className="cursor-pointer hover:bg-bobo-orangelight border rounded-full px-3 text-xs py-1 dark:border-dark-morefaded dark:bg-dark-lessfaded flex items-center space-x-3 transition-colors bg-white">
                        {t('general.offices')}
                      </button>

                      <button
                        onClick={() => {
                          const data: MDATRegion[] = object.regions
                            .filter(
                              (region: MDATRegion) => region.category === PropertyTypeGroup.SPECIAL
                            )
                            .sort((a, b) => a.local_council.localeCompare(b.local_council));
                          const fileName = `Områden samhällsfastigheter ${moment().format(
                            'YYYY-MM-DD'
                          )}`;
                          object.handleExcelExport(data, fileName);
                        }}
                        className="cursor-pointer hover:bg-bobo-orangelight border rounded-full px-3 text-xs py-1 dark:border-dark-morefaded dark:bg-dark-lessfaded flex items-center space-x-3 transition-colors bg-white">
                        {t('prop-types.special')}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </>
      );

      DialogWrapper = function DialogWrapper({ children }) {
        return (
          <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={closeModal}>
              {children}
            </Dialog>
          </Transition>
        );
      };
      break;
    case 'deactivated-account':
      template = (
        <>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="w-[650px] transform overflow-hidden rounded-md bg-white dark:bg-dark-lessfaded pb-12 text-center align-middle shadow-xl transition-all items-between flex flex-col justify-start">
                  <div className="bg-bobo-prop dark:bg-dark-prop p-2 px-4 text-white rounded-t-md">
                    {t('errors.account-not-active')}
                  </div>

                  <div className="flex flex-col justify-center pt-6 px-2 sm:px-8 md:px-16">
                    <div className="text-center flex justify-center">
                      <Image
                        src={`/images/illustrations/not-found.svg`}
                        height={40}
                        width={200}
                        alt={`Data not found`}
                      />
                    </div>

                    <div className="text-base text-bobo-black dark:text-gray-300 w-full my-4 px-4 flex-col justify-center">
                      <Trans i18nKey={'errors.account-not-active-desc'} t={t}>
                        Hej! Ditt konto är inte längre aktivt. Vänligen kontakta oss på{' '}
                        <a
                          className="text-bobo-prop dark:text-dark-prop hover:text-bobo-lightgreen dark:hover:text-bobo-lightgreen focus-visible:outline-none"
                          href="mailto:hello@fastighetsloggen.se">
                          hello@fastighetsloggen.se
                        </a>
                        eller{' '}
                        <span className="text-bobo-prop dark:text-dark-prop dark:hover:text-dark-prophover">
                          020-202900
                        </span>{' '}
                        så löser vi det!
                      </Trans>
                      <div className="p-2">{t('errors.mvh')}</div>

                      <div className="flex justify-end -mb-12">
                        <Link
                          href={'/api/auth/logout'}
                          onClick={() => {
                            shutdownIntercom();
                          }}>
                          <span className="p-3 px-4 flex items-start hover:bg-gray-100 dark:hover:bg-dark-faded transition ease-in-out duration-150">
                            <LogoutIcon
                              className="flex-shrink-0 h-5 w-5 text-bobo-orange"
                              aria-hidden="true"
                            />

                            <div className="ml-4">
                              <p className="text-sm font-medium text-gray-900 dark:text-white">
                                {t('general.log-out')}
                              </p>
                            </div>
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </>
      );

      DialogWrapper = function DialogWrapper({ children }) {
        return (
          <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={closeModal}>
              {children}
            </Dialog>
          </Transition>
        );
      };
      break;

    case 'prompt-handle-member':
      template = <HandleMember object={object} onClose={closeModal} />;
      break;

    case 'prompt-prop-unlock-fds':
      template = (
        <>
          <button onClick={closeModal} className="absolute right-6 top-6">
            <XMarkIcon className="h-5 w-5 text-gray-500 dark:text-white dark:hover:text-dark-prophover" />
          </button>
          <UnlockFDS close={closeModal} object={object} />
        </>
      );
      break;

    case 'prompt-crm2-create-team-dialog':
      if (isMd) {
        modalWidth = '800px';
      }
      template = <CRM2CreateTeamsDialog object={object} close={closeModal} />;
      break;

    case 'prompt-crm2-delete-teams-dialog':
      if (isMd) {
        modalWidth = '800px';
      }
      template = <CRM2DeleteTeamsDialog object={object} close={closeModal} />;
      break;

    case 'prompt-crm2-edit-team-dialog':
      if (isMd) {
        modalWidth = '800px';
      }
      template = <CRM2EditTeamDialog object={object} close={closeModal} />;
      break;

    case 'prompt-crm2-add-team-dialog':
      if (isMd) {
        modalWidth = '800px';
      }
      template = <CRM2AddTeamMemberDialog object={object} close={closeModal} />;
      break;

    default:
    // console.warn(`global-dialog: unknown dialog type "${type}"`);
  }

  return <DialogWrapper>{template}</DialogWrapper>;
}
