import { useUser } from '@auth0/nextjs-auth0';
import { Cog6ToothIcon, TrashIcon } from '@heroicons/react/24/solid';
import cls from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobal } from 'reactn';
import { Actor, ButtonType } from 'shared/dist/types';

import {
  AlbumNotFound01Icon,
  MapPinpoint02Icon,
  UserStoryIcon,
  ValidationIcon
} from '@/src/assets/icons';
import { useDialog } from '@/src/context/dialog-context';
import { useBreakpoint } from '@/src/hooks/use-breakpoint';
import { commonDataFetchFunction } from '@/src/lib/utils/dataExport';

import Button from '../buttons';
import DataExportDropdown from '../data-export/dataExportDropdown';
import { getExportHeaders } from '../data-export/export-headers';
import { getExportDataCrm } from '../data-export/get-export-data';
import Toggle from '../toggle';

export default function CRMListOptions({ currentBoard }: { currentBoard: any }) {
  const { isXl } = useBreakpoint({ breakpoint: 'xl' });

  const { setDialog } = useDialog();
  const { t } = useTranslation('common');

  const [actor] = useGlobal<{ activeActor: Actor }>('activeActor' as never);
  const [groupByOwn, setGroupByOwn] = useGlobal('groupByOwn' as never);
  const [addedByMe, setAddedByMe] = useGlobal('addedByMe' as never);
  const [compact, setCompact] = useGlobal('compact' as never);
  const [, setShowThumbnails] = useGlobal('showThumbnails' as never);
  const [validationView, SetValidationView] = useGlobal('validationView' as never);

  const { user } = useUser();

  const headers = getExportHeaders(t, 'crm');

  const isBoardCreator = currentBoard?.creator === user?.email;

  const archiveBoard = currentBoard?.for_archived;

  const validationEnabled =
    process.env.NEXT_PUBLIC_FEATURE_PROPERTY_VALIDATIONS_ENABLED === 'true' && !archiveBoard;

  const numberOfTogglesShown =
    !validationEnabled && currentBoard?.isPrivate
      ? 2
      : currentBoard?.isPrivate || !validationEnabled
        ? 3
        : 4;

  const boardDeleteEnabled =
    // board has loaded
    currentBoard &&
    // board has no properties
    !currentBoard?.lists?.some((a) => a?.properties?.length > 0) &&
    // isn't archive board
    !archiveBoard &&
    isBoardCreator;

  return (
    <div className="flex items-center justify-end">
      <div className="flex flex-row gap-3">
        <div className="has-tooltip relative">
          <Button
            disabled={archiveBoard}
            type={ButtonType.INFO}
            className="!py-1.5"
            onClick={(event) => {
              event?.stopPropagation();
              setDialog({
                dialogType: 'prompt-crm-dialog-switcher',
                object: {
                  boardName: currentBoard?.title,
                  boardId: currentBoard?.uuid,
                  boardPrivacy: currentBoard?.isPrivate,
                  board: currentBoard,
                  initStep: 30
                }
              });
            }}>
            <Cog6ToothIcon className="w-4 h-4" />
            <div className={cls(`hidden xl:block`)}>{t('navigation.settings.heading')}</div>
          </Button>

          {!isXl || archiveBoard ? (
            <div className="tooltip text-[10px] w-max max-w-[250px] font-demi bg-white rounded-md shadow-sm text-gray-500 absolute dark:text-white dark:border-dark-morefaded dark:bg-dark-faded p-1.5 px-3 border border-gray-200 top-8">
              {archiveBoard ? t('crm.board-actions.no-edit') : t('navigation.settings.heading')}
            </div>
          ) : null}
        </div>

        <div className="has-tooltip relative">
          <Button
            type={ButtonType.DELETE}
            className="!py-1.5"
            disabled={!boardDeleteEnabled || !isBoardCreator}
            onClick={(event) => {
              event?.stopPropagation();
              setDialog({
                dialogType: 'prompt-delete-board',
                object: {
                  board: currentBoard
                }
              });
            }}>
            <TrashIcon className="w-4 h-4" />
            <div className="hidden xl:block">{t('crm.board-actions.delete-board')}</div>
          </Button>

          {!isXl || !boardDeleteEnabled || !isBoardCreator ? (
            <div className="tooltip text-[10px] w-max max-w-[250px] font-demi bg-white rounded-md shadow-sm text-gray-500 absolute dark:text-white dark:border-dark-morefaded dark:bg-dark-faded p-1.5 px-3 border border-gray-200 top-8">
              {archiveBoard
                ? t('crm.board-actions.delete-board-not-allowed')
                : !isBoardCreator
                  ? t('crm.board-actions.cannot-delete-board-not-creator')
                  : !boardDeleteEnabled
                    ? t('crm.board-actions.cannot-delete-board')
                    : t('crm.board-actions.delete-board')}
            </div>
          ) : null}
        </div>
      </div>

      <div className="items-center justify-end px-3">
        <DataExportDropdown
          fileName={`${actor?.name ?? actor?.company_name} - ${
            currentBoard.title
          } ${moment().format(`YYYYMMDD`)}`}
          title={t('crm.board-actions.export-board')}
          tableHeaders={headers}
          dataFetchFunction={commonDataFetchFunction}
          data={getExportDataCrm(t, currentBoard)}
          down={true}
        />
      </div>

      <div
        className={cls(
          'grid font-demi dark:bg-dark-faded text-[10px] items-center gap-1 text-gray-500 dark:text-gray-300 flex-1 justify-end bg-gray-100 rounded-sm py-1.5 px-2',
          {
            'grid-cols-2': numberOfTogglesShown === 2,
            'grid-cols-3': numberOfTogglesShown === 3,
            'grid-cols-4': numberOfTogglesShown === 4
          }
        )}>
        {!currentBoard?.isPrivate && (
          <Toggle
            title={t('crm.board-actions.filter-by-mine')}
            isChecked={addedByMe}
            onCheck={(checked) => {
              setAddedByMe(checked as never);
              if (validationView) {
                SetValidationView(false as never);
              }
            }}
            icon={<UserStoryIcon className="dark:text-white" />}
          />
        )}

        {validationEnabled && (
          <Toggle
            isChecked={validationView}
            onCheck={(checked) => {
              SetValidationView(checked as never);
              if (checked && groupByOwn) {
                setGroupByOwn(false as never);
              }
              if (checked && addedByMe) {
                setAddedByMe(false as never);
              }
              if (checked && compact) {
                setCompact(false as never);
                setShowThumbnails(true as never);
              }
            }}
            title={t('crm.board-actions.validation-view')}
            icon={<ValidationIcon className="dark:text-white h-4 w-4" />}
          />
        )}

        <Toggle
          title={t('crm.board-actions.local-council-view')}
          isChecked={groupByOwn}
          onCheck={(checked) => {
            setGroupByOwn(checked as never);
            if (validationView) {
              SetValidationView(false as never);
            }
          }}
          icon={<MapPinpoint02Icon className="dark:text-white" />}
        />

        <Toggle
          title={t('crm.board-actions.compact-view')}
          isChecked={compact}
          onCheck={(checked) => {
            setCompact(checked as never);
            setShowThumbnails(!checked as never);
            if (validationView) {
              SetValidationView(false as never);
            }
          }}
          icon={<AlbumNotFound01Icon className="dark:text-white" />}
        />
      </div>
    </div>
  );
}
