import { primaryTabs } from 'src/lib/routes';

type Tab = {
  name: string;
  href: string;
  index?: boolean;
  query?: any;
  selected?: boolean;
  default?: boolean;
  items?: Tab[];
  shallow?: boolean;
};

const inPath = (paths, path) => {
  let foundPath = false;

  paths?.map((a) => {
    // split into paths into segments between '/', and check that atleast beginnings are matching
    const pathParts = path.split('/').filter((p) => p !== '');
    const aParts = a.split('/').filter((p) => p !== '');
    for (let i = 0; i < pathParts.length; i++) {
      if (pathParts[i] === aParts[i]) {
        foundPath = true;
      } else {
        break;
      }
    }
  });

  return foundPath;
};

const exactPath = (paths, path) => {
  let foundPath = false;
  paths?.map((a) => {
    if (path.trim() === a.trim()) {
      foundPath = true;
    }
  });
  return foundPath;
};

const tabs = {
  getPrimaryTabs: function (
    asPath: string,
    uuid: string | null,
    second: string | null,
    third: string | null,
    t: any
  ): any[] {
    let obj = primaryTabs
      .getPrimaryRoutes(uuid, second, third, t)
      ?.find((a) => exactPath(a?.paths, asPath));
    if (!obj) {
      const objects: any[] = primaryTabs
        .getPrimaryRoutes(uuid, second, third, t)
        ?.filter((a) => inPath(a?.paths, asPath));
      if (objects?.length > 1) {
        //Find the best matching, in this case the longest
        let longest = 0;
        objects?.map((a) => {
          if (a?.paths[0]?.length > longest && asPath.includes(a?.paths[0])) {
            obj = a;
            longest = a?.paths[0]?.length;
          }
        });
      } else {
        obj = objects.pop();
      }
    }

    if (obj && obj?.tabs) {
      return obj?.tabs;
    } else {
      return [];
    }
  },
  getSecondaryTabs: function (asPath, uuid, second, third, t): Tab[] {
    let obj = primaryTabs
      .getPrimaryRoutes(uuid, second, third, t)
      ?.find((a) => exactPath(a?.paths, asPath));
    if (!obj) {
      const objects: any[] = primaryTabs
        .getPrimaryRoutes(uuid, second, third, t)
        ?.filter((a) => inPath(a?.paths, asPath));
      if (objects?.length > 1) {
        //Find the best matching, in this case the longest
        let longest = 0;
        objects?.map((a) => {
          // if (a?.paths[0]?.length > longest) {
          if (a?.paths[0]?.length > longest && asPath.includes(a?.paths[0])) {
            obj = a;
            longest = a?.paths[0]?.length;
          }
        });
      } else {
        obj = objects.pop();
      }
    }
    let tabs: Tab[] = [];

    obj?.tabs?.map((i) => {
      if (i?.href === asPath || i?.items?.find((a) => a?.href === asPath)) {
        tabs = i?.items;
      }
    });

    return tabs;
  }
};

export default tabs;
