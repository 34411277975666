import { Popover, Transition } from '@headlessui/react';
import { TrashIcon } from '@heroicons/react/24/solid';
import cls from 'classnames';
import { Trans, useTranslation } from 'next-i18next';
import { Fragment } from 'react';
import { toast } from 'react-hot-toast';
import { getCategoryName } from 'shared/dist/constants';
import { ButtonType } from 'shared/dist/types';

import Button from '@/src/widgets/buttons';

export default function DeleteTabBtn({
  tabName,
  deleteTab,
  inSummary
}: {
  tabName: string;
  deleteTab: any;
  inSummary: boolean;
}) {
  const { t } = useTranslation();

  return (
    <>
      <Popover className="relative flex justify-end">
        {({ open }) => (
          <>
            <Button
              popover
              type={!open ? ButtonType.DELETE : ButtonType.CANCEL}
              className={
                inSummary
                  ? '!text-gray-400 hover:!text-bobo-bricksred !bg-transparent !shadow-none !p-0'
                  : 'w-[85px]'
              }>
              {inSummary ? (
                <TrashIcon className="h-5 w-5" />
              ) : (
                <span>{!open ? t('value-calculator.delete-tab') : t('general.cancel')}</span>
              )}
            </Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1">
              <Popover.Panel className="absolute bottom-1 right-3/3 z-10 mb-[38px] w-screen translate-x-0 transform px-4 sm:px-0 max-w-sm">
                {({ close }) => (
                  <div className="rounded-lg shadow-2xl ring-1 ring-black/5 bg-white dark:bg-dark-morefaded overflow-hidden">
                    <div className="p-6 pb-0 text-base text-gray-800 dark:text-white font-demi">
                      <Trans i18nKey="value-calculator.delete-tab-validate" t={t}>
                        Är du säker på att du vill radera fliken{' '}
                        <strong>
                          {{
                            tabName:
                              t(getCategoryName(tabName)).length > 0
                                ? t(getCategoryName(tabName))
                                : tabName
                          }}
                        </strong>
                        ?
                      </Trans>
                    </div>
                    <div className="relative flex justify-between gap-2 items-stretch p-6 z-50">
                      <button
                        onClick={() => {
                          close();
                        }}
                        className={cls(
                          `w-1/2 transition-all cursor-pointer rounded-md bg-gray-50 p-3 duration-200 flex justify-between items-center hover:bg-purple-50 dark:bg-dark-lessfaded dark:hover:bg-purple-900/20`
                        )}>
                        <div className="text-sm text-center w-full">
                          <div>{t('general.cancel')}</div>
                        </div>
                      </button>
                      <div className="border-l dark:border-dark-lessfaded mx-1 flex-1" />
                      <button
                        onClick={() => {
                          deleteTab(tabName);
                          toast.success(
                            t('value-calculator.delete-tab-validated', {
                              tabName:
                                t(getCategoryName(tabName)).length > 0
                                  ? t(getCategoryName(tabName))
                                  : tabName
                            })
                          );
                          close();
                        }}
                        className={cls(
                          `w-1/2 transition-all cursor-pointer rounded-md bg-gray-50 dark:bg-dark-lessfaded dark:hover:bg-bobo-bricksred p-3 duration-200 flex justify-between items-center hover:bg-purple-50`
                        )}>
                        <div className="text-sm text-center w-full">
                          <div>{t('general.delete')}</div>
                        </div>
                      </button>
                    </div>
                  </div>
                )}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
}
