export const crmDialogTheme = {
  container: 'flex-grow flex flex-col items-center gap-6',
  closeContainerX:
    'absolute right-5 top-5 z-50 text-gray-500 hover:text-bobo-prophover dark:text-gray-300 dark:hover:text-dark-prophover focus-visible:ring-indigo-500 focus-visible:text-indigo-500 dark:focus-visible:ring-indigo-400 dark:focus-visible:text-indigo-400 focus-visible:ring-2 focus-visible:hover:ring-0 rounded-md p-1 focus-visible:hover:text-gray-500 dark:focus-visible:hover:text-gray-300',
  image: 'flex justify-center',
  icon: 'relative z-20 text-center flex justify-center w-full',
  textContainer: 'flex flex-col w-full text-center items-center',
  title: 'text-lg font-medium text-gray-900 dark:text-gray-300 w-3/4 leading-6',
  errorTitle: 'text-red-700 font-light w-3/4 leading-6',
  subtext: 'mt-6 text-sm w-[80%] text-gray-500 dark:text-gray-300 text-balance',
  label: 'mt-8 text-xs font-medium text-gray-800 dark:text-gray-400',
  labelWithoutSubtext: 'mt-9 text-xs font-medium text-gray-400 dark:text-gray-300',
  input:
    'flex dark:border-dark-morefaded dark:bg-dark-lessfaded rounded-sm px-2 pt-2 placeholder:text-gray-400 dark:placeholder:text-gray-400 w-[60%] last:my-2',
  buttonContainer: 'flex flex-grow justify-between w-full items-end mt-4',
  subButtonContainer: 'flex gap-3',
  buttonClose:
    'rounded-sm text-gray-500 dark:text-gray-300 px-4 py-2 text-sm font-normal hover:bg-gray-100 dark:hover:bg-dark-faded dark:hover:text-white last:ml-auto only:ml-auto focus-visible:ring-indigo-500 dark:focus-visible:ring-indigo-400 focus-visible:text-indigo-500 dark:focus-visible:text-indigo-400 focus-visible:bg-transparent dark:focus-visible:bg-transparent focus-visible:ring-2 focus-visible:hover:ring-0 focus-visible:hover:bg-gray-100 focus-visible:hover:text-gray-500 focus:outline-none',
  buttonConfirm:
    'disabled:bg-gray-300 rounded-sm bg-bobo-prop dark:disabled:bg-dark-lessfaded dark:bg-dark-prop text-white text px-4 py-2 px-2 text-sm font-normal hover:bg-[#48b1a4] dark:hover:bg-dark-prophover transition-color duration-300 focus-visible:ring-indigo-500 dark:focus-visible:ring-indigo-400 focus-visible:text-indigo-500 dark:focus-visible:text-indigo-400 focus-visible:bg-transparent dark:focus-visible:bg-transparent focus-visible:ring-2 focus-visible:hover:ring-0 focus-visible:hover:bg-[#48b1a4] focus-visible:hover:text-white focus:outline-none',
  buttonDelete:
    'disabled:bg-gray-300 dark:disabled:bg-dark-lessfaded rounded-sm bg-bobo-council text-white px-4 py-2 text-sm font-normal focus-visible:ring-indigo-500 focus-visible:text-indigo-500 dark:focus-visible:ring-indigo-400 dark:focus-visible:text-indigo-400  focus-visible:bg-transparent dark:focus-visible:bg-transparent focus-visible:ring-2 focus-visible:hover:bg-[#d39d9b] focus-visible:hover:ring-0 focus-visible:hover:text-white hover:bg-[#d39d9b] dark:hover:bg-bobo-councilred transition-all duration-300 focus:outline-none transition-color duration-300',
  buttonArchive:
    'disabled:bg-gray-300 dark:disabled:bg-dark-lessfaded rounded-sm bg-bobo-council text-white px-4 py-2 text-sm font-normal focus-visible:ring-indigo-500 focus-visible:text-indigo-500 dark:focus-visible:ring-indigo-400 dark:focus-visible:text-indigo-400  focus-visible:bg-transparent dark:focus-visible:bg-transparent focus-visible:ring-2 focus-visible:hover:bg-[#d39d9b] focus-visible:hover:ring-0 focus-visible:hover:text-white hover:bg-[#d39d9b] dark:hover:bg-bobo-councilred transition-all duration-300 focus:outline-none transition-color duration-300',
  buttonBack:
    'inline-flex justify-center rounded-sm text-bobo-prop dark:text-dark-prop px-4 py-2 text-sm font-normal hover:bg-[#48b1a4] dark:hover:bg-dark-prophover hover:text-white dark:hover:text-white transition-color duration-300 focus-visible:ring-indigo-500 dark:focus-visible:ring-indigo-400 focus-visible:text-indigo-500 focus-visible:bg-transparent focus-visible:ring-2 focus-visible:hover:ring-0 focus-visible:hover:bg-[#48b1a4] focus-visible:hover:text-white focus:outline-none',
  selectionContainer: 'flex flex-col items-center w-full gap-1 justify-start',
  selectionTitle:
    'text-xs mb-2 text-left mx-8 font-medium border-gray-100 border-b dark:border-dark-morefaded w-[90%]',
  selectionGrid: 'grid md:grid-cols-4 grid-cols-1 sm:grid-cols-2 gap-2 text-xs w-[90%]',
  selectionItem:
    'bg-white border rounded-md cursor-pointer transition-all duration-300 hover:bg-bobo-proplight dark:border-dark-morefaded dark:hover:bg-dark-prophover dark:bg-dark-faded focus-visible:ring-2 focus-visible:border-none focus-visible:ring-bobo-prop dark:focus-visible:ring-dark-prop p-3 break-words relative flex items-center justify-center max-h-[max-content]',
  privacyContainer: 'w-full md:w-4/6 flex justify-between text-xs gap-4 text-center mt-2',
  privacyItem:
    'border-2 ring-gray-200 text-center rounded-md p-4 w-1/2 cursor-pointer hover:bg-bobo-proplight disabled:cursor-not-allowed disabled:bg-gray-100 disabled:dark:bg-dark-morefaded disabled:dark:border-gray-400 dark:border-dark-morefaded dark:hover:bg-dark-prophover dark:bg-dark-faded focus-visible:ring-2 focus-visible:border-none focus-visible:ring-bobo-prop dark:focus-visible:ring-dark-prop dark:focus-visible:bg-dark-faded dark:hover:focus-visible:bg-dark-prophover',
  privateBoardIcon: 'w-6 h-6 text-gray-600 m-auto dark:text-white',
  publicBoardIcon: 'w-6 h-6 text-bobo-prop m-auto dark:text-dark-prop',
  privacyTitle: 'font-demi mt-2',
  subtextDelete: 'mt-3 text-sm w-[80%] text-bobo-burgundy dark:text-gray-300',
  inputContainerArchive: 'flex mt-8 w-[90%] gap-2 h-full items-center mb-3',
  inputArchiveLabel: 'leading-4 text-sm text-right text-gray-500 dark:text-gray-300',
  inputArchive:
    'p-2 py-1 w-4/5 bg-card-graypanel border-2 dark:bg-dark-lessfaded dark:placeholder:text-gray-400 rounded dark:border-dark-morefaded',
  inputContainerDelete: 'flex mt-8 w-[90%] gap-2 h-full items-center mb-3',
  inputDeleteLabel: 'leading-4 text-sm text-right text-gray-500 dark:text-gray-300',
  inputDelete:
    'p-2 py-1 w-4/5 bg-card-graypanel border-2 dark:bg-dark-lessfaded dark:placeholder:text-gray-400 rounded dark:border-dark-morefaded'
};
