import { Dialog } from '@headlessui/react';
import moment from 'moment';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { PropertyVM } from 'shared/dist/types/property2';

import { useAccess } from '@/src/context/access-features-context';
import { getReport } from '@/src/lib/prop-client-wrapper';

//Step 1 = FDS
export function UnlockFDS({ close, object }: { close: () => void; object: PropertyVM }) {
  const [loading, setLoading] = useState<boolean>(true);
  const [freeCount, setFreeCount] = useState<number>(0);
  const { accessFeatures, unlockAccessFeatures } = useAccess();
  const router = useRouter();
  const { t } = useTranslation('common');
  const isUnlocked = accessFeatures?.fds || object?.access_level > 0;
  const uuid = object?.uuid;

  useEffect(() => {
    fetch(`/api/crm/checkFreeCredits`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json?.count) {
          setFreeCount(json?.count);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [uuid]);

  const fetchFDS = () => {
    if (isUnlocked) {
      toast.loading(t('fds.loading-toast', { authorityNickname: object?.authorityNickname }));
      getReport('fds', object?.uuid, router, t);
    }
  };

  return (
    <>
      <div className="flex justify-center">
        <Image
          alt="main illustration"
          src={`/images/property-focus.svg`}
          height={200}
          width={200}
        />
      </div>

      <Dialog.Title
        as="h3"
        className="text-lg font-medium leading-6 text-gray-900 dark:text-white w-3/4 m-auto">
        {isUnlocked ? t('fds.get-fds') : t('fds.unlock-title')}
      </Dialog.Title>

      <div className="mt-2 text-sm text-gray-500 dark:text-gray-100">
        {isUnlocked ? (
          <>
            <Trans
              i18nKey="fds.get-fds-desc"
              components={[<strong className="text-bobo-prop dark:text-dark-prop" key={0} />]}
              values={{ authorityNickname: object?.authorityNickname }}
            />

            <p>{t('fds.sent-to-email')}</p>
          </>
        ) : (
          <>
            <Trans i18nKey="fds.unlock-description" t={t} components={[<strong key={0} />]} />

            <p className="mt-2">
              {loading ? (
                t('fds.loading-unlock', { month: moment().format('MMMM') })
              ) : freeCount > 0 ? (
                <Trans
                  i18nKey="fds.amount-free-left"
                  values={{ freeCount: freeCount, month: moment().format('MMMM YYYY') }}
                  components={[<strong key={0} />]}
                />
              ) : (
                <>{t('fds.no-free-left', { month: moment().format('MMMM YYYY') })}</>
              )}
            </p>
          </>
        )}
      </div>

      <div className="mt-12 flex space-x-4 justify-between">
        <button
          className="inline-flex justify-center rounded-sm text-gray-500 px-4 py-2 text-sm font-normal hover:bg-gray-100 dark:hover:bg-dark-lessfaded dark:hover:text-white"
          onClick={close}>
          {isUnlocked ? t('general.close') : t('general.cancel')}
        </button>

        <div>
          <button
            className="inline-flex disabled:bg-gray-300 dark:disabled:bg-gray-500 disabled:text-gray-400 dark:disabled:text-gray-300 disabled:cursor-not-allowed justify-center rounded-sm bg-bobo-prop dark:bg-dark-prop dark:hover:bg-dark-prophover text-white px-4 py-2 text-sm font-normal hover:bg-[#48b1a4] transition-color duration-300"
            disabled={loading}
            onClick={() => {
              if (isUnlocked) {
                fetchFDS();
                close();
              } else {
                unlockAccessFeatures('fds', uuid);
              }
            }}>
            {isUnlocked
              ? t('fds.get-fds')
              : freeCount > 0
                ? t('fds.unlock-free')
                : t('fds.confirm')}
          </button>
        </div>
      </div>
    </>
  );
}
