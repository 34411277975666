import { ArrowRightCircleIcon } from '@heroicons/react/24/solid';
import { LinearProgress } from '@material-ui/core';
import cls from 'classnames';
import { Trans, useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { PropertyVM } from 'shared/dist/types/property2';

import { RowTableBody } from '@/src/widgets/row-table-body';
import SectionHeader from '@/src/widgets/section-header';

import { getOrgNr, OverviewPortfolioSimple } from './overviewTable';

const fetchCompanyData = async (actorId: string) => {
  return await fetch(`/api/company/getCorporateStructure?actor_id=${actorId}&ultra_light=1`)
    .then((res: any) => {
      return res?.json();
    })
    .then((json) => {
      return {
        root: json?.data,
        initial_actor_id: actorId
      };
    })
    .catch((error) => {
      console.error('[getCorporateStructure fetch error]', error);
      return [];
    });
};

export function CompanyTable({
  property,
  close,
  slideIn,
  showFull = false
}: {
  property: PropertyVM;
  close?: () => void;
  slideIn?: boolean;
  showFull?: boolean;
}) {
  const headers: any[] = [];
  const { t } = useTranslation();

  const [roots, setRoots] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showActorDetails, setShowActorDetails] = useState<boolean>(false);

  const getOrgNrString = (actor): string => {
    const orgNr = getOrgNr(actor);
    if (!orgNr) {
      return '';
    }
    return ` [${orgNr}]`;
  };
  const fetchData = async () => {
    const list: any[] = [];

    property.leaseHolders?.map((lh) => {
      list.push({ ...lh, owner_type: 'LEASE_HOLDER' });
    });

    property.landOwners?.map((lo) => {
      list.push({ ...lo, owner_type: 'LAND_OWNER' });
    });

    const structs: any[] = await Promise.all(
      list?.map(async (l) => {
        return await fetchCompanyData(l?.actor_id);
      })
    );

    setRoots(structs);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  {
    if (property.leaseHolders?.length > 0) {
      headers.push(<span key={`lease-1`}>{t('property.owner.lease-holder')}</span>);
    }

    property.leaseHolders?.map((item, idx) => {
      const leaseStruct: any = roots?.find((a) => a.initial_actor_id === item.actor_id)?.root;
      const rootCo: any = leaseStruct?.find((a) => a.is_root);

      if (property.leaseHolders?.length > 1) {
        headers.push(
          <span key={`leaseholder_${idx}`}>
            {item.name}
            {getOrgNrString(item)}
            {property.leaseHolders?.length - 1 === idx
              ? ' '
              : property.leaseHolders?.length - 2 === idx
                ? ' & '
                : ', '}
          </span>
        );
      } else {
        if (item.level === 1) {
          headers.push(
            <span key={3}>
              <span className="font-bold">{item.name?.trim() ?? item.company_name?.trim()}</span>
              {getOrgNrString(item)}{' '}
              <Trans i18nKey={'property.owner.root-to-company-count'} t={t}>
                som är koncernmoderbolag till{' '}
                <strong>{{ companyCount: rootCo?.root_prop_count }} fastigheter</strong>.
              </Trans>
            </span>
          );
        } else if (item.level > 1 && rootCo) {
          headers.push(
            <span key={4}>
              <span className="font-bold">{item.name?.trim() ?? item.company_name?.trim()}</span>
              {getOrgNrString(item)}{' '}
              <Trans i18nKey={'property.ownercompany-structure-count'} t={t}>
                som ingår i en koncern med{' '}
                <strong>{{ companyCount: rootCo.root_prop_count }} fastigheter</strong>.
                Koncernmoderbolaget är {{ root: rootCo.root_actor_name?.trim() }}.{' '}
              </Trans>
            </span>
          );
        } else {
          headers.push(
            <span key={5}>
              <span className="font-bold">{item.name?.trim() ?? item.company_name?.trim()}</span>
              {getOrgNrString(item)}
              {t('property.owner.no-company-structure')}
            </span>
          );
        }
      }
    });
  }

  {
    if (property.landOwners?.length > 0) {
      headers.push(
        <span key={6}>
          {t('property.owner.legal-owner', { count: property.landOwners?.length })}
        </span>
      );
    }

    property.landOwners?.slice(0, 4)?.map((item, idx) => {
      const leaseStruct: any = roots?.find((a) => a.initial_actor_id === item.actor_id)?.root;
      const rootCo: any = leaseStruct?.find((a) => a.is_root);

      if (property.landOwners?.length > 1) {
        headers.push(
          <span className="font-bold" key={idx}>
            {item.name?.trim() ?? item.company_name?.trim()}
            {getOrgNrString(item)}
            {property.landOwners?.length - 1 === idx
              ? ' '
              : property.landOwners?.length - 2 === idx
                ? ' & '
                : ', '}
          </span>
        );
      } else {
        if (item.level === 1) {
          headers.push(
            <span key={8}>
              <span className="font-bold">{item.name?.trim() ?? item.company_name?.trim()}</span>
              {getOrgNrString(item)}{' '}
              <Trans i18nKey={'property.owner.root-to-company-count'} t={t}>
                som är koncernmoderbolag till{' '}
                <strong>{{ companyCount: rootCo?.root_prop_count }} fastigheter</strong>.
              </Trans>
            </span>
          );
        } else if (item.level > 1 && rootCo) {
          headers.push(
            <span key={9}>
              <span className="font-bold">{item.name?.trim() ?? item.company_name?.trim()}</span>
              {getOrgNrString(item)}{' '}
              <Trans i18nKey={'property.ownercompany-structure-count'} t={t}>
                som ingår i en koncern med{' '}
                <strong>{{ companyCount: rootCo.root_prop_count }} fastigheter</strong>.
                Koncernmoderbolaget är {{ root: rootCo.root_actor_name?.trim() }}
              </Trans>
            </span>
          );
        } else {
          headers.push(
            <span key={10}>
              <span className="font-bold">{item.name?.trim() ?? item.company_name?.trim()}</span>
              {getOrgNrString(item)}
              {t('property.owner.no-company-structure')}
            </span>
          );
        }
      }
    });

    if (property.landOwners?.length > 4) {
      headers.push(
        <span key={20}>
          {t('property.owner.and-x-more', { companyCount: property.landOwners?.length - 4 })}
        </span>
      );
    }
  }

  const subTitle: JSX.Element | JSX.Element[] = (
    <div className="px-1 space-y-1 text-gray-700 dark:text-white text-[11px] leading-[14px]">
      <div className="text-xs">{headers}</div>
      <div className={cls('flex flex-col', slideIn ? 'items-start' : 'items-end')}>
        {!showFull ? (
          <button
            onClick={() => {
              setShowActorDetails(!showActorDetails);
            }}
            className="text-bobo-prop dark:text-dark-prop hover:text-bobo-prophover dark:hover:text-dark-prophover transition-colors duration-300 font-bold rounded-md text-xs mt-1 flex items-center space-x-1 py-1">
            <div>
              {!showActorDetails
                ? t('property.owner.show-company-info')
                : t('property.owner.hide-company-info')}
            </div>

            <ArrowRightCircleIcon
              className={cls('h-5 w-5 transition-transform duration-300', {
                'rotate-90': showActorDetails || showFull
              })}
            />
          </button>
        ) : (
          <div className="h-4">&nbsp;</div>
        )}
      </div>
    </div>
  );

  const ownerCount = (property.leaseHolders?.length ?? 0) + (property.landOwners?.length ?? 0);

  return (
    <div className="py-4 px-0 break-inside-avoid border rounded border-gray-100 bg-gray-50 dark:bg-dark-lessfaded dark:border-dark-faded relative">
      <div className="px-6">
        <SectionHeader
          title={t('general.owner', { count: ownerCount })}
          sources={'Lantmäteriet, Proff'}
        />
      </div>

      {loading ? (
        <div className="px-6">
          <LinearProgress />
        </div>
      ) : ownerCount > 0 ? (
        <RowTableBody>
          <div className="text-xs pt-2 px-6">{subTitle}</div>

          <div
            className={cls(
              'overflow-y-scroll transition-all duration-300 w-full',
              {
                'max-h-[850px]': showActorDetails || showFull
              },
              {
                '!max-h-0': !showActorDetails && !showFull
              }
            )}>
            <div className="flex h-fit justify-start items-start gap-4 py-3 w-fit px-6">
              {property.leaseHolders?.map((item, idx) => {
                const leaseStruct: any = roots?.find(
                  (a) => a.initial_actor_id === item.actor_id
                )?.root;
                const rootCo: any = leaseStruct?.find((a) => a.actor_id === item.actor_id);

                return (
                  <div
                    className="bg-white dark:bg-dark-lessfaded rounded-md w-full min-w-[400px]"
                    key={`lease-${idx + 10}`}>
                    <OverviewPortfolioSimple
                      companyData={{
                        actor: {
                          ...item,
                          ...rootCo,
                          owner_type: 'LEASE_HOLDER'
                        }
                      }}
                      close={close}
                      slideIn={slideIn}
                      compact={true}
                      corparateStructure={leaseStruct}
                    />
                  </div>
                );
              })}
              {property.landOwners?.map((item, idx) => {
                const leaseStruct: any = roots?.find(
                  (a) => a.initial_actor_id === item.actor_id
                )?.root;
                const rootCo: any = leaseStruct?.find((a) => a?.actor_id === item.actor_id);
                return (
                  <div
                    className="bg-white dark:bg-dark-lessfaded rounded-md w-full min-w-[400px]"
                    key={`land-${idx}`}>
                    <OverviewPortfolioSimple
                      companyData={{
                        actor: {
                          ...item,
                          ...rootCo,
                          owner_type: 'LAND_OWNER'
                        }
                      }}
                      close={close}
                      slideIn={slideIn}
                      compact={true}
                      corparateStructure={leaseStruct}
                    />
                  </div>
                );
              })}
            </div>
            {showActorDetails && ownerCount > 1 ? (
              <>
                <div className="absolute right-0 top-0 z-50 h-full w-8 bg-gradient-to-l from-gray-50 dark:from-dark-lessfaded to-transparent" />
                <div className="absolute left-0 top-0 z-50 h-full w-8 bg-gradient-to-r from-gray-50 dark:from-dark-lessfaded to-transparent" />
              </>
            ) : null}
          </div>
        </RowTableBody>
      ) : (
        <RowTableBody>
          <div className="text-xs mt-2 px-6">{t('property.owner.missing-company-info')}</div>
        </RowTableBody>
      )}
    </div>
  );
}
