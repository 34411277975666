import { asyncify, eachLimit } from 'async';
import _ from 'lodash';
import moment from 'moment';
import { EnvCertificate } from 'shared/dist/types';
import { PropertyVM } from 'shared/dist/types/property2';

import { getEnvCerts, getProperty } from '@/src/lib/prop-client-wrapper';
import { chunkArray } from '@/src/lib/utils/array';

/** used in relation to DataExportDropdown, part of 'dataFetchFunction'.
 * sets results on property objects.
 */
export async function fetchEnvCertificates(propChunk: PropertyVM[], t) {
  const missingItems: string[] = [];
  for await (const prop of propChunk) {
    if (!(prop?.envCerts instanceof Array)) {
      missingItems.push(prop.uuid);
    }
  }
  const envCertsResp = await getEnvCerts(missingItems);
  if (!envCertsResp.error) {
    const envCerts = envCertsResp.result;
    if (!('certs' in envCerts)) {
      for (const envCert of envCerts) {
        const prop = propChunk.find((p) => p.uuid === envCert.uuid);
        if (prop) {
          prop.envCerts = envCert.certs;
        }
      }
    }
    for await (const prop of propChunk as PropertyVM[]) {
      prop.environment_certification_str =
        (prop.envCerts || []).length > 0 ? `${t(`property.env.certified`)}: ` : '';

      let certificateDescriptionTexts: string[] = (prop.envCerts || []).map(
        (cert: EnvCertificate) =>
          `${cert.issuer}${
            cert.cert_date > 0 ? ' ' + moment(cert.cert_date * 1000).format('YYYY-MM-DD') : ''
          }${
            cert?.raw_data?.system_version_name ? ' ' + cert?.raw_data?.system_version_name : ''
          } ${cert.cert_level}`
      );
      certificateDescriptionTexts = _.uniq(certificateDescriptionTexts);
      prop.environment_certification_str += certificateDescriptionTexts.join(', ');
    }
  }
}

export async function commonDataFetchFunction(t, data, progressCb) {
  let i = 0;

  const chunkSize = 1;
  const chunkedData = chunkArray(chunkSize, data);
  const chunksToProcessInParallel = 10;

  await new Promise((resolve) => {
    eachLimit(
      chunkedData,
      chunksToProcessInParallel,
      asyncify(async (propChunk: any[]) => {
        await Promise.all([
          (async () => {
            for await (const prop of propChunk) {
              if (!prop?.landLeaseAgreement) {
                await getProperty(prop, ['lagfart', 'landOwnerSimple'], true)
                  .then((response) => {
                    if (response.error) {
                      console.error('getProperty error', response.errMessage);
                      return;
                    }
                    prop.landLeaseAgreement = response.property.landLeaseAgreement;
                    prop.has_land_lease_agreement = prop?.landLeaseAgreement?.uuid ? `Ja` : `Nej`;
                    prop.lease_fee = prop?.landLeaseAgreement?.lease_fee ?? 0;
                    prop.lease_annum = prop?.landLeaseAgreement?.lease_annum ?? 0;
                  })
                  .catch((err) => {
                    console.error('error fetching landLeaseAgreement', err);
                  });
              }
            }
          })(),
          (async () => {
            const addressRequiredUUIDS: string[] = [];

            for (const prop of propChunk) {
              if (!prop.addresses || !prop.addresses_str) {
                addressRequiredUUIDS.push(prop.uuid);
              }
            }

            if (addressRequiredUUIDS.length > 0) {
              await fetch(
                `/api/prop/getAddress?uuids=${addressRequiredUUIDS.join(',')}&refresh=true`
              )
                .then((response) => response.json())
                .then((response) => {
                  const addressesByPropUUID = response.addresses;
                  for (const prop_uuid in addressesByPropUUID) {
                    const property = propChunk.find((prop) => prop.uuid === prop_uuid);

                    property.addresses = addressesByPropUUID[prop_uuid];

                    property.addresses_str = (property?.addresses || [])
                      .map((addr) => {
                        return `${addr?.street} ${addr?.street_no > 0 ? addr?.street_no : ''}${
                          addr?.street_letter && addr?.street_letter?.length > 0
                            ? addr?.street_letter
                            : ''
                        } ${addr?.postal_code} ${addr?.postal_area}`;
                      })
                      .join(`, `);
                  }
                })
                .catch((err) => {
                  console.error('error fetching addresses', err);
                });
            }
          })(),
          (async () => {
            for await (const prop of propChunk) {
              if (!prop?.energyStatement?.energy_class) {
                await fetch(
                  `/api/environment/get-energy-statement?uuid=${prop.uuid}&county=${prop.county}&authority_nickname=${prop.authority_nickname}&refresh=true&limit=1`
                )
                  .then((response) => response.json())
                  .then((response) => {
                    const latestStatement = response.energyStatements[0] ?? {};
                    prop.energyStatement = latestStatement;
                    prop.energy_class = latestStatement.energy_class ?? '-';
                  })
                  .catch((err) => {
                    console.error('error fetching energy statement', err);
                  });
              }
            }
          })(),
          fetchEnvCertificates(propChunk, t)
        ]);

        progressCb(i);
        i = i + chunkSize;
      }),
      (err) => {
        if (err) {
          console.error('eachlimit error:', err);
        }
        resolve(null);
      }
    );
  });
  return data;
}
