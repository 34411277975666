/* eslint-disable i18next/no-literal-string */
import { useRouter } from 'next/router';

import Button from '../buttons';

export default function ItIsMadMapButton({ localCouncilCode }: { localCouncilCode: number }) {
  const router = useRouter();
  return (
    <>
      <Button onClick={() => router.push(`/itismad/map/${localCouncilCode}`)}>Kartvy</Button>
    </>
  );
}
