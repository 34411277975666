import { ShareIcon } from '@heroicons/react/24/outline';
import { ChartBarIcon, CubeIcon, MapPinIcon } from '@heroicons/react/24/solid';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { PROP_TYPES } from 'shared/dist/constants';

import WrapSection from '@/src/layout/section-wrapper';
import {
  TYPES_HOUSING,
  TYPES_INDUSTRY,
  TYPES_OFFICE,
  TYPES_SPECIAL,
  TYPES_STREET,
  TYPES_WAREHOUSE
} from '@/src/lib/constants';
import { compare_year } from '@/src/lib/utils/sort';
import { FinancialRow } from '@/src/widgets/financials';
import { ActorOrgStructureText } from '@/src/widgets/owner';

const TaxCodePieChart = dynamic(() => import('@/src/widgets/charts/tax-code-pie-chart'), {
  ssr: false
});

export function OverviewParent({
  companyData,
  corparateStructure,
  close,
  slideIn
}: {
  companyData: any;
  corparateStructure: any;
  close?: any;
  slideIn?: boolean;
}) {
  const [propTypes, setPropTypes] = useState<any[] | null>(null);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [hasSpecialProps, setHasSpecialProps] = useState<number>(0);

  const router = useRouter();

  const { t } = useTranslation('common');

  const rootNode: any = corparateStructure?.find((a) => a?.is_root);

  const activeNode: any = corparateStructure?.find(
    (a) => a?.actor_id === companyData.actor.actor_id
  );

  const activeNodeProperties: any = corparateStructure?.filter((a) => a?.obj_type === 'property');

  const getPropType = (taxCode) => {
    if (taxCode && TYPES_HOUSING.includes(taxCode)) {
      return 'housing';
    } else if (TYPES_OFFICE.includes(taxCode)) {
      return 'space';
    } else if (TYPES_WAREHOUSE.includes(taxCode)) {
      return 'warehouse';
    } else if (TYPES_SPECIAL.includes(taxCode)) {
      return 'special';
    } else if (TYPES_INDUSTRY.includes(taxCode)) {
      return 'industry';
    } else if (TYPES_STREET.includes(taxCode)) {
      return 'street';
    } else {
      return 'private';
    }
  };

  useEffect(() => {
    const pTypes: any[] = [];

    let hasSpecial = 0;
    let tCount = 0;

    activeNodeProperties?.map((a) => {
      a?.tax_codes?.map(() => {
        tCount++;
      });
    });

    if (!tCount) {
      tCount = 1;
    }

    activeNodeProperties?.map((a) => {
      a?.tax_codes?.map((tc) => {
        const propType: string = getPropType(tc);

        if (propType === 'special') {
          hasSpecial++;
        }

        const idx: number = pTypes?.findIndex((a) => a?.type === propType);

        if (idx < 0) {
          pTypes.push({
            type: propType,
            count: 1,
            percentage: Math.round((1 / tCount) * 100),
            desc: t(PROP_TYPES[propType])
          });
        } else {
          let tmp = pTypes[idx];

          const count = tmp?.count + 1;

          tmp = {
            ...tmp,
            count: tmp?.count + 1,
            percentage: Math.round((count / tCount) * 100)
          };
          pTypes[idx] = tmp;
        }
      });
    });

    setHasSpecialProps(hasSpecial);
    setPropTypes(pTypes);
    setTotalCount(tCount);
  }, []);

  const goToAllaBolag = (e) => {
    const allaBolag = `https://www.allabolag.se/${rootNode?.actor_id}/`;
    e.preventDefault();
    window.open(allaBolag, '_blank');
  };

  let financials: any[] = rootNode?.corp_financials?.sort(compare_year)?.map((a) => {
    return a;
  });

  let currYear: any = null;
  let previousYear: any = null;

  if (financials && financials?.length > 0 && activeNode?.level !== 0 && +financials[0]?.SDI > 0) {
    currYear = financials[0];

    if (financials && financials?.length > 1) {
      previousYear = financials[1];
    }
  }

  if (activeNode?.corp_financials?.length < 1) {
    financials = rootNode?.financials?.sort(compare_year)?.map((a) => {
      return a;
    });

    if (financials && financials?.length > 0) {
      currYear = financials[0];
      if (financials && financials?.length > 1) {
        previousYear = financials[1];
      }
    }
  }

  return (
    <div>
      <div className="border-gray-100">
        <WrapSection
          title={`Koncernen (${activeNode?.name})`}
          theme={slideIn ? 'compact' : 'modern'}>
          <div className="text-xs">
            <div className="mt-2">
              <ActorOrgStructureText activeNode={activeNode} rootNode={rootNode} country={`SE`} />
            </div>

            {activeNode?.root_prop_count > 0 ? (
              <button
                onClick={() => {
                  router.push(
                    `/avancerad/sok?owner_actor=${rootNode?.actor_id}&mode=structureOwned&to=list`,
                    '/avancerad/sok',
                    { shallow: true }
                  );
                  close();
                }}
                className="w-full flex transition-colors hover:bg-bobo-proplight dark:hover:bg-dark-prophover text-bobo-prop dark:text-dark-prop dark:hover:text-white duration-300 rounded-t-lg p-2 odd:bg-gray-50 border-b border-gray-100 dark:odd:bg-dark-faded dark:border-dark-morefaded">
                <div className="w-8">
                  <ShareIcon className="h-4 w-4 rotate-90" />
                </div>

                <div className="flex-1 text-left">
                  {t('property.owner.properties-in-structure')} ({activeNode?.root_actor_name})
                </div>

                <div className="font-normal">
                  {activeNode?.root_prop_count}
                  {t('company.units')}
                </div>
              </button>
            ) : (
              <div />
            )}

            <div className="w-full flex p-2 odd:bg-gray-50 border-b border-gray-100 dark:odd:bg-dark-faded dark:border-dark-morefaded">
              <div className="w-8">
                <CubeIcon className="h-4 w-4 text-gray-500 dark:text-gray-500" />
              </div>

              <div className="flex-1">
                {t('property.owner.area-in-structure', { areaType: 'BOA' })}
              </div>

              <div className="font-normal">
                {activeNode?.root_total_boa ? (
                  <NumberFormat
                    value={activeNode?.root_total_boa}
                    displayType={'text'}
                    suffix={t('general.square-meters')}
                    thousandSeparator={' '}
                  />
                ) : (
                  '-'
                )}
              </div>
            </div>

            <div className="w-full flex p-2 odd:bg-gray-50 border-b border-gray-100 dark:odd:bg-dark-faded dark:border-dark-morefaded">
              <div className="w-8">
                <CubeIcon className="h-4 w-4 text-gray-500 dark:text-gray-500" />
              </div>

              <div className="flex-1">
                {t('taxation.total-leasable-area')}
                {hasSpecialProps > 0 ? (
                  <span className="ml-1 text-bobo-council">
                    {t('property.owner.not-including', { amount: hasSpecialProps })}
                  </span>
                ) : null}
              </div>

              <div className="font-normal">
                {activeNode?.root_total_loa ? (
                  <NumberFormat
                    value={activeNode?.root_total_loa}
                    displayType={'text'}
                    suffix={t('general.square-meters')}
                    thousandSeparator={' '}
                  />
                ) : (
                  '-'
                )}
              </div>
            </div>

            <div className="w-full flex p-2 odd:bg-gray-50 border-b border-gray-100 dark:odd:bg-dark-faded dark:border-dark-morefaded">
              <div className="w-8">
                <MapPinIcon className="h-4 w-4 text-gray-500 dark:text-gray-500" />
              </div>

              <div className="flex-1">{t('property.total-taxed-area')}</div>

              <div className="font-normal">
                {activeNode?.root_total_land_area ? (
                  <NumberFormat
                    value={activeNode?.root_total_land_area}
                    displayType={'text'}
                    suffix={t('general.square-meters')}
                    thousandSeparator={' '}
                  />
                ) : (
                  '-'
                )}
              </div>
            </div>

            {propTypes && propTypes?.length > 0 ? (
              <div className="w-full h-72 mt-0">
                <div className="w-full flex p-2">
                  <div className="w-8">
                    <ChartBarIcon className="h-4 w-4 text-gray-500 dark:text-gray-500" />
                  </div>

                  <div className="flex-1">{t('property.owner.prop-distribution')}</div>
                </div>

                <TaxCodePieChart totalCount={totalCount} data={propTypes} />
              </div>
            ) : (
              <div className="border border-dashed border-red-400 p-2 px-6 mt-4 rounded-md">
                {t('property.owner.no-properties-in-structure')}
              </div>
            )}
          </div>
        </WrapSection>

        {activeNode?.corp_financials?.length > 0 ? (
          <div className="mt-2 w-full">
            <WrapSection
              title={t('property.owner.key-values-structure', {
                year: currYear?.period,
                currency: currYear?.currency
              })}
              theme={slideIn ? 'compact' : 'modern'}>
              <FinancialRow
                title={t('general.turnover')}
                keyItem={'SDI'}
                currYear={currYear}
                previousYear={previousYear}
              />

              <FinancialRow
                title={t('property.owner.financial-result')}
                keyItem={'resultat_e_finansnetto'}
                currYear={currYear}
                previousYear={previousYear}
              />

              <FinancialRow
                title={t('property.owner.current-result')}
                keyItem={'DR'}
                currYear={currYear}
                previousYear={previousYear}
              />
            </WrapSection>
          </div>
        ) : null}

        {activeNode?.corp_financials?.length < 1 &&
        activeNode?.level !== 0 &&
        currYear &&
        previousYear ? (
          <div className="mt-2 w-full">
            <WrapSection
              title={t('property.owner.key-values', {
                year: currYear?.period,
                currency: currYear?.currency
              })}
              theme={slideIn ? 'compact' : 'modern'}>
              <FinancialRow
                title={t('general.turnover')}
                keyItem={'SDI'}
                currYear={currYear}
                previousYear={previousYear}
              />

              <FinancialRow
                title={t('property.owner.current-result')}
                keyItem={'DR'}
                currYear={currYear}
                previousYear={previousYear}
              />

              <FinancialRow
                title={t('general.assets')}
                keyItem={'SED'}
                currYear={currYear}
                previousYear={previousYear}
              />
            </WrapSection>
          </div>
        ) : null}

        {slideIn && (rootNode?.country_code === 'SE' || rootNode?.country_code === 'se') ? (
          <div className="flex justify-around px-[15%] pt-6 space-x-4">
            {/*<div className="flex items-center justify-center">
              <button onClick={goToProff}>
                <div className="uppercase leading-3 justify-center items-center rounded-md transition-colors duration-300 shadow-md p-3 flex space-x-1">
                  <div>Länk till </div>
                  <Image src={`/images/proff.svg`} width={60} height={20} alt={`proff logo`} />
                </div>
              </button>
            </div>*/}

            <div className="flex items-center justify-center">
              <button onClick={goToAllaBolag}>
                <div className="uppercase leading-3 justify-center items-center rounded-md transition-colors duration-300 shadow-md p-3 flex space-x-1 dark:hover:bg-dark-lessfaded">
                  <div>{t('general.link-to')} </div>

                  <Image
                    src={`/images/allabolag-green.svg`}
                    width={100}
                    height={30}
                    alt={`allabolag.se logotyp`}
                  />
                </div>
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
