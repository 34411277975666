import { LinearProgress } from '@material-ui/core';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import SignedGoogleMapsImage from 'src/components/map/signed-google-maps-image';

export default function PropPermitsTemplate({ object, close }: { object: any; close: any }) {
  const [property] = useState<any | null>(null);
  const [loading] = useState<boolean>(false);
  const { t } = useTranslation('common');

  const router = useRouter();

  const latLong = `${object?.lat},${object?.lng}`;

  return (
    <div className="flex z-30 h-full flex-col overflow-y-scroll">
      {/* Main */}
      <div className="pb-1 sm:pb-6">
        <div className="relative h-36 sm:h-72 z-10 overflow-hidden">
          <SignedGoogleMapsImage
            height={430}
            width={650}
            className="object-contain"
            src={`https://maps.googleapis.com/maps/api/streetview?size=1200x415&location=${encodeURIComponent(
              latLong
            )}&fov=300&pitch=5&key=AIzaSyBYCMGQ9cdHlRBN414KW_VE0WgTzR6Sqow&source=outdoor`}
          />
        </div>

        {loading ? <LinearProgress /> : <div className="h-[4px] w-8" />}

        <div className="sm:flex sm:items-end sm:flex-1 mt-2 sm:mt-2 relative">
          <div>
            <motion.div
              layoutId={'objectTitle'}
              className="flex flex-wrap items-center space-x-0 lg:space-x-4 w-full">
              <div className="w-full items-start flex flex-col my-3 px-3 sm:px-6 justify-center text-left border-gray-100">
                <button
                  onClick={() => {
                    close();
                    router.push(`/crm/fastighet/${object?.uuid}/overview`);
                  }}
                  className="text-2xl text-left font-medium text-gray-900 dark:text-white sm:text-3xl flex items-center hover:text-bobo-link transition-colors duration-300">
                  {t('property.historic-point.safe-rooms')}: {object?.additional}
                </button>
              </div>

              <div className="w-4/5">
                <div className="text-sm text-gray-700 font-medium dark:text-gray-300 px-2">
                  <div className="flex w-full">
                    <div className="w-[135px]">{t('general.address')}:</div>
                    <div className="w-4/5 ml-4">
                      {object?.serviceL_2} {object?.serviceLoc}
                    </div>
                  </div>
                  <div className="flex w-full">
                    <div className="w-[135px]">{t('property.shelters.intended-for')}:</div>
                    <div className="w-4/5 ml-4 flex">{object?.typeOfOccu}</div>
                  </div>
                  <div className="flex w-full">
                    <div className="w-[135px]">{t('property.shelters.capacity')}:</div>
                    <div className="w-4/5 ml-4 flex">{object?.numberOfOc}</div>
                  </div>
                  <div className="flex w-full">
                    <div className="w-[135px]">{t('property.shelters.service-level')}:</div>
                    <div className="w-4/5 ml-4 flex">{object?.serviceLev}</div>
                  </div>
                  <div className="flex w-full">
                    <div className="w-[135px]">{t('property.shelters.resources')}:</div>
                    <div className="w-4/5 ml-4 flex">{object?.resourceFi}</div>
                  </div>
                  <div className="flex w-full">
                    <div className="w-[135px]">{t('property.shelters.responsible')}:</div>
                    <div className="w-4/5 ml-4 flex">{object?.pointOfCon}</div>
                  </div>
                  <div className="flex w-full">
                    <div className="w-[135px]">{t('property.shelters.object-id')}:</div>
                    <div className="w-4/5 ml-4 flex">{object?.OBJECTID}</div>
                  </div>
                </div>

                <div className="bg-bobo-verylightgreen py-1 mt-6 px-4 text-mini text-gray-900 rounded-full inline-flex z-30 mx-2">
                  {t('general.sources', { sources: 'MSB' })}
                </div>
              </div>
            </motion.div>
          </div>

          <div className="mt-8 w-4/5 m-auto flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3 items-center">
            <button
              onClick={() => {
                window.location.href = `/crm/fastighet/${property?.uuid}/overview`;
                close();
              }}
              type="button"
              className="items-center justify-center rounded-full border border-bobo-prop dark:border-dark-prop px-4 py-1 text-sm text-bobo-prop dark:text-dark-prop font-regular hover:bg-bobo-verylightgreen dark:hover:bg-dark-prophover dark:hover:border-dark-prophover dark:hover:text-white sm:flex-1 transition-colors duration-300">
              {t('property.explore-property')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
