import { Dialog } from '@headlessui/react';
import { TrashIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { ButtonType } from 'shared/dist/types';
import {
  CashflowCredit,
  CashflowInvestment,
  CashflowLease,
  CashflowValuationAsset,
  CashflowValuationModel,
  InvestmentParentType
} from 'shared/dist/types/cashflow/cashflow-model';
import { Error, Scooter } from 'src/animations';

import Button from '@/src/widgets/buttons';

import {
  DeletedAsset,
  DeletedCredit,
  DeletedInvestment,
  DeletedLease,
  DeletedModel,
  DeleteType
} from '../../cashflow-valuation/cashflow-dialog-switcher';
import { crmDialogTheme } from '../crm/dialog-theme';

const stepNames = {
  confirmDelete: 1,
  initialEdit: 2,
  loading: 3,
  error: 4
};

function CashflowDeleteModelStep1({
  close,
  setStep,
  setReload,
  model,
  setSelectedModel,
  deletedObject,
  goBack,
  onSuccess
}: {
  close: any;
  setStep: (step: number) => void;
  setReload: (reload: boolean) => void;
  model: CashflowValuationModel;
  setSelectedModel: (arg0?: any | any[]) => void;
  deletedObject?: DeletedModel | DeletedAsset | DeletedLease | DeletedCredit | DeletedInvestment;
  goBack?: () => void;
  onSuccess?: () => void;
}) {
  const { t } = useTranslation('common');

  const deleteModel = async () => {
    const res = await fetch('/api/prop/cashflow/delete', {
      method: 'POST',
      body: JSON.stringify({
        uuid: (deletedObject as CashflowValuationModel)?.uuid,
        object_type: (deletedObject as CashflowValuationModel)?.valuatedObject.type
      })
    });

    if (res.ok) {
      setSelectedModel(undefined);
    }
  };

  const deleteAsset = () => {
    const deletedAsset = deletedObject as DeletedAsset;
    const otherAssets = model?.assets.filter((p) => p.uuid !== deletedAsset?.uuid);

    const updatedModel = {
      ...model,
      assets: otherAssets
    };

    setSelectedModel(updatedModel);
    setReload(true);
  };

  const deleteLease = async () => {
    const deletedLease: CashflowLease = deletedObject as CashflowLease;
    const selectedAsset = model?.assets.find((asset) => asset.uuid === deletedLease?.assetUuid);

    if (!selectedAsset) {
      return;
    }

    const otherLeases = selectedAsset.adjustableAssumptions.rentRoll.filter(
      (lease) => lease.uuid !== deletedLease.uuid
    );

    const updatedAssumptions = { ...selectedAsset.adjustableAssumptions, rentRoll: otherLeases };

    const updatedAssets: CashflowValuationAsset[] = model?.assets.map((prop) => {
      if (prop.uuid !== deletedLease.assetUuid) {
        return prop;
      } else {
        return { ...prop, adjustableAssumptions: updatedAssumptions };
      }
    });

    const updatedModel: CashflowValuationModel = { ...model, assets: updatedAssets };
    setSelectedModel(updatedModel);
    setReload(true);
  };

  const deleteCredit = () => {
    if (!model) {
      return;
    }
    const otherCredits = model?.credits.filter(
      (c) => c.uuid !== (deletedObject as CashflowCredit).uuid
    );

    const updatedModel: CashflowValuationModel = { ...model, credits: otherCredits };

    setSelectedModel(updatedModel);
  };

  const getUpdatedModelForAssetInvestment = (deletedInvestment: CashflowInvestment) => {
    const selectedAsset = model?.assets.find(
      (asset) => asset.uuid === deletedInvestment?.parent.uuid
    );

    if (!selectedAsset) {
      return;
    }

    const otherInvestments = selectedAsset.adjustableAssumptions.investments.filter(
      (i) => i.uuid !== deletedInvestment.uuid
    );

    const updatedAssets: CashflowValuationAsset[] = model?.assets.map((prop) => {
      if (prop.uuid !== deletedInvestment.parent.uuid) {
        return prop;
      } else {
        return {
          ...prop,
          adjustableAssumptions: {
            ...selectedAsset.adjustableAssumptions,
            investments: otherInvestments
          }
        };
      }
    });
    const updatedModel: CashflowValuationModel = { ...model, assets: updatedAssets };
    return updatedModel;
  };

  const getUpdatedModelForModelInvestment = (deletedInvestment: CashflowInvestment) => {
    const updatedInvestments: CashflowInvestment[] = model.investments.filter(
      (i) => i.uuid !== deletedInvestment.uuid
    );

    return { ...model, investments: updatedInvestments };
  };

  const deleteInvestment = () => {
    if (!model) {
      return;
    }

    const deletedInvestment: CashflowInvestment = deletedObject as CashflowInvestment;

    const updatedModel =
      deletedInvestment.parent.type === InvestmentParentType.ASSET
        ? getUpdatedModelForAssetInvestment(deletedInvestment)
        : getUpdatedModelForModelInvestment(deletedInvestment);

    setSelectedModel(updatedModel);
  };

  const handleDelete = async () => {
    setReload(false);
    setStep(stepNames.loading);

    if (!deletedObject) {
      return setStep(stepNames.error);
    }

    const deleteType: DeleteType = deletedObject.deleteType;
    const name: string = deletedObject.name;

    try {
      switch (deleteType) {
        case DeleteType.MODEL:
          await deleteModel();
          break;
        case DeleteType.ASSET:
          await deleteAsset();
          break;
        case DeleteType.LEASE:
          await deleteLease();
          break;
        case DeleteType.CREDIT:
          deleteCredit();
          break;
        case DeleteType.INVESTMENT:
          deleteInvestment();
          break;
      }

      if (onSuccess) {
        onSuccess();
      }
      setReload(true);
      toast.success(
        t('property.cashflow-valuation.dialogs.delete-success', {
          context: deleteType,
          name: name
        })
      );

      close();
    } catch {
      setStep(stepNames.error);
    }
  };

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <TrashIcon className="w-8 h-8 text-red-500" />
      </div>

      <div className={crmDialogTheme.textContainer}>
        {t('property.cashflow-valuation.dialogs.delete-description', {
          context: deletedObject?.deleteType,
          name: deletedObject?.name
        })}
      </div>

      <div className={crmDialogTheme.buttonContainer}>
        <Button type={ButtonType.CANCEL} onClick={goBack ?? close}>
          {t('general.cancel')}
        </Button>

        <Button type={ButtonType.DELETE} onClick={handleDelete}>
          {t('general.delete')}
        </Button>
      </div>
    </div>
  );
}

function CashflowDeleteModelStepLoading() {
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Scooter />
      </div>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          {t('general.saving-info')}
        </Dialog.Title>
      </div>
    </div>
  );
}

function CashflowDeleteModelStepError({ close }: { close: any }) {
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.icon}>
        <Error />
      </div>

      <div className={crmDialogTheme.textContainer}>
        <div className={crmDialogTheme.errorTitle}>{t('errors.something-went-wrong')}</div>

        <div className={crmDialogTheme.subtext}>{t('errors.try-again')}</div>
      </div>

      <div className="w-full flex justify-end items-end mt-6">
        <Button onClick={close}>{t('general.close')}</Button>
      </div>
    </div>
  );
}

export default function CashflowDeleteModel({
  close,
  setReload,
  model,
  setSelectedModel,
  deletedObject,
  goBack,
  onSuccess
}: {
  close: () => void;
  setReload: (reload: boolean) => void;
  model: CashflowValuationModel;
  setSelectedModel: (arg0?: any | any[]) => void;
  deletedObject?: DeletedModel | DeletedAsset | DeletedLease | DeletedCredit | DeletedInvestment;
  goBack?: () => void;
  onSuccess?: () => void;
}) {
  const [step, setStep] = useState(stepNames.confirmDelete);
  let mod: JSX.Element = <div />;

  switch (step) {
    case stepNames.confirmDelete:
      mod = (
        <CashflowDeleteModelStep1
          close={close}
          setStep={setStep}
          setReload={setReload}
          model={model}
          setSelectedModel={setSelectedModel}
          deletedObject={deletedObject}
          goBack={goBack}
          onSuccess={onSuccess}
        />
      );
      break;

    case stepNames.loading:
      mod = <CashflowDeleteModelStepLoading />;
      break;

    case stepNames.error:
      mod = <CashflowDeleteModelStepError close={goBack || close} />;
      break;
  }

  return <>{mod}</>;
}
