/** context provider for access features */
import { useRouter } from 'next/router';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { PropertyVM } from 'shared/dist/types/property2';

type AccessContextType = {
  accessFeatures: PropertyVM['access_features'];
  unlockAccessFeatures: (
    accessType: 'edd_report' | 'bank_report' | 'fds',
    uuid: string,
    nextStep?: () => void
  ) => Promise<void>;
};

const AccessContext = createContext<AccessContextType>({
  accessFeatures: {},
  unlockAccessFeatures: async () => console.log('ctx is not defined')
});

export const AccessProvider = ({
  children,
  property
}: {
  children: React.ReactNode;
  property: PropertyVM | null;
}) => {
  const [accessFeatures, setAccessFeatures] = useState<AccessContextType['accessFeatures']>({});
  const router = useRouter();

  useEffect(() => {
    if (property?.access_features) {
      setAccessFeatures(property.access_features);
    }
  }, [property]);

  const unlockAccessFeatures: AccessContextType['unlockAccessFeatures'] = async (
    accessType,
    uuid,
    nextStep?
  ) => {
    const url = `/api/crm/upgrade`;

    try {
      const response = await fetch(url, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          uuid,
          accessType
        })
      });

      if (!response.ok) {
        throw new Error(`Failed to unlock access features: ${response.statusText}`);
      }

      await response.json();

      setAccessFeatures((prev) => ({
        ...prev,
        [accessType]: true
      }));

      if (nextStep) nextStep();
      // if (accessType === 'fds') {
      //   router.reload();
      // }
    } catch (error) {
      console.error('Error unlocking access features:', error);
      return Promise.reject(error);
    }
  };

  return (
    <AccessContext.Provider value={{ accessFeatures, unlockAccessFeatures }}>
      {children}
    </AccessContext.Provider>
  );
};

export const useAccess = () => useContext(AccessContext);
