import area from '@turf/area';
import bbox from '@turf/bbox';
import { featureCollection } from '@turf/helpers';
import { motion } from 'framer-motion';
import moment from 'moment';
import { Trans, useTranslation } from 'next-i18next';
import NumberFormat from 'react-number-format';
import { DETAIL_PLANS } from 'shared/dist/constants';
import ThumbMap from 'src/components/map/generic';

import WrapSection from '../../section-wrapper';

export default function DPTemplate({ object }: { object: any; close: any }) {
  let newBbox: any[] = [18.1, 57.1, 18.2, 57.2];
  const ts = useTranslation('shared').t;
  const { t } = useTranslation('common');

  try {
    const fc: any = featureCollection(object?.details);
    newBbox = bbox(fc);
  } catch (e) {
    console.error(e);
  }
  return (
    <div className="flex z-30 h-full flex-col">
      {/* Main */}
      <div>
        <div className="pb-1 sm:pb-6">
          <div>
            <div className="relative h-44 sm:h-64 border dark:border-dark-morefaded overflow-hidden">
              <ThumbMap
                object={object}
                // showDetailPlan={true}
                // auxDetailPlansRadius={500}
                // showSpecificPlan={object?.details[0]}
                actualBbox={[
                  [newBbox[0] - 0.001, newBbox[1] - 0.001],
                  [newBbox[2] + 0.001, newBbox[3] + 0.001]
                ]}
                zoomPadding={0.05}
                layers={[`properties`, `plans`]}
                specificHeight={'h-[250px]'}
                selected={object?.details?.map((a) => {
                  return {
                    source: 'plans',
                    sourceLayer: 'plan_yta',
                    id: a?.properties?.objekt_id
                  };
                })}
                // showNeighbours={false}
                // showTools={false}
                // obj={object}
                // bboxOverride={[
                //   newBbox[0] - 0.001,
                //   newBbox[1] - 0.001,
                //   newBbox[2] + 0.001,
                //   newBbox[3] + 0.001
                // ]}
                // feature={GotoFeature.DETAIL_PLAN}
                // defaultLayers={['detaljplaner', 'properties']}
              />
            </div>

            <div className="overflow-y-scroll h-[63vh] mt-10 overflow-hidden pb-16">
              <div className="px-6 py-4 border-b dark:border-dark-morefaded text-sm text-gray-800">
                <h3 className="text-base font-bold text-gray-900 dark:text-white sm:text-lg uppercase">
                  {t('general.summary')}
                </h3>

                <div className="text-xs text-gray-600 dark:text-white">
                  <Trans
                    i18nKey={'property.plans.detail-plan-count'}
                    count={object?.details?.length}
                    t={t}>
                    Det finns <strong>{{ amount: object?.details?.length }} gällande plan</strong>.
                  </Trans>
                </div>

                <div className="text-xs text-gray-600 dark:text-white italic mt-1">
                  {t('property.plans.detail-plan-lc')}
                </div>

                <div className="bg-bobo-verylightgreen py-1 px-4 text-mini text-gray-900 rounded-full inline-flex z-30 mt-2">
                  {t('general.sources', { sources: 'Lantmäteriet' })}
                </div>
              </div>

              {object?.details?.map((item, idx) => {
                const totArea = Math.round(area(item));

                return (
                  <div className="px-4 sm:flex sm:items-end sm:px-6 relative mt-5" key={idx}>
                    <motion.div className="flex flex-wrap items-center space-x-0 lg:space-x-4 w-full">
                      <WrapSection
                        title={item?.properties?.plnamn ?? `Extern: ${item?.properties?.externid}`}>
                        <div>
                          <div className="text-xs font-bold">
                            {t('property.plans.in-force-since', {
                              sinceWhen: moment(item?.properties?.bdat).format('YYYY-MM-DD')
                            })}
                            <div className="text-xs italic font-medium text-gray-400">
                              {moment(item?.properties?.bdat).fromNow()}
                            </div>
                          </div>
                        </div>

                        <div className="text-xs font-demi text-gray-700 dark:text-gray-300 mt-3">
                          <div className="flex w-full">
                            <div className="w-[135px]">{t('property.plans.plan-type')}</div>

                            <div className="w-4/5 ml-4">
                              {ts(DETAIL_PLANS[item?.properties?.plantyp]) ??
                                item?.properties?.plantyp}
                            </div>
                          </div>

                          <div className="flex w-full">
                            <div className="w-[135px]">{t('general.area')}</div>

                            <div className="w-4/5 ml-4 flex">
                              <NumberFormat
                                value={totArea}
                                displayType={'text'}
                                thousandSeparator={` `}
                                prefix={``}
                                suffix={t('general.square-meters')}
                              />
                            </div>
                          </div>

                          <div className="flex w-full">
                            <div className="w-[135px]">{t('general.uuid')}</div>

                            <div className="w-4/5 ml-4 flex">{item?.properties?.objekt_id}</div>
                          </div>

                          <div className="flex w-full">
                            <div className="w-[135px]">{t('property.plans.reference-name')}</div>

                            <div className="w-4/5 ml-4 flex">{item?.properties?.externid}</div>
                          </div>
                        </div>
                      </WrapSection>
                    </motion.div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
