/* External Libraries */
import 'mapbox-gl/dist/mapbox-gl.css';

import { XMarkIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'next-i18next';
import { useTheme } from 'next-themes';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Map, MapProvider, Popup } from 'react-map-gl';
/* Components & Widgets */
import { usePropertyTypesMode } from 'src/context/propertytype-context';
import { useSlideIn } from 'src/context/slide-in-context';

import { loadLayers } from '@/src/lib/map';
import highlightSelected from '@/src/lib/map/highlight-selected';
import loadArcs from '@/src/lib/map/layers/items/load-arcs';
import loadIcons from '@/src/lib/map/loadIcons';
import loadSources from '@/src/lib/map/sources';

import BuildingPermits from './items/building-permit';
import MapTools from './items/tools';

export default function RiskMap({
  pitch = 20,
  fullHeight,
  specificHeight = 'h-[350px]',
  actualBbox,
  showSearchBox = false,
  showBuildingPermits = false,
  buildingPermits,
  layers,
  tools,
  object,
  zoomPadding = 0.003,
  selected,
  arcs
}: {
  pitch?: number;
  fullHeight?: string | boolean;
  specificHeight?: string;
  showBuildingPermits?: boolean;
  buildingPermits?: any[];
  actualBbox?: any;
  showSearchBox?: boolean;
  markerProps?: any[];
  layers?: string[];
  tools?: string[];
  object: any;
  zoomPadding?: number;
  selected?: any[];
  arcs?: any;
}) {
  const mapRef: any = useRef();
  const selectedObject: any = useRef();
  const [popup, setPopup] = useState<any | null>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<any>(true);
  const [zoom, setZoom] = useState<number>(0);

  const { resolvedTheme } = useTheme();

  const { propertyTypes } = usePropertyTypesMode();
  const { setSlideIn, slideIn } = useSlideIn();

  const { t } = useTranslation('common');
  const ts = useTranslation('shared').t;

  const bounds: any = [
    [object?.lng - zoomPadding, object?.lat - zoomPadding],
    [object?.lng + zoomPadding, object?.lat + zoomPadding]
  ];

  const style = `mapbox://styles/bobolaget/clbad5k62007e14ntq3q6rrly`; //LIGHT
  // const style = `mapbox://styles/bobolaget/clcltopwm004v14pvvuke9ih3`; //SAT
  // const style = `mapbox://styles/bobolaget/clbtmmb9e001u15qosbay1cfv`; //DARK

  const [viewState, setViewState] = useState({
    maxZoom: 22,
    minZoom: 1,
    pitch: pitch,
    bearing: 0,
    bounds: actualBbox ? actualBbox : bounds
  });

  useEffect(() => {
    selectedObject.current = object?.uuid;
  }, [object?.uuid]);

  useEffect(() => {
    mapRef?.current?.getMap()?.getLayer('prop-area') &&
      mapRef?.current?.getMap()?.removeLayer('prop-area');
    mapRef?.current?.getMap()?.getLayer('prop-line') &&
      mapRef?.current?.getMap()?.removeLayer('prop-line');
    mapRef?.current?.getMap()?.getLayer('buildings') &&
      mapRef?.current?.getMap()?.removeLayer('buildings');

    if (loaded && layers) {
      loadLayers(
        mapRef?.current,
        layers,
        object,
        resolvedTheme,
        ts,
        t,
        propertyTypes,
        setPopup,
        selectedObject
      );
    }
  }, [propertyTypes]);

  useEffect(() => {
    if (loaded && layers) {
      loadSources(mapRef?.current, layers);
      loadLayers(
        mapRef?.current,
        layers,
        object,
        resolvedTheme,
        ts,
        t,
        propertyTypes,
        setPopup,
        selectedObject,
        setSlideIn
      );
      if (selected) {
        highlightSelected(mapRef?.current, selected);
      }
    }
  }, [layers, selected, loaded]);

  const onMapLoad = useCallback(async () => {
    if (!mapRef?.current) {
      throw 'map failed';
    } else {
      if (layers) {
        await loadIcons(mapRef?.current);
        loadSources(mapRef?.current, layers);
        loadLayers(
          mapRef?.current,
          layers,
          object,
          resolvedTheme,
          propertyTypes,
          setPopup,
          selectedObject,
          setSlideIn
        );
        if (arcs) {
          loadArcs(mapRef?.current, arcs);
        }
      }
      setLoaded(true);
    }
    () => {
      if (mapRef?.current) {
        mapRef?.current?.clearStorage();
      }
    };
  }, []);

  return (
    <MapProvider>
      {tools?.includes('maptool') && (
        <div className="flex text-xs z-50 bg-white dark:bg-dark-lessfaded shadow items-center font-normal w-full h-[62px] border">
          <MapTools
            setIsSearching={setIsSearching}
            showSearchBox={showSearchBox}
            map={mapRef?.current}
            zoom={zoom}
          />
        </div>
      )}

      {showBuildingPermits && buildingPermits && buildingPermits?.length > 0 && loaded && (
        <BuildingPermits
          localCouncilName={object?.council}
          loaded={loaded}
          features={buildingPermits as any[]}
          primaryObject={object}
        />
      )}

      <div
        className={`w-full ${
          fullHeight ? 'h-[calc(100vh-60px)]' : `${specificHeight}`
        } flex relative`}>
        <Map
          initialViewState={viewState}
          id="genericMap"
          ref={mapRef}
          onLoad={onMapLoad}
          style={{ width: '100%', height: '100%' }}
          mapboxAccessToken={`pk.eyJ1IjoiYm9ib2xhZ2V0IiwiYSI6ImNrZmNmZmtlajE4NG0zNm81NjB5ZTgwOGwifQ.AqhIm23pQPp2jjs_Q2Ca6Q`}
          mapStyle={style}>
          <Popup
            latitude={popup?.lat ?? 0}
            longitude={popup?.lng ?? 0}
            closeButton={false}
            closeOnClick={false}
            anchor="top">
            <button
              onClick={() => setPopup(null)}
              className="mb-2 text-center flex justify-center w-full">
              <div className="border rounded-full p-1">
                <XMarkIcon className="h-4 w-4" />
              </div>
            </button>

            <div className="font-demi text-sm mt-1">{popup?.riskLevel ?? popup?.title}</div>

            <div className="font-demi italic text-gray-500">{popup?.status}</div>

            <div className="font-demi mt-2">{popup?.riskLevel ? popup?.title : null}</div>

            <div className="italic">{popup?.subTitle}</div>
          </Popup>
        </Map>
      </div>
    </MapProvider>
  );
}
