import cls from 'classnames';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { getCategoryName } from 'shared/dist/constants';
import { ValueCategoryTypes } from 'shared/dist/types';
import { PropertyValuationDefaultValues } from 'shared/dist/types/cashflow/cashflow-model';

import { LeaseInput } from '@/src/components/dialogs/cashflow/cashflow-add-lease';
import { getDefaultValues } from '@/src/components/valuation-tool/items/functions';
import InputFieldDropdownMenu from '@/src/layout/components/input-field-dropdown-menu';

import { handleMaxInputLimit } from '../../constants/calculator-functions';
import { cashflowTheme } from '../../constants/cashflow-theme';
import { MAX_LIMIT_RENT_PER_SQM_INPUT } from '../../constants/general';

export default function SelectRentForLeaseDropdown({
  errorState,
  selectedLease,
  setSelectedLease,
  property
}: {
  errorState: any;
  selectedLease: LeaseInput;
  setSelectedLease: (lease: LeaseInput) => void;
  property: any;
}) {
  const [rentOptions, setRentOptions] = useState<any[]>([]);
  const { t } = useTranslation('common');

  useEffect(() => {
    if (property) {
      getRentOptions(property);
    }
  }, [property]);

  const getRentOptions = (property) => {
    const defaultValues: PropertyValuationDefaultValues = getDefaultValues(property, t);
    const defaultRentValues = defaultValues[ValueCategoryTypes.RENT];
    const flRentOptions = defaultRentValues.fl
      .filter((valueType) => valueType.value > 0)
      .map((valueType) => {
        return { ...valueType, source: 'Fastighetsloggen' };
      });
    const newsecRentOptions = defaultRentValues.newsec
      .filter((valueType) => valueType.value > 0)
      .map((valueType) => {
        return { ...valueType, source: 'Newsec' };
      });
    const forumRentOptions = defaultRentValues.forum
      .filter((valueType) => valueType.value > 0)
      .map((valueType) => {
        return { ...valueType, source: 'Forum' };
      });

    setRentOptions([...flRentOptions, ...newsecRentOptions, ...forumRentOptions]);
  };

  const renderRentOptions = (closeMenu) =>
    rentOptions.map((i, idx) => {
      return (
        <div
          key={idx}
          className="py-1 px-2 pr-6 transition-colors duration-200 hover:bg-bobo-proplight dark:hover:bg-dark-prophover text-bobo-prop dark:text-white hover:text-bobo-prophover w-full">
          <button
            onClick={() => {
              if (selectedLease) {
                setSelectedLease({ ...selectedLease, rent: i.value });
                closeMenu();
              }
            }}
            className="disabled:text-gray-300 w-full h-full flex justify-between items-center">
            <div className="flex flex-col items-start">
              <div>{i.source}</div>
              <div className="text-gray-400">{t(getCategoryName(i.propType))}</div>
            </div>

            <div>
              {i.value}
              {t('general.kr-per-square-m-suffix')}
            </div>
          </button>
        </div>
      );
    });

  const renderInputField = (setShowItems) => (
    <input
      type="number"
      id="rent-input"
      value={selectedLease?.rent || ''}
      onFocus={() => {
        setShowItems(true);
      }}
      placeholder={errorState.rent ? t(errorState.rent, { field: t('general.rent') }) : '0'}
      onChange={({ target }) => {
        setSelectedLease({ ...selectedLease, rent: parseFloat(target.value) });
      }}
      onInput={(e) => handleMaxInputLimit(e, MAX_LIMIT_RENT_PER_SQM_INPUT)}
      className={cls(cashflowTheme.inputField, {
        'placeholder:text-bobo-bricksred': errorState.rent
      })}
    />
  );

  return (
    <InputFieldDropdownMenu
      renderInputField={renderInputField}
      renderOptions={renderRentOptions}
      suffix={t('general.kr-per-square-m-suffix')}
    />
  );
}
