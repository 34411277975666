import ApartmentTable from 'src/components/property/items/apartment-table';

import { CompanyTable } from '@/src/components/bolag/items/company-table';
import AreaTaxTable from '@/src/components/property/items/area-tax-table';
import MarketDataTable from '@/src/components/property/items/market-data';
import RentTaxTableHousing from '@/src/components/property/rent/items/tables/rent-tax-table-housing';
import RentTaxTableSpace from '@/src/components/property/rent/items/tables/rent-tax-table-space';
import RentTaxTableWarehouse from '@/src/components/property/rent/items/tables/rent-tax-table-warehouse';

export function CRMPropInfo({ object, close }: { object: any; close: any }) {
  const loadingEnergy = false;
  const taxCodes: number[] = object?.propTax?.map((a) => a?.tax_code);

  return (
    <>
      <div className="gap-4 grid grid-cols-1">
        <CompanyTable property={object} slideIn={true} close={close} />
        <div className="flex flex-col lg:flex-row justify-start items-start lg:space-x-3 w-full space-y-3 lg:space-y-0">
          <div className="flex-1 text-xs w-full lg:w-[55%] space-y-3">
            <ApartmentTable
              object={{ ...object, energyStatement: object?.energyStatement }}
              compact={true}
              loadingEnergy={loadingEnergy}
            />
          </div>

          <div className="space-y-3 w-full lg:w-[45%]">
            <AreaTaxTable property={object} />
          </div>
        </div>

        <MarketDataTable object={object} />

        {taxCodes?.find((a) => [320, 321]?.includes(a)) && (
          <RentTaxTableHousing
            object={{ ...object }}
            rent={{ ...object }}
            pt={object?.propTax?.find((a) => [320, 321]?.includes(a?.tax_code))}
            slideIn={true}
          />
        )}

        {taxCodes?.find((a) => [423, 426, 432, 433]?.includes(a)) && (
          <RentTaxTableWarehouse
            object={{ ...object }}
            rent={{ ...object }}
            pt={object?.propTax?.find((a) => [423, 426, 432, 433]?.includes(a?.tax_code))}
            slideIn={true}
          />
        )}

        {taxCodes?.find((a) => [325, 326]?.includes(a)) && (
          <RentTaxTableSpace
            object={{ ...object }}
            rent={{ ...object }}
            pt={object?.propTax?.find((a) => [325, 326]?.includes(a?.tax_code))}
            slideIn={true}
          />
        )}
      </div>
    </>
  );
}
