/* External Libraries */
import axios from 'axios';
import { TResponse as HasAccessResponse } from 'pages/api/auth/userHasAccessToCustomer';
import toast from 'react-hot-toast';
/* Components & Widgets */
import { Actor, UpdateActiveActorVM, UpdateProfileInputVM, UserProfileVM } from 'shared/dist/types';
import { PlanningData } from 'shared/dist/types/urban-development';

import { DialogValueType } from '../context/dialog-context';
import { Tfunc } from '../types';

export async function sendPasswordEmail(user, t: Tfunc) {
  const toastId = toast.loading(t('toasts.password-email-loading'));

  const url = `https://bobo-prod.eu.auth0.com/dbconnections/change_password`;

  const options = {
    method: 'POST',
    url,
    headers: { 'content-type': 'application/json' },
    data: {
      client_id: 'ZmCqXQH1bExdZZpOeqkK75gJ6jDif562',
      email: user?.email,
      connection: 'Username-Password-Authentication'
    }
  };

  axios
    .request(options)
    .then(function () {
      toast.success(t('toasts.password-email-success'), {
        id: toastId
      });
      return true;
    })
    .catch(function (error) {
      toast.dismiss();

      return {
        error: true,
        msg: `Failed to fetch url: ${url} [${error.message}]`
      };
    });
}

export async function downloadFile(path, file, t) {
  try {
    toast.loading(t('toasts.downloading-plan', { file }));
    const response = await fetch(`/api/urban-development/download-plan?path=${path}&file=${file}`, {
      method: 'POST'
    });

    if (!response.ok) {
      throw new Error('Failed to download file');
    }

    // Convert response to a blob
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);

    // Create a temporary link element and trigger download
    const link = document.createElement('a');
    link.href = url;
    link.download = file;
    document.body.appendChild(link);
    link.click();

    toast.dismiss();
    // Clean up
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
}

export async function getZoningPlans(uuid, t): Promise<any> {
  const toastId = toast.loading(t('toasts.fetching-zoning-plans'));

  return fetch(`/api/urban-development/get-zoning-by-property?uuid=${uuid}`)
    .then((res) => {
      return res.json();
    })
    .then((json: PlanningData[]) => {
      toast.dismiss();
      return json;
    })
    .catch((err) => {
      console.error(`Failed to get zoning plans`, err);
      return [];
    });
}

export async function getCouncilZoningPlans(councilCode, councilName, t): Promise<PlanningData[]> {
  toast.loading(t('toasts.fetching-latest-zoning-plans', { name: councilName, code: councilCode }));

  return fetch(`/api/urban-development/get-zoning-by-council?council_uuid=${councilCode}`)
    .then((res) => {
      return res.json();
    })
    .then((json: PlanningData[]) => {
      toast.dismiss();
      return json;
    })
    .catch((err) => {
      toast.dismiss();
      console.error(`Failed to get council zoning plans`, err);
      return [];
    });
}

export async function getZoningPlansStatus(t): Promise<{ list: any[]; counter: number }> {
  toast.loading(t('toasts.fetching-latest-zoning-plans'));

  return fetch(`/api/urban-development/get-zoning-plans-status`)
    .then((res) => {
      return res.json();
    })
    .then((json: any) => {
      toast.dismiss();
      return json;
    })
    .catch((err) => {
      toast.dismiss();
      console.error(`Failed to get plan loading status`, err);
      return [];
    });
}

export async function saveActiveActor(actor: Actor, t: Tfunc, prompt = true) {
  const body: UpdateActiveActorVM = { active_actor_uuid: actor.actor_uuid };

  try {
    await fetch(`/api/profile/update-active-actor`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    if (prompt) {
      toast.success(t('toasts.active-actor-success', { actor: actor?.company_name }));
    }
  } catch (err) {
    console.error(err);
  }
}

export async function requestDelete(user, t: Tfunc) {
  const toastId = toast.loading(t('toasts.delete-me-loading', { email: user?.email }));

  return fetch(`/api/profile/delete-me`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      toast.error(t('toasts.delete-me-error', { errorMsg: err.message }), {
        id: toastId
      });
      return {
        error: true,
        msg: err?.message || err + ''
      };
    });
}

const handleInactiveUserOrAccount = async (setDialog: (a: DialogValueType) => void) =>
  setDialog({
    dialogType: 'deactivated-account',
    closeable: false,
    closeableByOutsideClick: false,
    object: {},
    dialogStyle: { maxWidth: '800px' }
  });

/**
 * gets active actor from the DB or if its not set, prompts user to select one in a global dialog.
 * @returns {Promise<Actor>} active actor
 *
 * TODO This function is quite hard to understand for non-large brains. Please rewrite!
 */
export async function getOrPromptActiveActor(
  setDialog: (a: DialogValueType) => void,
  t: Tfunc
): Promise<Actor | null> {
  const userData = await getUserProfile(t, false);
  if (userData.user.deactivated || userData.user.account_deleted) {
    await handleInactiveUserOrAccount(setDialog);
    return null;
  }

  const find = userData.userActors.find(
    (userActor) => userActor.actor.actor_id === userData.user.active_actor
  );

  if (find) {
    return { ...find, updated_at: 0 };
  }

  return await new Promise((resolve, reject) => {
    // open global modal to select active actor
    setDialog({
      dialogType: 'select-actor',
      object: {
        // onDone called from within the modal
        onDone: (actor: Actor) => {
          const inactiveActor = actor?.account_deactivated || actor?.account_soft_deleted;
          if (inactiveActor) {
            handleInactiveUserOrAccount(setDialog);
            return reject(null);
          }

          if (actor) {
            saveActiveActor(actor, t, false);
            setTimeout(() => {
              setDialog(null);
              resolve(actor);
            }, 1);
          }
        }
      },
      dialogStyle: { maxWidth: '800px' },
      closeableByOutsideClick: false
    });
  });
}

export async function hasAccess(level: string): Promise<boolean> {
  try {
    const url = `/api/auth/userHasAccessToCustomer`;

    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => {
        return res.json();
      })
      .then((json: HasAccessResponse) => {
        toast.dismiss();
        if (json.error) {
          return false;
        } else {
          return json.result.access.isAdmin;
        }
      })
      .catch((err) => {
        return {
          error: true,
          msg: `Failed to fetch url: ${url} with level ${level} ${err.message}`
        };
      });
  } catch (err) {
    return false;
  }
}

export async function getUserSessions(t: Tfunc) {
  const toastId = toast.loading(t('toasts.user-sessions-loading'));

  const url = `/api/profile/getUserSessions`;

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      toast.dismiss();
      return json;
    })
    .catch((err) => {
      toast.error(t('toasts.user-sessions-error', { errorMsg: err.message }), {
        id: toastId
      });
      return {
        error: true,
        msg: `Failed to fetch url: ${url}`
      };
    });
}

export async function getUserHash(t: Tfunc) {
  const url = `/api/intercom/get-user-hash`;

  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      toast.error(t('toasts.load-intercom-error', { errorMsg: err.message }));
      return {
        error: true,
        msg: `Failed to fetch url: ${url}`
      };
    });
}

export async function getTwoFactorStatus(t: Tfunc) {
  const toastId = toast.loading(t('toasts.get-twofa-loading'));

  const url = `/api/profile/get2FA`;

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      toast.dismiss();
      return json;
    })
    .catch((err) => {
      toast.error(t('toasts.auth-error', { errorMsg: err.message }), {
        id: toastId
      });
      return {
        error: true,
        msg: `Failed to fetch url: ${url}`
      };
    });
}

export async function activate2FA(t: Tfunc) {
  const toastId = toast.loading(t('toasts.create-twofa'));

  const url = `/api/profile/create2FAEnrollment`;

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      toast.dismiss();
      return json;
    })
    .catch((err) => {
      toast.error(t('toasts.auth-error', { errorMsg: err.message }), {
        id: toastId
      });
      return {
        error: true,
        msg: `Failed to fetch url: ${url}`
      };
    });
}

export async function delete2FA(twoFAID: string, t: Tfunc) {
  const toastId = toast.loading(t('toasts.remove-twofa-loading'));

  const url = `/api/profile/delete2FAEnrollment`;

  return fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ id: twoFAID })
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      toast.dismiss();
      return json;
    })
    .catch((err) => {
      toast.error(t('toasts.remove-twofa-error', { errorMsg: err.message }), {
        id: toastId
      });
      return {
        error: true,
        msg: `Failed to fetch url: ${url}`
      };
    });
}

export async function getUserProfile(t: Tfunc, doToast = true): Promise<UserProfileVM> {
  const url = `/api/profile/getProfileData`;

  return fetch(url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      if (doToast) {
        toast.dismiss();
      }
      return json;
    })
    .catch((err) => {
      toast.error(t('toasts.get-profile-error', { errorMsg: err.message }));
      return {
        error: true,
        msg: `Failed to fetch url: ${url}`
      };
    });
}

export async function saveProfile(data: UpdateProfileInputVM, t: Tfunc, disableToast = false) {
  let toastId: string;

  !disableToast && (toastId = toast.loading(t('toasts.save-profile-loading')));

  const body = JSON.stringify(data);

  return fetch(`/api/profile/updateProfileData`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      !disableToast &&
        toast.success(t('toasts.save-profile-success'), {
          id: toastId
        });
      return json;
    })
    .catch((err) => {
      !disableToast &&
        toast.error(t('toasts.save-profile-error', { errorMsg: err.message }), {
          id: toastId
        });
      return null;
    });
}

export async function addPropToCRM(boardId: string, listId: string, propertyUUID: string) {
  const body = JSON.stringify({
    boardId,
    listId,
    propertyUUID
  });

  return await fetch(`/api/crm/addPropToCRM`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      return json;
    });
}

export async function addPropertiesArrayToCRM(
  boardId: string,
  listId: string,
  propertiesUUIDS: string[]
) {
  const body = JSON.stringify({
    boardId,
    listId,
    propertiesUUIDS
  });

  return await fetch(`/api/crm/addPropertiesArrayToCRM`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  }).then((res) => {
    return res.json();
  });
}

/**
 * @param withProperties - include properties?
 * @param withLists - include lists?
 * @param boardUUID - optional, to get a specific board
 */
export async function getItIsMadCouncilData(councilCode: string): Promise<any[]> {
  const body = JSON.stringify({
    council_code: councilCode
  });

  return await fetch(`/api/itismad/get-all-data-for-council`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body
  })
    .then((res) => {
      return res.json();
    })
    .then((json: any[]) => {
      return json;
    });
}

/**
 * @param withProperties - include properties?
 * @param withLists - include lists?
 * @param boardUUID - optional, to get a specific board
 */
export async function getItIsMadData(show: string): Promise<any[]> {
  const body = JSON.stringify({
    show
  });

  return await fetch(`/api/itismad/get-all-data`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body
  })
    .then((res) => {
      return res.json();
    })
    .then((json: any[]) => {
      return json;
    });
}

/**
 * @param withProperties - include properties?
 * @param withLists - include lists?
 * @param boardUUID - optional, to get a specific board
 */
export async function getCRMBoardsSummary(show): Promise<any[]> {
  const body = JSON.stringify({
    show
  });

  return await fetch(`/api/crm/get-board-summary`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  })
    .then((res) => {
      return res.json();
    })
    .then((json: any[]) => {
      return json.sort((a, b) => {
        // archived board at the end
        let a_order = a.for_archived ? 3 : -3;
        let b_order = b.for_archived ? 3 : -3;

        // private boards at the end
        a_order += a.private ? 1 : 0;
        b_order += b.private ? 1 : 0;

        return a_order - b_order;
      });
    });
}

/**
 * @param withProperties - include properties?
 * @param withLists - include lists?
 * @param boardUUID - optional, to get a specific board
 */
export async function getCRMBoards(
  withProperties = 1,
  withLists = 1,
  boardUUID?: string
): Promise<any[]> {
  const body = JSON.stringify({
    with_properties: withProperties,
    with_lists: withLists,
    board_uuid: boardUUID
  });

  return await fetch(`/api/crm/getBoards?board_uuid=${boardUUID}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  })
    .then((res) => {
      return res.json();
    })
    .then((json: any[]) => {
      if (boardUUID) {
        return json;
      } else {
        // always show archive boards
        return (
          json
            // .filter((board) => {
            //   if (!board.for_archived) {
            //     return true;
            //   } else {
            //     const hasAnyProperties = !!board.lists.find((list) => {
            //       return list?.properties?.length > 0 || list?.propertiesCount > 0;
            //     });
            //     return hasAnyProperties;
            //   }
            // })
            .sort((a, b) => {
              // archived board at the end
              let a_order = a.for_archived ? 3 : -3;
              let b_order = b.for_archived ? 3 : -3;

              // private boards at the end
              a_order += a.private ? 1 : 0;
              b_order += b.private ? 1 : 0;

              return a_order - b_order;
            })
        );
      }
    });
}

/**
 * @param listUUID - optional, to get a specific board
 * @param light - light version of data
 */
export async function getCRMListProperties(listUUID?: string, light = true): Promise<any[]> {
  const body = JSON.stringify({
    light: light ? 1 : 0,
    list_uuid: listUUID
  });

  return await fetch(`/api/crm/get-list-props`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  })
    .then((res) => {
      return res.json();
    })
    .then((json: any[]) => {
      return json;
    });
}

export async function setPropertyArchived(
  listId: string,
  objectId: string,
  boardId: string,
  archived = true
): Promise<any[]> {
  const body = JSON.stringify({
    listId,
    objectId,
    boardId,
    archived
  });

  return await fetch(`/api/crm/setPropertyArchived`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  })
    .then((res) => {
      return res.json();
    })
    .then(async (json) => {
      return json ?? [];
    });
}

export async function archiveList(listId: string, boardId: string): Promise<any[]> {
  const body = JSON.stringify({
    listId,
    boardId
  });

  return await fetch(`/api/crm/archiveList`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  })
    .then((res) => {
      return res.json();
    })
    .then(async (json) => {
      return json ?? [];
    });
}

export async function deletePropertyFromBoard(objectId: string, boardId: string): Promise<any[]> {
  const body = JSON.stringify({
    objectId,
    boardId
  });

  return await fetch(`/api/crm/deletePropertyFromBoard`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  })
    .then((res) => {
      return res.json();
    })
    .then(async (json) => {
      return json ?? [];
    });
}

export async function deleteBoard(boardId: string, boardPrivacy): Promise<any[]> {
  const body = JSON.stringify({
    boardId,
    boardPrivacy
  });

  return await fetch(`/api/crm/deleteBoard`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  })
    .then((res) => {
      return res.json();
    })
    .then(async (json) => {
      return json ?? [];
    });
}

export async function addUpdateComment(
  listId,
  propertyUUID,
  messageBody,
  activityId,
  activityType
): Promise<any[]> {
  const body = JSON.stringify({
    listId,
    propertyUUID,
    messageBody,
    activityId,
    activityType
  });

  return await fetch(`/api/crm/addUpdateComment`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  })
    .then((res) => {
      return res.json();
    })
    .then(async (json) => {
      return json ?? [];
    });
}

export async function movePropertyToList(listId, boardId, propertyUUID): Promise<null> {
  const body = JSON.stringify({
    boardId,
    listId,
    propertyUUID
  });

  return await fetch(`/api/crm/movePropertyToList`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  })
    .then((res) => {
      return res.json();
    })
    .then(async () => {
      return null;
    });
}

export async function movePropertyToAnotherBoard(
  currentBoardId: string,
  propertyUUID: string,
  toBoardId: string,
  toListId?: string
): Promise<any[]> {
  const body = JSON.stringify({
    currentBoardId,
    propertyUUID,
    toBoardId,
    toListId
  });

  return await fetch(`/api/crm/movePropertyToAnotherBoard`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  }).then((res) => {
    return res.json();
  });
}

export async function setListPositions(
  boardId: string,
  lists: { uuid: string; position: number }[]
): Promise<any[]> {
  const body = JSON.stringify({
    boardId,
    lists
  });

  return await fetch(`/api/crm/setListPositions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  }).then((res) => {
    return res.json();
  });
}

export async function reportMissingArticle(articleId: string) {
  const url = `/api/intercom/missing-article?articleId=${articleId}`;

  return fetch(url, {
    method: 'POST'
  })
    .then((res) => {
      return res.json();
    })
    .catch(() => {
      return null;
    });
}
