import { BuildingOffice2Icon } from '@heroicons/react/24/outline';
import cls from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { getPropTypesFromTaxCodes } from 'shared/dist/helpers/propertyTaxes';
import { PropertyTypeGroup } from 'shared/dist/types';
import { getPrimaryTaxCodeBasedOnBuildingSize } from 'shared/dist/util/calculations';
import SignedGoogleMapsImage from 'src/components/map/signed-google-maps-image';
import { useDialog } from 'src/context/dialog-context';

import { Building } from '@/src/animations';
import { Archive01Icon, CardExchange02Icon, Delete01Icon } from '@/src/assets/icons';
import ColorHeader from '@/src/components/property/items/color-header';
import NewsecYieldBox from '@/src/components/property/items/newsec-yield-box';
import RentStatsTable from '@/src/components/property/items/rent-stats-table';
import { useBreakpoint } from '@/src/hooks/use-breakpoint';
import { getColorForPropertyType } from '@/src/lib/colors';
import { fetchPropertyRent, getProperty } from '@/src/lib/prop-client-wrapper';
import { CRMSlideInObject } from '@/src/types';
import CrmButton from '@/src/widgets/menu-options/crm-button';
import ValuationToolBtn from '@/src/widgets/menu-options/valuation-tool-button';
import { PropLabels } from '@/src/widgets/prop-labels';

import { CRMActivities } from './crm-tabs/card-activities';
import { CRMPropInfo } from './crm-tabs/card-propinfo';

export default function CRMSlideInTemplate({
  object,
  close
}: {
  object: CRMSlideInObject;
  close: any;
}) {
  const [crmCurrentBoardIncrement, setCrmCurrentBoardIncrement] = useGlobal<any>(
    'currentBoardIncrement' as never
  );
  const [tabIdx, setTabIdx] = useState<number>(0);
  const [mod, setMod] = useState<React.JSX.Element | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [crmObject, setCrmObject] = useState<any | null>(null);
  const [energy, setEnergy] = useState<any | null>(null);

  const router = useRouter();

  const { setDialog } = useDialog();

  const { t } = useTranslation('common');
  const { isMd } = useBreakpoint({ breakpoint: 'md' });

  const primaryType: PropertyTypeGroup = getPrimaryTaxCodeBasedOnBuildingSize(crmObject?.propTax);

  let propName: string = crmObject?.authority_nickname ?? crmObject?.authorityNickname;

  propName = propName?.replace(`${crmObject?.county?.toUpperCase()}`, ``);

  const { color } = getColorForPropertyType(primaryType);

  const fetchData = async () => {
    setLoading(true);
    const json = await getProperty(
      { uuid: object?.crmObject?.uuid },
      ['address', 'lagfart', 'core', 'energy', 'tax', 'whereInCrm'],
      false
    );

    if (json.error) {
      console.error('getProperty error', json.errMessage);
      setLoading(false);
      return;
    }

    const taxCodes: number[] = json.property?.taxCodes;
    const propTypes: PropertyTypeGroup[] = getPropTypesFromTaxCodes(taxCodes);

    await fetchPropertyRent(object?.crmObject, propTypes, true, undefined)
      .then((data) => {
        const isBRF = crmObject?.owners?.find((a) => a?.actor_id?.startsWith('7'));

        setCrmObject({
          ...json.property,
          listId: object?.crmObject?.listId,
          listName: object?.crmObject?.listName,
          list: object?.crmObject?.list,
          boardId: object?.crmObject?.boardId,
          boardName: object?.crmObject?.boardName,
          cardAdded: object?.crmObject?.cardAdded,
          creator: object?.crmObject?.creator,
          market_data: {
            newsec: data?.market_data?.filter((obj) => obj?.institute === 'newsec'),
            forum: data?.market_data?.filter((obj) => obj?.institute === 'forum')
          },
          v2: data?.v2,
          is_brf: isBRF,
          housing: {
            ...data?.housing
          },
          space: {
            ...data?.space
          },
          warehouse: {
            ...data?.warehouse
          }
        });
        setEnergy(json.property?.energyStatement);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (crmObject) {
      switch (tabIdx) {
        case 1:
          setMod(<CRMActivities close={close} object={crmObject} />);
          break;

        default:
          setMod(<CRMPropInfo close={close} object={{ ...crmObject, energyStatement: energy }} />);
      }
    }
  }, [tabIdx, crmObject, close, energy]);

  const openDeletionDialog = () => {
    setDialog({
      object: {
        ...object,
        onDone: () => {
          setDialog(null);
          close();
          // nav with reload
          setCrmCurrentBoardIncrement((crmCurrentBoardIncrement || 0) + 1);
        }
      },
      dialogType: 'prompt-delete-property-from-board'
    });
  };

  const openArchivalDialog = (inArchive: boolean) => {
    setDialog({
      dialogType: 'prompt-crm-dialog-switcher',
      object: {
        initStep: inArchive ? 43 : 42,
        listId: crmObject?.listId,
        boardId: crmObject?.boardId,
        propertyUUID: crmObject?.uuid,
        authorityNickname: crmObject?.authorityNickname
      }
    });
  };

  const latLong = `${crmObject?.lat}, ${crmObject?.lng}`;

  const isBoardForArchived = object?.board.for_archived == true;

  useEffect(() => {
    if (object?.crmObject) {
      fetchData();
    }
  }, []);

  const filterCurrentBoard = (): CRMSlideInObject['crmObject'] => {
    const tempCrmObject = { ...crmObject };
    tempCrmObject.whereInCRM = crmObject?.whereInCRM?.filter(
      (found) => found.board.uuid !== crmObject.boardId
    );
    return tempCrmObject;
  };

  return (
    <div className="flex z-30 h-full flex-col overflow-y-scroll bg-gray-50 dark:bg-dark-lessfaded scroll-smooth">
      <div className="md:pb-1 pb-6">
        {loading ? (
          <>
            <div className="relative h-36 sm:h-48 z-10 overflow-hidden" />
            <Building />
          </>
        ) : (
          <>
            <div className="relative h-36 sm:h-48 z-10 overflow-hidden">
              <SignedGoogleMapsImage
                height={286}
                width={970}
                className="object-contain"
                src={`https://maps.googleapis.com/maps/api/streetview?size=1200x188&location=${encodeURIComponent(
                  latLong
                )}&fov=120&pitch=5&key=AIzaSyBYCMGQ9cdHlRBN414KW_VE0WgTzR6Sqow&source=outdoor`}
              />
            </div>
            <div className="items-start flex flex-col justify-center w-full">
              <ColorHeader primaryType={primaryType}>
                <div className="flex flex-col w-full gap-2 md:flex-row md:justify-between md:items-start">
                  <div className="flex flex-col justify-center text-xl text-left font-medium text-gray-900 dark:text-white -mt-1">
                    <PropLabels small={true} object={crmObject} />
                    <div className="flex flex-col items-start w-full">
                      <h1 className="text-xl text-left font-medium text-gray-900 dark:text-white sm:text-2xl mt-2">
                        {propName}
                      </h1>

                      <div className="font-normal text-[10px] text-gray-400 -mt-1 leading-4 dark:text-white">
                        {crmObject?.county?.toUpperCase()}
                      </div>
                    </div>

                    <div className="flex justify-start md:items-center gap-2 w-[max-content] mt-5">
                      <button
                        onClick={() => {
                          router.push(`/crm/fastighet/${crmObject?.uuid}/overview`, undefined, {
                            shallow: true
                          });
                          close();
                        }}
                        style={{ backgroundColor: `${color}` }}
                        className="flex items-center hover:opacity-80 transition-all duration-200 rounded-md text-xs text-white font-medium p-2 px-3 hover">
                        <BuildingOffice2Icon className="w-4 h-4 mr-2" />
                        {t('property.property-card')}
                      </button>

                      {_.uniq(
                        (crmObject?.whereInCRM || []).map((crmInfo: any) => crmInfo.board.uuid)
                      ).length === 1 ? (
                        <button
                          className="flex bg-white text-gray-900 relative has-tooltip justify-center items-center text-left dark:bg-dark-faded flex-nowrap whitespace-nowrap dark:border-gray-400 dark:hover:text-dark-prophover dark:hover:bg-dark-lessfaded h-9 w-9 text-xs font-normal dark:text-dark-prop hover:bg-light-faded rounded-full transition-colors duration-200 group"
                          onClick={(event) => {
                            close();
                            event.stopPropagation();
                            setDialog({
                              dialogType: 'prompt-crm-dialog-switcher',
                              object: {
                                addEditMode: true,
                                initStep: 20,
                                propertyUUID: crmObject?.uuid,
                                authorityNickname: crmObject?.authorityNickname
                              }
                            });
                          }}>
                          <div className="tooltip bg-white dark:bg-dark-lessfaded rounded-md shadow-sm border border-gray-100 flex-wrap flex overflow-hidden p-3 py-2 text-bobo-black absolute top-10 dark:text-white dark:border-dark-morefaded right-0">
                            {t('crm.user-actions.add-to-new-board')}
                          </div>

                          <CardExchange02Icon className="w-5 h-5 text-gray-600 group-hover:text-bobo-prophover dark:text-dark-prop dark:group-hover:text-dark-prophover transition-all duration-300" />
                        </button>
                      ) : (
                        <CrmButton property={filterCurrentBoard()} />
                      )}

                      {isMd ? <ValuationToolBtn iconOnly={true} property={crmObject} /> : null}

                      <button
                        className="text-left relative has-tooltip bg-light-primary hover:text-bobo-prop p-2 text-xs cursor-pointer text-gray-800 inline-flex hover:bg-bobo-proplight rounded-full transition-colors duration-300 font-medium dark:bg-dark-faded dark:text-dark-inverted dark:hover:text-dark-prophover dark:hover:bg-dark-lessfaded"
                        tabIndex={-1}
                        onClick={() => {
                          close();
                          openArchivalDialog(isBoardForArchived);
                        }}>
                        <Archive01Icon className="text-gray-600 dark:text-white" />
                        <div className="tooltip bg-white dark:bg-dark-lessfaded rounded-md shadow-sm border border-gray-100 flex-wrap flex overflow-hidden p-3 py-2 text-bobo-black absolute top-10 dark:text-white dark:border-dark-morefaded right-0">
                          {isBoardForArchived
                            ? t('crm.user-actions.unarchive')
                            : t('crm.user-actions.archive')}
                        </div>
                      </button>

                      <button
                        className="text-left has-tooltip relative bg-red-200 dark:bg-opacity-100 dark:hover:bg-opacity-80 bg-opacity-60 hover:bg-opacity-100 hover:text-red-500 p-2 text-xs cursor-pointer text-red-800 inline-flex rounded-full transition-colors duration-300 font-medium group"
                        onClick={() => {
                          close();
                          openDeletionDialog();
                        }}>
                        <Delete01Icon className="text-red-800 group-hover:text-red-500 transition-colors duration-300" />
                        <div className="tooltip bg-white dark:bg-dark-lessfaded rounded-md shadow-sm border border-gray-100 flex-wrap flex overflow-hidden p-3 py-2 text-bobo-black absolute top-10 dark:text-white dark:border-dark-morefaded right-0">
                          {t('general.delete')}
                        </div>
                      </button>
                    </div>

                    <div className="font-normal w-5/6 text-xs text-gray-600 dark:text-gray-300 mt-4">
                      <Trans
                        i18nKey="crm.property.added-by"
                        creator={crmObject?.creator}
                        when={moment(crmObject?.cardAdded * 1000).fromNow()}
                        list={crmObject?.listName}
                        board={crmObject?.boardName}>
                        Tillagd av <strong>{{ creator: crmObject?.creator }}</strong>{' '}
                        {{ when: moment(crmObject?.cardAdded * 1000).fromNow() }} i listan
                        <strong> {{ list: crmObject?.listName }}</strong> på tavlan
                        <strong> {{ board: crmObject?.boardName }}</strong>.
                      </Trans>
                    </div>
                  </div>

                  <div className="flex gap-2 flex-col md:items-end w-full h-full sm:w-[unset]">
                    <div className="w-full lg:w-[260px] max-w-[260px]">
                      <NewsecYieldBox object={crmObject} iconOnly={true} />
                    </div>

                    <div className="w-full lg:w-[260px] max-w-[260px] h-[103px]">
                      <RentStatsTable iconOnly={true} property={crmObject} close={close} />
                    </div>
                  </div>
                </div>
              </ColorHeader>

              <div className="w-full px-3 sm:px-6 pb-3 border-gray-100">
                <div className="w-full pr-8 text-[13px] font-normal">
                  <div className="flex items-center justify-start border-b dark:border-dark-morefaded space-x-2">
                    <button
                      onClick={() => setTabIdx(0)}
                      className={cls(
                        `p-2 rounded-t-md cursor-pointer`,
                        {
                          'bg-bobo-prop dark:bg-dark-prop shadow-sm text-white': tabIdx === 0
                        },
                        {
                          'hover:bg-bobo-colight dark:hover:bg-dark-primary text-gray-400 dark:text-gray-300 dark:hover:text-dark-prophover bg-gray-100 dark:bg-dark-faded':
                            tabIdx !== 0
                        }
                      )}>
                      {t('property.property-data')}
                    </button>

                    <button
                      onClick={() => setTabIdx(1)}
                      className={cls(
                        `p-2 rounded-t-md cursor-pointer transition-colors duration-300`,
                        {
                          'bg-bobo-prop dark:bg-dark-prop shadow-sm text-white': tabIdx === 1
                        },
                        {
                          'hover:bg-bobo-colight dark:hover:bg-dark-primary text-gray-400 dark:text-gray-300 dark:hover:text-dark-prophover bg-gray-100 dark:bg-dark-faded':
                            tabIdx !== 1
                        }
                      )}>
                      {t('crm.property.activity')}
                    </button>
                  </div>
                </div>

                <div className="sm:pr-6 w-full mt-4">{mod && mod}</div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="mb-20"></div>
    </div>
  );
}
