import cls from 'classnames';
import { useTranslation } from 'next-i18next';
import { useGlobal } from 'reactn';
import { Actor, ButtonType, UserProfileActor } from 'shared/dist/types';
import Button from 'src/widgets/buttons';

import { useDialog } from '@/src/context/dialog-context';
import MainPageWrapper from '@/src/layout/components/main-page-wrapper';
import { saveActiveActor } from '@/src/lib/user-client-wrapper';

export default function ActiveActors({
  onSelectedActor
}: {
  onSelectedActor?: (a: UserProfileActor['actor']) => void;
}) {
  const [activeActor, setActiveActor] = useGlobal<{ activeActor: Actor }>('activeActor' as never);
  const [userActors] = useGlobal<{ userActors: UserProfileActor[] }>('userActors' as never);
  const [, setTeamRefreshTrigger] = useGlobal<any>('teamRefreshTrigger' as never);

  const { t } = useTranslation('common');

  const { setDialog } = useDialog();

  const isActorInactive = (userActor: UserProfileActor) =>
    userActor.actor.account_deactivated || userActor.actor.account_soft_deleted;

  const onClick = async (userActor: UserProfileActor) => {
    if (onSelectedActor) {
      // This might be used when we're in the actor selector modal?
      const value = {
        ...userActor.actor,
        admin: userActor.permission?.isAdmin
      };
      onSelectedActor(value);
      return;
    }

    // This happens in the ordinary actor selector
    if (isActorInactive(userActor)) {
      setDialog({
        dialogType: 'deactivated-account',
        closeable: false,
        closeableByOutsideClick: false,
        object: {},
        dialogStyle: { maxWidth: '800px' }
      });
    } else {
      await saveActiveActor(userActor.actor, t);
      setActiveActor(userActor.actor);
      setTeamRefreshTrigger(true);
    }
  };

  return (
    <MainPageWrapper title={t('menu.my-companies')} sources={[]}>
      <div className="md:grid md:gap-1 md:grid-cols-1 xl:grid-cols-3 p-5 pb-10">
        <h3 className="md:col-span-3 xl:col-span-1 text-lg font-medium leading-6 text-gray-900 dark:text-white text-left">
          {t('settings.personal.pick-actor-heading')}
        </h3>
        <p className="md:row-start-2 mt-1 text-sm text-gray-600 dark:text-gray-200 text-left">
          {t('settings.personal.pick-actor-desc')}
        </p>

        <div className="mt-5 md:mt-0 md:col-span-2 md:row-start-2 overflow-hidden xl:px-4 space-y-6">
          <fieldset>
            <div className="divide-y divide-gray-100 dark:divide-dark-morefaded text-sm">
              {userActors?.map((userActor, idx) => {
                const isCurrentActor = activeActor?.actor_uuid === userActor.actor.actor_uuid;
                const inactiveActor = isActorInactive(userActor);

                return (
                  <div
                    key={idx}
                    className={cls(
                      'py-3 px-6 w-full flex justify-between items-center text-gray-500 dark:text-gray-300 md:rounded-md',
                      {
                        'bg-bobo-prop dark:bg-dark-prop text-white': isCurrentActor
                      }
                    )}>
                    <div className="font-normal text-lg">
                      {userActor.actor.company_name ?? userActor.actor.name}
                      <p className="text-sm md:ml-3">
                        {inactiveActor
                          ? t('settings.personal.account-deactivated')
                          : userActor.permission?.isAdmin
                            ? t('settings.team.admin')
                            : t('settings.team.user')}
                      </p>
                    </div>

                    {isCurrentActor ? (
                      <div className="px-6 py-2 w-[120px]">{t('general.current')}</div>
                    ) : (
                      <Button
                        type={ButtonType.INFO}
                        disabled={inactiveActor}
                        className="w-[120px]"
                        onClick={() => onClick(userActor)}>
                        {inactiveActor
                          ? t('settings.personal.cannot-pick')
                          : t('settings.personal.pick-company')}
                      </Button>
                    )}
                  </div>
                );
              })}
            </div>
          </fieldset>
        </div>
      </div>
    </MainPageWrapper>
  );
}
