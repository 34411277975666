import { XMarkIcon } from '@heroicons/react/24/solid';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonType } from 'shared/dist/types';

import { useOutsideAlerter } from '@/src/hooks/use-outside-alerter';
import Button from '@/src/widgets/buttons';

export interface ActionButton {
  text: string;
  function: () => void;
}

export default function ModalWarning({
  text,
  setShowWarning,
  close,
  button
}: {
  text: string;
  setShowWarning: (arg: boolean) => void;
  close?: () => void;
  button: ActionButton;
}) {
  const { t } = useTranslation('common');
  const showWarningRef = useRef(null);

  useOutsideAlerter(showWarningRef, setShowWarning);

  const buttonClasses = '!py-1 !px-2 !text-mini';

  return (
    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
      <div className="w-full h-full bg-black opacity-20 z-50 absolute top-0 left-0" />
      <div
        ref={showWarningRef}
        className="text-xs text-bobo-bricksred z-50 border border-gray-100 dark:border-dark-morefaded shadow rounded-md px-2 pt-8 pb-2 flex flex-col gap-5 bg-white dark:bg-dark-primary w-1/3 relative">
        <div>{text}</div>

        <div className="flex justify-between">
          <Button
            type={ButtonType.CANCEL}
            onClick={() => setShowWarning(false)}
            className={buttonClasses}>
            {t('general.cancel')}
          </Button>

          <div className="flex gap-2">
            {close ? (
              <Button type={ButtonType.DELETE} onClick={close} className={buttonClasses}>
                {t('general.close')}
              </Button>
            ) : null}

            <Button
              onClick={() => {
                button.function();
                setShowWarning(false);
              }}
              className={buttonClasses}>
              {button.text}
            </Button>
          </div>
        </div>

        <button
          onClick={() => setShowWarning(false)}
          className="absolute top-1 right-1 p-1 rounded-full hover:bg-bobo-proplight dark:hover:bg-dark-faded transition-colors duration-200">
          <XMarkIcon className="w-4 h-4 text-gray-500" />
        </button>
      </div>
    </div>
  );
}
