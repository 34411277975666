/* External Libraries */

import Image from 'next/image';
import { useEffect, useState } from 'react';

export default function SignedGoogleMapsImage({
  src,
  alt,
  height,
  width,
  className,
  priority
}: {
  src: string;

  alt?: string;
  height?: number;
  width?: number;
  priority?: boolean;
  className?: string;
}) {
  const [signedURL, setSignedURL] = useState<null | string>(null);

  useEffect(() => {
    const fetchSignedURL = async () => {
      fetch(`/api/maps/signRequest?url=${encodeURIComponent(src)}`)
        .then((res) => res.text())
        .then((signedURL) => {
          setSignedURL(signedURL);
        })
        .catch((err) => {
          console.error(err);
        });
    };

    fetchSignedURL();
  }, [src]);

  return (
    <>
      {signedURL ? (
        <Image
          alt={alt ?? 'Location streetview'}
          height={height}
          width={width}
          priority={priority}
          className={className}
          src={signedURL}
        />
      ) : (
        <></>
      )}
    </>
  );
}
