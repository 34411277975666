import _ from 'lodash';
import moment from 'moment';
import { ValueCategoryTypes } from 'shared/dist/types';
import {
  CashflowLease,
  CashflowValuationAsset,
  PropertyValuationSummaryData,
  SliderValue
} from 'shared/dist/types/cashflow/cashflow-model';
import { NewsecCategory } from 'shared/dist/types/rent';

import {
  calculateAvgYieldOnValue,
  calculatePropertyTax,
  calculatePropertyValue
} from '../../valuation-tool/items/functions';
import { getRentRollArea } from './calculator-functions';

export const calculateNewSliderValues = (
  sliderValues: SliderValue[],
  category: NewsecCategory,
  rentRoll: CashflowLease[],
  assetArea: number
): SliderValue[] => {
  if (category === NewsecCategory.HOUSING) {
    return sliderValues;
  }

  const newValues: SliderValue[] = sliderValues.map((cat) => {
    if (cat.category === ValueCategoryTypes.VACANCY) {
      const commercialVacancy = calculateCommercialVacancy(rentRoll, assetArea);
      return { ...cat, value: commercialVacancy };
    }
    if (cat.category === ValueCategoryTypes.RENT) {
      const commercialRent = calculateCommercialRent(rentRoll);
      return { ...cat, value: commercialRent };
    }
    return cat;
  });
  return newValues;
};

export const calculateCommercialRent = (tenants: CashflowLease[]): number => {
  let rent = 0;
  const assetArea = getRentRollArea(tenants);

  tenants
    .filter((tenant) => isOngoingLease(tenant))
    .map((tenant) => {
      const tenantRent: number = tenant.rent * tenant.area;
      rent += tenantRent;
    });
  return assetArea > 0 ? Math.round(rent / assetArea) : 0;
};

export const isOngoingLease = (lease: CashflowLease): boolean => {
  const presentDate: number = moment().unix();
  if (lease.startDate <= presentDate && lease.endDate >= presentDate) {
    return true;
  }
  return false;
};

export const calculateCommercialVacancy = (rentRoll: CashflowLease[], propArea: number) => {
  if (propArea === 0 || rentRoll.length === 0) return 0;
  const leasesOnPresentDate = rentRoll.filter((lease) => isOngoingLease(lease));

  const area = _.sumBy(leasesOnPresentDate, (t: CashflowLease) => t.area);

  return +(100 - (area / propArea) * 100).toFixed(2);
};

export function calculateYearDifference(startDate: number, endDate: number) {
  const start = moment.unix(startDate);
  const end = moment.unix(endDate);
  const yearDifference = end.diff(start, 'years') + 1;

  return yearDifference;
}

const calculateRentNet = (rent: number, vacancy: number) =>
  Math.round(rent * ((100 - vacancy) / 100));

const calculateOperatingNet = (
  rent: number,
  vacancy: number,
  costs: number,
  maintenance: number,
  propertyTax: number
) => Math.round(rent * ((100 - vacancy) / 100) - costs - maintenance - propertyTax);

const getAssetTax = (asset: CashflowValuationAsset, area: number, apartmentCount: number) =>
  area > 0
    ? calculatePropertyTax(
        asset.adjustableAssumptions.propertyTax.propertyTaxType,
        asset.adjustableAssumptions.propertyTax.taxValue,
        apartmentCount
      )
    : 0;

const getAssetMaintenance = (asset: CashflowValuationAsset, area: number) => {
  if (asset.calculatedAssumptions.calculatorResult[ValueCategoryTypes.MAINTENANCE]) {
    return asset.calculatedAssumptions.calculatorResult[ValueCategoryTypes.MAINTENANCE];
  }
  return area > 0
    ? (asset.adjustableAssumptions.sliderValues.find(
        (value) => value.category === ValueCategoryTypes.MAINTENANCE
      )?.value ?? 0) * area
    : 0;
};

export const getCalculatorSummary = (
  assets: CashflowValuationAsset[],
  object
): PropertyValuationSummaryData => {
  let totalSum = 0;
  let totalArea = 0;
  let totalRentGross = 0;
  let totalRentNet = 0;
  let totalCosts = 0;
  let totalMaintenance = 0;
  let totalTax = 0;
  let totalOperatingNet = 0;
  const countLeaseFee = true;
  const apartmentCount = object?.apartmentCount ?? 0;

  const assetData = assets.map((asset) => {
    const { adjustableAssumptions, calculatedAssumptions } = asset;
    const { area, propertyTax } = adjustableAssumptions;
    const { calculatorResult: assetResults } = calculatedAssumptions;

    const assetArea = area ?? 0;
    const countPropertyTax = propertyTax.countPropertyTax;
    const assetTax = getAssetTax(asset, assetArea, apartmentCount);
    const assetMaintenance = getAssetMaintenance(asset, assetArea);
    totalMaintenance += assetMaintenance;

    totalTax += countPropertyTax ? assetTax : 0;

    const assetSum = calculatePropertyValue(
      assetResults[ValueCategoryTypes.YIELD],
      assetResults[ValueCategoryTypes.RENT] *
        ((100 - assetResults[ValueCategoryTypes.VACANCY]) / 100),
      assetResults[ValueCategoryTypes.COSTS] + assetMaintenance + (countPropertyTax ? assetTax : 0)
    );

    totalSum += assetSum ?? 0;
    totalArea += assetArea;

    const rentGross = Math.round(assetResults[ValueCategoryTypes.RENT]);
    totalRentGross += rentGross;

    const rentNet = calculateRentNet(
      assetResults[ValueCategoryTypes.RENT],
      assetResults[ValueCategoryTypes.VACANCY]
    );
    totalRentNet += rentNet;

    const tabCosts = Math.round(assetResults[ValueCategoryTypes.COSTS]);
    totalCosts += tabCosts;

    const operatingNet = calculateOperatingNet(
      assetResults[ValueCategoryTypes.RENT],
      assetResults[ValueCategoryTypes.VACANCY],
      tabCosts,
      assetMaintenance,
      assetTax
    );
    totalOperatingNet += operatingNet;

    const valueSqm = assetArea > 0 ? Math.round(assetSum / assetArea) : 0;
    const valueTotal = assetArea > 0 ? Math.round(assetSum) : 0;

    return {
      tabName: asset.name,
      rentGross,
      rentNet,
      tax: assetTax,
      vacancy: assetResults[ValueCategoryTypes.VACANCY],
      costs: tabCosts,
      maintenance: assetMaintenance,
      area: assetArea,
      operatingNet,
      yield: assetResults[ValueCategoryTypes.YIELD],
      valueSqm,
      valueTotal: valueTotal ?? 0,
      countPropertyTax,
      uuid: asset.uuid
    };
  });

  const avgYieldForLandLeaseCost = calculateAvgYieldOnValue(totalSum, assetData);

  const landLeaseCost =
    object?.landLeaseAgreement?.lease_fee && countLeaseFee
      ? +object?.landLeaseAgreement?.lease_fee
      : 0;

  const landLeaseCostWithYield =
    object?.landLeaseAgreement?.lease_fee && countLeaseFee && totalSum > 0
      ? Math.round(
          +object?.landLeaseAgreement?.lease_fee /
            ((avgYieldForLandLeaseCost > 0 ? avgYieldForLandLeaseCost : 1) / 100)
        )
      : 0;

  const totalValue = totalSum - landLeaseCostWithYield;
  const operatingNetTotal = totalOperatingNet - landLeaseCost;
  const totalYield =
    operatingNetTotal > 0 ? +((operatingNetTotal / totalValue) * 100).toFixed(2) : 0;

  const tmpSummaryData: PropertyValuationSummaryData = {
    totalValueSqm: totalArea > 0 ? Math.round(totalValue / totalArea) : 0,
    totalValue,
    totalArea,
    totalRentGross,
    totalRentNet,
    totalCosts: totalCosts + landLeaseCost,
    totalMaintenance,
    totalTax,
    totalOperatingNetSqm: totalArea > 0 ? Math.round(operatingNetTotal / totalArea) : 0,
    totalOperatingNet: operatingNetTotal,
    avgYieldForLandLeaseCost,
    totalYield,
    countLeaseFee,
    landLeaseCost,
    landLeaseCostWithYield,
    tabData: assetData
  };

  return tmpSummaryData;
};
