import { ArrowDownRightIcon, ArrowUpRightIcon } from '@heroicons/react/24/solid';
import cls from 'classnames';
import { useTranslation } from 'next-i18next';
import NumberFormat from 'react-number-format';

export const FinancialRow = ({
  title,
  keyItem,
  currYear,
  previousYear
}: {
  title: string;
  keyItem: string;
  currYear: any;
  previousYear: any;
}) => {
  const { t } = useTranslation('common');

  if (
    currYear &&
    currYear?.length > 0 &&
    currYear[keyItem] &&
    previousYear &&
    previousYear?.length > 0 &&
    previousYear[keyItem]
  ) {
    const percChange: number =
      Math.round(
        ((currYear[keyItem] - previousYear[keyItem]) / previousYear[keyItem]) * 100 * 100
      ) / 100;

    return (
      <div className="w-full flex p-2 even:bg-gray-50 dark:even:bg-dark-lessfaded justify-between items-center border-b border-gray-100 dark:bg-dark-faded dark:border-dark-morefaded">
        <div className="flex-1">{title}</div>

        <div
          className={cls(
            `w-2/5 text-right font-normal`,
            {
              'text-bobo-council': currYear[keyItem] < 0
            },
            {
              'text-bobo-black dark:text-white': currYear[keyItem] > 0
            }
          )}>
          {currYear[keyItem] ? (
            <NumberFormat
              value={currYear[keyItem]}
              displayType={'text'}
              suffix={``}
              thousandSeparator={' '}
            />
          ) : (
            '-'
          )}

          {(previousYear[keyItem] > 0 || previousYear[keyItem] < 0) &&
          (currYear[keyItem] > 0 || currYear[keyItem] < 0) ? (
            <span className="ml-1">
              ({percChange}
              %)
            </span>
          ) : (
            <span className="ml-1">{t('general.na')}</span>
          )}
        </div>

        <div className="ml-4">
          <div
            className={cls(
              `rounded-full p-1 w-[24px]`,
              {
                'bg-bobo-council flex justify-center items-center h-[24px]': percChange < 0
              },
              {
                'bg-bobo-prop dark:bg-dark-prop flex justify-center items-center h-[24px]':
                  percChange > 0
              },
              {
                'bg-gray-400': percChange == 0 || !percChange
              }
            )}>
            {percChange < 0 ? <ArrowDownRightIcon className="h-2 w-2 text-white" /> : null}
            {percChange > 0 ? <ArrowUpRightIcon className="h-2 w-2 text-white" /> : null}
            {!percChange ? <span className="text-white flex justify-center">-</span> : null}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
