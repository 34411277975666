import { Switch } from '@headlessui/react';
import {
  BuildingOffice2Icon,
  BuildingOfficeIcon,
  ChevronDownIcon,
  ChevronRightIcon
} from '@heroicons/react/20/solid';
import cls from 'classnames';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { GotoFeature } from 'shared/dist/types';

import { useSlideIn } from '@/src/context/slide-in-context';
import { OrgNumber } from '@/src/widgets/owner';

export default function CompanyStructureSlideIn({
  companyStructure,
  setSlideinContent
}: {
  companyStructure: any;
  corparateStructure: any;
  setSlideinContent: any;
}) {
  const { t } = useTranslation('common');

  const { setSlideIn } = useSlideIn();

  const [showFull, setShowFull] = useState<boolean>(false);

  const listRef = useRef<any>();

  const activeCompanyIndex = companyStructure.findIndex((element) => {
    return element.isCurrentActor;
  });

  useEffect(() => {
    if (listRef?.current) {
      listRef.current.scrollToItem(activeCompanyIndex, 'center');
    }
  }, [listRef?.current, activeCompanyIndex]);

  const newCompany = (object: any) => {
    const isInternationalCompany: boolean =
      object?.contry_code === 'se' || object?.contry_code === 'SE';

    setSlideinContent(isInternationalCompany ? 1 : 0);
    setSlideIn({
      type: GotoFeature.COMPANY,
      object: object
    });
  };

  return (
    <div className="flex flex-col text-sm px-3">
      <div className="border-b pb-4 flex justify-start py-2 dark:border-dark-morefaded">
        <div className="mr-4 text-gray-500">{t('company.show-the-entire-group-tree')}</div>

        <Switch
          checked={showFull}
          onChange={() => setShowFull(!showFull)}
          className={cls(
            showFull ? 'bg-bobo-prop dark:bg-dark-prop' : 'bg-gray-200 dark:bg-dark-morefaded',
            'relative inline-flex h-5 w-9 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out cursor-pointer'
          )}>
          <span
            className={cls(
              showFull ? 'translate-x-4' : 'translate-x-0',
              'pointer-events-none relative inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}>
            <span
              className={cls(
                showFull ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
                'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
              )}
              aria-hidden="true">
              <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                <path
                  d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>

            <span
              className={cls(
                showFull ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
                'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
              )}
              aria-hidden="true">
              <svg
                className="h-3 w-3 text-bobo-prop dark:text-dark-prop"
                fill="currentColor"
                viewBox="0 0 12 12">
                <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
              </svg>
            </span>
          </span>
        </Switch>
      </div>

      {companyStructure &&
        companyStructure?.map((row, index) => {
          if (!row?.isMainPath && !showFull) {
            return <div key={index} />;
          }

          const country_code: string = row?.structure?.country_code?.toLowerCase();

          const flagUrl = `/images/flags/${country_code}.svg`;

          const internationalComp =
            row?.org_nr?.length !== 10 && row?.structure?.obj_type === 'company';

          const openChivron = row?.fold_button && !row?.open;

          return (
            !row.folded && (
              <div
                key={index}
                className={cls(
                  'px-2 pb-1 hover:bg-gray-50 dark:hover:bg-dark-lessfaded text-bobo-prop dark:text-dark-prop flex font-medium justify-between',
                  {
                    '!pl-[0px]': row.slideIn && row.level == 1,
                    '!pl-[11px]': row.slideIn && row.level == 2,
                    '!pl-[22px]': row.slideIn && row.level == 3,
                    '!pl-[33px]': row.slideIn && row.level == 4,
                    '!pl-[44px]': row.slideIn && row.level == 5,
                    '!pl-[55px]': row.slideIn && row.level == 6,
                    '!pl-[66px]': row.slideIn && row.level == 7,
                    '!pl-[77px]': row.slideIn && row.level == 8,
                    '!pl-[88px]': row.slideIn && row.level == 9,
                    '!pl-[99px]': row.slideIn && row.level == 10
                  }
                )}>
                <div>
                  {row.level == 1 ? (
                    <div className="h-5 w-2"></div>
                  ) : (
                    <div className="h-4 w-2 border-l border-b dark:border-dark-morefaded"></div>
                  )}

                  <div className="-mt-3">
                    <div
                      className={cls(
                        'flex gap-1 justify-start transition-colors duration-300 items-center',
                        { '': internationalComp },
                        { 'hover:text-bobo-orangehover text-bobo-orange': row?.isCurrentActor },
                        {
                          'hover:text-bobo-lightgreen dark:hover:text-bobo-lightgreen':
                            !row?.isCurrentActor && !internationalComp
                        }
                      )}>
                      {row.structure.obj_type !== 'company' ? (
                        <BuildingOffice2Icon className="w-3.5 h-3.5 ml-3" />
                      ) : null}

                      <button
                        onClick={() => {
                          if (row.structure.obj_type === 'company') {
                            newCompany(row.structure);
                          } else {
                            setSlideIn({
                              type: GotoFeature.PROPERTY,
                              object: row.structure
                            });
                          }
                        }}
                        className={cls(`ml-1 items-center truncate flex`, {
                          'ml-2.5 mt-0': row.structure.obj_type === 'company'
                        })}
                        title={row.name}>
                        {row.name}
                        {country_code &&
                        country_code !== 'se' &&
                        row?.structure?.obj_type === 'company' ? (
                          <div className="ml-2">
                            <Image src={flagUrl} height={10} width={15} alt={`National Flag`} />
                          </div>
                        ) : null}
                      </button>
                    </div>

                    <div className="relative">
                      {!row.lastOnBranch && !openChivron ? (
                        <div className="absolute h-8 border-l border-gray-200 dark:border-dark-morefaded -mt-2"></div>
                      ) : null}

                      {row.org_nr ? (
                        <div className="text-gray-400 text-[10px] italic pl-3 -mt-1">
                          {country_code && country_code === 'se' ? (
                            <div>
                              {t('company.organisation-number')}
                              <OrgNumber actorId={row?.org_nr} />
                            </div>
                          ) : country_code && country_code !== 'se' ? (
                            <div>{t('company.international-company')}</div>
                          ) : (
                            <div>{t('company.no-task')}</div>
                          )}
                        </div>
                      ) : (
                        <div className="text-gray-400 text-[10px] italic ml-3 -mt-2"></div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex items-center">
                  <div className="mt-3 text-gray-400 font-normal text-xs flex items-center">
                    {row?.number_of_properties && row?.number_of_properties > 0 ? (
                      <div className="flex items-center rounded-md text-gray-400 -mt-3">
                        <NumberFormat
                          value={row?.number_of_properties}
                          suffix={' x '}
                          displayType="text"
                        />{' '}
                        <BuildingOfficeIcon className="h-4 w-4" />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>

                  <div className="flex items-center cursor-pointer">
                    {row.fold_button ? (
                      row.open === true ? (
                        <ChevronRightIcon
                          onClick={(event) => {
                            if (row.onClick) {
                              row.onClick(event);
                            }
                          }}
                          className={`h-5 w-5 text-gray-400`}
                        />
                      ) : (
                        <ChevronDownIcon
                          onClick={(event) => {
                            if (row.onClick) {
                              row.onClick(event);
                            }
                          }}
                          className={`h-5 w-5 text-bobo-prop dark:text-dark-prop`}
                        />
                      )
                    ) : (
                      <div className="h-5 w-5" />
                    )}
                  </div>
                </div>
              </div>
            )
          );
        })}
    </div>
  );
}
