import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { Actor, UserProfileVM } from 'shared/dist/types';
import { Scooter } from 'src/animations';
import ProfileActors from 'src/components/profile/active-actors';
import { getUserProfile } from 'src/lib/user-client-wrapper';

export function ActorSelectionDialog({ onSelectedActor }: { onSelectedActor: (a: Actor) => void }) {
  const enum Steps {
    InitialLoading,
    AutoSelecting,
    ManualSelect
  }

  const [step, setStep] = useState(Steps.InitialLoading);
  const [activeActor, setActiveActor] = useGlobal<{ activeActor: Actor }>('activeActor' as never);
  const [userProfile, setUserProfile] = useState<UserProfileVM>();

  const { t } = useTranslation('common');

  useEffect(() => {
    if (userProfile) return;
    if (activeActor) {
      onSelectedActor(activeActor);
      return;
    }

    getUserProfile(t, false).then((userData) => {
      setUserProfile(userData);
      if (!userData.user.active_actor) {
        setStep(Steps.ManualSelect);
      } else {
        setStep(Steps.AutoSelecting);
        setActiveActor(userData?.userActors[0].actor);
        onSelectedActor(userData?.userActors[0].actor);
      }
    });
  }, [onSelectedActor, setActiveActor, activeActor, t, setStep, userProfile, setUserProfile]);

  switch (step) {
    case Steps.InitialLoading:
      return (
        <div className="h-full min-h-[200px] flex flex-col">
          <div className="text-2xl mb-8 text-center">{t('general.fetching-data')}</div>
          <div>
            <Scooter />
          </div>
          <div className="my-8 w-3/4 m-auto">{t('general.fetching-data-desc')}</div>
        </div>
      );
    case Steps.AutoSelecting:
      return (
        <div className="h-full min-h-[200px] flex flex-col flex-start">
          <div className="text-2xl mb-8">{t('general.fetching-data')}</div>
          <div className="flex-grow flex flex-col justify-end">
            <Scooter />
          </div>
          <div className="my-8 w-3/4 m-auto">{t('settings.personal.actor-updated')}</div>
        </div>
      );
    case Steps.ManualSelect:
      return (
        <div className="h-full min-h-[200px] flex flex-col justify-center items-center">
          <ProfileActors onSelectedActor={onSelectedActor} />
        </div>
      );
  }
}
