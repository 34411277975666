import { Locale } from 'date-fns';
import * as dateFnsLocales from 'date-fns/locale';
import { NextRouter } from 'next/router';
import { registerLocale } from 'react-datepicker';

export const localCouncilNameConverter = (lc: string) => {
  let localCouncil: string = lc?.toLowerCase();
  localCouncil = localCouncil?.replace(/\u00e4/g, 'a');
  localCouncil = localCouncil?.replace(/\u00f6/g, 'o');
  localCouncil = localCouncil?.replace(/\u00e5/g, 'a');

  return localCouncil;
};

export const SWE_BBOX: any = [
  [11.0273686052, 54.7617373725],
  [23.9033785336, 69.3062472602]
];

export const TYPES_PRIVATE: number[] = [200, 220];
export const TYPES_HOUSING: number[] = [320, 321];
export const TYPES_OFFICE: number[] = [325, 326];
export const TYPES_WAREHOUSE: number[] = [423, 426, 432, 433];
export const TYPES_INDUSTRY: number[] = [
  411, 412, 413, 414, 415, 420, 421, 422, 424, 425, 430, 431, 443, 480, 481, 497
];
export const TYPES_SPECIAL: number[] = [810, 820, 821, 822, 823, 824, 826, 827, 828, 829, 890];
export const TYPES_STREET: number[] = [498, 499];

export const RCP_SCENARIO = {
  rcp26: 'rcp.scenario.rcp26',
  rcp45: 'rcp.scenario.rcp45',
  rcp85: 'rcp.scenario.rcp85'
};

export const PROVIDER_TYPE = {
  WATER: 'general.water',
  INTERNET: 'property.broadband',
  POWER_GRID: 'property.power-grid-short',
  WASTE_MANAGEMENT: 'property.garbage-handling',
  DISTRICT_HEATING: 'property.district-heating'
};

export const RENT_POINTS_TABLE: any[] = [
  {
    text: `rent-points.1-room-heading`,
    points91: `rent-points.p-24`,
    points12: `rent-points.p-27`,
    points78_old: 'rent-points.p-27',
    points78_new: `rent-points.p-32`
  },
  {
    text: `rent-points.1-room-plus-heading`,
    points91: `rent-points.p-27`,
    points12: `rent-points.p-30`,
    points78_old: `rent-points.p-31`,
    points78_new: `rent-points.p-36`
  },
  {
    text: `rent-points.1-room-kitchen-heading`,
    points91: `rent-points.p-34`,
    points12: `rent-points.p-34`,
    points78_old: `rent-points.p-39`,
    points78_new: `rent-points.p-40`
  },
  {
    text: `rent-points.1-5-room-heading`,
    points91: `rent-points.p-37`,
    points12: `rent-points.p-37`,
    points78_old: `general.not-applicable`,
    points78_new: `general.not-applicable`
  },
  {
    text: `rent-points.2-room-plus-heading`,
    points91: `rent-points.p-34`,
    points12: `rent-points.p-34`,
    points78_old: `rent-points.p-34`,
    points78_new: `rent-points.p-40`
  },
  {
    text: `rent-points.2-room-kitchen-heading`,
    points91: `rent-points.p-40`,
    points12: `rent-points.p-40`,
    points78_old: `rent-points.p-45`,
    points78_new: `rent-points.p-45`
  },
  {
    text: `rent-points.2-5-room-heading`,
    points91: `rent-points.p-42`,
    points12: `rent-points.p-42`,
    points78_old: `rent-points.p-50`,
    points78_new: `rent-points.p-50`
  },
  {
    text: `rent-points.3-room-heading`,
    points91: `rent-points.p-44`,
    points12: `rent-points.p-44`,
    points78_old: `rent-points.p-54`,
    points78_new: `rent-points.p-54`
  },
  {
    text: `rent-points.3-5-room-heading`,
    points91: `rent-points.p-46-5`,
    points12: `rent-points.p-46-5`,
    points78_old: `general.not-applicable`,
    points78_new: `general.not-applicable`
  },
  {
    text: `rent-points.4-room-heading`,
    points91: `rent-points.p-49-extra-wc`,
    points12: `rent-points.p-49`,
    points78_old: `rent-points.p-56`,
    points78_new: `rent-points.p-56`
  },
  {
    text: `rent-points.4-5-room-heading`,
    points91: `rent-points.p-50-5`,
    points12: `rent-points.p-50-5`,
    points78_old: `general.not-applicable`,
    points78_new: `general.not-applicable`
  },
  {
    text: `rent-points.5-room-heading`,
    points91: `rent-points.p-52`,
    points12: `rent-points.p-52`,
    points78_old: `rent-points.p-56`,
    points78_new: `rent-points.p-56`
  },
  {
    text: `rent-points.6-room-heading`,
    points91: `rent-points.p-55`,
    points12: `rent-points.p-55`,
    points78_old: `rent-points.p-58`,
    points78_new: `rent-points.p-58`
  },
  {
    text: `rent-points.7-room-heading`,
    points91: `rent-points.p-57`,
    points12: `rent-points.p-58`,
    points78_old: `rent-points.p-60`,
    points78_new: `rent-points.p-60`
  },
  {
    text: `rent-points.8-room-heading`,
    points91: `rent-points.p-59`,
    points12: `rent-points.p-59`,
    points78_old: `general.not-applicable`,
    points78_new: `general.not-applicable`
  }
];

export const RENT_MAP_STATIC_DATA_THRESHOLD = 5000;

export const companyRelevantActors = [
  {
    root_actor_id: 'Tk8tRnJlZGVuc2JvcmcgQVM=',
    root_actor_name: 'Fredensborg AS',
    relevant_actor_id: '5566700455',
    relevant_actor_name: 'Heimstaden AB'
  },
  {
    root_actor_id: 'REUtRGV1dHNjaGUgQW5uaW5ndG9uIEFjcXVzaXRpb24gSG9sZGluZyBHbWJo',
    root_actor_name: 'Deutsche Annington Acqusition Holding Gmbh',
    relevant_actor_id: '5566950738',
    relevant_actor_name: 'Victoriahem AB'
  }
];

export const bboxAroundSweden = [
  // [lng, lat], // sw
  // [lng, lat] // ne
  [10.0273686052, 50.7617373725],
  [26.9033785336, 71.1062472602]
];

export const PROPERTY_CARD_MENU_OPTIONS_BASIC = ['crm', 'reports', 'valuation_tool'];

const isValidLocale = (localeCode: string): boolean => {
  try {
    const locale: Locale = dateFnsLocales[localeCode];
    if (locale) {
      registerLocale(localeCode, locale);
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error(`Error registering locale '${localeCode}':`, error);
    return false;
  }
};

export const getValidLocale = (router: NextRouter): string => {
  const localeCode = router.locale ?? 'sv';
  if (isValidLocale(localeCode)) {
    return localeCode;
  }
  return 'sv';
};
