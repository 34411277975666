/* This example requires Tailwind CSS v2.0+ */

// import { taxTypes } from 'src/lib/constants';
import cls from 'classnames';

import InfoButton from '../widgets/info-button';

export default function WrapSection({
  children,
  helpTextId,
  title,
  theme,
  className
}: {
  children?: JSX.Element | JSX.Element[];
  helpTextId?: string;
  title: JSX.Element | string;
  theme?: string;
  className?: string;
}) {
  return (
    <div
      className={cls(
        'rounded-md overflow-hidden dark:bg-dark-lessfaded w-full',
        { '!pb-0 mb-0': theme === 'compact' },
        { 'pb-2': theme === 'nopadding' },
        { '!pb-0 shadow': theme === 'compact-less-padding' || theme === 'with-bg' },
        { 'pb-10': !['nopadding', 'compact']?.includes(theme ?? '') },
        {
          'border border-bobo-proplight rounded-b-md dark:border-dark-prop':
            theme !== 'modern' && theme !== 'nospace' && theme !== '2.0'
        },
        {
          'border !pb-3 border-gray-100 dark:border-dark-morefaded rounded-md bg-gray-50 dark:bg-dark-lessfaded p-3 text-gray-800 dark:text-white':
            theme === '2.0'
        },
        className
      )}>
      <div
        className={cls(
          '',
          {
            'bg-bobo-proplight dark:bg-dark-prop text-bobo-black dark:text-white rounded-tr-md font-demi py-2 px-4 text-xs uppercase flex justify-between':
              !theme || theme === 'nopadding' || theme === 'with-bg'
          },
          {
            'bg-bobo-prop dark:bg-dark-prop text-white rounded-tr-md font-bold py-2 px-4 text-xs uppercase flex justify-between':
              theme === 'nospace'
          },
          {
            'bg-bobo-proplight dark:bg-dark-prop text-gray-800 dark:text-white rounded-t-md font-demi py-2 px-4 text-xs uppercase flex':
              theme === 'light'
          },
          {
            'bg-bobo-prop dark:bg-dark-prop text-white border-b rounded-t-md font-demi py-2 px-4 text-xs uppercase border-gray-100 dark:border-dark-morefaded flex':
              theme === 'modern'
          },
          {
            'bg-bobo-proplight dark:bg-dark-prop text-bobo-black dark:text-white justify-between rounded-t-md font-demi py-2 px-4 text-xs uppercase flex':
              theme === 'compact'
          },
          {
            'bg-bobo-proplight dark:bg-dark-prop text-bobo-black dark:text-white border-b justify-between rounded-t-md font-demi py-2 px-2 text-xs uppercase dark:border-dark-morefaded border-gray-100 flex':
              theme === 'compact-less-padding'
          },
          {
            'border-b mb-2 border-gray-200 dark:border-dark-morefaded font-demi flex':
              theme === '2.0'
          }
        )}>
        <div className="w-full">{title}</div>

        {helpTextId && (
          <div className="font-normal">
            <InfoButton
              helpTextId={helpTextId}
              className="p-[1px] text-mini text-bobo-prop dark:text-dark-prop dark:hover:text-white bg-white dark:bg-dark-lessfaded hover:bg-bobo-lightgreen dark:hover:bg-dark-prophover transition-color duration-300 hover:text-white border border-bobo-prop dark:border-dark-prop dark:hover:border-dark-prophover rounded-full w-[18px] h-[18px] flex items-center justify-center"
            />
          </div>
        )}
      </div>

      <div
        className={cls(
          'font-medium p-4 w-full text-xs dark:text-white',
          {
            'p-0': theme === 'compact' || theme === 'nopadding'
          },
          {
            '!p-0': theme === 'nospace'
          },
          {
            '!p-0 bg-white dark:bg-dark-faded': theme === 'compact-less-padding'
          },
          {
            'bg-white dark:bg-dark-faded': theme === 'with-bg'
          },
          {
            '!p-0': theme === '2.0'
          }
        )}>
        {children}
      </div>
    </div>
  );
}
