export default function loadArcs(map, geoJson) {
  if (!map?.getMap()?.getSource('arcs')) {
    map?.getMap()?.addSource('arcs', {
      type: 'geojson',
      data: geoJson
    });
  }

  !map.getMap()?.getLayer('arcs-line') &&
    map?.getMap()?.addLayer({
      id: 'arcs-line',
      type: 'line',
      source: 'arcs',
      layout: {},
      paint: {
        'line-color': 'rgba(0, 0, 0, 1)',
        'line-dasharray': [5, 5],
        'line-opacity': 0.2,
        'line-width': 2
      }
    });

  !map.getMap()?.getLayer('arcs-text') &&
    map?.getMap()?.addLayer({
      id: 'arcs-text',
      type: 'symbol',
      source: 'arcs',
      layout: {
        'text-field': ['format', ['get', 'distance']],
        'text-font': ['Open Sans Regular', 'Arial Unicode MS Bold'],
        'text-size': 11,
        'symbol-placement': 'line-center'
      },
      paint: {
        'text-color': 'rgba(0, 0, 0, 1)',
        'text-halo-color': 'rgba(255, 255, 255, .75)',
        'text-halo-width': 20
      }
    });
}
