import { XMarkIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';

import { crmDialogTheme } from '../dialogs/crm/dialog-theme';
import Crm2BoardDialogCreate from '../dialogs/crm2/board-create';
// import Crm2ImportProperties from '../dialogs/crm2/import-properties';
// import Crm2ListDialogAddEdit from '../dialogs/crm2/list-add-edit';
// import Crm2ListDialogArchive from '../dialogs/crm2/list-archive';
// import Crm2ListDialogDelete from '../dialogs/crm2/list-delete';
// import { Crm2PropertiesDialogAdd, ErrorTooManyProps } from '../dialogs/crm2/property-add';
// import Crm2PropertyDialogArchive from '../dialogs/crm2/property-archive';

// enum StepNames = {
//   newBoard
// };

const stepNames = {
  new_board: 10,
  update_board: 30,
  new_list: 2
  // update_list: 5,
  // delete_list: 6,
  // archive_list: 7,
  // import_properties: 8,
  // add_property: 20,
  // add_properties: 40,
  // too_many_props_to_import: 41,
  // archive_property: 42,
  // unarchive_property: 43
};

export default function Crm2DialogSwitcher({
  object: {
    initStep = stepNames.new_list,
    listId = null,
    listName = ``,
    boardName,
    boardId,
    priority,
    boardPrivacy,
    propertyUUID,
    authorityNickname,
    propertiesUUIDS,
    authoritiesNicknames,
    addEditMode = false,
    isEmpty
  },
  onClose
}: {
  onClose: () => any;
  object: {
    initStep?: number;
    listId?: null | string;
    listName?: string;
    boardName?: string;
    boardId?: null | string;
    priority?: number;
    boardPrivacy?: boolean;
    propertyUUID?: string;
    authorityNickname?: string;
    propertiesUUIDS?: string[];
    authoritiesNicknames?: string[];
    addEditMode?: boolean;
    isEmpty?: boolean;
  };
}) {
  const [step, setStep] = useState(initStep);
  const [reload, setReload] = useState(false);
  const [currentObject, setCurrentObject] = useState({
    listName,
    boardName,
    boardId,
    listId,
    boardPrivacy
  });

  const [crmIncrement, setCRMIncrement] = useGlobal('crmIncrement');
  const [crmCurrentBoardIncrement, setCrmCurrentBoardIncrement] = useGlobal<any>(
    'currentBoardIncrement' as never
  );

  const setListName = (newListName) => {
    setCurrentObject({
      ...currentObject,
      listName: newListName
    });
  };

  useEffect(() => {
    setCurrentObject({
      listName,
      boardName,
      boardId,
      listId,
      boardPrivacy
    });
  }, [boardName, boardId, boardPrivacy]);

  const setBoardName = (boardName) => {
    setCurrentObject({
      ...currentObject,
      boardName
    });
  };

  const setBoardPrivacy = (boardPrivacy: boolean) => {
    setCurrentObject({
      ...currentObject,
      boardPrivacy
    });
  };

  useEffect(() => {
    setCurrentObject({
      ...currentObject,
      listId,
      listName,
      boardId,
      boardName,
      boardPrivacy
    });
  }, [listId]);

  function closeModal() {
    onClose();
    setStep(0);
    if (reload && step === (stepNames.new_board || stepNames.update_board)) {
      setCRMIncrement((crmIncrement || 0) + 1);
    } else if (reload) {
      setCrmCurrentBoardIncrement((crmCurrentBoardIncrement || 0) + 1);
    }
  }

  // const inviteToBoard = () => {
  //   console.log('Invite to Board');
  // };

  let mod: JSX.Element = <div />;

  switch (step) {
    case stepNames.new_board:
      mod = (
        <Crm2BoardDialogCreate
          // initStep={addEditMode ? 2 : 1}
          setMainStep={setStep}
          close={closeModal}
          // board={{ boardName, boardPrivacy, boardId }}
          // setBoardPrivacy={setBoardPrivacy}
          // setBoardName={setBoardName}
          object={currentObject}
          setObject={setCurrentObject}
          // addEditMode={addEditMode}
          // authoritiesNicknames={authoritiesNicknames}
          setReload={setReload}
        />
      );
      break;

    // case stepNames.update_board:
    //   mod = (
    //     <Crm2BoardDialogAddEdit
    //       initStep={4}
    //       setMainStep={setStep}
    //       close={closeModal}
    //       setBoardPrivacy={setBoardPrivacy}
    //       setBoardName={setBoardName}
    //       board={{ boardName, boardPrivacy, boardId }}
    //       object={currentObject}
    //       setObject={setCurrentObject}
    //       addEditMode={addEditMode}
    //       authoritiesNicknames={authoritiesNicknames}
    //       setReload={setReload}
    //     />
    //   );
    //   break;

    // case stepNames.new_list:
    //   currentObject.listId = null;
    //   mod = (
    //     <Crm2ListDialogAddEdit
    //       initStep={1}
    //       setMainStep={setStep}
    //       close={closeModal}
    //       setListName={setListName}
    //       object={currentObject}
    //       setObject={setCurrentObject}
    //       addEditMode={addEditMode}
    //       authoritiesNicknames={authoritiesNicknames}
    //       setReload={setReload}
    //     />
    //   );
    //   break;

    // case stepNames.update_list:
    //   mod = (
    //     <Crm2ListDialogAddEdit
    //       initStep={2}
    //       setMainStep={setStep}
    //       close={closeModal}
    //       setListName={setListName}
    //       listName={listName}
    //       object={currentObject}
    //       setObject={setCurrentObject}
    //       addEditMode={addEditMode}
    //       authoritiesNicknames={authoritiesNicknames}
    //       setReload={setReload}
    //     />
    //   );
    //   break;

    // case stepNames.archive_list:
    //   mod = (
    //     <Crm2ListDialogArchive
    //       initStep={1}
    //       close={closeModal}
    //       object={currentObject}
    //       setReload={setReload}
    //     />
    //   );
    //   break;

    // case stepNames.import_properties:
    //   mod = (
    //     <Crm2ImportProperties
    //       initStep={1}
    //       close={closeModal}
    //       object={currentObject}
    //       setReload={setReload}
    //     />
    //   );
    //   break;

    // case stepNames.delete_list:
    //   mod = (
    //     <Crm2ListDialogDelete
    //       initStep={isEmpty ? 2 : 1}
    //       close={closeModal}
    //       object={currentObject}
    //       setReload={setReload}
    //     />
    //   );
    //   break;

    // case stepNames.add_property:
    //   mod = (
    //     <Crm2PropertiesDialogAdd
    //       setMainStep={setStep}
    //       setObject={setCurrentObject}
    //       close={closeModal}
    //       authorityNicknames={[authorityNickname] as string[]}
    //       propertyUUIDs={[propertyUUID] as string[]}
    //       setReload={setReload}
    //     />
    //   );
    //   break;

    // case stepNames.add_properties:
    //   mod = (
    //     <Crm2PropertiesDialogAdd
    //       setMainStep={setStep}
    //       close={closeModal}
    //       setObject={setCurrentObject}
    //       authorityNicknames={authoritiesNicknames as string[]}
    //       propertyUUIDs={propertiesUUIDS as string[]}
    //       setReload={setReload}
    //     />
    //   );
    //   break;

    // case stepNames.too_many_props_to_import:
    //   mod = <ErrorTooManyProps close={closeModal} />;
    //   break;

    // case stepNames.archive_property:
    //   mod = (
    //     <Crm2PropertyDialogArchive
    //       initStep={1}
    //       close={closeModal}
    //       listId={listId}
    //       propertyId={propertyUUID}
    //       boardId={boardId}
    //       authorityNickname={authorityNickname}
    //       setReload={setReload}
    //     />
    //   );
    //   break;

    // case stepNames.unarchive_property:
    //   mod = (
    //     <Crm2PropertyDialogArchive
    //       initStep={2}
    //       close={closeModal}
    //       propertyId={propertyUUID}
    //       boardId={boardId}
    //       authorityNickname={authorityNickname}
    //       setReload={setReload}
    //     />
    //   );
    //   break;
  }

  return (
    <>
      <button onClick={closeModal} className={crmDialogTheme.closeContainerX}>
        <XMarkIcon className="h-5 w-5" />
      </button>
      {mod}
    </>
  );
}
