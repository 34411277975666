import 'react-datepicker/dist/react-datepicker.css';

import cls from 'classnames';
import moment from 'moment';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import toast from 'react-hot-toast';
import { getCategoryName } from 'shared/dist/constants';
import { ButtonType } from 'shared/dist/types';
import { CashflowLease, CashflowValuationAsset } from 'shared/dist/types/cashflow/cashflow-model';
import { CashflowLeaseTemplateProps } from 'shared/dist/types/cashflow/general';
import { NewsecCategory } from 'shared/dist/types/rent';
import { v4 as uuidv4 } from 'uuid';
import * as z from 'zod';

import DropdownMenu from '@/src/layout/components/dropdown-menu';
import { getValidLocale } from '@/src/lib/constants';
import Button from '@/src/widgets/buttons';

import { DeleteType } from '../../cashflow-valuation/cashflow-dialog-switcher';
import { handleMaxInputLimit } from '../../cashflow-valuation/constants/calculator-functions';
import {
  cashflowTheme,
  DEFAULT_INFLATION_UUID
} from '../../cashflow-valuation/constants/cashflow-theme';
import { MAX_LIMIT_LEASE_AREA_INPUT } from '../../cashflow-valuation/constants/general';
import SelectRentForLeaseDropdown from '../../cashflow-valuation/items/buttons/select-rent-for-lease-dropdown';
import CashflowDeleteModel from './cashflow-delete-model';

// Zod schema for lease validation
const leaseSchema = z.object({
  name: z.string().min(1, 'property.cashflow-valuation.lease.input-errors.string'),
  // startDate: z.date(), // add check for correct date input?
  // endDate: z.date(),  // add check for correct date input?
  rent: z.number().positive('property.cashflow-valuation.lease.input-errors.number'),
  area: z.number().positive('property.cashflow-valuation.lease.input-errors.number'),
  supplements: z.number().min(0, 'property.cashflow-valuation.lease.input-errors.number'),
  indexation: z.number().min(0, 'property.cashflow-valuation.lease.input-errors.number')
});

// Type for form inputs
export interface LeaseInput {
  name: string;
  uuid: string;
  area: number;
  startDate: number;
  endDate: number;
  rent: number;
  supplements: number;
  indexation: number;
  inflationUuid?: string;
  assetName?: string;
  assetUuid?: string;
}

const stepNames = {
  initialAdd: 1,
  deleteLease: 2
};

const validateLeaseInput = (
  leaseInput: LeaseInput
): { valid: boolean; errors: Record<string, string> } => {
  const result = leaseSchema.safeParse(leaseInput);

  if (result.success) {
    return { valid: true, errors: {} };
  }

  const errors: Record<string, string> = {};
  result.error.errors.forEach((error) => {
    const path = error.path.join('.');
    errors[path] = error.message;
  });

  return { valid: false, errors };
};

// * Make sure that dates are only beginning of month with validation in datepicker
export interface AssetOption {
  name: string;
  uuid: string;
  rentRoll: CashflowLease[];
  area: number;
}

export default function CashflowLeaseTemplate({
  object,
  close,
  setReload,
  activeAsset
}: {
  object: CashflowLeaseTemplateProps;
  close: any;
  setReload: (arg: boolean) => void;
  activeAsset?: AssetOption;
}) {
  const { t } = useTranslation();
  const { lease, model, save, setSelectedModel, property } = object;
  const [step, setStep] = useState<number>(stepNames.initialAdd);
  const [errorState, setErrorState] = useState<any>({});

  const [selectedLease, setSelectedLease] = useState<LeaseInput | null>(null);
  const [assetOptions, setAssetOptions] = useState<AssetOption[]>([]);
  const [selectedAssetOption, setSelectedAssetOption] = useState<AssetOption | null>(null);

  const currentYear = +moment().format('YYYY');
  const modelLength: number = moment
    .unix(model.modelLength.endDate)
    .diff(moment.unix(model.modelLength.startDate), 'years');
  const lastYear = currentYear + modelLength;

  const router = useRouter();
  const locale: string = getValidLocale(router);

  useEffect(() => {
    if (!model) {
      return;
    }

    // set initial lease
    if (lease) {
      setSelectedLease({ ...lease, indexation: lease.indexation * 100 });
    } else {
      setInitialNewLease();
    }

    // set initial asset options
    if (activeAsset) {
      setInitialAssetIfAdjustingExistingAsset(activeAsset);
      return;
    }

    if (lease) {
      const endDate = moment.unix(model.modelLength.endDate).unix();
      setInitialAssetsIfExistingLease({
        ...lease,
        endDate: endDate,
        indexation: lease.indexation * 100
      });
      return;
    }

    setInitialAssetsIfAdjustingNewAsset();
  }, [model]);

  const setInitialAssetsIfExistingLease = (lease: CashflowLease): void => {
    const leaseAsset: CashflowValuationAsset | undefined = model.assets.find(
      (asset) => asset.uuid === lease.assetUuid
    );

    if (leaseAsset) {
      const leaseAssetOption = {
        name: leaseAsset.name,
        uuid: leaseAsset.uuid,
        rentRoll: leaseAsset.adjustableAssumptions.rentRoll,
        area: leaseAsset.adjustableAssumptions.area
      };
      setSelectedAssetOption(leaseAssetOption);
      setAssetOptions([leaseAssetOption]);
    }
  };

  const setInitialAssetIfAdjustingExistingAsset = (activeAsset: AssetOption): void => {
    setSelectedAssetOption(activeAsset);
    setAssetOptions([activeAsset]);
  };

  const setInitialAssetsIfAdjustingNewAsset = (): void => {
    setAssetOptions(
      model.assets
        .filter((asset) => asset.adjustableAssumptions.type !== NewsecCategory.HOUSING)
        .map(
          (asset) =>
            asset && {
              name: asset.name,
              uuid: asset.uuid,
              rentRoll: asset.adjustableAssumptions.rentRoll,
              area: asset.adjustableAssumptions.area
            }
        )
    );
  };

  const setInitialNewLease = () => {
    const newLease: LeaseInput = {
      name: '',
      uuid: uuidv4(),
      area: 0,
      startDate: moment(currentYear + '-01-01')
        .startOf('day')
        .unix(),
      endDate: moment(lastYear + '-01-01')
        .endOf('year')
        .startOf('day')
        .unix(),
      rent: 0,
      supplements: 0,
      indexation: 100,
      inflationUuid: DEFAULT_INFLATION_UUID
    };
    setSelectedLease(newLease);
  };

  const validateAndSave = async () => {
    if (!selectedLease) {
      return;
    }

    const { valid, errors } = validateLeaseInput({
      ...selectedLease,
      assetName: selectedAssetOption?.name,
      assetUuid: selectedAssetOption?.uuid
    });

    if (!valid || !selectedAssetOption) {
      let tmpErrors = errors;

      if (!selectedAssetOption) {
        tmpErrors = { ...errors, asset: 'property.cashflow-valuation.lease.input-errors.string' };
      }

      setErrorState(tmpErrors);
      return;
    }

    setErrorState({});

    const validatedLease: CashflowLease = {
      ...selectedLease,
      name:
        t(getCategoryName(selectedLease?.name ?? '')).length > 0
          ? t(getCategoryName(selectedLease?.name ?? ''))
          : selectedLease?.name,
      assetName: selectedAssetOption.name,
      assetUuid: selectedAssetOption.uuid,
      indexation: selectedLease.indexation / 100
    };

    const rentRoll: CashflowLease[] = selectedAssetOption.rentRoll;

    const updatedRentRoll: CashflowLease[] = lease
      ? rentRoll?.map((r) => (r.uuid === lease.uuid ? validatedLease : r))
      : [...rentRoll, validatedLease];

    save(updatedRentRoll);
    if (!activeAsset) {
      setReload(true);
      close();
    }

    toast.success(t('property.cashflow-valuation.lease.saved', { lease: validatedLease?.name }));
  };

  const renderAssetOptions = (closeMenu) =>
    assetOptions?.map((i, idx) => {
      return (
        <div
          key={idx}
          className="py-2 px-2 transition-colors duration-200 hover:bg-bobo-proplight dark:hover:bg-dark-prophover text-bobo-prop dark:text-white hover:text-bobo-prophover w-full">
          <button
            onClick={() => {
              setSelectedAssetOption(i);
              closeMenu();
            }}
            className="disabled:text-gray-300 w-full h-full text-start">
            {t(getCategoryName(i.name)) || i.name}
          </button>
        </div>
      );
    });

  let mod: JSX.Element = <div />;

  switch (step) {
    case stepNames.initialAdd:
      mod = (
        <div className="z-30 max-h-[calc(100vh-100px)] overflow-auto flex flex-col gap-8 -m-6 p-6">
          <div>
            <h3 className="text-base font-bold text-gray-900 dark:text-white sm:text-lg uppercase">
              {t(`property.cashflow-valuation.lease.${lease ? 'modify' : 'add-new'}`)}
            </h3>

            {selectedAssetOption ? (
              <div className="text-xs text-gray-600 dark:text-white flex flex-col">
                <div>
                  <Trans
                    i18nKey="property.cashflow-valuation.lease.existing-rental-agreements-property"
                    count={selectedAssetOption.rentRoll.length}>
                    Du har <strong>{{ count: selectedAssetOption.rentRoll.length }}</strong>{' '}
                    befintliga hyresavtal på lokalslaget{' '}
                    <strong>
                      {{
                        asset:
                          t(getCategoryName(selectedAssetOption?.name)) || selectedAssetOption?.name
                      }}
                    </strong>
                  </Trans>
                </div>
                <div className="text-gray-400 leading-3">
                  {t('property.cashflow-valuation.lease.lease-vacancy')}
                </div>
              </div>
            ) : null}
          </div>

          {selectedLease ? (
            <div className="flex flex-col justify-center gap-4 text-xs">
              <div className="flex gap-2 items-center">
                <label htmlFor="namn" className="font-demi w-[100px] text-right">
                  {t('general.name')}:
                </label>
                <div className={cashflowTheme.inputContainer}>
                  <input
                    id="namn"
                    type="text"
                    autoComplete="off"
                    value={t(getCategoryName(selectedLease?.name)) || selectedLease?.name || ''}
                    placeholder={
                      errorState.name
                        ? t(errorState.name, { field: t('general.name') })
                        : t('property.cashflow-valuation.lease.name')
                    }
                    onChange={({ target }) => {
                      setSelectedLease({ ...selectedLease, name: target.value });
                    }}
                    className={cls(cashflowTheme.inputField, {
                      'placeholder:text-bobo-bricksred': errorState.name
                    })}
                  />
                </div>
              </div>

              <div className="flex gap-2 items-center">
                <label htmlFor={'property'} className="font-demi w-[100px] text-right">
                  {t('property.cashflow-valuation.assumptions.header')}:
                </label>

                <DropdownMenu
                  className="z-50"
                  title={
                    errorState.asset ? (
                      <span className="text-bobo-bricksred whitespace-nowrap">
                        {t(errorState.asset, {
                          field: t('property.cashflow-valuation.assumptions.header')
                        })}
                      </span>
                    ) : selectedAssetOption ? (
                      t(getCategoryName(selectedAssetOption?.name)) || selectedAssetOption?.name
                    ) : (
                      t('property.cashflow-valuation.lease.choose-property')
                    )
                  }
                  renderMenuItems={renderAssetOptions}
                />
              </div>

              <div className="flex gap-2 items-center">
                <label htmlFor="rent-input" className="font-demi w-[100px] text-right">
                  {t('general.rent')}:
                </label>
                <SelectRentForLeaseDropdown
                  property={property}
                  errorState={errorState}
                  selectedLease={selectedLease}
                  setSelectedLease={setSelectedLease}
                />
              </div>

              <div className="flex gap-2 items-center">
                <label htmlFor="area-input" className="font-demi w-[100px] text-right">
                  {t('general.area')}:
                </label>

                <div className={cashflowTheme.inputContainer}>
                  <input
                    type="number"
                    id="area-input"
                    value={selectedLease?.area || ''}
                    placeholder={
                      errorState.area ? t(errorState.area, { field: t('general.area') }) : '0'
                    }
                    onChange={({ target }) => {
                      setSelectedLease({ ...selectedLease, area: parseFloat(target.value) });
                    }}
                    onInput={(e) => handleMaxInputLimit(e, MAX_LIMIT_LEASE_AREA_INPUT)}
                    className={cls(cashflowTheme.inputField, {
                      'placeholder:text-bobo-bricksred': errorState.area
                    })}
                  />

                  <div className="whitespace-nowrap">
                    {t('general.square-meters')}
                    {selectedAssetOption && selectedAssetOption.area > 0
                      ? t('property.cashflow-valuation.lease.asset-area', {
                          area: selectedAssetOption?.area
                        })
                      : ''}
                  </div>
                </div>
              </div>

              <div className="flex gap-2 items-center">
                <label htmlFor="start-date" className="font-demi w-[100px] text-right">
                  {t('property.cashflow-valuation.assumptions.contract-start')}:
                </label>
                <div className={cashflowTheme.inputContainer}>
                  <DatePicker
                    id="start-date"
                    selected={
                      selectedLease?.startDate
                        ? moment.unix(selectedLease.startDate).toDate()
                        : new Date()
                    }
                    onChange={(date) => {
                      setSelectedLease({
                        ...selectedLease,
                        startDate: moment(date, 'YYYY-MM').startOf('month').unix()
                      });
                    }}
                    dateFormat="yyyy-MM-dd"
                    locale={locale}
                    showMonthYearPicker
                    className={cashflowTheme.inputField}
                    wrapperClassName="!w-full"
                  />
                </div>
              </div>

              <div className="flex gap-2 items-center">
                <label htmlFor="end-date" className="font-demi w-[100px] text-right">
                  {t('property.cashflow-valuation.assumptions.contract-end')}:
                </label>
                <div className={cashflowTheme.inputContainer}>
                  <DatePicker
                    id="end-date"
                    selected={
                      selectedLease?.startDate
                        ? moment.unix(selectedLease.endDate).toDate()
                        : new Date()
                    }
                    onChange={(date) => {
                      setSelectedLease({
                        ...selectedLease,
                        endDate: moment(date, 'YYYY-MM').endOf('month').unix()
                      });
                    }}
                    dateFormat="yyyy-MM-dd"
                    locale={locale}
                    showMonthYearPicker
                    className={cashflowTheme.inputField}
                    wrapperClassName="!w-full"
                  />
                </div>
              </div>

              <div className="flex gap-2 items-center">
                <label htmlFor="supplements" className="font-demi w-[100px] text-right">
                  {t('property.cashflow-valuation.assumptions.supplements')}:
                </label>
                <div className={cashflowTheme.inputContainer}>
                  <input
                    type="number"
                    id="supplements"
                    min={0}
                    step={1}
                    placeholder={'0'}
                    value={Number(+(selectedLease?.supplements ?? 0)).toString()}
                    onChange={({ target }) => {
                      setSelectedLease({ ...selectedLease, supplements: +target.value });
                    }}
                    className={cashflowTheme.inputField}
                  />
                  <div>{t('general.kr-suffix')}</div>
                </div>
              </div>

              <div className="flex gap-2 items-center">
                <label htmlFor={'indexation'} className="font-demi w-[100px] text-right">
                  {t('property.cashflow-valuation.assumptions.indexation')}:
                </label>
                <div className={cashflowTheme.inputContainer}>
                  <input
                    type={'number'}
                    id="indexation"
                    step={1}
                    placeholder={
                      errorState.indexation
                        ? t(errorState.indexation, {
                            field: t('property.cashflow-valuation.assumptions.indexation')
                          })
                        : '0'
                    }
                    value={selectedLease.indexation.toString()}
                    onChange={({ target }) => {
                      setSelectedLease({ ...selectedLease, indexation: +target.value });
                    }}
                    onInput={(e) => handleMaxInputLimit(e, 100)}
                    className={cashflowTheme.inputField}
                  />
                  <div>%</div>
                </div>
              </div>
            </div>
          ) : null}

          <div className="flex justify-between items-end">
            <Button type={ButtonType.CANCEL} onClick={close}>
              {t(activeAsset !== undefined ? 'general.back' : 'general.cancel')}
            </Button>

            <div className="flex gap-2">
              {lease && (
                <Button
                  type={ButtonType.DELETE}
                  onClick={async () => {
                    if (activeAsset && selectedAssetOption) {
                      const updatedRentRoll: CashflowLease[] = selectedAssetOption.rentRoll.filter(
                        (lease) => lease.uuid !== selectedLease?.uuid
                      );
                      save(updatedRentRoll);
                      return;
                    }
                    setStep(2);
                  }}>
                  {t('general.delete')}
                </Button>
              )}

              <Button onClick={validateAndSave}>{t('general.save')}</Button>
            </div>
          </div>
        </div>
      );
      break;
    case stepNames.deleteLease:
      mod = (
        <>
          <CashflowDeleteModel
            close={close}
            model={model}
            deletedObject={{ ...(lease as CashflowLease), deleteType: DeleteType.LEASE }}
            setReload={setReload}
            goBack={() => setStep(1)}
            setSelectedModel={setSelectedModel}
          />
        </>
      );
  }
  return <>{mod}</>;
}
