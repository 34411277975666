import cls from 'classnames';
import { Trans, useTranslation } from 'next-i18next';
import NumberFormat from 'react-number-format';
import { Workplace } from 'shared/dist/types';

import { OrgNumber } from '@/src/widgets/owner';

const WorkplaceBlob = ({ wp }: { wp: Workplace }) => {
  const { t } = useTranslation('common');

  const office: any = wp.market_data?.data?.find((x) => x?.property_type === 'office');
  const retail: any = wp.market_data?.data?.find((x) => x?.property_type === 'retail');
  const industry: any = wp.market_data?.data?.find((x) => x?.property_type === 'industry');

  return (
    <div className="border rounded-md bg-white dark:bg-dark-morefaded border-gray-100 dark:border-dark-morefaded text-gray-800 mb-3 p-0 text-xs">
      <div className="p-2 text-[12px] bg-bobo-proplight dark:bg-dark-prop dark:even:bg-dark-faded rounded-t-md border-b border-gray-100 dark:border-dark-morefaded text-sm">
        <div className="font-demi">
          {wp?.office_name} {wp?.office_type && `(${wp?.office_type})`}
        </div>

        <div className="text-xs text-gray-500 dark:text-gray-300">
          <OrgNumber actorId={wp?.actor_id ?? ''} />
        </div>

        {wp?.SNI_code_description !== 'NULL' || wp?.SNI_code !== 'NULL' ? (
          <div className="text-xs italic text-gray-500 dark:text-gray-300">
            {wp?.SNI_code_description} ({wp?.SNI_code})
          </div>
        ) : (
          <div className="text-xs italic text-gray-500 dark:text-gray-300">
            {t('general.no-data-singular')}
          </div>
        )}
      </div>

      <div className="p-2 text-[12px] odd:bg-gray-50 dark:even:bg-dark-primary border-b border-gray-100 dark:border-dark-morefaded flex justify-between items-start dark:text-white">
        <div>
          <div>
            {wp?.address?.street} {wp?.address?.street_no}
            {wp?.address?.street_letter}
          </div>

          <div>
            {wp?.address?.postal_code} {wp?.address?.postal_area}
          </div>

          <div>{wp?.property?.authority_nickname ? wp?.property?.authority_nickname : null}</div>
        </div>

        <div>
          {t('company.phone-number', { phoneNumber: wp?.phone?.length > 6 ? wp?.phone : '-' })}
        </div>
      </div>

      <div className="odd:bg-gray-50 dark:odd:bg-dark-faded p-2 dark:text-white">
        <Trans
          i18nKey="company.employed-in-office"
          values={{ interval: wp?.employees_interval }}
          components={[<strong key={0} />]}
        />
      </div>

      <div className="odd:bg-gray-50 dark:odd:bg-dark-faded dark:even:bg-dark-primary p-2 flex justify-between items-center dark:text-white">
        <div>
          <Trans
            i18nKey="company.office-rent"
            values={{ area: office?.area }}
            components={[<strong key={0} />, <em key={1} />]}
          />
          <div>{t('company.area-class', { class: office?.class })}</div>
        </div>

        <div>
          <NumberFormat value={office?.rent_min} displayType={'text'} thousandSeparator={' '} /> -{' '}
          <NumberFormat value={office?.rent_max} displayType={'text'} thousandSeparator={' '} />{' '}
          <em>
            (
            <NumberFormat value={office?.rent_avg} displayType={'text'} thousandSeparator={' '} />)
          </em>
        </div>
      </div>

      <div className="odd:bg-gray-50 dark:odd:bg-dark-faded dark:even:bg-dark-primary p-2 flex justify-between items-center dark:text-white">
        <div>
          <Trans
            i18nKey="company.retail-rent"
            area={retail?.area}
            values={{ area: retail?.area }}
            components={[<strong key={0} />, <em key={1} />]}
          />

          <div>{t('company.area-class', { class: retail?.class })}</div>
        </div>

        <div>
          <NumberFormat value={retail?.rent_min} displayType={'text'} thousandSeparator={' '} /> -{' '}
          <NumberFormat value={retail?.rent_max} displayType={'text'} thousandSeparator={' '} />{' '}
          <em>
            (
            <NumberFormat value={retail?.rent_avg} displayType={'text'} thousandSeparator={' '} />)
          </em>
        </div>
      </div>

      <div className="odd:bg-gray-50 dark:odd:bg-dark-faded dark:even:bg-dark-primary p-2 flex justify-between items-center dark:text-white rounded-b-md">
        <div>
          <Trans
            i18nKey="company.industry-rent"
            values={{ area: industry?.area }}
            components={[<strong key={0} />, <em key={1} />]}
            t={t}
          />
          <div>{t('company.area-class', { class: industry?.class })}</div>
        </div>

        <div>
          <NumberFormat value={industry?.rent_min} displayType={'text'} thousandSeparator={' '} /> -{' '}
          <NumberFormat value={industry?.rent_max} displayType={'text'} thousandSeparator={' '} />{' '}
          <em>
            (
            <NumberFormat value={industry?.rent_avg} displayType={'text'} thousandSeparator={' '} />
            )
          </em>
        </div>
      </div>
    </div>
  );
};

export default function Tenant({ companyData, slideIn }: { companyData: any; slideIn?: boolean }) {
  if (!companyData?.workplaces || companyData?.workplaces?.length < 1) {
    return (
      <div className={cls({ 'p-0': slideIn }, { 'p-8 pr-12': !slideIn })}>
        <div className="border-gray-100 m-4 my-2 text-xs text-gray-500">
          <Trans
            i18nKey="company.offices-not-found"
            values={{ company: companyData?.actor?.name }}
            components={[<strong key={0} />]}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={cls({ 'p-0': slideIn }, { 'p-8 pr-12': !slideIn })}>
      <div className="m-4 my-2">
        {companyData?.workplaces
          ?.filter((x) => x?.actor_id === companyData?.actor?.actor_id)
          ?.map((wp: Workplace, idx) => {
            return <WorkplaceBlob wp={wp} key={idx} />;
          })}

        {companyData?.workplaces?.filter((x) => x?.actor_id !== companyData?.actor?.actor_id)
          ?.length > 0 ? (
          <div className="text-xs py-1 mt-8 mb-2 border-b border-gray-100 text-gray-800">
            <Trans
              i18nKey="company.offices-other"
              values={{ company: companyData?.actor?.name }}
              components={[<strong key={0} />]}
            />
          </div>
        ) : null}
        {companyData?.workplaces
          ?.filter((x) => x?.actor_id !== companyData?.actor?.actor_id)
          ?.map((wp: Workplace, idx) => {
            return <WorkplaceBlob wp={wp} key={idx} />;
          })}
      </div>
    </div>
  );
}
