import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { ButtonType } from 'shared/dist/types';

import { Error, Success } from '@/src/animations';
import { crmDialogTheme } from '@/src/components/dialogs/crm/dialog-theme';
import { deleteCRM2Team } from '@/src/lib/crm2-wrapper';
import Button from '@/src/widgets/buttons';

enum stepNames {
  deleteTeam = 1,
  error = 2,
  done = 3
}

function DeleteTeamStep({
  object,
  close,
  setStep
}: {
  object: any;
  close: () => void;
  setStep: any;
}) {
  const { t } = useTranslation('common');

  const [crmRefresh, setCrmRefresh] = useGlobal<{ crmRefresh: number }>('crmRefresh' as never);

  const router = useRouter();

  return (
    <div className={crmDialogTheme.container}>
      <div className="flex-grow flex flex-col justify-end">
        <div className="text-lg font-medium text-gray-900 dark:text-gray-300 flex items-center">
          <Trans
            i18nKey="crm.modals.teams.confirmation-of-team-deletion"
            t={t}
            values={{ teamName: object?.team?.title }}
            components={[<span className="text-bobo-prop dark:text-dark-prop pl-1.5" key={0} />]}
          />
        </div>
      </div>

      <div className="flex flex-grow justify-between w-full items-end">
        <Button type={ButtonType.CANCEL} onClick={close}>
          {t('general.cancel')}
        </Button>

        <Button
          type={ButtonType.DELETE}
          onClick={() => {
            deleteCRM2Team(object?.teamUUID)
              .then(() => {
                setStep(stepNames.done);
              })
              .then(() => {
                setCrmRefresh((crmRefresh || 0) + 1);
                setTimeout(() => {
                  close();
                }, 3000);
                router.push('/profile/me', undefined, { shallow: true });
              })
              .catch((error) => {
                console.error('error deleting team:', error);
                setStep(stepNames.error);
              });
          }}>
          {t('general.delete')}
        </Button>
      </div>
    </div>
  );
}

function ErrorStep({ close, object }: { close: () => void; object: any }) {
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.icon}>
        <Error />
      </div>

      <div className="flex flex-col w-full text-center items-center">
        <Dialog.Title as="h3" className="text-red-700 w-3/4 leading-6">
          <div className="text-lg font-medium text-gray-900 dark:text-gray-300">
            <span className="break-all">
              <Trans
                i18nKey="crm.modals.teams.error-team-deletion-forbidden"
                t={t}
                values={{ teamName: object?.team?.title }}
                components={[<span className="text-bobo-prop dark:text-dark-prop" key={0} />]}
              />
              {t('errors.try-again')}
            </span>
          </div>
        </Dialog.Title>
      </div>

      <div className={crmDialogTheme.buttonContainer}>
        <Button type={ButtonType.CANCEL} onClick={close}>
          {t('general.close')}
        </Button>
      </div>
    </div>
  );
}

function DeleteTeamStepDone({ object }: { object: any }) {
  const { t } = useTranslation('common');

  return (
    <div>
      <div className={crmDialogTheme.icon}>
        <Success />
      </div>

      <Trans
        i18nKey="crm.modals.teams.deleted-team"
        t={t}
        values={{ teamName: object?.team?.title }}
        components={[<span className="text-bobo-prop dark:text-dark-prop pl-1.5" key={0} />]}
      />
    </div>
  );
}

export default function CRM2DeleteTeamsDialog({
  close,
  object
}: {
  close: () => void;
  object: any;
}) {
  const [step, setStep] = useState(stepNames.deleteTeam);

  let mod: JSX.Element = <div />;

  switch (step) {
    case stepNames.deleteTeam:
      mod = <DeleteTeamStep object={object} close={close} setStep={setStep} />;
      break;

    case stepNames.done:
      mod = <DeleteTeamStepDone object={object} />;
      break;

    case stepNames.error:
      mod = <ErrorStep close={close} object={object} />;
      break;
  }

  return (
    <>
      <button onClick={close} className={crmDialogTheme.closeContainerX}>
        <XMarkIcon className="h-5 w-5" />
      </button>
      {mod}
    </>
  );
}
