import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FLCustom } from '@/src/widgets/numbers';
import { PropWarning } from '@/src/widgets/prop-labels';
import { RowHeader } from '@/src/widgets/row-table-body';

import { headerCellStyle, rowCellStyle } from './constants';

export function SummaryBanner({ summaryData }: { summaryData: any }) {
  const [noData, setNoData] = useState<boolean>(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (summaryData.totalValue > 0 && noData === true) {
      setNoData(false);
    } else if (summaryData.totalValue === 0 && noData === false) {
      setNoData(true);
    } else {
      return;
    }
  }, [summaryData.totalValue]);

  return (
    <div className="w-[700px] pt-3 p-6 mr-3 border rounded border-gray-100 bg-gray-50 dark:bg-dark-lessfaded dark:border-dark-faded shadow-md">
      {noData ? (
        <PropWarning>
          <div className="py-2">{t('value-calculator.no-data-warning')}</div>
        </PropWarning>
      ) : (
        <>
          <RowHeader title={t('value-calculator.summary')} />
          <div className="grid grid-cols-6 items-stretch justify-between rounded-t-md border-x border-t border-gray-100 w-full overflow-hidden text-xs bg-white mt-4 dark:bg-dark-lessfaded dark:border-dark-morefaded">
            <div className={`${headerCellStyle}`}>{t('general.area')}</div>
            <div className={`${headerCellStyle}`}>{t('value-calculator.operation-net-sqm')}</div>
            <div className={`${headerCellStyle}`}>{t('value-calculator.operation-net')}</div>
            <div className={`${headerCellStyle}`}>{t('value-calculator.yield-avg')}</div>
            <div className={`${headerCellStyle}`}>{t('value-calculator.value-sqm')}</div>
            <div className={`${headerCellStyle}`}>{t('value-calculator.value')}</div>
          </div>
          <div className="grid grid-cols-6 items-stretch justify-between rounded-b-md w-full border-x border-b border-gray-100 bg-white dark:bg-dark-faded dark:border-dark-morefaded">
            <div className={`${rowCellStyle} !min-h-fit`}>
              <FLCustom value={summaryData?.totalArea} suffix={t('general.meters-short-suffix')} />
            </div>
            <div className={`${rowCellStyle} !min-h-fit`}>
              <FLCustom
                value={summaryData?.totalArea > 0 ? summaryData?.totalOperatingNetSqm : 0}
                suffix={t('general.kr-per-square-m-suffix')}
              />
            </div>
            <div className={`${rowCellStyle} !min-h-[40px]`}>
              <FLCustom
                value={Math.round(summaryData?.totalOperatingNet / 1000)}
                suffix={t('general.thousand-suffix')}
                prefix={t('general.sek-prefix')}
              />
            </div>
            <div className={`${rowCellStyle} !min-h-[40px]`}>
              <FLCustom value={summaryData?.totalYield} suffix={'%'} />
            </div>
            <div className={`${rowCellStyle} !min-h-[40px]`}>
              <FLCustom
                value={Math.round(summaryData?.totalArea > 0 ? summaryData.totalValueSqm : 0)}
                suffix={t('general.kr-per-square-m-suffix')}
              />
            </div>
            <div className={`${rowCellStyle} !min-h-[40px]`}>
              <FLCustom
                value={Math.round(summaryData.totalValue / 1000)}
                suffix={t('general.thousand-suffix')}
                prefix={t('general.sek-prefix')}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
