import { Switch } from '@headlessui/react';
import cls from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCategoryName } from 'shared/dist/constants';
import { ValueCategoryTypes } from 'shared/dist/types';

import { FLCustom, FLSqm } from '@/src/widgets/numbers';

import DeleteTabBtn from './items/buttons/delete-tab-button';
import { headerCellStyle, rowCellStyle } from './items/constants';

export function SummaryTab({
  object,
  summaryData,
  deleteTab,
  changeLeaseFee
}: {
  object: any;
  summaryData: any;
  deleteTab: any;
  changeLeaseFee: any;
}) {
  const { t } = useTranslation('common');
  const [countLeaseFee, setCountLeaseFee] = useState<boolean>(true);

  useEffect(() => {
    if (countLeaseFee !== summaryData?.countLeaseFee) {
      setCountLeaseFee(summaryData?.countLeaseFee);
    }
  }, [summaryData]);

  return (
    <>
      <div className="grid grid-cols-12 items-stretch justify-between rounded-tr-md border-t border-x border-gray-100 w-full overflow-hidden text-xs bg-gray-100 dark:bg-dark-lessfaded dark:border-dark-morefaded z-20">
        <div className={`${headerCellStyle}`}></div>
        <div className={`${headerCellStyle}`}>{t('value-calculator.rent-gross')}</div>
        <div className={`${headerCellStyle}`}>{t(ValueCategoryTypes.VACANCY)}</div>
        <div className={`${headerCellStyle}`}>{t('value-calculator.rent-net')}</div>
        <div className={`${headerCellStyle}`}>
          {t('property.cashflow-valuation.maintenance-short')}
        </div>
        <div className={`${headerCellStyle}`}>{t('property.maintainance')}</div>
        <div className={`${headerCellStyle}`}>{t('value-calculator.prop-tax')}</div>
        <div className={`${headerCellStyle}`}>{t('value-calculator.operation-net-sqm')}</div>
        <div className={`${headerCellStyle}`}>{t('value-calculator.operation-net')}</div>
        <div className={`${headerCellStyle}`}>{t(ValueCategoryTypes.YIELD)}</div>
        <div className={`${headerCellStyle}`}>{t('value-calculator.value-sqm')}</div>
        <div className={`${headerCellStyle}`}>{t('value-calculator.value')}</div>
      </div>

      {summaryData && summaryData?.tabData?.length > 0 ? (
        <>
          {summaryData?.tabData?.map((tab, idx) => {
            return (
              <div
                key={idx}
                className="grid grid-cols-12 justify-between border-x border-b border-gray-100 dark:border-dark-morefaded relative">
                <div className={`${rowCellStyle} !font-medium`}>
                  <div>
                    {t(getCategoryName(tab.tabName)).length > 0
                      ? t(getCategoryName(tab.tabName))
                      : tab.tabName}
                  </div>
                  <div
                    className={cls({
                      'text-gray-400': tab.area > 0,
                      'text-bobo-bricksred opacity-70': tab.area === 0
                    })}>
                    <FLSqm value={tab.area} />
                  </div>
                </div>

                <div className={`${rowCellStyle} !font-light`}>
                  <FLCustom
                    value={tab.area ? Math.round(tab.rentGross / 1000) : 0}
                    suffix={t('general.thousand-suffix')}
                    prefix={t('general.sek-prefix')}
                  />
                </div>

                <div className={`${rowCellStyle}`}>
                  <FLCustom value={tab.vacancy} suffix={'%'} />
                </div>
                <div className={`${rowCellStyle} !bg-gray-50 dark:!bg-dark-faded`}>
                  <FLCustom
                    value={tab.area ? Math.round(tab.rentNet / 1000) : 0}
                    suffix={t('general.thousand-suffix')}
                    prefix={t('general.sek-prefix')}
                  />
                </div>

                <div className={`${rowCellStyle}`}>
                  <FLCustom
                    value={Math.round(-tab.costs / 1000)}
                    suffix={t('general.thousand-suffix')}
                    prefix={t('general.sek-prefix')}
                  />
                </div>

                <div className={`${rowCellStyle}`}>
                  <FLCustom
                    value={Math.round(-tab.maintenance / 1000)}
                    suffix={t('general.thousand-suffix')}
                    prefix={t('general.sek-prefix')}
                  />
                </div>

                <div className={`${rowCellStyle}`}>
                  {tab.countPropertyTax ? (
                    <FLCustom
                      value={-Math.round(tab.tax / 1000)}
                      suffix={t('general.thousand-suffix')}
                      prefix={t('general.sek-prefix')}
                    />
                  ) : (
                    <div>–</div>
                  )}
                </div>

                <div className={`${rowCellStyle} !font-medium !bg-gray-50 dark:!bg-dark-faded`}>
                  <FLCustom
                    value={Math.round(tab.operatingNet / tab.area)}
                    suffix={t('general.kr-per-square-m-suffix')}
                  />
                </div>
                <div className={`${rowCellStyle} !font-medium !bg-gray-50 dark:!bg-dark-faded`}>
                  <FLCustom
                    value={Math.round(tab.operatingNet / 1000)}
                    suffix={t('general.thousand-suffix')}
                    prefix={t('general.sek-prefix')}
                  />
                </div>

                <div className={`${rowCellStyle}`}>
                  <FLCustom value={tab.yield} suffix={'%'} />
                </div>

                <div className={`${rowCellStyle} !font-medium !bg-gray-50 dark:!bg-dark-faded`}>
                  <FLCustom
                    value={Math.round(tab.valueTotal / tab.area)}
                    suffix={t('general.kr-per-square-m-suffix')}
                  />
                </div>
                <div className={`${rowCellStyle} !font-medium !bg-gray-50 dark:!bg-dark-faded`}>
                  <FLCustom
                    value={Math.round(tab.valueTotal / 1000)}
                    suffix={t('general.thousand-suffix')}
                    prefix={t('general.sek-prefix')}
                  />
                </div>

                <div className="absolute -right-7 h-full flex items-center">
                  <DeleteTabBtn tabName={tab.tabName} deleteTab={deleteTab} inSummary={true} />
                </div>
              </div>
            );
          })}
        </>
      ) : null}

      {summaryData ? (
        <div className="grid grid-cols-12 justify-between border-x border-b border-gray-100 dark:border-dark-morefaded">
          <div className={`${rowCellStyle} !font-medium`}>
            <div>{t('value-calculator.lease-fee')}</div>

            {object?.landLeaseAgreement?.lease_fee ? (
              <Switch
                checked={countLeaseFee}
                onChange={(value) => {
                  changeLeaseFee(value);
                }}
                className={cls(
                  'relative inline-flex mt-1 h-[17px] w-[34px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75',
                  {
                    'bg-bobo-prop dark:bg-dark-prop': countLeaseFee,
                    'bg-gray-300 dark:bg-dark-morefaded': !countLeaseFee
                  }
                )}>
                <span
                  aria-hidden="true"
                  className={cls(
                    'pointer-events-none inline-block h-[14px] w-[14px] transform rounded-full bg-white dark:bg-dark-primary shadow-lg ring-0 transition duration-200 ease-in-out',
                    { 'translate-x-4': countLeaseFee, 'translate-x-0': !countLeaseFee }
                  )}
                />
              </Switch>
            ) : null}
          </div>

          <div className={`${rowCellStyle}`} />

          <div className={`${rowCellStyle}`} />
          <div className={`${rowCellStyle} !bg-gray-50 dark:!bg-dark-faded`} />

          <div className={`${rowCellStyle}`}>
            {object?.landLeaseAgreement?.lease_fee && countLeaseFee ? (
              <FLCustom
                value={Math.round(-object?.landLeaseAgreement?.lease_fee / 1000)}
                suffix={t('general.thousand-suffix')}
                prefix={t('general.sek-prefix')}
              />
            ) : (
              <div>–</div>
            )}
          </div>

          <div className={`${rowCellStyle}`} />
          <div className={`${rowCellStyle}`} />

          <div className={`${rowCellStyle} !bg-gray-50 dark:!bg-dark-faded`} />
          <div className={`${rowCellStyle} !bg-gray-50 dark:!bg-dark-faded`} />

          <div className={`${rowCellStyle}`}>
            {object?.landLeaseAgreement?.lease_fee && countLeaseFee ? (
              <FLCustom value={summaryData?.avgYieldForLandLeaseCost} suffix={'%'} />
            ) : (
              <div>–</div>
            )}
          </div>

          <div className={`${rowCellStyle} !bg-gray-50 dark:!bg-dark-faded`} />
          <div className={`${rowCellStyle} !font-medium !bg-gray-50 dark:!bg-dark-faded`}>
            {object?.landLeaseAgreement?.lease_fee && countLeaseFee ? (
              <FLCustom
                value={Math.round(-summaryData.landLeaseCostWithYield / 1000) ?? 0}
                suffix={t('general.thousand-suffix')}
                prefix={t('general.sek-prefix')}
              />
            ) : (
              <div>–</div>
            )}
          </div>
        </div>
      ) : null}

      {summaryData ? (
        <div className="grid grid-cols-12 justify-between border-x border-b border-gray-100 dark:border-dark-morefaded">
          <div className={`${rowCellStyle} !font-medium !bg-gray-50  dark:!bg-dark-faded`}>
            <div>{t('general.total')}</div>
            <div className="text-gray-400">
              <FLSqm value={summaryData?.totalArea} />
            </div>
          </div>
          <div className={`${rowCellStyle} !font-medium !bg-gray-50  dark:!bg-dark-faded`}>
            <FLCustom
              value={Math.round(summaryData?.totalRentGross / 1000)}
              suffix={t('general.thousand-suffix')}
              prefix={t('general.sek-prefix')}
            />
          </div>

          <div className={`${rowCellStyle} !font-medium !bg-gray-50  dark:!bg-dark-faded`} />
          <div className={`${rowCellStyle} !font-medium !bg-gray-50  dark:!bg-dark-faded`}>
            <FLCustom
              value={Math.round(summaryData?.totalRentNet / 1000)}
              suffix={t('general.thousand-suffix')}
              prefix={t('general.sek-prefix')}
            />
          </div>

          <div className={`${rowCellStyle} !font-medium !bg-gray-50  dark:!bg-dark-faded`}>
            <FLCustom
              value={Math.round(-summaryData?.totalCosts / 1000)}
              suffix={t('general.thousand-suffix')}
              prefix={t('general.sek-prefix')}
            />
          </div>

          <div className={`${rowCellStyle} !font-medium !bg-gray-50  dark:!bg-dark-faded`}>
            <FLCustom
              value={Math.round(-summaryData?.totalMaintenance / 1000)}
              suffix={t('general.thousand-suffix')}
              prefix={t('general.sek-prefix')}
            />
          </div>

          <div className={`${rowCellStyle} !font-medium !bg-gray-50  dark:!bg-dark-faded`}>
            <FLCustom
              value={-summaryData?.totalTax}
              suffix={t('general.thousand-suffix')}
              prefix={t('general.sek-prefix')}
            />
          </div>

          <div className={`${rowCellStyle} !font-medium !bg-gray-50  dark:!bg-dark-faded`}>
            <FLCustom
              value={summaryData?.totalArea > 0 ? summaryData?.totalOperatingNetSqm : 0}
              suffix={t('general.kr-per-square-m-suffix')}
            />
          </div>
          <div className={`${rowCellStyle} !font-medium !bg-gray-50  dark:!bg-dark-faded`}>
            <FLCustom
              value={Math.round(summaryData?.totalOperatingNet / 1000)}
              suffix={t('general.thousand-suffix')}
              prefix={t('general.sek-prefix')}
            />
          </div>

          <div className={`${rowCellStyle} !font-medium !bg-gray-50  dark:!bg-dark-faded`}>
            <FLCustom value={summaryData?.totalYield} suffix={'%'} />
          </div>

          <div className={`${rowCellStyle} !font-medium !bg-gray-50 dark:!bg-dark-faded`}>
            <FLCustom
              value={Math.round(summaryData?.totalArea > 0 ? summaryData.totalValueSqm : 0)}
              suffix={t('general.kr-per-square-m-suffix')}
            />
          </div>
          <div className={`${rowCellStyle} !font-medium !bg-gray-50  dark:!bg-dark-faded`}>
            <FLCustom
              value={Math.round(summaryData.totalValue / 1000)}
              suffix={t('general.thousand-suffix')}
              prefix={t('general.sek-prefix')}
            />
          </div>
        </div>
      ) : null}
    </>
  );
}
