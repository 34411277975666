import { PlusIcon, XMarkIcon } from '@heroicons/react/24/solid';
import cls from 'classnames';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCategoryName } from 'shared/dist/constants';
import {
  RadioButtonOptions,
  TaxCategory,
  ValueCalculation,
  ValueCalculationData,
  ValueCategoryTypes
} from 'shared/dist/types';
import { PropertyValuationDefaultValues } from 'shared/dist/types/cashflow/cashflow-model';

import { Building } from '@/src/animations';
import { useBreakpoint } from '@/src/hooks/use-breakpoint';
import DropdownMenu from '@/src/layout/components/dropdown-menu';
import DataExportDropdown from '@/src/widgets/data-export/dataExportDropdown';
import InfoButton from '@/src/widgets/info-button';
import { PropWarning } from '@/src/widgets/prop-labels';

import { crmDialogTheme } from '../dialogs/crm/dialog-theme';
import { CalculationTab } from './calculation-tab';
import { DeleteCalculationBtn } from './items/buttons/delete-calculation-btn';
import { SaveCalculationBtn } from './items/buttons/save-calculation-btn';
import { excelHeaders } from './items/constants';
import {
  createExportableData,
  createTabArrayOnPropTypes,
  getDefaultValues,
  getNewArea,
  getNewPropertyTax,
  getNewValue,
  getSummaryData,
  setTabCategory
} from './items/functions';
import { SummaryBanner } from './items/summary-banner';
import { SummaryTab } from './summary-tab';

export function ValuationTool({ object, close }: { object: any; close: any }) {
  const { t } = useTranslation();
  const [defaultValues, setDefaultValues] = useState<PropertyValuationDefaultValues | null>(null);
  const [summaryData, setSummaryData] = useState<any | null>(null);
  const [savedCalculation, setSavedCalculation] = useState<ValueCalculation | null>(null);
  const [savedCalculations, setSavedCalculations] = useState<any[]>([]);

  const [tabs, setTabs] = useState<string[]>([t('value-calculator.summary')]);
  const [selectedTab, setSelectedTab] = useState<string>(t('value-calculator.summary'));

  const [totalResult, setTotalResult] = useState<any[]>([]);

  const { isLg } = useBreakpoint({ breakpoint: 'lg' });
  const isMobileOrTabletPortrait = !isLg;

  useEffect(() => {
    if (!object) {
      return;
    }
    const tmpDefaultValues = getDefaultValues(object, t);
    const tabArray = createTabArrayOnPropTypes(object);
    setTabs([t('value-calculator.summary')].concat(tabArray));
    setDefaultValues(tmpDefaultValues);
  }, [object]);

  useEffect(() => {
    if (tabs.length > 1) {
      const tmpTotalResult = tabs
        .filter((tab) => tab !== t('value-calculator.summary'))
        ?.map((tab) => {
          return setTabCategory(tab, defaultValues, object);
        });
      setTotalResult(tmpTotalResult);
    }
  }, [defaultValues]);

  const revertToDefault = () => {
    setSelectedTab(t('value-calculator.summary'));
    const tabArray = createTabArrayOnPropTypes(object);
    setTabs([t('value-calculator.summary')].concat(tabArray));

    if (tabArray.length > 0) {
      const tmpTotalResult = tabArray?.map((tab) => setTabCategory(tab, defaultValues, object));
      setTotalResult(tmpTotalResult);
    }
  };

  const createNewTab = () => {
    let newTabName = `Kalkyl ${tabs?.length}`;
    while (tabs.includes(newTabName)) {
      newTabName = `Kalkyl ${tabs?.length + 1}`;
    }

    const newTabArray: string[] = [...tabs, newTabName];

    const newTab = setTabCategory('other', defaultValues, object, newTabName);

    setTabs(newTabArray);
    setTotalResult([...totalResult, newTab]);
    setSelectedTab(newTabName);
  };

  const changeTabName = (tabName: string, newTabName: string) => {
    let tmpName = newTabName;
    if (tabs.includes(newTabName)) {
      const sameNameNumber: number = tabs.filter((tab) => tab === newTabName).length;
      tmpName = `${tmpName} ${sameNameNumber + 1}`;
    }

    const tabIndex: number = tabs.indexOf(tabName);
    const tabArray = tabs;
    if (tabIndex !== -1) {
      tabArray[tabIndex] = tmpName;
    }

    const newTotalResult = totalResult?.map((tab) => {
      if (tab.tabName !== tabName) {
        return tab;
      } else {
        return { ...tab, tabName: tmpName };
      }
    });

    setTotalResult(newTotalResult);
    setTabs(tabArray);
    setSelectedTab(tmpName);
  };

  const deleteTab = (tabName: string) => {
    const newTotalResult = totalResult?.filter((tab) => tab.tabName !== tabName);
    const newTabArray = tabs?.filter((tab) => tab !== tabName);
    if (selectedTab === tabName) {
      setSelectedTab(t('value-calculator.summary'));
    }
    setTotalResult(newTotalResult);
    setTabs(newTabArray);
  };

  const setNewCategory = (propType: string) => {
    let tabName: string = propType;
    let count = 1;
    while (tabs.includes(tabName) && selectedTab !== tabName) {
      tabName = `${t(getCategoryName(propType))} ${
        tabs?.filter((tab) => tab === tabName).length + count
      }`;
      count += 1;
    }

    const newTotalResult = totalResult?.map((tab) => {
      if (tab.tabName !== selectedTab) {
        return tab;
      } else {
        const newTabData = setTabCategory(propType, defaultValues, object, tabName);
        return { ...newTabData, leaseFee: tab.countLeaseFee };
      }
    });
    const newTabs = tabs?.map((tab) => {
      if (tab !== selectedTab) {
        return tab;
      } else {
        return tabName;
      }
    });

    setTotalResult(newTotalResult);
    setTabs(newTabs);
    setSelectedTab(tabName);
  };

  const changeValue = (
    tabName: string,
    valueType: ValueCategoryTypes,
    newValue: number,
    radioButton?: RadioButtonOptions,
    newsecCategory?: string,
    flCategory?: string,
    forumCategory?: string
  ) => {
    const newTotalResult = getNewValue(
      tabName,
      valueType,
      newValue,
      totalResult,
      radioButton,
      newsecCategory,
      flCategory,
      forumCategory
    );
    setTotalResult(newTotalResult);
  };

  const changeArea = (tabName: string, newArea: number) => {
    const newTotalResult = getNewArea(tabName, newArea, totalResult);
    setTotalResult(newTotalResult);
  };

  const changePropertyTax = (tabName: string, taxType: TaxCategory, taxValue: number) => {
    const newTotalResult = getNewPropertyTax(tabName, taxType, taxValue, totalResult);
    setTotalResult(newTotalResult);
  };

  const changeLeaseFee = (countLeaseFee: boolean) => {
    const newTotalResult = totalResult?.map((tab) => {
      return { ...tab, countLeaseFee: countLeaseFee };
    });
    setTotalResult(newTotalResult);
  };

  useEffect(() => {
    if (totalResult) {
      const tmpSummaryData = getSummaryData(totalResult, object);
      setSummaryData(tmpSummaryData);
    }
  }, [totalResult]);

  const getMaintenanceValue = (tab: ValueCalculationData): number => {
    const maintenance =
      tab.values.find((value) => value.category === ValueCategoryTypes.MAINTENANCE)?.value ?? 0;
    const area = tab.area;
    return maintenance * area;
  };

  const adjustExistingValues = (calculation: ValueCalculation): ValueCalculation => {
    const adjustedData = calculation.data.map((tab) => {
      const adjustedResult = tab.result[ValueCategoryTypes.MAINTENANCE]
        ? tab.result
        : {
            ...tab.result,
            [ValueCategoryTypes.MAINTENANCE]: getMaintenanceValue(tab)
          };
      const adjustedSliderValues = tab.values.map((value) => {
        if (value.selectedForumCategory) {
          return value;
        }
        return { ...value, selectedForumCategory: value.selectedNewsecCategory };
      });
      return { ...tab, values: adjustedSliderValues, result: adjustedResult };
    });
    return { ...calculation, data: adjustedData };
  };

  const loadSavedCalculation = (calculation: ValueCalculation) => {
    if (calculation?.uuid !== savedCalculation?.uuid) {
      const tabArray = calculation?.data?.map((tab) => {
        return tab?.tabName;
      });
      if (tabArray.filter((tab) => tab === selectedTab)?.length === 0) {
        setSelectedTab(t('value-calculator.summary'));
      }
      const adjustedCalculation = adjustExistingValues(calculation);
      console.log(adjustedCalculation);
      setTabs([t('value-calculator.summary')].concat(tabArray));
      setSavedCalculation(adjustedCalculation);
      setTotalResult(adjustedCalculation.data);
    }
  };

  const renderSavedCalculations = (closeMenu: () => void) =>
    savedCalculations.map((calc: ValueCalculation, idx) => {
      return (
        <div
          key={idx}
          className={cls(
            'w-full py-1 px-2 transition-colors duration-200 hover:bg-bobo-proplight dark:hover:bg-dark-prophover text-bobo-prop dark:text-white hover:text-bobo-prophover text-xs',
            {
              '!bg-bobo-prop dark:!bg-dark-prop !text-white':
                savedCalculation && savedCalculation?.uuid === calc?.uuid
            }
          )}>
          <button
            onClick={() => {
              loadSavedCalculation(calc);
              closeMenu();
            }}
            className="disabled:text-gray-300 w-full h-full text-left">
            {calc.name}
          </button>
        </div>
      );
    });

  return (
    <div className="w-full px-4 lg:pb-16">
      <button
        autoFocus={true}
        onClick={() => close()}
        className={`${crmDialogTheme.closeContainerX} !right-3 !top-3`}>
        <XMarkIcon className="h-5 w-5" />
      </button>
      {defaultValues ? (
        <>
          <div className="w-full flex justify-between items-end leading-8 text-gray-900 dark:text-gray-100 mb-12 mt-20 lg:mt-4">
            <div className="flex flex-col items-start">
              <div className="text-xl lg:text-2xl font-bold leading-5 uppercase flex gap-2">
                <div>{t('general.valuation-tool')}</div>
                <InfoButton helpTextId="valuationToolDesc" />
              </div>
              <div className="text-gray-400 text-xs font-medium">{object.authority_nickname}</div>

              {!isMobileOrTabletPortrait && (
                <DropdownMenu
                  title={
                    savedCalculation
                      ? savedCalculation?.name
                      : savedCalculations?.length > 0
                        ? t('value-calculator.saved-calculations')
                        : t('value-calculator.no-saved-calculations')
                  }
                  renderMenuItems={renderSavedCalculations}
                  className="mt-2"
                  disabled={savedCalculations?.length === 0}
                />
              )}
            </div>

            {summaryData && !isMobileOrTabletPortrait ? (
              <SummaryBanner summaryData={summaryData} />
            ) : null}
          </div>

          {isMobileOrTabletPortrait ? (
            <PropWarning>
              <div className="py-2">
                {t('general.no-mobile-warning', { component: t('general.valuation-tool-the') })}
              </div>
            </PropWarning>
          ) : (
            <>
              <div className="flex justify-between items-end w-full">
                <div className="w-full flex items-end justify-start text-sm font-medium">
                  {tabs
                    ? tabs?.map((tab, idx) => {
                        return (
                          <div
                            key={idx}
                            role="button"
                            tabIndex={idx}
                            onClick={() => setSelectedTab(tab)}
                            onKeyDown={() => setSelectedTab(tab)}
                            className={cls(
                              'h-11 rounded-t-lg shadow-[0px_0px_2px_rgba(0,0,0,0.1)] py-3 px-4 flex items-center overflow-hidden relative transition-colors duration-200 z-10',
                              selectedTab === tab
                                ? 'bg-gray-100 dark:bg-dark-lessfaded cursor-default'
                                : 'text-gray-600 dark:text-gray-400 bg-gray-50 dark:bg-dark-faded hover:bg-bobo-light dark:hover:bg-dark-prophover dark:hover:!text-white cursor-pointer'
                            )}>
                            {t(getCategoryName(tab)).length > 0 ? t(getCategoryName(tab)) : tab}
                          </div>
                        );
                      })
                    : null}

                  <button
                    onClick={createNewTab}
                    className="h-11 hover:bg-bobo-light dark:hover:bg-dark-prophover dark:text-dark-prop dark:bg-dark-faded dark:hover:text-white cursor-pointer rounded-t-lg shadow-[0px_0px_3px_rgba(0,0,0,0.1)] py-3 px-4 text-gray-600 bg-gray-50 flex items-center overflow-hidden transition-colors duration-200">
                    <PlusIcon className="w-5 h-5" />
                  </button>
                </div>

                <div className="mb-2">
                  <DataExportDropdown
                    data={totalResult?.length > 0 ? createExportableData(summaryData, t) : []}
                    tableHeaders={excelHeaders}
                    fileName={`${t('general.value-calculation')}: ${object?.authority_nickname}${
                      savedCalculation ? ` - ${savedCalculation.name}` : ''
                    } - ${moment().format(`YYYYMMDD`)}`}
                  />
                </div>
              </div>

              <div className="w-full rounded-tr-md">
                {selectedTab && selectedTab === t('value-calculator.summary') ? (
                  <SummaryTab
                    object={object}
                    summaryData={summaryData}
                    deleteTab={deleteTab}
                    changeLeaseFee={changeLeaseFee}
                  />
                ) : (
                  <CalculationTab
                    defaultValues={defaultValues}
                    totalResult={totalResult}
                    setTotalResult={setTotalResult}
                    changeValue={changeValue}
                    changeArea={changeArea}
                    changePropertyTax={changePropertyTax}
                    selectedTab={selectedTab}
                    setNewCategory={setNewCategory}
                    deleteTab={deleteTab}
                    changeTabName={changeTabName}
                    property={object}
                  />
                )}
              </div>

              <div className="flex gap-2 absolute right-9 bottom-5">
                <DeleteCalculationBtn
                  savedCalculation={savedCalculation}
                  setSavedCalculation={setSavedCalculation}
                  savedCalculations={savedCalculations}
                  setSavedCalculations={setSavedCalculations}
                  revertToDefault={revertToDefault}
                />
                <SaveCalculationBtn
                  totalResult={totalResult}
                  object={object}
                  setSavedCalculation={setSavedCalculation}
                  setSavedCalculations={setSavedCalculations}
                  savedCalculation={savedCalculation}
                  savedCalculations={savedCalculations}
                />
              </div>
            </>
          )}
        </>
      ) : (
        <div>
          <Building />
        </div>
      )}
    </div>
  );
}
