import cls from 'classnames';
import { useTranslation } from 'next-i18next';

import { Calculator01Icon } from '@/src/assets/icons';
import { useDialog } from '@/src/context/dialog-context';

import MenuButton from './menu-button';

export default function ValuationToolBtn({
  property,
  iconOnly = false,
  closeSlideIn
}: {
  property: any;
  iconOnly?: boolean;
  closeSlideIn?: () => void;
}) {
  const { setDialog } = useDialog();
  const { t } = useTranslation('common');

  return (
    <MenuButton
      handleClick={(event) => {
        event.stopPropagation();
        closeSlideIn && closeSlideIn();
        setDialog({
          dialogType: 'value-spinner',
          object: property,
          closeableByOutsideClick: false
        });
      }}
      icon={
        <Calculator01Icon
          className={cls(
            'group-hover:text-bobo-texthover transition-colors duration-200 dark:text-white h-5 w-5 text-bobo-black cursor-pointer',
            {
              iconOnly: 'text-gray-600 dark:text-white'
            }
          )}
        />
      }
      label={t('general.valuation-tool')}
      tooltip={t('general.valuation-tool')}
      iconOnly={iconOnly}
    />
  );
}
