import moment from 'moment';
import { useTranslation } from 'next-i18next';
import React from 'react';
import NumberFormat from 'react-number-format';
import { CashflowValuationModel } from 'shared/dist/types/cashflow/cashflow-model';

import { useCashflowModelContext } from '@/src/hooks/use-cashflow-context';
import RowWrapper from '@/src/layout/row-wrapper';
import { RowTableWrapper } from '@/src/widgets/row-table-body';
import SectionHeader from '@/src/widgets/section-header';

import { calculateYearDifference } from '../../constants/calculations';

export const getOverviewData = (model: CashflowValuationModel) => {
  const discountRate =
    model?.assets.length === 0
      ? 0
      : model.assets.reduce(
          (acc, asset) => {
            const value = asset.valuation?.amount ?? 0;
            const rate = asset.calculatedAssumptions.discountRate ?? 0;
            return {
              weightedSum: acc.weightedSum + value * rate,
              totalValue: acc.totalValue + value
            };
          },
          { weightedSum: 0, totalValue: 0 }
        );

  const weightedDiscountRate =
    discountRate && discountRate.totalValue !== 0
      ? discountRate.weightedSum / discountRate.totalValue
      : 0;

  const growthRate =
    model?.assets.length === 0
      ? 0
      : model.assets.reduce((acc, asset) => acc + asset.calculatedAssumptions.growthRate, 0) /
        model.assets.length;
  const area = model.assets.reduce((acc, asset) => acc + asset.adjustableAssumptions.area, 0);
  return { area: area, discountRate: weightedDiscountRate, growthRate: growthRate };
};

function OverviewTable() {
  const { t } = useTranslation();
  const { selectedModel } = useCashflowModelContext();

  if (!selectedModel) {
    return null;
  }

  const numbers = getOverviewData(selectedModel);
  const area = numbers.area;
  const discountRate = numbers.discountRate;
  const growthRate = numbers.growthRate;

  const modelValue = selectedModel.valuation?.amount;

  return (
    <div className="w-full">
      <RowTableWrapper>
        <SectionHeader title={t('property.cashflow-valuation.overview.header')} />

        <div>
          <RowWrapper title={t('property.property-nickname')} theme={'roomy'}>
            {selectedModel?.valuatedObject.name}
          </RowWrapper>

          <RowWrapper title={t('general.area')} theme={'roomy'}>
            <NumberFormat
              value={area?.toFixed(0)}
              displayType={'text'}
              thousandSeparator={` `}
              suffix={t('general.square-meters')}
            />
          </RowWrapper>

          <RowWrapper
            title={t('property.cashflow-valuation.overview.cards.valuation.title')}
            theme={'roomy'}>
            <NumberFormat
              value={modelValue ? (modelValue / 10 ** 3)?.toFixed(0) : 0}
              displayType={'text'}
              thousandSeparator={` `}
              suffix={t('general.thousand-suffix')}
            />
          </RowWrapper>

          <RowWrapper
            title={t('property.cashflow-valuation.overview.cards.value-per-sqm.title')}
            theme={'roomy'}>
            <NumberFormat
              value={modelValue && area ? (modelValue / area)?.toFixed(0) : 0}
              displayType={'text'}
              thousandSeparator={` `}
              suffix={t('general.kr-per-square-m-suffix')}
            />
          </RowWrapper>

          <RowWrapper title={t('property.cashflow-valuation.discount-rate')} theme={'roomy'}>
            <NumberFormat
              value={+(discountRate * 100).toFixed(2)}
              displayType={'text'}
              suffix={'%'}
            />
          </RowWrapper>

          <RowWrapper title={t('property.cashflow-valuation.growth-rate')} theme={'roomy'}>
            <NumberFormat
              value={+(growthRate * 100).toFixed(2)}
              displayType={'text'}
              suffix={'%'}
            />
          </RowWrapper>

          <RowWrapper
            title={t('property.cashflow-valuation.settings.model-length')}
            theme={'roomy'}>
            <NumberFormat
              value={calculateYearDifference(
                selectedModel.modelLength.startDate,
                selectedModel.modelLength.endDate
              )}
              displayType={'text'}
              suffix={t('units.year-suffix')}
            />
          </RowWrapper>

          <RowWrapper title={t('general.last-updated')} theme={'roomy'}>
            {moment.unix(selectedModel.updatedAt).format('YYYY-MM-DD')}
          </RowWrapper>

          <RowWrapper title={t('itismad.table.date-created')} theme={'roomy'}>
            {moment.unix(selectedModel.createdAt).format('YYYY-MM-DD')}
          </RowWrapper>
        </div>
      </RowTableWrapper>
    </div>
  );
}

export default OverviewTable;
