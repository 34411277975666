import { sourcesConfig } from './config';

export default async function loadSources(map: any, layers: string[]) {
  const districtSource = `mapbox://bobolaget.dofiwhn7`;
  const countySource = `mapbox://bobolaget.60jr9vre`;
  const propSource = `mapbox://bobolaget.newprop2`;
  const buildingSource = `mapbox://bobolaget.66imyyr8`;
  const disasterSource = `mapbox://bobolaget.1c4qm4uh`;

  const ebhSource: any = sourcesConfig.soil_pollution.url;
  const lamningarSource = `mapbox://bobolaget.lamningar-yta`;
  const lamningarPointSource = `mapbox://bobolaget.lamningar-v2-point`;

  const floodingLayer = sourcesConfig.flood_100years;
  const chfLayer = sourcesConfig.flood_chf;
  const flooding1mLayer = sourcesConfig.flood_1meter;
  const flooding3mLayer = sourcesConfig.flood_3meter;
  const flooding5mLayer = sourcesConfig.flood_5meter;

  const detailPlan = sourcesConfig.detailed_plans.url;
  const detailDecisions = detailPlan;
  const powerGrid = sourcesConfig.power_grid.url;
  const transformers = sourcesConfig.transformers.url;

  const rentSource = sourcesConfig.rent_housing.url;
  const rentSourceSpaces = sourcesConfig.rent_spaces.url;

  layers?.includes('districts') &&
    !map?.getMap()?.getSource('districts') &&
    map?.getMap()?.addSource('districts', {
      type: 'vector',
      url: districtSource,
      promoteId: { 'districts_new-9lzmwp': 'distriktskod' }
    });

  layers?.includes('counties') &&
    !map?.getMap()?.getSource('counties') &&
    map?.getMap()?.addSource('counties', {
      type: 'vector',
      url: countySource,
      maxzoom: 8,
      promoteId: { kommunytaid: 'kommunkod' }
    });

  layers?.includes('rent') &&
    !map?.getMap()?.getSource('rent') &&
    map?.getMap()?.addSource('rent', {
      type: 'vector',
      url: rentSource,
      promoteId: { rent: 'property_uuid' }
    });

  layers?.includes('rent-spaces') &&
    !map?.getMap()?.getSource('rent-spaces') &&
    map?.getMap()?.addSource('rent-spaces', {
      type: 'vector',
      url: rentSourceSpaces,
      promoteId: { rent: 'property_uuid' }
    });

  layers?.includes('plans') &&
    !map?.getMap()?.getSource('plans') &&
    map?.getMap()?.addSource('plans', {
      type: 'vector',
      url: detailPlan,
      promoteId: { plan_yta: 'objekt_id' }
    });

  layers?.includes('decisions') &&
    !map?.getMap()?.getSource('decisions') &&
    map?.getMap()?.addSource('decisions', {
      type: 'vector',
      url: detailDecisions,
      promoteId: { bestammelse_yta: 'objekt_id' }
    });

  layers?.includes('powergrid') &&
    !map?.getMap()?.getSource('powergrid') &&
    map?.getMap()?.addSource('powergrid', {
      type: 'vector',
      url: powerGrid,
      promoteId: { 'ledningslinje-csfbj0': 'objekt_id' }
    });

  layers?.includes('transformers') &&
    !map?.getMap()?.getSource('transformers') &&
    map?.getMap()?.addSource('transformers', {
      type: 'vector',
      url: transformers,
      promoteId: { 'transformatoromrade-ac90nc': 'objektidentitet' }
    });

  layers?.includes('properties') &&
    !map?.getMap()?.getSource('properties') &&
    map?.getMap()?.addSource('properties', {
      type: 'vector',
      url: propSource,
      minzoom: 10,
      maxzoom: 20,
      promoteId: { properties: 'uuid' }
    });

  layers?.includes('buildings') &&
    !map?.getMap()?.getSource('buildings') &&
    map?.getMap()?.addSource('buildings', {
      type: 'vector',
      url: buildingSource,
      minzoom: 12,
      maxzoom: 20,
      promoteId: { buildings: 'property_uuid' }
    });

  layers?.includes('natural-disasters') &&
    !map?.getMap()?.getSource('natural-disasters') &&
    map?.getMap()?.addSource('natural-disasters', {
      type: 'vector',
      url: disasterSource,
      promoteId: { 'hhEventNaturalDisasters-8evcvn': 'localId' }
    });

  layers?.includes('ebh') &&
    !map?.getMap()?.getSource('ebh') &&
    map?.getMap()?.addSource('ebh', {
      type: 'vector',
      url: ebhSource,
      promoteId: { 'EBH_Potentiellt_fororenade_om-d2uvef': 'EBH_ID' }
    });

  if (layers?.includes('lamningar')) {
    !map?.getMap()?.getSource('lamningar') &&
      map?.getMap()?.addSource('lamningar', {
        type: 'vector',
        url: lamningarSource,
        promoteId: { 'Lamningar yta': 'id' }
      });

    !map?.getMap()?.getSource('lamningar-point') &&
      map?.getMap()?.addSource('lamningar-point', {
        type: 'vector',
        url: lamningarPointSource,
        promoteId: { 'Lamningar point': 'id' }
      });
  }

  layers?.includes('flooding') &&
    !map?.getMap()?.getSource('flooding') &&
    map?.getMap()?.addSource('flooding', floodingLayer);

  layers?.includes('bhf') &&
    !map?.getMap()?.getSource('bhf') &&
    map?.getMap()?.addSource('bhf', chfLayer);

  layers?.includes('c1') &&
    !map?.getMap()?.getSource('c1') &&
    map?.getMap()?.addSource('c1', flooding1mLayer);

  layers?.includes('c3') &&
    !map?.getMap()?.getSource('c3') &&
    map?.getMap()?.addSource('c3', flooding3mLayer);

  layers?.includes('c5') &&
    !map?.getMap()?.getSource('c5') &&
    map?.getMap()?.addSource('c5', flooding5mLayer);

  layers?.includes('skyddsrum') &&
    !map?.getMap()?.getSource('skyddsrum') &&
    map?.getMap()?.addSource('skyddsrum', sourcesConfig.bombshelters);
}
