import {
  ArrowLeftIcon,
  ArrowsRightLeftIcon,
  ChevronRightIcon,
  XMarkIcon
} from '@heroicons/react/24/solid';
import cls from 'classnames';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { PropertyTypeGroup } from 'shared/dist/types';
import { getPrimaryTaxCodeBasedOnBuildingSize } from 'shared/dist/util/calculations';
import tabs from 'src/layout/tabs';
import { getCRMBoards } from 'src/lib/user-client-wrapper';

import { useBreakpoint } from '@/src/hooks/use-breakpoint';
import CrmButton from '@/src/widgets/menu-options/crm-button';
import ReportsBtn from '@/src/widgets/menu-options/reports-button';
import { PropLabels } from '@/src/widgets/prop-labels';

export default function TabMenu({
  title,
  section,
  titleRight,
  switchUrl,
  tabsRight,
  hideTabMenu = false,
  backUrl = null,
  mobileDropdown = true,
  subMenu,
  object
}: {
  title: any;
  section?: string | JSX.Element;
  image: string;
  switchUrl?: string;
  titleRight?: JSX.Element | JSX.Element[] | string;
  tabsRight?: JSX.Element | JSX.Element[] | null;
  mobileDropdown?: boolean;
  backUrl?: string | null;
  hideTabMenu?: boolean;
  reduceSecondaryRow: boolean;
  subMenu?: JSX.Element | null;
  object?: any;
}) {
  const router = useRouter();
  const { t } = useTranslation('common');

  const { isLg } = useBreakpoint({ breakpoint: 'lg' });
  const isMobileOrTabletPortrait = !isLg;

  const idx = router?.query && router?.query?.index;
  const uuid = idx && idx?.length > 0 ? idx[0] : null;
  const second = idx && idx?.length > 1 ? idx[1] : null;
  const third = idx && idx?.length > 2 ? idx[2] : null;

  const [crmIncrement] = useGlobal('crmIncrement');
  const [crmBoardsGlobal, setCRMBoardsGlobal] = useGlobal<any[]>('crmBoards' as never);
  const [hideSubMenu, setHideSubMenu] = useGlobal<any[]>('hideSubMenu' as never);

  const [savedSearches, setSavedSearches] = useState<any[]>([]);

  const [subMenuRefresh, setSubMenuRefresh] = useGlobal<any[]>('subMenuRefresh' as never);

  const newQuery: any = {};

  if (router.query.selectedActor) {
    newQuery.selectedActor = router.query.selectedActor;
  }

  if (router.query.selectedProperty) {
    newQuery.selectedProperty = router.query.selectedProperty;
  }

  const [primaryTabs, setPrimaryTabs] = useState<any[]>(
    tabs?.getPrimaryTabs(decodeURI(router?.asPath), uuid, second, third, t)
  );

  const fetchSavedSearches = async () => {
    await fetch(`/api/store/get-saved-filters`)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        setSavedSearches(json.savedSearches);
      });
  };

  useEffect(() => {
    fetchSavedSearches();
  }, [subMenuRefresh]);

  useEffect(() => {
    const newTabs = tabs?.getPrimaryTabs(decodeURI(router?.asPath), uuid, second, third, t);
    setPrimaryTabs(newTabs);
  }, [router?.asPath, uuid, second, third]);

  let selectedIdx = -1;
  let selectedPrimaryTitle: any = null;

  let selectedSidx = -1;
  let selectedSubTitle: any = null;

  const path: string = decodeURI(router?.asPath).toLowerCase();

  primaryTabs?.forEach((tab, idx) => {
    if (
      tab?.href?.toLowerCase() === path?.toLowerCase() ||
      tab?.items?.find((a) => a?.href?.toLowerCase() === path?.toLowerCase()) ||
      tab?.items?.find((a) => path?.toLowerCase()?.startsWith(a?.href?.toLowerCase()))
    ) {
      selectedIdx = idx;
      selectedPrimaryTitle = tab;

      if (tab.items) {
        tab.items.map((subTab, subIdx) => {
          if (subTab.href?.toLowerCase() === path?.toLowerCase()) {
            selectedSidx = subIdx;
            selectedSubTitle = subTab;
          }
        });
      }
    }
  });

  const withIconStyle = (IconComponent) => {
    return <IconComponent className="w-4 h-4 text-gray-800 dark:text-white" />;
  };

  function isViewingCRM() {
    return (
      decodeURI(router?.asPath).toLowerCase()?.startsWith('/crm') &&
      !decodeURI(router?.asPath).toLowerCase()?.startsWith('/crm/fastighet') &&
      router?.query?.index &&
      (router?.query?.index?.length === 1 || router?.query?.index?.length === 2)
    );
  }

  useEffect(() => {
    // if global crmIncrement changed -- fetch new boards
    if (crmIncrement && crmIncrement > 0 && isViewingCRM()) {
      getCRMBoards(0, 0).then((data) => {
        setCRMBoardsGlobal(data);
      });
    }
  }, [crmIncrement]);

  useEffect(() => {
    if (isViewingCRM()) {
      // updateCRMBoards();
    }
  }, [crmBoardsGlobal]);

  useEffect(() => {
    const tmp: any[] = tabs?.getPrimaryTabs(decodeURI(router?.asPath), uuid, second, third, t);

    if (isViewingCRM()) {
      // updateCRMBoards();
    } else {
      setPrimaryTabs(tmp);
    }
  }, [router?.query?.index]);

  primaryTabs?.forEach((tab, idx) => {
    if (
      tab?.href?.toLowerCase() === path?.toLowerCase() ||
      tab?.items?.find((a) => a?.href?.toLowerCase() === path?.toLowerCase()) ||
      tab?.items?.find((a) => path?.toLowerCase()?.startsWith(a?.href?.toLowerCase()))
    ) {
      selectedIdx = idx;
    }
  });

  const primaryType: PropertyTypeGroup = getPrimaryTaxCodeBasedOnBuildingSize(object?.propTax);

  const relevantTab = (arr) => arr.some((a) => primaryType === a);

  return (
    <div
      className={cls(
        'w-full transition-all tracking-normal duration-200 lg:w-[230px] lg:min-w-[230px] pt-5 lg:mt-0 bg-gray-50 lg:bg-gray-100 pb-2 lg:pb-0 dark:bg-dark-lessfaded xl:z-0 lg:h-[100vh] overflow-y-auto relative px-3 border-r border-gray-200 dark:border-dark-morefaded',
        {
          '!pt-0 lg:pt-4 lg:!w-0 lg:!min-w-0 !h-0 lg:h-auto !px-0 overflow-hidden opacity-0':
            hideSubMenu && !isMobileOrTabletPortrait
        }
      )}>
      <div className={cls('m-auto w-full')}>
        <motion.div
          className={cls('items-center justify-between w-full', {
            '!w-full !items-center': hideTabMenu
          })}>
          <div className="flex items-center justify-between w-full">
            <div className={cls('h-[42px] lg:h-[62px] px-3 mt-2')}>
              <div className="font-demi !leading-4 !lg:leading-5 text-sm lg:text-base tracking-normal text-gray-900 dark:text-gray-100 flex items-start flex-col gap-1">
                {section}
                {switchUrl && (
                  <button
                    onClick={() => router.push(switchUrl)}
                    className="font-normal text-xs flex items-center transition-all text-gray-500 hover:text-bobo-prop dark:hover:text-dark-prophover duration-300">
                    <ArrowsRightLeftIcon className="h-3 w-3 mr-1" />
                    <div className="whitespace-nowrap">{t('submenu.change-lc')}</div>
                  </button>
                )}
              </div>

              {titleRight && (
                <h2 className="font-normal leading-5 !text-sm tracking-tight text-gray-600 dark:text-gray-400">
                  {titleRight}
                </h2>
              )}
            </div>

            {isMobileOrTabletPortrait && object ? (
              <div className="flex items-center justify-end gap-2">
                <CrmButton property={object} iconOnly />
                <ReportsBtn property={object} iconOnly />
              </div>
            ) : null}
          </div>
          {hideTabMenu ? <div className="h-full flex">{tabsRight}</div> : null}
        </motion.div>

        <div className="-mt-1">
          {object && !isMobileOrTabletPortrait ? (
            <div className="px-3">
              <PropLabels small={true} object={object} />
            </div>
          ) : null}

          {isMobileOrTabletPortrait && (
            <div
              className={cls(
                `text-smaller mt-3 max-h-0 transition-all overflow-hidden duration-300`,
                {
                  'max-h-[200px]': hideSubMenu
                }
              )}>
              <button
                onClick={() => {
                  setHideSubMenu(!hideSubMenu);
                }}
                className={cls(
                  `flex items-center text-smaller font-demi justify-between space-x-2 bg-gray-100 dark:bg-dark-faded p-3 px-3  dark:hover:bg-dark-faded rounded-md w-full text-left transition-colors duration-200`
                )}>
                <div>
                  {selectedSubTitle && selectedSubTitle.icon
                    ? withIconStyle(selectedSubTitle.icon)
                    : selectedPrimaryTitle && selectedPrimaryTitle.icon
                      ? withIconStyle(selectedPrimaryTitle.icon)
                      : null}
                </div>

                <div className="w-full text-md">
                  {selectedSubTitle ? (
                    <div className="flex justify-start space-x-1 items-center">
                      <div>{selectedPrimaryTitle?.name}</div>
                      <ChevronRightIcon className="w-4 h-4 text-gray-800 dark:text-gray-200" />{' '}
                      <div>{selectedSubTitle?.name}</div>
                    </div>
                  ) : (
                    (selectedPrimaryTitle?.name ?? title)
                  )}
                </div>

                <div>
                  {hideSubMenu ? (
                    <ChevronRightIcon
                      className={cls(
                        `w-4 h-4 transition-all duration-300 text-gray-800 dark:text-gray-100`
                      )}
                    />
                  ) : (
                    <XMarkIcon
                      className={cls(
                        `w-4 h-4 transition-all duration-300 text-gray-800 dark:text-gray-100`
                      )}
                    />
                  )}
                </div>
              </button>
            </div>
          )}

          <div
            className={cls(
              'mt-3 lg:mt-6 lg:w-full overflow-hidden transition-all duration-300',
              hideSubMenu && isMobileOrTabletPortrait ? 'max-h-0' : 'block max-h-[2000px]'
            )}>
            <div
              className={cls(
                'flex justify-between flex-col lg:flex-row items-start lg:items-end',
                !mobileDropdown ? 'flex-col lg:flex-row items-center lg:items-end' : ''
              )}>
              {backUrl ? (
                <button
                  className="flex text-gray-600 items-center p-1 hover:text-bobo-co -mt-2"
                  onClick={() => router.push(`${backUrl}`)}>
                  <ArrowLeftIcon className="w-4 h-4 mr-1" /> {t('general.back')}
                </button>
              ) : (
                <nav
                  className={cls(
                    'flex flex-col relative w-full'
                    // !mobileDropdown
                    //   ? 'lg:order-none lg:items-start order-2 items-center'
                    //   : 'sm:flex'
                  )}
                  aria-label="Tabs">
                  {primaryTabs?.map((tab, idx) => {
                    // See if we find a perfect match
                    //If not we use index as fallback
                    const selected = idx === selectedIdx;
                    const shallow = !!tab?.shallow;

                    return (
                      <div key={idx}>
                        <button
                          onClick={() => {
                            router.push(
                              {
                                pathname: tab.href,
                                query: Object.assign({}, newQuery, tab.query ?? {})
                              },
                              undefined,
                              { shallow }
                            );
                            if ((!tab.items || selected) && isMobileOrTabletPortrait) {
                              setHideSubMenu(true);
                            }
                          }}
                          className={cls(
                            `flex items-center text-smaller font-medium justify-between space-x-2 bg-transparent p-2 px-3 hover:bg-gray-200 dark:hover:bg-dark-faded rounded-md w-full text-left transition-colors duration-200 mb-0.5`,
                            {
                              'bg-white dark:bg-dark-morefaded font-demi': selected && !tab.items
                            }
                          )}
                          aria-current={tab?.href === router?.asPath ? 'page' : undefined}>
                          <div>{tab.icon && withIconStyle(tab.icon)}</div>
                          <div className="flex-1">{tab.name}</div>
                          <div>
                            {tab.items?.length > 0 ? (
                              <ChevronRightIcon
                                className={cls(
                                  `w-4 h-4 transition-all duration-300 text-gray-800 dark:text-gray-100`,
                                  {
                                    'rotate-90': selected
                                  }
                                )}
                              />
                            ) : null}
                          </div>
                        </button>

                        {tab.items?.length > 0 && (
                          <div
                            className={cls(
                              `overflow-hidden duration-500 transition-all`,
                              {
                                'max-h-[500px]': selected
                              },
                              {
                                'max-h-0': !selected
                              }
                            )}>
                            {tab.items.map((subItem, sidx) => {
                              const selectedSub = sidx === selectedSidx;

                              if (subItem?.only && !relevantTab(subItem?.only)) {
                                return;
                              }

                              return (
                                <button
                                  key={`${sidx}-sub`}
                                  onClick={() => {
                                    router.push(
                                      {
                                        pathname: subItem.href,
                                        query: Object.assign({}, newQuery, subItem.query ?? {})
                                      },
                                      undefined,
                                      { shallow }
                                    );

                                    if (isMobileOrTabletPortrait) {
                                      setHideSubMenu(true);
                                    }
                                  }}
                                  className={cls(
                                    `flex items-center text-smaller font-normal justify-between space-x-2 bg-transparent p-2 px-3 hover:bg-gray-200 dark:hover:bg-dark-faded rounded-md w-full text-left transition-colors duration-200 pl-[35px] mb-0.5`,
                                    {
                                      'bg-white font-demi dark:bg-dark-morefaded ': selectedSub
                                    }
                                  )}
                                  aria-current={
                                    subItem?.href === router?.asPath ? 'page' : undefined
                                  }>
                                  {subItem.name}
                                </button>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </nav>
              )}
            </div>
            <div>{subMenu}</div>
          </div>
        </div>
      </div>
      <div className="border-t border-gray-200 mt-8 pt-4 hidden">{tabsRight}</div>
    </div>
  );
}
