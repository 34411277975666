import { GotoFeature, PropertyTypeGroup } from 'shared/dist/types';

export default function powerGrid(
  map: any,
  object: any,
  propertyTypes?: PropertyTypeGroup[],
  setPopup?: any,
  selectedObject?: any,
  setSlideIn?: any
) {
  let hoveredStateId: string | null = null;
  // const fallBackColor = 'rgba(0, 0, 0, .2)';

  !map?.getMap()?.getLayer('power-line') &&
    map?.getMap()?.addLayer({
      id: 'power-line',
      type: 'line',
      source: 'powergrid', // reference the data source
      'source-layer': 'ledningslinje-csfbj0', // reference the data source
      layout: {},
      paint: {
        'line-color': 'rgba(146, 42, 91, 0.9)',
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'selected'], false],
          2,
          ['boolean', ['feature-state', 'hover'], false],
          2,
          4
        ],
        'line-dasharray': [2, 2],
        'line-opacity': [
          'case',
          //If selected
          ['boolean', ['feature-state', 'selected'], false],
          1,
          //If hover
          ['boolean', ['feature-state', 'hover'], false],
          0.6,
          //Fallback
          1
        ]
      }
    });

  !map.getMap()?.getLayer('grid-text') &&
    map?.getMap()?.addLayer({
      id: 'grid-text',
      type: 'symbol',
      source: 'powergrid',
      'source-layer': 'ledningslinje-csfbj0',
      layout: {
        'text-field': ['format', ['get', 'objekttyp']],
        'text-font': ['Open Sans Regular', 'Arial Unicode MS Bold'],
        'text-size': 11,
        'symbol-placement': 'line-center'
      },
      paint: {
        'text-color': 'rgba(0, 0, 0, 1)',
        'text-halo-color': 'rgba(255, 255, 255, .75)',
        'text-halo-width': 20
      }
    });

  !map.getMap()?.getLayer('transformer-text') &&
    map?.getMap()?.addLayer({
      id: 'transformer-text',
      type: 'symbol',
      source: 'transformers',
      'source-layer': 'transformatoromrade-ac90nc',
      layout: {
        'text-field': ['format', ['get', 'objekttyp']],
        'text-font': ['Open Sans Regular', 'Arial Unicode MS Bold'],
        'text-size': 11
      },
      paint: {
        'text-color': 'rgba(0, 0, 0, 1)',
        'text-halo-color': 'rgba(255, 255, 255, .75)',
        'text-halo-width': 20
      }
    });

  !map?.getMap()?.getLayer('transformers') &&
    map?.getMap()?.addLayer({
      id: 'transformers',
      type: 'fill-extrusion',
      source: 'transformers', // reference the data source
      'source-layer': 'transformatoromrade-ac90nc', // reference the data source
      layout: {},
      paint: {
        'fill-extrusion-color': 'rgba(146, 42, 91, 0.9)',
        'fill-extrusion-opacity': 0.85,
        'fill-extrusion-base': 0,
        'fill-extrusion-height': 100
      }
    });

  map?.getMap()?.on('mousemove', 'plan-area', (e) => {
    const idx = 0;
    if (e.features.length > 0 && e.features[idx].properties?.objekt_id !== hoveredStateId) {
      map.getMap().getCanvas().style.cursor = 'pointer';
      if (hoveredStateId !== null) {
        // const tmp: any = e.features[idx]?.properties;
        // tmp.title = `${e.features[idx]?.properties?.authority_nickname}`?.toLowerCase();
        // const c: any = centroid(e.features[idx]?.geometry);
        // tmp.lng = c?.geometry?.coordinates[0];
        // tmp.lat = c?.geometry?.coordinates[1];

        map?.getMap()?.setFeatureState(
          {
            source: 'plans',
            id: hoveredStateId,
            sourceLayer: 'plan_yta'
          },
          { hover: false }
        );
      }
      hoveredStateId = e.features[idx]?.properties?.objekt_id;

      map?.getMap()?.setFeatureState(
        {
          source: 'plans',
          id: hoveredStateId,
          sourceLayer: 'plan_yta'
        },
        { hover: true }
      );
    }
  });

  map?.getMap()?.on('mouseleave', 'plan-area', () => {
    map.getMap().getCanvas().style.cursor = 'default';
    map?.getMap()?.setFeatureState(
      {
        source: 'plans',
        id: hoveredStateId,
        sourceLayer: 'plan_yta'
      },
      { hover: false }
    );
    hoveredStateId = null;
  });

  const planAreaClick = (e) => {
    map?.getMap()?.removeFeatureState({
      source: 'plans',
      sourceLayer: 'plan_yta'
    });

    const details = e?.features?.map((a) => {
      map?.getMap()?.setFeatureState(
        {
          source: 'plans',
          id: a?.properties?.objekt_id,
          sourceLayer: 'plan_yta'
        },
        { selected: true }
      );
      return a;
    });

    setSlideIn({
      object: {
        details
      },
      type: GotoFeature.DETAIL_PLAN
    });
  };

  map?.getMap()?.on('click', 'plan-area', planAreaClick);
  map?.getMap()?.on('touchend', 'plan-area', planAreaClick);
}
