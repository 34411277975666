import { Dialog } from '@headlessui/react';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import cls from 'classnames';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { useRef } from 'react';
import { Error, Scooter, Success } from 'src/animations';

import {
  getCRMBoards,
  movePropertyToAnotherBoard,
  setPropertyArchived
} from '@/src/lib/user-client-wrapper';
import { CRMBoardVM, CRMListVM } from '@/src/types';

import { crmDialogTheme } from './dialog-theme';
import LoadingBoards from './loading-boards';

const stepNames = {
  initial_archive: 1,
  initial_unarchive: 2,
  loading: 3,
  error: 4,
  done: 5
};

function PropertyArchivalStepArchive({
  close,
  setReload,
  authorityNickname,
  setStep,
  listId,
  propertyId,
  boardId,
  setArchiveBoardId
}: {
  close: any;
  setReload: any;
  setStep: any;
  setArchiveBoardId: any;
  authorityNickname?: string;
  listId?: string | null;
  propertyId?: string;
  boardId?: string | null;
}) {
  const confirmRef: any = useRef(null);
  const { t } = useTranslation('common');

  useEffect(() => {
    if (confirmRef?.current) {
      confirmRef?.current?.focus();
    }
  }, []);

  const archive = () => {
    setReload(true);
    setStep(stepNames.loading);
    if (listId && propertyId && boardId) {
      setPropertyArchived(listId, propertyId, boardId)
        .then((res: any) => {
          if (res?.ok === 200) {
            setStep(stepNames.done);
            setArchiveBoardId(res?.boardUUID);
          } else {
            setStep(stepNames.error);
          }
        })
        .catch(() => {
          setStep(stepNames.error);
        });
    }
  };

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Image
          alt="main illustration"
          src={`/images/apartment-rate-increment.svg`}
          height={200}
          width={200}
        />
      </div>

      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          <Trans i18nKey="crm.modals.archive-property-title" authorityNickname={authorityNickname}>
            Är du säker att du vill arkivera fastigheten
            <span className="text-bobo-prop dark:text-dark-prop"> {{ authorityNickname }}</span>?
          </Trans>
        </Dialog.Title>
      </div>

      <div className={crmDialogTheme.buttonContainer}>
        <button className={crmDialogTheme.buttonClose} onClick={close}>
          {t('general.cancel')}
        </button>

        <div>
          <button
            ref={confirmRef}
            className={crmDialogTheme.buttonArchive}
            onClick={() => {
              archive();
            }}>
            {t('crm.user-actions.archive')}
          </button>
        </div>
      </div>
    </div>
  );
}

function PropertyArchivalStepUnarchive({
  close,
  setReload,
  authorityNickname,
  setStep,
  propertyId,
  currentBoardId,
  selectedBoard,
  setSelectedBoard,
  selectedList,
  setSelectedList
}: {
  close: any;
  setReload: any;
  setStep: any;
  authorityNickname?: string;
  currentBoardId?: string | null;
  propertyId?: string;
  selectedBoard: CRMBoardVM | null;
  setSelectedBoard: any;
  selectedList: CRMListVM | null;
  setSelectedList: any;
}) {
  const confirmRef: any = useRef(null);
  const { t } = useTranslation('common');

  const [boards, setBoards] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  let listOfBoards: any[] = boards;
  let listOfLists: any[] = [];

  useEffect(() => {
    setLoading(true);
    getCRMBoards(0).then((res) => {
      // dont show archive boards
      const filteredBoards = res.filter((fetchedBoard) => fetchedBoard.for_archived !== true);

      setBoards(filteredBoards);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (selectedBoard && selectedList) {
      setTimeout(() => {
        confirmRef.current?.focus();
      }, 150);
    }
  }, [selectedBoard, selectedList]);

  if (selectedBoard) {
    listOfBoards = boards?.filter((a) => a?.uuid === selectedBoard?.uuid);

    listOfLists = listOfBoards[0].lists.filter((l) => l.name !== 'Från arkivet'); // don't show the default 'from archive' list
    if (selectedList) {
      listOfLists = listOfLists?.filter((a) => a?.uuid === selectedList.uuid);
    }
  }

  const unarchive = () => {
    setReload(true);
    setStep(stepNames.loading);
    const listToAddTo = selectedList ? selectedList?.uuid : undefined; // listId undefined => 'from archive' default list being created

    if (currentBoardId && propertyId && selectedBoard?.uuid) {
      movePropertyToAnotherBoard(currentBoardId, propertyId, selectedBoard.uuid, listToAddTo)
        .then((res: any) => {
          if (res?.ok === 200) {
            setStep(stepNames.done);
          } else {
            setStep(stepNames.error);
          }
        })
        .catch(() => {
          setStep(stepNames.error);
        });
    }
  };

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Image
          alt="main illustration"
          src={`/images/apartment-rate-increment.svg`}
          height={200}
          width={200}
        />
      </div>

      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          <Trans
            i18nKey="crm.modals.unarchive-property-title"
            authorityNickname={authorityNickname}>
            Till vilken tavla/lista vill du flytta fastigheten
            <span className="text-bobo-prop dark:text-dark-prop"> {{ authorityNickname }}</span>?
          </Trans>
        </Dialog.Title>
      </div>

      <div className={crmDialogTheme.selectionContainer}>
        <div className={crmDialogTheme.selectionTitle}>{t('crm.modals.pick-board')}</div>
        {loading ? (
          <LoadingBoards />
        ) : (
          <div className={crmDialogTheme.selectionGrid}>
            {boards &&
              listOfBoards?.map((board, idx) => {
                return (
                  <div
                    key={idx}
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      if (board?.uuid === selectedBoard?.uuid) {
                        setSelectedBoard(null);
                        setSelectedList(null);
                      } else {
                        setSelectedBoard(board);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key == 'Enter') {
                        if (board?.uuid === selectedBoard?.uuid) {
                          setSelectedBoard(null);
                          setSelectedList(null);
                        } else {
                          setSelectedBoard(board);
                        }
                      }
                    }}
                    className={cls(crmDialogTheme.selectionItem, {
                      'border-2 border-bobo-prop dark:border-dark-prop dark:hover:border-dark-morefaded bg-bobo-proplight hover:bg-white hover:border-gray-200 focus-visible:bg-white dark:bg-dark-prophover dark:hover:!bg-dark-faded dark:focus-visible:bg-dark-faded':
                        board?.uuid === selectedBoard?.uuid
                    })}>
                    <>{board?.title}</>
                    {board?.private && (
                      <LockClosedIcon className="w-3 h-3 right-1 top-1 absolute text-gray-600 dark:text-gray-400" />
                    )}
                  </div>
                );
              })}
          </div>
        )}
      </div>
      {selectedBoard && (
        <div className={crmDialogTheme.selectionContainer}>
          <div className={crmDialogTheme.selectionTitle}>{t('crm.modals.pick-list')}</div>

          <div className={crmDialogTheme.selectionGrid}>
            {(!selectedList || selectedList.name === 'Från arkivet') && (
              <button
                onClick={() => {
                  if (selectedList?.name === 'Från arkivet') {
                    setSelectedList(null);
                  } else {
                    setSelectedList({ name: 'Från arkivet' });
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key == 'Enter') {
                    if (selectedList?.name === 'Från arkivet') {
                      setSelectedList(null);
                    } else {
                      setSelectedList({ name: 'Från arkivet' });
                    }
                  }
                }}
                tabIndex={0}
                className={cls(
                  'border border-dashed rounded-md cursor-pointer transition-all duration-300 bg-white hover:bg-bobo-proplight dark:border-dark-morefaded dark:hover:bg-dark-prophover dark:bg-dark-faded focus-visible:ring-2 focus-visible:ring-bobo-prop dark:focus-visible:ring-dark-prop p-3 break-words relative max-h-[max-content] flex items-center justify-center has-tooltip',
                  {
                    'border-2 border-bobo-prop dark:border-dark-prop  dark:hover:border-dark-morefaded bg-bobo-proplight hover:bg-white hover:border-gray-200 focus-visible:bg-white dark:bg-dark-prophover dark:hover:!bg-dark-faded dark:focus-visible:bg-dark-faded':
                      selectedList?.name === 'Från arkivet'
                  }
                )}>
                {t('crm.list-properties.from-archive')}
                <span
                  className={cls(
                    'tooltip text-mini leading-3 rounded shadow-lg p-1 bg-bobo-black bg-gray-400 dark:bg-dark-lessfaded top-8 w-[max-content] max-w-[230px] absolute',
                    {
                      hidden: selectedList?.name === 'Från arkivet'
                    }
                  )}>
                  {t('crm.modals.unarchive-property-tooltip')}
                </span>
              </button>
            )}
            {listOfLists.length > 0 &&
              listOfLists?.map((list, idx) => {
                return (
                  <div
                    key={idx}
                    onClick={() => {
                      if (list?.uuid === selectedList?.uuid) {
                        setSelectedList(null);
                      } else {
                        setSelectedList(list);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key == 'Enter') {
                        if (list?.uuid === selectedList?.uuid) {
                          setSelectedList(null);
                        } else {
                          setSelectedList(list);
                        }
                      }
                    }}
                    role="button"
                    tabIndex={0}
                    className={cls(crmDialogTheme.selectionItem, {
                      'border-2 border-bobo-prop bg-bobo-proplight hover:bg-white hover:border-gray-200 focus-visible:bg-white dark:hover:!bg-dark-faded dark:bg-dark-prophover dark:border-dark-prop dark:hover:border-dark-morefaded dark:focus-visible:bg-dark-faded':
                        list?.uuid === selectedList?.uuid
                    })}>
                    <>{list?.name}</>
                  </div>
                );
              })}
          </div>
        </div>
      )}

      <div className={crmDialogTheme.buttonContainer}>
        <button className={crmDialogTheme.buttonClose} onClick={close}>
          {t('general.cancel')}
        </button>
        <button
          className={crmDialogTheme.buttonArchive}
          disabled={!selectedList}
          onClick={() => {
            unarchive();
          }}>
          {t('crm.user-actions.unarchive')}
        </button>
      </div>
    </div>
  );
}

function PropertyArchivalStepLoading() {
  const { t } = useTranslation('common');
  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Scooter />
      </div>

      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          {t('crm.modals.move-property-loading-title')}
        </Dialog.Title>

        <p className={crmDialogTheme.subtext}>{t('fun-facts.swe-smallest-property')}</p>
      </div>
    </div>
  );
}

function PropertyArchivalStepError({ close }: { close: any }) {
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.icon}>
        <Error />
      </div>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.errorTitle}>
          {t('errors.something-went-wrong')}
        </Dialog.Title>
      </div>
      <div className={crmDialogTheme.buttonContainer}>
        <button
          className={crmDialogTheme.buttonClose}
          onClick={() => {
            close();
          }}>
          {t('general.cancel')}
        </button>
      </div>
    </div>
  );
}

function PropertyArchivalStepDone({
  close,
  authorityNickname,
  board,
  archiveBoardId
}: {
  close: any;
  authorityNickname?: string;
  board?: CRMBoardVM | null;
  archiveBoardId?: string | null;
}) {
  const { t } = useTranslation('common');
  const router = useRouter();

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.icon}>
        <Success />
      </div>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          {board?.title ? (
            <Trans
              i18nKey="crm.modals.move-property-successful"
              authorityNickname={authorityNickname}
              boardName={board.title}>
              <span className="text-bobo-prop dark:text-dark-prop">{{ authorityNickname }}</span>{' '}
              har flyttats till tavlan{' '}
              <span className="uppercase">{{ boardName: board.title }}</span>.
            </Trans>
          ) : (
            <Trans
              i18nKey="crm.modals.archive-property-successful"
              authorityNickname={authorityNickname}>
              <span className="text-bobo-prop dark:text-dark-prop">{{ authorityNickname }}</span>{' '}
              har arkiverats.
            </Trans>
          )}
        </Dialog.Title>
      </div>
      <div className={crmDialogTheme.buttonContainer}>
        <button
          className={crmDialogTheme.buttonClose}
          onClick={() => {
            close();
          }}>
          {t('general.close')}
        </button>
        <button
          className={crmDialogTheme.buttonConfirm}
          onClick={() => {
            board ? router.push(`/crm/${board.uuid}`) : router.push(`/crm/${archiveBoardId}`);
            close();
          }}>
          {board ? t('crm.modals.link-to-board') : t('crm.modals.link-to-archive')}
        </button>
      </div>
    </div>
  );
}

/**
 * dialog to confirm archiving of list
 */
export default function CrmPropertyDialogArchive({
  initStep = 1,
  setReload,
  close,
  listId,
  propertyId,
  boardId,
  authorityNickname
}: {
  initStep?: number;
  setReload: any;
  close: any;
  propertyId?: string;
  authorityNickname?: string;
  listId?: string | null;
  boardId?: string | null;
}) {
  const [step, setStep] = useState(initStep);
  const [board, setBoard] = useState<CRMBoardVM | null>(null);
  const [list, setList] = useState<CRMListVM | null>(null);
  const [archiveBoardId, setArchiveBoardId] = useState<string | null>(null);

  let mod: JSX.Element = <div />;

  switch (step) {
    case stepNames.initial_archive:
      mod = (
        <PropertyArchivalStepArchive
          close={close}
          authorityNickname={authorityNickname}
          setStep={setStep}
          listId={listId}
          propertyId={propertyId}
          boardId={boardId}
          setArchiveBoardId={setArchiveBoardId}
          setReload={setReload}
        />
      );
      break;

    case stepNames.initial_unarchive:
      mod = (
        <PropertyArchivalStepUnarchive
          close={close}
          setStep={setStep}
          propertyId={propertyId}
          authorityNickname={authorityNickname}
          selectedBoard={board}
          setSelectedBoard={setBoard}
          selectedList={list}
          setSelectedList={setList}
          currentBoardId={boardId}
          setReload={setReload}
        />
      );
      break;

    case stepNames.loading:
      mod = <PropertyArchivalStepLoading />;
      break;

    case stepNames.error:
      mod = <PropertyArchivalStepError close={close} />;
      break;

    case stepNames.done:
      mod = (
        <PropertyArchivalStepDone
          close={close}
          authorityNickname={authorityNickname}
          board={board}
          archiveBoardId={archiveBoardId}
        />
      );
      break;
  }

  return <>{mod}</>;
}
