import { useEffect, useState } from 'react';
import { useMap } from 'react-map-gl';
import { GotoFeature } from 'shared/dist/types';
import { parseBuildingPermits } from 'src/lib/geojson-parser';

export default function ManageStyles({
  feature,
  layers,
  obj,
  viewObject
}: {
  uuid?: string;
  feature: GotoFeature;
  layers?: string[];
  obj?: any;
  viewObject?: any;
  satelite?: boolean;
}) {
  const [loaded, setLoaded] = useState<boolean>(false);
  const { myMapA } = useMap();

  myMapA?.getMap()?.on('load', () => {
    setLoaded(true);
  });

  useEffect(() => {
    loaded && handleStyles();
  }, [layers, feature, loaded]);

  const handleStyles = () => {
    myMapA?.getLayer('unclustered-point') && myMapA?.getMap()?.removeLayer('unclustered-point');
    myMapA?.getLayer('unclustered-count') && myMapA?.getMap()?.removeLayer('unclustered-count');
    myMapA?.getLayer('cluster-count') && myMapA?.getMap()?.removeLayer('cluster-count');
    myMapA?.getLayer('clusters') && myMapA?.getMap()?.removeLayer('clusters');

    myMapA?.getLayer('prop-polygons') && myMapA?.getMap()?.removeLayer('prop-polygons');
    myMapA?.getSource('prop-area') && myMapA?.getMap()?.removeSource('prop-area');
    myMapA?.getSource('detaljplaner') && myMapA?.getMap()?.removeSource('detaljplaner');
    myMapA?.getSource('building_permits') && myMapA?.getMap()?.removeSource('building_permits');

    myMapA?.getMap().setLayoutProperty('lamningar-yta', 'visibility', 'none');
    myMapA?.getMap().setLayoutProperty('lamningar-line', 'visibility', 'none');
    myMapA?.getMap().setLayoutProperty('lamningar-point', 'visibility', 'none');
    myMapA?.getMap().setLayoutProperty('lamningar-text', 'visibility', 'none');

    myMapA?.getMap().setLayoutProperty('fastighet-text', 'visibility', 'none');
    myMapA?.getMap().setLayoutProperty('fastighet-yta', 'visibility', 'none');
    myMapA?.getMap().setLayoutProperty('fastighet-line', 'visibility', 'none');

    myMapA?.getMap().setLayoutProperty('plan-line', 'visibility', 'none');
    myMapA?.getMap().setLayoutProperty('plan-yta', 'visibility', 'none');
    myMapA?.getMap().setLayoutProperty('plan-punkt', 'visibility', 'none');
    myMapA?.getMap().setLayoutProperty('bestammelse-line', 'visibility', 'none');
    myMapA?.getMap().setLayoutProperty('bestammelse-yta', 'visibility', 'none');

    layers &&
      layers?.map((layer) => {
        switch (layer) {
          case 'properties': {
            myMapA?.getMap().setLayoutProperty('fastighet-line', 'visibility', 'visible');
            myMapA?.getMap().setLayoutProperty('fastighet-text', 'visibility', 'visible');
            myMapA?.getMap().setLayoutProperty('fastighet-yta', 'visibility', 'visible');
            myMapA?.getMap().setPaintProperty('fastighet-line', 'line-opacity', 0.5);
            break;
          }
          case 'detaljplaner': {
            const blobs = obj?.details?.map((a) => {
              return a?.properties?.objekt_id;
            });
            myMapA
              ?.getMap()
              .setPaintProperty('plan-yta', 'fill-opacity', [
                'match',
                ['get', 'objekt_id'],
                blobs,
                0.3,
                0
              ]);
            myMapA
              ?.getMap()
              .setPaintProperty('plan-punkt', 'text-opacity', [
                'match',
                ['get', 'objekt_id'],
                blobs,
                1,
                0
              ]);
            myMapA
              ?.getMap()
              .setPaintProperty('plan-line', 'line-opacity', [
                'match',
                ['get', 'objekt_id'],
                blobs,
                1,
                0
              ]);

            myMapA?.getMap()?.on('click', 'plan-yta', (e) => {
              if (e.features && e?.features?.length > 0) {
                const feature: any = e.features[0];
                const coordinates = feature?.geometry.coordinates.slice();
                const details = e.features.map(async (a) =>
                  details.push({ properties: a.properties, coordinates })
                );

                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                  coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }
                viewObject({ details, obj, lngLat: e.lngLat }, GotoFeature.DETAIL_PLAN);
              }
            });
            myMapA?.getMap()?.on('mouseenter', 'plan-yta', () => {
              myMapA.getMap().getCanvas().style.cursor = 'pointer';
            });
            myMapA?.getMap()?.on('mouseleave', 'plan-yta', () => {
              myMapA.getMap().getCanvas().style.cursor = '';
            });
            break;
          }
          case 'lamningar': {
            try {
              myMapA?.getMap().setLayoutProperty('lamningar-yta', 'visibility', 'visible');
              myMapA?.getMap().setLayoutProperty('lamningar-line', 'visibility', 'visible');
              myMapA?.getMap().setLayoutProperty('lamningar-point', 'visibility', 'visible');
              myMapA?.getMap().setPaintProperty('lamningar-line', 'line-opacity', 1.0);
              myMapA?.getMap().setPaintProperty('lamningar-line', 'line-width', 5);
              myMapA?.getMap().setPaintProperty('lamningar-point', 'icon-opacity', 1);
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore TODO
              myMapA?.getMap().setPaintProperty('lamningar-point', 'icon-size', 10);
            } catch (e) {
              console.log(e);
            }
            break;
          }
          case 'building_permits': {
            //Clustered layer with building permit
            console.log(`Layer: Adding building permits as cluster`);
            const bpSource: any = parseBuildingPermits(obj?.buildingPermits);
            if (!myMapA?.getSource('building_permits')) {
              myMapA?.getMap()?.addSource('building_permits', {
                type: 'geojson',
                data: bpSource,
                cluster: true,
                clusterMaxZoom: 14,
                clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
              });

              myMapA?.getMap()?.addLayer({
                id: 'clusters',
                type: 'circle',
                source: 'building_permits',
                filter: ['has', 'point_count'],
                paint: {
                  // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
                  // with three steps to implement three types of circles:
                  //   * Blue, 20px circles when point count is less than 100
                  //   * Yellow, 30px circles when point count is between 100 and 750
                  //   * Pink, 40px circles when point count is greater than or equal to 750
                  'circle-color': [
                    'step',
                    ['get', 'point_count'],
                    '#888888',
                    100,
                    '#FB923C',
                    750,
                    '#0A9396'
                  ],
                  'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
                }
              });

              myMapA?.getMap()?.addLayer({
                id: 'cluster-count',
                type: 'symbol',
                source: 'building_permits',
                filter: ['has', 'point_count'],
                layout: {
                  'text-field': '{point_count_abbreviated}',
                  'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                  'text-size': 12
                },
                paint: {
                  'text-color': '#ffffff'
                }
              });

              myMapA?.getMap()?.addLayer({
                id: 'unclustered-count',
                type: 'symbol',
                source: 'building_permits',
                filter: ['!', ['has', 'point_count']],
                layout: {
                  'text-field': '  {name}',
                  'text-padding': 0,
                  'text-offset': [0, 1],
                  'text-anchor': 'top',
                  'text-justify': 'center',
                  'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                  'text-size': 11
                },
                paint: {
                  'text-color': '#ffffff',
                  'text-halo-color': 'rgba(50, 50, 50, .9)',
                  'text-halo-width': 80
                }
              });

              myMapA?.getMap()?.addLayer({
                id: 'unclustered-point',
                type: 'circle',
                source: 'building_permits',
                filter: ['!', ['has', 'point_count']],
                paint: {
                  'circle-color': '#0A9396',
                  'circle-radius': 8,
                  'circle-stroke-width': 2,
                  'circle-stroke-color': '#fff'
                }
              });

              // inspect a cluster on click
              myMapA?.getMap()?.on('click', 'clusters', (e) => {
                const features = myMapA?.getMap()?.queryRenderedFeatures(e.point, {
                  layers: ['clusters']
                });

                if (features && features?.length > 0 && features[0]?.properties) {
                  const feature: any = features[0];
                  const clusterId: string = feature?.properties.cluster_id;
                  const source: any = myMapA?.getMap()?.getSource('building_permits');

                  source?.getClusterExpansionZoom(clusterId, (err, zoom) => {
                    if (err) return;
                    myMapA?.getMap()?.easeTo({
                      center: feature.geometry.coordinates,
                      zoom: zoom
                    });
                  });
                }
              });
              myMapA?.getMap()?.on('click', 'unclustered-point', (e) => {
                if (e.features && e.features?.length > 0) {
                  const feature: any = e.features[0];
                  const coordinates = feature?.geometry.coordinates.slice();
                  const property = feature?.properties?.property;
                  const address = feature?.properties?.address;
                  const propType = feature?.properties?.propType;
                  const area = feature?.properties?.area;
                  const propertyUUID = feature?.properties?.propertyUUID;

                  const details: any[] = [];
                  e?.features?.map(async (a) => {
                    const tmp = JSON.parse(a.properties?.details);
                    details.push(tmp);
                  });

                  while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                  }
                  viewObject(
                    { property, details, address, propType, area, propertyUUID },
                    GotoFeature.PROPERTY_PERMITS
                  );
                }
              });
              myMapA?.getMap()?.on('mouseenter', 'clusters', () => {
                myMapA.getMap().getCanvas().style.cursor = 'pointer';
              });
              myMapA?.getMap()?.on('mouseleave', 'clusters', () => {
                myMapA.getMap().getCanvas().style.cursor = '';
              });
              myMapA?.getMap()?.on('mouseenter', 'unclustered-point', () => {
                myMapA.getMap().getCanvas().style.cursor = 'pointer';
              });
              myMapA?.getMap()?.on('mouseleave', 'unclustered-point', () => {
                myMapA.getMap().getCanvas().style.cursor = '';
              });
            }
            break;
          }
        }
      });

    switch (feature) {
      case GotoFeature.LOCAL_COUNCIL:
        // Darken the Local Council geometry
        myMapA
          ?.getMap()
          .setPaintProperty('kommunytaid', 'fill-opacity', [
            'match',
            ['id'],
            [parseInt(obj?.uuid)],
            0.19,
            0
          ]);
        myMapA
          ?.getMap()
          .setPaintProperty('kommunytaid-line', 'line-opacity', [
            'match',
            ['id'],
            [parseInt(obj?.uuid)],
            1,
            0.25
          ]);
        myMapA
          ?.getMap()
          .setPaintProperty('kommunytaid-line', 'line-width', [
            'match',
            ['id'],
            [parseInt(obj?.uuid)],
            1,
            1
          ]);
        break;
    }
  };

  return <div />;
}
