import cls from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowRight01Icon } from '@/src/assets/icons';
import { RowHeader, RowTableBody } from '@/src/widgets/row-table-body';

export default function ExpandableInfoBox({
  helpTextId,
  title,
  description,
  theme = 'default',
  enableExpand = true
}: {
  helpTextId?: string;
  title: string | JSX.Element;
  description: string | JSX.Element;
  theme?: 'smaller' | 'default';
  enableExpand?: boolean;
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation('common');

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="border border-gray-100 dark:border-gray-700 rounded-md bg-gray-50 dark:bg-gray-800 p-4 text-gray-800 dark:text-white mt-4">
      <RowHeader
        theme={theme}
        helpTextId={helpTextId ? `${helpTextId}` : undefined}
        title={title}
      />
      <RowTableBody>
        <>
          <div
            className={`text-xs dark:text-white transition-all duration-300 py-1 ease-in-out ${
              isExpanded ? 'max-h-[1000px]' : 'max-h-10'
            } overflow-hidden relative`}>
            <div className="h-fit w-full">{description}</div>
            <div
              className={cls(
                'absolute bottom-0 left-0 right-0 h-6 bg-gradient-to-t from-gray-50 dark:from-gray-800 to-transparent pointer-events-none transition-opacity duration-200',
                { 'opacity-0': isExpanded, 'opacity-100': !isExpanded }
              )}></div>
          </div>
          {enableExpand && (
            <div className="flex justify-end mt-2">
              <button
                onClick={toggleExpand}
                className="text-xs text-gray-600 dark:text-gray-400 hover:text-bobo-prophover dark:hover:text-dark-prophover transition-colors duration-200 flex items-center focus:outline-none">
                <>
                  {isExpanded ? t('general.read-less') : t('general.read-more')}{' '}
                  <ArrowRight01Icon
                    className={cls(
                      'ml-1 h-4 w-4 transition-transform duration-200 dark:text-white',
                      {
                        '-rotate-90': isExpanded
                      }
                    )}
                  />
                </>
              </button>
            </div>
          )}
        </>
      </RowTableBody>
    </div>
  );
}
