/* External Libraries */
import toast from 'react-hot-toast';

import { CreateUpdateBoardData, Tfunc } from '../types';
/* Components & Widgets */

export async function saveUpdateBoard(object: CreateUpdateBoardData) {
  const url = `/api/crm/addUpdateCRMBoard`;
  return fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      ...object
    })
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      return json;
    })
    .catch(() => {
      return {
        error: true,
        msg: `Failed to fetch url: ${url}`
      };
    });
}

export async function saveUpdateList(object) {
  const url = `/api/crm/addUpdateCRMList`;
  return fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ ...object })
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      return json;
    })
    .catch(() => {
      return {
        error: true,
        msg: `Failed to fetch url: ${url}`
      };
    });
}

export async function deleteList(object) {
  const url = `/api/crm/deleteCRMList`;
  return fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ ...object })
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      return json;
    })
    .catch(() => {
      return {
        error: true,
        msg: `Failed to fetch url: ${url}`
      };
    });
}

export async function saveActorSettings(data, t: Tfunc) {
  const toastId: string = toast.loading(t('toasts.save-actor-settings-loading'));

  const body = JSON.stringify(data);

  return fetch(`/api/actor/updateActorSettings`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      toast.success(t('toasts.save-actor-settings-success'), {
        id: toastId
      });
      return json;
    })
    .catch((err) => {
      toast.error(t('toasts.save-actor-settings-error', { errorMsg: err.message }), {
        id: toastId
      });
      return null;
    });
}

export async function getActor(uuid: string, t: Tfunc) {
  const toastId = toast.loading(t('toasts.get-actor-loading'));

  const url = `/api/actor/getActor?uuid=${uuid}`;

  return fetch(url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      toast.dismiss();
      return json;
    })
    .catch((err) => {
      toast.error(t('toasts.get-actor-error', { errorMsg: err.message }), {
        id: toastId
      });
      return {
        error: true,
        msg: `Failed to fetch url: ${url}`
      };
    });
}

export async function getActorSettings(uuid: string) {
  const url = `/api/actor/get-actor-settings?uuid=${uuid}`;

  return fetch(url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      toast.dismiss();
      return json;
    })
    .catch((err) => {
      console.error({ errorMsg: err.message });
      return {
        error: true,
        msg: `Failed to fetch url: ${url}`
      };
    });
}

export async function saveActorLogo(data) {
  const url = `/api/actor/uploadLogo?uuid=${data?.actor_uuid}`;

  const formData = new FormData();
  formData.append('file', data?.file);

  return fetch(url, {
    method: 'POST',
    body: formData
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      return json;
    })
    .catch(() => {
      return null;
    });
}

export async function getActorLogo(uuid) {
  const url = `/api/actor/getActorLogo?uuid=${uuid}`;

  return fetch(url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      return json;
    })
    .catch(() => {
      return null;
    });
}
