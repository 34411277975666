import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import toast from 'react-hot-toast';
import { getCategoryName } from 'shared/dist/constants';
import { ButtonType } from 'shared/dist/types';
import { CashflowCredit, CashflowValuationModel } from 'shared/dist/types/cashflow/cashflow-model';
import { v4 as uuidv4 } from 'uuid';
import * as z from 'zod';

import { getValidLocale } from '@/src/lib/constants';
import Button from '@/src/widgets/buttons';

import { DeleteType } from '../../cashflow-valuation/cashflow-dialog-switcher';
import { handleMaxInputLimit } from '../../cashflow-valuation/constants/calculator-functions';
import { cashflowTheme } from '../../cashflow-valuation/constants/cashflow-theme';
import { MAX_LIMIT_CREDIT_VALUE_INPUT } from '../../cashflow-valuation/constants/general';
import CashflowDeleteModel from './cashflow-delete-model';

// Zod schema for lease validation
const creditSchema = z.object({
  name: z.string().min(1, 'property.cashflow-valuation.lease.input-errors.string'),
  startDate: z.number(),
  amount: z.number().min(0, 'property.cashflow-valuation.lease.input-errors.number'),
  floatingInterest: z.number().min(0, 'property.cashflow-valuation.lease.input-errors.number'),
  amortization: z.number().min(0, 'property.cashflow-valuation.lease.input-errors.number')
});

// Type for form inputs
interface CreditInput {
  name: string;
  uuid: string;
  amount: number;
  startDate: number;
  floatingInterest: number;
  amortization: number;
}

const stepNames = {
  initialAdd: 1,
  deleteCredit: 2
};

const validateCreditInput = (
  creditInput: CreditInput
): { valid: boolean; errors: Record<string, string> } => {
  const result = creditSchema.safeParse(creditInput);

  if (result.success) {
    return { valid: true, errors: {} };
  }

  const errors: Record<string, string> = {};
  result.error.errors.forEach((error) => {
    const path = error.path.join('.');
    errors[path] = error.message;
  });

  return { valid: false, errors };
};

export default function CashflowCreditTemplate({
  object,
  close,
  setReload,
  setSelectedModel
}: {
  object: {
    object: any;
    model: CashflowValuationModel;
    credit: CashflowCredit | undefined;
  };
  setSelectedModel: (arg0?: CashflowValuationModel) => void;
  close: any;
  setReload: (arg: boolean) => void;
}) {
  const { t } = useTranslation();
  const { credit, model } = object;
  const [step, setStep] = useState<number>(stepNames.initialAdd);
  const currentYear = +moment().format('YYYY');
  const router = useRouter();
  const locale: string = getValidLocale(router);

  const [selectedCredit, setSelectedCredit] = useState<CreditInput | null>(null);

  const [loading, setLoading] = useState(false);

  const [errorState, setErrorState] = useState<any>({});

  useEffect(() => {
    if (credit) {
      const creditInput: CreditInput = {
        name: credit.name,
        uuid: credit.uuid,
        amount: credit.amount,
        startDate: credit.startDate,
        floatingInterest: +credit.floatingInterest * 100,
        amortization: +credit.amortization * 100
      };
      setSelectedCredit(creditInput);
      return;
    }

    const newCredit: CreditInput = {
      name: t('property.cashflow-valuation.credit.new-credit'),
      uuid: uuidv4(),
      amount: 0,
      startDate: moment(currentYear + '-01-01')
        .startOf('day')
        .unix(),
      floatingInterest: 0,
      amortization: 0
    };
    setSelectedCredit(newCredit);
  }, []);

  const validateAndSave = async () => {
    if (!selectedCredit) {
      return;
    }

    const { valid, errors } = validateCreditInput(selectedCredit);

    if (!valid) {
      setErrorState(errors);
      return;
    }

    setErrorState({});

    const validatedCredit: CashflowCredit = {
      ...selectedCredit,
      floatingInterest: selectedCredit.floatingInterest / 100,
      amortization: selectedCredit.amortization / 100
    };

    const updatedCredits: CashflowCredit[] = credit
      ? model.credits.map((c) => (c.uuid === credit.uuid ? validatedCredit : c))
      : [...model.credits, validatedCredit];

    setSelectedModel({
      ...model,
      credits: updatedCredits
    });
    toast.success(t('property.cashflow-valuation.credit.saved', { name: validatedCredit.name }));
    setLoading(false);
    close();
  };

  let mod: JSX.Element = <div />;

  if (selectedCredit) {
    switch (step) {
      case stepNames.initialAdd:
        mod = (
          <div className="z-30 max-h-[calc(100vh-100px)] overflow-auto flex flex-col gap-8">
            <div>
              <h3 className="text-base font-bold text-gray-900 dark:text-white sm:text-lg uppercase">
                {t(`property.cashflow-valuation.credit.${credit ? 'modify' : 'add-new'}`)}
              </h3>
            </div>

            <div className="flex flex-col justify-center gap-4 text-xs">
              <div className="flex gap-2 items-center">
                <label htmlFor="namn" className="font-demi w-[100px] text-right">
                  {t('property.cashflow-valuation.credit.issuer')}
                </label>
                <div className={cashflowTheme.inputContainer}>
                  <input
                    id="namn"
                    type="text"
                    autoComplete="off"
                    value={
                      t(getCategoryName(selectedCredit.name ?? '')) || selectedCredit.name || ''
                    }
                    placeholder={
                      errorState.name
                        ? t(errorState.string, { field: t('general.name') })
                        : t('property.cashflow-valuation.credit.issuer-placeholder')
                    }
                    onChange={({ target }) => {
                      setSelectedCredit({ ...selectedCredit, name: target.value });
                    }}
                    className={cashflowTheme.inputField}
                  />
                </div>
              </div>

              <div className="flex gap-2 items-center">
                <label htmlFor="credit-input" className="font-demi w-[100px] text-right">
                  {t('property.cashflow-valuation.credit.header')}
                </label>
                <div className={cashflowTheme.inputContainer}>
                  <input
                    type="number"
                    id="credit-input"
                    value={selectedCredit.amount || ''}
                    placeholder={
                      errorState.rent ? t(errorState.rent, { field: t('general.rent') }) : '0'
                    }
                    onChange={({ target }) => {
                      setSelectedCredit({ ...selectedCredit, amount: parseFloat(target.value) });
                    }}
                    onInput={(e) => handleMaxInputLimit(e, MAX_LIMIT_CREDIT_VALUE_INPUT)}
                    className={cashflowTheme.inputField}
                  />
                  <div className="whitespace-nowrap">{t('general.kr-suffix')}</div>
                </div>
              </div>

              <div className="flex gap-2 items-center">
                <label htmlFor="start-date" className="font-demi w-[100px] text-right">
                  {t('property.cashflow-valuation.assumptions.contract-start')}:
                </label>
                <div className={cashflowTheme.inputContainer}>
                  <DatePicker
                    id="start-date"
                    selected={
                      selectedCredit?.startDate
                        ? moment.unix(selectedCredit.startDate).toDate()
                        : new Date()
                    }
                    onChange={(date) => {
                      setSelectedCredit({
                        ...selectedCredit,
                        startDate: moment(date, 'YYYY-MM').startOf('month').unix()
                      });
                    }}
                    dateFormat="yyyy-MM-dd"
                    locale={locale}
                    showMonthYearPicker
                    className={cashflowTheme.inputField}
                    wrapperClassName="!w-full"
                  />
                </div>
              </div>

              <div className="flex gap-2 items-center">
                <label htmlFor={'floating-interest'} className="font-demi w-[100px] text-right">
                  {t('property.cashflow-valuation.credit.floating-interest')}:
                </label>
                <div className={cashflowTheme.inputContainer}>
                  <input
                    type={'number'}
                    id="floating-interest"
                    step={1}
                    placeholder={
                      errorState.indexation
                        ? t(errorState.indexation, {
                            field: t('property.cashflow-valuation.assumptions.indexation')
                          })
                        : '0'
                    }
                    value={+(selectedCredit.floatingInterest ?? 0).toFixed(2)}
                    onChange={({ target }) => {
                      setSelectedCredit({ ...selectedCredit, floatingInterest: +target.value });
                    }}
                    onInput={(e) => handleMaxInputLimit(e, 100)}
                    className={cashflowTheme.inputField}
                  />
                  <div>%</div>
                </div>
              </div>

              <div className="flex gap-2 items-center">
                <label htmlFor={'amortization'} className="font-demi w-[100px] text-right">
                  {t('property.cashflow-valuation.amortization')}:
                </label>
                <div className={cashflowTheme.inputContainer}>
                  <input
                    type={'number'}
                    id="amortization"
                    step={'0.01'}
                    placeholder={
                      errorState.amortization
                        ? t(errorState.indexation, {
                            field: t('property.cashflow-valuation.assumptions.indexation')
                          })
                        : '0'
                    }
                    value={+(selectedCredit.amortization ?? 0).toFixed(2)}
                    onChange={({ target }) => {
                      setSelectedCredit({ ...selectedCredit, amortization: +target.value });
                    }}
                    onInput={(e) => handleMaxInputLimit(e, 100)}
                    className={cashflowTheme.inputField}
                  />
                  <div>%</div>
                </div>
              </div>
            </div>

            <div className="flex justify-between items-end">
              <Button type={ButtonType.CANCEL} onClick={close}>
                {t('general.cancel')}
              </Button>

              <div className="flex gap-2">
                {credit && (
                  <Button
                    type={ButtonType.DELETE}
                    disabled={loading}
                    onClick={async () => {
                      setStep(2);
                    }}>
                    {t('general.delete')}
                  </Button>
                )}

                <Button disabled={loading} onClick={validateAndSave}>
                  {t('general.save')}
                </Button>
              </div>
            </div>
          </div>
        );
        break;
      case stepNames.deleteCredit:
        mod = (
          <>
            <CashflowDeleteModel
              close={close}
              model={model}
              setSelectedModel={setSelectedModel}
              deletedObject={{ ...(credit as CashflowCredit), deleteType: DeleteType.CREDIT }}
              setReload={setReload}
              goBack={() => setStep(1)}
            />
          </>
        );
    }
  }
  return <>{mod}</>;
}
