export function getHousingNeighbours(array: any, object: any, interval: [number, number]): any {
  const rentNeighbours: any[] =
    array?.length > 0
      ? array?.filter(
          (a) => a?.residential_rent > interval[0] / 1.5 && a?.residential_rent < interval[1] * 1.5
        )
      : [];

  //If this current property is not included, add it.
  if (!rentNeighbours?.find((a) => a?.uuid === object?.uuid)) {
    rentNeighbours.push({
      uuid: object?.uuid,
      authority_nickname: object?.authority_nickname,
      tax_owners: object?.owners,
      gross_leasable_area: object?.loa,
      gross_residential_area: object?.boa,
      residential_rent: object?.v2?.residential_rent_91,
      lat: object?.lat,
      lng: object?.lng
    });
  }

  return rentNeighbours;
}

export function getOfficeNeighbours(array: any, object: any, interval: [number, number]): any[] {
  const rentNeighbours: any[] =
    array.length > 0
      ? array?.filter((a) => a?.office_rent > interval[0] / 2 && a?.office_rent < interval[1] * 2)
      : [];

  //If this current property is not included, add it.
  if (!rentNeighbours?.find((a) => a?.uuid === object?.uuid)) {
    rentNeighbours.push({
      uuid: object?.uuid,
      authority_nickname: object?.authority_nickname,
      tax_owners: object?.owners,
      gross_leasable_area: object?.loa,
      boa: object?.boa,
      office_rent: object?.v2?.office_rent
    });
  }

  return rentNeighbours;
}

export function getWarehouseNeighbours(array: any, object: any, interval: [number, number]): any[] {
  const rentNeighbours: any[] =
    array.length > 0
      ? array?.filter(
          (a) => a?.warehouse_rent > interval[0] / 2 && a?.warehouse_rent < interval[1] * 2
        )
      : [];

  //If this current property is not included, add it.
  if (!rentNeighbours?.find((a) => a?.uuid === object?.uuid)) {
    rentNeighbours.push({
      uuid: object?.uuid,
      authority_nickname: object?.authority_nickname,
      tax_owners: object?.owners,
      gross_leasable_area: object?.loa,
      boa: object?.boa,
      warehouse_rent: object?.v2?.warehouse_rent
    });
  }

  return rentNeighbours;
}
