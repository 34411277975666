/**
 * Convert a 2D array into a CSV string
 */
function arrayToCsv(data) {
  return data
    .map(
      (row) =>
        row
          .map(String) // convert every value to String
          .map((v) => v.replaceAll('"', '""')) // escape double colons
          .map((v) => `"${v}"`) // quote it
          .join(',') // comma-separated
    )
    .join('\r\n'); // rows starting on new lines
}

/**
 * Download CSV contents as a file
 */
function downloadCSV(content: string, filename) {
  // create BOM UTF-8
  // const buffer = new ArrayBuffer(3);
  // const dataView = new DataView(buffer);
  // dataView.setUint8(0, 0xfe);
  // dataView.setUint8(1, 0xbb);
  // dataView.setUint8(2, 0xbf);
  // const read = new Uint8Array(buffer);

  // const blob = new Blob([read /*prepend bom*/, content], { type: 'text/csv;charset=utf-8' });
  const blob = new Blob([content], { type: 'text/csv;charset=utf-8' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
}

export function createAndDownloadCsv(
  data: Array<Array<string | number>>,
  headers: Array<string>,
  fileName: string
) {
  const csvContent = arrayToCsv([headers, ...data]);
  downloadCSV(csvContent, fileName);
}
