import cls from 'classnames';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { PropertyVM } from 'shared/dist/types/property2';

import { handleMaxInputLimit } from '@/src/components/cashflow-valuation/constants/calculator-functions';
import { MAX_LIMIT_ASSET_AREA_INPUT } from '@/src/components/cashflow-valuation/constants/general';
import InputFieldDropdownMenu from '@/src/layout/components/input-field-dropdown-menu';

export default function AreaInputDropdown({
  changeArea,
  area,
  selectedTab,
  property,
  errorState
}: {
  changeArea: any;
  area: number;
  selectedTab: string;
  property: PropertyVM;
  errorState: any;
}) {
  const [areaOptions, setAreaOptions] = useState<any[]>([]);
  const { t } = useTranslation('common');

  useEffect(() => {
    if (property) {
      const boa: number = property.boa ?? 0;
      const loa: number = property.loa ?? 0;
      const total: number = boa + loa;
      const options: { value: number; source: string }[] = [
        { value: boa, source: t('general.boa') },
        { value: loa, source: t('general.loa') },
        { value: total, source: `${t('general.boa')} + ${t('general.loa')}` }
      ];
      setAreaOptions(options);
    }
  }, [property]);

  const renderAreaOptions = (closeMenu) =>
    areaOptions?.map((i, idx) => {
      return (
        <div
          key={idx}
          className="py-1 px-2 pr-6 transition-colors duration-200 hover:bg-bobo-proplight dark:hover:bg-dark-prophover text-bobo-prop dark:text-white hover:text-bobo-prophover w-full">
          <button
            onClick={() => {
              changeArea(t(selectedTab), +i.value);
              closeMenu();
            }}
            className="disabled:text-gray-300 w-full h-full flex justify-between items-center">
            <div className="flex flex-col items-start">
              <div>{i.source}</div>
            </div>

            <div>
              {i.value}
              {t('general.square-meters')}
            </div>
          </button>
        </div>
      );
    });

  const renderInputField = (setShowItems) => (
    <input
      type={'number'}
      placeholder={
        errorState.area && area === 0 ? t(errorState.area, { field: t('general.area') }) : 0
      }
      value={area > 0 ? area : ''}
      onFocus={() => {
        setShowItems(true);
      }}
      onChange={({ target }) => {
        changeArea(t(selectedTab), +target.value);
      }}
      onInput={(e) => handleMaxInputLimit(e, MAX_LIMIT_ASSET_AREA_INPUT)}
      className={cls(
        'p-0 m-0 text-xs text-gray-400 dark:bg-dark-primary border-none flex-1 w-full placeholder:italic',
        {
          'placeholder:!text-bobo-bricksred': errorState.area || area === 0,
          'placeholder:text-gray-400': !errorState.area
        }
      )}
    />
  );

  return (
    <InputFieldDropdownMenu
      renderInputField={renderInputField}
      renderOptions={renderAreaOptions}
      suffix={t('general.square-meters')}
    />
  );
}
