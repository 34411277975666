/* eslint-disable i18next/no-literal-string */
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Download05Icon } from '@/src/assets/icons';
import MainPageWrapper from '@/src/layout/components/main-page-wrapper';
import RowWrapper from '@/src/layout/row-wrapper';
import WrapSection from '@/src/layout/section-wrapper';
import councilsGEO from '@/src/lib/data/councils-geo-se.json';
import councils from '@/src/lib/data/councils-se.json';
import LoadingModal from '@/src/widgets/loading-dialog';

export const AddDeliverable = () => {
  const router = useRouter();

  const { t } = useTranslation('common');

  const [searchStr, setSearchStr] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [localCouncil, setLocalCouncil] = useState<any>(null);
  const [activeLayer, setActiveLayer] = useState<any>(null);

  useEffect(() => {
    //Get some stats on how much data is loaded and where
    setLoading(false);

    if (router?.query?.index && router?.query?.index?.length > 2 && !loading) {
      if (router?.query && router?.query?.index && router?.query?.index?.length > 1) {
        const lc_code = router?.query?.index[1];

        const local_geo = councils?.find((a) => a?.uuid === lc_code);
        const local_lc = councilsGEO?.find((a) => a?.kod === lc_code);

        setLocalCouncil({ ...local_geo, ...local_lc });
      }
    }
  }, [loading]);

  const selectedLayer = (markedLayer) => {
    setActiveLayer(markedLayer);
  };

  const listFilter = (a) => {
    if (searchStr?.length > 0) {
      if (
        a?.name?.startsWith(searchStr?.toUpperCase()) ||
        a?.uuid?.startsWith(searchStr?.toUpperCase()) ||
        a?.region?.toUpperCase()?.startsWith(searchStr?.toUpperCase())
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  if (loading) {
    return <LoadingModal isLoading={loading} />;
  }

  return (
    <MainPageWrapper
      title={t(`general.deliverables`)}
      sources={[`Lantmäteriet`, `Forum Fastighetsekonomi`]}
      menuOptions={['forum_deliverable']}>
      <div className="p-0 lg:p-6 2xl:p-6 h-full">
        {router?.query?.index && router?.query?.index?.length < 3 ? (
          <div className="px-3">
            <div>
              <WrapSection title={t('general.deliverables')} theme={`2.0`}>
                <div className="h-full overflow-scroll">
                  <RowWrapper
                    mapOdd={true}
                    clickable={() => {
                      // const geo = councilsGEO?.find((a) => a?.kod === lc?.uuid);
                      // setLocalCouncil({ ...lc, ...geo });
                      // router.push(`/itismad/map/${lc?.uuid}`);
                    }}
                    theme={`2.0`}
                    title={<div className="font-demi">Göteborg Bostäder - version 1.0</div>}>
                    <div className="w-[15%] font-demi text-left">Skapad av</div>
                    <div className="w-[15%] font-demi text-left">Datum</div>
                    <div className="w-[15%] font-demi text-left">Geo Package</div>
                    <div className="w-[10%] font-demi text-left">CSV</div>
                    <div className="w-[10%] font-demi text-left">Shape</div>
                    <div className="w-[10%] font-demi text-left">GeoJSON</div>
                  </RowWrapper>

                  <RowWrapper
                    mapOdd={true}
                    clickable={() => {
                      // const geo = councilsGEO?.find((a) => a?.kod === lc?.uuid);
                      // setLocalCouncil({ ...lc, ...geo });
                      // router.push(`/itismad/map/${lc?.uuid}`);
                    }}
                    theme={`2.0`}
                    title={`Göteborg Bostäder - version 1.0`}>
                    <div className="w-[15%] text-left">Malin Reidarsson</div>
                    <div className="w-[15%] text-left">29 augusti, 2024</div>

                    <div className="w-[15%] text-left">
                      <Download05Icon className="text-gray-900 dark:text-white" />
                    </div>

                    <div className="w-[10%] text-left">
                      <Download05Icon className="text-gray-900 dark:text-white" />
                    </div>

                    <div className="w-[10%] text-left">
                      <Download05Icon className="text-gray-900 dark:text-white" />
                    </div>

                    <div className="w-[10%] text-left">
                      <Download05Icon className="text-gray-900 dark:text-white" />
                    </div>
                  </RowWrapper>
                </div>
              </WrapSection>
            </div>
          </div>
        ) : null}
      </div>
    </MainPageWrapper>
  );
};
