/* External Libraries */
import { LinearProgress } from '@mui/material';
import cls from 'classnames';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { getCategoryName } from 'shared/dist/constants';
import { getPropTypesFromTaxCodes } from 'shared/dist/helpers/propertyTaxes';
import { PropertyVM } from 'shared/dist/types/property2';
import { getValueYearString } from 'shared/dist/util/rent';

import SectionHeader from '@/src/widgets/section-header';

import { MarketData } from '../mdat-grid';

export default function MarketDataTable({ object }: { object: PropertyVM }) {
  const [loading, setLoading] = useState<boolean>(true);
  const router = useRouter();
  const { t } = useTranslation('common');

  const [marketData, setMarketData] = useState<MarketData[]>([]);

  const fetchMarketData = async () => {
    try {
      const tc: number[] = [...object.taxCodes];
      if (tc.length > 0 && tc[0] === 321) {
        tc.push(325);
      }
      const res = await fetch('/api/prop/getRent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          uuid: object.uuid,
          loadAll: false,
          propTypes: getPropTypesFromTaxCodes(tc),
          includeBRF: object.isBRF
        })
      });
      const data = await res.json();

      let filteredMarketData: MarketData[] = data?.market_data?.filter((x) => x.is_primary);
      const sortOrder = ['residential', 'industry', 'office', 'retail'];
      filteredMarketData.sort((a, b) => {
        const aIndex = sortOrder.indexOf(a.property_type);
        const bIndex = sortOrder.indexOf(b.property_type);
        if (aIndex === -1 && bIndex === -1) {
          return a.property_type.localeCompare(b.property_type);
        }
        if (aIndex === -1) return 1;
        if (bIndex === -1) return -1;
        return aIndex - bIndex;
      });

      if (filteredMarketData.some((data) => data.property_type === 'residential')) {
        filteredMarketData = filteredMarketData.filter((data) => data.property_type !== 'retail');
      }

      setMarketData(filteredMarketData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching market data', error);
    }
  };

  useEffect(() => {
    fetchMarketData();
  }, []);

  const renderLogoCell = (institute: string): React.JSX.Element => (
    <td className="px-2 py-1 w-[90px] xl:w-[120px]">
      <div className="bg-white flex justify-center rounded-md p-3">
        {institute === 'newsec' ? (
          <Image
            src={`/images/Newsec_Logo_Two_Colored_RGB.svg`}
            height={15}
            width={55}
            alt={t('general.newsec')}
          />
        ) : (
          <Image src={`/images/forum-logo.jpg`} height={20} width={55} alt={t('general.forum')} />
        )}
      </div>
    </td>
  );

  return (
    <div className="w-full mt-4 px-6 lg:px-0">
      <SectionHeader title={t('general.market-data-overview')} />

      <button
        onClick={() => router.push(`/crm/fastighet/${object.uuid}/mdat`)}
        className={cls(
          'p-3 transition-colors border border-gray-100 bg-gray-50 w-full duration-200 break-inside-avoid rounded dark:bg-dark-lessfaded dark:text-white dark:border-dark-faded hover:bg-bobo-proplight dark:hover:bg-gray-800 relative'
        )}>
        {!loading && marketData.length === 0 ? (
          <div className="px-3 py-1 flex justify-start text-gray-400 text-xs italic">
            {t('general.no-market-data')}
          </div>
        ) : (
          <table className="table-auto text-xs text-left w-full">
            <thead className="border-b border-gray-200 dark:border-dark-morefaded w-full">
              <tr className="font-normal">
                <th className="px-2 py-1 min-w-[90px] xl:min-w-[120px]" />
                <th className="px-2 py-1 min-w-[125px]">{t('property.space-type')}</th>
                <th className="px-2 py-1">{t('property.location')}</th>
                <th className="px-2 py-1 text-right">{t(`column-headers.yield`)}</th>
              </tr>
            </thead>

            <tbody>
              {loading ? (
                ['newsec', 'forum'].map((row, idx) => {
                  return (
                    <tr
                      className="bg-gray-400 dark:bg-black even:bg-opacity-5 dark:even:bg-opacity-15 odd:!bg-opacity-0 border-b border-gray-200 dark:border-dark-morefaded last:rounded-b-md"
                      key={idx}>
                      {renderLogoCell(row)}

                      <td className="px-2 py-2" colSpan={4}>
                        <div className="font-medium">
                          <LinearProgress color="secondary" />
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <>
                  {marketData.map((row, idx) => {
                    const valueYearString = getValueYearString(row.value_year_interval, t);

                    return (
                      <tr
                        className="bg-gray-400 dark:bg-black even:bg-opacity-5 dark:even:bg-opacity-15 odd:!bg-opacity-0 border-b border-gray-200 dark:border-dark-morefaded last:rounded-b-md"
                        key={idx}>
                        {renderLogoCell(row.institute)}

                        <td className="px-2 py-1">
                          <div className="font-medium">{t(getCategoryName(row.property_type))}</div>
                          <div className="italic text-gray-400">{valueYearString}</div>
                        </td>

                        <td className="px-2">
                          <div className="font-demi">{row.class}</div>
                          <div>
                            {row.unclassified_fallback
                              ? t('general.entire-municipality')
                              : row.area}
                          </div>
                        </td>
                        <td className="px-2 text-right">
                          <NumberFormat
                            value={row.yield_min}
                            displayType="text"
                            decimalScale={2}
                            decimalSeparator=","
                            suffix="%"
                          />{' '}
                          -{' '}
                          <NumberFormat
                            value={row.yield_max}
                            displayType="text"
                            decimalScale={2}
                            decimalSeparator=","
                            suffix="%"
                          />
                        </td>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </table>
        )}
      </button>
    </div>
  );
}
