import { Feature, featureCollection } from '@turf/helpers';
import { useEffect } from 'react';
import { useMap } from 'react-map-gl';
import { GotoFeature } from 'shared/dist/types';
import { useSlideIn } from 'src/context/slide-in-context';

export default function BuildingPermits({
  features,
  loaded
}: {
  localCouncilName?: string;
  primaryObject?: null | any;
  features: Feature[];
  loaded: boolean;
}) {
  const { mapContainer } = useMap();
  const map = mapContainer?.getMap();
  const { slideIn, setSlideIn } = useSlideIn();

  useEffect(() => {
    if (features && features?.length > 0 && loaded) {
      showPermits();
    }
  }, [...features, loaded]);

  const viewObject = (object: any, type: GotoFeature) => {
    setSlideIn({ object, type });
  };

  const showPermits = () => {
    if (features && features?.length > 0) {
      const collection: any = featureCollection(features as Feature[]);

      if (!map?.getSource('permits')) {
        map?.addSource('permits', {
          type: 'geojson',
          data: collection
        });
      }

      !map?.getLayer('permit-circle-uncluster-point') &&
        map?.addLayer({
          id: 'permit-circle-uncluster-point',
          type: 'circle',
          source: 'permits',
          paint: {
            'circle-color': '#0A9396',
            'circle-radius': 12,
            'circle-stroke-width': 4,
            'circle-stroke-color': '#fff'
          }
        });

      map?.moveLayer('prop-area', 'permit-circle-uncluster-point');

      map?.on('mousemove', 'permit-circle-uncluster-point', () => {
        map.getCanvas().style.cursor = 'pointer';
      });
      map?.on('mouseleave', 'permit-circle-uncluster-point', () => {
        map.getCanvas().style.cursor = '';
      });

      const permitUnclusterPointClick = (e) => {
        // mark event as cancelled so other click handlers can ignore it.
        e.originalEvent.cancelBubble = true;

        const features: any[] = map?.queryRenderedFeatures(e.point, {
          layers: ['permit-circle-uncluster-point']
        }) as any[];
        if (e?.features && e?.features?.length > 0) {
          const feature: any = e.features[0];
          const coordinates = feature.geometry?.coordinates?.slice();
          const property = feature?.properties?.property;
          const authorityNickname =
            feature?.properties?.authorityNickname ?? feature?.properties?.authority_nickname;
          const county = feature?.properties?.county;
          // const propType = feature?.properties?.propType;
          // const area = feature?.properties?.area;
          const propertyUUID = feature?.properties?.propertyUUID;
          const lat = e.lngLat.lat;
          const lng = e.lngLat.lng;

          const details: any[] = [];
          e?.features?.map(async (a) => {
            const tmp = JSON.parse(a.properties?.details);
            if (!details.find((bb) => bb.kungorelseid === tmp.kungorelseid)) {
              details.push(tmp);
            }
          });

          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }
          viewObject(
            { property, details, authorityNickname, propertyUUID, county, lat, lng },
            GotoFeature.PROPERTY_PERMITS
          );
        }
      };

      map?.on('click', 'permit-circle-uncluster-point', permitUnclusterPointClick);
      map?.on('touchend', 'permit-circle-uncluster-point', permitUnclusterPointClick);
    }
  };
  return <div />;
}
