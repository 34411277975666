import { ChatBubbleLeftIcon } from '@heroicons/react/24/solid';
import moment from 'moment';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobal } from 'reactn';
import { Actor2 } from 'shared/dist/types';
import { compare_timestamp } from 'src/lib/utils/sort';

const FLTextEditor = dynamic(() => import('@/src/components/overview/crm-activity-editor'), {
  ssr: false
});

export function CRMActivities({ object }: { object: any; close: any }) {
  const [activities, setActivities] = useState<any[]>([]);
  const [_isClient, setIsClient] = useState(false);
  const { t } = useTranslation('common');
  const [activeActor, _] = useGlobal<{ activeActor: Actor2 }>('activeActor' as never);

  // Wait until after client-side hydration to show
  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    fetch(`/api/crm/fetchActivitiesForCard`, {
      method: 'post',
      body: JSON.stringify({
        actor_uuid: activeActor?.actor_uuid,
        listId: object?.listId,
        boardId: object?.boardId,
        uuid: object?.uuid
      })
    })
      .then((res) => {
        return res?.json();
      })
      .then((json) => {
        setActivities([...json]);
        return json;
      })
      .catch(() => {
        console.log('failed to fetch');
      });
    setActivities(object?.cardActivities);
  }, [object?.id, object?.listId]);

  return (
    <div className="w-full">
      <div className="border border-gray-100 dark:border-dark-morefaded bg-gray-50 dark:bg-dark-lessfaded rounded-sm shadow text-[12px]">
        <div className="rounded-b-sm w-full p-4">
          <FLTextEditor object={object} setActivities={setActivities} />
        </div>
      </div>

      {!activities ||
        (activities?.length < 1 && (
          <div className="w-full m-auto text-gray-500 dark:text-gray-300 border border-dashed border-bobo-prop dark:border-dark-prop rounded-md p-4 text-center text-xs my-4 text-[13px]">
            {t('crm.activity-type.add-first-activity')}
          </div>
        ))}

      {activities?.sort(compare_timestamp)?.map((activity, idx) => {
        const messageBody: any = activity?.messageBody ? JSON.parse(activity?.messageBody) : ``;

        const icon = <ChatBubbleLeftIcon className="h-4 w-4 text-bobo-prop dark:text-dark-prop" />;

        return (
          <div className="mt-2 text-center" key={idx}>
            <div className="text-xs text-gray-500 dark:text-gray-300 text-center border-b border-gray-300 dark:border-dark-morefaded inline-flex flex-col">
              <div className="text-bobo-black dark:text-white font-bold">{activity?.creator}</div>
              <div className="italic text-mini">
                {moment(activity?.timestamp * 1000).format('YYYY-MM-DD HH:mm')}
              </div>
            </div>

            <div className="h-2 w-1 border-l border-dashed border-gray-300 dark:border-dark-morefaded m-auto" />

            <div className="flex items-center justify-start space-x-2">
              <div className="bg-bobo-proplight dark:bg-dark-morefaded p-3 rounded-full">
                {icon}
              </div>

              <div className="border border-gray-100 dark:border-gray-500 bg-gray-50 overflow-hidden rounded-sm shadow-sm text-[10px] mt-1 dark:bg-dark-morefaded w-full">
                <div className="rounded-b-sm p-4 text-[13px] text-gray-700 dark:text-white leading-[16px] text-left">
                  <div dangerouslySetInnerHTML={{ __html: messageBody?.text }} />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
