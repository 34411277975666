import { RiskGroup, RiskHandle, RiskLevel } from 'shared/dist/types/risk';

export const createObjectESG = (esgData: any): RiskHandle => {
  return {
    vulnerable_area: {
      impactLevel: esgData.social.vulnerable_area.impact_level,
      riskLevel: esgData.social.vulnerable_area.risk_level,
      riskType: esgData.social.vulnerable_area.type,
      riskGroup: RiskGroup.SOCIAL_FACTORS,
      motivation: esgData.social.vulnerable_area.motivation,
      title: esgData.social.vulnerable_area.title,
      index: esgData.social.vulnerable_area.key,
      source: esgData.social.vulnerable_area.source,
      layers: esgData.social.vulnerable_area.layers,
      features: esgData.social.vulnerable_area.features,
      helpTextId: 'riskVulnerableArea'
    },
    population_trend: {
      impactLevel: esgData.social.population.impact_level,
      riskLevel: esgData.social.population.risk_level,
      riskGroup: RiskGroup.SOCIAL_FACTORS,
      motivation: esgData.social.population.motivation,
      title: esgData.social.population.title,
      index: esgData.social.population.key,
      source: esgData.social.population.source,
      layers: esgData.social.population.layers,
      features: esgData.social.population.features,
      helpTextId: 'riskPopulationTrend'
    },
    toxic: {
      impactLevel: esgData.toxic.impact_level,
      riskLevel: esgData.toxic.risk_level,
      riskGroup: RiskGroup.POLLUTED_AREA,
      motivation: esgData.toxic.motivation,
      title: esgData.toxic.title,
      index: esgData.toxic.key,
      source: esgData.toxic.source,
      layers: esgData.toxic.layers,
      features: esgData.toxic.features,
      helpTextId: 'riskPolution'
    },
    // sulphite: {
    //   impactLevel: esgData.toxic.impact_level,
    //   riskLevel: esgData.toxic.risk_level,
    //   riskGroup: RiskGroup.SOCIAL_FACTORS,
    //   motivation: esgData.toxic.motivation,
    //   title: esgData.toxic.title,
    //   index: `Förekomst av Sulfidberg`, //esgData.toxic.key,
    //   source: esgData.toxic.source
    // },
    natura_2000: {
      impactLevel: esgData.protected_areas.natura2000.impact_level,
      riskLevel: esgData.protected_areas.natura2000.risk_level,
      riskGroup: RiskGroup.SOCIAL_FACTORS,
      motivation: esgData.protected_areas.natura2000.motivation,
      title: esgData.protected_areas.natura2000.title,
      index: esgData.protected_areas.natura2000.key,
      source: esgData.protected_areas.natura2000.source,
      layers: esgData.protected_areas.natura2000.layers,
      features: esgData.protected_areas.natura2000.features,
      helpTextId: 'riskNatura2000'
    },
    nature_reserve: {
      impactLevel: esgData.protected_areas.nature_reserve.impact_level,
      riskLevel: esgData.protected_areas.nature_reserve.risk_level,
      riskGroup: RiskGroup.SOCIAL_FACTORS,
      motivation: esgData.protected_areas.nature_reserve.motivation,
      title: esgData.protected_areas.nature_reserve.title,
      index: esgData.protected_areas.nature_reserve.key,
      source: esgData.protected_areas.nature_reserve.source,
      layers: esgData.protected_areas.nature_reserve.layers,
      features: esgData.protected_areas.nature_reserve.features,
      helpTextId: 'riskNatureReserve'
    },
    protected_area: {
      impactLevel: esgData.protected_areas.nature_reserve.impact_level,
      riskLevel: RiskLevel.NA,
      riskGroup: RiskGroup.SOCIAL_FACTORS,
      motivation: esgData.protected_areas.nature_reserve.motivation,
      title: esgData.protected_areas.nature_reserve.title,
      index: 'risk.protected_area',
      source: esgData.protected_areas.nature_reserve.source,
      layers: esgData.protected_areas.nature_reserve.layers,
      features: esgData.protected_areas.nature_reserve.features
    },
    nature_care_area: {
      impactLevel: esgData.protected_areas.nature_care_area.impact_level,
      riskLevel: esgData.protected_areas.nature_care_area.risk_level,
      riskGroup: RiskGroup.SOCIAL_FACTORS,
      motivation: esgData.protected_areas.nature_care_area.motivation,
      title: esgData.protected_areas.nature_care_area.title,
      index: esgData.protected_areas.nature_care_area.key,
      source: esgData.protected_areas.nature_care_area.source,
      layers: esgData.protected_areas.nature_care_area.layers,
      features: esgData.protected_areas.nature_care_area.features,
      helpTextId: 'riskNatureCareArea'
    },
    water_protection_area: {
      impactLevel: esgData.protected_areas.water_protection_area.impact_level,
      riskLevel: esgData.protected_areas.water_protection_area.risk_level,
      riskGroup: RiskGroup.SOCIAL_FACTORS,
      motivation: esgData.protected_areas.water_protection_area.motivation,
      title: esgData.protected_areas.water_protection_area.title,
      index: esgData.protected_areas.water_protection_area.key,
      source: esgData.protected_areas.water_protection_area.source,
      layers: esgData.protected_areas.water_protection_area.layers,
      features: esgData.protected_areas.water_protection_area.features,
      helpTextId: 'riskWaterProtectionArea'
    },
    water_presence: {
      impactLevel: esgData.water.water_presence.impact_level,
      riskLevel: esgData.water.water_presence.risk_level,
      riskGroup: RiskGroup.SOCIAL_FACTORS,
      motivation: esgData.water.water_presence.motivation,
      title: esgData.water.water_presence.title,
      index: esgData.water.water_presence.key,
      source: esgData.water.water_presence.source,
      layers: esgData.water.water_presence.layers,
      features: esgData.water.water_presence.features,
      helpTextId: 'riskWaterPresence'
    },
    wetlands: {
      impactLevel: esgData.water.wetlands.impact_level,
      riskLevel: esgData.water.wetlands.risk_level,
      riskGroup: RiskGroup.SOCIAL_FACTORS,
      motivation: esgData.water.wetlands.motivation,
      title: esgData.water.wetlands.title,
      index: esgData.water.wetlands.key,
      source: esgData.water.wetlands.source,
      layers: esgData.water.wetlands.layers,
      features: esgData.water.wetlands.features,
      helpTextId: 'riskWetlands'
    },
    bio: {
      impactLevel: esgData.protected_areas.bio.impact_level,
      riskLevel: esgData.protected_areas.bio.risk_level,
      riskGroup: RiskGroup.SOCIAL_FACTORS,
      motivation: esgData.protected_areas.bio.motivation,
      title: esgData.protected_areas.bio.title,
      index: esgData.protected_areas.bio.key,
      source: esgData.protected_areas.bio.source,
      layers: esgData.protected_areas.bio.layers,
      features: esgData.protected_areas.bio.features,
      helpTextId: 'riskBioArea'
    },
    national_park: {
      impactLevel: esgData.protected_areas.national_park.impact_level,
      riskLevel: esgData.protected_areas.national_park.risk_level,
      riskGroup: RiskGroup.SOCIAL_FACTORS,
      motivation: esgData.protected_areas.national_park.motivation,
      title: esgData.protected_areas.national_park.title,
      index: esgData.protected_areas.national_park.key,
      source: esgData.protected_areas.national_park.source,
      layers: esgData.protected_areas.national_park.layers,
      features: esgData.protected_areas.national_park.features,
      helpTextId: 'riskNationalPark'
    },
    interim_ban: {
      impactLevel: esgData.protected_areas.interim_ban.impact_level,
      riskLevel: esgData.protected_areas.interim_ban.risk_level,
      riskGroup: RiskGroup.SOCIAL_FACTORS,
      motivation: esgData.protected_areas.interim_ban.motivation,
      title: esgData.protected_areas.interim_ban.title,
      index: esgData.protected_areas.interim_ban.key,
      source: esgData.protected_areas.interim_ban.source,
      layers: esgData.protected_areas.interim_ban.layers,
      features: esgData.protected_areas.interim_ban.features,
      helpTextId: 'riskInterimBan'
    },
    dewatering: {
      impactLevel: esgData.protected_areas.dewatering.impact_level,
      riskLevel: esgData.protected_areas.dewatering.risk_level,
      riskGroup: RiskGroup.SOCIAL_FACTORS,
      motivation: esgData.protected_areas.dewatering.motivation,
      title: esgData.protected_areas.dewatering.title,
      index: esgData.protected_areas.dewatering.key,
      source: esgData.protected_areas.dewatering.source,
      layers: esgData.protected_areas.dewatering.layers,
      features: esgData.protected_areas.dewatering.features,
      helpTextId: 'riskDewatering'
    },
    historic_sites: {
      impactLevel: esgData.land.historic_sites.impact_level,
      riskLevel: esgData.land.historic_sites.risk_level,
      riskGroup: RiskGroup.SOCIAL_FACTORS,
      motivation: esgData.land.historic_sites.motivation,
      title: esgData.land.historic_sites.title,
      index: esgData.land.historic_sites.key,
      source: esgData.land.historic_sites.source,
      layers: esgData.land.historic_sites.layers,
      features: esgData.land.historic_sites.features,
      helpTextId: 'riskHistoricSites'
    },
    fire: {
      impactLevel: esgData.climate.fire.impact_level,
      riskLevel: esgData.climate.fire.risk_level,
      riskGroup: RiskGroup.CLIMATE,
      motivation: { key: 'misc.blank', options: { ns: 'shared' } },
      title: esgData.climate.fire.key,
      layers: esgData.climate.fire.layers,
      index: esgData.climate.fire.key,
      source: esgData.climate.fire.source,
      features: [],
      helpTextId: 'riskFire'
    },
    fire_open: {
      impactLevel: esgData.climate.fire_open.impact_level,
      riskLevel: esgData.climate.fire_open.risk_level,
      riskGroup: RiskGroup.CLIMATE,
      motivation: esgData.climate.fire_open.motivation,
      title: esgData.climate.fire_open.title,
      layers: esgData.climate.fire_open.layers,
      index: esgData.climate.fire_open.key,
      source: esgData.climate.fire_open.source,
      features: esgData.climate.fire_open.features
    },
    fire_woods: {
      impactLevel: esgData.climate.fire_woods.impact_level,
      riskLevel: esgData.climate.fire_woods.risk_level,
      riskGroup: RiskGroup.CLIMATE,
      motivation: esgData.climate.fire_woods.motivation,
      title: esgData.climate.fire_woods.title,
      layers: esgData.climate.fire_woods.layers,
      index: esgData.climate.fire_woods.key,
      source: esgData.climate.fire_woods.source,
      features: esgData.climate.fire_woods.features
    },
    // skyfall: {
    //   impactLevel: esgData.climate.fire_woods.impact_level,
    //   riskLevel: esgData.climate.fire_woods.risk_level,
    //   riskGroup: RiskGroup.CLIMATE,
    //   motivation: esgData.climate.fire_woods.motivation,
    //   title: `Ras, skred och erosion`, //esgData.climate.landslide.title,
    //   index: `Skyfall`, //esgData.climate.landslide.key,
    //   source: [] //esgData.climate.landslide.source
    // },
    draught: {
      impactLevel: esgData.climate.draught.impact_level,
      riskLevel: esgData.climate.draught.risk_level,
      riskGroup: RiskGroup.CLIMATE,
      motivation: esgData.climate.draught.motivation,
      title: esgData.climate.draught.title,
      layers: esgData.climate.draught.layers,
      index: esgData.climate.draught.key,
      source: esgData.climate.draught.source,
      features: esgData.climate.draught.features,
      helpTextId: 'riskDraught'
    },
    flooding_summary: {
      riskLevel: esgData.flooding.flooding_summary.risk_level,
      riskGroup: RiskGroup.FLOODING_OCEAN,
      motivation: { key: 'misc.blank', options: { ns: 'shared' } },
      title: esgData.flooding.flooding_summary.key,
      index: `risk.flooding-summary`,
      source: esgData.flooding.flooding_summary.source,
      layers: esgData.flooding.flooding_summary.layers,
      features: [],
      helpTextId: `riskFloodingRiver`
    },
    flooding_inland_threat_bhf: esgData.flooding.flooding_inland_threat_bhf
      ? {
          impactLevel: esgData.flooding.flooding_inland_threat_bhf.impact_level,
          riskLevel: esgData.flooding.flooding_inland_threat_bhf.risk_level,
          riskGroup: RiskGroup.FLOODING_OCEAN,
          motivation: esgData.flooding.flooding_inland_threat_bhf.motivation,
          title: esgData.flooding.flooding_inland_threat_bhf.title,
          index: esgData.flooding.flooding_inland_threat_bhf.key,
          source: esgData.flooding.flooding_inland_threat_bhf.source,
          layers: esgData.flooding.flooding_inland_threat_bhf.layers,
          features: esgData.flooding.flooding_inland_threat_bhf.features,
          helpTextId: 'riskFloodingRiver'
        }
      : undefined,
    flooding_inland_threat_50: esgData.flooding.flooding_inland_threat_50
      ? {
          impactLevel: esgData.flooding.flooding_inland_threat_50.impact_level,
          riskLevel: esgData.flooding.flooding_inland_threat_50.risk_level,
          riskGroup: RiskGroup.FLOODING_OCEAN,
          motivation: esgData.flooding.flooding_inland_threat_50.motivation,
          title: esgData.flooding.flooding_inland_threat_50.title,
          index: esgData.flooding.flooding_inland_threat_50.key,
          source: esgData.flooding.flooding_inland_threat_50.source,
          layers: esgData.flooding.flooding_inland_threat_50.layers,
          features: esgData.flooding.flooding_inland_threat_50.features,
          helpTextId: 'riskFloodingRiver'
        }
      : undefined,
    flooding_inland_threat_100: esgData.flooding.flooding_inland_threat_100
      ? {
          impactLevel: esgData.flooding.flooding_inland_threat_100.impact_level,
          riskLevel: esgData.flooding.flooding_inland_threat_100.risk_level,
          riskGroup: RiskGroup.FLOODING_OCEAN,
          motivation: esgData.flooding.flooding_inland_threat_100.motivation,
          title: esgData.flooding.flooding_inland_threat_100.title,
          index: esgData.flooding.flooding_inland_threat_100.key,
          source: esgData.flooding.flooding_inland_threat_100.source,
          layers: esgData.flooding.flooding_inland_threat_100.layers,
          features: esgData.flooding.flooding_inland_threat_100.features,
          helpTextId: 'riskFloodingRiver'
        }
      : undefined,
    flooding_inland_100: esgData.flooding.flooding_inland_100
      ? {
          impactLevel: esgData.flooding.flooding_inland_100.impact_level,
          riskLevel: esgData.flooding.flooding_inland_100.risk_level,
          riskGroup: RiskGroup.FLOODING_OCEAN,
          motivation: esgData.flooding.flooding_inland_100.motivation,
          title: esgData.flooding.flooding_inland_100.title,
          index: esgData.flooding.flooding_inland_100.key,
          source: esgData.flooding.flooding_inland_100.source,
          layers: esgData.flooding.flooding_inland_100.layers,
          features: esgData.flooding.flooding_inland_100.features,
          helpTextId: 'riskFloodingRiver'
        }
      : undefined,
    flooding_inland_200: esgData.flooding.flooding_inland_200
      ? {
          impactLevel: esgData.flooding.flooding_inland_200.impact_level,
          riskLevel: esgData.flooding.flooding_inland_200.risk_level,
          riskGroup: RiskGroup.FLOODING_OCEAN,
          motivation: esgData.flooding.flooding_inland_200.motivation,
          title: esgData.flooding.flooding_inland_200.title,
          index: esgData.flooding.flooding_inland_200.key,
          source: esgData.flooding.flooding_inland_200.source,
          layers: esgData.flooding.flooding_inland_200.layers,
          features: esgData.flooding.flooding_inland_200.features,
          helpTextId: 'riskFloodingRiver'
        }
      : undefined,
    flooding_inland_bhf: esgData.flooding.flooding_inland_bhf
      ? {
          impactLevel: esgData.flooding.flooding_inland_bhf.impact_level,
          riskLevel: esgData.flooding.flooding_inland_bhf.risk_level,
          riskGroup: RiskGroup.FLOODING_OCEAN,
          motivation: esgData.flooding.flooding_inland_bhf.motivation,
          title: esgData.flooding.flooding_inland_bhf.title,
          index: esgData.flooding.flooding_inland_bhf.key,
          source: esgData.flooding.flooding_inland_bhf.source,
          layers: esgData.flooding.flooding_inland_bhf.layers,
          features: esgData.flooding.flooding_inland_bhf.features,
          helpTextId: 'riskFloodingRiver'
        }
      : undefined,
    // Mark for deletion
    // flooding_lake_rivers: {
    //   impactLevel: esgData.flooding.flooding_lake_rivers.impact_level,
    //   riskLevel: esgData.flooding.flooding_lake_rivers.risk_level,
    //   riskGroup: RiskGroup.FLOODING_OCEAN,
    //   motivation: esgData.flooding.flooding_lake_rivers.motivation,
    //   title: `risk.flooding-lake-rivers`, //esgData.climate.landslide.title,
    //   index: `risk.flooding-lake-rivers`, //esgData.climate.landslide.key,
    //   source: esgData.flooding.flooding_lake_rivers.source,
    //   layers: esgData.flooding.flooding_lake_rivers.layers,
    //   features: esgData.flooding.flooding_lake_rivers.features
    // },
    flooding_malaren: esgData.flooding.flooding_malaren
      ? {
          impactLevel: esgData.flooding.flooding_malaren.impact_level,
          riskLevel: esgData.flooding.flooding_malaren.risk_level,
          riskGroup: RiskGroup.FLOODING_OCEAN,
          motivation: esgData.flooding.flooding_malaren.motivation,
          title: esgData.flooding.flooding_malaren.title,
          index: esgData.flooding.flooding_malaren.key,
          source: esgData.flooding.flooding_malaren.source,
          layers: esgData.flooding.flooding_malaren.layers,
          features: esgData.flooding.flooding_malaren.features
        }
      : undefined,
    flooding_ocean: {
      impactLevel: esgData.flooding.coastal_flooding.impact_level,
      riskLevel: esgData.flooding.coastal_flooding.risk_level,
      riskGroup: RiskGroup.FLOODING_OCEAN,
      motivation: esgData.flooding.coastal_flooding.motivation,
      title: esgData.flooding.coastal_flooding.title,
      index: esgData.flooding.coastal_flooding.key,
      source: esgData.flooding.coastal_flooding.source,
      layers: esgData.flooding.coastal_flooding.layers,
      features: esgData.flooding.coastal_flooding.features
    },
    landslide: {
      impactLevel: esgData.climate.landslide.impact_level,
      riskLevel: esgData.climate.landslide.risk_level,
      riskGroup: RiskGroup.CLIMATE,
      motivation: esgData.climate.landslide.motivation,
      title: esgData.climate.landslide.key,
      layers: esgData.climate.landslide.layers,
      index: esgData.climate.landslide.key,
      source: esgData.climate.landslide.source,
      features: esgData.climate.landslide.features,
      helpTextId: 'riskLandslide'
    },
    ravine: {
      impactLevel: esgData.climate.ravine.impact_level,
      riskLevel: esgData.climate.ravine.risk_level,
      riskGroup: RiskGroup.CLIMATE,
      motivation: esgData.climate.ravine.motivation,
      title: esgData.climate.ravine.title,
      layers: esgData.climate.ravine.layers,
      index: esgData.climate.ravine.key,
      source: esgData.climate.ravine.source,
      features: esgData.climate.ravine.features
    },
    erosion: {
      impactLevel: esgData.climate.erosion.impact_level,
      riskLevel: esgData.climate.erosion.risk_level,
      riskGroup: RiskGroup.CLIMATE,
      motivation: esgData.climate.erosion.motivation,
      title: esgData.climate.erosion.title,
      layers: esgData.climate.erosion.layers,
      index: esgData.climate.erosion.key,
      source: esgData.climate.erosion.source,
      features: esgData.climate.erosion.features
    },
    solid_ground: {
      impactLevel: esgData.climate.solid_ground.impact_level,
      riskLevel: esgData.climate.solid_ground.risk_level,
      riskGroup: RiskGroup.CLIMATE,
      motivation: esgData.climate.solid_ground.motivation,
      title: esgData.climate.solid_ground.title,
      layers: esgData.climate.solid_ground.layers,
      index: esgData.climate.solid_ground.key,
      source: esgData.climate.solid_ground.source,
      features: esgData.climate.solid_ground.features
    },
    landslide_fine_grained: {
      impactLevel: esgData.climate.landslide_fine_grained.impact_level,
      riskLevel: esgData.climate.landslide_fine_grained.risk_level,
      riskGroup: RiskGroup.CLIMATE,
      motivation: esgData.climate.landslide_fine_grained.motivation,
      title: esgData.climate.landslide_fine_grained.title,
      layers: esgData.climate.landslide_fine_grained.layers,
      index: esgData.climate.landslide_fine_grained.key,
      source: esgData.climate.landslide_fine_grained.source,
      features: esgData.climate.landslide_fine_grained.features
    },
    landslide_zones: {
      impactLevel: esgData.climate.landslide_zones.impact_level,
      riskLevel: esgData.climate.landslide_zones.risk_level,
      riskGroup: RiskGroup.CLIMATE,
      motivation: esgData.climate.landslide_zones.motivation,
      title: esgData.climate.landslide_zones.title,
      layers: esgData.climate.landslide_zones.layers,
      index: esgData.climate.landslide_zones.key,
      source: esgData.climate.landslide_zones.source,
      features: esgData.climate.landslide_zones.features
    },
    storm: {
      impactLevel: esgData.climate.storm.impact_level,
      riskLevel: esgData.climate.storm.risk_level,
      riskGroup: RiskGroup.CLIMATE,
      motivation: esgData.climate.storm.motivation,
      title: esgData.climate.storm.title,
      layers: esgData.climate.storm.layers,
      index: esgData.climate.storm.key,
      source: esgData.climate.storm.source,
      features: esgData.climate.storm.features,
      helpTextId: 'riskStorm'
    },
    heat_summary: {
      impactLevel: esgData.climate.heat_summary.impact_level,
      riskLevel: esgData.climate.heat_summary.risk_level,
      riskGroup: RiskGroup.CLIMATE,
      motivation: esgData.climate.heat_summary.motivation,
      title: esgData.climate.heat_summary.key,
      layers: esgData.climate.heat_summary.layers,
      index: esgData.climate.heat_summary.key,
      source: esgData.climate.heat_summary.source,
      features: [],
      helpTextId: 'riskHeat'
    },
    heat: {
      impactLevel: esgData.climate.heat.impact_level,
      riskLevel: esgData.climate.heat.risk_level,
      riskGroup: RiskGroup.CLIMATE,
      motivation: esgData.climate.heat.motivation,
      title: esgData.climate.heat.title,
      layers: esgData.climate.heat.layers,
      index: esgData.climate.heat.key,
      source: esgData.climate.heat.source,
      features: esgData.climate.heat.features
    },
    heat_spots: {
      impactLevel: esgData.climate.heat_spots.impact_level,
      riskLevel: esgData.climate.heat_spots.risk_level,
      riskGroup: RiskGroup.CLIMATE,
      motivation: esgData.climate.heat_spots.motivation,
      title: esgData.climate.heat_spots.title,
      layers: esgData.climate.heat_spots.layers,
      index: esgData.climate.heat_spots.key,
      source: esgData.climate.heat_spots.source,
      features: esgData.climate.heat_spots.features
    },
    energy: {
      impactLevel: esgData.climate.energy.impact_level,
      riskLevel: esgData.climate.energy.risk_level,
      riskGroup: RiskGroup.CLIMATE,
      motivation: esgData.climate.energy.motivation,
      title: esgData.climate.energy.title,
      layers: esgData.climate.energy.layers,
      index: esgData.climate.energy.key,
      source: esgData.climate.energy.source,
      features: esgData.climate.energy.features
    }
  };
};
