import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import cls from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import ImageCutOut from 'public/images/shapes/image-cutout-top-left-triangle.svg';
import { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { Actor2, PropertyReportData } from 'shared/dist/types';
import { Error, Scooter } from 'src/animations';

import { PresentationIcon, TextFileIcon, TickIcon } from '@/src/assets/icons';
import { crmDialogTheme } from '@/src/components/dialogs/crm/dialog-theme';
import SignedGoogleMapsImage from '@/src/components/map/signed-google-maps-image';
import { useBreakpoint } from '@/src/hooks/use-breakpoint';
import { getActorLogo, getActorSettings } from '@/src/lib/actor-client-wrapper';
import { getPropertyReport } from '@/src/lib/prop-client-wrapper';

require('dayjs/locale/sv');
require('dayjs/locale/en');

enum COVERS {
  STREET = 'streetview',
  STANDARD1 = 'fl-1',
  STANDARD2 = 'fl-2',
  STANDARD3 = 'fl-3'
}

type ThemeOptions = {
  cover?: string | null;
  coverUrl?: string | null;
  logo?: boolean;
  colour?: boolean;
};

type DataOption = {
  id: string;
  fields: {
    title: string;
    id: string;
    dataIds: string[];
    selected: boolean;
    desc: string | JSX.Element;
  }[];
};

type ReportOptions = {
  theme: ThemeOptions;
  data: Record<string, string[]>[];
  format: 'docx' | 'ppt';
};

const stepNames = {
  report_theme: 1,
  fields: 2,
  file_format: 3,
  loading: 4,
  error: 5
};

function ChooseTheme({
  setStep,
  close,
  latLong,
  themeOptions,
  setOptions,
  property,
  actorSettings,
  today
}: {
  setStep: any;
  close: any;
  latLong: string;
  setOptions: (options) => void;
  themeOptions?: ThemeOptions;
  property: any;
  actorSettings?: {
    CUSTOM_ACCENT?: string;
    LOGO_URL?: string;
  };
  today: Dayjs;
}) {
  const { t } = useTranslation('common');

  const [activeOptions, setActiveOptions] = useState<ThemeOptions>({
    cover: themeOptions?.cover,
    coverUrl: themeOptions?.coverUrl,
    logo: themeOptions?.logo,
    colour: themeOptions?.colour
  });

  const propNameShort = `${property?.authority_nickname?.replace(property?.county, '')}`;

  const coverData = {
    [COVERS.STREET]: {
      url: `https://maps.googleapis.com/maps/api/streetview?size=600x342&location=${encodeURIComponent(
        latLong
      )}&fov=200&pitch=5&key=AIzaSyBYCMGQ9cdHlRBN414KW_VE0WgTzR6Sqow&source=outdoor`,
      alt: t('images.google-streetview-of-prop', { property: property.authority_nickname }),
      title: t('map.google-street')
    },
    [COVERS.STANDARD1]: {
      url: '/images/reportcovers/malmo-coast.jpg',
      alt: t('images.malmo-coast-alt'),
      title: ''
    },
    [COVERS.STANDARD2]: {
      url: '/images/reportcovers/stockholm-aerial.jpg',
      alt: t('images.stockholm-by-air-alt'),
      title: ''
    },
    [COVERS.STANDARD3]: {
      url: '/images/reportcovers/properties-concept.jpg',
      alt: t('images.properties-concept-alt'),
      title: ''
    }
  };

  const coverButtons: JSX.Element[] = [];

  for (const [key, { url, title, alt }] of Object.entries(coverData)) {
    coverButtons.push(
      <button
        key={key}
        className={cls(
          'flex flex-col relative items-center justify-center text-center rounded-lg cursor-pointer ring-offset-2 hover:ring-2 focus-visible:ring-2  focus-visible:ring-bobo-proplight dark:focus-visible:ring-dark-prop hover:ring-bobo-prop dark:hover:ring-dark-prop w-[130px] has-tooltip transition-all duration-200 ring-offset-white dark:ring-offset-dark-primary',
          {
            'ring-2 ring-bobo-prop dark:ring-dark-prop': activeOptions.cover === key
          }
        )}
        onClick={() => {
          if (activeOptions.cover === key) {
            return;
          }
          setActiveOptions((current) => ({ ...current, cover: key, coverUrl: url }));
        }}>
        {key === COVERS.STREET ? (
          <div className="object-cover">
            <SignedGoogleMapsImage
              height={200}
              width={300}
              priority={true}
              className="object-cover rounded-md"
              src={url}
              alt={alt}
            />
          </div>
        ) : (
          <Image height={200} width={300} className="object-cover rounded-md" src={url} alt={alt} />
        )}

        <p className="tooltip absolute bottom-1 text-xs bg-light-faded dark:bg-dark-faded opacity-90 px-2 rounded-sm">
          {title}
        </p>
      </button>
    );
  }

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          <Trans
            i18nKey="reports.property-report.dialog-heading"
            property={property.authority_nickname}>
            Skapa rapport för
            <div className="text-bobo-prop"> {{ property: property.authority_nickname }}</div>
          </Trans>
        </Dialog.Title>
        <p className={crmDialogTheme.subtext}>
          <Trans i18nKey="reports.property-report.pick-theme-desc">
            Anpassa rapportens utseende med cover-bild, logga och accent-färg. Du kan ändra logga
            och färg i{' '}
            <Link className="text-bobo-prop" href="/companyprofile/settings" onClick={close}>
              företagsinställningar.
            </Link>
          </Trans>
        </p>
      </div>

      <div className="flex flex-col md:flex-row space-x-5 items-center md:items-start justify-evenly w-full gap-5 md:gap-0">
        {/* Preview of report cover */}
        <div className="border-[1px] border-gray-100 shadow-md w-[295px] h-[442.5px] aspect-[2/3] relative flex flex-col items-center pt-6 text-gray-600 bg-light-primary dark:border-dark-lessfaded">
          <div
            className={cls('w-[80%] flex justify-between items-center', {
              '!justify-end': !activeOptions.logo
            })}>
            {activeOptions.logo ? (
              <Image
                height={20}
                width={100}
                className="object-contain w-auto h-auto max-h-[20px] max-w-[100px]"
                src={
                  actorSettings && actorSettings.LOGO_URL
                    ? actorSettings.LOGO_URL
                    : `/images/logo-full2.png`
                }
                alt=""
              />
            ) : (
              <></>
            )}
            <div className="flex flex-col text-[6px] text-right text-light-graytext">
              {t('reports.property-report.preview-header-version')}
              <p className="italic text-[5px] -mt-0.5">{t('reports.created-by-us')}</p>
              <p className="italic text-[5px] -mt-0.5">{today.format('DD MMMM YYYY')}</p>
            </div>
          </div>

          <h3
            className={cls('font-semibold text-[10px] mt-[28%] text-left w-[80%]', {
              'ml-9': !activeOptions.colour
            })}>
            {t('reports.property-report.title')}
          </h3>
          <div className="flex justify-start space-x-3.5 text-left mt-2 w-[80%]">
            <div
              style={{
                backgroundColor: `${
                  activeOptions.colour && actorSettings?.CUSTOM_ACCENT
                    ? actorSettings.CUSTOM_ACCENT
                    : ''
                }`
              }}
              className={cls(`block w-[3px] h-full`, {
                'bg-transparent': !activeOptions.colour,
                'bg-bobo-orange': !actorSettings?.CUSTOM_ACCENT
              })}
            />
            <div>
              <h3 className="text-[7px]">{property.county}</h3>
              <p className="text-xl -mt-0.5 font-semibold">{propNameShort}</p>
              <p className="italic text-[7px] -mt-0.5">
                {t('reports.created-when', { time: today.format('YYYY-MM-DD') })}
              </p>
            </div>
          </div>

          <div className="h-full"></div>
          {activeOptions.cover && activeOptions.coverUrl ? (
            <div className="flex relative w-full h-full">
              {activeOptions.cover === COVERS.STREET ? (
                <>
                  <ImageCutOut className="absolute w-[293px] h-[34px] -top-[1.8px] left-0 text-white" />
                  <SignedGoogleMapsImage
                    height={300}
                    width={400}
                    priority={true}
                    className="object-contain w-[296px] h-auto"
                    src={activeOptions.coverUrl}
                    alt={coverData[activeOptions.cover].alt}
                  />
                </>
              ) : (
                <>
                  <ImageCutOut className="absolute w-[293px] h-[34px] -top-[2px] left-0 text-white" />
                  <Image
                    height={300}
                    width={400}
                    className="object-contain w-[296px] h-auto"
                    src={activeOptions.coverUrl}
                    alt={coverData[activeOptions.cover].alt}
                  />
                </>
              )}
            </div>
          ) : null}
        </div>

        {/* Theme options */}
        <div className="flex flex-col items-center md:align-start !mx-0 mt-4 md:mt-0 w-max">
          <div className="text-sm mt-4 grid grid-cols-2 w-full gap-4 justify-items-center">
            <p className="col-span-2 text-left w-full text-sm">{t('reports.choose-image')}</p>
            {coverButtons}
          </div>

          <button
            className="mt-5 p-1 text-gray-800 dark:text-white flex items-center space-x-3 hover:bg-bobo-proplight w-full text-left col-span-2 dark:hover:bg-dark-lessfaded rounded-sm"
            onClick={() => {
              if (activeOptions.cover) {
                setActiveOptions((current) => ({ ...current, coverUrl: null, cover: null }));
              } else {
                setActiveOptions((current) => ({
                  ...current,
                  coverUrl: coverData[COVERS.STANDARD1].url,
                  cover: COVERS.STANDARD1
                })); // set to default cover if unchecked
              }
            }}>
            <input
              checked={!activeOptions.cover}
              onChange={() => null}
              type="checkbox"
              className={cls(
                'h-5 w-5 text-bobo-prop dark:text-dark-prop border-gray-400 rounded cursor-pointer dark:checked:bg-dark-prop checked:bg-bobo-prop checked:hover:bg-bobo-prophover dark:checked:hover:bg-dark-prophover dark:bg-dark-morefaded dark:border-dark-faded'
              )}
            />
            <p className="text-sm flex items-center gap-3">{t('reports.no-image')}</p>
          </button>

          <button
            className="p-1 text-gray-800 dark:text-white flex items-center space-x-3 hover:bg-bobo-proplight w-full text-left mt-2 has-tooltip relative dark:hover:bg-dark-lessfaded rounded-sm"
            onClick={() => {
              setActiveOptions((current) => ({ ...current, logo: !current.logo }));
            }}>
            <input
              checked={activeOptions.logo}
              onChange={() => null}
              type="checkbox"
              className={cls(
                'h-5 w-5 text-bobo-prop dark:text-dark-prop border-gray-400 rounded cursor-pointer dark:checked:bg-dark-prop checked:bg-bobo-prop checked:hover:bg-bobo-prophover dark:checked:hover:bg-dark-prophover dark:bg-dark-morefaded dark:border-dark-faded'
              )}
            />
            <p className="text-sm w-full">{t('reports.incl-logo')}</p>
            <p className="tooltip absolute top-[68px] md:top-20 right-0 w-full text-xs bg-light-faded dark:bg-dark-faded opacity-90 py-2 px-4 rounded-sm">
              {t('reports.logo-tooltip')}
            </p>
          </button>

          <button
            className="p-1 text-gray-800 dark:text-white flex items-center space-x-3 hover:bg-bobo-proplight w-full text-left mt-2 has-tooltip relative dark:hover:bg-dark-lessfaded rounded-sm"
            onClick={() => {
              setActiveOptions((current) => ({ ...current, colour: !current.colour }));
            }}>
            <input
              checked={activeOptions.colour}
              onChange={() => null}
              type="checkbox"
              className={cls(
                'h-5 w-5 text-bobo-prop dark:text-dark-prop border-gray-400 rounded cursor-pointer dark:checked:bg-dark-prop checked:bg-bobo-prop checked:hover:bg-bobo-prophover dark:checked:hover:bg-dark-prophover dark:bg-dark-morefaded dark:border-dark-faded'
              )}
            />
            <p className="text-sm flex items-center gap-3">{t('reports.incl-accent-colour')}</p>
            <span
              style={{
                backgroundColor: `${
                  activeOptions.colour && actorSettings?.CUSTOM_ACCENT
                    ? actorSettings.CUSTOM_ACCENT
                    : ''
                }`
              }}
              className={cls(`w-4 h-4 rounded-sm`, {
                'bg-bobo-orange': !actorSettings?.CUSTOM_ACCENT
              })}
            />
            <p className="tooltip text-xs absolute top-8 md:top-11 right-0 bg-light-faded dark:bg-dark-faded opacity-90 py-2 px-4 rounded-sm w-full">
              {t('reports.accent-colour-tooltip')}
            </p>
          </button>
        </div>
      </div>

      <div className={crmDialogTheme.buttonContainer}>
        <button
          className={crmDialogTheme.buttonClose}
          onClick={() => {
            close();
          }}>
          {t('general.cancel')}
        </button>

        <button
          className={crmDialogTheme.buttonConfirm}
          onClick={() => {
            setOptions((prev) => ({ ...prev, theme: { ...activeOptions } }));
            setStep(stepNames.fields);
          }}>
          {t('reports.button-next-step-contents')}
        </button>
      </div>
    </div>
  );
}

export const DataCategory = ({
  category,
  updateField
}: {
  category: DataOption;
  updateField: (catId: string, fieldId?: string) => void;
}) => {
  return (
    <>
      {category.fields.map((field) => (
        <button
          key={field.id}
          disabled={field.id === 'baseInfo'}
          className="p-1 px-5 text-gray-800 dark:text-white flex items-center space-x-3 hover:bg-bobo-proplight w-full dark:hover:bg-dark-lessfaded rounded-sm text-left mt-2 disabled:cursor-default has-tooltip"
          onClick={() => {
            if (field.id === 'baseInfo') {
              return;
            }
            updateField(category.id, field.id);
          }}>
          {field.id === 'baseInfo' ? (
            <>
              <TickIcon className="w-5 h-5 text-gray-600 m-auto dark:text-white" />
              <p className="w-full">{field.title}</p>
            </>
          ) : (
            <>
              <input
                checked={field.selected}
                onChange={() => null}
                type="checkbox"
                className={cls(
                  'h-4 w-4 text-bobo-prop dark:text-dark-prop border-gray-400 rounded cursor-pointer dark:checked:bg-dark-prop checked:bg-bobo-prop checked:hover:bg-bobo-prophover dark:checked:hover:bg-dark-prophover dark:bg-dark-morefaded dark:border-dark-faded'
                )}
              />
              <p className="w-full">{field.title}</p>
            </>
          )}
          <div className="tooltip text-xs absolute bottom-10 right-0 w-full flex justify-center">
            <p className="bg-light-faded dark:bg-dark-faded opacity-90 py-2 px-4 rounded-sm w-[80%]">
              {field.desc}
            </p>
          </div>
        </button>
      ))}
    </>
  );
};

function ChooseFields({
  setStep,
  close,
  property,
  setDataFields,
  generateReport,
  dataOptions
}: {
  setStep: any;
  close: any;
  property: any;
  setDataFields: (options) => void;
  generateReport: () => void;
  dataOptions: DataOption[];
}) {
  const { isMd } = useBreakpoint({ breakpoint: 'md' });
  const { t } = useTranslation('common');

  const updateField = (catId: string, fieldId?: string) => {
    setDataFields((prevDataFields: DataOption[]): DataOption[] => {
      return prevDataFields.map((category: DataOption) => {
        let updatedFields: DataOption['fields'] = [];
        if (category.id === catId) {
          updatedFields = category.fields.map((field) => {
            if (field.id === fieldId) {
              return { ...field, selected: !field.selected };
            }
            return field;
          });

          const allFieldsSelected = updatedFields.every((field) => field.selected);

          return {
            ...category,
            selected: allFieldsSelected,
            fields: updatedFields
          };
        }
        return category;
      });
    });
  };

  const totalFields = dataOptions.reduce((prev, currCat) => prev + currCat.fields.length, 0);

  return (
    <div className="flex-grow flex flex-col items-center gap-6 min-h-[450px] relative">
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          <Trans
            i18nKey="reports.property-report.dialog-heading"
            property={property.authority_nickname}>
            Skapa rapport för
            <div className="text-bobo-prop"> {{ property: property.authority_nickname }}</div>
          </Trans>
        </Dialog.Title>
        <p className={crmDialogTheme.subtext}>{t('reports.choose-fields-desc')}</p>
      </div>
      <div
        className={cls(
          'w-full md:w-4/5 grid grid-cols-1 md:grid-cols-2 grid-flow-col grid-rows-7',
          { 'md:grid-rows-3': totalFields < 7 },
          { 'md:grid-rows-4': totalFields > 6 && totalFields < 9 }
        )}>
        {dataOptions?.map((cat) => (
          <DataCategory category={cat} key={cat.id} updateField={updateField} />
        ))}
      </div>
      <div className={crmDialogTheme.buttonContainer}>
        {isMd ? (
          <>
            <button className={crmDialogTheme.buttonClose} onClick={close}>
              {t('general.cancel')}
            </button>
            <div className={crmDialogTheme.subButtonContainer}>
              <button
                className={crmDialogTheme.buttonBack}
                onClick={() => setStep(stepNames.report_theme)}>
                {t('general.back')}
              </button>
              {/* <button
                className={crmDialogTheme.buttonConfirm}
                onClick={() => setStep(stepNames.file_format)}>
                {t('reports.button-next-step-format')}
              </button> */}
              <button className={crmDialogTheme.buttonConfirm} onClick={generateReport}>
                {t('reports.button-create-report')}
              </button>
            </div>
          </>
        ) : (
          <>
            <button
              className={crmDialogTheme.buttonBack}
              onClick={() => setStep(stepNames.report_theme)}>
              {t('general.back')}
            </button>
            {/* <button
              className={crmDialogTheme.buttonConfirm}
              onClick={() => setStep(stepNames.file_format)}>
              {t('reports.button-next-step-format')}
            </button> */}
            <button className={crmDialogTheme.buttonConfirm} onClick={generateReport}>
              {t('reports.button-create-report')}
            </button>
          </>
        )}
      </div>
    </div>
  );
}

function ChooseFormat({
  setStep,
  close,
  property,
  formatOptions,
  setOptions,
  generateReport
}: {
  setStep: any;
  close: any;
  property: any;
  formatOptions: string;
  setOptions: (options) => void;
  generateReport: () => void;
}) {
  const { isMd } = useBreakpoint({ breakpoint: 'md' });
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          <Trans
            i18nKey="reports.property-report.dialog-heading"
            property={property.authority_nickname}>
            Skapa rapport för
            <div className="text-bobo-prop"> {{ property: property.authority_nickname }}</div>
          </Trans>
        </Dialog.Title>
        <p className={crmDialogTheme.subtext}>{t('reports.choose-format-desc')}</p>
      </div>
      <div className="w-full md:w-2/3 flex flex-grow justify-around items-center text-xs text-center mt-2">
        <button
          className={cls(
            'border-2 ring-gray-200 text-center rounded-md px-3 py-5 md:px-10 md:py-6 cursor-pointer hover:bg-bobo-proplight dark:border-dark-morefaded dark:hover:bg-dark-prophover dark:bg-dark-faded focus-visible:ring-2 focus-visible:border-none focus-visible:ring-bobo-prop dark:focus-visible:ring-dark-prop dark:focus-visible:bg-dark-faded dark:hover:focus-visible:bg-dark-prophover w-[130px] md:w-[180px] h-[116px] md:h-max',
            {
              'bg-bobo-proplight border-bobo-prop dark:border-dark-prop': formatOptions === 'docx'
            }
          )}
          onClick={() => {
            const selected = formatOptions === 'docx';
            if (!selected) {
              setOptions((prev) => ({ ...prev, format: 'docx' }));
            }
          }}>
          <TextFileIcon className="w-8 h-8 text-gray-600 m-auto dark:text-white" />
          <div className={crmDialogTheme.privacyTitle}>{t('reports.file-format-docx')}</div>
        </button>

        <button
          onClick={() => {
            const selected = formatOptions === 'ppt';
            if (!selected) {
              setOptions((prev) => ({ ...prev, format: 'ppt' }));
            }
          }}
          className={cls(
            'border-2 ring-gray-200 text-center rounded-md px-3 py-5 md:px-10 md:py-6 cursor-pointer hover:bg-bobo-proplight dark:border-dark-morefaded dark:hover:bg-dark-prophover dark:bg-dark-faded focus-visible:ring-2 focus-visible:border-none focus-visible:ring-bobo-prop dark:focus-visible:ring-dark-prop dark:focus-visible:bg-dark-faded dark:hover:focus-visible:bg-dark-prophover w-[130px] md:w-[180px] h-[116px] md:h-max',
            {
              'bg-bobo-proplight border-bobo-prop dark:border-dark-prop': formatOptions === 'ppt'
            }
          )}>
          <PresentationIcon className="w-8 h-8 text-gray-600 m-auto dark:text-white" />

          <div className={'font-demi mt-2 px-3'}>{t('reports.file-format-ppt')}</div>
        </button>
      </div>
      <div className={crmDialogTheme.buttonContainer}>
        {isMd ? (
          <>
            <button className={crmDialogTheme.buttonClose} onClick={close}>
              {t('general.cancel')}
            </button>
            <div className={crmDialogTheme.subButtonContainer}>
              <button
                className={crmDialogTheme.buttonBack}
                onClick={() => setStep(stepNames.fields)}>
                {t('general.back')}
              </button>
              <button className={crmDialogTheme.buttonConfirm} onClick={generateReport}>
                {t('reports.button-create-report')}
              </button>
            </div>
          </>
        ) : (
          <>
            <button className={crmDialogTheme.buttonBack} onClick={() => setStep(stepNames.fields)}>
              {t('general.back')}
            </button>
            <button className={crmDialogTheme.buttonConfirm} onClick={generateReport}>
              {t('reports.button-create-report')}
            </button>
          </>
        )}
      </div>
    </div>
  );
}

function LoadingStep() {
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Scooter />
      </div>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          {t('reports.loading-report')}
        </Dialog.Title>
        <p className={crmDialogTheme.subtext}>{t('reports.loading-report-desc')}</p>
      </div>
    </div>
  );
}

function ErrorStep({ close, property }: { close: any; property: any }) {
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.icon}>
        <Error />
      </div>
      <div className="flex flex-col w-full text-center items-center">
        <Dialog.Title as="h3" className="text-red-700 w-3/4 leading-6">
          <Trans
            i18nKey="errors.report-generation-error-for-prop"
            property={property.authority_nickname}>
            Något gick fel när vi skulle skapa rapporten för{' '}
            <div className="text-light-inverted dark:text-dark-inverted">
              {{ property: property.authority_nickname }}.
            </div>
          </Trans>
        </Dialog.Title>

        <p className={crmDialogTheme.subtext}>{t('errors.try-again')}</p>
      </div>

      <div className={crmDialogTheme.buttonContainer}>
        <button
          className={crmDialogTheme.buttonClose}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              close();
            }
          }}
          onClick={() => {
            close();
          }}>
          {t('general.close')}
        </button>
      </div>
    </div>
  );
}

/**
 * dialog for adding new and editing list
 */
export default function CreatePropReport({ close, object }: { close: any; object: any }) {
  const [step, setStep] = useState(stepNames.report_theme);
  const [options, setOptions] = useState<ReportOptions>({
    theme: {
      cover: COVERS.STANDARD1,
      coverUrl: '/images/reportcovers/malmo-coast.jpg',
      logo: true,
      colour: true
    },
    data: [],
    format: 'docx'
  });

  const [dataFields, setDataFields] = useState<DataOption[]>([]);

  const [actorSettings, setActorSettings] = useState<{
    CUSTOM_ACCENT?: string;
    LOGO_URL?: string;
  }>();

  const [actor] = useGlobal<{ activeActor: Actor2 }>('activeActor' as never);
  const { t } = useTranslation('common');
  const router = useRouter();

  useEffect(() => {
    const propInfoFields = [
      {
        title: t('property.information-about-property'),
        id: 'baseInfo',
        dataIds: [
          'authority_nickname',
          'authorityNickname',
          'county',
          'districtCode',
          'districtName',
          'council',
          'propertyKey',
          'landLeaseAgreement',
          'previous_property',
          'apartmentCount',
          'buildYears',
          'valueYears',
          'valueArea',
          'buildingPermits',
          'energyStatement',
          'propTax',
          'landOwner',
          'addresses',
          'lat',
          'lng',
          'neighbourhood',
          'uuid'
        ],
        selected: true,
        desc: (
          <Trans i18nKey="reports.property-report.prop-info-tooltip">
            Inkluderar basinformation om fastigheten så som Fastighetsbeteckning, kommun,
            fastighetsyckel, byggår, värdeår mm. <strong>Alltid vald</strong>
          </Trans>
        )
      },
      {
        title: t('column-headers.owners'),
        id: 'owners',
        dataIds: ['owners', 'landOwners', 'leaseHolders'],
        selected: true,
        desc: t('reports.property-report.owners-tooltip')
      }
      // {
      //   title: 'Leverantörer',
      //   id: '',
      //   dataIds: [],
      //   selected: true,
      //   desc: ''
      // }
    ];

    const showApartments =
      property.apartmentCount > 0 &&
      property.taxCodes?.find((taxcode: number) => taxcode === 320 || taxcode === 321);

    if (showApartments) {
      propInfoFields.push({
        title: t('property.residential-apartments'),
        id: 'apartments',
        dataIds: ['apartments'],
        selected: true,
        desc: t('reports.property-report.apartments-tooltip')
      });
    }

    const otherPropType: boolean =
      Object.keys(property.warehouse ?? {}).length === 0 &&
      Object.keys(property.space ?? {}).length === 0 &&
      Object.keys(property.housing ?? {}).length === 0;

    const rentFields: DataOption['fields'] = [];

    const showRents = property.v2 && !otherPropType;

    if (showRents) {
      rentFields.push({
        title: t('property.rent-summary.header'),
        id: 'rentSummary',
        dataIds: [
          'taxcodes',
          'housing.nearbyHousing',
          'housing.investment_support',
          'space.nearbySpaces',
          'warehouse.warehouse_details',
          'warehouse.nearbyWarehouses',
          'warehouse.loa',
          'v2'
        ],
        selected: true,
        desc: t('reports.property-report.rent-summary-tooltip')
      });
    }

    setDataFields([
      {
        id: 'propInfo',
        fields: propInfoFields
      },
      {
        id: 'economy',
        fields: [
          {
            title: t('submenu.title.tax'),
            id: 'taxation',
            dataIds: [
              'area',
              'loa',
              'boa',
              'taxYears',
              'taxValue',
              'totalRent',
              'taxCodes',
              'samTaxCount',
              'samTaxProps'
            ],
            selected: true,
            desc: t('reports.property-report.tax-tooltip')
          },
          {
            title: t('property.rent.newsec'),
            id: 'marketData',
            dataIds: ['market_data', 'market_data_date'],
            selected: true,
            desc: t('reports.property-report.market-data-tooltip')
          },
          {
            title: t('menu.transactions'),
            id: 'transactions',
            dataIds: ['transactions'],
            selected: true,
            desc: t('reports.property-report.transaction-tooltip')
          }
        ]
      },
      {
        id: 'rent',
        fields: rentFields
      }
      // {
      //   id: 'demographics',
      //   fields: [
      //     {
      //       title: 'Demografi & grafer',
      //       id: '',
      //       dataIds: [],
      //       selected: true,
      //       desc: ''
      //     }
      //   ]
      // }
    ]);
  }, []);

  useEffect(() => {
    const getSettings = async () => {
      const result = await getActorSettings(actor.actor_uuid);

      if (result && result.actor_settings) {
        const settings = JSON.parse(result?.actor_settings);
        if (settings?.COLOUR === 'CUSTOM') {
          setActorSettings({ CUSTOM_ACCENT: settings.CUSTOM_ACCENT });
        }
        if (settings?.LOGO === 'CUSTOM') {
          const logoUrl = await getActorLogo(actor?.actor_uuid);
          logoUrl && setActorSettings((prev) => ({ ...prev, LOGO_URL: logoUrl }));
        }
      }
    };
    getSettings();
  }, [actor, t]);

  useEffect(() => {
    const tmpFields: ReportOptions['data'] = [];
    dataFields.forEach((cat) => {
      cat.fields.forEach((field) => {
        if (field.selected) {
          tmpFields.push({ [cat.id]: field.dataIds });
        }
      });
    });
    if (tmpFields.length < 1) {
      setOptions((prev) => ({ ...prev, data: [] }));
    } else {
      setOptions((prev) => ({ ...prev, data: tmpFields }));
    }
  }, [dataFields]);

  const { property } = object;
  const latLong = `${property?.lat},${property?.lng}`;

  const locale = process.env.NEXT_PUBLIC_STAGE !== 'production' ? router.locale : 'sv';
  const today = dayjs().locale(locale || 'sv');

  const generateReport = async () => {
    setStep(stepNames.loading);

    const dataForReport: PropertyReportData = {
      propInfo: {},
      economy: {},
      rent: {},
      format: options.format,
      uuid: property.uuid,
      authorityNickname: property.authority_nickname,
      propertyKey: property.propertyKey
    };

    options.data.map((cat) => {
      const catName = Object.keys(cat)[0];
      // Populate the dataForReport object with property data.
      cat[catName].forEach((field) => {
        if (property[field] !== undefined) {
          dataForReport[catName][field] = true;
        } else if (/\./.test(field)) {
          const nestedFields = field.split('.');
          if (property[nestedFields[0]][nestedFields[1]]) {
            if (!dataForReport[catName][nestedFields[0]]) {
              dataForReport[catName][nestedFields[0]] = {};
            }
            dataForReport[catName][nestedFields[0]][nestedFields[1]] = true;
          }
        }
      });
    });

    if (options.theme.logo) {
      dataForReport['logoUrl'] = actorSettings?.LOGO_URL ?? '/images/logo-full2.png';
    }
    if (options.theme.colour) {
      dataForReport['accentColour'] = actorSettings?.CUSTOM_ACCENT ?? '#FB923C';
    }
    if (options.theme.cover && options.theme.coverUrl) {
      dataForReport['coverUrl'] = options.theme.coverUrl;
    }

    const queryParams: string[][] = [];

    dataFields.forEach((cat) => {
      cat.fields.forEach((field) => {
        if (field.selected) {
          queryParams.push([field.id, 'true']);
        }
      });
    });

    const res = await getPropertyReport(dataForReport, queryParams, locale, t);

    if (res.error) {
      setStep(stepNames.error);
    } else {
      // it's a drag to have to go through the whole interface when testing.
      process.env.NEXT_PUBLIC_STAGE === 'development' ? setStep(stepNames.fields) : close();
    }
  };

  let mod: JSX.Element = <div />;

  switch (step) {
    case stepNames.report_theme:
      mod = (
        <ChooseTheme
          property={property}
          setStep={setStep}
          close={close}
          latLong={latLong}
          themeOptions={options?.theme}
          setOptions={setOptions}
          actorSettings={actorSettings}
          today={today}
        />
      );
      break;
    case stepNames.fields:
      mod = (
        <ChooseFields
          property={property}
          setStep={setStep}
          dataOptions={dataFields}
          setDataFields={setDataFields}
          generateReport={generateReport}
          close={close}
        />
      );
      break;

    case stepNames.file_format:
      mod = (
        <ChooseFormat
          setStep={setStep}
          close={close}
          property={property}
          setOptions={setOptions}
          formatOptions={options.format}
          generateReport={generateReport}
        />
      );
      break;

    case stepNames.loading:
      mod = <LoadingStep />;
      break;

    case stepNames.error:
      mod = <ErrorStep close={close} property={property} />;
      break;
  }

  return (
    <>
      <button onClick={close} className={crmDialogTheme.closeContainerX}>
        <XMarkIcon className="h-5 w-5" />
      </button>
      {mod}
    </>
  );
}
