import { BBox } from '@turf/helpers';
import cls from 'classnames';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { GotoFeature } from 'shared/dist/types';
import { RiskHandle, RiskObject } from 'shared/dist/types/risk';

import { MapsRefreshIcon } from '@/src/assets/icons';
import { useSlideIn } from '@/src/context/slide-in-context';

import { RiskTableRow, RiskTableRowExpandable } from './risk-table-row';

export default function RiskScoreCard({
  scorecard,
  visuals,
  setVisuals,
  crm = false,
  nickname = '',
  risk_score = 0,
  raw
}: {
  scorecard?: RiskHandle;
  visuals: any;
  setVisuals: any;
  crm?: boolean;
  nickname?: string;
  risk_score?: number;
  raw?: RiskObject;
}) {
  const [bbox, setBbox] = useState<BBox | null>(null);
  const { t } = useTranslation('common');

  const { setSlideIn } = useSlideIn();

  useEffect(() => {
    if (!raw?.uuid) {
      return;
    }
    fetch(`/api/prop/get-bbox?propertyUUID=${raw?.uuid}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json?.bbox) {
          setBbox(json.bbox);
        }
      });
  }, [raw?.uuid]);

  if (!scorecard) {
    return <div />;
  }

  const crmCategoryCellStyle = (extraClasses: string): string => {
    return `${extraClasses} px-2 h-[36px] border-gray-200 border-y dark:border-dark-morefaded`;
  };

  function PropCardRowHeader({ header }: { header: string }) {
    return (
      <div className="header w-1/6 p-2 font-medium flex items-center justify-center space-x-1 text-center text-xs">
        {header}
      </div>
    );
  }

  const rowStyle =
    'flex items-stretch justify-between w-full bg-white dark:bg-dark-faded border-b border-gray-100 dark:border-dark-morefaded';

  return (
    <div
      className={cls(
        `${
          crm
            ? 'min-w-[220px]'
            : 'mb-6 border-x border-t rounded-t-md border-gray-100 dark:border-dark-morefaded'
        }`
      )}>
      {!crm ? (
        <div className="flex items-stretch justify-between rounded-t-md w-full overflow-hidden text-xs bg-gray-100 dark:bg-dark-lessfaded border-b border-gray-100 dark:border-dark-morefaded">
          <div className="header w-1/6 p-2 py-2 font-medium flex items-center text-center">
            {t('info.risk-table.risk-type')}
          </div>

          <div className="header flex-1 p-2 py-2 font-medium flex items-center justify-start border-gray-200 text-lef border-x dark:border-dark-morefaded">
            {t('info.risk-table.risk')}
          </div>

          <div className="header w-[85px] p-2 py-2 font-medium flex items-center justify-center text-center border-r dark:border-dark-morefaded">
            {t('info.risk-table.risk-level')}
          </div>

          <div className="header p-2 w-[40px] py-2 font-medium  flex items-center justify-center text-center"></div>
        </div>
      ) : (
        <div className="p-2 h-[85px] flex flex-col justify-center items-center text-xs font-medium space-y-1 border-b border-gray-200 dark:border-dark-morefaded">
          <button
            onClick={() => {
              setSlideIn({
                object: { uuid: raw?.uuid, authority_nickname: nickname },
                type: GotoFeature.PROPERTY
              });
            }}
            className="px-3 flex items-center group hover:text-bobo-orange transition-colors duration-300 leading-[13px]">
            {nickname}
          </button>

          <button
            onClick={() => {
              setSlideIn({
                object: { ...raw, bbox },
                type: GotoFeature.RISK_MAP
              });
            }}
            className="bg-gray-200 dark:bg-dark-morefaded rounded-full p-1.5 px-3 hidden md:flex space-x-2 items-center group shadow-sm">
            <MapsRefreshIcon className="group-hover:text-bobo-orange dark:text-white transition-colors duration-200" />
            <div className="group-hover:text-bobo-orange transition-colors duration-200">
              {t('general.explore')}
            </div>
          </button>
        </div>
      )}
      <div>
        {crm && (
          <div
            className={crmCategoryCellStyle(
              'flex items-center justify-center text-gray-700 font-demi text-left text-[11.5px] dark:bg-dark-lessfaded dark:text-white'
            )}>
            {Math.round(risk_score)}
          </div>
        )}
        {crm && <div className={crmCategoryCellStyle('bg-purple-50 dark:bg-purple-500/20')} />}
        <div className={rowStyle}>
          {!crm && <PropCardRowHeader header={t('info.risk-table.social-factors')} />}
          <div
            className={cls('border-gray-100 dark:border-dark-morefaded', {
              'w-full': crm,
              'w-5/6 border-l': !crm
            })}>
            {[scorecard.vulnerable_area, scorecard.population_trend]
              .filter((x) => x !== undefined)
              .map((item, idx) => {
                return item ? (
                  <RiskTableRow
                    crm={crm}
                    setVisuals={setVisuals}
                    visuals={visuals}
                    key={idx}
                    risk={item}
                  />
                ) : null;
              })}
          </div>
        </div>
        {crm && <div className={crmCategoryCellStyle('bg-blue-50 dark:bg-blue-500/30')} />}
        <div className={rowStyle}>
          {!crm && <PropCardRowHeader header={t('info.risk-table.climate-risk')} />}
          <div
            className={cls('border-gray-100 dark:border-dark-morefaded', {
              'w-full': crm,
              'w-5/6 border-l': !crm
            })}>
            <div>
              {scorecard.flooding_summary && (
                <RiskTableRowExpandable
                  crm={crm}
                  risk={scorecard.flooding_summary}
                  items={[
                    scorecard.flooding_ocean,
                    scorecard.flooding_inland_threat_50,
                    scorecard.flooding_inland_threat_100,
                    scorecard.flooding_inland_threat_bhf,
                    scorecard.flooding_inland_100,
                    scorecard.flooding_inland_200,
                    scorecard.flooding_inland_bhf,
                    scorecard.flooding_malaren
                  ]}
                  visuals={visuals}
                  setVisuals={setVisuals}
                />
              )}
            </div>
            <div>
              {scorecard.fire_open && (
                <RiskTableRowExpandable
                  crm={crm}
                  risk={scorecard.fire}
                  items={[scorecard.fire_open, scorecard.fire_woods]}
                  visuals={visuals}
                  setVisuals={setVisuals}
                />
              )}
            </div>
            <div>
              {scorecard.landslide && (
                <RiskTableRowExpandable
                  crm={crm}
                  risk={scorecard.landslide}
                  items={[
                    scorecard.landslide_fine_grained,
                    scorecard.landslide_zones,
                    scorecard.erosion,
                    scorecard.ravine,
                    scorecard.solid_ground
                  ]}
                  visuals={visuals}
                  setVisuals={setVisuals}
                />
              )}
            </div>
            <div>
              {scorecard.heat_summary && (
                <>
                  {!crm ? (
                    <RiskTableRowExpandable
                      crm={crm}
                      risk={scorecard.heat_summary}
                      items={[scorecard.heat, scorecard.heat_spots]}
                      visuals={visuals}
                      setVisuals={setVisuals}
                    />
                  ) : (
                    <>
                      {[scorecard.heat, scorecard.heat_spots]
                        .filter((x) => x !== undefined)
                        .map((item, idx) => {
                          return item ? (
                            <RiskTableRow
                              crm={crm}
                              setVisuals={setVisuals}
                              visuals={visuals}
                              key={idx}
                              risk={item}
                            />
                          ) : null;
                        })}
                    </>
                  )}
                </>
              )}
            </div>
            {[scorecard.storm, scorecard.draught, scorecard.energy]
              .filter((x) => x !== undefined)
              .map((item, idx) => {
                return item ? (
                  <RiskTableRow
                    crm={crm}
                    setVisuals={setVisuals}
                    visuals={visuals}
                    key={idx}
                    risk={item}
                  />
                ) : null;
              })}
          </div>
        </div>
        {crm && <div className={crmCategoryCellStyle('bg-rose-50 dark:bg-rose-500/20')} />}
        <div className={rowStyle}>
          {!crm && <PropCardRowHeader header={t('info.risk-table.env-risk')} />}
          <div
            className={cls('border-gray-100 dark:border-dark-morefaded', {
              'w-full': crm,
              'w-5/6 border-l': !crm
            })}>
            {[
              scorecard.toxic,
              scorecard.historic_sites
              //scorecard.sulphite
            ]
              .filter((x) => x !== undefined)
              .map((item, idx) => {
                return item ? (
                  <RiskTableRow
                    crm={crm}
                    setVisuals={setVisuals}
                    visuals={visuals}
                    key={idx}
                    risk={item}
                  />
                ) : null;
              })}
            <div
              className={crmCategoryCellStyle(
                'bg-blue-50 dark:bg-blue-500/20 text-xs font-medium text-center flex items-center justify-start'
              )}>
              {!crm && t('risk.protected-areas')}
            </div>
            <div>
              {[
                scorecard.natura_2000,
                scorecard.nature_reserve,
                scorecard.nature_care_area,
                scorecard.water_protection_area,
                scorecard.water_presence,
                scorecard.wetlands,
                scorecard.bio,
                scorecard.national_park,
                scorecard.interim_ban,
                scorecard.dewatering
              ]
                .filter((x) => x !== undefined)
                .map((item, idx) => {
                  return item ? (
                    <RiskTableRow
                      crm={crm}
                      setVisuals={setVisuals}
                      visuals={visuals}
                      key={idx}
                      risk={item}
                    />
                  ) : null;
                })}
              {/*              {scorecard.protected_area && scorecard.protected_area !== undefined && (
                <RiskTableRowExpandable
                  crm={crm}
                  risk={scorecard.protected_area}
                  items={[
                    scorecard.natura_2000,
                    scorecard.nature_reserve,
                    scorecard.nature_care_area,
                    scorecard.water_protection_area,
                    scorecard.water_presence,
                    scorecard.wetlands,
                    scorecard.bio,
                    scorecard.national_park,
                    scorecard.interim_ban,
                    scorecard.dewatering
                  ]}
                  visuals={visuals}
                  setVisuals={setVisuals}
                />
              )}*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
