import {
  ArrowTrendingDownIcon as TrendingDownIcon,
  ArrowTrendingUpIcon as TrendingUpIcon
} from '@heroicons/react/24/outline';
import { LinearProgress } from '@material-ui/core';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import MapWidget from 'src/components/map/map-widget';
import { localCouncilNameConverter } from 'src/lib/constants';
import { getLocalCouncilDetails } from 'src/lib/local-council-wrapper';

import Button from '@/src/widgets/buttons';
import SectionHeader from '@/src/widgets/section-header';

const Bar = dynamic(() => import('src/components/charts/bar'), {
  ssr: false
});

export default function LocalCouncilTemplate({ object, close }: { object: any; close: any }) {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [result, setResult] = useState<any | null>(null);
  const { t } = useTranslation('common');

  const buildData = (arr) => {
    const dStruct: any[] = [];

    arr?.map((d) => {
      dStruct.push({
        time: `Q${d?.quarter} '${d?.year?.toString()?.substring(2, 4)}`,
        Påbörjade: d?.started,
        PåbörjadeColor: 'hsl(61, 70%, 50%)',
        Färdigställda: d?.completed,
        FärdigställdaColor: 'hsl(55, 70%, 50%)'
      });
    });
    return dStruct;
  };

  useEffect(() => {
    setLoading(true);

    getLocalCouncilDetails(
      object?.uuid,
      `land_instructions,top_property_owners,latest_sustainability_certs,apartment_construction`
    ).then((res) => {
      const dd = buildData(res?.constructions);

      setLoading(false);
      setData(dd);
      setResult(res);
      setLoaded(true);
    });
  }, []);

  const bbox: any = JSON.parse(object?.bbox);

  const router = useRouter();

  return (
    <div className="z-30 h-full">
      <div className="relative h-40 sm:h-56">
        <MapWidget bbox={bbox} id={object?.code} />
      </div>

      {loading ? <LinearProgress /> : <div className="h-[4px] w-8" />}

      <div className="px-2 mt-6 sm:px-6 relative dark:bg-dark-lessfaded flex flex-col gap-6">
        <div className="flex flex-wrap items-start justify-between w-full">
          <div>
            <Image
              className="object-contain overflow-hidden mt-1.5"
              src={`/images/kommunvapen/${localCouncilNameConverter(object?.name)}.png`}
              height={64}
              width={64}
              alt=""
            />
          </div>

          <div className="w-4/5">
            <div className="flex justify-center flex-col w-full">
              <div className="text-xl font-medium text-gray-900 dark:text-white sm:text-3xl">
                {t('lc.name-lc', { lc: object?.name })}
              </div>

              <div className="text-mini italic text-gray-400">
                {t('general.sources', { sources: 'Lantmäteriet, SCB' })}
              </div>
            </div>

            <div className="text-xs text-gray-800 dark:text-gray-300 mt-3">
              <div className="flex w-full py-2 border-b border-gray-100 dark:border-dark-morefaded">
                <div className="w-[155px]">{t('lc.population')}</div>

                <div className="flex-col sm:flex-row w-4/5 ml-4 justify-end sm:items-start items-end space-x-4 flex text-right leading-3 font-normal">
                  {object?.populationChange > 0 ? (
                    <div className="flex font-light justify-end text-mini items-start">
                      <TrendingUpIcon className="h-4 w-4 mx-1 text-bobo-prop dark:text-dark-prop" />
                      <NumberFormat
                        value={object?.populationChange}
                        displayType={'text'}
                        thousandSeparator={` `}
                        prefix={`(+`}
                        suffix={` ${t('general.since', { time: '2011' })})`}
                      />
                    </div>
                  ) : (
                    <div className="flex items-start text-mini font-light">
                      <TrendingDownIcon className="h-4 w-4 mx-1 text-bobo-burgundy" />
                      (
                      <NumberFormat
                        value={object?.populationChange}
                        displayType={'text'}
                        thousandSeparator={` `}
                        prefix={``}
                        suffix={` ${t('general.since', { time: '2011' })}`}
                      />
                      )
                    </div>
                  )}

                  <div>
                    <NumberFormat
                      value={object?.population}
                      displayType={'text'}
                      thousandSeparator={` `}
                      prefix={``}
                    />
                  </div>
                </div>
              </div>

              <div className="flex w-full py-2 border-b border-gray-100 dark:border-dark-morefaded">
                <div className="w-[155px]">{t('general.areal')}</div>

                <div className="w-4/5 ml-4 flex justify-end">
                  <NumberFormat
                    value={Math.round(object?.area / 1000000)}
                    displayType={'text'}
                    thousandSeparator={` `}
                    prefix={``}
                    suffix={t('general.square-kilometers')}
                  />
                </div>
              </div>

              <div className="flex w-full py-2">
                <div className="w-[155px]">{t('lc.population-density')}</div>

                <div className="w-4/5 ml-4 flex items-center justify-end">
                  <NumberFormat
                    value={Math.round(object?.population / (object?.area / 1000000))}
                    displayType={'text'}
                    thousandSeparator={` `}
                    prefix={``}
                    suffix={t('lc.people-per-km')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-center">
          <Button
            onClick={() => {
              close();
              router.push(`/kommuner/kommun/${object.code}/${object.county}/overview`);
            }}>
            {t('lc.explore-lc', { lc: object?.name })}
          </Button>
        </div>

        <div className="pb-5 dark:bg-dark-faded">
          <SectionHeader title={t('lc.newly-build')} sources="SCB" />

          <div className="w-full">
            <Bar data={data} height={200} />
          </div>
        </div>
      </div>
    </div>
  );
}
