import { Feature, featureCollection, lineString, Point } from '@turf/helpers';
import cls from 'classnames';
import { Expression } from 'mapbox-gl';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';
import { RiskHandle } from 'shared/dist/types/risk';

import ExpandableInfoBox from '@/src/layout/components/expandable-info-box';
import { getLayerColor } from '@/src/lib/colors';
import { createObjectESG } from '@/src/lib/utils/esg-tools';
import { PropWarning } from '@/src/widgets/prop-labels';

import RiskEnvironmentalScore from '../widgets/risk-env-score';
import RiskScoreCard from '../widgets/risk-score-card';

export default function SummaryBoard({
  esgData,
  visuals,
  setVisuals,
  hideTable = false
}: {
  esgData: any;
  visuals: any;
  setVisuals: any;
  hideTable: boolean;
}) {
  const { riskMap } = useMap();

  let hoveredStateId = null;
  let hoveredSource = null;
  let hoveredSourceLayer = null;

  const solidLayers: string[] = [
    'uso_2023',
    'RegSO_2018_v2-a7xtub',
    'skred-finkornig-jord-lutning',
    'skred-finkornig-jord-strandnara',
    'skred-inspire-zon1',
    'skred-inspire-zon2',
    'skred-inspire-zon3',
    'jordskred_raviner',
    'kust_',
    'Grundvatten_Vattenforekomster_2016_1',
    'Kustvatten_Vattenforekomster_2016_1',
    'Sjoar_OvrigtVatten_2016_1',
    'Sjoar_Vattenforekomster_2016_1',
    'Vattendrag_OvrigtVatten_2016_1',
    'Vattendrag_Vattenforekomster_2016_1',
    'NR_polygon',
    'NVO_polygon',
    'VSO_polygon',
    'NP_polygon',
    'Forbud_mot_markavvattning',
    'VMI',
    'sksBiotopskydd',
    'InspireMSB_hot_risk_bhf',
    'InspireMSB_hot_risk_50',
    'InspireMSB_hot_risk_100',
    '100arsflode',
    '200arsflode',
    'BHF',
    'Sverige_temp_gt35_2024_v3'
  ];

  const { t } = useTranslation('common');

  // Function to add mouseover effect to a layer
  function addMouseoverEffect(map, layerId) {
    map.on('mousemove', layerId, (e) => {
      map.getCanvas().style.cursor = 'pointer';

      if (e.features && e.features.length > 0) {
        const source = e?.features[0].source;
        const sourceLayer = e?.features[0].sourceLayer;

        if (hoveredStateId !== null) {
          map.setFeatureState({ source, sourceLayer, id: hoveredStateId }, { hover: false });
        }

        const newHoveredId = e.features[0].id as number;

        if (newHoveredId) {
          hoveredStateId = e.features[0].id;
          hoveredSource = source;
          hoveredSourceLayer = sourceLayer;

          map.setFeatureState({ source, sourceLayer, id: newHoveredId }, { hover: true });
        }
      }
    });

    map.on('mouseleave', layerId, () => {
      map.getCanvas().style.cursor = '';

      if (hoveredStateId) {
        map.setFeatureState(
          { source: hoveredSource, sourceLayer: hoveredSourceLayer, id: hoveredStateId },
          { hover: false }
        );
      }
      hoveredStateId = null;
      hoveredSource = null;
      hoveredSourceLayer = null;
    });
  }

  const addProperty = () => {
    if (!riskMap) {
      return;
    }

    const m: mapboxgl.Map = riskMap.getMap();

    if (!m?.getSource('property-border')) {
      riskMap.getMap().addSource('property-border', {
        type: 'geojson',
        data: esgData.geometry
      });
    }

    if (!m?.getLayer('border')) {
      m.addLayer({
        id: 'border',
        type: 'line',
        source: 'property-border',
        paint: {
          'line-color': '#FF0000',
          'line-width': 4
        }
      });
    }

    if (!m?.getLayer('area')) {
      m.addLayer({
        id: 'area',
        type: 'fill',
        source: 'property-border',
        paint: {
          'fill-color': '#FF0000',
          'fill-opacity': 0.1
        }
      });
    }
  };

  const addRiskLayers = () => {
    if (!riskMap) {
      return;
    }

    const m: mapboxgl.Map = riskMap.getMap();

    const features: any[] = [];

    //Climate
    features.push(esgData.climate.fire_open);
    features.push(esgData.climate.fire_woods);
    features.push(esgData.climate.landslide_fine_grained);
    features.push(esgData.climate.landslide_zones);
    features.push(esgData.climate.heat_spots);

    features.push(esgData.climate.ravine);
    features.push(esgData.climate.erosion);
    features.push(esgData.climate.solid_ground);

    //Water
    // features.push(esgData.flooding.coastal_flooding);
    features.push(esgData.water.wetlands);
    features.push(esgData.water.water_presence);
    esgData.flooding.flooding_inland_threat_50 &&
      features.push(esgData.flooding.flooding_inland_threat_50);
    esgData.flooding.flooding_inland_threat_100 &&
      features.push(esgData.flooding.flooding_inland_threat_100);
    esgData.flooding.flooding_inland_threat_bhf &&
      features.push(esgData.flooding.flooding_inland_threat_bhf);
    esgData.flooding.flooding_inland_100 && features.push(esgData.flooding.flooding_inland_100);
    esgData.flooding.flooding_inland_200 && features.push(esgData.flooding.flooding_inland_200);
    esgData.flooding.flooding_inland_bhf && features.push(esgData.flooding.flooding_inland_bhf);

    //Protected areas
    features.push(esgData.protected_areas.nature_reserve);
    features.push(esgData.protected_areas.natura2000);
    features.push(esgData.protected_areas.nature_care_area);
    features.push(esgData.protected_areas.bio);
    features.push(esgData.protected_areas.national_park);
    features.push(esgData.protected_areas.interim_ban);
    features.push(esgData.protected_areas.dewatering);
    features.push(esgData.protected_areas.water_protection_area);
    features.push(esgData.land.historic_sites);

    features.push(esgData.toxic);

    //Social
    features.push(esgData.social.population);
    features.push(esgData.social.vulnerable_area);

    features.map((x: any) => {
      if (!x.tile) {
        console.error(`Missing tile in climate-score-summary!!`, x);
        return;
      }
      if (x) {
        if (!m?.getSource(`${x.tile}`)) {
          // console.log('Add source', x.tile);
          m.addSource(`${x.tile}`, {
            type: 'vector',
            maxzoom: 22,
            url: `mapbox://${x.tile}`,
            promoteId: `${x.filter_param}`
          });
        }

        const uuids: string[] | number[] = x?.number
          ? (Array.from(
              new Set(x?.features?.map((y: Feature) => parseInt(y.properties?.uuid)))
            ) as number[])
          : (Array.from(
              new Set(x?.features?.map((y: Feature) => `${y.properties?.uuid}`))
            ) as string[]);

        let filter: Expression =
          x.features?.filter((x) => x.properties.distance || x.properties.distance_from_border)
            ?.length > 0
            ? ['match', ['get', `${x.filter_param}`], uuids, true, false]
            : ['all'];

        if (
          [
            'bobolaget.skred',
            'bobolaget.heat-spots',
            'bobolaget.fire',
            'bobolaget.flooding-coastal'
          ].includes(x.tile)
        ) {
          filter = ['all'];
        }

        x.layers?.map((layer: string) => {
          if (!layer || layer === '') return;

          if (!m?.getLayer(`${layer}`)) {
            // The coloring
            if ([`lamningar_sverige_point`, `EBH_Potentiellt_fororenade_omraden`].includes(layer)) {
              m.addLayer(
                {
                  id: `${layer}`,
                  type: 'circle',
                  source: `${x.tile}`,
                  'source-layer': `${layer}`,
                  minzoom: 0, // Ensures the layer is visible at all zoom levels
                  maxzoom: 22, // Ensures the layer is visible at all zoom levels
                  paint: {
                    'circle-radius': 11,
                    'circle-stroke-width': layer === `lamningar_sverige_point` ? 0 : 1,
                    'circle-stroke-color': `#ffffff`,
                    'circle-color': solidLayers?.find((x) => layer.startsWith(x))
                      ? getLayerColor(layer)
                      : [
                          'case',
                          // Condition 1: If attribute matches a specific value, use a specific color
                          ['==', ['get', 'antikv_bed'], 'Ej kulturhistorisk lämning'],
                          '#AEAEAE',
                          ['==', ['get', 'antikv_bed'], 'Ingen antikvarisk bedömning'],
                          '#AEAEAE',
                          ['==', ['get', 'antikv_bed'], 'Övrig kulturhistorisk lämning'],
                          '#01567e',
                          ['==', ['get', 'antikv_bed'], 'Möjlig fornlämning'],
                          '#c94a17',
                          ['==', ['get', 'antikv_bed'], 'Fornlämning'],
                          '#c94a17',
                          ['==', ['get', 'RISKKLASS'], '1'],
                          '#ff0300',
                          ['==', ['get', 'RISKKLASS'], '2'],
                          '#ffa900',
                          ['==', ['get', 'RISKKLASS'], '3'],
                          '#fffe01',
                          ['==', ['get', 'RISKKLASS'], '4'],
                          '#aaff00',
                          // Default case: Use the color from getLayerColor
                          `${getLayerColor(layer)}`
                        ],
                    'circle-opacity': [
                      'case',
                      ['boolean', ['feature-state', 'hover'], false],
                      1,
                      0.9
                    ]
                  },
                  filter,
                  layout: {
                    visibility: 'none'
                  }
                },
                'border'
              );

              if (layer === `EBH_Potentiellt_fororenade_omraden`) {
                m.addLayer({
                  id: `${layer}-line`,
                  type: 'symbol',
                  source: `${x?.tile}`,
                  'source-layer': `${layer}`,
                  paint: {
                    'text-color': '#000000'
                    // 'line-dasharray': [2, 2]
                  },
                  filter,
                  layout: {
                    visibility: 'none',
                    'text-size': 11,
                    'text-field': [
                      'case',
                      ['==', ['get', 'PRECISERAD'], 'Mindre Känslig Markanvändning'],
                      'MKM',
                      ['==', ['get', 'PRECISERAD'], 'Känslig Markanvändning'],
                      'KM',
                      ['coalesce', ['get', 'RISKKLASS'], 'E']
                    ]
                  }
                });
              } else if (layer === `lamningar_sverige_point`) {
                m.addLayer({
                  id: `${layer}-line`,
                  type: 'symbol',
                  source: `${x?.tile}`,
                  'source-layer': `${layer}`,
                  paint: {
                    'text-color': '#000000'
                    // 'line-dasharray': [2, 2]
                  },
                  filter,
                  layout: {
                    visibility: 'none',
                    'icon-image': [
                      'case',
                      ['==', ['get', 'antikv_bed'], 'Fornlämning'],
                      'fornlamning', // The name of the icon for Value1
                      ['==', ['get', 'antikv_bed'], 'Övrig kulturhistorisk lämning'],
                      'kultur', // The name of the icon for Value2
                      ['==', ['get', 'antikv_bed'], 'Ej kulturhistorisk lämning'],
                      'other', // The name of the icon for Value3
                      ['==', ['get', 'antikv_bed'], 'Ingen antikvarisk bedömning'],
                      'other', // The name of the icon for Value3
                      ['==', ['get', 'antikv_bed'], 'Möjlig fornlämning'],
                      'kultur', // The name of the icon for Value3
                      'other' // The default icon if no conditions match
                    ],
                    'icon-size': 1.1 // Adjust the icon size as needed
                  }
                });
              }
            } else if ([`strander`, `jordskred_raviner`].includes(layer)) {
              m.addLayer({
                id: `${layer}`,
                type: 'line',
                source: `${x.tile}`,
                'source-layer': `${layer}`,
                paint: {
                  'line-width': 4,
                  'line-color': solidLayers?.includes(layer)
                    ? getLayerColor(layer)
                    : [
                        'case',
                        // Condition 1: If attribute matches a specific value, use a specific color
                        ['==', ['get', 'erod_tx'], 'Ej bedömd eroderbarhet'],
                        '#ffd23d',
                        ['==', ['get', 'erod_tx'], `Viss eroderbarhet`],
                        '#ffd23d',
                        ['==', ['get', 'erod_tx'], 'Ingen eller mycket låg eroderbarhet'],
                        '#04fb4a',
                        ['==', ['get', 'erod_tx'], `Låg eroderbarhet`],
                        '#04fb4a',
                        ['==', ['get', 'erod_tx'], 'Potentiellt hög eroderbarhet'],
                        '#f62323',
                        // Default case: Use the color from getLayerColor
                        `${getLayerColor(layer)}`
                      ],
                  'line-opacity': [
                    'case',
                    ['boolean', ['feature-state', 'hover'], false],
                    0.9,
                    0.75
                  ]
                },
                filter,
                layout: {
                  visibility: 'none'
                }
              });

              m.addLayer({
                id: `${layer}-line`,
                type: 'line',
                source: `${x?.tile}`,
                'source-layer': `${layer}`,
                paint: {
                  'line-color': solidLayers?.includes(layer)
                    ? getLayerColor(layer)
                    : [
                        'case',
                        // Condition 1: If attribute matches a specific value, use a specific color
                        ['==', ['get', 'erod_tx'], 'Ej bedömd eroderbarhet'],
                        '#ffd23d',
                        ['==', ['get', 'erod_tx'], `Viss eroderbarhet`],
                        '#ffd23d',
                        ['==', ['get', 'erod_tx'], 'Ingen eller mycket låg eroderbarhet'],
                        '#04fb4a',
                        ['==', ['get', 'erod_tx'], `Låg eroderbarhet`],
                        '#04fb4a',
                        ['==', ['get', 'erod_tx'], 'Potentiellt hög eroderbarhet'],
                        '#f62323',
                        // Default case: Use the color from getLayerColor
                        `${getLayerColor(layer)}`
                      ],
                  'line-width': 0,
                  'line-opacity': 0
                  // 'line-dasharray': [2, 2]
                },
                filter,
                layout: {
                  visibility: 'none'
                }
              });
            } else {
              m.addLayer({
                id: `${layer}`,
                type: 'fill',
                source: `${x.tile}`,
                'source-layer': `${layer}`,
                paint: {
                  'fill-color': solidLayers?.find((x) => layer.startsWith(x))
                    ? getLayerColor(layer)
                    : [
                        'case',
                        // Condition 1: If attribute matches a specific value, use a specific color
                        ['==', ['get', 'Klasskod'], 'IB 1'],
                        '#000000',
                        ['==', ['get', 'Klasskod'], 'IB 2'],
                        '#000000',
                        ['==', ['get', 'Klasskod'], 'IB 3'],
                        '#7d7d7d',
                        ['==', ['get', 'Klasskod'], 'IO 1'],
                        '#7d7d7d',
                        ['==', ['get', 'Klasskod'], 'BR 1'],
                        '#ff0101',
                        ['==', ['get', 'Klasskod'], 'BR 2'],
                        '#ff8701',
                        ['==', ['get', 'Klasskod'], 'BR 3'],
                        '#ffff01',
                        ['==', ['get', 'Klasskod'], 'LV 2'],
                        '#a56441',
                        ['==', ['get', 'Klasskod'], 'LV 3'],
                        '#ebe1c3',
                        ['==', ['get', 'Klasskod'], 'TV 1'],
                        '#ff0ae6',
                        ['==', ['get', 'Klasskod'], 'TV 2'],
                        '#9601ff',
                        ['==', ['get', 'Klasskod'], 'IV 1'],
                        '#6699cd',
                        ['==', ['get', 'Klasskod'], 'HE 1'],
                        '#a4e57c',
                        ['==', ['get', 'Klasskod'], 'HE 2'],
                        '#5e8147',
                        ['==', ['get', 'Klasskod'], 'HE 3'],
                        '#304424',
                        ['==', ['get', 'Klasskod'], 'JB 1'],
                        '#c6c6c6',
                        ['==', ['get', 'Klasskod'], 'GS 1'],
                        '#9a7c3b',
                        ['==', ['get', 'Klasskod'], 'GS 2'],
                        '#cd6931',
                        ['==', ['get', 'Klasskod'], 'WM 1'],
                        '#e59dff',
                        ['==', ['get', 'Klasskod'], 'WM 2'],
                        '#e59dff',
                        ['==', ['get', 'Klasskod'], 'WM 3'],
                        '#720095',
                        ['==', ['get', 'antikv_bed'], 'Ej kulturhistorisk lämning'],
                        '#AEAEAE',
                        ['==', ['get', 'antikv_bed'], 'Ingen antikvarisk bedömning'],
                        '#AEAEAE',
                        ['==', ['get', 'antikv_bed'], 'Övrig kulturhistorisk lämning'],
                        '#01567e',
                        ['==', ['get', 'antikv_bed'], 'Möjlig fornlämning'],
                        '#c94a17',
                        ['==', ['get', 'antikv_bed'], 'Fornlämning'],
                        '#c94a17',
                        ['==', ['get', 'fastmark_tx'], 'Fastmark'],
                        '#b3e0c3',
                        ['==', ['get', 'fastmark_tx'], 'Ej Fastmark'],
                        '#DC143C',
                        ['==', ['get', 'erod_tx'], 'Ej bedömd eroderbarhet'],
                        '#ffd23d',
                        ['==', ['get', 'erod_tx'], `Viss eroderbarhet`],
                        '#ffd23d',
                        ['==', ['get', 'erod_tx'], 'Ingen eller mycket låg eroderbarhet'],
                        '#04fb4a',
                        ['==', ['get', 'erod_tx'], `Låg eroderbarhet`],
                        '#04fb4a',
                        ['==', ['get', 'erod_tx'], 'Potentiellt hög eroderbarhet'],
                        '#f62323',
                        ['==', ['get', 'ContourMax'], 40],
                        '#f57446',
                        ['==', ['get', 'ContourMax'], 45],
                        '#d93328',
                        ['>', ['get', 'ContourMax'], 49],
                        '#a50026',
                        ['==', ['get', 'RISKKLASS'], 1],
                        '#ff0300',
                        ['==', ['get', 'RISKKLASS'], 2],
                        '#ffa900',
                        ['==', ['get', 'RISKKLASS'], 3],
                        '#fffe01',
                        ['==', ['get', 'RISKKLASS'], 4],
                        '#aaff00',
                        ['==', ['get', 'RISKKLASS'], 4],
                        '#aaff00',
                        // Default case: Use the color from getLayerColor
                        `${getLayerColor(layer)}`
                      ],
                  'fill-opacity': [
                    'case',
                    ['boolean', ['feature-state', 'hover'], false],
                    0.55,
                    0.4
                  ]
                },
                filter,
                layout: {
                  visibility: 'none'
                }
              });

              if (layer === 'lamningar_sverige_polygon') {
                m.addLayer({
                  id: `${layer}-line`,
                  type: 'symbol',
                  source: `${x?.tile}`,
                  'source-layer': `${layer}`,
                  paint: {
                    'text-color': '#000000'
                    // 'line-dasharray': [2, 2]
                  },
                  filter,
                  layout: {
                    'icon-anchor': 'center',
                    visibility: 'none',
                    'icon-image': [
                      'case',
                      ['==', ['get', 'antikv_bed'], 'Fornlämning'],
                      'fornlamning', // The name of the icon for Value1
                      ['==', ['get', 'antikv_bed'], 'Övrig kulturhistorisk lämning'],
                      'kultur', // The name of the icon for Value2
                      ['==', ['get', 'antikv_bed'], 'Ej kulturhistorisk lämning'],
                      'other', // The name of the icon for Value3
                      ['==', ['get', 'antikv_bed'], 'Ingen antikvarisk bedömning'],
                      'other', // The name of the icon for Value3
                      ['==', ['get', 'antikv_bed'], 'Möjlig fornlämning'],
                      'kultur', // The name of the icon for Value3
                      'other' // The default icon if no conditions match
                    ],
                    'icon-size': 1.1 // Adjust the icon size as needed
                  }
                });
              } else {
                m.addLayer({
                  id: `${layer}-line`,
                  type: 'line',
                  source: `${x?.tile}`,
                  'source-layer': `${layer}`,
                  paint: {
                    'line-color': solidLayers?.find((x) => layer.startsWith(x))
                      ? getLayerColor(layer)
                      : [
                          'case',
                          // Condition 1: If attribute matches a specific value, use a specific color
                          ['==', ['get', 'Klasskod'], 'IB 1'],
                          '#000000',
                          ['==', ['get', 'Klasskod'], 'IB 2'],
                          '#000000',
                          ['==', ['get', 'Klasskod'], 'IB 3'],
                          '#7d7d7d',
                          ['==', ['get', 'Klasskod'], 'IO 1'],
                          '#7d7d7d',
                          ['==', ['get', 'Klasskod'], 'BR 1'],
                          '#ff0101',
                          ['==', ['get', 'Klasskod'], 'BR 2'],
                          '#ff8701',
                          ['==', ['get', 'Klasskod'], 'BR 3'],
                          '#ffff01',
                          ['==', ['get', 'Klasskod'], 'LV 2'],
                          '#a56441',
                          ['==', ['get', 'Klasskod'], 'LV 3'],
                          '#ebe1c3',
                          ['==', ['get', 'Klasskod'], 'TV 1'],
                          '#ff0ae6',
                          ['==', ['get', 'Klasskod'], 'TV 2'],
                          '#9601ff',
                          ['==', ['get', 'Klasskod'], 'IV 1'],
                          '#6699cd',
                          ['==', ['get', 'Klasskod'], 'HE 1'],
                          '#a4e57c',
                          ['==', ['get', 'Klasskod'], 'HE 2'],
                          '#5e8147',
                          ['==', ['get', 'Klasskod'], 'HE 3'],
                          '#304424',
                          ['==', ['get', 'Klasskod'], 'JB 1'],
                          '#c6c6c6',
                          ['==', ['get', 'Klasskod'], 'GS 1'],
                          '#9a7c3b',
                          ['==', ['get', 'Klasskod'], 'GS 2'],
                          '#cd6931',
                          ['==', ['get', 'Klasskod'], 'WM 1'],
                          '#e59dff',
                          ['==', ['get', 'Klasskod'], 'WM 2'],
                          '#e59dff',
                          ['==', ['get', 'Klasskod'], 'WM 3'],
                          '#720095',
                          ['==', ['get', 'antikv_bed'], 'Ej kulturhistorisk lämning'],
                          '#AEAEAE',
                          ['==', ['get', 'antikv_bed'], 'Ingen antikvarisk bedömning'],
                          '#AEAEAE',
                          ['==', ['get', 'antikv_bed'], 'Övrig kulturhistorisk lämning'],
                          '#01567e',
                          ['==', ['get', 'antikv_bed'], 'Möjlig fornlämning'],
                          '#c94a17',
                          ['==', ['get', 'antikv_bed'], 'Fornlämning'],
                          '#c94a17',
                          ['==', ['get', 'fastmark_tx'], 'Fastmark'],
                          '#b3e0c3',
                          ['==', ['get', 'fastmark_tx'], 'Ej Fastmark'],
                          '#DC143C',
                          ['==', ['get', 'erod_tx'], 'Ej bedömd eroderbarhet'],
                          '#ffd23d',
                          ['==', ['get', 'erod_tx'], `Viss eroderbarhet`],
                          '#ffd23d',
                          ['==', ['get', 'erod_tx'], 'Ingen eller mycket låg eroderbarhet'],
                          '#04fb4a',
                          ['==', ['get', 'erod_tx'], `Låg eroderbarhet`],
                          '#04fb4a',
                          ['==', ['get', 'erod_tx'], 'Potentiellt hög eroderbarhet'],
                          '#f62323',
                          ['==', ['get', 'ContourMax'], 40],
                          '#f57446',
                          ['==', ['get', 'ContourMax'], 45],
                          '#d93328',
                          ['>', ['get', 'ContourMax'], 49],
                          '#a50026',
                          ['==', ['get', 'RISKKLASS'], 1],
                          '#ff0300',
                          ['==', ['get', 'RISKKLASS'], 2],
                          '#ffa900',
                          ['==', ['get', 'RISKKLASS'], 3],
                          '#fffe01',
                          ['==', ['get', 'RISKKLASS'], 4],
                          '#aaff00',
                          // Default case: Use the color from getLayerColor
                          `${getLayerColor(layer)}`
                        ],
                    'line-width': 2,
                    'line-opacity': 0.75
                    // 'line-dasharray': [2, 2]
                  },
                  filter,
                  layout: {
                    visibility: 'none'
                  }
                });
              }
            }

            addMouseoverEffect(m, layer);
          }
        });

        x?.features?.map((feature: Feature<Point>) => {
          if (feature && feature.properties && feature.properties.show_path) {
            const uniqueLayerId = `${feature.properties.uuid}-connector`;
            if (m && !m?.getSource(uniqueLayerId)) {
              if (feature.properties.distance_from_border > 0) {
                const connectorLine = lineString(
                  [
                    feature.geometry.coordinates,
                    [feature.properties.intersection_lng, feature.properties.intersection_lat]
                  ],
                  {
                    name: feature.properties.title,
                    distance: feature.properties.distance_from_border
                  }
                );

                // if (counter < 4) {
                m.addSource(uniqueLayerId, {
                  type: 'geojson',
                  maxzoom: 22,
                  data: featureCollection([connectorLine])
                });

                m.addLayer({
                  id: `${uniqueLayerId}`,
                  type: 'line',
                  source: `${uniqueLayerId}`,
                  paint: {
                    'line-color': '#fff', //`${getLayerColor(layer?.layer)}`,
                    'line-width': 2,
                    'line-dasharray': [2, 2]
                  },
                  layout: {
                    visibility: 'none',
                    'line-cap': 'round'
                  }
                });

                m.addLayer({
                  id: `${uniqueLayerId}-label`,
                  type: 'symbol',
                  minzoom: 8,
                  maxzoom: 22,
                  source: `${uniqueLayerId}`,
                  layout: {
                    'text-field': t('units.distance-x-m', {
                      distance: feature.properties.distance_from_border
                    }),
                    'text-font': ['literal', ['DIN Pro Medium']],
                    'text-offset': [0, 0.7],
                    'text-size': 13,
                    // 'text-ignore-placement': true,
                    'symbol-placement': 'line-center',
                    visibility: 'none'
                  },
                  paint: {
                    'text-halo-width': 2,
                    'text-halo-color': `rgba(0, 0, 0, .75)`,
                    'text-color': '#FFFFFF'
                  }
                });
                // }
              }
            }
          }
        });
      }
    });
  };

  useEffect(() => {
    if (esgData && riskMap?.loaded) {
      const m: mapboxgl.Map = riskMap.getMap();

      m.on('styledata', function () {
        addProperty();
        addRiskLayers();
      });
    }
  }, [riskMap, esgData]);

  let riskObj: RiskHandle | null;
  if (esgData && esgData.nickname) {
    riskObj = esgData;
  } else {
    riskObj = esgData ? createObjectESG(esgData) : null;
  }

  return hideTable ? (
    <></>
  ) : (
    <div className={cls(`relative`)}>
      <ExpandableInfoBox
        title={t('risk.risk-index-score')}
        helpTextId={`riskIndexScore`}
        description={t('risk.risk-index-score-text')}
        theme={`smaller`}
      />
      <RiskEnvironmentalScore
        environmental_summary={{
          score: esgData?.risk_score,
          title: `risk.index`,
          summary: t('general.hi')
        }}
      />
      <div className="p-1 border-t dark:border-dark-faded my-4"></div>
      <ExpandableInfoBox
        title={t('risk.risk-table')}
        description={t('risk.risk-table-text')}
        theme={`smaller`}
      />
      <div className={cls(`mt-4`)}>
        <div className="h-auto relative">
          {riskObj !== undefined ? (
            <RiskScoreCard
              scorecard={riskObj ?? undefined}
              visuals={visuals}
              setVisuals={setVisuals}
            />
          ) : (
            <PropWarning>
              <div className="p-2">{t('risk.no-esg-data')}</div>
            </PropWarning>
          )}
        </div>
      </div>
    </div>
  );
}
