export function chunkArray(chunkSize = 10, array: any[]): any[][] {
  const results: any[][] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    results.push(chunk);
  }
  return results;
}

export const filterNotEmpty = (arrayItem: any): boolean => !!arrayItem;
