/* This example requires Tailwind CSS v2.0+ */
import cls from 'classnames';

import InfoButton from '../widgets/info-button';

export function RowTableBody({ children }: { children: JSX.Element | JSX.Element[] }) {
  return <div className="h-full">{children}</div>;
}

export function RowTableWrapper({
  children,
  theme,
  scroll = true
}: {
  children: JSX.Element | JSX.Element[] | null;
  theme?: string;
  scroll?: boolean;
}) {
  return (
    <div
      className={cls(
        theme === 'straight'
          ? `rounded-md`
          : `` + ' text-gray-600 dark:text-white shadow-xs break-inside-avoid',
        { 'overflow-auto': scroll },
        {
          'border border-bobo-prop dark:border-dark-prop overflow-auto': theme === 'green'
        },
        {
          'shadow border border-bobo-proplight dark:border-dark-morefaded overflow-visible':
            theme === 'shadow'
        },
        {
          'border-gray-100 dark:border-dark-morefaded p-6 lg:p-2 2xl:p-2 rounded-md':
            !theme || theme === 'straight'
        },
        {
          'border-gray-100 dark:border-dark-morefaded p-3 lg:p-2 2xl:p-2 rounded-md':
            theme === 'main'
        },
        {
          'border-gray-100 overflow-hidden rounded-md dark:border-dark-morefaded border bg-gray-50':
            theme === 'no-buffer'
        },
        {
          'border-0': theme === 'clean'
        }
      )}>
      {children}
    </div>
  );
}

export function RowHeader({
  helpTextId,
  title,
  subTitle,
  theme = 'default'
}: {
  helpTextId?: string;
  title: JSX.Element | string;
  subTitle?: JSX.Element | JSX.Element[] | string;
  icon?: JSX.Element;
  theme?: 'smaller' | 'default';
}) {
  return (
    <div className="font-normal py-1 border-gray-200  dark:border-dark-morefaded border-b">
      <div
        className={cls(
          `inline-flex items-center justify-between rounded-r-md w-full`,
          {
            'text-sm lg:text-base font-demi text-gray-800 dark:text-white': theme === 'default'
          },
          {
            'text-xs 2xl:text-xs font-demi text-gray-800 dark:text-white': theme === 'smaller'
          }
        )}>
        <div>{title}</div>
        {helpTextId ? (
          <InfoButton
            helpTextId={helpTextId}
            small={theme === 'smaller'}
            className={cls(
              `transition-color duration-300 border rounded-full ${
                theme === 'smaller' ? 'w-[16px] h-[16px]' : 'w-[20px] h-[20px]'
              } p-1 flex font-normal text-smaller items-center justify-center text-bobo-prop border-bobo-prop dark:text-dark-prop dark:border-dark-prop dark:hover:border-dark-prophover dark:hover:bg-dark-prophover dark:hover:text-white hover:text-bobo-lightgreen hover:border-bobo-lightgreen`
            )}
          />
        ) : null}
      </div>
      {subTitle ? <div className="text-xs text-gray-600 leading-4">{subTitle}</div> : null}
    </div>
  );
}
