/* External Libraries */
import 'mapbox-gl/dist/mapbox-gl.css';

import { motion } from 'framer-motion';
import Image from 'next/image';
import { useTheme } from 'next-themes';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Map, MapProvider } from 'react-map-gl';
import { GotoFeature } from 'shared/dist/types';
/* Components & Widgets */
import { useBbox } from 'src/context/bbox-context';
import { useMenuMode } from 'src/context/menu-mode-context';
import { useSlideIn } from 'src/context/slide-in-context';

import ManagedThumbStyles from './items/managed-thumb-styles';

export default function MainMap({
  feature,
  obj,
  defaultLayers,
  bboxOverride
}: {
  feature: GotoFeature;
  obj: any;
  defaultLayers: string[];
  bboxOverride?: any;
}) {
  const mapRef: any = useRef();
  const { bbox } = useBbox();
  const { resolvedTheme } = useTheme();
  const { menuMode, setMenuMode } = useMenuMode();
  const { slideIn, setSlideIn } = useSlideIn();

  const customMap =
    resolvedTheme === 'light'
      ? 'mapbox://styles/bobolaget/ckwlahgui1ctx14o2ybuyx5vy'
      : 'mapbox://styles/bobolaget/cl4kx9o21007j15nyucf4lw80';

  const [selectedUUID, setSelectedUUID] = useState<string | null>(null);
  const [selectedInfo, setSelectedInfo] = useState<any | null>(null);
  const [addr, setAddr] = useState<any | null>(null);
  const [data, setData] = useState<any | null>(null);
  const [zoom, setZoom] = useState<number | null>(null);
  const [mapStyle, setMapStyle] = useState(true);
  const [viewState, setViewState] = useState({
    maxZoom: 22,
    minZoom: 4,
    pitch: 20,
    bearing: Math.floor(Math.random() * 20) + 1,
    bounds: bboxOverride ?? bbox
  });

  useEffect(() => {
    mapRef?.current?.map;
  }, [feature]);

  const viewObject = (object: any, type: GotoFeature) => {
    setSlideIn({ object, type });
  };

  const onMapLoad = useCallback(() => {
    if (!mapRef?.current) {
      throw 'map failed';
    }

    mapRef.current.on('zoom', (e) => {
      // do something
      setZoom(Math.round(e?.target?.getZoom() * 100) / 100);
    });
  }, []);

  return (
    <MapProvider>
      <ManagedThumbStyles
        viewObject={viewObject}
        satelite={mapStyle}
        obj={obj}
        feature={feature}
        layers={defaultLayers}
      />
      <div className="w-full sm:h-[255px] flex relative">
        <Map
          initialViewState={viewState}
          id="myMapA"
          ref={mapRef}
          onLoad={onMapLoad}
          style={{ width: '100%', height: '100%' }}
          mapboxAccessToken={`pk.eyJ1IjoiYm9ib2xhZ2V0IiwiYSI6ImNrZmNmZmtlajE4NG0zNm81NjB5ZTgwOGwifQ.AqhIm23pQPp2jjs_Q2Ca6Q`}
          mapStyle={`${
            mapStyle ? 'mapbox://styles/bobolaget/cl4lfiyys007x15nyt5ww6otv' : customMap
          }`}
        />
      </div>
    </MapProvider>
  );
}
