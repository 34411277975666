import cls from 'classnames';
import { useTranslation } from 'react-i18next';

import { SlidersHorizontalIcon } from '@/src/assets/icons';
import { useDialog } from '@/src/context/dialog-context';

export default function RiskLevels({
  property,
  iconOnly = false
}: {
  property: any;
  iconOnly?: boolean;
}) {
  const { dialog, setDialog } = useDialog();
  const { t } = useTranslation('common');

  const activeModel = `Atrax-modellen`;
  return (
    <button
      className={cls(
        `bg-white space-x-2 group justify-center items-center text-left flex dark:bg-dark-faded flex-nowrap whitespace-nowrap dark:border-dark-prop  border-bobo-black text-xs font-normal dark:text-dark-prop ${
          iconOnly
            ? 'rounded-full p-2 bg-white dark:bg-dark-morefaded text-bobo-prop dark:text-white hover:bg-gray-100'
            : 'rounded-full lg:rounded-md p-1.5 px-3 bg-transparent lg:bg-gray-200 dark:text-white text-black '
        } transition-colors duration-200`
      )}
      onClick={(event) => {
        event.stopPropagation();
        setDialog({
          dialogType: 'value-spinner',
          object: property
        });
      }}>
      <SlidersHorizontalIcon className="group-hover:text-bobo-texthover transition-colors duration-200" />
      <div
        className={cls(
          ` ${
            iconOnly ? 'hidden' : 'hidden xl:block'
          } font-demi text-smaller tracking-wide group-hover:text-bobo-texthover transition-colors duration-200`
        )}>
        {t('buttons.risk-levels-btn', { model: activeModel })}
      </div>
    </button>
  );
}
