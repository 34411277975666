import { Switch } from '@headlessui/react';
import { useTranslation } from 'next-i18next';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Toggle({
  isChecked,
  onCheck,
  icon,
  title,
  styles
}: {
  isChecked: boolean;
  onCheck: (checked: boolean) => void;
  title: string;
  icon?: JSX.Element;
  styles?: string;
}) {
  const { t } = useTranslation('common');

  return (
    <div className="flex items-center justify-center gap-1 px-1.5 has-tooltip relative">
      {icon ? icon : <></>}
      <div
        className={`${styles} tooltip bg-white dark:bg-dark-faded dark:text-white p-1.5 px-3 shadow-md border rounded-md border-gray-200 dark:border-dark-morefaded top-7`}>
        {title}
      </div>
      <Switch
        checked={isChecked}
        onChange={onCheck}
        className={classNames(
          isChecked ? 'bg-bobo-prop dark:bg-dark-prop' : 'bg-gray-200 dark:bg-dark-morefaded',
          'relative inline-flex h-5 w-9 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out'
        )}>
        <span className="sr-only">{t('screen-reader.setting-toggle')}</span>

        <span
          className={classNames(
            isChecked ? 'translate-x-4' : 'translate-x-0',
            'pointer-events-none relative inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}>
          <span
            className={classNames(
              isChecked ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
              'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
            )}
            aria-hidden="true">
            <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
              <path
                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>

          <span
            className={classNames(
              isChecked ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
              'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
            )}
            aria-hidden="true">
            <svg
              className="h-3 w-3 text-bobo-prop dark:text-dark-prop"
              fill="currentColor"
              viewBox="0 0 12 12">
              <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
            </svg>
          </span>
        </span>
      </Switch>
    </div>
  );
}
