import { ChevronRightIcon } from '@heroicons/react/24/solid';
import cls from 'classnames';
import React, { useRef, useState } from 'react';

import { useOutsideAlerter } from '@/src/hooks/use-outside-alerter';

export default function InputFieldDropdownMenu({
  renderInputField,
  renderOptions,
  suffix = ''
}: {
  renderInputField: (
    setShowItems: (state: boolean | ((prevState: boolean) => boolean)) => void
  ) => React.JSX.Element;
  renderOptions: (closeMenu: () => void) => React.JSX.Element[];
  suffix?: string;
}) {
  const [showItems, setShowItems] = useState<boolean>(false);
  const showItemsRef = useRef(null);
  useOutsideAlerter(showItemsRef, setShowItems);

  const closeMenu = () => {
    setShowItems(false);
  };

  return (
    <div ref={showItemsRef} className="relative w-full z-40">
      <div
        role="button"
        tabIndex={0}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
        className={cls(
          'overflow-auto flex items-center justify-between text-gray-400 gap-1 leading-3 rounded-t-sm border-x border-t border-b-none border-gray-200 dark:border-dark-morefaded dark:bg-dark-primary transition-colors duration-200 px-2 py-0.5 w-full text-xs',
          {
            'hover:border-bobo-prop dark:hover:border-dark-prop': !showItems,
            'border-b rounded-b-sm': !showItems
          }
        )}>
        <div className="w-full text-left">
          <div className="flex justify-between items-center">
            {renderInputField(setShowItems)}
            {suffix && <div className="whitespace-nowrap">{suffix}</div>}
          </div>
        </div>

        <button onClick={() => setShowItems(!showItems)}>
          <ChevronRightIcon
            className={cls('w-4 h-4 transition-transform duration-200', {
              'rotate-90': showItems
            })}
          />
        </button>
      </div>

      <div
        className={cls(
          'absolute w-full bg-white dark:text-white dark:bg-dark-primary text-bobo-black border-x border-b border-gray-200 dark:border-dark-morefaded rounded-b-md overflow-auto shadow-sm flex flex-col items-start text-start pt-1 transition-all duration-300 ease-out transform',
          { 'translate-y-0 opacity-100': showItems, '-translate-y-2 opacity-0': !showItems }
        )}>
        {showItems && renderOptions(closeMenu)}
      </div>
    </div>
  );
}
