import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';
import { Fragment, useEffect, useState } from 'react';
import { Scooter } from 'src/animations';

export default function LoadingModal({ isLoading }: { isLoading: boolean }) {
  const [isOpen, setIsOpen] = useState(isLoading);
  const { t } = useTranslation('common');

  useEffect(() => {
    setIsOpen(isLoading);
  }, [isLoading]);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={closeModal}>
          <div className="fixed inset-0 bg-gray-600/95" aria-hidden="true" />
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-md bg-white dark:bg-dark-faded p-6 text-center align-middle shadow-xl transition-all mt-20 md:mt-0 md:ml-14">
                  <button onClick={closeModal} className="absolute right-6 top-6">
                    <XMarkIcon className="h-5 w-5 text-gray-500 dark:text-white dark:hover:text-dark-prophover" />
                  </button>

                  <div className="text-2xl mb-8">{t('general.fetching-data')}</div>

                  <Scooter />

                  <div className="my-8 w-3/4 m-auto">{t('general.fetching-data-desc')}</div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
