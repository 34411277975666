import { PlusIcon } from '@heroicons/react/24/solid';
import cls from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { ButtonType } from 'shared/dist/types';
import { PropertyVM } from 'shared/dist/types/property2';

import { CRMIcon } from '@/src/assets/icons';
import { useDialog } from '@/src/context/dialog-context';
import DropdownMenu from '@/src/layout/components/dropdown-menu';

import Button from '../buttons';

export default function CrmButton({
  property,
  small = false,
  iconOnly = false
}: {
  property: PropertyVM;
  small?: boolean;
  iconOnly?: boolean;
}) {
  const { setDialog } = useDialog();
  const { t } = useTranslation('common');
  const isInCrm: boolean = property?.whereInCRM?.length > 0;

  const router = useRouter();
  const goToBoard = (boardId: string) => {
    router && router.push(`/crm/${boardId}`);
  };

  const addToCrm = (e) => {
    e.stopPropagation();
    setDialog({
      dialogType: 'prompt-crm-dialog-switcher',
      object: {
        addEditMode: true,
        initStep: 20,
        propertyUUID: property?.uuid,
        authorityNickname: property?.authorityNickname ?? property?.authority_nickname
      }
    });
  };

  const renderMenuItems = (closeMenu: () => void): React.JSX.Element[] => {
    const menuItems = property?.whereInCRM?.map((found, idx) => {
      return (
        <button
          key={idx}
          onClick={(e) => {
            e.stopPropagation();
            goToBoard(found.board.uuid);
          }}
          className="leading-3 p-3 flex flex-col items-start w-full dark:hover:bg-dark-lessfaded hover:bg-gray-100 transition-colors duration-300">
          <div className="w-full text-left whitespace-nowrap text-xs text-bobo-black dark:text-white">
            {t('crm.board', { boardName: found.board.title })}
          </div>

          <div className="w-full text-left whitespace-nowrap text-gray-400 text-mini uppercase font-medium">
            {t('crm.list', { listName: found.list.name })}
          </div>
        </button>
      );
    });

    const addToBoardButton: React.JSX.Element = (
      <button
        key={menuItems.length + 1}
        className="p-2 w-full flex items-center justify-center hover:bg-gray-100 transition-colors duration-200 font-demi"
        onClick={(event) => {
          addToCrm(event);
          closeMenu();
        }}>
        <div className="flex justify-center items-center gap-1 text-left flex-nowrap whitespace-nowrap text-xs text-bobo-prop dark:text-dark-prop">
          <PlusIcon className="w-4 h-4" />
          {t('crm.user-actions.add-to-new-board')}
        </div>
      </button>
    );

    return [...menuItems, addToBoardButton];
  };

  return (
    <div>
      {isInCrm ? (
        <DropdownMenu
          renderMenuItems={renderMenuItems}
          title={
            <>
              <CRMIcon
                className={cls(
                  'group-hover:text-bobo-texthover dark:text-white transition-colors duration-200',
                  { 'text-gray-600': iconOnly }
                )}
              />

              <div
                className={cls('font-demi text-smaller tracking-wide', {
                  hidden: iconOnly,
                  'hidden xl:block': !iconOnly
                })}>
                {t('general.in-crm')}
              </div>
            </>
          }
          buttonClassName={cls('!h-[32px]', {
            'xl:!w-[160px]': !iconOnly,
            '!bg-white dark:!bg-dark-faded !rounded-full !px-2': small
          })}
          buttonType={ButtonType.INFO}
          dropdownClassName={cls('dark:!bg-dark-faded !right-0 !border-none', {
            'lg:!bg-gray-200': !small
          })}
          tooltipText={iconOnly ? t('general.in-crm') : undefined}
        />
      ) : (
        <Button
          type={ButtonType.INFO}
          onClick={addToCrm}
          className={cls({
            '!bg-white dark:!bg-dark-faded !rounded-full !px-1.5 h-7 min-w-7': small,
            '!h-[32px] !font-demi': !small
          })}
          tooltipText={iconOnly ? t('crm.user-actions.add-to-crm') : undefined}>
          <PlusIcon className="w-4 h-4" />
          {iconOnly || small ? null : t('crm.user-actions.add-to-crm')}
        </Button>
      )}
    </div>
  );
}
