// Define styles for GeoJSON layers
export const geojsonLayerStyles = {
  regions: {
    fillColor: '#330000', // Customize as needed
    strokeColor: '#FFFFFF',
    strokeWeight: 1,
    fillOpacity: 0.1
  },
  regions_hover: {
    fillColor: '#330000', // Customize as needed
    strokeColor: '#FFFFFF',
    strokeWeight: 1,
    fillOpacity: 0.2
  },
  councils: {
    fillColor: '#0000FF', // Customize as needed
    strokeColor: '#FFFF00',
    strokeWeight: 1,
    fillOpacity: 0.1
  },
  councils_hover: {
    fillColor: '#0000FF', // Customize as needed
    strokeColor: '#FFFF00',
    strokeWeight: 1,
    fillOpacity: 0.2
  },
  zp: {
    fillColor: '#f4a261', // Customize as needed
    strokeColor: '#f4a261',
    strokeWeight: 1,
    fillOpacity: 0.3
  },
  property: {
    fillColor: '#e63946', // Customize as needed
    strokeColor: '#e63946',
    strokeWeight: 4,
    fillOpacity: 0.4
  },
  zp_hover: {
    fillColor: '#f4a261', // Customize as needed
    strokeColor: '#f4a261',
    strokeWeight: 1,
    fillOpacity: 0.3
  },
  zp_active: {
    fillColor: '#2a9d8f', // Customize as needed
    strokeColor: '#f4a261',
    strokeWeight: 5,
    fillOpacity: 0.1
  }
  // Add additional layer styles here as needed
};
