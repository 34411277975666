import cls from 'classnames';
import { PropertyTypeGroup } from 'shared/dist/types';

export default function ColorHeader({
  primaryType,
  children
}: {
  primaryType: PropertyTypeGroup | null;
  children: JSX.Element | JSX.Element[];
}) {
  return (
    <div className="bg-gray-50 dark:bg-dark-lessfaded w-full">
      <div
        className={cls(
          `bg-gradient-to-b px-4 lg:px-6 py-4 flex w-full border-gray-100`,
          {
            'from-bobo-typespacelight to-bg-gray-50 dark:to-dark-lessfaded':
              primaryType === PropertyTypeGroup.SPACE
          },
          {
            'from-bobo-typewarehouselight to-gray-50 dark:to-dark-lessfaded':
              primaryType === PropertyTypeGroup.WAREHOUSE
          },
          {
            'from-bobo-typeresidentiallight to-gray-50 dark:to-dark-lessfaded':
              primaryType === PropertyTypeGroup.HOUSING
          },
          {
            'from-bobo-typeindustrylight to-gray-50 dark:to-dark-lessfaded':
              primaryType === PropertyTypeGroup.INDUSTRY
          },
          {
            'from-bobo-typespeciallight to-gray-50 dark:to-dark-lessfaded':
              primaryType === PropertyTypeGroup.SPECIAL
          },
          {
            'from-bobo-typestreetlight to-gray-50 dark:to-dark-lessfaded':
              primaryType === PropertyTypeGroup.STREET
          }
        )}>
        <div className="w-full">{children}</div>
      </div>
    </div>
  );
}
