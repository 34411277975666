export default async function loadIcons(map: any) {
  map?.getMap()?.loadImage('/icons/fornlamning.png', (error, image) => {
    if (error) throw error;
    // Add the image to the map style.
    map?.getMap()?.addImage('fornlamning', image);
  });
  map?.getMap()?.loadImage('/icons/kulturlamning.png', (error, image) => {
    if (error) throw error;
    // Add the image to the map style.
    map?.getMap()?.addImage('kulturlamning', image);
  });
  map?.getMap()?.loadImage('/icons/ovrig.png', (error, image) => {
    if (error) throw error;
    // Add the image to the map style.
    map?.getMap()?.addImage('other', image);
  });
}
