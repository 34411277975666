import {
  CRM2BoardDetailsVM,
  CRM2BoardListVM,
  CRM2Ownership,
  TeamDetailsVM,
  TeamListVM,
  UserDetailsVM,
  UserListVM
} from 'shared/dist/types';

export async function createCRM2Team(title: string): Promise<TeamDetailsVM> {
  const body = JSON.stringify({ title });

  return await fetch(`/api/crm2/teams`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  }).then((response) => response.json());
}

export async function getCRM2Team(uuid): Promise<TeamDetailsVM> {
  return await fetch(`/api/crm2/teams/${uuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then((response) => response.json());
}

export async function editCRM2Team(teamUuid, team): Promise<TeamDetailsVM> {
  const body = JSON.stringify({
    title: team.title,
    department_uuid: team.department_uuid,
    team_admin_uuids: team.team_admin_uuids,
    member_uuids: team.member_uuids
  });

  return await fetch(`/api/crm2/teams/${teamUuid}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  }).then((response) => response.json());
}

export async function deleteCRM2Team(uuid): Promise<void> {
  return await fetch(`/api/crm2/teams/${uuid}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(async (response) => {
    if (response.status !== 200) {
      throw new Error('failed to delete team');
    }
  });
}

export async function getCRM2Teams(): Promise<TeamListVM[]> {
  return await fetch(`/api/crm2/teams`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then((response) => {
    return response.json();
  });
}

export async function getCRM2TeamMembers(team: TeamDetailsVM): Promise<UserDetailsVM[]> {
  if (!team) {
    return [];
  }

  const memberUUIDS = [...team.member_uuids, ...team.team_admin_uuids];

  return (
    await Promise.all(
      memberUUIDS.map(async (memberUUID) => {
        return await fetch(`/api/crm2/users/${memberUUID}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((response) => response.json());
      })
    )
  )
    .filter((user) => !(!user.name && !user.email))
    .sort((a: any, b: any) => {
      const result = a.name.toLowerCase().localeCompare(b.name.toLowerCase());

      if (result != 0) {
        return result;
      }

      if (a.name > b.name) {
        return 1;
      } else if (a.name < b.name) {
        return -1;
      } else {
        return 0;
      }
    });
}

export async function getCRM2AvailableTeamMembers(): Promise<UserListVM[]> {
  return await fetch(`/api/crm2/users`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then((response) => response.json());
}

export async function getCRM2Boards(): Promise<CRM2BoardListVM[]> {
  return await fetch(`/api/crm2/boards`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then((response) => response.json());
}

export async function createCRM2Board(
  title: string,
  ownership: CRM2Ownership
): Promise<CRM2BoardDetailsVM | { error: string }> {
  const body = JSON.stringify({ title, ownership });

  return await fetch(`/api/crm2/boards`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  }).then((response) => {
    return response.json();
  });
}

export async function getCRM2TeamBoards(teamUUID): Promise<CRM2BoardListVM[]> {
  return await fetch(`/api/crm2/teams/${teamUUID}/boards`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then((response) => {
    return response.json();
  });
}
