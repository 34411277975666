/* External Libraries */
import { Popover, Transition } from '@headlessui/react';
import {
  BanknotesIcon,
  Bars3Icon as MenuIcon,
  UserIcon,
  XMarkIcon as XIcon
} from '@heroicons/react/24/solid';
import cls from 'classnames';
import moment from 'moment';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Fragment } from 'react';
import { MenuMode } from 'shared/dist/types';
/* Components & Widgets */
import {
  AdvancedFilterIcon,
  AllmannyttanLogo,
  CRM2Icon,
  CRMIcon,
  DataStatusIcon,
  GearsIcon,
  LocalCouncilIcon,
  MarketDataIcon,
  SearchBuildingIcon,
  ThirdBracketIcon
} from 'src/assets/icons';
import { useMenuMode } from 'src/context/menu-mode-context';

import UserMenu from './user-menu';

export default function MainMenu({ user }: { user: any }): JSX.Element {
  const { menuMode, setMenuMode } = useMenuMode();

  const { t } = useTranslation('common');

  const router = useRouter();

  const navigation = [
    [
      {
        name: t('navigation.crm.heading-main'),
        subName: ``,
        disabled: false,
        key: `crm`,
        href: `/crm/overview`,
        icon: CRMIcon,
        selectedIcon: CRMIcon
      },
      {
        name: t('navigation.search.heading'),
        disabled: false,
        key: `hitta`,
        href: `/hitta`,
        icon: SearchBuildingIcon,
        selectedIcon: SearchBuildingIcon
      },
      {
        name: t('navigation.advanced-search.heading'),
        disabled: false,
        key: `avancerad`,
        href: `/avancerad/sok`,
        matchFullPath: true,
        shallowNavigation: false,
        icon: AdvancedFilterIcon,
        selectedIcon: AdvancedFilterIcon,
        items: []
      },
      {
        name: t('navigation.rental-map.heading'),
        disabled: false,
        key: `avancerad`,
        href: `/avancerad/hyreskartan`,
        matchFullPath: true,
        shallowNavigation: false,
        icon: MarketDataIcon,
        selectedIcon: MarketDataIcon,
        items: []
      },
      {
        name:
          process.env.NEXT_PUBLIC_STAGE === 'production'
            ? t('general.local-councils')
            : t('navigation.local-councils.heading-demograhic'),
        disabled: false,
        key: `kommuner`,
        href: `/kommuner/overview`,
        icon: LocalCouncilIcon,
        selectedIcon: LocalCouncilIcon
      },
      {
        name: t('menu.transactions'),
        disabled: false,
        key: `transaktioner`,
        href: `/transaktioner/overview`,
        matchFullPath: true,
        shallowNavigation: false,
        icon: BanknotesIcon,
        selectedIcon: BanknotesIcon,
        items: []
      },
      user?.email.endsWith('@fastighetsloggen.se') || user?.email.endsWith('@fforum.se')
        ? {
            name: t('navigation.itismad.heading'),
            subName: ``,
            disabled: false,
            key: `bolagssök`,
            href: `/itismad/overview`,
            icon: ThirdBracketIcon,
            selectedIcon: ThirdBracketIcon
          }
        : null,
      user?.email.endsWith('@fastighetsloggen.se') ||
      user?.email.endsWith('@sverigesallmannytta.se')
        ? {
            name: t('navigation.an.heading'),
            subName: ``,
            disabled: false,
            key: `at`,
            href: `/at/`,
            icon: AllmannyttanLogo,
            selectedIcon: AllmannyttanLogo
          }
        : null,
      {
        name: t('data-updates.title'),
        disabled: false,
        key: 'data-status',
        href: '/data-status',
        matchFullPath: true,
        shallowNavigation: false,
        icon: DataStatusIcon,
        selectedIcon: DataStatusIcon
      },
      process.env.NEXT_PUBLIC_STAGE === 'production'
        ? null
        : {
            name: t('navigation.crm.heading-main'),
            subName: ``,
            disabled: false,
            key: `crm2`,
            href: `/crm2/overview`,
            icon: CRM2Icon,
            selectedIcon: CRM2Icon
          }
    ],
    [
      {
        name: t('navigation.settings.heading'),
        disabled: false,
        key: `settings`,
        href: `/profile/me`,
        icon: GearsIcon,
        mobileOnly: true,
        selectedIcon: GearsIcon
      }
    ]
  ]
    .filter((item) => !!item) // filter out undefined / empty tabs
    .map((item) => {
      return (item.filter as any)((item) => !!item);
    });

  const newQuery: any = {};

  if (router.query.selectedActor) {
    newQuery.selectedActor = router.query.selectedActor;
  }

  if (router.query.selectedProperty) {
    newQuery.selectedProperty = router.query.selectedProperty;
  }

  /* State flags */
  return (
    <div
      className={cls(
        'bg-gray-100 lg:bg-gray-200 dark:bg-dark-faded flex flex-shrink-0 top-[0px] bottom-0 left-0 border-r border-gray-200 dark:border-dark-faded transition-all duration-300',
        'fixed z-[51] w-full md:block md:w-[53px]',
        {
          'md:w-[53px]': menuMode !== MenuMode.EXPANDED_FIXED,
          hidden: menuMode && [MenuMode.MOBILE_CLOSED, MenuMode.COLLAPSED].includes(menuMode),
          '!w-[53px]': menuMode === MenuMode.COLLAPSING
        }
      )}>
      <div className="flex flex-col w-full">
        <div className="flex-1 flex flex-col min-h-0 h-full">
          <div className="flex-1 flex flex-col w-full items-start">
            {menuMode === MenuMode.MOBILE_OPEN ? (
              <XIcon
                onClick={() => setMenuMode(MenuMode.COLLAPSED)}
                className="flex ml-4 md:hidden h-[20px] w-[20px] mx-2 my-5.5 fill-light-graytext cursor-pointer"
              />
            ) : (
              <MenuIcon
                onClick={() => setMenuMode(MenuMode.MOBILE_OPEN)}
                className="flex ml-4 md:hidden h-[20px] w-[20px] mx-2 my-5.5 fill-light-graytext cursor-pointer"
              />
            )}

            <button
              className="pl-3 md:hidden text-sm font-sans font-medium text-gray-800 dark:text-gray-400 flex space-x-8 items-center"
              onClick={() => {
                if (menuMode === MenuMode.MOBILE_OPEN) {
                  setMenuMode(MenuMode.COLLAPSED);
                }
                router.push(
                  {
                    pathname: `/`,
                    query: newQuery
                  },
                  undefined,
                  { shallow: true }
                );
              }}>
              <div className="items-end ml-1">
                <span className="sr-only">{t('general.brand-name')}</span>

                <Image
                  height={45}
                  width={45}
                  className="h-[25px] md:h-[33px] transition-all duration-300 hover:opacity-80 w-auto"
                  src="/images/logo-icon.svg"
                  alt="logo Fastighetsloggen"
                />
              </div>

              <div>{t('menu.dashboard')}</div>
            </button>

            <nav
              aria-label="Sidebar"
              className="pt-6 pb-1 md:flex flex-col items-center w-full justify-start">
              <div className="w-full justify-center mb-[48px] hidden md:flex mt-1">
                <Link href="/" className="flex items-end">
                  <span className="sr-only">{t('general.brand-name')}</span>

                  {moment().unix() < 1735257600 ? (
                    <Image
                      height={70}
                      width={70}
                      className="h-[25px] lg:h-[40px] transition-all duration-300 hover:opacity-80 w-auto"
                      src="/images/logo-icon-xmas.svg"
                      alt="logo Fastighetsloggen"
                    />
                  ) : (
                    <Image
                      height={45}
                      width={45}
                      className="h-[20px] lg:h-[33px] transition-all duration-300 hover:opacity-80 w-auto"
                      src="/images/logo-icon.svg"
                      alt="logo Fastighetsloggen"
                    />
                  )}
                </Link>
              </div>

              {navigation.map((items: any, index) => (
                <div key={index} className="-0 w-full">
                  {items.map((item: any, idx) => {
                    let selected =
                      router?.asPath === item.href ||
                      item.key === router?.asPath?.split('/')[1] ||
                      router?.asPath?.split('?')[0] === item.href;
                    if (item.matchFullPath) {
                      const pathWithoutQuery = router?.asPath?.split('?')[0];
                      selected = pathWithoutQuery === item.href;
                    }

                    return (
                      <div
                        className={cls('px-0', {
                          'lg:hidden': item.mobileOnly
                        })}
                        key={idx}>
                        <div>
                          <button
                            onClick={() => {
                              if (menuMode === MenuMode.MOBILE_OPEN) {
                                setMenuMode(MenuMode.COLLAPSED);
                              }
                              router.push(
                                {
                                  pathname: item.href,
                                  query: newQuery
                                },
                                undefined,
                                { shallow: item.shallowNavigation ?? true }
                              );
                            }}
                            disabled={item.disabled}
                            className={cls(
                              'flex group py-7 relative border-l-4 cursor-pointer w-full items-center justify-start max-h-[40px] has-tooltip',
                              selected
                                ? 'dark:bg-dark-morefaded border-bobo-prop dark:border-bobo-prop bg-bobo-proplight'
                                : 'dark:border-transparent border-transparent dark:hover:text-white hover:text-bobo-hover hover:fill-bobo-hover hover:bg-bobo-proplight dark:hover:bg-dark-faded'
                            )}>
                            <div>
                              <div
                                className={cls(
                                  'flex items-center justify-center dark:text-white border-l-0 px-4 has-tooltip transition-colors',
                                  selected
                                    ? 'text-bobo-prop dark:text-dark-prop group-hover:text-bobo-prop group-hover:fill-bobo-prop dark:group-hover:text-dark-prophover dark:group-hover:fill-dark-prophover'
                                    : 'text-gray-800 dark:text-gray-400 group-hover:text-bobo-hover group-hover:fill-bobo-hover dark:group-hover:text-bobo-orange dark:group-hover:fill-dark-primary'
                                )}>
                                {selected ? (
                                  <item.selectedIcon
                                    className={`h-[19px] w-[19px] fill-bobo-prop text-bobo-prop dark:text-dark-prop dark:fill-dark-prop`}
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <item.icon
                                    className="h-[18px] w-[18px] group-hover:h-[20px] group-hover:w-[20px] fill-gray-900 dark:fill-light-faded dark:text-light-faded transition-all duration-200"
                                    aria-hidden="true"
                                  />
                                )}
                                <span className="sr-only">{item.name}</span>
                              </div>
                            </div>

                            <div
                              className={cls(
                                'md:hidden dark:text-white min-w-[135px] w-fit absolute left-[55px] max-w-[230px] p-2 px-4 md:shadow-lg font-sans flex-grow text-left text-sm leading-4 font-medium transition-colors duration-300 md:bg-white text-bobo-black md:border-gray-200 md:border md:rounded-md'
                              )}>
                              {item.name}
                              {item.subName && (
                                <>
                                  <br />
                                  <div className="text-[13px] break-words group-hover:text-light-graytext text-dark-faded font-normal dark:text-light-graysubtext transition-colors duration-300">
                                    {item.subName}
                                  </div>
                                </>
                              )}
                            </div>

                            <div
                              className={cls(
                                'hidden tooltip md:block min-w-[135px] w-fit absolute left-[55px] max-w-[230px] p-2 px-4 md:shadow-lg font-sans flex-grow text-left text-sm leading-4 font-medium transition-colors duration-300 md:bg-white text-bobo-black md:border-gray-200 md:border md:rounded-md dark:bg-dark-faded dark:border-dark-morefaded dark:text-white'
                              )}>
                              {item.name}
                              {item.subName && (
                                <>
                                  <br />
                                  <div className="text-[13px] break-words group-hover:text-light-graytext text-dark-faded font-normal dark:text-light-graysubtext transition-colors duration-300">
                                    {item.subName}
                                  </div>
                                </>
                              )}
                            </div>
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ))}
            </nav>
          </div>
        </div>
      </div>

      <div className="lg:absolute lg:bottom-2 left-0 w-full h-16">
        <Popover className="relative w-full">
          {({ open, close }) => (
            <>
              <Popover.Button
                className={cls(
                  open
                    ? 'text-gray-900 dark:text-white border-bobo-prop'
                    : 'text-gray-800 dark:text-gray-200 border-transparent',
                  'group justify-center hover:bg-bobo-proplight py-3 items-center w-full border-l-4 transition-all dark:bg-dark-primary text-xs font-normal hover:text-gray-600 lowercase md:flex hidden'
                )}>
                <div className="-ml-1 rounded-full bg-bobo-orange overflow-hidden pt-1">
                  <span className="sr-only">{t('general.brand-name')}</span>

                  <UserIcon className="-mb-1 h-[30px] w-[30px] text-white" />
                </div>
              </Popover.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-[900px]"
                enterTo="opacity-100 -translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 -translate-y-0"
                leaveTo="opacity-0 translate-y-[900px]">
                <Popover.Panel className="absolute bottom-1 z-50 left-[53px] transform mt-3 px-2 w-screen max-w-xs sm:px-0">
                  <div className="rounded-lg overflow-hidden shadow-lg ring-1 ring-black ring-opacity-5">
                    <UserMenu user={user} close={close} />
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    </div>
  );
}
