import { LinkIcon } from '@heroicons/react/24/outline';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GotoFeature } from 'shared/dist/types';
import ThumbMap from 'src/components/map/thumb';
import { getProximityProperties } from 'src/lib/gis';

export default function HistoricPoint({ object, close }: { object: any; close: any }) {
  const [properties, setProperties] = useState<any[]>([]);
  const router = useRouter();
  const { t } = useTranslation('common');

  // const lng = Array.isArray(object?.details[0]?.coordinates[0])
  //   ? object?.details[0]?.coordinates[0][0][0]
  //   : object?.details[0]?.coordinates[0];
  // const lat = Array.isArray(object?.details[0]?.coordinates[0])
  //   ? object?.details[0]?.coordinates[0][0][1]
  //   : object?.details[0]?.coordinates[1];
  // if (object?.yta) {
  //   const polygonJson = object?.details?.map((a) => {
  //     return bbox(polygon(a?.coordinates));
  //   });
  //   newBbox = polygonJson[0];
  // } else {
  //   newBbox = [lng - diff, lat - diff, lng + diff, lat + diff];
  // }

  const lat: number = object?.details?.lat;
  const lng: number = object?.details?.lng;

  const info: any = object?.details?.properties;

  useEffect(() => {
    getProximityProperties(lng, lat).then((result) => {
      setProperties(result?.features);
    });
  }, []);

  return (
    <div className="flex z-30 h-full flex-col">
      {/* Main */}
      <div>
        <div className="pb-1 sm:pb-6">
          <div>
            <div className="relative h-44 sm:h-64 border overflow-hidden hidden md:block">
              <ThumbMap
                obj={object}
                bboxOverride={[lng - 0.001, lat - 0.001, lng + 0.001, lat + 0.001]}
                feature={GotoFeature.DETAIL_PLAN}
                defaultLayers={['lamningar', 'properties']}
              />
            </div>

            <div className="overflow-y-scroll h-[90vh] md:h-[63vh] mt-4">
              <div className="px-2 sm:px-6 py-4 text-sm text-gray-800">
                <div className="text-base font-bold text-gray-900 dark:text-white sm:text-lg uppercase !leading-tight mt-10 md:mt-0">
                  {info?.lamningtyp}
                </div>

                <div className="text-sm text-gray-700 dark:text-gray-300 mt-1">
                  {t('property.raa', { nr: info?.raa_nummer })}{' '}
                  <div className="bg-bobo-verylightgreen dark:bg-dark-prop px-2 text-mini text-gray-900 dark:text-white font-medium rounded-full inline-flex z-30">
                    {info?.akt_status}
                  </div>
                </div>

                <div className="text-sm text-gray-700 dark:text-gray-300">
                  {t('general.published', { when: info?.sen_publi })}
                </div>

                <div className="text-xs text-gray-600 dark:text-gray-400 my-2 py-4">
                  <div className="font-medium text-base mb-1">{t('property.nearby-props')}</div>

                  {properties?.map((prop, key) => {
                    return (
                      <button
                        key={key}
                        onClick={() => {
                          close();
                          router.push(`/crm/fastighet/${prop?.properties?.objekt_id}/overview`);
                        }}
                        className="flex justify-between border-t mt-0 font-medium hover:bg-gray-100 dark:hover:bg-dark-lessfaded cursor-pointer w-full">
                        <div className="py-2 w-3/6 px-0 text-bobo-prop dark:text-dark-prop flex">
                          <LinkIcon className="w-4 h-4 mr-2" /> {prop?.properties?.kommunnamn}{' '}
                          {prop?.properties?.fastighet}
                        </div>

                        <div className="py-2 w-3/6 px-0 text-right italic">
                          {Math.round(prop?.properties?.tilequery?.distance) === 0
                            ? t('property.within-area')
                            : t('property.m-from-area', {
                                amount: Math.round(prop?.properties?.tilequery?.distance)
                              })}
                        </div>
                      </button>
                    );
                  })}
                </div>

                <div className="text-xs text-gray-600 dark:text-gray-400 my-2 py-4 border-t dark:border-gray-600">
                  <div className="font-medium text-base mb-1">{t('general.desc')}</div>
                  {info?.beskrivn}
                </div>

                <div className="text-xs text-gray-600 dark:text-gray-400 py-2 border-b dark:border-gray-600">
                  <div className="font-medium text-base mb-1">
                    {t('property.historic-point.status')}
                  </div>

                  <div className="grid grid-cols-2 border-t dark:border-gray-600 mt-4 font-medium">
                    <div className="py-2">{t('property.historic-point.assessment')}</div>

                    <div className="py-2 text-right">{info?.antikv_bed}</div>

                    <div className="bg-gray-100 dark:bg-dark-lessfaded py-2">
                      {t('property.historic-point.comment')}
                    </div>

                    <div className="bg-gray-100 dark:bg-dark-lessfaded py-2 text-right">
                      {info?.antikv_mot}
                    </div>

                    <div className="py-2">{t('property.historic-point.damage')}</div>

                    <div className="py-2 text-right">{info?.skadestat}</div>

                    <div className="bg-gray-100 dark:bg-dark-lessfaded py-2">
                      {t('property.historic-point.status-examination')}
                    </div>

                    <div className="bg-gray-100 dark:bg-dark-lessfaded py-2 text-right">
                      {info?.und_status}
                    </div>

                    <div className="py-2">{t('property.location')}</div>

                    <div className="py-2 text-right">
                      {info?.orienter} - {info?.placering}
                    </div>
                  </div>
                </div>

                <div className="bg-bobo-verylightgreen dark:bg-dark-prop py-1 px-4 text-mini text-gray-900 dark:text-white rounded-full inline-flex z-30 mt-8">
                  {t('general.sources', { sources: info?.pub_av_org })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
