/* External Libraries */
import { Combobox, Dialog, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline';
import cls from 'classnames';
import { Trans, useTranslation } from 'next-i18next';
import { Fragment, useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { ButtonType } from 'shared/dist/types';
import { addUpdateMember } from 'src/lib/customer-client-wrapper';
/* Components & Widgets */
import Button from 'src/widgets/buttons';
import { Input } from 'src/widgets/inputs';
import isEmail from 'validator/lib/isEmail';

export default function HandleMember({
  object: { initStep, member, activeActor = null },
  onClose
}: {
  onClose: () => any;
  object: { initStep: 'add' | 'edit' | 'deactivate'; member: any; activeActor? };
}) {
  const { t } = useTranslation('common');

  const roles = [
    { id: 'analysis', name: t('settings.roles.analysis') },
    { id: 'transaction', name: t('settings.roles.transaction') },
    { id: 'business-development', name: t('settings.roles.business-development') },
    { id: 'management', name: t('settings.roles.management') },
    { id: 'durability', name: t('settings.roles.durability') }
  ];

  const [query, setQuery] = useState('');

  const filteredRoles =
    query === ''
      ? roles
      : roles.filter((role) =>
          role.name
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        );

  const [localMember, setLocalMember] = useState<{
    user: {
      name: string;
      email: string;
      role: { id: string; name: string };
    };
    permission: {
      isAdmin: boolean;
    };
  }>({
    user: {
      name: '',
      email: '',
      role: { id: '', name: '' }
    },
    permission: {
      isAdmin: false
    }
  });

  const [valid, setValid] = useState<boolean>(false);

  const [teamRefreshTrigger, setTeamRefreshTrigger] = useGlobal<any>('teamRefreshTrigger' as never);

  useEffect(() => {
    if (member) {
      const role = member?.user?.role;

      let roleObj: any;

      if (role) {
        roleObj = roles.find((r) => r.id == role);
        if (!roleObj) {
          //custom role
          roleObj = { id: role, name: role.split(':').slice(1).join(':') };
        }
      }

      setLocalMember({
        ...member,
        user: { ...member.user, role: roleObj },
        permission: { isAdmin: member?.permission?.isAdmin ?? localMember?.permission.isAdmin }
      });
    }
  }, [member]);

  let mod: JSX.Element = <div />;

  const existing: boolean = member?.user?.email?.length > 0 ? true : false;

  const validateInput = (name: string, email: string): boolean => {
    return name && email && name?.length >= 1 && isEmail(email);
  };

  const handleRoleChange = (role) => {
    setLocalMember({
      ...localMember,
      user: {
        ...localMember.user,
        role: role
      }
    });
    setValid(validateInput(localMember?.user?.name, localMember?.user.email));
  };

  switch (initStep) {
    case 'add':
    case 'edit':
      mod = (
        <div className="flex-grow flex flex-col items-center">
          {/* Header */}
          <div className="flex flex-col w-full text-center items-center">
            <Dialog.Title
              as="h3"
              className="text-lg font-medium text-gray-900 dark:text-white w-3/4 leading-6">
              {existing
                ? t('settings.team.edit', {
                    name: member?.user?.name
                  })
                : t('settings.team.invite-user')}
            </Dialog.Title>

            <p className="mt-6 text-sm w-full text-gray-500 dark:text-gray-300">
              {existing
                ? t('settings.team.edit-user-desc', {
                    name: member?.user?.name
                  })
                : t('settings.team.invite-user-desc')}
            </p>
          </div>

          <label
            htmlFor="user-name"
            className="w-full md:!w-[60%] text-left mt-5 text-xs font-medium text-gray-500 dark:text-gray-400">
            {t('general.name')}
          </label>

          <Input
            type="text"
            name="user-name"
            value={localMember?.user?.name}
            onChange={(name) => {
              setLocalMember({
                ...localMember,
                user: {
                  ...localMember.user,
                  name: name?.target.value
                }
              });
              setValid(validateInput(name?.target.value, localMember?.user?.email));
            }}
            id="user-name"
            className="flex border-b rounded-sm px-2 pt-2 placeholder:text-gray-200 dark:placeholder:text-gray-400 md:!w-[60%] last:mb-2"
          />

          <label
            htmlFor="user-email"
            className="w-full md:!w-[60%] text-left mt-5 text-xs font-medium text-gray-500 dark:text-gray-400">
            {t('settings.personal.email')}
          </label>

          <Input
            type="email"
            name="user-email"
            value={localMember?.user?.email ?? member?.user?.email}
            disabled={existing ? true : false}
            onChange={(email) => {
              setLocalMember({
                ...localMember,
                user: {
                  ...localMember.user,
                  email: email?.target?.value
                }
              });
              setValid(validateInput(localMember?.user?.name, email?.target?.value));
            }}
            id="user-email"
            className={cls(
              'flex border-b rounded-sm px-2 pt-2 placeholder:text-gray-200 dark:placeholder:text-gray-400 md:!w-[60%] last:mb-2',
              existing && 'text-gray-500 bg-gray-200'
            )}
          />

          <div className="md:!w-[60%]">
            <label
              htmlFor="user-role"
              className="block text-xs font-medium text-left text-gray-500 dark:text-gray-400 mt-5">
              {t('settings.team.user-role')}
            </label>

            <Combobox
              value={localMember?.user?.role ?? { id: '', name: '' }}
              by="id"
              onChange={handleRoleChange}>
              {({ open }) => (
                <div className="relative mt-1">
                  <div className="relative w-full cursor-default overflow-hidden border border-gray-300 dark:border-dark-morefaded bg-white text-left rounded-sm">
                    <Combobox.Input
                      className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 dark:text-white dark:bg-dark-faded"
                      displayValue={(role: any) => role?.name}
                      onChange={(event) => setQuery(event.target.value)}
                    />

                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronDownIcon
                        className={cls('h-5 w-5 text-gray-400', {
                          '-rotate-90': open
                        })}
                        aria-hidden="true"
                      />
                    </Combobox.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setQuery('')}>
                    <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-sm bg-white dark:bg-dark-faded shadow-lg ring-1 ring-black/5 text-left">
                      {query.length > 0 && (
                        <Combobox.Option
                          value={{ id: 'custom:' + query, name: query }}
                          className="text-bobo-green text-xs py-2 pl-10 pr-4">
                          {t('settings.team.create')} &#34;{query}&#34;
                        </Combobox.Option>
                      )}

                      {filteredRoles.map((role) => (
                        <Combobox.Option
                          key={role.id}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                              active
                                ? 'bg-bobo-prop dark:bg-dark-prophover text-white text-xs'
                                : 'text-gray-900 dark:text-white text-xs'
                            }`
                          }
                          value={role}>
                          {({ selected, active }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? 'font-medium' : 'font-normal'
                                }`}>
                                {role.name}
                              </span>

                              {selected ? (
                                <span
                                  className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                    active ? 'text-white' : 'text-gray-900 dark:text-white'
                                  }`}>
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Combobox.Option>
                      ))}
                    </Combobox.Options>
                  </Transition>
                </div>
              )}
            </Combobox>
          </div>

          {/* Privacy */}
          <fieldset className="w-full mt-5">
            <legend className="sr-only">{t('settings.team.access')}</legend>

            <h4 className="text-sm text-gray-800 dark:text-gray-300" aria-hidden="true">
              {t('settings.team.access')}
            </h4>

            <div className="flex gap-4 mt-5">
              <button
                className={cls(
                  'border ring-gray-200 text-center rounded-md p-4 w-1/2 cursor-pointer hover:bg-bobo-proplight disabled:cursor-not-allowed disabled:bg-gray-100 disabled:dark:bg-dark-morefaded disabled:dark:border-gray-400 dark:border-dark-morefaded dark:hover:bg-dark-prophover dark:bg-dark-faded focus-visible:ring-2 focus-visible:border-none focus-visible:ring-bobo-prop dark:focus-visible:ring-dark-prop dark:focus-visible:bg-dark-faded dark:hover:focus-visible:bg-dark-prophover',
                  { 'border-bobo-prop dark:border-dark-prop': localMember?.permission?.isAdmin }
                )}
                onClick={() => {
                  setLocalMember({
                    ...localMember,
                    permission: {
                      isAdmin: true
                    }
                  });
                  setValid(validateInput(localMember?.user?.name, localMember?.user?.email));
                }}>
                <div className="flex w-full justify-center items-center">
                  <span className="text-sm text-gray-600 dark:text-white cursor-pointer">
                    {t('settings.team.admin')}
                  </span>
                </div>

                <p
                  id="admin-access-description"
                  className="text-xs text-gray-500 dark:text-white text-center mt-3">
                  {t('settings.team.admin-access-desc')}
                </p>
              </button>

              <button
                className={cls(
                  'border ring-gray-200 text-center rounded-md p-4 w-1/2 cursor-pointer hover:bg-bobo-proplight disabled:cursor-not-allowed disabled:bg-gray-100 disabled:dark:bg-dark-morefaded disabled:dark:border-gray-400 dark:border-dark-morefaded dark:hover:bg-dark-prophover dark:bg-dark-faded focus-visible:ring-2 focus-visible:border-none focus-visible:ring-bobo-prop dark:focus-visible:ring-dark-prop dark:focus-visible:bg-dark-faded dark:hover:focus-visible:bg-dark-prophover',
                  { 'border-bobo-prop dark:border-dark-prop': !localMember?.permission?.isAdmin }
                )}
                onClick={() => {
                  setLocalMember({
                    ...localMember,
                    permission: {
                      isAdmin: false
                    }
                  });
                  setValid(validateInput(localMember?.user?.name, localMember?.user?.email));
                }}>
                <div className="flex w-full justify-center items-center">
                  <span className="text-sm font-medium text-gray-600 dark:text-white cursor-pointer">
                    {t('settings.team.user')}
                  </span>
                </div>

                <p
                  id="user-access-description"
                  className="text-xs text-gray-500 dark:text-white text-center mt-3">
                  {t('settings.team.user-access-description')}
                </p>
              </button>
            </div>
          </fieldset>

          {/* Action buttons */}
          <div className="flex flex-grow justify-between w-full items-end mt-5">
            <Button type={ButtonType.CANCEL} onClick={onClose}>
              {t('general.cancel')}
            </Button>

            <Button
              type={ButtonType.ADD}
              disabled={!valid}
              onClick={async () => {
                const isValid = validateInput(
                  localMember?.user?.name ?? member?.user?.name,
                  localMember?.user?.email ?? member?.user?.email
                );

                if (!isValid) {
                  return;
                }
                onClose();
                await addUpdateMember(localMember, member?.user?.email ? member?.user : null, t);
                setTeamRefreshTrigger(true);
              }}>
              {existing ? t('general.save') : t('settings.team.invite')}
            </Button>
          </div>
        </div>
      );
      break;

    case 'deactivate':
      mod = (
        <div className="flex-grow flex flex-col items-center space-y-5">
          {/* Header */}
          <div className="w-full text-center items-center mt-5">
            <Trans
              i18nKey="settings.team.warning-message"
              t={t}
              name={member?.user?.name}
              companyName={activeActor?.company_name ?? activeActor?.name}>
              När du avaktiverar kan <strong>{{ name: member?.user?.name }}</strong>
              inte längre logga in och använda Fastighetsloggen som{' '}
              <strong>{{ companyName: activeActor?.company_name ?? activeActor?.name }}</strong>.
            </Trans>
          </div>

          {/* Divider container */}
          {/* Project name */}
          <div className="px-4 sm:px-6 py-10 bg-gray-100 dark:bg-dark-faded">
            {t('settings.team.user-deactivation-confirm', {
              name: member?.user?.name
            })}
          </div>

          {/* Action buttons */}
          <div className="flex justify-between w-full items-end flex-grow">
            <Button type={ButtonType.CANCEL} onClick={onClose}>
              {t('general.cancel')}
            </Button>

            <Button
              type={ButtonType.DELETE}
              onClick={async () => {
                onClose();
                await addUpdateMember(
                  localMember,
                  member?.user?.email ? member?.user : null,
                  t,
                  true,
                  false
                );
                setTeamRefreshTrigger(true);
              }}>
              {t('settings.security.deactivate')}
            </Button>
          </div>
        </div>
      );
      break;
  }
  return (
    <>
      <button
        onClick={onClose}
        className="absolute right-5 top-5 z-50 text-gray-500 hover:text-bobo-prophover dark:text-gray-300 dark:hover:text-dark-prophover focus-visible:ring-indigo-500 focus-visible:text-indigo-500 dark:focus-visible:ring-indigo-400 dark:focus-visible:text-indigo-400 focus-visible:ring-2 focus-visible:hover:ring-0 rounded-md p-1 focus-visible:hover:text-gray-500 dark:focus-visible:hover:text-gray-300">
        <XMarkIcon className="h-5 w-5" />
      </button>
      {mod}
    </>
  );
}
