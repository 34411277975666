/* External Libraries */
import toast from 'react-hot-toast';

import { Tfunc } from '../types';

export async function getActorUsers(actorUuid: string, t: Tfunc) {
  const toastId = toast.loading(t('toasts.get-team-loading'));

  const url = `/api/customer/getActorUsers`;

  const body = JSON.stringify({ actor_uuid: actorUuid });

  return fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      return res.json();
    })
    .then((json) => {
      toast.dismiss();
      return json;
    })
    .catch((err) => {
      toast.error(t('toasts.get-team-error', { errorMsg: err.message }), {
        id: toastId
      });
    });
}

export async function addUpdateMember(
  newData,
  orgData,
  t: Tfunc,
  deactivate = false,
  activate = false
) {
  const toastId = toast.loading(
    orgData ? t('toasts.update-member-loading') : t('toasts.invite-member-loading')
  );

  const body = JSON.stringify({
    newData,
    orgData,
    permission: newData?.permission,
    deactivate,
    activate
  });

  return fetch(`/api/customer/addUpdateMember`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      if (json.result == 'invite-user-error-duplicate-email') {
        toast.error(t('toasts.invite-member-error'), {
          id: toastId
        });
      } else if (!orgData) {
        toast.success(t('toasts.invite-member-success'), {
          id: toastId
        });
      } else {
        toast.success(t('toasts.update-member-success'), {
          id: toastId
        });
      }
      return json;
    })
    .catch((err) => {
      toast.error(t('toasts.update-team-error', { errorMsg: err.message }), {
        id: toastId
      });
      return null;
    });
}

export async function addNewMember(
  newData,
  orgData,
  actor,
  t: Tfunc,
  deactivate = false,
  activate = false
) {
  const toastId = toast.loading(
    orgData ? t('toasts.update-member-loading') : t('toasts.invite-member-loading')
  );

  const body = JSON.stringify({
    newData,
    orgData: null,
    addNewCo: true,
    customerActor: actor,
    permission: newData?.permission,
    deactivate,
    activate
  });

  return fetch(`/api/customer/addUpdateMember`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      toast.success(
        orgData ? t('toasts.update-member-success') : t('toasts.invite-member-success'),
        {
          id: toastId
        }
      );
      return json;
    })
    .catch((err) => {
      toast.error(
        t('toasts.update-team-error', {
          errorMsg: err.message
        }),
        {
          id: toastId
        }
      );
      return null;
    });
}

export async function updateCustomerPlan(actor, activePlan, t: Tfunc) {
  return fetch('/api/admin/setCustomerPlan', {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      activePlan: activePlan.name,
      actor_uuid: actor.actor_uuid
    })
  }).catch((err) => {
    toast.error(
      t('toasts.update-customer-plan-error', {
        errorMsg: err.message
      })
    );
    throw err;
  });
}
