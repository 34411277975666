/* eslint-disable i18next/no-literal-string */
import { useRouter } from 'next/router';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useGlobal } from 'reactn';

import UploadExcelModal from '@/src/components/itismad/upload-excel';

import Button from '../buttons';

export default function ItIsMadAddButton() {
  const router = useRouter();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [crmIncrement, setCRMIncrement] = useGlobal('crmIncrement');

  const handleDataSubmit = (data: any) => {
    toast(
      `Laddat upp ny fil med ${data?.length} rader marknadsdata för ${
        router.query.index && router.query.index.length > 1 ? router.query.index[1] : ''
      }.`
    );
  };

  return (
    <>
      <Button onClick={() => setIsModalOpen(true)}>Ladda upp data</Button>
      <UploadExcelModal
        isOpen={isModalOpen}
        onClose={() => {
          setCRMIncrement((crmIncrement || 0) + 1);
          setIsModalOpen(false);
        }}
      />
    </>
  );
}
