import { BuildingOffice2Icon } from '@heroicons/react/24/solid';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { PropertyTypeGroup } from 'shared/dist/types';
import { getPrimaryTaxCodeBasedOnBuildingSize } from 'shared/dist/util/calculations';

import ColorHeader from '@/src/components/property/items/color-header';
import NewsecYieldBox from '@/src/components/property/items/newsec-yield-box';
import RentStatsTable from '@/src/components/property/items/rent-stats-table';
import { useBreakpoint } from '@/src/hooks/use-breakpoint';
import { getColorForPropertyType } from '@/src/lib/colors';
import CrmButton from '@/src/widgets/menu-options/crm-button';
import ValuationToolBtn from '@/src/widgets/menu-options/valuation-tool-button';
import { PropLabels } from '@/src/widgets/prop-labels';

export default function PropertyHeader({ property, close }: { property: any; close: any }) {
  const { isMd } = useBreakpoint({ breakpoint: 'md' });
  const { t } = useTranslation('common');

  const primaryType: PropertyTypeGroup = getPrimaryTaxCodeBasedOnBuildingSize(property?.propTax);

  let propName: string = property?.authority_nickname ?? property?.authorityNickname;

  propName = propName?.replace(`${property?.county?.toUpperCase()}`, ``);

  const { color } = getColorForPropertyType(primaryType);

  return (
    <ColorHeader primaryType={primaryType}>
      <div className="text-xl text-left font-medium text-gray-900 dark:text-white sm:text-2xl w-full">
        <PropLabels small={true} object={property} />

        <div className="flex flex-col lg:flex-row justify-between items-start w-full mt-2 h-fit">
          <div className="h-full flex flex-col justify-between">
            <div className="flex items-center">
              <div>{propName}</div>
            </div>

            <div className="font-normal text-[10px] text-gray-400 -mt-1 leading-4 dark:text-white">
              {property?.county?.toUpperCase()}
            </div>

            <div className="flex justify-start items-center mt-3 gap-2 h-full">
              <Link
                href={`/crm/fastighet/${property?.uuid}/overview`}
                shallow={true}
                onClick={() => {
                  close();
                }}>
                <span
                  style={{ backgroundColor: `${color}` }}
                  className="flex items-center hover:opacity-80 transition-all duration-200 rounded-sm text-xs text-white font-normal p-2 px-3 hover">
                  <BuildingOffice2Icon className="w-5 h-5 mr-2" />
                  {t('property.property-card')}
                </span>
              </Link>

              <CrmButton property={property} iconOnly={true} />

              {isMd ? (
                <ValuationToolBtn property={property} iconOnly={true} closeSlideIn={close} />
              ) : null}
            </div>
          </div>

          <div className="flex gap-2 flex-col sm:flex-row mt-2 sm:mt-4 lg:mt-0 w-full h-full sm:w-[unset]">
            <div className="w-full lg:w-[260px]">
              <NewsecYieldBox object={property} iconOnly={true} close={close} />
            </div>

            <div className="w-full lg:w-[260px]">
              <RentStatsTable iconOnly={true} property={property} close={close} />
            </div>
          </div>
        </div>
      </div>
    </ColorHeader>
  );
}
