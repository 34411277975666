import { useTranslation } from 'next-i18next';
import NumberFormat from 'react-number-format';
import { filterOutExtremeRents } from 'shared/dist/constants';
import { PropertyTypeGroup } from 'shared/dist/types';
import {
  getBuildingUsed,
  getLatestYearOfRentalData,
  getRentIntervals
} from 'shared/dist/util/rent';

import RowWrapper from '@/src/layout/row-wrapper';
import { getOfficeNeighbours } from '@/src/lib/rent-calculator';
import { compare_index } from '@/src/lib/utils/sort';
import ShowRent, { FLSqm } from '@/src/widgets/numbers';

import RentTaxTableWrapper from '../wrappers/rent-tax-table-wrapper';

export default function RentTaxTableSpace({
  object,
  rent,
  pt,
  forceCategory = null,
  slideIn = false
}: {
  object: any | null;
  rent: any | null;
  pt: any | null;
  forceCategory?: PropertyTypeGroup | null;
  slideIn?: boolean;
}) {
  const { t } = useTranslation('common');

  const loa = rent?.v2?.office_details?.sqm ?? null;

  const valueYear: string[] = [];
  pt?.taxUnits?.map((tu: any) => {
    if (!valueYear?.includes(tu?.value_year) && tu?.value_year) {
      valueYear.push(tu?.value_year);
    }
  });

  const list: number[] = object?.space?.nearbySpaces
    ?.filter((a) => filterOutExtremeRents(a, PropertyTypeGroup.SPACE))
    ?.map((a) => a?.office_rent);

  const { interval, mean }: { interval: [number, number]; mean: number } = getRentIntervals(
    list ?? [],
    rent?.v2?.office_rent
  );

  const rentNeighbours: any[] = getOfficeNeighbours(
    rent?.space?.nearbySpaces ?? [],
    object,
    interval
  );

  const { buildingUsed } = getBuildingUsed(object?.propTax, PropertyTypeGroup.SPACE);

  const chartData: any[] =
    rentNeighbours?.length > 0
      ? rentNeighbours
          ?.filter((a) => a?.office_rent > 0)
          ?.map((a) => {
            if (object?.uuid === a?.uuid) {
              return {
                authority_nickname: a?.authority_nickname,
                lat: a?.lat,
                lng: a?.lng,
                county: a?.county,
                uuid: a?.uuid,
                owner: a?.tax_owners?.map((o) => o?.name),
                loa: a?.gross_leasable_area,
                boa: null,
                rent: null,
                selected: Math.round(a?.office_rent),
                index: Math.round(a?.office_rent)
              };
            } else {
              return {
                authority_nickname: a?.authority_nickname,
                lat: a?.lat,
                lng: a?.lng,
                county: a?.county,
                uuid: a?.uuid,
                owner: a?.tax_owners?.map((o) => o?.name),
                loa: a?.gross_leasable_area,
                boa: null,
                rent: Math.round(a?.office_rent),
                selected: null,
                index: Math.round(a?.office_rent)
              };
            }
          })
      : [];

  chartData.sort(compare_index);

  const { lastYearOfSpacesRent } = getLatestYearOfRentalData(rent?.v2);

  return (
    <RentTaxTableWrapper
      pt={pt}
      forceCategory={forceCategory}
      slideIn={slideIn}
      object={object}
      primaryType={PropertyTypeGroup.SPACE}
      chartData={chartData}
      mean={mean}>
      <RowWrapper
        title={`${t('property.rent.estimated-rent-short', {
          rentType: t('property.rent-avg')
        })} ${lastYearOfSpacesRent}`}
        helpTextId={'rentAvgSpaces'}>
        <ShowRent
          value={Math.round(rent?.v2?.office_rent)}
          suffix={t('general.kr-per-square-m-suffix')}
          propType={PropertyTypeGroup.SPACE}
        />
      </RowWrapper>

      <RowWrapper
        helpTextId={'rentInterval'}
        title={t('general.rent-interval', { rentType: t('property.rent-avg') })}>
        {interval[0] > 0 && interval[1] > 0 ? (
          <>
            <NumberFormat
              value={Math.round(interval[0])}
              displayType={'text'}
              thousandSeparator={` `}
              suffix={t('general.kr-suffix')}
              prefix={t('general.sek-prefix')}
            />
            &nbsp; - &nbsp;
            <NumberFormat
              value={Math.round(interval[1])}
              displayType={'text'}
              thousandSeparator={` `}
              suffix={t('general.kr-suffix')}
              prefix={t('general.sek-prefix')}
            />{' '}
          </>
        ) : (
          t('property.no-rent-data-for-property')
        )}
      </RowWrapper>

      <RowWrapper helpTextId={'rentLoa'} title={t('general.loa')}>
        <NumberFormat
          value={Math.round(loa)}
          displayType={'text'}
          thousandSeparator={` `}
          suffix={t('general.square-meters')}
        />
      </RowWrapper>
      {buildingUsed && buildingUsed?.length > 1 ? <div /> : null}

      {buildingUsed && buildingUsed?.length > 1 && (
        <div className="divide-dashed divide-y pb-2 px-3 pl-4 pt-2 even:bg-gray-50 dark:even:bg-dark-faded dark:text-white text-xs">
          {buildingUsed?.map((br, idx) => {
            return (
              <div key={idx} className="flex py-1 first:pt-0 justify-between">
                <div className="flex items-start justify-start">
                  <div className="bg-bobo-prop dark:bg-dark-prop mt-[3px] rounded-full w-2 h-2 mr-1" />
                  <div className="capitalize mr-6 leading-[13px]">{br?.unit_type}</div>
                </div>
                <div className="text-right flex-1 min-w-[60px]">
                  <FLSqm value={br?.area} />
                </div>
              </div>
            );
          })}
        </div>
      )}
      <RowWrapper helpTextId={'rentValueYearArea'} title={t('property.value-year')}>
        {valueYear?.join(', ')}
      </RowWrapper>
    </RentTaxTableWrapper>
  );
}
