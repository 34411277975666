import { ArrowRightCircleIcon } from '@heroicons/react/24/solid';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { getPropTypesFromTaxCodes } from 'shared/dist/helpers/propertyTaxes';
import { PropertyTypeGroup } from 'shared/dist/types';
import { Building } from 'src/animations';
import SignedGoogleMapsImage from 'src/components/map/signed-google-maps-image';
import ApartmentTable from 'src/components/property/items/apartment-table';

import { CompanyTable } from '@/src/components/bolag/items/company-table';
import AreaTaxTable from '@/src/components/property/items/area-tax-table';
import MarketDataTable from '@/src/components/property/items/market-data';
import RentTaxTableHousing from '@/src/components/property/rent/items/tables/rent-tax-table-housing';
import RentTaxTableSpace from '@/src/components/property/rent/items/tables/rent-tax-table-space';
import RentTaxTableWarehouse from '@/src/components/property/rent/items/tables/rent-tax-table-warehouse';
import { fetchPropertyRent, getProperty } from '@/src/lib/prop-client-wrapper';
import { PropWarning } from '@/src/widgets/prop-labels';

import PropertyHeader from './items/property-header';

export default function RentHousing({ object, close }: { object: any; close: any }) {
  const [globalFilters, setGlobalFilters] = useGlobal<any>('globalFilters');
  const [loading, setLoading] = useState<boolean>(true);
  const [property, setProperty] = useState<any | null>(null);
  const [energy, setEnergy] = useState<any | null>(null);
  const router = useRouter();
  const { t } = useTranslation('common');

  const includeBRF = globalFilters?.PROP_OWNERS_BRF ?? false;

  const fetchData = async () => {
    setLoading(true);
    const json = await getProperty(
      { uuid: object?.uuid },
      ['address', 'lagfart', 'core', 'energy', 'tax', 'whereInCrm'],
      false
    );

    if (json?.error) {
      console.error(json?.errMessage);
      setLoading(false);
      return;
    }

    const taxCodes: number[] = json?.property?.taxCodes;
    const propTypes: PropertyTypeGroup[] = getPropTypesFromTaxCodes(taxCodes);

    await fetchPropertyRent(object, propTypes, includeBRF, undefined)
      .then((data) => {
        const isBRF = property?.owners?.find((a) => a?.actor_id?.startsWith('7'));

        setProperty({
          ...json?.property,
          v2: data?.v2,
          is_brf: isBRF,
          market_data: {
            newsec: data?.market_data?.filter((obj) => obj?.institute === 'newsec'),
            forum: data?.market_data?.filter((obj) => obj?.institute === 'forum')
          },
          housing: {
            ...data?.housing
          },
          space: {
            ...data?.space
          },
          warehouse: {
            ...data?.warehouse
          }
        });
        setEnergy(json?.property?.energyStatement);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const el: any = window.document.getElementById('test');

    el.scrollTo(0, 0);

    if (object?.uuid) {
      fetchData();
    }
  }, [object?.uuid]);

  const taxCodes: number[] = property?.propTax?.map((a) => a?.tax_code);

  return (
    <div
      id={`test`}
      className="flex z-30 h-full flex-col overflow-y-scroll bg-gray-50 dark:bg-dark-lessfaded scroll-smooth">
      <div className="pb-1 sm:pb-6 h-full">
        {loading ? (
          <>
            <div className="relative h-36 sm:h-48 z-10 overflow-hidden" />
            <Building />
          </>
        ) : property ? (
          <>
            <div className="relative h-36 sm:h-48 z-10 overflow-hidden">
              <SignedGoogleMapsImage
                height={286}
                width={970}
                className="object-contain"
                src={`https://maps.googleapis.com/maps/api/streetview?size=1200x188&location=${encodeURIComponent(
                  `${property?.lat},${property?.lng}`
                )}&fov=120&pitch=5&key=AIzaSyBYCMGQ9cdHlRBN414KW_VE0WgTzR6Sqow&source=outdoor`}
              />
            </div>

            <div className="items-start flex flex-col justify-center text-left w-full">
              <PropertyHeader property={{ ...property }} close={close} />

              <div className="w-full mt-4 md:px-4 lg:px-6">
                <div>
                  {property ? (
                    <CompanyTable property={property} slideIn={true} close={close} />
                  ) : null}
                </div>
              </div>

              <div className="flex flex-col lg:flex-row mt-3 justify-start items-start lg:space-x-3 w-full md:px-4 lg:px-6 space-y-3 lg:space-y-0">
                <div className="flex-1 text-xs w-full lg:w-[55%] space-y-3">
                  <ApartmentTable
                    object={{ ...property, energyStatement: energy }}
                    compact={true}
                  />
                </div>

                <div className="space-y-3 w-full lg:w-[45%]">
                  <AreaTaxTable property={property} />
                </div>
              </div>

              <div className="my-4 w-full md:px-6 sm:px-4">
                <MarketDataTable object={property} />
              </div>

              <div className="mt-3 w-full md:px-4 sm:px-6">
                {taxCodes?.find((a) => [320, 321]?.includes(a)) && (
                  <RentTaxTableHousing
                    object={{ ...property }}
                    rent={{ ...property }}
                    pt={property?.propTax?.find((a) => [320, 321]?.includes(a?.tax_code))}
                    slideIn={true}
                  />
                )}

                {taxCodes?.find((a) => [423, 426, 432, 433]?.includes(a)) && (
                  <RentTaxTableWarehouse
                    object={{ ...property }}
                    rent={{ ...property }}
                    pt={property?.propTax?.find((a) => [423, 426, 432, 433]?.includes(a?.tax_code))}
                    slideIn={true}
                  />
                )}

                {taxCodes?.find((a) => [325, 326]?.includes(a)) && (
                  <RentTaxTableSpace
                    object={{ ...property }}
                    rent={{ ...property }}
                    pt={property?.propTax?.find((a) => [325, 326]?.includes(a?.tax_code))}
                    slideIn={true}
                  />
                )}
              </div>

              <div className="w-full flex justify-end items-center pb-24 pt-2 pr-6">
                <button
                  className="flex text-bobo-prop hover:text-bobo-prophover dark:text-dark-prop dark:hover:text-dark-prophover transition-color duration-200 items-center gap-1"
                  onClick={() => {
                    router.push(`/crm/fastighet/${property?.uuid}/overview`);
                    close();
                  }}>
                  <div className="text-xs font-bold">{t('navigation.go-to-prop-card')}</div>

                  <ArrowRightCircleIcon className="w-5 h-5" />
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="p-4 h-full flex items-center">
            <PropWarning>
              <div className="p-4 text-base flex flex-col gap-4">
                <div>{t('general.oops')}</div>
                <Trans i18nKey="fds.error-msg" t={t}>
                  Någonting verkar ha gått fel och just nu kan vi inte hämta fastighetsdatan.
                  <br /> Vi ber om ursäkt för besväret och försöker lösa problemet så snabbt vi kan!
                </Trans>
              </div>
            </PropWarning>
          </div>
        )}
      </div>
      <div className="mb-36"></div>
    </div>
  );
}
