import { Dialog } from '@headlessui/react';
import cls from 'classnames';
import Image from 'next/image';
import { Trans, useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { useRef } from 'react';
import { Error, Scooter, Success } from 'src/animations';

import { archiveList } from '@/src/lib/user-client-wrapper';

import { crmDialogTheme } from './dialog-theme';

const stepNames = {
  initial: 1,
  loading: 2,
  error: 3,
  done: 4
};

export function ListArchivalStep1({
  setStep,
  setReload,
  close,
  boardId,
  listId,
  listName
}: {
  setStep: any;
  setReload: any;
  close: any;
  boardId: string;
  listId: string;
  listName: string;
}) {
  const [inputValue, setInputValue] = useState<string>('');
  const [inputTouched, setInputTouched] = useState<boolean>(false);
  const [inputValid, setInputValid] = useState<boolean>(false);
  const [inputFocusedInitially, setInputFocusedInitially] = useState<boolean>(false);

  const inputRef: any = useRef(null);
  const { t } = useTranslation('common');

  useEffect(() => {
    if (inputRef?.current && !inputFocusedInitially) {
      inputRef?.current?.focus();
      setInputFocusedInitially(true);
    }
  }, [inputFocusedInitially]);

  function handleSubmit() {
    setInputTouched(true);

    if (inputValid) {
      setReload(true);
      setStep(stepNames.loading);
      archiveList(listId + '', boardId + '')
        .then((res: any) => {
          if (res?.ok === 200) {
            setStep(stepNames.done);
          } else {
            setStep(stepNames.error);
          }
        })
        .catch(() => {
          setStep(stepNames.error);
        });
    }
  }

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Image
          alt="main illustration"
          src={`/images/apartment-rate-increment.svg`}
          height={200}
          width={200}
        />
      </div>

      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          <Trans key="crm.modals.archive-list-title" t={t} listName={listName}>
            Är du säker att du vill arkivera listan{' '}
            <span className="text-bobo-prop dark:text-dark-prop">{{ listName }}</span>?
          </Trans>
        </Dialog.Title>

        <div className={crmDialogTheme.inputContainerArchive}>
          <span className={crmDialogTheme.inputArchiveLabel}>
            <Trans i18nKey="crm.modals.archive-list-prompt" t={t} listName={listName}>
              Skriv in{' '}
              <span className="font-demi text-bobo-black dark:text-white">{{ listName }}</span>{' '}
              (exakt) i rutan till höger för att bekräfta arkivering.
            </Trans>
          </span>

          <input
            ref={inputRef}
            className={cls(crmDialogTheme.inputArchive, {
              'border-bobo-burgundy': !inputValid,
              'bg-red-200 focus:bg-card-graypanel': !inputValid && inputTouched
            })}
            value={inputValue}
            onPaste={() => {
              setInputTouched(true);
            }}
            onChange={(event) => {
              setInputValue(event.target.value);
              setInputValid(event.target.value.trim() == listName);
            }}
            onBlur={() => {
              setInputTouched(true);
            }}
            onKeyDown={(event) => {
              setInputTouched(true);
              if (event.keyCode === 13) {
                // pressed enter
                handleSubmit();
              }
            }}
          />
        </div>
      </div>

      <div className={crmDialogTheme.buttonContainer}>
        <button className={crmDialogTheme.buttonClose} onClick={close}>
          {t('general.cancel')}
        </button>

        <div>
          <button
            className={crmDialogTheme.buttonArchive}
            disabled={!inputTouched || !inputValid}
            onClick={() => {
              handleSubmit();
            }}>
            {t('crm.user-actions.archive')}
          </button>
        </div>
      </div>
    </div>
  );
}

function ListArchivalStepLoading() {
  const { t } = useTranslation('common');
  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Scooter />
      </div>

      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          {t('crm.modals.archive-list-loading')}
        </Dialog.Title>

        <p className={crmDialogTheme.subtext}>{t('fun-facts.swe-smallest-property')}</p>
      </div>
    </div>
  );
}

function ListArchivalStepError({ close }: { close: any }) {
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.icon}>
        <Error />
      </div>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.errorTitle}>
          {t('errors.something-went-wrong')}
        </Dialog.Title>
      </div>
      <div className={crmDialogTheme.buttonContainer}>
        <button
          className={crmDialogTheme.buttonClose}
          onClick={() => {
            close();
          }}>
          {t('general.cancel')}
        </button>
      </div>
    </div>
  );
}

function ListArchivalStepDone({ close, listName }: { close: any; listName: string }) {
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.icon}>
        <Success />
      </div>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          <Trans key="crm.modals.archive-list-successful" listName={listName}>
            Listan <span className="text-bobo-prop dark:text-dark-prop">{{ listName }}</span> har
            arkiverats.
          </Trans>
        </Dialog.Title>
      </div>
      <div className={crmDialogTheme.buttonContainer}>
        <button
          className={crmDialogTheme.buttonClose}
          onClick={() => {
            close();
          }}>
          {t('crm.modals.done')}
        </button>
      </div>
    </div>
  );
}

export default function CrmListDialogArchive({
  initStep = 1,
  setReload,
  close,
  object
}: {
  initStep?: number;
  setReload: any;
  close: any;
  object: any;
}) {
  const [step, setStep] = useState(initStep);

  function closeAndNotify() {
    close();
  }

  let mod: JSX.Element = <div />;

  switch (step) {
    case stepNames.initial:
      mod = (
        <ListArchivalStep1
          setStep={setStep}
          close={close}
          boardId={object?.boardId}
          listId={object.listId}
          listName={object?.listName}
          setReload={setReload}
        />
      );
      break;

    case stepNames.loading:
      mod = <ListArchivalStepLoading />;
      break;

    case stepNames.error:
      mod = <ListArchivalStepError close={closeAndNotify} />;
      break;

    case stepNames.done:
      mod = <ListArchivalStepDone close={closeAndNotify} listName={object?.listName} />;
      break;
  }

  return <>{mod}</>;
}
