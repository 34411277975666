export const helpTextIds: any = {
  valuationToolDesc: '229751',
  valuationToolVacancy: '229750',
  valuationToolRent: '229749',
  valuationToolTax: '229748',
  valuationToolYield: '229747',
  valuationToolCosts: '229746',
  riskFire: '229745',
  riskLandslide: '229744',
  riskFloodingSkyfall: '229743',
  riskFloodingRiver: '229742',
  riskStorm: '229741',
  riskHeat: '229740',
  riskDraught: '229739',
  riskPolution: '229738',
  riskHistoricSites: '229737',
  riskIndexScore: '229736',
  riskNatura2000: '229735',
  riskNatureCareArea: '229734',
  riskWaterProtectionArea: '229732',
  riskWaterPresence: '229731',
  riskWetlands: '229730',
  riskNatureReserve: '229729',
  riskBioArea: '229728',
  riskNationalPark: '229727',
  riskInterimBan: '229726',
  riskDewatering: '229725',
  riskVulnerableArea: '229724',
  riskPopulationTrend: '229723',
  rentalMapOverview: '229721',
  rentalMapBiggestOwner: '229720',
  rentalMapRentLevel: '229719',
  rentalMapResidential: '229718',
  rentalMapRentIndexed: '229717',
  rentalMapSpaces: '229716',
  rentalMapRentAvg: '229715',
  rentalMapWarehouse: '229714',
  rentalMapAgreements: '229713',
  rentalMapPropTypeDist: '229712',
  rentAvgResidential: '229695',
  rentIndexed91: '229694',
  rentIndexed78: '229693',
  rentIndexed12: '229692',
  rentInvestmentSupport: '229691',
  rentInterval: '229690',
  rentIndexedPerProperty: '229689',
  rentValueYearArea: '229688',
  rentApartmentCount: '229687',
  rentAvgSpaces: '229674',
  rentAvgWarehouse: '229635',
  rentLoa: '229673',
  rentTaxedPlotArea: '229672',
  rentLandLease: '229671',
  rentEmployeeCount: '229670',
  rentTenantCount: '229669',
  rentTenantsAB: '229668',
  rentTenantsOther: '229659',
  rentTenantsTradingCo: '229658',
  rentPointCalculation: '229667',
  rentRentalChanges: '229666',
  rentBrokeredApartments: '229665',
  rentTenantsData: '229639',
  rentResidentialData: '229638',
  rentSpacesData: '229636',
  rentWarehouseData: '229635',
  rentAagreements: '229634',
  demographicsDesc: '229686',
  demographicsDispIncomeArea: '229685',
  demographicsDispIncomeTrend: '229684',
  demographicsApartmentPricesArea: '229683',
  demographicsApartmentPricesLc: '229682',
  demographicsPopulationArea: '229681',
  demographicsAgeArea: '229680',
  demographicsAgeTrend: '229678',
  demographicsHousingType: '229677',
  demographicsBuidingPermits: '229676',
  demographicsPopulationTrend: '229662',
  marketDataNewsec: '229650',
  easementActNr: '229661',
  easementDesc: '229660',
  propertyTaxation: '229675',
  propertyPowerLines: '229664',
  propertyBoa: '229656',
  propertyApartmentCount: '229655',
  propertyPropTypes: '229651',
  propertyEnvCerts: '229645',
  propertyEnergyDeclarations: '229644',
  propertyFds: '229631',
  updatesGeneral: '229648',
  updatesCorpStructure: '229647',
  infoDataExport: '229646',
  infoDataSecurity: '229641',
  infoPrices: '229640',
  infoLogin: '229632',
  infoFindProperties: '229431',
  infoIntroGuide: '229374',
  infoValuationTool: '229642',
  infoDemographics: '229643',
  crmAddProp: '229442',
  crmDesc: '229363',
  crmCreateBoard: '229319',
  advancedRegsoDraw: '229388',
  advancedTenants: '229378',
  Naturvårdsverket: '236916',
  Lantmäteriet: '236919',
  'Lantmäteriet via UC': '246423',
  registeredTransactions: '239207',
  Newsec: '229650',
  cashflowValuation: '265267',
  cashflowNOI: '265270',
  cashflowYield: '265271',
  cashflowDiscountFactor: '265272',
  cashflowAssetType: '265273',
  cashflowLease: '265274',
  cashflowVacancy: '265275',
  cashflowLongTermYield: '265276',
  cashflowModelSettings: '265277',
  cashflowUnlevered: '265278',
  cashflowRent: '272278',
  cashflowMaintenance: '272279',
  cashflowLoan: '272280',
  cashflowModelOverview: '274403',
  cashflowModel: '274404',
  cashflowCalculatorLeases: '274456',
  cashflowRentIndex: '275422',
  cashflowCostIndex: '275423',
  cashflowInvestments: '275424',
  cashflowCredits: '275425'
};
