import { GotoFeature } from 'shared/dist/types';

export default function plansBasic(map: any, setSlideIn?: any) {
  let hoveredStateId: string | null = null;

  !map?.getMap()?.getLayer('plan-line') &&
    map?.getMap()?.addLayer({
      id: 'plan-line',
      type: 'line',
      source: 'plans', // reference the data source
      'source-layer': 'plan_yta', // reference the data source
      layout: {},
      paint: {
        'line-color': 'rgba(146, 42, 91, 0.9)',
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'selected'], false],
          2,
          ['boolean', ['feature-state', 'hover'], false],
          2,
          2
        ],
        'line-dasharray': [2, 2],
        'line-opacity': [
          'case',
          ['boolean', ['feature-state', 'selected'], false],
          1,
          ['boolean', ['feature-state', 'hover'], false],
          1,
          1
        ]
      }
    });

  !map?.getMap()?.getLayer('plan-area') &&
    map?.getMap()?.addLayer({
      id: 'plan-area',
      type: 'fill',
      source: 'plans', // reference the data source
      'source-layer': 'plan_yta', // reference the data source
      layout: {},
      paint: {
        'fill-color': 'rgba(146, 42, 91, 0.4)',
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'selected'], false],
          0.5,
          ['boolean', ['feature-state', 'hover'], false],
          0.3,
          0.1
        ]
      }
    });

  map?.getMap()?.on('mousemove', 'plan-area', (e) => {
    const idx = 0;
    if (e.features.length > 0 && e.features[idx].properties?.objekt_id !== hoveredStateId) {
      map.getMap().getCanvas().style.cursor = 'pointer';
      if (hoveredStateId !== null) {
        // const tmp: any = e.features[idx]?.properties;
        // tmp.title = `${e.features[idx]?.properties?.authority_nickname}`?.toLowerCase();
        // const c: any = centroid(e.features[idx]?.geometry);
        // tmp.lng = c?.geometry?.coordinates[0];
        // tmp.lat = c?.geometry?.coordinates[1];

        map?.getMap()?.setFeatureState(
          {
            source: 'plans',
            id: hoveredStateId,
            sourceLayer: 'plan_yta'
          },
          { hover: false }
        );
      }
      hoveredStateId = e.features[idx]?.properties?.objekt_id;

      map?.getMap()?.setFeatureState(
        {
          source: 'plans',
          id: hoveredStateId,
          sourceLayer: 'plan_yta'
        },
        { hover: true }
      );
    }
  });

  map?.getMap()?.on('mouseleave', 'plan-area', () => {
    map.getMap().getCanvas().style.cursor = 'default';
    map?.getMap()?.setFeatureState(
      {
        source: 'plans',
        id: hoveredStateId,
        sourceLayer: 'plan_yta'
      },
      { hover: false }
    );
    hoveredStateId = null;
  });

  const planAreaClick = (e) => {
    map?.getMap()?.removeFeatureState({
      source: 'plans',
      sourceLayer: 'plan_yta'
    });

    const details = e?.features?.map((a) => {
      map?.getMap()?.setFeatureState(
        {
          source: 'plans',
          id: a?.properties?.objekt_id,
          sourceLayer: 'plan_yta'
        },
        { selected: true }
      );
      return a;
    });

    setSlideIn({
      object: {
        details
      },
      type: GotoFeature.DETAIL_PLAN
    });
  };

  map?.getMap()?.on('click', 'plan-area', planAreaClick);
  map?.getMap()?.on('touchend', 'plan-area', planAreaClick);
}
