import cls from 'classnames';
import { useTranslation } from 'next-i18next';
import { EnvironmentalSummary } from 'shared/dist/types/risk';

export default function RiskEnvironmentalScore({
  environmental_summary
}: {
  environmental_summary: EnvironmentalSummary | undefined;
}) {
  const { t } = useTranslation('common');

  return (
    <div className="pt-2">
      <div className="flex items-center">
        <div className="py-2 flex flex-col justify-between items-start w-full flex-1 mt-5">
          <div className="w-full relative">
            <div
              style={{ left: `${environmental_summary?.score}%` }}
              className={cls('absolute -top-5')}>
              <div className="border-l border-dashed border-black dark:border-bobo-orange pb-11 relative">
                <div className="border-black text-black dark:text-white text-xs font-normal text-left absolute -top-1 left-1 w-[90px]">
                  {t('risk.risk-index')}: {Math.round(environmental_summary?.score ?? 0)}
                </div>
              </div>
            </div>
            <div className="bg-rating w-full h-6 rounded-full grid grid-cols-5">
              <div className="h-8 border-r border-white dark:border-dark-faded" />
              <div className="h-8 border-r border-white dark:border-dark-faded" />
              <div className="h-8 border-r border-white dark:border-dark-faded" />
              <div className="h-8 border-r border-white dark:border-dark-faded" />
              <div className="h-8 border-r border-white dark:border-dark-faded" />
            </div>
            <div className="w-full h-8 rounded-full grid grid-cols-5 text-[11px] font-medium">
              <div className="h-8 text-center leading-[12px] mt-1">
                {/*<div>Låg</div>*/}
                {/* eslint-disable-next-line i18next/no-literal-string*/}
                <div>0 - 24</div>
              </div>
              <div className="h-8 text-center"></div>
              <div className="h-8 text-center leading-[12px] mt-1">
                {/*<div>Medium</div>*/}
                {/* eslint-disable-next-line i18next/no-literal-string*/}
                <div>25 - 60</div>
              </div>
              <div className="h-8 text-center"></div>
              <div className="h-8 text-center leading-[12px] mt-1">
                {/*<div>Hög</div>*/}
                {/* eslint-disable-next-line i18next/no-literal-string*/}
                <div>61 - 100</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
