/* External Libraries */
import {
  BanknotesIcon,
  BuildingOfficeIcon,
  LockClosedIcon,
  StarIcon
} from '@heroicons/react/24/solid';
import cls from 'classnames';
import { useTranslation } from 'next-i18next';
import { TAX_TYPE_SHORT } from 'shared/dist/constants';
import { capFirstLetter } from 'shared/dist/util/string';

import { getColorForObjectType } from '@/src/lib/colors';

export function PropLabels({
  object,
  small = true,
  isTooltip = false,
  mapView = false,
  compact = false
}: {
  object: any;
  small?: boolean;
  isTooltip?: boolean;
  mapView?: boolean;
  compact?: boolean;
}) {
  let specialTax = 0;
  const labels: JSX.Element[] = [];
  const { t } = useTranslation('common');

  object?.taxCodes?.sort().map((tc, idx) => {
    const { color, bgColor } = getColorForObjectType(tc ?? 0);

    if (tc > 799 && tc !== 800) {
      specialTax++;
    }

    labels.push(
      <div
        style={{
          backgroundColor: bgColor
        }}
        key={idx}
        className={cls(
          `left text-bobo-black dark:text-white dark:bg-opacity-0 font-medium rounded inline-flex items-center space-x-1`,
          { 'text-[9px] leading-3 py-[2px] px-1': small },
          { 'text-[11px] leading-3.2 py-1 px-2': !small },
          { 'has-tooltip relative': compact }
        )}>
        <div className="w-2 h-2 rounded-full" style={{ backgroundColor: color }} />
        {!compact ? (
          <div>
            {capFirstLetter(t(TAX_TYPE_SHORT[tc]))} [{tc}]
          </div>
        ) : (
          <div
            className="tooltip px-2 py-1 rounded top-5 right-0 font-demi text-white"
            style={{
              backgroundColor: color
            }}>
            {capFirstLetter(t(TAX_TYPE_SHORT[tc]))} [{tc}]
          </div>
        )}
      </div>
    );
  });
  {
    object?.samTaxCount > 0 &&
      labels.push(
        <div
          key={`samTaxCount`}
          className={cls(
            `inline-flex rounded-md items-center bg-bobo-councillight dark:bg-bobo-councildim mr-1`,
            { 'text-[9px] leading-3 py-[2px] px-1': small },
            { 'text-[11px] leading-3.2 py-1 px-2': !small },
            { 'has-tooltip relative': compact }
          )}>
          <div className="h-2 w-2 mr-1 bg-bobo-council rounded-full" />{' '}
          {!compact ? (
            <span className="dark:text-white">{t('prop-labels.collectively-taxed')}</span>
          ) : (
            <span className="tooltip bg-bobo-council px-2 py-1 rounded top-5 right-0 font-demi text-white">
              {t('prop-labels.collectively-taxed')}
            </span>
          )}
        </div>
      );
  }
  {
    object?.erodingSpace &&
      labels.push(
        <div
          key={`erodingSpace`}
          className={cls(
            `inline-flex rounded-md items-center bg-bobo-councillight dark:bg-bobo-councildim mr-1 dark:text-white`,
            { 'text-[9px] leading-3 py-[2px] px-1': small },
            { 'text-[11px] leading-3.2 py-1 px-2': !small },
            { 'has-tooltip relative': compact }
          )}>
          <div className="h-2 w-2 mr-1 bg-bobo-council rounded-full" />{' '}
          {!compact ? (
            <span>{t('prop-labels.eroding-space')}</span>
          ) : (
            <span className="tooltip bg-bobo-council px-2 py-1 rounded top-5 right-0 font-demi text-white">
              {t('prop-labels.eroding-space')}
            </span>
          )}
        </div>
      );
  }
  {
    object?.erodedBySpace &&
      labels.push(
        <div
          key={`erodedBySpace`}
          className={cls(
            `inline-flex rounded-md items-center bg-bobo-councillight dark:bg-bobo-councildim mr-1 dark:text-white`,
            { 'text-[9px] leading-3 py-[2px] px-1': small },
            { 'text-[11px] leading-3.2 py-1 px-2': !small },
            { 'has-tooltip relative': compact }
          )}>
          <div className="h-2 w-2 mr-1 bg-bobo-council rounded-full" />
          {!compact ? (
            <span>{t('prop-labels.hollowed-out-by-eroding-properties')}</span>
          ) : (
            <span className="tooltip bg-bobo-council px-2 py-1 rounded top-5 right-0 font-demi text-white">
              {t('prop-labels.hollowed-out-by-eroding-properties')}
            </span>
          )}
        </div>
      );
  }
  {
    specialTax > 0 &&
      labels.push(
        <div
          key={`specialTax`}
          className={cls(
            `inline-flex rounded-md items-center bg-bobo-councillight dark:bg-bobo-councildim mr-1 mb-0 dark:text-white`,
            { 'text-[9px] leading-3 py-[2px] px-1': small },
            { 'text-[11px] leading-3.2 py-1 px-2': !small },
            { 'has-tooltip relative': compact }
          )}>
          <div className="h-2 w-2 mr-1 bg-bobo-councilred rounded-full" />
          {!compact ? (
            <span>{t('prop-labels.special-tax')}</span>
          ) : (
            <span className="tooltip bg-bobo-councilred px-2 py-1 rounded top-5 right-0 font-demi text-white">
              {t('prop-labels.special-tax')}
            </span>
          )}
        </div>
      );
  }

  if (!object?.taxCodes || object?.taxCodes?.length < 1) {
    labels.push(
      <div
        key={`specialTax`}
        className={cls(
          `inline-flex rounded-md items-center bg-bobo-councillight mr-1 dark:text-bobo-black`,
          { 'text-[9px] leading-3 py-[2px] px-1': small },
          { 'text-[11px] leading-3.2 py-1 px-2': !small },
          { 'has-tooltip relative': compact }
        )}>
        <div className="h-2 w-2 mr-1 bg-bobo-council rounded-full" /> {t('general.one-moment')}
      </div>
    );
  }

  if (object?.actual_tenants_b > 0) {
    labels.push(
      <div
        key={`housingRecords`}
        className={cls(
          `inline-flex rounded-md items-center bg-[#538be7] bg-opacity-20 mr-1 dark:text-white`,
          { 'text-[9px] leading-3 py-[2px] px-1': small },
          { 'text-[11px] leading-3.2 py-1 px-2': !small },
          { 'has-tooltip relative': compact }
        )}>
        <StarIcon className="h-3 w-3 mr-1 text-[#538be7] rounded-full" />
        {!compact ? (
          <span>
            {t('prop-labels.housing-records', { actualTenantsB: object?.actual_tenants_b })}
          </span>
        ) : (
          <span className="tooltip bg-[#538be7] px-2 py-1 rounded top-5 right-0 font-demi text-white">
            {t('prop-labels.housing-records', { actualTenantsB: object?.actual_tenants_b })}
          </span>
        )}
      </div>
    );
  }

  if (object?.actual_tenants_l > 0) {
    labels.push(
      <div
        key={`spaceRecords`}
        className={cls(
          `inline-flex rounded-md items-center bg-bobo-purpleiconbg mr-1 dark:text-white`,
          { 'text-[9px] leading-3 py-[2px] px-1': small },
          { 'text-[11px] leading-3.2 py-1 px-2': !small },
          { 'has-tooltip relative': compact }
        )}>
        <StarIcon className="h-3 w-3 mr-1 text-bobo-purple rounded-full" />
        {t('prop-labels.housing-records', { actualTenantsB: object?.actual_tenants_b })}
        {t('prop-labels.space-records', { actualTenantsL: object?.actual_tenants_l })}
      </div>
    );
  }

  if (object?.presumption_rent) {
    labels.push(
      <div
        key={`presumptionRecords`}
        className={cls(
          `inline-flex rounded-md items-center bg-bobo-counciliconbg bg-opacity-20 mr-1 dark:text-white`,
          { 'text-[9px] leading-3 py-[2px] px-1': small },
          { 'text-[11px] leading-3.2 py-1 px-2': !small },
          { 'has-tooltip relative': compact }
        )}>
        <LockClosedIcon className="h-3 w-3 mr-1 text-bobo-council rounded-full" />
        {t('prop-labels.presumption-rent')}
      </div>
    );
  }

  if (object?.owners?.find((a) => a?.actor_id?.startsWith('7'))) {
    labels.push(
      <div
        key={`presumptionRecords`}
        className={cls(
          `inline-flex rounded-md items-center bg-[#cb6f6c] bg-opacity-20 mr-1 dark:text-white`,
          { 'text-[9px] leading-3 py-[2px] px-1': small },
          { 'text-[11px] leading-3.2 py-1 px-2': !small },
          { 'has-tooltip relative': compact }
        )}>
        <BuildingOfficeIcon className="h-3 w-3 mr-1 text-bobo-council rounded-full" />
        {!compact ? (
          <span>{t('prop-labels.BRF-housing-association')}</span>
        ) : (
          <span className="tooltip bg-bobo-council px-2 py-1 rounded top-5 right-0 font-demi text-white">
            {t('prop-labels.BRF-housing-association')}
          </span>
        )}
      </div>
    );
  }

  if (object?.investment_support) {
    labels.push(
      <div
        key={`spaceRecords`}
        className={cls(
          `inline-flex rounded-md items-center bg-bobo-propiconbg bg-opacity-20 mr-1 dark:text-white`,
          { 'text-[9px] leading-3 py-[2px] px-1': small },
          { 'text-[11px] leading-3.2 py-1 px-2': !small },
          { 'has-tooltip relative': compact }
        )}>
        <BanknotesIcon className="h-3 w-3 mr-1 text-bobo-prop dark:text-dark-prop rounded-full" />
        {small ? (
          <div>{t('prop-labels.investment-support')}</div>
        ) : (
          <div>{t('prop-labels.recieved-inv-support')}</div>
        )}
      </div>
    );
  }

  if (object?.has_actual_rents) {
    labels.push(
      <div
        key={`housingRecords`}
        className={cls(
          `inline-flex rounded-md items-center bg-[#538be7] bg-opacity-20  dark:text-white`,
          { 'text-[9px] leading-3 py-[2px] px-1': small },
          { 'text-[11px] leading-3.2 py-1 px-2': !small },
          { 'has-tooltip relative': compact }
        )}>
        <StarIcon className="h-3 w-3 mr-1 text-[#538be7] rounded-full" />
        {t('prop-labels.has-actual-rents')}
      </div>
    );
  }

  return (
    <div
      className={cls(
        isTooltip ? 'flex-col' : '',
        'flex flex-wrap my-1 gap-1 justify-start whitespace-nowrap'
      )}>
      {labels?.slice(isTooltip ? (mapView ? 2 : 1) : 0, 20)}
    </div>
  );
}

export function LabelWrapper({
  children,
  color,
  bgColor,
  icon = false
}: {
  children: any;
  color?: string;
  bgColor?: string;
  icon?: boolean;
}) {
  return (
    <div
      style={{
        backgroundColor: bgColor ?? ''
      }}
      className={cls(
        'text-[9px] leading-3 py-[2px] px-1 text-bobo-black dark:text-white dark:bg-opacity-0 font-medium rounded inline-flex items-center gap-1 whitespace-nowrap max-w-full overflow-clip',
        { 'bg-bobo-councillight dark:bg-dark-lessfaded': !bgColor }
      )}>
      {!icon ? (
        <div
          className={cls('w-2 h-2 rounded-full', { 'bg-bobo-council': !color })}
          style={{ backgroundColor: color ?? '' }}
        />
      ) : null}
      <div className="flex gap-1">{children}</div>
    </div>
  );
}

export function PropLabelsSingle({
  object,
  mapView,
  className,
  short = false
}: {
  object: any;
  mapView?: boolean;
  className?: string;
  short?: boolean;
}) {
  let specialTax = 0;
  let labels: JSX.Element[] = [];

  const { t } = useTranslation('common');

  const small = true;
  object?.taxCodes?.sort().map((tc, idx) => {
    const { color, bgColor } = getColorForObjectType(tc ?? 0);
    if (tc > 799 && tc !== 800) {
      specialTax++;
    }
    labels.push(
      <div key={idx} className="has-tooltip relative">
        <LabelWrapper color={color} bgColor={bgColor}>
          {!short ? `${capFirstLetter(t(TAX_TYPE_SHORT[tc]))} [` : ''}
          {tc}
          {!short ? ']' : ''}
        </LabelWrapper>

        {short ? (
          <div className="tooltip absolute top-6 text-xs py-1 px-2 bg-white dark:text-white dark:bg-dark-lessfaded text-bobo-black rounded-md shadow-md">
            <LabelWrapper color={color} bgColor={bgColor}>
              {capFirstLetter(t(TAX_TYPE_SHORT[tc]))} [{tc}]
            </LabelWrapper>
          </div>
        ) : null}
      </div>
    );
  });
  {
    object?.samTaxCount > 0 &&
      labels.push(
        <LabelWrapper key={'samTaxCount'}>{t('prop-labels.collectively-taxed')}</LabelWrapper>
      );
  }
  {
    object?.erodingSpace &&
      labels.push(
        <LabelWrapper key={`erodingSpace`}>{t('prop-labels.eroding-space')}</LabelWrapper>
      );
  }
  {
    object?.erodedBySpace &&
      labels.push(
        <LabelWrapper key={`erodedBySpace`}>
          {t('prop-labels.hollowed-out-by-eroding-properties')}
        </LabelWrapper>
      );
  }
  {
    specialTax > 0 &&
      labels.push(<LabelWrapper key={`specialTax`}>{t('prop-labels.special-tax')}</LabelWrapper>);
  }

  if (!object?.taxCodes || object?.taxCodes?.length < 1) {
    labels.push(
      <LabelWrapper key={`specialTax-2`}>{t('prop-labels.small-house-and-other')}</LabelWrapper>
    );
  }

  if (object?.actual_tenants_b > 0) {
    labels.push(
      <LabelWrapper key={`housingRecords`} bgColor={'#538be7'} icon>
        <StarIcon className="h-3 w-3 mr-1 text-[#538be7] rounded-full" />
        {t('prop-labels.housing-records', { actualTenantsB: object?.actual_tenants_b })}
      </LabelWrapper>
    );
  }

  if (object?.actual_tenants_l > 0) {
    labels.push(
      <LabelWrapper key={`spaceRecords`} icon bgColor="rgba(134, 40, 145, 0.2)">
        <StarIcon className="h-3 w-3 mr-1 text-bobo-purple rounded-full" />
        {t('prop-labels.space-records', { actualTenantsL: object?.actual_tenants_l })}
      </LabelWrapper>
    );
  }

  if (object?.presumption_rent) {
    labels.push(
      <LabelWrapper key={`presumptionRecords`} bgColor={'rgba(203, 111, 108, .2)'} icon>
        <LockClosedIcon className="h-3 w-3 mr-1 text-bobo-council rounded-full" />
        {t('prop-labels.presumption-rent')}
      </LabelWrapper>
    );
  }

  if (object?.owners?.find((a) => a?.actor_id?.startsWith('7'))) {
    labels.push(
      <LabelWrapper key={`presumptionRecords`} icon>
        <BuildingOfficeIcon className="h-3 w-3 mr-1 text-bobo-council rounded-full" />
        {t('prop-labels.BRF-housing-association')}
      </LabelWrapper>
    );
  }

  if (object?.investment_support) {
    labels.push(
      <LabelWrapper key={`spaceRecords`} icon bgColor="rgba(41, 145, 132, 0.2)">
        <BanknotesIcon className="h-3 w-3 mr-1 text-bobo-prop dark:text-dark-prop rounded-full" />
        {t('prop-labels.investment-support')}
      </LabelWrapper>
    );
  }

  if (object?.has_actual_rents) {
    labels.push(
      <LabelWrapper key={`housingRecords`} icon bgColor="#538be7">
        <StarIcon className="h-3 w-3 mr-1 text-[#538be7] rounded-full" />
        {t('prop-labels.has-actual-rents')}
      </LabelWrapper>
    );
  }

  if ((labels?.length > 1 && !mapView) || (labels?.length > 2 && mapView)) {
    const count = mapView ? labels?.length - 2 : labels?.length - 1;

    labels = mapView ? labels?.slice(0, 2) : labels?.slice(0, 1);

    labels.push(
      <div
        role="button"
        tabIndex={0}
        onKeyDown={(event) => {
          event.stopPropagation();
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        className={cls(
          `inline-flex text-white relative rounded-md has-tooltip items-center bg-bobo-orange bg-opacity-20 hover:bg-bobo-orangehover transition-colors duration-200`,
          { 'text-[9px] leading-3 py-[2px] px-1': small },
          { 'text-[11px] leading-3.2 py-1 px-2': !small }
        )}
        key={10}>
        <div className="h-5 w-full absolute top-0 left-0"></div>
        <div
          className={cls(
            'tooltip bg-white hover:text-bobo-black text-bobo-black px-2 flex items-center dark:bg-dark-lessfaded dark:border-dark-lessfaded shadow border-gray-100 border rounded-md',
            { 'top-5 right-1': mapView },
            { 'top-5': !mapView }
          )}>
          <PropLabels small={true} object={object} isTooltip={true} mapView={mapView} />
        </div>
        + {count}
      </div>
    );
  }

  return (
    <div
      className={cls(
        mapView ? 'justify-end' : 'justify-start',
        `flex flex-wrap my-1 gap-1 items-center ${className}`
      )}>
      {labels}
    </div>
  );
}

export function PropWarning({ children }: { children: JSX.Element | JSX.Element[] | string }) {
  return (
    <div className="m-0 my-2 mt-3 p-4 py-2 rounded-md border text-xs font-demi border-dashed border-red-400 text-gray-800 dark:text-gray-200 w-full">
      {children}
    </div>
  );
}
