import { ArrowDownRightIcon, ArrowRightIcon, ArrowUpRightIcon } from '@heroicons/react/24/solid';
import cls from 'classnames';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useTheme } from 'next-themes';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { getCategoryName } from 'shared/dist/constants';
import { getValueYearString } from 'shared/dist/util/rent';

import { useBreakpoint } from '@/src/hooks/use-breakpoint';

export default function NewsecYieldBox({
  object,
  iconOnly = false,
  close
}: {
  object: any;
  iconOnly?: boolean;
  close?: any;
}) {
  const [tableData, setTableData] = useState<any | null>(null);

  const { resolvedTheme } = useTheme();

  const { t } = useTranslation('common');

  const { isLg } = useBreakpoint({ breakpoint: 'lg' });
  const { is2xl } = useBreakpoint({ breakpoint: '2xl' });
  const isMobileOrTabletPortrait = !isLg;

  useEffect(() => {
    let tmpDataObject: any | null = null;

    if (object) {
      const data = object?.market_data?.newsec?.find((cat) => {
        return cat.is_primary;
      });

      if (data) {
        tmpDataObject = {
          rent: [+data?.rent_min, +data?.rent_max, +data?.rent_trend, +data?.rent_avg],
          yield: [+data?.yield_min, +data?.yield_max, +data?.yield_trend],
          category: t(getCategoryName(data?.property_type)),
          area: data?.area ?? t('general.entire-municipality'),
          valueYear: getValueYearString(data.value_year_interval, t)
        };
      } else {
        tmpDataObject = {
          noPrimaryCategory: true
        };
      }
    }
    setTableData(tmpDataObject);
  }, []);

  function TrendArrow({ trend }: { trend: number }) {
    return (
      <div
        className={cls(
          'rounded-full p-1 w-4 h-4',
          {
            'bg-bobo-council flex justify-center items-center': trend === -1
          },
          {
            'bg-bobo-prop dark:bg-dark-prop flex justify-center items-center': trend === 1
          },
          {
            'bg-gray-400 flex justify-center items-center': trend === 0 || !trend
          }
        )}>
        {trend === -1 ? <ArrowDownRightIcon className="h-3 w-3 text-white" /> : null}
        {trend === 1 ? <ArrowUpRightIcon className="h-3 w-3 text-white" /> : null}
        {trend === 0 || !trend ? <ArrowRightIcon className="h-3 w-3 text-white" /> : null}
      </div>
    );
  }

  const router = useRouter();

  if (isMobileOrTabletPortrait && !iconOnly) {
    return (
      <button
        onClick={() => {
          router.push(`/crm/fastighet/${object?.uuid}/mdat`);
          if (close) {
            close();
          }
        }}
        className="flex items-center px-3 justify-start space-x-2 rounded-md py-1 pt-2">
        <div className="p-0.5 pl-1 h-6 w-6 bg-[#526ef2] rounded-full"></div>

        <div className="p-1 text-xs font-demi text-left">
          {tableData && tableData.yield ? (
            <div>
              <span className="pr-1">{t('general.yield')}:</span>

              <NumberFormat displayType="text" value={tableData?.yield[0]} suffix={'%'} />
              {' - '}
              <NumberFormat displayType="text" value={tableData?.yield[1]} suffix={'%'} />

              <span className="pl-1">
                ({tableData?.category}, {tableData?.area})
              </span>
            </div>
          ) : (
            <div>
              <span className="text-left">
                {t('property.missing-data-yield', {
                  nickname: object?.authorityNickname?.replace(object?.county, '')?.toLowerCase()
                })}
              </span>
            </div>
          )}
        </div>
      </button>
    );
  }

  return process.env.PUBLIC_FEATURE_FORUM_MARKET_DATA
    ? tableData && (
        <button
          onClick={() => {
            router.push(`/crm/fastighet/${object?.uuid}/mdat`);
            if (close) {
              close();
            }
          }}
          className={cls(
            `h-auto flex flex-col justify-start p-4 transition-colors duration-300 rounded-md border border-gray-200 dark:border-dark-morefaded hover:bg-bobo-proplight dark:hover:bg-dark-prop ${
              iconOnly
                ? 'shadow bg-white dark:bg-dark-lessfaded w-full'
                : '2xl:h-[125px] bg-gray-50 dark:bg-dark-lessfaded'
            }`
          )}>
          <div className="grid grid-cols-5 w-full">
            <div className="col-span-1"></div>
            <div className="col-span-2 flex items-center justify-end gap-2 text-[12px] font-medium text-right">
              <Image
                className="object-contain"
                src={
                  resolvedTheme !== 'dark'
                    ? '/images/Newsec_Logo_Two_Colored_RGB.svg'
                    : '/images/Newsec_Logo_Beyond_Beige_RGB.svg'
                }
                alt="Newsec logo"
                height={45}
                width={55}
                quality={100}
                unoptimized
              />
            </div>
            <div className="col-span-2 flex items-center justify-end gap-2 text-[12px] font-medium text-right">
              <Image
                className="object-contain"
                src={resolvedTheme !== 'dark' ? '/images/logo-forum.png' : '/images/logo-forum.png'}
                alt="Forum logo"
                height={25}
                width={15}
                quality={100}
                unoptimized
              />
              <div>Forum</div>
            </div>
          </div>
          {!iconOnly && (
            <div className="grid grid-cols-5 w-full font-medium text-xs mt-2 even:bg-gray-100 p-1">
              <div className="col-span-1 text-right">{t('itismad.table.name')}</div>
              <div className="col-span-2 text-right font-normal">
                {tableData?.category}, {tableData?.area}
              </div>
              <div className="col-span-2 text-right font-normal">
                {tableData?.category}, {tableData?.area}
              </div>
            </div>
          )}
          <div className="grid grid-cols-5 w-full font-medium text-xs even:bg-gray-100 p-1">
            <div className="col-span-1 text-right">{t('general.yield')}</div>
            <div className="col-span-2 text-right font-normal">
              <NumberFormat displayType="text" value={tableData?.yield[0]} suffix={'%'} />
              {'-'}
              <NumberFormat displayType="text" value={tableData?.yield[1]} suffix={'%'} />
            </div>
            <div className="col-span-2 text-right font-normal">
              <NumberFormat displayType="text" value={tableData?.yield[0]} suffix={'%'} />
              {'-'}
              <NumberFormat displayType="text" value={tableData?.yield[1]} suffix={'%'} />
            </div>
          </div>
          <div className="grid grid-cols-5 w-full font-medium text-xs even:bg-gray-100 p-1">
            <div className="col-span-1 text-right">{t('property.rent-avg-cap')}</div>
            <div className="col-span-2 text-right font-normal">
              <NumberFormat
                decimalScale={0}
                fixedDecimalScale={true}
                displayType="text"
                value={tableData?.rent[3]}
                suffix={' kr'}
              />
            </div>
            <div className="col-span-2 text-right font-normal">
              <NumberFormat
                decimalScale={0}
                fixedDecimalScale={true}
                displayType="text"
                value={tableData?.rent[3]}
                suffix={' kr'}
              />
            </div>
          </div>
        </button>
      )
    : tableData && (
        <button
          onClick={() => {
            router.push(`/crm/fastighet/${object?.uuid}/mdat`);
            if (close) {
              close();
            }
          }}
          className={cls(
            `flex justify-between overflow-hidden items-center p-3 h-full transition-colors duration-300 rounded-md border border-gray-200 dark:border-dark-morefaded hover:bg-bobo-proplight dark:hover:bg-dark-prop ${
              iconOnly
                ? 'bg-gray-50 dark:bg-dark-lessfaded w-full'
                : '2xl:h-[105px] bg-gray-50 dark:bg-dark-lessfaded'
            }`
          )}>
          <div
            className={cls(
              'p-2 items-center flex lg:hidden 2xl:flex justify-center bg-transparent',
              {
                'hidden 2xl:flex': !iconOnly,
                flex: iconOnly
              }
            )}>
            <Image
              className="object-contain"
              src={
                resolvedTheme !== 'dark'
                  ? '/images/Newsec_Logo_Two_Colored_RGB.svg'
                  : '/images/Newsec_Logo_Beyond_Beige_RGB.svg'
              }
              alt="Newsec logo"
              height={is2xl || isMobileOrTabletPortrait ? 60 : 45}
              width={is2xl || isMobileOrTabletPortrait ? 60 : 45}
              quality={100}
              unoptimized
            />
          </div>

          <div
            className={cls(
              `flex text-right items-end flex-col justify-start h-full flex-1 font-normal text-gray-800 dark:text-gray-200`
            )}>
            <div className="font-demi mb-2 text-smaller dark:text-white">
              {t('general.yield')} {!is2xl ? `(${t('general.newsec')})` : ''}
            </div>
            {tableData?.noPrimaryCategory ? (
              <div className="text-mini leading-4">
                {t('property.missing-data-yield', { nickname: object?.authorityNickname })}
                {iconOnly ? '' : t('property.missing-data-newsec-see-table')}
              </div>
            ) : (
              <>
                <div
                  className={cls(
                    `flex gap-0 items-center justify-end lg:justify-start 2xl:justify-end text-xs ${
                      iconOnly ? 'text-[10px] leading-[11px]' : ''
                    }`
                  )}>
                  <div>
                    <NumberFormat displayType="text" value={tableData?.yield[0]} suffix={'%'} />
                    {' - '}
                    <NumberFormat displayType="text" value={tableData?.yield[1]} suffix={'%'} />
                  </div>

                  <div className="hidden">
                    <TrendArrow trend={tableData?.yield[2]} />
                  </div>
                </div>

                <div className="text-xs">
                  {tableData?.category}, {tableData?.area}
                </div>

                <div className="text-xs">{tableData?.valueYear}</div>
              </>
            )}
          </div>
        </button>
      );
}
