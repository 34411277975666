import moment from 'moment';
import { useTranslation } from 'next-i18next';

import InfoButton from './info-button';

export default function SectionHeader({
  title,
  sources,
  updatedAt,
  helpTextId
}: {
  title: string | React.JSX.Element;
  sources?: string;
  updatedAt?: number;
  helpTextId?: string;
}) {
  const { t } = useTranslation('common');

  return (
    <div className="w-full mb-2">
      <div className="w-full flex justify-between items-center border-b mt-2 border-gray-200 dark:border-dark-morefaded pb-2">
        <div className="font-demi text-sm lg:text-base dark:text-bobo-orange">{title}</div>

        {helpTextId ? <InfoButton helpTextId={helpTextId} /> : null}
      </div>

      {sources || updatedAt ? (
        <div className="w-full flex items-center justify-between mt-1">
          <div className="text-gray-500 dark:text-gray-400 text-mini italic">
            {sources ? <>{t('general.sources', { sources: sources })}</> : null}
          </div>

          <div className="text-gray-500 dark:text-gray-400 text-mini italic">
            {updatedAt ? (
              <>
                {t('general.updated-at', { date: moment(updatedAt * 1000).format('YYYY-MM-DD') })}
              </>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
}
