import { ShareIcon } from '@heroicons/react/24/outline';
import cls from 'classnames';
import Image from 'next/image';
import { Trans, useTranslation } from 'next-i18next';
import NumberFormat from 'react-number-format';
import { GotoFeature } from 'shared/dist/types';

import { useSlideIn } from '../context/slide-in-context';

export function OrgNumber({ actorId, country = 'SE' }: { actorId: string; country?: string }) {
  const isPersonalNumber: boolean = actorId?.length === 12;

  const orgNumber: string = isPersonalNumber ? actorId?.slice(0, 8) : actorId;

  switch (country) {
    case 'SE':
    default:
      return (
        <span className="whitespace-nowrap">
          <NumberFormat
            displayType="text"
            format={isPersonalNumber ? '########-####' : '######-####'}
            value={orgNumber}
            allowEmptyFormatting
            mask={isPersonalNumber ? 'x' : '_'}
          />
        </span>
      );
  }
}

export function ActorOrgStructureText({
  activeNode,
  rootNode
}: {
  activeNode: any;
  rootNode: any;
  country?: string;
}) {
  const { t } = useTranslation('common');

  return (
    <div className="pb-4">
      {activeNode?.child_actors?.length > 0 ? (
        <span className="mr-1">
          <Trans
            i18nKey="owner.child-actors"
            name={activeNode?.name}
            count={activeNode?.child_actors?.length}>
            <strong>{{ name: activeNode?.name }}</strong> har{' '}
            {{ count: activeNode?.child_actors?.length }} dotterbolag.
          </Trans>
        </span>
      ) : null}
      {rootNode?.child_actors?.length > 0 ? (
        <span className="mr-1">
          <Trans
            i18nKey="owner.root-actor-info"
            count={activeNode?.child_actors?.length ?? 0}
            rootActor={rootNode?.name ?? ''}>
            Bolaget ingår i en koncern med
            <strong> {{ count: activeNode?.child_actors?.length ?? 0 }} bolag</strong> där
            koncernmoderbolaget är<strong> {{ rootActor: rootNode?.name ?? '' }}</strong>
          </Trans>
          {rootNode?.country_code === 'SE' || !rootNode?.country_code ? (
            <span>
              {t('owner.with-org-nr')}
              <strong>
                <OrgNumber actorId={rootNode?.actor_id} />.
              </strong>
            </span>
          ) : (
            <span>
              .
              <Image
                src={`/images/flags/${rootNode?.country_code?.toLowerCase()}.svg`}
                height={10}
                width={15}
                alt={`National Flag`}
              />
            </span>
          )}
        </span>
      ) : null}
    </div>
  );
}

export function TaxedOwner({
  owner,
  hideOrgNumber = false,
  tooltip = false,
  compact = false
}: {
  owner: any;
  hideOrgNumber?: boolean;
  tooltip?: boolean;
  compact?: boolean;
}) {
  // const [loading, setLoading] = useState<boolean>(true);

  const { setSlideIn } = useSlideIn();

  const { t } = useTranslation('common');

  const handleOwnerClick = (e, clickedOwner) => {
    if (clickedOwner) {
      setSlideIn({
        type: GotoFeature.COMPANY,
        object: clickedOwner
      });
      e.stopPropagation();
    }
  };

  return (
    <div
      className={cls(`text-xs dark:text-white w-full`, {
        'pr-1': !compact
      })}>
      <div>
        <button
          onClick={(e) => handleOwnerClick(e, owner)}
          className={cls(
            `font-medium cursor-pointer text-left text-bobo-prop dark:text-dark-prop hover:text-bobo-lightgreen dark:hover:text-bobo-lightgreen transition-colors duration-300`,
            {
              '!text-right': compact
            }
          )}>
          {owner?.name || owner?.company_name || t('general.no-data-available')}
        </button>
      </div>

      {owner?.actor_id?.length === 10 && !hideOrgNumber && !compact && (
        <div
          className={cls(`flex items-center`, {
            '!justify-end': compact
          })}>
          {t('general.org-nr')}
          <span className="ml-1">
            <OrgNumber actorId={owner?.actor_id} />
          </span>
        </div>
      )}

      {owner?.mainActivity && !owner?.company_type_name && !compact && (
        <div>{owner?.mainActivity}</div>
      )}

      {owner?.company_type_name && !compact && (
        <div>
          {t('owner.company-type-and-location', {
            type_name: owner?.company_type_name,
            location: owner?.country_part ?? owner?.county
          })}
        </div>
      )}

      {!compact && (
        <>
          {owner?.ownership ? (
            <div>{t('owner.ownership', { ownership: owner.ownership })}</div>
          ) : null}
        </>
      )}

      {owner?.actor_id?.length === 12 ||
        owner?.first_name ||
        (owner?.last_name && (
          <div className="flex items-center justify-start">
            <div className="mt-0">{owner?.address_generic}</div>
            <div className="text-right">
              {owner?.postal_code} {owner?.postal_area}
            </div>
          </div>
        ))}
      {owner?.root_actor_name && owner?.root_actor_id !== owner?.actor_id && !tooltip && (
        <button
          onClick={(e) =>
            handleOwnerClick(e, { name: owner?.root_actor_name, actor_id: owner?.root_actor_id })
          }
          className={cls(
            `font-medium mt-1 leading-[9px] text-bobo-prop dark:text-dark-prop hover:text-bobo-lightgreen dark:hover:text-bobo-lightgreen transition-colors duration-300 disabled:text-gray-500 text-left`,
            {
              'flex items-center justify-end w-full !text-right': compact
            }
          )}>
          {!compact ? (
            <div className="text-[9px] font-demi text-gray-600 dark:text-gray-200 uppercase flex items-center">
              <ShareIcon className="h-3 w-3 mr-1 rotate-90 text-gray-400 dark:text-gray-200" />
              <div>{t('general.parent-co')}</div>
            </div>
          ) : (
            <ShareIcon className="h-3 w-3 mr-1 rotate-90" />
          )}
          <div className="mt-1 leading-3">{owner?.root_actor_name}</div>
        </button>
      )}
    </div>
  );
}
