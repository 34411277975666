/* Components & Widgets */
import { hide as hideIntercom, showArticle } from '@intercom/messenger-js-sdk';
import { useTranslation } from 'next-i18next';
import { GotoFeature } from 'shared/dist/types';

import { useSlideIn } from '../context/slide-in-context';
import { helpTextIds } from '../lib/help-text-ids';
import { reportMissingArticle } from '../lib/user-client-wrapper';

export default function InfoButton({
  helpTextId,
  title,
  className,
  small
}: {
  helpTextId: string;
  title?: string;
  className?: string;
  small?: boolean;
}) {
  const { t } = useTranslation('common');
  const { setSlideIn } = useSlideIn();

  const info = {
    'prop.skyddsrum': {
      title: t('info.safe-rooms.title'),
      desc: t('info.safe-rooms.desc')
    },
    'climate.wind': {
      title: t('info.wind.title'),
      desc: t('info.wind.desc')
    },
    'climate.snow': {
      title: t('info.snow.title'),
      desc: t('info.snow.desc')
    },
    'climate.minTemp': {
      title: t('info.min-temp.title'),
      desc: t('info.min-temp.desc')
    },
    'climate.maxTemp': {
      title: t('info.max-temp.title'),
      desc: t('info.max-temp.desc')
    },
    'geo.soiltype': {
      title: t('info.soiltype.title'),
      desc: t('info.soiltype.desc')
    },
    'geo.depth': {
      title: t('info.soil-depth.title'),
      desc: t('info.soil-depth.desc')
    },
    'geo.bergart': {
      title: t('info.rock.title'),
      desc: t('info.rock.desc')
    },
    'geo.geo_enh_tx': {
      title: t('info.rock-origin.title'),
      desc: t('info.rock-origin.desc')
    },
    'geo.vled_text': {
      title: t('info.geothermal.title'),
      desc: t('info.geothermal.desc')
    },
    'geo.gamma': {
      title: t('info.gamma.title'),
      desc: t('info.gamma.desc')
    },
    'geo.kalium': {
      title: t('info.potassium.title'),
      desc: t('info.potassium.desc')
    },
    'geo.torium': {
      title: t('info.thorium.title'),
      desc: t('info.thorium.desc')
    },
    'geo.flooding': {
      title: t('info.flooding.title'),
      desc: t('info.flooding.desc')
    },
    'geo.bhf': {
      title: t('info.max-flow.title'),
      desc: t('info.max-flow.desc')
    },
    'geo.c1': {
      title: t('info.coastal-1.title'),
      desc: t('info.coastal-1.desc')
    },
    'geo.c3': {
      title: t('info.coastal-3.title'),
      desc: t('info.coastal-3.desc')
    },
    'geo.c5': {
      title: t('info.coastal-5.title'),
      desc: t('info.coastal-5.desc')
    },
    'geo.ebh': {
      title: t('info.ehb.title'),
      desc: t('info.ehb.desc')
    },
    'geo.nd': {
      title: t('info.nd.title'),
      desc: t('info.nd.desc')
    },
    'geo.lamningar': {
      title: t('info.archaeology.title'),
      desc: t('info.archaeology.desc')
    },
    'geo.ebh.section': {
      title: t('info.ebh-section.title'),
      desc: t('info.ebh-section.desc')
    }
  };

  const handleClick = (e) => {
    e.stopPropagation();

    if (helpTextId.includes('.')) {
      hideIntercom();
      setSlideIn({ object: info[helpTextId], type: GotoFeature.INFO });
    } else {
      const id = helpTextIds[helpTextId];
      if (!id) {
        process.env.NEXT_PUBLIC_STAGE === 'development'
          ? console.error('article missing', { helpTextId })
          : reportMissingArticle(helpTextId);

        showArticle('236340');
      } else {
        showArticle(id);
      }
    }
  };

  const classes = className
    ? className
    : `p-[1px] text-mini text-bobo-prop dark:text-dark-prop dark:hover:text-white hover:bg-bobo-prophover dark:hover:bg-dark-prophover transition-color duration-300 hover:text-white border border-bobo-prop dark:border-dark-prop dark:hover:border-dark-prophover dark:bg-dark-lessfaded rounded-full flex items-center justify-center ${
        small ? 'min-w-[16px] w-[16px] h-[16px] min-h-[16px]' : 'w-[20px] h-[20px] p-1'
      }`;

  return (
    <>
      <button onClick={(e) => handleClick(e)} className={classes}>
        {small ? '?' : (title ?? <strong>?</strong>)}
      </button>
    </>
  );
}
