import { useTranslation } from 'next-i18next';
import { MouseEventHandler } from 'react';

export default function ReportOption({
  onClick,
  title,
  accessStatus
}: {
  onClick: MouseEventHandler<HTMLButtonElement>;
  title: string;
  accessStatus?: boolean;
}) {
  const { t } = useTranslation('common');
  return (
    <button
      onClick={onClick}
      className="leading-3 p-3 flex wrap justify-between items-center dark:hover:bg-dark-lessfaded hover:bg-gray-100 w-full transition-colors duration-300 last:rounded-b-sm">
      <div>
        <div className="w-full text-left text-xs text-bobo-black dark:text-white">{title}</div>
        {accessStatus !== undefined ? (
          <div className="w-full text-left text-gray-400 text-mini uppercase font-medium">
            {accessStatus ? t('reports.unlocked') : t('reports.unlock')}
          </div>
        ) : null}
      </div>
    </button>
  );
}
