import centroid from '@turf/centroid';
import { EBH_LEVEL } from 'shared/dist/constants';
import { GotoFeature, PropertyTypeGroup } from 'shared/dist/types';

import { layersConfig } from '@/src/lib/map/config';

import buildingBasic from './items/buildings-basic';
import plansBasic from './items/plans-basic';
import powerGrid from './items/powergrid';
import propertiesBasic from './items/properties-basic';

export const loadLayers = async (
  map: any,
  layers: string[],
  object: any,
  theme: any,
  ts,
  t,
  propertyTypes?: PropertyTypeGroup[],
  setPopup?: any,
  selectedObject?: any,
  setSlideIn?: any,
  disableInteraction = false
) => {
  map?.getMap()?.getLayer('ebh') && map?.getMap()?.removeLayer('ebh');
  map?.getMap()?.getLayer('ebh-text') && map?.getMap()?.removeLayer('ebh-text');
  map?.getMap()?.getLayer('lamningar') && map?.getMap()?.removeLayer('lamningar');
  map?.getMap()?.getLayer('lamningar-point') && map?.getMap()?.removeLayer('lamningar-point');
  map?.getMap()?.getLayer('c1') && map?.getMap()?.removeLayer('c1');
  map?.getMap()?.getLayer('c3') && map?.getMap()?.removeLayer('c3');
  map?.getMap()?.getLayer('c5') && map?.getMap()?.removeLayer('c5');
  map?.getMap()?.getLayer('flooding') && map?.getMap()?.removeLayer('flooding');
  map?.getMap()?.getLayer('bhf') && map?.getMap()?.removeLayer('bhf');
  map?.getMap()?.getLayer('lamningar-text') && map?.getMap()?.removeLayer('lamningar-text');
  map?.getMap()?.getLayer('skyddsrum') && map?.getMap()?.removeLayer('skyddsrum');

  if (layers?.includes('properties')) {
    await propertiesBasic(map, theme, propertyTypes, disableInteraction);
  }

  layers?.includes('ebh') &&
    !map?.getMap()?.getLayer('ebh') &&
    map?.getMap()?.addLayer({
      id: 'ebh',
      type: 'circle',
      source: 'ebh', // reference the data source
      'source-layer': 'EBH_Potentiellt_fororenade_om-d2uvef', // reference the data source
      layout: {},
      paint: {
        'circle-radius': 12,
        'circle-stroke-width': 2,
        'circle-stroke-color': [
          'match',
          ['get', 'RISKKLASS'],
          ['1'],
          'rgba(203, 111, 108, 1)',
          ['2', '3'],
          'rgba(235, 186, 73, 1)',
          ['4', '5', 'MK', 'KMK'],
          'rgba(41, 145, 132, 1)',
          'rgba(251, 146, 60, 1)'
        ],
        'circle-color': [
          'match',
          ['get', 'RISKKLASS'],
          ['1'],
          'rgba(203, 111, 108, 0.75)',
          ['2', '3'],
          'rgba(235, 186, 73, 0.75)',
          ['4', '5', 'MK', 'KMK'],
          'rgba(41, 145, 132, 0.75)',
          'rgba(251, 146, 60, 0.75)'
        ],
        'circle-opacity': 1
      }
    });
  layers?.includes('ebh') &&
    !map?.getMap()?.getLayer('ebh-text') &&
    map?.getMap()?.addLayer({
      id: 'ebh-text',
      type: 'symbol',
      source: 'ebh', // reference the data source
      'source-layer': 'EBH_Potentiellt_fororenade_om-d2uvef', // reference the data source
      layout: {
        'text-field': ['case', ['has', 'RISKKLASS'], ['concat', ['get', 'RISKKLASS'], '\n'], '?'],
        'text-size': 11,
        'text-font': ['Open Sans Bold']
      },
      paint: {
        'text-opacity': 0.75,
        'text-color': '#222222'
      }
    });

  layers?.includes('ebh') &&
    map?.getMap()?.on('mousemove', 'ebh', () => {
      map.getMap().getCanvas().style.cursor = 'pointer';
    });

  layers?.includes('ebh') &&
    map?.getMap()?.on('mouseleave', 'ebh', () => {
      map.getMap().getCanvas().style.cursor = 'default';
    });

  const ebhClick = (e) => {
    // mark event as cancelled so other click handlers can ignore it.
    e.originalEvent.cancelBubble = true;

    setPopup({
      title: e.features[0].properties.P_BRANSCH,
      subTitle: e.features[0].properties.S_BRANSCH,
      status: e.features[0].properties.STATUS,
      riskLevel: `${ts(EBH_LEVEL[e.features[0].properties.RISKKLASS]) ?? t('general.unknown')} (${
        e.features[0].properties.RISKKLASS ?? '?'
      })`,
      lat: e.features[0].geometry.coordinates[1],
      lng: e.features[0].geometry.coordinates[0]
    });
  };

  layers?.includes('ebh') && !map?.getMap()?.on('click', 'ebh', ebhClick);
  layers?.includes('ebh') && !map?.getMap()?.on('touchend', 'ebh', ebhClick);

  if (layers?.includes('buildings')) {
    buildingBasic(map, object, propertyTypes, selectedObject);
  }

  if (layers?.includes('plans')) {
    plansBasic(map, setSlideIn);
  }

  if (layers?.includes('powergrid')) {
    powerGrid(map, object, propertyTypes, setPopup, selectedObject, setSlideIn);
  }

  layers?.includes('natural-disasters') &&
    !map?.getMap()?.getLayer('natural-disasters') &&
    map?.getMap()?.addLayer({
      id: 'natural-disasters',
      type: 'fill',
      source: 'natural-disasters', // reference the data source
      'source-layer': 'hhEventNaturalDisasters-8evcvn', // reference the data source
      layout: {},
      paint: {
        'fill-color': 'rgba(41, 145, 132, 1.0)',
        'fill-opacity': ['case', ['boolean', ['feature-state', 'selected'], false], 0.3, 0.0]
      }
    });

  layers?.includes('rent') &&
    !map?.getMap()?.getLayer('rent') &&
    map?.getMap()?.addLayer({
      id: 'rent',
      type: 'circle',
      source: 'rent', // reference the data source
      'source-layer': 'rent', // reference the data source
      minzoom: 10,
      layout: {},
      paint: {
        'circle-color': 'rgb(83, 139, 231)',
        'circle-radius': 2
      }
    });

  layers?.includes('rent-spaces') &&
    !map?.getMap()?.getLayer('rent-spaces') &&
    map?.getMap()?.addLayer({
      id: 'rent-spaces',
      type: 'circle',
      minzoom: 10,
      source: 'rent-spaces', // reference the data source
      'source-layer': 'rent', // reference the data source
      layout: {},
      paint: {
        'circle-color': 'rgba(134, 40, 145, 1.0)',
        'circle-radius': 2
      }
    });

  layers?.includes('rent-spaces') &&
    !map?.getMap()?.getLayer('rent-spaces-heatmap') &&
    map?.getMap()?.addLayer({
      id: 'rent-spaces-heatmap',
      type: 'heatmap',
      source: 'rent-spaces', // reference the data source
      'source-layer': 'rent', // reference the data source
      layout: {
        visibility: 'none'
      },
      maxzoom: 15,
      paint: {
        // Increase the heatmap weight based on frequency and property magnitude
        'heatmap-weight': ['interpolate', ['linear'], ['get', 'rent'], 0, 0, 4000, 1],
        // Increase the heatmap color weight weight by zoom level
        // heatmap-intensity is a multiplier on top of heatmap-weight
        'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, 13, 3],
        // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
        // Begin color ramp at 0-stop with a 0-transparancy color
        // to create a blur-like effect.
        'heatmap-color': [
          'interpolate',
          ['linear'],
          ['heatmap-density'],
          0,
          'rgba(33,102,172,0)',
          0.2,
          'rgba(134, 40, 145, .2)',
          0.4,
          'rgba(134, 40, 145, .3)',
          0.6,
          'rgba(134, 40, 145, .4)',
          0.8,
          'rgba(134, 40, 145, .6)',
          1,
          'rgba(134, 40, 145, .9)'
        ],
        // Adjust the heatmap radius by zoom level
        'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, 13, 20],
        // Transition from heatmap to circle layer by zoom level
        'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 1, 13, 0]
      }
    });

  layers?.includes('rent') &&
    !map?.getMap()?.getLayer('rent-housing-heatmap') &&
    map?.getMap()?.addLayer({
      id: 'rent-housing-heatmap',
      type: 'heatmap',
      source: 'rent', // reference the data source
      'source-layer': 'rent', // reference the data source
      layout: {},
      maxzoom: 15,
      paint: {
        // Increase the heatmap weight based on frequency and property magnitude
        'heatmap-weight': ['interpolate', ['linear'], ['get', 'rent'], 0, 0, 2000, 1],
        // Increase the heatmap color weight weight by zoom level
        // heatmap-intensity is a multiplier on top of heatmap-weight
        'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, 13, 3],
        // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
        // Begin color ramp at 0-stop with a 0-transparancy color
        // to create a blur-like effect.
        'heatmap-color': [
          'interpolate',
          ['linear'],
          ['heatmap-density'],
          0,
          'rgba(83, 139, 231, 0)',
          0.2,
          'rgba(83, 139, 231, .2)',
          0.4,
          'rgba(83, 139, 231, .3)',
          0.6,
          'rgba(83, 139, 231, .4)',
          0.8,
          'rgba(83, 139, 231, .6)',
          1,
          'rgba(83, 139, 231, .9)'
        ],
        // Adjust the heatmap radius by zoom level
        'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, 13, 20],
        // Transition from heatmap to circle layer by zoom level
        'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 1, 13, 0]
      }
    });

  layers?.includes('flooding') &&
    !map?.getMap()?.getLayer('flooding') &&
    map?.getMap()?.addLayer(layersConfig.flood_100years);

  layers?.includes('c1') &&
    !map?.getMap()?.getLayer('c1') &&
    map?.getMap()?.addLayer(layersConfig.flood_1meter);

  layers?.includes('c3') &&
    !map?.getMap()?.getLayer('c3') &&
    map?.getMap()?.addLayer(layersConfig.flood_3meter);

  layers?.includes('c5') &&
    !map?.getMap()?.getLayer('c5') &&
    map?.getMap()?.addLayer(layersConfig.flood_5meter);

  layers?.includes('bhf') &&
    !map?.getMap()?.getLayer('bhf') &&
    map?.getMap()?.addLayer(layersConfig.flood_chf);

  if (layers?.includes('lamningar')) {
    !map?.getMap()?.getLayer('lamningar') &&
      map?.getMap()?.addLayer({
        id: 'lamningar',
        type: 'fill',
        source: 'lamningar', // reference the data source
        'source-layer': 'Lamningar yta', // reference the data source
        layout: {},
        paint: {
          'fill-color': 'rgba(220, 182, 121, .25)',
          'fill-opacity': 1
        }
      });

    if (!map?.getMap()?.getLayer('lamningar-point')) {
      map?.getMap()?.addLayer({
        id: 'lamningar-point',
        type: 'symbol',
        source: 'lamningar-point', // reference the data source
        'source-layer': 'Lamningar', // reference the data source
        layout: {
          'icon-image': [
            'match',
            ['get', 'antikv_bed'],
            ['Fornlämning', 'Möjlig fornlämning'],
            'fornlamning',
            ['Övrig kulturhistorisk lämning'],
            'kulturlamning',
            'other'
          ],
          'icon-size': 1
        },
        paint: {
          'text-opacity': 0.75,
          'text-color': '#222222'
        }
      });
    }
  }

  layers?.includes('lamningar') &&
    !map?.getMap()?.getLayer('lamningar-text') &&
    map?.getMap()?.addLayer({
      id: 'lamningar-text',
      type: 'symbol',
      source: 'lamningar', // reference the data source
      'source-layer': 'Lamningar yta', // reference the data source
      layout: {
        'icon-image': [
          'match',
          ['get', 'antikv_bed'],
          ['Fornlämning', 'Möjlig fornlämning'],
          'fornlamning',
          ['Övrig kulturhistorisk lämning'],
          'kulturlamning',
          'other'
        ],
        'icon-size': 1
      },
      paint: {
        'text-opacity': 0.75,
        'text-color': '#222222'
      }
    });

  if (layers?.includes('lamningar')) {
    map?.getMap()?.on('mousemove', 'lamningar', () => {
      map.getMap().getCanvas().style.cursor = 'pointer';
    });

    map?.getMap()?.on('mouseleave', 'lamningar', () => {
      map.getMap().getCanvas().style.cursor = 'default';
    });

    map?.getMap()?.on('mousemove', 'lamningar-point', () => {
      map.getMap().getCanvas().style.cursor = 'pointer';
    });

    map?.getMap()?.on('mouseleave', 'lamningar-point', () => {
      map.getMap().getCanvas().style.cursor = 'default';
    });
  }

  if (layers?.includes('lamningar')) {
    const lamningarClick1 = (e) => {
      e.preventDefault();
      if (e.features && e.features?.length > 0) {
        const cent: any = centroid(e.features[0]);
        setSlideIn({
          object: {
            details: {
              properties: e.features[0]?.properties,
              lat: cent?.geometry?.coordinates[1],
              lng: cent?.geometry?.coordinates[0]
            }
          },
          type: GotoFeature.HISTORIC_POINT
        });
      }
    };

    !map?.getMap()?.on('click', 'lamningar-text', lamningarClick1);
    !map?.getMap()?.on('touchend', 'lamningar-text', lamningarClick1);

    const lamningarClick2 = (e) => {
      e.preventDefault();
      if (e.features && e.features?.length > 0) {
        const cent: any = centroid(e.features[0]);
        setSlideIn({
          object: {
            details: {
              properties: e.features[0]?.properties,
              lat: cent?.geometry?.coordinates[1],
              lng: cent?.geometry?.coordinates[0]
            }
          },
          type: GotoFeature.HISTORIC_POINT
        });
      }
    };

    !map?.getMap()?.on('click', 'lamningar-point', lamningarClick2);
    !map?.getMap()?.on('touchend', 'lamningar-point', lamningarClick2);
  }

  layers?.includes('skyddsrum') &&
    !map?.getMap()?.getLayer('skyddsrum') &&
    map?.getMap()?.addLayer(layersConfig.bombshelters);

  const skyddsrumClick = (e) => {
    // mark event as cancelled so other click handlers can ignore it.
    e.originalEvent.cancelBubble = true;

    const skyddsrumProperty = `${e?.features[0].properties?.serviceL_1?.toUpperCase() || ''} ${(
      e?.features[0].properties?.additional ||
      e?.features[0].properties?.additionalLocationDescription
    )?.toUpperCase()}`;
    const street = e?.features[0].properties?.serviceL_2;
    const numberOccupants =
      e?.features[0].properties.numberOfOc || e?.features[0].properties?.numberOfOccupants;

    setPopup({
      title: 'Skyddsrum',
      skyddsrumSubTitle: skyddsrumProperty,
      skyddsrumSubTitle2: street,
      skyddsrumSubTitle3: `Plats för ${numberOccupants} personer`,
      lat: e?.features[0].geometry.coordinates[1],
      lng: e?.features[0].geometry.coordinates[0]
    });
  };

  layers?.includes('skyddsrum') && !map?.getMap()?.on('click', 'skyddsrum', skyddsrumClick);
  layers?.includes('skyddsrum') && !map?.getMap()?.on('touchend', 'skyddsrum', skyddsrumClick);
};
