import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMapContext } from '@/src/context/google-map-context';

interface Layer {
  id: string;
  name: string;
  featureType: string;
  elementType: string;
}

const MapLayerToggle: React.FC = () => {
  const { t } = useTranslation('common');
  const { toggleDefaultLayer, setLayerVisibility } = useMapContext();

  // Include the default Google Maps layer as a toggleable layer
  const defaultMapLayers: Layer[] = [
    { id: 'street', name: t('layers.street-name'), featureType: 'road', elementType: 'labels' },
    { id: 'poi', name: t('layers.poi'), featureType: 'poi', elementType: 'all' },
    {
      id: 'administrative',
      name: t('layers.admin-labels'),
      featureType: 'administrative',
      elementType: 'all'
    },
    { id: 'landscape', name: t('layers.landscape'), featureType: 'landscape', elementType: 'all' },
    { id: 'water', name: t('layers.water'), featureType: 'water', elementType: 'all' },
    { id: 'transit', name: t('layers.transit'), featureType: 'transit', elementType: 'all' },
    {
      id: 'arterial',
      name: t('layers.arterial'),
      featureType: 'road.arterial',
      elementType: 'geometry'
    },
    {
      id: 'highway',
      name: t('layers.highways'),
      featureType: 'road.highway',
      elementType: 'geometry'
    },
    { id: 'road', name: t('layers.roads'), featureType: 'road.local', elementType: 'geometry' }
  ];

  const geoJsonLayers = [
    { id: 'regions', name: 'Regions' },
    { id: 'councils', name: 'Councils' }
  ];

  return (
    <div className="p-2 flex justify-between">
      <div className="space-y-1">
        <div className="text-xs font-demi">{t('general.default-map-layers')}</div>
        {geoJsonLayers.map((layer) => (
          <div key={layer.id} className="flex items-center space-x-2 text-xs">
            <label className="cursor-pointer flex items-center space-x-1">
              <input
                defaultChecked
                type="checkbox"
                // checked={layerVisibility[layer.id] ?? true}
                onChange={(e) => setLayerVisibility(layer.id, e.target.checked)}
                className="mr-2 rounded-sm checked:bg-bobo-prop hover:bg-bobo-proplight cursor-pointer"
              />
              {layer.name}
            </label>
          </div>
        ))}
      </div>
      <div className="space-y-1">
        <div className="text-xs font-demi">{t('general.default-map-layers')}</div>
        {defaultMapLayers.map((layer) => (
          <div key={layer.id} className="flex items-center space-x-2 text-xs">
            <label className="cursor-pointer flex items-center space-x-1">
              <input
                type="checkbox"
                // checked={layerVisibility[layer.id] ?? true}
                defaultChecked
                onChange={(e) =>
                  toggleDefaultLayer(layer.featureType, layer.elementType, e.target.checked)
                }
                className="mr-2 rounded-sm checked:bg-bobo-prop hover:bg-bobo-proplight cursor-pointer"
              />
              {layer.name}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MapLayerToggle;
