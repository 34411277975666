import { featureCollection } from '@turf/helpers';
import { PropertyTypeGroup } from 'shared/dist/types';
import { getGroupForObjectType } from 'shared/dist/util/calculations';

import { getPrimaryColorForMap } from '../../../colors';

export default function buildSelectedProperty(map: any, object: any) {
  if (object?.buildings && object?.buildings?.length > 0) {
    const geoJson: any = featureCollection(object?.buildings);

    let taxCode = 220;
    if (object?.primaryTaxCode) {
      taxCode = object?.primaryTaxCode;
    } else if (object?.taxCodes && object?.taxCodes?.length > 0) {
      taxCode = object?.taxCodes[0];
    }
    const propType: PropertyTypeGroup = getGroupForObjectType(taxCode);

    if (map.getMap()?.getLayer('selected-building')) {
      map.getMap()?.removeLayer('selected-building');
    }

    if (map.getMap()?.getSource('selected-building')) {
      map.getMap()?.removeSource('selected-building');
    }

    !map.getMap()?.getSource('selected-building') &&
      map.getMap()?.addSource('selected-building', {
        type: 'geojson',
        data: geoJson
      });

    if (!map?.getMap()?.getLayer('selected-building')) {
      map?.getMap()?.addLayer(
        {
          id: 'selected-building',
          type: 'fill',
          source: 'selected-building',
          layout: {},
          paint: {
            'fill-color': getPrimaryColorForMap(propType, 0.01),
            // 'fill-extrusion-base': 0,
            // 'fill-extrusion-height': 0.1,
            'fill-opacity': 1
          }
        },
        'prop-line'
      );
    }
  }
}
