import { PencilIcon } from '@heroicons/react/24/solid';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useGlobal } from 'reactn';
import { ButtonType, TeamDetailsVM } from 'shared/dist/types';

import { Add01Icon } from '@/src/assets/icons';
import { useDialog } from '@/src/context/dialog-context';
import Button from '@/src/widgets/buttons';

export default function TeamsMenuOptions() {
  const router = useRouter();

  const { dialog, setDialog } = useDialog();

  const [teamGlobal] = useGlobal<{ team: TeamDetailsVM }>('team' as never);

  const { t } = useTranslation('common');

  return (
    <div className="flex gap-2 items-center justify-center">
      {!router?.asPath?.includes('overview') ? (
        <>
          <Button
            type={ButtonType.DELETE}
            onClick={() => {
              setDialog({
                dialogType: 'prompt-crm2-delete-teams-dialog',
                object: {
                  initStep: 1,
                  teamUUID: router?.query?.index?.[0],
                  team: teamGlobal
                }
              });
            }}>
            {t('crm.user-actions.delete-team')}
          </Button>

          <Button
            type={ButtonType.ADD}
            onClick={() => {
              setDialog({
                dialogType: 'prompt-crm2-edit-team-dialog',
                object: {
                  initStep: 2,
                  teamUUID: router?.query?.index?.[0],
                  team: teamGlobal
                }
              });
            }}>
            <PencilIcon className="text-white w-4 h-4" />
            <div>{t('crm.user-actions.change-name')}</div>
          </Button>

          <Button
            type={ButtonType.ADD}
            onClick={() => {
              setDialog({
                dialogType: 'prompt-crm2-add-team-dialog',
                object: {
                  initStep: 3,
                  teamUUID: router?.query?.index?.[0],
                  team: teamGlobal
                }
              });
            }}>
            <Add01Icon className="text-white w-4 h-4" />
            <div>{t('crm.user-actions.add-member')}</div>
          </Button>
        </>
      ) : null}

      <Button
        type={ButtonType.ADD}
        onClick={() => {
          setDialog({
            dialogType: 'prompt-crm2-create-team-dialog',
            object: {
              initStep: 4
            }
          });
        }}>
        <Add01Icon className="text-white w-4 h-4" />
        <div>{t('crm.user-actions.create-a-new-team')}</div>
      </Button>
    </div>
  );
}
