export default function loadFeatures(map, geoJson) {
  map?.getMap()?.getLayer('feats-circle') && map?.getMap()?.removeLayer('feats-circle');
  map?.getMap()?.getLayer('feats-text') && map?.getMap()?.removeLayer('feats-text');
  map?.getMap()?.getSource('feats') && map?.getMap()?.removeSource('feats');

  if (!map?.getMap()?.getSource('feats')) {
    map?.getMap()?.addSource('feats', {
      type: 'geojson',
      data: geoJson
    });
  }

  !map.getMap()?.getLayer('feats-circle') &&
    map?.getMap()?.addLayer({
      id: 'feats-circle',
      type: 'circle',
      source: 'feats',
      layout: {},
      paint: {
        'circle-color': 'rgba(41, 145, 132, 0.9)',
        'circle-opacity': 1,
        'circle-radius': 14,
        'circle-stroke-width': 3,
        'circle-stroke-color': '#ffffff'
      }
    });

  !map.getMap()?.getLayer('feats-text') &&
    map?.getMap()?.addLayer({
      id: 'feats-text',
      type: 'symbol',
      source: 'feats',
      layout: {
        'text-field': ['format', ['get', 'authorityNickname']],
        'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
        'text-size': 11,
        'text-offset': [0, 2.5]
      },
      paint: {
        'text-color': 'rgba(0, 0, 0, 1)'
        // 'text-halo-color': 'rgba(0, 0, 0, 1)',
        // 'text-halo-width': 20
      }
    });
}
