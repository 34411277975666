import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { Add01Icon } from '@/src/assets/icons';
import { useDialog } from '@/src/context/dialog-context';
import { createCRM2Board } from '@/src/lib/crm2-wrapper';

import Button from '../buttons';

export default function CRM2OverviewMenuOptions() {
  const { t } = useTranslation('common');
  const { dialog, setDialog } = useDialog();

  const addBoard = (e) => {
    e.stopPropagation();
    setDialog({
      dialogType: 'prompt-crm2-dialog-switcher',
      object: {
        initStep: 10
      }
    });
  };

  return (
    <div className="flex space-x-2">
      <div className="inset-0 flex items-center justify-center">
        <Button onClick={(e) => addBoard(e)}>
          <Add01Icon className="dark:text-white text-white" />
          <div>{t('crm.user-actions.add-board')}</div>
        </Button>
      </div>
    </div>
  );
}
