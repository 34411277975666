import { Dialog } from '@headlessui/react';
import cls from 'classnames';
import { useTranslation } from 'next-i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { ButtonType } from 'shared/dist/types';
import {
  CashflowModelObjectType,
  CashflowValuationModel
} from 'shared/dist/types/cashflow/cashflow-model';
import { Error, Scooter } from 'src/animations';

import DropdownMenu from '@/src/layout/components/dropdown-menu';
import Button from '@/src/widgets/buttons';

import { createNewModel } from '../../../lib/utils/cashflow-valuation/general';
import { cashflowTheme } from '../../cashflow-valuation/constants/cashflow-theme';
import { crmDialogTheme } from '../crm/dialog-theme';

const stepNames = {
  initialAdd: 1,
  initialEdit: 2,
  loading: 3,
  error: 4
};

function CashflowAddModelStep1({
  close,
  setStep,
  setReload,
  object,
  setSelectedModel,
  models
}: {
  close: any;
  setStep: (step: number) => void;
  setReload: (reload: boolean) => void;
  object: any;
  setSelectedModel: (arg0?: CashflowValuationModel) => void;
  models?: CashflowValuationModel[];
}) {
  const inputRef: any = useRef(null);
  const [name, setName] = useState<string>(``);
  const [clicked, setClicked] = useState<boolean>(false);
  const { t } = useTranslation('common');

  const defaultModel = useMemo(
    () =>
      createNewModel(
        object,
        t('property.cashflow-valuation.default-model'),
        t,
        CashflowModelObjectType.PROPERTY
      ),
    [object, t]
  );

  const [modelTemplates, setModelTemplates] = useState<CashflowValuationModel[]>([defaultModel]);
  const [selectedModelTemplate, setSelectedModelTemplate] =
    useState<CashflowValuationModel>(defaultModel);

  useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current?.focus();
    }

    if (models?.length) {
      const newTemplates = [defaultModel, ...models];
      setModelTemplates(newTemplates);
    }
  }, [models, defaultModel]);

  const saveModelFetch = async (cashflowModel: CashflowValuationModel): Promise<boolean> => {
    const response = await fetch('/api/prop/cashflow/create', {
      method: 'POST',
      body: JSON.stringify({
        data: cashflowModel
      })
    });
    return response.ok;
  };

  const saveModel = async () => {
    setReload(false);
    setStep(stepNames.loading);

    if (!selectedModelTemplate) {
      setStep(stepNames.error);
      return;
    }

    const model = { ...selectedModelTemplate, name: name, uuid: defaultModel.uuid };

    try {
      const success = await saveModelFetch(model);
      if (!success) {
        setStep(stepNames.error);
        return;
      }

      setSelectedModel(model);
      toast.success(
        t('property.cashflow-valuation.dialogs.create-model-success-text', {
          model: model.name,
          property: object.authority_nickname
        })
      );
      close();
    } catch (error) {
      setStep(stepNames.error);
    }
  };

  const renderModelItems = (close: () => void) => {
    return modelTemplates.map((model, idx) => {
      const isSelected: boolean = selectedModelTemplate.uuid === model.uuid;

      return (
        <div
          key={idx}
          className={cls(
            'py-1 px-2 pr-6 transition-colors duration-200 dark:text-white text-xs w-full  text-bobo-prop',
            {
              'hover:bg-bobo-proplight dark:hover:bg-dark-prophover text-bobo-prop hover:text-bobo-prophover':
                !isSelected,
              'bg-bobo-prop dark:bg-dark-prop text-white': isSelected
            }
          )}>
          <button
            onClick={() => {
              setSelectedModelTemplate(model);
              close();
            }}
            className="w-full h-full flex justify-between items-center">
            <div className="flex flex-col items-start">
              <div>{model.name}</div>
            </div>
          </button>
        </div>
      );
    });
  };

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          {t('property.cashflow-valuation.dialogs.create-new-model')}
        </Dialog.Title>
        <p className={crmDialogTheme.subtext}>
          {t('property.cashflow-valuation.dialogs.create-new-model-description')}
        </p>

        <div className={`${cashflowTheme.inputContainer} !w-2/3 !mt-2`}>
          <input
            placeholder={t('property.cashflow-valuation.settings.model-name')}
            autoComplete="off"
            className={`${cashflowTheme.inputField} !h-6 placeholder:text-gray-400`}
            value={name}
            onChange={(txt) => {
              setName(txt.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key == 'Enter' && clicked === false && name?.trim().length > 0) {
                saveModel();
                setClicked(true);
              }
            }}
            ref={inputRef}
          />
        </div>

        <div className={crmDialogTheme.subtext}>
          {t('property.cashflow-valuation.choose-model-template')}
        </div>
        <div className="w-2/3 mt-1">
          <DropdownMenu
            title={
              <div className="text-xs text-gray-400 dark:text-white h-6 py-1">
                {selectedModelTemplate.name}
              </div>
            }
            renderMenuItems={renderModelItems}
          />
        </div>
      </div>

      <div className="rounded-md text-mini h-[100px] bg-bobo-proplight dark:bg-dark-lessfaded dark:text-white w-1/2 overflow-y-scroll overflow-x-hidden p-4 pb-0 -mb-6 text-left relative">
        <div className="w-full flex flex-col gap-1">
          <div className="font-semibold">{t('property.cashflow-valuation.disclaimer.header')}</div>
          <div>{t('property.cashflow-valuation.disclaimer.text-1')}</div>

          <div className="font-semibold mt-1">
            {t('property.cashflow-valuation.disclaimer.sub-header')}
          </div>

          <div className="flex flex-col gap-2 w-full">
            <div className="flex items-center justify-start gap-2">
              <div>*</div>
              <div>{t('property.cashflow-valuation.disclaimer.point-1')}</div>
            </div>
            <div className="flex items-center justify-start gap-2">
              <div>*</div>
              <div>{t('property.cashflow-valuation.disclaimer.point-2')}</div>
            </div>
            <div className="flex items-center justify-start gap-2">
              <div>*</div>
              <div>{t('property.cashflow-valuation.disclaimer.point-3')}</div>
            </div>
          </div>

          <div>{t('property.cashflow-valuation.disclaimer.text-2')}</div>
        </div>

        <div className="h-8 w-full sticky bottom-0 from-bobo-proplight dark:from-dark-lessfaded to-transparent bg-gradient-to-t" />
      </div>

      <div className={crmDialogTheme.buttonContainer}>
        <Button type={ButtonType.CANCEL} onClick={close}>
          {t('general.cancel')}
        </Button>

        <Button
          disabled={!(name?.trim().length > 0)}
          onClick={() => {
            if (clicked === false) {
              saveModel();
              setClicked(true);
            }
          }}>
          {t('property.cashflow-valuation.dialogs.create-model')}
        </Button>
      </div>
    </div>
  );
}

function CashflowAddModelStepLoading() {
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.image}>
        <Scooter />
      </div>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          {t('general.saving-info')}
        </Dialog.Title>
      </div>
    </div>
  );
}

function CashflowAddModelStepError({ close }: { close: any }) {
  const { t } = useTranslation('common');

  return (
    <div className={crmDialogTheme.container}>
      <div className={crmDialogTheme.icon}>
        <Error />
      </div>
      <div className={crmDialogTheme.textContainer}>
        <Dialog.Title as="h3" className={crmDialogTheme.title}>
          <div className={crmDialogTheme.errorTitle}>{t('errors.something-went-wrong')}</div>
        </Dialog.Title>

        <p className={crmDialogTheme.subtext}>{t('errors.try-again')}</p>
      </div>

      <div className={crmDialogTheme.buttonContainer}>
        <Button
          onClick={close}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              close();
            }
          }}>
          {t('general.close')}
        </Button>
      </div>
    </div>
  );
}

export default function CashflowAddModel({
  close,
  setReload,
  object,
  setSelectedModel,
  models
}: {
  close: () => void;
  setReload: (reload: boolean) => void;
  object: any;
  setSelectedModel: (arg0?: CashflowValuationModel) => void;
  models?: CashflowValuationModel[];
}) {
  const [step, setStep] = useState(stepNames.initialAdd);

  let mod: JSX.Element = <div />;

  switch (step) {
    case stepNames.initialAdd:
      mod = (
        <CashflowAddModelStep1
          close={close}
          setStep={setStep}
          setReload={setReload}
          object={object}
          setSelectedModel={setSelectedModel}
          models={models}
        />
      );
      break;

    case stepNames.loading:
      mod = <CashflowAddModelStepLoading />;
      break;

    case stepNames.error:
      mod = <CashflowAddModelStepError close={close} />;
      break;
  }

  return <>{mod}</>;
}
