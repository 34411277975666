/* External Libraries */
import 'dayjs/locale/sv';
import 'dayjs/locale/en';

import { NextRouter } from 'next/router';
import { TResponse as GetEnvCertsResponse } from 'pages/api/prop/get-env-certs';
import { TResponse as GetPropertyResponse } from 'pages/api/prop/getProperty';
import toast from 'react-hot-toast';
import { TResponse as GetRentResponse } from 'shared/dist/helpers/market-data';
import { PropertyTypeGroup } from 'shared/dist/types';
import { PropertyItems, PropertyVM } from 'shared/dist/types/property2';
import { PropertyReportData, ReportType } from 'shared/dist/types/reports';
import { generateReportFileName } from 'shared/dist/util/string';

import { Tfunc } from '../types';

export async function searchAddress(searchStr) {
  const controller = new AbortController();
  controller.abort();

  const url = `/api/prop/searchAddress?searchStr=${encodeURIComponent(searchStr)}`;

  return fetch(url, {
    method: 'GET',
    signal: controller.signal,
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      return json;
    })
    .catch(() => {
      return {
        error: true,
        msg: `Failed to fetch url: ${url}`
      };
    });
}

export async function getReport(
  reportType: ReportType,
  propertyUUIDs: string | string[],
  router: NextRouter,
  t: Tfunc
) {
  const locale = router?.locale ? router.locale : 'sv';

  if (process.env.NEXT_PUBLIC_FEATURE_REPORT_GENERATION_IN_BACKGROUND_ENABLED == 'true') {
    enqueueReport(reportType, propertyUUIDs, t, locale);
  } else {
    router.push(`/api/reports/${reportType}?uuid=${propertyUUIDs}&locale=${locale}`);
  }
}

export async function enqueueReport(
  reportType: string,
  propertyUUIDs: string | string[],
  t: Tfunc,
  locale: string
) {
  // enqueue report generation on the backend
  // with email sent to the user after its done
  const toastId = toast.loading(t('toasts.fetching'));

  const propertyUUIDsValue = typeof propertyUUIDs == 'string' ? [propertyUUIDs] : propertyUUIDs;
  fetch(`/api/reports/enqueueReport`, {
    method: 'post',
    body: JSON.stringify({
      reportType,
      propertyUUIDs: propertyUUIDsValue,
      locale: locale
    })
  })
    .then((json) => {
      toast.dismiss();
      toast(t('toasts.send-report-success'));
      return json;
    })
    .catch((err) => {
      console.error(err);
      toast.error(t('toasts.send-report-error', { errorMsg: err.message }), {
        id: toastId
      });
    });
}

export async function fetchPropertyRent(
  property: any,
  propTypes: PropertyTypeGroup[],
  includeBRF: boolean,
  loadAll: boolean | undefined
): Promise<GetRentResponse> {
  const isBRF =
    ([
      ...(property?.owner || []),
      ...(property?.tax_owners || []),
      ...(property?.owners || [])
    ].filter((a) => a?.actor_id?.startsWith('7')).length > 0 ||
      property?.is_brf) ??
    false;

  return await fetch('/api/prop/getRent', {
    method: 'POST',
    body: JSON.stringify({
      propTypes,
      loadAll: loadAll,
      includeBRF: isBRF,
      uuid: property?.uuid
    })
  }).then((response) => {
    return response.json();
  });
}

/**
 * Fetches property data from the backend.
 * Supports fetching in 'items' (chunks) to reduce the amount of data fetched.
 * Checks if the property already has the required items, and only fetches the missing ones.
 * @param property - The existing property data, or just the uuid
 * @param items - The items to fetch
 * @returns The property data
 */
export async function getProperty(
  property: { uuid: string } | PropertyVM,
  items: PropertyItems[],
  isLight: boolean
): Promise<GetPropertyResponse> {
  const existingItems: PropertyItems[] = 'items' in property ? property.items : [];
  const requiredItems: PropertyItems[] = items.filter((item) => !existingItems.includes(item));

  if (requiredItems.length === 0 && 'items' in property) {
    return Promise.resolve({ property, error: false });
  }

  if (!property.uuid) {
    throw new Error('Property UUID is required');
  }

  const defaultError = 'Failed to fetch property';

  return await fetch(
    `/api/prop/getProperty?uuid=${property.uuid}&light=${
      isLight ? 'true' : ''
    }&items=${requiredItems.join(',')}`
  )
    .then((response) => {
      return response.json();
    })
    .then((json: GetPropertyResponse) => {
      if (!json || json?.error || !('property' in json)) {
        return {
          error: true,
          errMessage: json.errMessage || defaultError
        } as GetPropertyResponse & { error: true };
      } else {
        // merge existing property (if exists) with new data
        const newItems: PropertyItems[] = json?.property?.items ?? [];
        const resultItems: PropertyItems[] = [...new Set([...existingItems, ...newItems])];

        return {
          property: {
            ...property,
            ...json.property,
            items: resultItems
          },
          error: false
        };
      }
    })
    .catch((err) => {
      return {
        error: true,
        errMessage: err instanceof Error ? err.message : defaultError
      };
    });
}

export async function getEnvCerts(propertyUUID: string | string[]): Promise<GetEnvCertsResponse> {
  const defaultError = 'Failed to fetch env certs';

  return fetch('/api/prop/get-env-certs', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      property_uuid: propertyUUID
    })
  })
    .then((response) => {
      return response.json();
    })
    .then((json: GetEnvCertsResponse) => {
      if (!json || json?.error || !('result' in json)) {
        return {
          error: true,
          errMessage: json.errMessage || defaultError
        } as GetEnvCertsResponse & { error: true };
      } else {
        return json;
      }
    })
    .catch((err) => {
      return {
        error: true,
        errMessage: err instanceof Error ? err.message : defaultError
      };
    });
}

export async function getPropertyReport(
  reportData: PropertyReportData,
  queryParams: string[][],
  locale = 'sv',
  t: Tfunc
): Promise<{ error: boolean }> {
  const toastId = toast.loading(t('toasts.fetching'));
  try {
    const fetchUrl = queryParams?.length
      ? `/api/reports/property?${new URLSearchParams(queryParams)}`
      : '/api/reports/property';

    const response = await fetch(fetchUrl, {
      method: 'post',
      body: JSON.stringify({
        reportData,
        locale
      })
    });

    if (!response.ok) {
      throw new Error(t('errors.report-generation-error'));
    }

    const filename = generateReportFileName(
      reportData.authorityNickname,
      t('reports.property-report.title')
    );

    const blob = await response.blob();
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(link);
    toast.dismiss(toastId);
    return { error: false };
  } catch (error) {
    console.error('Report generation failed:', error);
    toast.error(
      t('toasts.send-report-error', {
        errorMsg:
          error instanceof Error
            ? error.message
            : t('crm.modals.import-properties-error-message-unknown')
      }),
      { id: toastId }
    );
    return { error: true };
  }
}
