import { useTranslation } from 'next-i18next';
import NumberFormat from 'react-number-format';
import { filterOutExtremeRents } from 'shared/dist/constants';
import { PropertyTypeGroup } from 'shared/dist/types';
import { getLatestYearOfRentalDataForPropType, getRentIntervals } from 'shared/dist/util/rent';

import RowWrapper from '@/src/layout/row-wrapper';
import { getHousingNeighbours } from '@/src/lib/rent-calculator';
import { compare_index } from '@/src/lib/utils/sort';
import ShowRent from '@/src/widgets/numbers';

import RentTaxTableWrapper from '../wrappers/rent-tax-table-wrapper';

export default function RentTaxTableHousing({
  object,
  rent,
  pt,
  forceCategory = null,
  slideIn = false
}: {
  object: any | null;
  rent: any | null;
  pt: any | null;
  forceCategory?: PropertyTypeGroup | null;
  slideIn?: boolean;
}) {
  const { t } = useTranslation('common');

  const valueYear: string[] = [];

  pt?.taxUnits?.map((tu: any) => {
    if (!valueYear?.includes(tu?.value_year) && tu?.value_year) {
      valueYear.push(tu?.value_year);
    }
  });

  const boa = rent?.v2?.residential_details?.sqm ?? null;

  const list: number[] = rent?.housing?.nearbyHousing
    ?.filter((a) => filterOutExtremeRents(a, PropertyTypeGroup.HOUSING))
    ?.map((a) => a?.indexed_rent);

  const {
    interval,
    mean
  }: { interval: [number, number]; extremeRentValue: boolean; mean: number } = getRentIntervals(
    list ?? [],
    rent?.v2?.indexed_rent
  );

  const rentNeighbours: any[] = getHousingNeighbours(
    rent?.housing?.nearbyHousing ?? [],
    object,
    interval
  );

  const chartData: any[] = rentNeighbours
    ?.filter((a) => a?.indexed_rent > 0)
    ?.map((a) => {
      if (object?.uuid === a?.uuid) {
        return {
          authority_nickname: a?.authority_nickname,
          county: a?.conty,
          uuid: a?.uuid,
          owner: a?.tax_owners?.map((o) => o?.name),
          loa: a?.gross_leasable_area,
          boa: a?.gross_residential_area,
          lat: a?.lat,
          lng: a?.lng,
          rent: 0,
          selected: Math.round(a?.indexed_rent),
          index: Math.round(a?.indexed_rent)
        };
      } else {
        return {
          authority_nickname: a?.authority_nickname,
          county: a?.conty,
          uuid: a?.uuid,
          owner: a?.tax_owners?.map((o) => o?.name),
          loa: a?.gross_leasable_area,
          boa: a?.gross_residential_area,
          lat: a?.lat,
          lng: a?.lng,
          rent: Math.round(a?.indexed_rent),
          selected: 0,
          index: Math.round(a?.indexed_rent)
        };
      }
    });

  if (compare_index && compare_index?.length > 0 && chartData) {
    chartData.sort(compare_index);
  }

  const lastYearOfPrimaryType = getLatestYearOfRentalDataForPropType(
    PropertyTypeGroup.HOUSING,
    rent?.v2
  );

  return (
    <RentTaxTableWrapper
      pt={pt}
      forceCategory={forceCategory}
      slideIn={slideIn}
      object={object}
      primaryType={PropertyTypeGroup.HOUSING}
      chartData={chartData}
      mean={mean}>
      <RowWrapper
        title={`${t('property.rent.estimated-rent-short', {
          rentType: t('property.rent-avg')
        })} ${lastYearOfPrimaryType}`}
        theme="roomy"
        helpTextId={'rentAvgResidential'}>
        {rent?.v2?.residential_details?.total_rent_income &&
        rent?.v2?.residential_details?.total_rent_income[lastYearOfPrimaryType] ? (
          <ShowRent
            value={Math.round(
              (rent?.v2?.residential_details?.total_rent_income[lastYearOfPrimaryType] * 1000) /
                rent?.v2?.residential_details?.sqm
            )}
            suffix={t('general.kr-per-square-m-suffix')}
            propType={PropertyTypeGroup.HOUSING}
          />
        ) : (
          t('general.missing-data')
        )}
      </RowWrapper>

      <RowWrapper
        title={
          <div>
            {t('property.rent.estimated-rent-short', { rentType: t('property.rent-indexed') })}{' '}
            {lastYearOfPrimaryType}{' '}
            <div className="text-gray-500 dark:text-gray-400 italic">
              {t('property.rent.rent-method', { year: '91' })}
            </div>
          </div>
        }
        helpTextId={'rentIndexed91'}>
        <NumberFormat
          value={Math.round(rent?.v2?.residential_rent_91)}
          displayType={'text'}
          thousandSeparator={` `}
          suffix={t('general.kr-per-square-m-suffix')}
        />
      </RowWrapper>

      {rent?.v2?.residential_rent_78 > 0 && !forceCategory ? (
        <RowWrapper
          title={
            <div>
              {t('property.rent.estimated-rent-short', { rentType: t('property.rent-indexed') })}{' '}
              {lastYearOfPrimaryType}{' '}
              <div className="text-gray-500 dark:text-gray-400 italic">
                {t('property.rent.rent-method', { year: '78' })}
              </div>
            </div>
          }
          helpTextId={'rentIndexed78'}>
          <NumberFormat
            value={Math.round(rent?.v2?.residential_rent_78)}
            displayType={'text'}
            thousandSeparator={` `}
            suffix={t('general.kr-per-square-m-suffix')}
          />
        </RowWrapper>
      ) : null}

      {rent?.v2?.residential_rent_12 > 0 && !forceCategory ? (
        <RowWrapper
          title={
            <div>
              {t('property.rent.estimated-rent-short', { rentType: t('property.rent-indexed') })}{' '}
              {lastYearOfPrimaryType}{' '}
              <div className="text-gray-500 dark:text-gray-400 italic">
                {t('property.rent.rent-method', { year: '12' })}
              </div>
            </div>
          }
          helpTextId={'rentIndexed12'}>
          <NumberFormat
            value={Math.round(rent?.v2?.residential_rent_12)}
            displayType={'text'}
            thousandSeparator={` `}
            suffix={t('general.kr-per-square-m-suffix')}
          />
        </RowWrapper>
      ) : null}

      {rent?.v2?.residential_details?.sqm > 0 && !forceCategory ? (
        <RowWrapper
          title={t('prop-labels.recieved-inv-support')}
          helpTextId={'rentInvestmentSupport'}>
          {rent?.housing?.investment_support ? t('general.yes') : t('general.no')}
        </RowWrapper>
      ) : null}

      <RowWrapper
        helpTextId={'rentInterval'}
        title={t('general.rent-interval', { rentType: t('property.rent-indexed') })}>
        {interval[0] > 0 && interval[1] > 0 ? (
          <>
            <NumberFormat
              value={Math.round(interval[0])}
              displayType={'text'}
              thousandSeparator={` `}
              suffix={t('general.kr-suffix')}
              prefix={t('general.sek-prefix')}
            />
            &nbsp;-&nbsp;
            <NumberFormat
              value={Math.round(interval[1])}
              displayType={'text'}
              thousandSeparator={` `}
              suffix={t('general.kr-suffix')}
              prefix={t('general.sek-prefix')}
            />
          </>
        ) : (
          t('general.missing-data-point')
        )}
      </RowWrapper>

      <RowWrapper helpTextId={'propertyBoa'} title={t('general.boa')}>
        <NumberFormat
          value={Math.round(boa)}
          displayType={'text'}
          thousandSeparator={` `}
          suffix={t('general.square-meters')}
        />
      </RowWrapper>

      <RowWrapper helpTextId={'rentValueYearArea'} title={t('property.value-year')}>
        {valueYear?.join(', ')}
      </RowWrapper>
    </RentTaxTableWrapper>
  );
}
