/* External Libraries */
import _ from 'lodash';
import moment from 'moment';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import NumberFormat from 'react-number-format';
import { compressAddresses } from 'shared/dist/util/addresses';
import { parseBeachWasteWater } from 'shared/dist/util/beach-waste-water';
import { getEnergyClasses, getPrimaryEnergyNumbers } from 'shared/dist/util/energy';

import RowWrapper from '@/src/layout/row-wrapper';
import { TaxedOwner } from '@/src/widgets/owner';
import { RowHeader, RowTableWrapper } from '@/src/widgets/row-table-body';

export default function PropertyApartmentTable({
  object,
  compact = false,
  loadingEnergy = false
}: {
  object: any;
  compact?: boolean;
  loadingEnergy?: boolean;
}) {
  const router = useRouter();

  const [leaseholderAgreement, setLeaseholderAgreement] = useState<boolean>(false);
  const [showFullAddress, setShowFullAddress] = useState<boolean>(false);

  const { t } = useTranslation('common');

  let previous: any[] = [];

  try {
    if (object?.previous_property) {
      const prev: any[] = JSON.parse(object?.previous_property);
      previous = prev;
    }
  } catch (e) {
    console.error(`failed to parse json`, e);
  }

  const { beachText, wasteText, waterText } = parseBeachWasteWater(object?.propTax, t);

  const compressedAddresses = compressAddresses(object?.addresses);

  const energyClasses: string[] = getEnergyClasses(object?.energyStatement);

  const primaryEnergyNumbers: number[] = getPrimaryEnergyNumbers(object?.energyStatement);

  const showApartments = object?.taxCodes?.find(
    (taxcode: number) => taxcode === 320 || taxcode === 321
  );

  return (
    <RowTableWrapper>
      <RowHeader title={t('property.information-about-property')} />

      <div>
        <RowWrapper title={t('property.property-nickname')} theme={'roomy'}>
          <>{object?.authorityNickname ?? object?.authority_nickname}</>
        </RowWrapper>

        {!compact && object?.propertyKey > 0 && (
          <RowWrapper title={t('property.key')} theme={'roomy'}>
            <>{object?.propertyKey}</>
          </RowWrapper>
        )}

        <RowWrapper title={t('general.local-council')} theme={'roomy'}>
          <>{object?.county}</>
        </RowWrapper>

        {object?.districtCode && (
          <RowWrapper title={t('property.district')} theme={'roomy'}>
            {object?.districtName} ({object?.districtCode})
          </RowWrapper>
        )}

        <RowWrapper
          title={
            <div>
              <div>{t('property.land-lease')}</div>

              {object?.landLeaseAgreement?.uuid ? (
                <button
                  onClick={() => {
                    setLeaseholderAgreement(!leaseholderAgreement);
                  }}
                  className="text-xs text-bobo-prop dark:text-dark-prop">
                  {leaseholderAgreement ? t('general.hide') : t('general.show')}{' '}
                  {t('property.lease-fee')}
                </button>
              ) : null}
            </div>
          }
          theme={'roomy'}>
          <div className="flex">
            {object?.landLeaseAgreement?.uuid ? t('general.yes') : t('general.no')}
          </div>
        </RowWrapper>

        {leaseholderAgreement && (
          <div className="border border-dashed !border-bobo-orange rounded-md overflow-hidden relative">
            <RowWrapper title={t('property.lease-fee-title')} theme={'roomy'}>
              <div className="flex">
                <NumberFormat
                  value={object?.landLeaseAgreement?.lease_fee ?? 0}
                  displayType={'text'}
                  thousandSeparator={` `}
                  suffix={t('general.kr-suffix')}
                  prefix={t('general.sek-prefix')}
                />
              </div>
            </RowWrapper>

            <RowWrapper title={t('property.grace-period')} theme={'roomy'}>
              <div className="flex">
                <NumberFormat
                  value={object?.landLeaseAgreement?.lease_annum ?? 0}
                  displayType={'text'}
                  thousandSeparator={` `}
                  suffix={t('units.year-suffix')}
                />
              </div>
            </RowWrapper>

            <RowWrapper title={t('property.start-date')} theme={'roomy'}>
              <div className="flex">
                {object?.landLeaseAgreement?.lease_start_date ??
                  object?.landLeaseAgreement?.org_start_date ??
                  t('general.missing-data-point')}
              </div>
            </RowWrapper>

            <RowWrapper title={t('property.termination-date')} theme={'roomy'}>
              <div className="flex">
                {object?.landLeaseAgreement?.cancellation_date ?? t('general.missing-data-point')}
              </div>
            </RowWrapper>

            {object?.leaseHolder && object?.leaseHolder?.name && (
              <RowWrapper title={t('general.lease-holder')} theme={'roomy'}>
                <TaxedOwner compact={true} owner={object?.leaseHolder} />
              </RowWrapper>
            )}
          </div>
        )}

        {!compact && previous?.length > 0 && (
          <RowWrapper title={t('property.previous-designation')} theme={'roomy'}>
            <div>
              {previous?.map((p, idx) => {
                return (
                  <div className="mb-3 w-full flex justify-between" key={idx}>
                    <div className="text-xs w-full">
                      <div>{p?.property}</div>

                      {p?.akt ? (
                        <div className="text-mini">
                          {t('property.deeds.act')} {p?.akt}
                        </div>
                      ) : null}

                      <div className="text-mini font-normal leading-[8px] italic">
                        {t('property.value-indication.delivery-text.changed')}{' '}
                        {moment(p?.changeDate).format('DD MMMM YYYY')}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </RowWrapper>
        )}

        {object?.apartmentCount > 0 && showApartments && (
          <RowWrapper title={<div>{t('property.residential-apartments')}</div>} theme={'roomy'}>
            <div className="w-4/5 ml-4 text-right justify-center flex flex-col items-end font-normal">
              <div>
                {object?.apartmentCount} {t('general.residential-flats')}
              </div>
            </div>
          </RowWrapper>
        )}

        {object?.buildYears && object?.buildYears?.length > 0 && (
          <RowWrapper title={t('property.build-year')} theme={'roomy'}>
            {object?.buildYears?.length > 0 ? object?.buildYears?.join(', ') : t('general.no-data')}
          </RowWrapper>
        )}

        {!compact && object?.rebuildYears && object?.rebuildYears?.length > 0 && (
          <RowWrapper title={t('property.reconstruction-year')} theme={'roomy'}>
            {object?.rebuildYears?.length > 0
              ? object?.rebuildYears?.join(', ')
              : t('general.no-data')}
          </RowWrapper>
        )}

        <RowWrapper title={t('property.value-year')} theme={'roomy'}>
          {object?.valueYears?.length > 0 ? object?.valueYears?.join(', ') : t('general.no-data')}
        </RowWrapper>

        {!compact && (
          <RowWrapper title={t('property.value-range')} theme={'roomy'}>
            {object?.valueArea && object?.valueArea?.properties
              ? object?.valueArea?.properties?.VO_22
              : t('general.no-data')}{' '}
            ({object?.neighbourhood})
          </RowWrapper>
        )}

        {!compact && (
          <RowWrapper title={t('property.building-permit-decision')} theme={'roomy'}>
            <div className="w-4/5 ml-4 justify-end text-right">
              <div>
                {object?.buildingPermits && object?.buildingPermits?.length > 0 ? (
                  <span className="mr-1">{object?.buildingPermits?.length}</span>
                ) : (
                  <span>{t('property.none')}&nbsp;</span>
                )}{' '}
                {t('property.building-permit-between-2019-and-June-2023')}
              </div>

              {object?.buildingPermits && object?.buildingPermits?.length > 0 && (
                <button
                  onClick={() => router.push(`/crm/fastighet/${object?.uuid}/bygglov`)}
                  className="text-bobo-prop dark:text-dark-prop text-xs hover:text-bobo-prophover dark:hover:text-dark-prophover cursor-pointer text-left">
                  {t('general.show')}
                </button>
              )}
            </div>
          </RowWrapper>
        )}

        {compact ? (
          <RowWrapper title={t('property.energy-dec')} theme={'roomy'}>
            <>
              {!loadingEnergy ? (
                <>
                  {energyClasses &&
                  energyClasses.length > 0 &&
                  primaryEnergyNumbers &&
                  primaryEnergyNumbers.length > 0 ? (
                    <div className="w-4/5 ml-4 text-right justify-end flex items-center">
                      {primaryEnergyNumbers.length === 1 ||
                      Math.min.apply(0, primaryEnergyNumbers) ===
                        Math.max.apply(0, primaryEnergyNumbers)
                        ? t('property.prime-energy-numbers', {
                            primeEnergyNumbers: primaryEnergyNumbers[0]
                          })
                        : t('property.prime-energy-numbers', {
                            primeEnergyNumbers: `${Math.min.apply(
                              0,
                              primaryEnergyNumbers
                            )}-${Math.max.apply(0, primaryEnergyNumbers)}`
                          })}
                      &nbsp;({t('property.class')}
                      {': '}
                      {energyClasses.length === 1 ||
                      energyClasses[0] === energyClasses[energyClasses.length - 1]
                        ? energyClasses[0]
                        : `${energyClasses[0]}-${energyClasses[energyClasses.length - 1]}`}
                      )
                    </div>
                  ) : (
                    <div className="w-4/5 ml-4 text-right justify-end flex items-center">
                      {t('pages.search.energy-declaration-not-done-or-old')}
                    </div>
                  )}
                </>
              ) : null}
            </>
          </RowWrapper>
        ) : null}

        {beachText && beachText?.length > 0 && !compact && (
          <RowWrapper title={beachText} list={true} theme={'roomy'} />
        )}
        {waterText && waterText?.length > 0 && !compact && (
          <RowWrapper title={waterText} list={true} theme={'roomy'} />
        )}
        {wasteText && wasteText?.length > 0 && !compact && (
          <RowWrapper title={wasteText} list={true} theme={'roomy'} />
        )}

        {object?.landOwner && object?.landOwner?.length > 0 && (
          <RowWrapper title={t('property.legal-owner')} theme={'roomy'}>
            <div className="flex w-full py-1 text-[12px] pt-1 justify-end">
              {_.uniqBy(object?.landOwner[0]?.owners || [], (o: any) => o?.actor_id)?.map(
                (lo, idx) => {
                  return (
                    <div
                      className="flex-grow mt-0 flex items-start flex-wrap justify-end"
                      key={idx}>
                      <div>
                        <TaxedOwner compact={true} owner={lo} />
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </RowWrapper>
        )}

        {compressedAddresses?.length > 0 && (
          <RowWrapper
            title={
              <div>
                <div>{t('general.addresses')}</div>

                {compressedAddresses?.length > 1 ? (
                  <button
                    onClick={() => {
                      setShowFullAddress(!showFullAddress);
                    }}
                    className="text-xs text-bobo-prop dark:text-dark-prop">
                    {showFullAddress ? t('general.hide') : t('general.show-all')}{' '}
                    {t('general.addresses').toLowerCase()}
                  </button>
                ) : null}
              </div>
            }
            theme={'roomy'}>
            <div>
              {compressedAddresses?.slice(0, showFullAddress ? 100 : 1)?.map((address, idx) => {
                return (
                  <div key={idx} className="text-right mt-1 first:mt-0 capitalize">
                    <div>
                      {address?.street} {address?.numbers?.sort().join(', ')}
                    </div>

                    <div>{address?.base}</div>
                  </div>
                );
              })}
            </div>
          </RowWrapper>
        )}

        {!compact && (
          <RowWrapper theme={'roomy'} title={t('property.latitude-wgs-84')}>
            <div>{object?.lat}</div>
          </RowWrapper>
        )}

        {!compact && (
          <RowWrapper theme={'roomy'} title={t('property.longitude-wgs-84')}>
            <div>{object?.lng}</div>
          </RowWrapper>
        )}
      </div>
    </RowTableWrapper>
  );
}
