import cls from 'classnames';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import {
  CashflowValuationAsset,
  CashflowValuationModel,
  IndexType
} from 'shared/dist/types/cashflow/cashflow-model';

import { NewAssetOption } from '@/src/components/dialogs/cashflow/cashflow-add-inflation';
import DropdownMenu from '@/src/layout/components/dropdown-menu';
import { FLSqm } from '@/src/widgets/numbers';

export default function SelectAssetsForInflation({
  model,
  selectedAssetUuids,
  setSelectedAssetUuids,
  newAsset,
  indexType
}: {
  model: CashflowValuationModel;
  selectedAssetUuids: string[];
  setSelectedAssetUuids: (selectedAssetUuids: string[]) => void;
  newAsset: NewAssetOption | undefined;
  indexType: IndexType;
}) {
  const [assets, setAssets] = useState<(CashflowValuationAsset | NewAssetOption)[]>([]);
  const { t } = useTranslation('common');

  const getIsLastIndexAssumption = (): boolean => {
    if (indexType === IndexType.RENT) {
      return model.rentIndexes.length === 1;
    }
    return model.costIndexes.length === 1;
  };

  const isLastIndexAssumption: boolean = getIsLastIndexAssumption();

  useEffect(() => {
    let assetOptions: (CashflowValuationAsset | NewAssetOption)[] = model.assets;
    if (newAsset) {
      assetOptions = [...assetOptions, newAsset];
    }
    setAssets(assetOptions);
  }, [model]);

  const handleClick = (e, asset: CashflowValuationAsset | NewAssetOption, isSelected: boolean) => {
    e.stopPropagation();

    if (isSelected) {
      const updatedSelectedAssetUuids = selectedAssetUuids.filter(
        (assetUuid) => assetUuid !== asset.uuid
      );
      setSelectedAssetUuids(updatedSelectedAssetUuids);
      return;
    }

    const updatedSelectedAssetUuids = [...selectedAssetUuids, asset.uuid];
    setSelectedAssetUuids(updatedSelectedAssetUuids);
  };

  const renderItems = (closeMenu) => {
    return assets.map((asset, idx) => {
      const isSelected: boolean = selectedAssetUuids.some((assetUuid) => assetUuid === asset.uuid);

      return (
        <div
          key={idx}
          className={cls('py-1 px-2 pr-6 transition-colors duration-200 dark:text-white w-full', {
            'hover:bg-bobo-proplight dark:hover:bg-dark-prophover text-bobo-prop hover:text-bobo-prophover':
              !isSelected,
            'bg-bobo-prop dark:bg-dark-prop hover:bg-bobo-prophover dark:hover:bg-dark-prophover text-white':
              isSelected
          })}>
          <button
            onClick={(e) => handleClick(e, asset, isSelected)}
            disabled={isLastIndexAssumption}
            className="disabled:text-gray-300 w-full h-full flex justify-between items-center">
            <div className="flex flex-col items-start">
              <div>{asset.name}</div>
              <div className={cls({ 'text-gray-400': !isSelected, 'text-gray-300': isSelected })}>
                <FLSqm value={asset.adjustableAssumptions.area} />
              </div>
            </div>
          </button>
        </div>
      );
    });
  };

  return (
    <DropdownMenu
      title={
        <div className="text-xs text-gray-400 dark:text-white">
          {t('property.cashflow-valuation.inflation.selected-assets', {
            count: selectedAssetUuids.length
          })}
        </div>
      }
      renderMenuItems={renderItems}
      className="h-[24px] !z-20"
      buttonClassName="!py-0.5 !h-[26px]"
    />
  );
}
