/* External Libraries */
import { Toaster } from 'react-hot-toast';

export default function ToastMod() {
  return (
    <Toaster
      position="top-center"
      reverseOrder={false}
      gutter={8}
      containerClassName="w-full"
      containerStyle={{}}
      toastOptions={{
        // Define default options
        className: '',
        duration: 5000,
        style: {
          width: '100%',
          background: '#363636',
          color: '#fff'
        },
        // Default options for specific types
        success: {
          duration: 3000,
          iconTheme: {
            primary: 'green',
            secondary: 'black'
          }
        }
      }}
    />
  );
}
