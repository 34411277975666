import { ValueCategoryTypes } from 'shared/dist/types';
import {
  CashflowCredit,
  CashflowInflation,
  CashflowInvestment,
  CashflowLease,
  CashflowValuationAsset,
  CashflowValuationModel,
  InflationType,
  InvestmentParentType,
  PropertyTax
} from 'shared/dist/types/cashflow/cashflow-model';
import {
  CashflowValuationRequestAsset,
  CashflowValuationRequestModel,
  ModelAssumptions
} from 'shared/dist/types/cashflow/valuation';
import { PropertyVM } from 'shared/dist/types/property2';
import { NewsecCategory } from 'shared/dist/types/rent';

enum AssetPropertyTypeGroup {
  COMMERCIAL = 'commercial',
  RESIDENTIAL = 'residential'
}

export const getOrUpdateValuation = async (model: CashflowValuationModel, object: PropertyVM) => {
  const formattedRequest: CashflowValuationRequestModel = formatModelForApiRequest(model, object);
  return await fetch(`/cashflow-python-backend/valuation`, {
    method: 'POST',
    body: JSON.stringify(formattedRequest),
    headers: {
      'Content-type': 'application/json'
    }
  });
};

// Simple formatting of the Javascript object to match the expected format of the Python API
function formatDateForRequest(d: number) {
  const date = new Date(d * 1000); // Double check that the date is actually a date (and not a string from the DB)
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(
    date.getDate()
  ).padStart(2, '0')}T00:00:00.000`;
}

function formatCredit(credit: CashflowCredit) {
  return {
    name: credit.name,
    uuid: credit.uuid,
    amount: credit.amount,
    start_date: formatDateForRequest(credit.startDate),
    amortization: credit.amortization,
    floating_interest: credit.floatingInterest,
    fixed_interest: credit.fixedInterest
      ? {
          end_date: formatDateForRequest(credit.fixedInterest.endDate),
          interest: credit.fixedInterest.amount
        }
      : undefined
  };
}

const formatModelForApiRequest = (
  model: CashflowValuationModel,
  object: PropertyVM
): CashflowValuationRequestModel => {
  const formattedRentIndexes: CashflowInflation[] = model.rentIndexes.map(formatInflation);
  const formattedCostIndexes: CashflowInflation[] = model.costIndexes.map(formatInflation);
  const assumptionsForRequest: ModelAssumptions = formatModelAssumptionsForRequest(
    model.modelLength,
    formattedRentIndexes
  );
  const totalAssetsArea: number = model.assets.reduce(
    (a, b) => a + b.adjustableAssumptions.area,
    0
  );
  const assetsForRequest: CashflowValuationRequestAsset[] = model.assets.map((asset) =>
    formatAssetForRequest(
      asset,
      formattedRentIndexes,
      formattedCostIndexes,
      model.investments,
      totalAssetsArea,
      object
    )
  );

  return {
    uuid: model.uuid,
    name: model.name,
    assumptions: assumptionsForRequest,
    properties: assetsForRequest,
    credits: model.credits?.map((credit) => formatCredit(credit))
  };
};

const formatInflation = (inflation: CashflowInflation): CashflowInflation => {
  return {
    name: inflation.name,
    uuid: inflation.uuid,
    values: inflation.values.map((v: any) => ({
      year: v.year,
      value: v.value
    }))
  };
};

const formatModelAssumptionsForRequest = (
  modelLength: {
    startDate: number;
    endDate: number;
  },
  inflations: CashflowInflation[]
): ModelAssumptions => {
  return {
    start_date: formatDateForRequest(modelLength.startDate),
    end_date: formatDateForRequest(modelLength.endDate),
    inflations: inflations
  };
};

export const getLeaseFeePerSqm = (totalAssetsArea: number, object: PropertyVM): number => {
  if (
    totalAssetsArea === 0 ||
    !object.landLeaseAgreement?.lease_fee ||
    object.landLeaseAgreement?.lease_fee === 0
  ) {
    return 0;
  }

  return object.landLeaseAgreement?.lease_fee / totalAssetsArea;
};

export const getPropertyTax = (propertyTax: PropertyTax): number => {
  if (!propertyTax.countPropertyTax) {
    return 0;
  }
  return propertyTax.taxValue * propertyTax.propertyTaxType.value; // total taxed value of property times property tax type percentage
};

const formatAssetForRequest = (
  asset: CashflowValuationAsset,
  rentIndex: CashflowInflation[],
  costIndex: CashflowInflation[],
  modelInvestments: CashflowInvestment[],
  totalAssetsArea: number,
  object: PropertyVM
): CashflowValuationRequestAsset => {
  const assetType =
    asset.adjustableAssumptions.type === NewsecCategory.HOUSING
      ? AssetPropertyTypeGroup.RESIDENTIAL
      : AssetPropertyTypeGroup.COMMERCIAL;

  const assetArea: number = asset.adjustableAssumptions.area ?? 0;
  const assetRentIndex: CashflowInflation =
    rentIndex.find((infl) => infl.uuid === asset.adjustableAssumptions.rentIndex.uuid) ??
    rentIndex[0];
  const hasNoLeases: boolean =
    asset.adjustableAssumptions.type !== NewsecCategory.HOUSING &&
    asset.adjustableAssumptions.rentRoll.reduce((a, b) => a + b.area, 0) === 0;

  const commercialNumbers =
    assetType === AssetPropertyTypeGroup.RESIDENTIAL
      ? []
      : asset.adjustableAssumptions.rentRoll.map((lease: CashflowLease) => {
          return {
            ...lease,
            start_date: formatDateForRequest(lease.startDate),
            end_date: formatDateForRequest(lease.endDate),
            rent: lease.area > 0 ? lease.rent * lease.area : 0, // total rent for lease (rent/m2 * lease area)
            inflation: assetRentIndex
          };
        });

  const getCosts = (totalCosts: number, assetArea: number): number => {
    if (assetArea === 0 || hasNoLeases) {
      return 0;
    }
    return totalCosts / assetArea;
  };

  const assetTax: number = hasNoLeases
    ? 0
    : getPropertyTax(asset.adjustableAssumptions.propertyTax);
  const assetCostIndex: CashflowInflation =
    costIndex.find((infl) => infl.uuid === asset.adjustableAssumptions.costIndex.uuid) ??
    costIndex[0];
  const formattedOpex = {
    maintenance: getCosts(
      asset.calculatedAssumptions.calculatorResult[ValueCategoryTypes.COSTS] +
        (asset.calculatedAssumptions.calculatorResult[ValueCategoryTypes.MAINTENANCE] ?? 0),
      assetArea
    ),
    leasehold: getLeaseFeePerSqm(totalAssetsArea, object),
    property_tax: (assetTax * 1000) / assetArea,
    inflation_type: asset.adjustableAssumptions.costIndex.type,
    inflation: assetCostIndex
  };

  const getWeightedValue = (value, totalArea) => {
    if (totalArea === 0 || value === 0 || assetArea === 0) {
      return 0;
    }

    const valuePerSqm = value / totalArea;
    return valuePerSqm * assetArea;
  };

  function formatInvestmentForRequest(investment: CashflowInvestment) {
    let amount = investment.amount;
    let saving = investment.saving;
    if (investment.parent.type === InvestmentParentType.MODEL) {
      amount = getWeightedValue(amount, totalAssetsArea);
      saving = getWeightedValue(saving, totalAssetsArea);
    }

    return {
      name: investment.name,
      uuid: investment.uuid,
      start_date: formatDateForRequest(investment.startDate),
      duration: investment.duration,
      amount: amount,
      saving: saving
    };
  }

  const formattedModelInvestments = (modelInvestments || []).map(formatInvestmentForRequest);
  const formattedAssetInvestments = (asset.adjustableAssumptions?.investments || [])?.map(
    formatInvestmentForRequest
  );

  const assetForRequest: CashflowValuationRequestAsset = {
    id: asset.uuid,
    name: asset.name,
    area: assetArea,
    type: assetType,
    opex: formattedOpex,
    inflation_start_date: formatDateForRequest(asset.calculatedAssumptions.inflationStartDate),
    discrete_inflation_month: asset.calculatedAssumptions.discreteInflationMonth,
    discount_rate: asset.calculatedAssumptions.discountRate,
    growth_rate: asset.calculatedAssumptions.growthRate,
    investments: [...formattedAssetInvestments, ...formattedModelInvestments],
    custom_cashflow_events: [] // deprecated (currently in favor of investments)
  };

  const getRentalIncomeForResidential = (rent, area) => {
    if (area === 0) {
      return 0;
    }

    return rent / assetArea; // rent/m2 for residential rent
  };
  const residentialAssumptions = {
    rental_income: getRentalIncomeForResidential(
      asset.calculatedAssumptions.calculatorResult[ValueCategoryTypes.RENT],
      assetArea
    ),
    vacancy_rate: asset.calculatedAssumptions.calculatorResult[ValueCategoryTypes.VACANCY],
    apartments: 0,
    churn_rate: 0,
    churn_cost: 0,
    inflation_type: InflationType.DISCRETE,
    inflation: assetRentIndex
  };

  if (assetType === AssetPropertyTypeGroup.COMMERCIAL) {
    return { ...assetForRequest, commercial: commercialNumbers };
  } else {
    return { ...assetForRequest, residential: residentialAssumptions };
  }
};
