const colors = require('tailwindcss/colors');
//const defaultTheme = require('tailwindcss/defaultTheme')

module.exports = {
  mode: 'jit',
  content: [
    './pages/**/*.{js,ts,jsx,tsx}',
    './src/components/**/*.{js,ts,jsx,tsx}',
    './src/widgets/**/*.{js,ts,jsx,tsx}',
    './src/layout/*.{js,ts,jsx,tsx}',
    './src/layout/**/*.{js,ts,jsx,tsx}'
  ],
  media: false, // or 'media' or 'class'
  darkMode: 'class',
  lightMode: 'class',
  theme: {
    extend: {
      screens: {
        print: { raw: 'print' },
        lgminus: '1008px', // for adv search filters slide in
        lg: '1024px',
        xlminus: '1261px', // for adv search filters slide in
        '2xlplus': '1576px',
        '3xl': '2000px',
        '4xl': '2500px'
      },
      fontFamily: {
        sans: ['Inter'],
        serif: ['Avenir Next']
      },
      fontWeight: {
        light: [300],
        normal: [400],
        medium: [500],
        demi: [600],
        bold: [800]
      },
      gridTemplateRows: {
        main: '56px calc(100% - 56px)'
      },
      colors: {
        'accent-1': '#FAFAFA',
        'accent-2': '#EAEAEA',
        'accent-7': '#333',
        success: '#0070f3',
        cyan: '#79FFE1',
        primary: '#E46543',
        orange: colors.orange,
        rose: colors.rose,
        gray: colors.gray,
        sky: colors.sky,
        teal: colors.teal,
        fl: {
          darkgray: '#4B5662'
        },
        light: {
          faded: '#f9fafb',
          orange: '#FB923C',
          inverted: '#222222',
          invertedtext: '#fff',
          primary: '#fff',
          graytext: '#8796a1',
          graysubtext: '#C9C9C9',
          grayactive: '#eee',
          grayhover: '#FCF3EC',
          grayborder: '#979797'
        },
        dark: {
          faded: '#4A4A4A',
          orange: '#FB923C',
          inverted: '#f9fafb',
          invertedtext: '#222',
          primary: '#2A2A2A',
          morefaded: '#5c5c5c',
          lessfaded: '#3c3c3c',
          // prop: 'rgba(131,186,179,1)',
          prop: 'rgba(110,151,145,1)',
          // prophover: 'rgba(131,186,179,0.6)'
          prophover: 'rgba(96,129,124,1)'
        },
        bobo: {
          menublue: '#497eb7',
          hover: '#575756',
          light: '#FEE9D8',
          geo: 'rgba(150, 75, 0, 0.1)',
          DEFAULT: '#FDBA74',
          dark: '#FB923C',
          link: '#888',
          black: '#333',
          iconbg: '#537470',
          data: '#ccc',
          co: 'rgba(0, 132, 187, 1.0)',
          colight: 'rgba(0, 132, 187, .05)',
          lowlight: 'rgba(41, 145, 132, 0.5)',
          medium: '#f9e6c3',
          low: '#b3e0c3',
          high: '#fac5b1',
          mediumlight: '#f9e6c3',
          prop: 'rgba(41, 145, 132, 1.0)',
          propiconbg: 'rgba(41, 145, 132, 0.2)',
          proplight: '#eff5f5',
          prophover: 'rgba(41, 145, 132, 0.8)',
          purple: 'rgba(134, 40, 145, 1)',
          purpleiconbg: 'rgba(134, 40, 145, 0.2)',
          orange: 'rgba(251, 146, 60, 1.0)',
          texthover: '#f87527',
          orangelight: 'rgba(251, 146, 60, .05)',
          orangehover: 'rgba(251, 146, 60, .8)',
          council: 'rgba(203, 111, 108, 1.0)',
          counciliconbg: 'rgba(203, 111, 108, .2)',
          councillight: 'rgba(250, 241, 241, 1)',
          councildim: '#5e5757',
          councilred: 'rgba(255, 0, 0, .3)',
          basic: '#ee8e3f',
          note: '#2A7B87',
          blue: 'rgb(96, 183, 255)',
          fun: '#ff7060',
          input: '#f0f3f5',
          green: '#2a9184',
          lightgreen: 'rgba(148, 210, 189, 36)',
          verylightgreen: '#c2e4d8',
          menubg: '#F8FAFC',
          darkmenu: '#f2f5f9',
          darkermenu: '#e7ebf1',
          electricgreen: '#0A9396',
          seablue: '#005F73',
          burgundy: '#9B2226',
          sunsetyellow: '#EE9B00',
          bricksred: '#BB3E03',
          gray: '#e9ecef',
          icelandicGray: '#7d8597',
          nordiclichengreen: '#94D2BD',
          bordergray: '#dddddd',
          dotsgray: '#858585',
          gridcardtopgray: '#f3f4f6',
          olive: '#808000',
          bggreen: '#D9EFE7',
          linegray: '#979797',
          lightgray: '#FBFBFB',
          nextgray: '#EBEBEB',
          typeprop: 'rgba(12, 26, 151, 1)',
          typeproplight: 'rgba(12, 26, 151, .1)',
          typewarehouse: 'rgba(145, 133, 40, 1)',
          typewarehouselight: 'rgba(145, 133, 40, .2)',
          typewarehouselighthover: 'rgba(145, 133, 40, .075)',
          typewarehousemustard: 'rgb(145, 133, 40)',
          typespace: 'rgba(134, 40, 145, 1)',
          typespacelight: 'rgba(134, 40, 145, .2)',
          typespacelighthover: 'rgba(134, 40, 145, .075)',
          typespacepurple: 'rgb(134, 40, 145)',
          typestreet: 'rgba(38, 145, 39, 1)',
          typestreetlight: 'rgba(38, 145, 39, .1)',
          typeindustry: 'rgba(145, 88, 40, 1)',
          typeindustrylight: 'rgba(145, 88, 40, .1)',
          typeresidential: 'rgba(83, 139, 231, 1.0)',
          typeresidentiallight: 'rgba(83, 139, 231, 0.2)',
          typespecial: '#cb6f6d',
          typespeciallight: 'rgba(255, 0, 0, .2)',
          // darkblue: 'rgba(83, 139, 231)',
          typeresidentialblue: 'rgb(83, 139, 231)',
          typeresidentiallighthover: 'rgba(83, 139, 231, 0.075)',
          indanthrenedarkblue: '#13395c',
          indanthrenedarkbluefill: '#2c3a46'
          // eveningGlow: '#ffafaf'
        },
        card: {
          graysubtext: '#4B5663',
          graypanel: '#f3f4f6',
          grayborder: '#ebebeb'
        }
      },
      shadow: {
        fl: '0 8px 5px rgba(0, 0, 0, 0.08)'
      },
      spacing: {
        28: '7rem'
      },
      letterSpacing: {
        tighter: '-.04em'
      },
      gridColumn: {
        'span-16': 'span 16 / span 16'
      },
      gridTemplateColumns: {
        // Simple 16 column grid
        16: 'repeat(16, minmax(0, 1fr))'
      },
      maxHeight: {
        match: 'calc(100vh - 0rem)',
        view: 'calc(100vh - 5rem)',
        drop: '250px',
        selectorSmall: '300px',
        selectorMid: '400px',
        selectorMid2: '350px',
        selector: '400px',
        maponly: 'calc(100vh - 250px)',
        overview: 'calc(100vh - 200px)',
        overview2: 'calc(100vh - 215px)'
      },
      maxWidth: {
        semi: '50vw',
        match: '60vw',
        table: '80vw',
        large: '1600px'
      },
      width: {
        almostHalf: '45%',
        justOverHalf: '55%',
        5.5: '22px',
        corner: '4rem'
      },
      minHeight: (theme) => ({
        // adding in all the usual spacing classes
        ...theme('spacing'),
        match: 'calc(100vh - 10rem)',
        full: 'calc(100vh)',
        almostFull: 'calc(96vh)',
        info: 'calc(100vh - 335px)',
        stretch: 'calc(50vh)',
        box: '160px',
        selectorSmall: '300px',
        selector: '400px',
        dialog: '600px',
        upload: '500px',
        fill: '100vh',
        maponly: 'calc(100vh - 250px)',
        fullmap: 'calc(100vh - 145px)'
      }),
      height: {
        pdf: 'calc(100vh - 10rem)',
        bigthumb: 'calc(80vh)',
        map: 'calc(65vh)',
        nocontent: 'calc(45vh)',
        chart: '400px',
        low: '300px',
        box: '12vh',
        setmap: '500px',
        fill: '100vh',
        5.5: '22px',
        maponly: 'calc(100vh - 240px)',
        fullmap: 'calc(100vh - 145px)'
      },
      minWidth: {
        wide: '500px',
        normal: '300px',
        medium: '120px',
        small: '80px',
        logo: '260px',
        selector: '50vw',
        table: '70vw',
        thehalf: '35vw',
        thefull: '80vw'
      },
      lineHeight: {
        tight: 1.2
      },
      padding: {
        step1: '1.5rem',
        step2: '2rem',
        step3: '2.5rem',
        5.5: '22px'
      },
      margin: {
        5.5: '22px'
      },
      zIndex: {
        100: '100'
      },
      fontSize: {
        mini: '10px',
        smaller: '13px',
        base: '16px',
        '5xl': '2.5rem',
        '6xl': '2.75rem',
        boboMD: '48px',
        boboLG: '60px',
        '7xl': '4.5rem',
        '8xl': '6.25rem'
      },
      boxShadow: {
        small: '0 5px 10px rgba(0, 0, 0, 0.12)',
        medium: '0 8px 30px rgba(0, 0, 0, 0.12)',
        card: '0 2px 4px rgba(154, 154, 154, 0.5)',
        carddark: '0 2px 4px rgba(0, 0, 0, 0.5)'
      }
    }
  },
  variants: {
    extend: {}
  },
  plugins: [require('@tailwindcss/forms'), require('@tailwindcss/aspect-ratio')]
};
