import { Popover, Transition } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { LinearProgress } from '@material-ui/core';
import cls from 'classnames';
import { useTranslation } from 'next-i18next';
import React, { Fragment, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { ButtonType } from 'shared/dist/types';
import { utils, writeFile } from 'xlsx';

import { Tfunc } from '@/src/types';

import { Csv02Icon, Xls02Icon } from '../../assets/icons';
import { useOutsideAlerter } from '../../hooks/use-outside-alerter';
import { createAndDownloadCsv } from '../../lib/utils/csv';
import Button from '../buttons';

interface DataExportDropdownProps {
  data: any[];
  tableHeaders?: { label: string; key: string }[];
  fileName?: string;
  title?: string;
  dataFetchFunction?: (
    t: Tfunc,
    data: any[],
    progressCb: (progress: number) => void
  ) => Promise<any[]>;
  disabledText?: string;
  down?: boolean;
  right?: boolean;
  children?: any;
  className?: string;
}

const DataExportDropdown: React.FC<DataExportDropdownProps> = ({
  data,
  tableHeaders,
  fileName,
  title,
  dataFetchFunction,
  disabledText,
  down = false,
  right = false,
  children,
  className = ''
}) => {
  const { t } = useTranslation('common');

  const [exportStatus, setExportStatus] = useState<'initial' | 'in-progress' | 'done'>('initial');
  const [dataFetchProgress, setDataFetchProgress] = useState<number | undefined>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openModalRef = useRef(null);

  useOutsideAlerter(openModalRef, setIsOpen);

  let headers: { label: string; key: string }[] = tableHeaders ?? [];

  if (!headers && data?.length) {
    headers = Object.keys(data[0]).map((key) => ({ label: key, key }));
  }
  const handleExcelExport = (fetchedData?: any) => {
    const exportData = fetchedData || data;

    const wb = utils.book_new();

    const dataForAoa: Array<Array<string>> = exportData.map((row: any) => {
      const newRow = { ...row };

      delete newRow['_sortRowLast'];

      const rowForExcel: string[] = [];

      for (const header of headers) {
        let cellValue = row[header.key];

        if (row[header.key + '__string']) {
          cellValue = row[header.key + '__string'];
        }
        rowForExcel.push(cellValue || '');
      }
      return rowForExcel;
    });

    const fr = headers.map((header) => header.label);

    dataForAoa.unshift(fr);

    const ws = utils.aoa_to_sheet(dataForAoa);

    const sheetName = 'Sheet1';

    utils.book_append_sheet(wb, ws, sheetName);

    writeFile(wb, fileName ? fileName + '.xlsx' : 'data.xlsx');
  };

  const handleCSVExport = (fetchedData?: any) => {
    const exportData = fetchedData || data;

    const csvHeaders = headers.map((header) => header.label);

    const csvData = exportData.map((row) => {
      const rowForCSV: string[] = [];

      for (const header of headers) {
        let cellValue = row[header.key];

        if (row[header.key + '__string']) {
          cellValue = row[header.key + '__string'];
        }
        rowForCSV.push(cellValue || '');
      }
      return rowForCSV;
    });

    createAndDownloadCsv(
      csvData,
      csvHeaders as string[],
      fileName ? fileName + '.csv' : 'data.csv'
    );
  };

  const disabledButton: boolean = data?.length === 0;

  function fetchDataAndExport(selectedExportType) {
    if (exportStatus === 'in-progress') {
      return;
    }
    setDataFetchProgress(0);
    setExportStatus('in-progress');
    if (dataFetchFunction) {
      dataFetchFunction(t, data, (progress) => {
        const total = data.length;
        const progressPercentage = Math.round((progress / total) * 100);
        setDataFetchProgress(progressPercentage);
      })
        .then((fetchedData) => {
          setExportStatus('done');
          if (selectedExportType === 'excel') {
            handleExcelExport(fetchedData);
          } else {
            handleCSVExport(fetchedData);
          }
        })
        .catch((err) => {
          setExportStatus('initial');
          setDataFetchProgress(0);
          console.error(`[dataExportDropdown] error:`, err);
          toast.error(t('errors.opps-something-went-wrong'));
        });
    } else {
      setExportStatus('initial');
      if (selectedExportType === 'excel') {
        handleExcelExport();
      } else {
        handleCSVExport();
      }
    }
  }

  return (
    <Popover as="div" className="relative inline-block text-left">
      <>
        {!children ? (
          <Button
            popover
            type={ButtonType.INFO}
            disabled={disabledButton || exportStatus === 'in-progress'}
            onClick={() => setIsOpen(!isOpen)}
            className={`has-tooltip !py-1.5 ${className}`}>
            <>
              {disabledButton && disabledText ? (
                <div className="tooltip text-wrap text-mini leading-3 rounded shadow-lg p-2 bg-bobo-black bg-opacity-80 text-white mt-[73px] w-[270px] right-0 bottom-8 text-left">
                  {disabledText}
                </div>
              ) : null}

              {exportStatus === 'in-progress' ? (
                <span>
                  <LinearProgress
                    style={{ width: '100px' }}
                    value={dataFetchProgress}
                    variant="determinate"
                  />
                </span>
              ) : null}

              <div>{title ?? t('general.export')}</div>

              <ChevronRightIcon
                className={cls('w-4 h-4 transition-transform duration-200', {
                  '-rotate-90': isOpen && !down,
                  'rotate-90': isOpen && down
                })}
              />
            </>
          </Button>
        ) : (
          <Popover.Button
            className="has-tooltip"
            onClick={() => setIsOpen(!isOpen)}
            disabled={disabledButton || exportStatus === 'in-progress'}>
            {children}
            {exportStatus === 'in-progress' ? (
              <div className="rounded shadow-lg p-1 bg-gray-400 dark:bg-dark-lessfaded top-5 w-[max-content] max-w-[135px] absolute right-0.5 z-50">
                <LinearProgress
                  style={{ width: '100px' }}
                  value={dataFetchProgress}
                  variant="determinate"
                />
              </div>
            ) : null}
          </Popover.Button>
        )}

        <Transition
          as={Fragment}
          show={isOpen}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Popover.Panel
            ref={openModalRef}
            className={cls(
              'origin-top-right absolute w-[250px] z-30 rounded-md shadow-lg border bg-white border-gray-200 dark:border-dark-morefaded dark:bg-dark-lessfaded ring-1 ring-gray-200 ring-opacity-5 focus:outline-none',
              {
                'bottom-8': !down
              },
              {
                'top-10': down
              },
              {
                'right-0': !right
              },
              {
                'left-0': right
              }
            )}
            role="menu">
            <div className="py-1" role="none">
              <span
                className="text-gray-700 dark:text-gray-400 block px-4 py-2 text-xs font-demi"
                id="menu-item-0">
                {t('data-export.pick-format')}
              </span>

              <button
                onClick={() => {
                  setTimeout(() => {
                    fetchDataAndExport('excel');
                  }, 0);
                  setIsOpen(false);
                }}
                className="text-gray-700 dark:text-white flex px-4 py-2 text-sm hover:bg-bobo-orangelight dark:hover:bg-dark-faded w-full text-left transition-color duration-300 space-x-2"
                role="menuitem"
                id="menu-item-1">
                <Xls02Icon className="dark:text-white" />
                <div>{t('data-export.excel')}</div>
              </button>

              <button
                type="submit"
                className="text-gray-700 dark:text-white flex px-4 py-2 text-sm hover:bg-bobo-orangelight dark:hover:bg-dark-faded w-full text-left transition-color duration-300 space-x-2"
                role="menuitem"
                onClick={() => {
                  setTimeout(() => {
                    fetchDataAndExport('csv');
                  }, 0);
                  setIsOpen(false);
                }}
                id="menu-item-2">
                <Csv02Icon className="dark:text-white" />
                <div>{t('data-export.csv')}</div>
              </button>
            </div>
          </Popover.Panel>
        </Transition>
      </>
    </Popover>
  );
};

export default DataExportDropdown;
