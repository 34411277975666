import { Popover, Transition } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import cls from 'classnames';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCategoryName } from 'shared/dist/constants';
import { RadioButtonOptions, ValueCategoryTypes } from 'shared/dist/types';
import { CashflowLease } from 'shared/dist/types/cashflow/cashflow-model';

import { useOutsideAlerter } from '@/src/hooks/use-outside-alerter';
import { FLCustom } from '@/src/widgets/numbers';

export function NewsecRadioButton({
  valueCategory,
  changeValue,
  selectedTab,
  tabValues,
  disabled,
  radioButtonStyle,
  defaultRowValues,
  rentRoll
}: {
  valueCategory: any;
  changeValue: any;
  selectedTab: string;
  tabValues: any;
  disabled: boolean;
  radioButtonStyle: string;
  defaultRowValues: any;
  rentRoll?: CashflowLease[];
}) {
  const [showNewsecCategories, setShowNewsecCategories] = useState<boolean>(false);
  const [selectedNewsecType, setSelectedNewsecType] = useState<any>(
    defaultRowValues.newsec.find((type) => type.propType === tabValues.selectedNewsecCategory)
  );
  const { t } = useTranslation('common');
  const showNewsecCategoriesRef = useRef(null);

  useOutsideAlerter(showNewsecCategoriesRef, setShowNewsecCategories);

  useEffect(() => {
    if (tabValues.selectedNewsecCategory !== selectedNewsecType.propType) {
      setSelectedNewsecType(
        defaultRowValues.newsec.find((type) => type.propType === tabValues.selectedNewsecCategory)
      );
    }
  }, [tabValues.selectedNewsecCategory]);

  return (
    <div
      className={cls('flex items-center justify-center w-full gap-2 border-gray-100', {
        'grayscale opacity-30':
          selectedNewsecType?.value === 0 && valueCategory.title !== ValueCategoryTypes.VACANCY
      })}>
      <div className="w-1/6 h-full flex items-center justify-center">
        <input
          type={'radio'}
          name={`${valueCategory.title}-select`}
          disabled={
            (selectedNewsecType?.value === 0 &&
              valueCategory.title !== ValueCategoryTypes.VACANCY) ||
            disabled ||
            rentRoll !== undefined
          }
          value={RadioButtonOptions.NEWSEC}
          checked={tabValues.radioButton === RadioButtonOptions.NEWSEC && !rentRoll}
          onChange={() => {
            changeValue(
              selectedTab,
              valueCategory.title,
              selectedNewsecType.value,
              RadioButtonOptions.NEWSEC
            );
          }}
          className={radioButtonStyle}
        />
      </div>

      <div className="flex flex-col w-5/6 justify-start text-start leading-3">
        <Popover className="relative">
          <Popover.Button
            onClick={(e) => {
              e.stopPropagation();
              setShowNewsecCategories(!showNewsecCategories);
            }}
            disabled={
              disabled ||
              defaultRowValues?.newsec?.filter((rentCategory) => rentCategory.value > 0).length ===
                0
            }
            className={cls(
              'flex gap-1 items-center transition-colors duration-200 justify-start',
              {
                'text-bobo-prop hover:text-bobo-prophover dark:text-dark-prop hover:dark:text-dark-prophover':
                  !disabled
              },
              { 'text-gray-400': disabled }
            )}>
            <div className="whitespace-nowrap text-start w-[110px]">
              {RadioButtonOptions.NEWSEC} ({t(getCategoryName(selectedNewsecType?.propType))})
            </div>

            <ChevronRightIcon
              className={cls('w-4 h-4 transition-transform duration-200', {
                'rotate-90': showNewsecCategories
              })}
            />
          </Popover.Button>

          <Transition
            as={Fragment}
            show={showNewsecCategories}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 -translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-1">
            <Popover.Panel
              ref={showNewsecCategoriesRef}
              className="absolute transform translate-x-0 z-50">
              <div className="bg-white dark:text-white dark:bg-dark-morefaded text-bobo-black rounded-md shadow-md flex flex-col w-[110px] overflow-hidden">
                {defaultRowValues.newsec.map((propType, idx) => {
                  return (
                    <div
                      key={idx}
                      className={cls(
                        'py-3 px-5 transition-colors duration-200 group',
                        propType.propType === selectedNewsecType.propType &&
                          (propType.value > 0 || valueCategory.title === ValueCategoryTypes.VACANCY)
                          ? 'bg-bobo-prop dark:bg-dark-prop text-white'
                          : 'hover:bg-bobo-proplight dark:hover:bg-dark-prophover'
                      )}>
                      <button
                        onClick={() => {
                          if (propType) {
                            setSelectedNewsecType(propType);
                            if (!rentRoll) {
                              changeValue(
                                selectedTab,
                                valueCategory.title,
                                propType.value,
                                RadioButtonOptions.NEWSEC,
                                propType.propType
                              );
                            }
                            setShowNewsecCategories(false);
                          }
                        }}
                        disabled={
                          propType.value === 0 && valueCategory.title !== ValueCategoryTypes.VACANCY
                        }
                        className={cls(
                          'disabled:text-gray-300 w-full h-full',
                          propType.propType === selectedNewsecType.propType &&
                            (propType.value > 0 ||
                              valueCategory.title === ValueCategoryTypes.VACANCY)
                            ? 'text-white'
                            : 'text-bobo-prop dark:text-white group-hover:text-bobo-prophover dark:group-hover:text-white'
                        )}>
                        {t(getCategoryName(propType.propType))}
                        {propType.value > 0 ||
                        valueCategory.title === ValueCategoryTypes.VACANCY ? (
                          <div
                            className={cls(
                              'whitespace-nowrap',
                              propType.propType === selectedNewsecType?.propType
                                ? 'text-gray-200'
                                : 'text-gray-400'
                            )}>
                            <FLCustom value={propType.value} suffix={t(valueCategory.suffix)} />
                          </div>
                        ) : null}
                      </button>
                    </div>
                  );
                })}
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>

        {selectedNewsecType?.value === 0 &&
        valueCategory.title !== ValueCategoryTypes.VACANCY ? null : (
          <div className="text-gray-400">
            <FLCustom value={selectedNewsecType?.value} suffix={t(valueCategory.suffix)} />
          </div>
        )}
      </div>
    </div>
  );
}
