import { Popover, Transition } from '@headlessui/react';
import cls from 'classnames';
import { Trans, useTranslation } from 'next-i18next';
import { Fragment, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { ButtonType } from 'shared/dist/types';

import { useOutsideAlerter } from '@/src/hooks/use-outside-alerter';
import Button from '@/src/widgets/buttons';

export function SaveCalculationBtn({
  totalResult,
  object,
  savedCalculation,
  savedCalculations,
  setSavedCalculation,
  setSavedCalculations
}: {
  totalResult: any[];
  object: any;
  savedCalculation: any | null;
  savedCalculations: any[];
  setSavedCalculation: any;
  setSavedCalculations: any;
}) {
  const [saveAsNew, setSaveAsNew] = useState(false);
  const [calculationName, setCalculationName] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const showSavePanelRef = useRef(null);

  useOutsideAlerter(showSavePanelRef, setIsOpen);

  const { t } = useTranslation();

  const fetchSavedCalculations = async () => {
    await fetch(`/api/prop/value-calculator/get-all`, {
      method: 'POST',
      body: JSON.stringify({
        propertyUuid: object.uuid
      })
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        setSavedCalculations(json.savedCalculations);
      });
  };

  useEffect(() => {
    fetchSavedCalculations();
  }, [object]);

  const updateExistingSearch = async () => {
    await fetch('/api/prop/value-calculator/update', {
      method: 'POST',
      body: JSON.stringify({
        calculationUuid: savedCalculation.uuid,
        calculationName: savedCalculation.name,
        calculationData: totalResult
      })
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        const newCalculation = json?.newCalculation;
        setSavedCalculation(newCalculation);

        const tmpSavedCalculations = savedCalculations?.map((calc) => {
          if (calc.uuid !== newCalculation.uuid) {
            return calc;
          } else {
            return newCalculation;
          }
        });
        setSavedCalculations(tmpSavedCalculations);
        toast.success(`Kalkylen ${calculationName} är nu uppdaterad.`);
      });
  };

  const saveNewSearch = async () => {
    await fetch(`/api/prop/value-calculator/create`, {
      method: 'POST',
      body: JSON.stringify({
        propertyUuid: object.uuid,
        calculationData: totalResult,
        calculationName: calculationName
      })
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        setSavedCalculation(json.newCalculation);

        const tmpSavedCalculations = savedCalculations?.concat([json.newCalculation]);
        setSavedCalculations(tmpSavedCalculations);

        toast.success(t('value-calculator.save-validated', { calculationName: calculationName }));
      });
  };

  const handleSave = (e) => {
    e.stopPropagation();
    setSaveAsNew(false);
    setCalculationName('');
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Popover className="relative flex justify-end">
        <>
          <Button
            popover
            onClick={handleSave}
            className="w-[80px]"
            type={isOpen ? ButtonType.CANCEL : ButtonType.ACTION}>
            {!isOpen ? t('general.save') : t('general.cancel')}
          </Button>

          <Transition
            as={Fragment}
            show={isOpen}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1">
            <Popover.Panel
              ref={showSavePanelRef}
              className="absolute bottom-1 right-3/3 z-10 mb-[38px] w-screen translate-x-0 transform px-4 sm:px-0 max-w-sm">
              <div className="rounded-lg shadow-2xl ring-1 ring-black/5 bg-white dark:bg-dark-morefaded overflow-hidden">
                <div className="p-6 pb-0 text-base dark:text-white text-gray-800 font-demi">
                  {saveAsNew || !savedCalculation
                    ? t('value-calculator.save')
                    : t('value-calculator.save-question')}
                </div>
                <div className="relative flex justify-between items-stretch p-6 z-50">
                  <button
                    onClick={() => {
                      updateExistingSearch();
                      setIsOpen(false);
                    }}
                    className={cls(
                      `w-1/2 transition-all cursor-pointer rounded-md bg-gray-50 dark:bg-dark-lessfaded p-3 duration-200 flex justify-between items-center hover:bg-purple-50 dark:hover:bg-purple-900/20`,
                      {
                        '!w-0 hidden opacity-0': saveAsNew || !savedCalculation
                      }
                    )}>
                    <div className="text-sm text-center w-full">
                      <div>
                        <Trans i18nKey="value-calculator.update" t={t}>
                          Uppdatera <strong>{{ calculationName: savedCalculation?.name }}</strong>
                        </Trans>
                      </div>
                    </div>
                  </button>
                  <div
                    className={cls(`border-l dark:border-dark-lessfaded mx-2 flex-1`, {
                      '!w-0 hidden opacity-0': saveAsNew || !savedCalculation
                    })}
                  />
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      if (!saveAsNew) {
                        setSaveAsNew(true);
                      }
                    }}
                    onKeyDown={() => {
                      if (!saveAsNew) {
                        setSaveAsNew(true);
                      }
                    }}
                    className={cls(
                      `w-1/2 flex bg-gray-50 dark:bg-dark-lessfaded text-center transition-colors duration-200 items-center rounded-md`,
                      {
                        'w-full dark:bg-dark-morefaded bg-transparent cursor-default':
                          saveAsNew || !savedCalculation
                      },
                      {
                        'hover:bg-bobo-proplight dark:hover:bg-dark-prophover cursor-pointer':
                          !saveAsNew && savedCalculation
                      }
                    )}>
                    {saveAsNew || !savedCalculation ? (
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          saveNewSearch();
                          setIsOpen(false);
                        }}
                        className="flex justify-between items-center w-full">
                        <div
                          className={cls(`w-3/4 relative`, {
                            'w-full': saveAsNew
                          })}>
                          <input
                            value={calculationName}
                            onChange={(txt) => {
                              setCalculationName(txt.target.value);
                            }}
                            autoFocus={true}
                            placeholder={t('value-calculator.my-calculation')}
                            className={cls(
                              `border placeholder:italic w-full border-gray-200 rounded-md text-sm text-gray-800 p-2 bg-white dark:bg-dark-primary dark:text-white placeholder:dark:text-gray-400`
                            )}
                          />
                        </div>
                        <button
                          type="submit"
                          disabled={calculationName?.length < 3}
                          className={cls(
                            `text-xs p-2 px-4 rounded-sm shadow bg-gray-700 dark:bg-gray-500 dark:hover:bg-dark-prop hover:bg-bobo-prop transition-colors duration-200 text-white disabled:bg-gray-300 dark:disabled:bg-dark-lessfaded dark:disabled:text-gray-300 disabled:text-gray-400 disabled:shadow-none`,
                            {
                              'ml-4': saveAsNew
                            }
                          )}>
                          {saveAsNew || !savedCalculation
                            ? t(`general.save`)
                            : t(`general.update`)}{' '}
                        </button>
                      </form>
                    ) : (
                      <div className="p-3">
                        <div
                          className={cls(`text-sm`, {
                            'ml-4': saveAsNew
                          })}>
                          {saveAsNew || !savedCalculation
                            ? t(`general.save`)
                            : t('value-calculator.save-new')}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      </Popover>
    </>
  );
}
