import { Switch } from '@headlessui/react';
import { Square3Stack3DIcon, XMarkIcon } from '@heroicons/react/24/solid';
import cls from 'classnames';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { useMap } from 'react-map-gl';

export default function SwitchTablePopup() {
  const [showSwitchTable, setShowSwitchTable] = useState<boolean>(false);
  const [showHeightCurves, setShowHeightCurves] = useState<boolean>(true);
  const [showPropBorders, setShowPropBorders] = useState<boolean>(true);

  const { riskMap } = useMap();
  const { t } = useTranslation('common');

  useEffect(() => {
    if (riskMap?.loaded) {
      riskMap
        .getMap()
        .setLayoutProperty(`area`, `visibility`, showPropBorders ? 'visible' : 'none');
      riskMap
        .getMap()
        .setLayoutProperty(`border`, `visibility`, showPropBorders ? 'visible' : 'none');
    }
  }, [showPropBorders]);

  useEffect(() => {
    if (riskMap?.loaded) {
      riskMap
        .getMap()
        .setLayoutProperty(`hojdlinje`, `visibility`, showHeightCurves ? 'visible' : 'none');
      riskMap
        .getMap()
        .setLayoutProperty(`hojdlinje-label`, `visibility`, showHeightCurves ? 'visible' : 'none');
    }
  }, [showHeightCurves]);

  return (
    <div className="relative">
      <div
        className={cls(
          'transition-all h-auto bottom-0 z-10 bg-gray-50 dark:bg-dark-primary rounded-l-md duration-200 right-0 w-[200px] absolute overflow-hidden flex flex-col justify-start items-start',
          { '!hidden': !showSwitchTable }
        )}>
        <div className="relative p-3 w-full">
          <button
            className="text-smaller font-medium flex items-center space-x-1 text-gray-700 dark:text-white"
            onClick={() => {
              setShowSwitchTable(!showSwitchTable);
            }}>
            <XMarkIcon className="w-5 h-5" />
            <div>{t('general.map-layers')}</div>
          </button>
          <div className="flex justify-start space-x-1 items-center border-b mt-3 py-2">
            <div className="text-xs">
              <div className="h-1 w-4 bg-red-500 rounded-full" />
            </div>
            <div className="text-xs">{t('general.show-property-borders')}</div>
            <div className="flex-1 text-right">
              <Switch
                checked={showPropBorders}
                onChange={setShowPropBorders}
                className={`${
                  showPropBorders ? 'bg-bobo-prop' : 'bg-gray-400'
                } relative inline-flex h-5 w-[35px] items-center rounded-full`}>
                <span className="sr-only">{t('general.enable-notifications')}</span>
                <span
                  className={`${
                    showPropBorders ? 'translate-x-4' : 'translate-x-1'
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
            </div>
          </div>
          <div className="flex justify-start items-center py-2 space-x-1">
            <div className="text-xs">
              <div className="h-1 w-4 bg-gray-400 rounded-full" />
            </div>
            <div className="text-xs">{t('general.show-height-curves')}</div>
            <div className="flex-1 text-right">
              <Switch
                checked={showHeightCurves}
                onChange={setShowHeightCurves}
                className={`${
                  showHeightCurves ? 'bg-bobo-prop' : 'bg-gray-400'
                } relative inline-flex h-5 w-[35px] items-center rounded-full`}>
                <span className="sr-only">{t('general.enable-notifications')}</span>
                <span
                  className={`${
                    showHeightCurves ? 'translate-x-4' : 'translate-x-1'
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
            </div>
          </div>
        </div>
      </div>
      <button
        onClick={() => {
          setShowSwitchTable(!showSwitchTable);
        }}
        className={cls(
          `relative bg-gray-50 top-0 rounded-l-md shadow-2xl dark:bg-dark-primary p-2 h-[48px] w-[48px] flex justify-center items-center`
        )}>
        <div className="text-smaller font-medium text-gray-700 dark:text-white">
          <Square3Stack3DIcon className="w-5 h-5" />
        </div>
      </button>
    </div>
  );
}
