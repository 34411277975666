import cls from 'classnames';
import { ButtonType } from 'shared/dist/types';

import { useBreakpoint } from '@/src/hooks/use-breakpoint';
import Tooltip from '@/src/layout/components/tooltip';

import Button from '../buttons';

export default function MenuButton({
  handleClick,
  icon,
  label,
  tooltip,
  iconOnly = false
}: {
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  icon: JSX.Element;
  label: string;
  tooltip: string;
  iconOnly?: boolean;
}) {
  const { isXl } = useBreakpoint({ breakpoint: 'xl' });

  const showTooltip = !isXl || iconOnly;

  return (
    <Button
      onClick={handleClick}
      type={ButtonType.INFO}
      tooltipText={showTooltip ? tooltip : undefined}
      className={cls(`gap-2 has-tooltip !text-smaller group max-h-[32px] !py-1.5 relative`, {
        '!px-2': iconOnly
      })}>
      {icon}
      <div
        className={cls(`hidden font-demi text-smaller tracking-wide`, {
          'xl:block': !iconOnly
        })}>
        {label}
      </div>
    </Button>
  );
}
