/* External Libraries */
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import cls from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import NumberFormat from 'react-number-format';
import { filterOutExtremeRents } from 'shared/dist/constants';
import { PropertyTypeGroup } from 'shared/dist/types';
import { getPrimaryTaxCodeBasedOnBuildingSize } from 'shared/dist/util/calculations';
import { getRentIntervals } from 'shared/dist/util/rent';

import { getColorForPropertyType } from '@/src/lib/colors';
import { FLCustom } from '@/src/widgets/numbers';
import { RowTableBody } from '@/src/widgets/row-table-body';

export default function RentStatsTable({
  property,
  iconOnly = false,
  slideIn = false,
  primaryType,
  close
}: {
  property: any;
  primaryType?: PropertyTypeGroup;
  iconOnly?: boolean;
  slideIn?: boolean;
  close?: any;
}) {
  const router = useRouter();

  const { t } = useTranslation('common');

  if (!primaryType) {
    primaryType = getPrimaryTaxCodeBasedOnBuildingSize(property?.propTax);
  }

  let rentNumber = 0;
  let rentRadius = '0.25 km';
  let interval: [number, number] = [0, 0];
  let extremeRentValue = false;

  let index = 0;

  const hasRentData =
    primaryType === PropertyTypeGroup.HOUSING ||
    primaryType === PropertyTypeGroup.SPACE ||
    primaryType === PropertyTypeGroup.WAREHOUSE;

  switch (primaryType) {
    case PropertyTypeGroup.HOUSING: {
      rentNumber = filterOutExtremeRents(property?.v2, PropertyTypeGroup.HOUSING)
        ? property?.v2?.residential_rent_91
        : 0;
      rentRadius = property?.housing?.radius;

      const list: number[] = property?.housing?.nearbyHousing
        ?.filter((a) => filterOutExtremeRents(a, PropertyTypeGroup.HOUSING))
        ?.map((a) => a?.residential_rent);

      // Figure out if it is a newly build or not
      const buildYear: number = property?.housing?.build_year;

      const nearbyBuildYears: number[] =
        property?.housing?.nearbyHousing?.length > 0
          ? property?.housing?.nearbyHousing?.map((a) => {
              return Math.max(a?.build_year);
            })
          : [];

      nearbyBuildYears.push(buildYear);

      const validNearbyBuildYears: number[] =
        nearbyBuildYears?.length > 0
          ? nearbyBuildYears.sort()?.filter((value) => value != null && value > 0)
          : [];
      index =
        validNearbyBuildYears?.length > 0 && buildYear > 0
          ? validNearbyBuildYears?.indexOf(buildYear)
          : 0;

      const resObj: { interval: [number, number]; extremeRentValue: boolean; mean: number } =
        getRentIntervals(list ?? [], rentNumber, index);

      interval = resObj?.interval;
      extremeRentValue = resObj?.extremeRentValue;

      break;
    }

    case PropertyTypeGroup.SPACE: {
      rentNumber = filterOutExtremeRents(property?.v2, PropertyTypeGroup.SPACE)
        ? property?.v2?.office_rent
        : 0;
      rentRadius = property?.space?.radius;

      const list: number[] = property?.space?.nearbySpaces
        ?.filter((a) => filterOutExtremeRents(a, PropertyTypeGroup.SPACE))
        ?.map((a) => a?.office_rent);

      const resObj: { interval: [number, number]; extremeRentValue: boolean; mean: number } =
        getRentIntervals(list ?? [], rentNumber);

      interval = resObj?.interval;
      extremeRentValue = resObj?.extremeRentValue;

      break;
    }

    case PropertyTypeGroup.WAREHOUSE: {
      rentNumber = filterOutExtremeRents(property?.v2, PropertyTypeGroup.WAREHOUSE)
        ? property?.v2?.warehouse_rent
        : 0;
      rentRadius = property?.warehouse?.radius;

      const list: number[] = property?.warehouse?.nearbyWarehouses
        ?.filter((a) => filterOutExtremeRents(a, PropertyTypeGroup.WAREHOUSE))
        ?.map((a) => a?.warehouse_rent);

      const resObj: { interval: [number, number]; extremeRentValue: boolean; mean: number } =
        getRentIntervals(list ?? [], rentNumber);

      interval = resObj?.interval;
      extremeRentValue = resObj?.extremeRentValue;

      break;
    }
  }

  const { color } = getColorForPropertyType(primaryType);
  const inRentSection = router?.asPath?.includes('rent-overview');

  return (
    <div
      className={cls(
        `dark:border-dark-morefaded w-full overflow-hidden ${
          iconOnly
            ? 'rounded-md bg-white border border-gray-200 h-[125px] dark:bg-dark-morefaded hover:bg-bobo-proplight'
            : 'dark:to-dark-faded'
        }`
      )}>
      <div
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (hasRentData && !inRentSection) {
            router.push(`/crm/fastighet/${property?.uuid}/rent-overview`);
            if (iconOnly && e.key === 'Enter') {
              close();
            }
          }
        }}
        onClick={() => {
          if (hasRentData && !inRentSection) {
            router.push(`/crm/fastighet/${property?.uuid}/rent-overview`);
            if (iconOnly) {
              close();
            }
          }
        }}
        className={cls(
          'transition-all duration-300 w-full h-full',
          hasRentData && !inRentSection
            ? ' dark:hover:bg-dark-morefaded hover:bg-bobo-proplight'
            : 'cursor-default',
          {
            'hover:!bg-bobo-typespacelighthover':
              primaryType === PropertyTypeGroup.SPACE && !inRentSection
          },
          {
            'hover:!bg-bobo-typeresidentiallighthover':
              primaryType === PropertyTypeGroup.HOUSING && !inRentSection
          },
          {
            'hover:!bg-bobo-typewarehouselighthover':
              primaryType === PropertyTypeGroup.WAREHOUSE && !inRentSection
          },
          { 'rounded-md': iconOnly }
        )}>
        <RowTableBody>
          <div
            className={cls(
              'w-full h-full pb-4 lg:pb-6 pt-4 lg:pt-1 flex items-center text-left p-6 lg:p-3 2xl:p-6 relative font-bold dark:text-white',
              interval[0] > 0 ? 'justify-between' : 'justify-center',
              slideIn ? 'to-gray-50' : 'to-white',
              {
                'from-bobo-typespacelight bg-gradient-to-b dark:to-dark-lessfaded dark:from-bobo-typespacelight':
                  primaryType === PropertyTypeGroup.SPACE && !iconOnly
              },
              {
                'from-bobo-typewarehouselight bg-gradient-to-b  dark:to-dark-lessfaded dark:from-bobo-typewarehouselight':
                  primaryType === PropertyTypeGroup.WAREHOUSE && !iconOnly
              },
              {
                'from-bobo-typeresidentiallight bg-gradient-to-b  dark:to-dark-lessfaded dark:from-bobo-typeresidentiallight':
                  primaryType === PropertyTypeGroup.HOUSING && !iconOnly
              },
              {
                'bg-gray-50 dark:bg-dark-lessfaded dark:hover:bg-dark-primary hover:bg-bobo-proplight transition-colors duration-300':
                  iconOnly
              }
            )}>
            <div
              className={cls('items-center flex justify-center w-2/4', {
                'h-10': iconOnly
              })}>
              <div
                className={cls(
                  'font-medium w-full flex-col items-center justify-between leading-3',
                  { '!text-bobo-bricksred': extremeRentValue, 'rounded-md': iconOnly }
                )}>
                {rentNumber && rentNumber > 0 ? (
                  <>
                    <div
                      className={cls(
                        'dark:!text-white w-fit p-1 px-1 rounded-md flex flex-col justify-center'
                      )}>
                      <div
                        style={{ color }}
                        className={cls(
                          `flex items-center justify-start whitespace-nowrap ${
                            iconOnly ? 'text-3xl xl:text-2xl' : 'text-3xl'
                          } dark:!text-white`,
                          {
                            'has-tooltip relative': extremeRentValue
                          }
                        )}>
                        <FLCustom value={Math.round(rentNumber)} />
                        <div className="flex flex-col justify-start relative">
                          <div className="font-normal text-xs ml-1">
                            {t('general.kr-per-square-m-suffix')}
                          </div>
                          {extremeRentValue ? (
                            <ExclamationTriangleIcon className="h-4 w-4 absolute -top-4" />
                          ) : null}
                        </div>
                        <div className="tooltip absolute p-2 px-1 top-3 bg-white text-bobo-black dark:text-white dark:bg-dark-faded rounded-md text-xs shadow-sm">
                          {t('property.rent.outside-of-interval')}
                        </div>
                      </div>
                      <div className="text-[11px] font-normal italic break-words text-gray-500 dark:text-gray-200">
                        {t('property.rent.estimated-rent-short', {
                          rentType:
                            primaryType === PropertyTypeGroup.HOUSING
                              ? t('property.rent-indexed')
                              : t('property.rent-avg')
                        })}
                      </div>
                    </div>
                  </>
                ) : (
                  <div
                    className={cls(
                      'flex flex-col items-center w-full justify-center text-center has-tooltip relative text-gray-400 pl-2',
                      iconOnly ? 'text-[8px]' : 'text-mini'
                    )}>
                    <ExclamationTriangleIcon className={cls(iconOnly ? 'w-5 h-5' : 'w-6 h-6')} />

                    <div className="whitespace-normal">{t('property.rent.missing-rent')}</div>
                  </div>
                )}
              </div>
            </div>
            {interval[1] > 1 ? (
              <div className="p-1 rounded-md px-3 w-2/4 min-w-[130px] text-smaller leading-[15px] flex flex-col items-end text-right justify-end">
                <div>
                  <div className="font-demi mt-8 -ml-4 lg:font-medium text-gray-800 dark:text-white text-smaller">
                    <NumberFormat
                      value={interval[0]}
                      thousandSeparator={' '}
                      suffix={t('general.kr-suffix')}
                      prefix={t('general.sek-prefix')}
                      displayType={'text'}
                    />{' '}
                    -{' '}
                    <NumberFormat
                      value={interval[1]}
                      thousandSeparator={' '}
                      suffix={t('general.kr-suffix')}
                      prefix={t('general.sek-prefix')}
                      displayType={'text'}
                    />
                  </div>
                  <div className="text-[11px] font-normal italic text-gray-500 dark:text-gray-200">
                    {t('property.rent.interval-radius', { radius: rentRadius })}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </RowTableBody>
      </div>
    </div>
  );
}
